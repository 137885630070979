  <div class="bgInicio">
    <div class="wrapper fadeInDown">
        <div id="formContent">
            <div class="fadeIn fourth" style="padding:30px">
                <img src="../../../assets/img/logo.png">
            </div>
          <form style="padding:20px">
            <input type="text" class="form-control fadeIn second" [(ngModel)]="user.email" name="email" placeholder="email">
            <br>
            <input type="password" class="form-control fadeIn third" [(ngModel)]="user.password" name="senha" placeholder="senha">
            <br>
            <input (click)="auth.login(user)" type="submit" class="btn btn-pink fadeIn fourth" value="ENTRAR">
          </form>
        </div>
      </div>
      <div style="position:absolute;bottom:10px;color:#fff;font-size: 0.6em;">Atualização: 20/11/2023</div>
  </div>