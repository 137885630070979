<div class="no-print">
    <div class="back" (click)="goBack()">
        <fa-icon [icon]="['fas', 'arrow-alt-circle-left']" size="2x"></fa-icon>
    </div>
    <div class="print" (click)="printContrato()">
        <fa-icon [icon]="['fas', 'print']" size="2x"></fa-icon>
    </div>
</div>
<!-- INICIO CONTRATO -->
<div class="contrato">
    <div style="text-align: right; font-size: 0.75em;">
        Geral – Associação de Alunos n° {{orcamento?.nProposta}}
    </div>
    <br><br><br> 
    <h5>CONTRATO PARTICULAR DE PRESTAÇÃO DE SERVIÇO DE ORGANIZAÇÃO DE EVENTOS DE FORMATURA</h5>
<br>
<br> 
    <u>I - DAS PARTES:</u>
<br>
<br>
    <strong>CONTRATADA: Descontei App Ltda</strong>, inscrita no CNPJ/MF sob o nº 30.538.802/0001-10, com sede e foro na Rua Marechal Deodoro, 3353, centro, cidade de Cascavel, estado do Paraná, sendo representada oficialmente neste instrumento pelo Sócio Sr. Anderson Alencar Novachinski, brasileiro, solteiro, portador do CPF n° 021.805.981-73 e RG n° 12.355.849-9 SSP/PR, residente e domiciliado à Rua Fortaleza, 3180, apto B1, Cascavel/PR, doravante denominada CONTRATADA.
<br>
<br>
    <strong>CONTRATANTE: {{contrato.nomeComissao}}</strong> <span *ngIf="contrato.cnpjComissao !== ''">inscrita no CNPJ/MF sob n° <strong>{{contrato.cnpjComissao}}</strong>, com sede e foro na <strong>{{contrato.enderecoComissao}}</strong> - <strong>{{contrato.bairroComissao}}</strong>, CEP <strong>{{contrato.cepComissao}}</strong>, <strong>{{contrato.cidadeComissao}}</strong> - <strong>{{contrato.ufComissao}}</strong></span>, sendo representada oficialmente nesse instrumento 
    <span *ngIf="turma.presidente">, pelo(a) Presidente <strong>{{turma.presidente}}</strong> portador do CPF n° <strong>{{turma.presidenteCpf}}</strong>, e RG n° <strong>{{turma.presidenteRg}}</strong>, <strong>{{turma.presidenteRgOrgao}}</strong>, <strong>{{turma.presidenteNacionalidade}}</strong>, <strong>{{turma.presidenteEstadoCivil}}</strong>, maior, residente e domiciliada na <strong>{{turma.presidenteEndereco}}</strong></span> 
    <span *ngIf="turma.vicePresidente">, pelo(a) Vice-presidente <strong>{{turma.vicePresidente}}</strong> portador do CPF n° <strong>{{turma.vicePresidenteCpf}}</strong>, e RG n° <strong>{{turma.vicePresidenteRg}}</strong>, <strong>{{turma.vicePresidenteRgOrgao}}</strong>, <strong>{{turma.vicePresidenteNacionalidade}}</strong>, <strong>{{turma.vicePresidenteEstadoCivil}}</strong>, maior, residente e domiciliada na <strong>{{turma.vicePresidenteEndereco}}</strong> </span>
    <span *ngIf="turma.primeiroSecretario">, pelo(a) 1º Secretário(a) <strong>{{turma.primeiroSecretario}}</strong> portador do CPF n° <strong>{{turma.primeiroSecretarioCpf}}</strong>, e RG n° <strong>{{turma.primeiroSecretarioRg}}</strong>, <strong>{{turma.primeiroSecretarioRgOrgao}}</strong>, <strong>{{turma.primeiroSecretarioNacionalidade}}</strong>, <strong>{{turma.primeiroSecretarioEstadoCivil}}</strong>, maior, residente e domiciliada na <strong>{{turma.primeiroSecretarioEndereco}}</strong> </span>
    <span *ngIf="turma.segundoSecretario">, pelo(a) 2º Secratário(a) <strong>{{turma.segundoSecretario}}</strong> portador do CPF n° <strong>{{turma.segundoSecretarioCpf}}</strong>, e RG n° <strong>{{turma.segundoSecretarioRg}}</strong>, <strong>{{turma.segundoSecretarioRgOrgao}}</strong>, <strong>{{turma.segundoSecretarioNacionalidade}}</strong>, <strong>{{turma.segundoSecretarioEstadoCivil}}</strong>, maior, residente e domiciliada na <strong>{{turma.segundoSecretarioEndereco}}</strong> </span> 
    <span *ngIf="turma.terceiroSecretario">, pelo(a) 3º Secratário(a) <strong>{{turma.terceiroSecretario}}</strong> portador do CPF n° <strong>{{turma.terceiroSecretarioCpf}}</strong>, e RG n° <strong>{{turma.terceiroSecretarioRg}}</strong>, <strong>{{turma.terceiroSecretarioRgOrgao}}</strong>, <strong>{{turma.terceiroSecretarioNacionalidade}}</strong>, <strong>{{turma.terceiroSecretarioEstadoCivil}}</strong>, maior, residente e domiciliada na <strong>{{turma.terceiroSecretarioEndereco}}</strong> </span> 
    <span *ngIf="turma.tesoureiro">, pelo(a) Tesoureiro(a) <strong>{{turma.tesoureiro}}</strong> portador do CPF n° <strong>{{turma.tesoureiroCpf}}</strong>, e RG n° <strong>{{turma.tesoureiroRg}}</strong>, <strong>{{turma.tesoureiroRgOrgao}}</strong>, <strong>{{turma.tesoureiroNacionalidade}}</strong>, <strong>{{turma.tesoureiroEstadoCivil}}</strong>, maior, residente e domiciliada na <strong>{{turma.tesoureiroEndereco}}</strong> </span> 
    <span *ngIf="turma.primeiroConsFiscal">, pelo(a) 1º Conselheiro(a) Fiscal <strong>{{turma.primeiroConsFiscal}}</strong> portador do CPF n° <strong>{{turma.primeiroConsFiscalCpf}}</strong>, e RG n° <strong>{{turma.primeiroConsFiscalRg}}</strong>, <strong>{{turma.primeiroConsFiscalRgOrgao}}</strong>, <strong>{{turma.primeiroConsFiscalNacionalidade}}</strong>, <strong>{{turma.primeiroConsFiscalEstadoCivil}}</strong>, maior, residente e domiciliada na <strong>{{turma.primeiroConsFiscalEndereco}}</strong> </span>  
    <span *ngIf="turma.segundoConsFiscal">, pelo(a) 2º Conselheiro(a) Fiscal <strong>{{turma.segundoConsFiscal}}</strong> portador do CPF n° <strong>{{turma.segundoConsFiscalCpf}}</strong>, e RG n° <strong>{{turma.segundoConsFiscalRg}}</strong>, <strong>{{turma.segundoConsFiscalRgOrgao}}</strong>, <strong>{{turma.segundoConsFiscalNacionalidade}}</strong>, <strong>{{turma.segundoConsFiscalEstadoCivil}}</strong>, maior, residente e domiciliada na <strong>{{turma.segundoConsFiscalEndereco}}</strong> </span>  
    <span *ngIf="turma.terceiroConsFiscal">, pelo(a) 3º Conselheiro(a) Fiscal <strong>{{turma.terceiroConsFiscal}}</strong> portador do CPF n° <strong>{{turma.terceiroConsFiscalCpf}}</strong>, e RG n° <strong>{{turma.terceiroConsFiscalRg}}</strong>, <strong>{{turma.terceiroConsFiscalRgOrgao}}</strong>, <strong>{{turma.terceiroConsFiscalNacionalidade}}</strong>, <strong>{{turma.terceiroConsFiscalEstadoCivil}}</strong>, maior, residente e domiciliada na <strong>{{turma.terceiroConsFiscalEndereco}}</strong> </span>  
     doravante denominada <strong>CONTRATANTE.</strong> 
    As partes acima identificadas têm, entre si, justo e acertado o presente Contrato de Prestação de Serviços, que se regerá pelas cláusulas seguintes e pelos termos e condições descritas abaixo:
<br>
<br>
    <u>II – OBJETO DO CONTRATO</u>
<br>
<br>
    <strong>Cláusula 1ª</strong>. É objeto do presente contrato de prestação de serviço de planejamento, organização e realização de eventos de formatura da<strong> {{contrato.nomeComissao}}</strong> compreendendo: (<strong *ngFor="let item of arrayItensContratados">{{item}}, </strong> festas e promoções que antecedem aos eventos relacionados) constantes no orçamento no Anexo I. 
<br>
<br>
    <u>III – OBRIGAÇÕES DO CONTRATANTE</u>
<br>
<br>
    <strong>Cláusula 2ª.</strong> O CONTRATANTE contrata com exclusividade, os serviços da CONTRATADA, no valor de <strong>{{orcamento?.totalSomas | currency:'BRL'}}</strong> (<strong>{{valorPorExtenso}}</strong>), correspondentes aos custos de contratações de fornecedores e profissionais para realização de: <strong *ngFor="let item of arrayItensContratados">{{item}}, </strong> festas e promoções que antecedem aos eventos relacionados.
<br>
<br>
    <strong>Parágrafo Primeiro.</strong> Sendo a CONTRATANTE a gestora dos recursos arrecadados dos alunos, provenientes das mensalidades, festividades e eventos preparatórios, esta será a responsável pelos pagamentos dos fornecedores e profissionais contratados pela <strong>Descontei App Ltda.</strong>
<br>
<br> 
    <strong>Parágrafo Segundo.</strong>  A CONTRATANTE, deverá apresentar cópia de todos os pagamentos realizados referentes às contratações de Fornecedores e Profissionais feitos pela CONTRATADA, para administração e controle dos gastos inerentes ao objeto do presente contrato.
<br>
<br> 
    <strong>Cláusula 3ª.</strong> O CONTRATANTE declara ser representante dos <strong>{{orcamento?.formandos}}</strong> (<strong>{{formandosExtenso}}</strong>) formandos dos <strong>CURSOS DE  <strong *ngFor="let turma of orcamento?.arrayTurmas">{{turma.curso}}</strong>, TURMAS <strong>{{orcamento?.dtFim | date:'yyyy'}}</strong>, DA UNIVERSIDADE <strong>{{orcamento?.instituicao}}</strong>,</strong> aderentes ao objeto do presente contrato e está de acordo com a exclusividade de cobertura de <strong *ngFor="let item of arrayItensContratados">{{item}}, </strong>foto e vídeo e eventos preparatórios que antecedem as festividades de formatura constantes do orçamento em anexo.
<br>
<br>
    <strong>Cláusula 4ª.</strong> A CONTRANTE, deverá participar da escolha dos serviços e itens a serem contratados junto a fornecedores terceiros, tais como: local para realização da Cerimônia do jantar e baile; buffet para realização do jantar; banda para o baile; floricultura para a decoração do salão de jantar/baile, e demais serviços e profissionais necessários para a execução com eficácia de todos os eventos citados na <strong>Cláusula 1ª</strong>, cuja exclusividade de contratação é da CONTRATADA, que terá a responsabilidade de planejar, organizar, conferir e garantir que a prestação desses serviços sejam executados conforme proposta, Anexo I deste contrato.
<br>
<br>
    <strong>Parágrafo Primeiro.</strong> A CONTRATANTE deverá fornecer à CONTRATADA todas as informações necessárias à realização do serviço de cerimonial dos eventos oficiais, devendo especificar os detalhes necessários à perfeita execução do mesmo.
<br>
<br>   
    <strong>Cláusula 5ª.</strong> Compete a CONTRATANTE informar à CONTRATADA, por e-mail: contato@trendsformaturas.com.br  com antecedência mínima de 15 (quinze) dias, quando da realização de PRÉ-EVENTOS (encontros anteriores às festas da formatura), onde se reúnam no mínimo 70% (setenta por cento) dos alunos do curso, e que seja necessária à presença da equipe da CONTRATADA para cobertura de fotos e vídeo. 
<br>
<br>
    <strong>Parágrafo Primeiro.</strong> Fica convencionado que a CONTRATADA tem a obrigação de fazer a cobertura dos PRÉ-EVENTOS desde que, respeitados os prazos descritos na Cláusula 5ª.
<br>
<br>
    <strong>Parágrafo Segundo.</strong> No caso em que o prazo de solicitação de cobertura do PRÉ-EVENTO seja inferior a 15 (quinze) dias, a decisão de cobertura passa pela deliberação da CONTRATADA.
<br>
<br>
    <strong>Cláusula 6ª.</strong> A CONTRATANTE tem ciência e está de acordo de que a CONTRATADA tem exclusividade dos serviços profissionais de cobertura fotográfica e gravação em vídeo dos eventos do cerimonial de formatura e dos pré-eventos, objetos deste contrato, estando ciente de que não é permitida sem autorização prévia da CONTRATADA, a presença de quaisquer profissionais ou contratados para execução dos serviços de fotógrafia ou cinegrafia nos locais onde a CONTRATADA desempenhará suas atividades e funções.
<br>
<br>
    <strong>Cláusula 7ª.</strong> Deverá a CONTRATANTE fornecer à CONTRATADA no ato da assinatura do contrato uma listagem dos formandos aderentes aos eventos, objeto deste contrato, constando os nomes, endereços, número de registro civil e do CPF e números de telefones para contatos e visitas futuras.
<br>
<br>
    <u>III – OBRIGAÇÕES DA CONTRATADA</u>
<br>
<br>
    <strong>Cláusula 8ª.</strong> A CONTRATADA deverá prestar os serviços de planejamento, organização e realização de eventos de formatura conforme detalhamento e prazos descritos no orçamento Anexo I ao presente contrato.
<br>
<br>
    <strong>Cláusula 9ª.</strong> A CONTRATADA compromete-se a prestar os serviços de montagem e execução de cerimonial à CONTRATANTE, fornecendo aos formandos, os itens (materiais e serviços) descritos na sua proposta, o qual é assinado pelas partes no Anexo I deste contrato, e fazendo-se presente com no mínimo 01 (uma) hora de antecedência, com número de profissionais capacitados, a seu critério, equipados e devidamente uniformizados, nos seguintes eventos:
<br>
<br>
    <strong>I - Colação de Grau</strong>, a realizar-se em dia a ser definido pela CONTRATANTE, a qual deverá comunicar através de ofício a CONTRATADA com antecedência mínima de 06 (seis) meses, para que a mesma possa agendar o local constante no Anexo I, programar e organizar o cerimonial e/ou equipe de fotografia e vídeo. 
<br>
<br>
    <strong>II - Jantar e Baile:</strong> A realizar-se em {{orcamento?.cidade}}/PR, conforme consta do Anexo I. A execução dos serviços de cerimonial serão realizadas pela CONTRATADA, com a utilização dos seguintes profissionais: coordenador-geral do evento, recepcionistas, cinegrafistas, fotógrafos, seguranças, mestre de cerimônia, porteiros no salão para controle da entrada dos convidados, garçons, técnicos de som e iluminação, dentre outros profissionais que se fizerem necessários para a perfeita realização do evento.
<br>
<br>
    <strong>III. Atividades Complementares:</strong> É também responsabilidade da CONTRATADA a seleção e contratação dos serviços de decoração/floricultura, buffet, banda e outros que sejam necessários para a realização do jantar/baile, respeitando-se os valores estabelecidos para cada um dos serviços constantes no Anexo I deste contrato.
<br>
<br>
    <strong>Cláusula 10ª:</strong> Com relação à segurança do Jantar e Baile, a CONTRATADA esclarece que em caso de infortúnio dentro do salão onde ocorre o evento, intermediará o conflito retirando do local quem deu causa a tal situação, entretanto a CONTRATADA exime-se de responder por danos morais ou físicos, vez que para manter a ordem do local e a segurança dos demais, terá que retirar do local aquele que der causa. A contratada não se responsabiliza por incidentes que ocorrerem fora do local do evento, sendo que, a responsabilidade da equipe de segurança se limita a zelar pelo ambiente interno do evento, excluindo a responsabilidade da CONTRATADA com relação aos veículos estacionados fora do local, seja em estacionamento gratuito ou não, inclusive dos veículos estacionados em via pública.
<br>
<br>
    <strong>Parágrafo Único.</strong> Conforme lei estadual 12.224/05 combinado com artigo 243 do estatuto da Criança e do Adolescente é proibido o consumo de bebidas alcoólicas a menores de 18 anos.
<br>
<br>
    <strong>Cláusula 11ª.</strong> A CONTRATADA compromete-se a realizar completa cobertura fotográfica e gravação em vídeo de todos os eventos e pré-eventos relativos a este contrato, desde que respeitados os prazos de comunicação que antecedem cada evento, sempre feitos formalmente pelo e-mail constante na Cláusula 5ª. A CONTRATADA compromete-se ainda a alocar equipe de profissionais em número suficiente para atender a demanda de todos os eventos, além de disponibilizar estúdios para sessões de fotos nos locais dos eventos.
<br>
<br>
    <strong>Cláusula 12ª.</strong> A contratação de todos os fornecedores e profissionais para execução dos serviços e atividades constantes no Anexo I do presente contrato é de responsabilidade exclusiva da CONTRATADA, tendo sempre a anuência por escrito da CONTRATANTE.
<br>
<br>
    <strong>Cláusula 13ª.</strong> Serão de responsabilidade da CONTRATADA os meios e atividades necessários para viabilizar a prestação de serviço objeto deste instrumento, incluindo equipamentos e serviços de terceiros, entre outros, salvo as obrigações da CONTRATANTE previstas neste contrato.
<br>
<br>
    <strong>Cláusula 14ª.</strong> Os Contratos, informações, dados, materiais e documentos inerentes à CONTRATANTE ou a seus clientes deverão ser utilizados, pela CONTRATADA, por seus funcionários ou contratados, estritamente para cumprimento dos serviços solicitados pela CONTRATANTE. A CONTRATADA concorda que tais informações devam ser manuseadas com o mesmo grau de cuidado que aplica às suas próprias informações confidenciais e se responsabiliza pelo correto uso de tais informações por parte de seus funcionários e contratados, sempre observando as autorizações acordadas entre as partes.
<br>
<br>
    <strong>Cláusula 15ª.</strong> Será de responsabilidade da CONTRATADA todo o ônus trabalhista ou tributário referente aos serviços contratados e utilizados para a prestação dos serviços objeto deste instrumento, ficando a CONTRATANTE isenta de qualquer obrigação em relação a eles.
<br>
<br>
    <strong>Cláusula 16ª.</strong> Fica atribuída a CONTRATADA a gestão de cobrança dos valores oriundos dos boletos, cheques pré-datados ou outros documentos de cobrança emitidos aos alunos associados, se responsabilizando principalmente pela emissão de avisos por atrasos e falta de pagamentos, inclusão dos inadimplentes nos cadastros de devedores de órgãos de proteção ao crédito (SCPC, Serasa, ou outro do gênero), bem como pelas cobranças judiciais e extrajudiciais. 
<br>
<br>
    <strong>Cláusula 17ª.</strong> Em caso de greve que venha a postergar a data de formação dos alunos, a CONTRATADA se responsabiliza pela realização da formatura em outra data, conforme disponibilidade do salão e demais serviços já contratados.
<br>
<br>
    <strong>Cláusula 18ª.</strong> A CONTRATADA deverá fornecer os respectivos documentos das contratações objeto do presente instrumento.
<br>
<br>
<u>IV – DO PREÇO E DAS CONDIÇÕES DE PAGAMENTO</u>
<br>
<br>
<strong>Cláusula 19ª.</strong> O valor total dos serviços objeto do presente contrato constante no Anexo I, é de <strong>{{orcamento?.totalSomas | currency:'BRL'}}</strong> (<strong>{{valorPorExtenso}}</strong>), (base de custos iniciais) correspondentes aos custos de contratações de fornecedores e profissionais que serão pagos diretamente aos mesmos pela Associação de Alunos, para realização dos eventos de formatura (<strong *ngFor="let item of arrayItensContratados">{{item}}, </strong> festas e promoções que antecedem aos eventos relacionados).
<br>
<br>
<strong>Parágrafo Primeiro:</strong> O valor base citado nesta cláusula corresponde a somatória da arrecadação de <strong>{{orcamento?.formandos}}</strong> (<strong>{{formandosExtenso}}</strong>) alunos aderentes a Associação de Alunos, os quais são partes integrantes do presente contrato, cujo valor por aluno é de <strong>{{orcamento?.totalPorFormandos | currency: 'BRL'}}</strong> (<strong>{{valorFormandoExtenso}}</strong>) que serão divididos em <strong>{{orcamento?.parcelas}}</strong> (<strong>{{parcelasExtenso}}</strong>) parcelas de <strong>{{orcamento?.totalPorFormandosPorMes | currency: 'BRL'}}</strong> (<strong>{{totalPorFormandosPorMesExtenso}}</strong>).
<br>
<br>
<strong>Parágrafo Segundo:</strong> Na ocorrência de aumento do valor arrecadado junto aos alunos pela aquisição de convites adicionais para o Jantar e Baile pelos alunos, e pelo auferimento das aplicações financeiras, os mesmos (valores) serão somados ao valor constantes nesta Cláusula, cuja soma se constituirá na base de cálculo para a Taxa de Administração constante na Cláusula 20ª.
<br>
<br>
<strong>Cláusula 20ª:</strong> Fica estabelecido que o valor da taxa de Administração a ser paga à CONTRATADA será de <strong>{{percentualCerimonialNr}}%</strong> (<strong>{{percentualCerimonialExtenso}}</strong>) aplicados sobre o valor total arrecadado dos alunos, acrescidos dos rendimentos auferidos nas aplicações financeiras ao longo da vigência do contrato.
<br>
<br>
<strong>Parágrafo Primeiro.</strong> Fica assegurado o valor mínimo <strong>{{orcamento?.assessoriaCerimonial | currency: 'BRL'}}</strong> (<strong>{{assessoriaCerimonialExtenso}}</strong>) de taxa de Administração à CONTRATADA, independentemente da diminuição da arrecadação ocasionados por inadimplemento ou desistência de alunos aderentes a Associação de Alunos.
<br>
<br>
<strong>Parágrafo Segundo.</strong> O pagamento da Taxa de Administração constante no Parágrafo Primeiro dessa Cláusula, será feito da seguinte forma: <strong><span *ngFor="let parcela of arrayPgtosAssessoria, let i = index">{{parcela.vlr | currency:'BRL'}} dia {{parcela.data}}<span *ngIf="i != 2">,</span><span *ngIf="i+1 == arrayPgtosAssessoria.length">.</span>&nbsp;</span></strong>
<br>
<br>
<strong>Clausula 21ª.</strong> Caso ocorra desistências ou transferências de formandos, o valor total deste contrato não sofrerá modificação, tendo em vista que a <strong>Descontei App Ltda.</strong> deverá dispor do valor mínimo estipulado de <strong>{{orcamento?.totalSomas | currency:'BRL'}}</strong> (<strong>{{valorPorExtenso}}</strong>), da <strong>Associação de Alunos</strong>, correspondente ao custo total dos eventos de formatura, o que implicará em um rateio com os demais formandos remanescentes dos valores referentes a somatória dos valores devidos aos alunos desistentes ou transferidos.
<br>
<br>
<strong>Parágrafo Primeiro:</strong> A CONTRATADA, no mês de dezembro de <strong>{{anoFim}}</strong>, mês que antecede os eventos de colação de grau, jantar e baile, realizará o fechamento do orçamento do contrato, devendo os valores recebidos corresponderem ao valor estipulado na Cláusula 19ª, acrescidos dos valores adicionais arrecadados dos alunos mais os valores auferidos das aplicações financeiras. Caso o valor recebido seja menor do que o convencionado na cláusula 19ª mesmo com as arrecadações adicionais acrescidos dos valores recebidos das aplicações financeiras, os formandos aderentes que ficaram, deverão fazer a complementação dos valores faltantes, a fim de que seja mantido o equilíbrio financeiro constante na Cláusula 2ª deste contrato.
<br>
<br>
<strong>Parágrafo Segundo.</strong> Em caso de recusa do complemento dos valores estabelecido no Parágrafo Primeiro dessa Cláusula, e não sendo de interesse da CONTRATADA em cobrar a diferença, poderá a mesma realizar o evento em condições compatíveis com os valores recebidos, em concordância com a Diretoria da Associação de Alunos.
<br>
<br>
<strong>Cláusula 22ª.</strong> Tendo em vista que o contrato é de realização futura, ou seja, a execução dos serviços e as contratações de profissionais e fornecedores (decoração, buffet, banda, local do evento, etc.) só se efetivarão no período que compreende a assinatura do presente contrato e a data do jantar/baile, as partes pactuam que eventuais correções nos preços dos serviços de profissionais fornecedores contratados, poderá acarretar um acréscimo nos custos totais dos eventos de formatura, cujos valores serão apurados somente no ano da referida formatura e dos respectivos pagamentos. 
<br>
<br>
<u>V – DO INADIMPLEMENTO, DO CUMPRIMENTO, DA RESCISÃO E DA MULTA</u>
<br>
<br>
<strong>Cláusula 23ª.</strong> Em caso de rescisão integral deste contrato de prestação de serviço por iniciativa da CONTRATANTE, fica convencionado que esses deverão ressarcir integralmente a CONTRATADA de todos os valores e serviços por ela já contratados com terceiros (local de realização do evento, decoração, buffet, brindes, entre outros) devidamente corrigidos até a data da rescisão, sem prejuízo da multa convencionada, bem como, se resguarda no direito de cobrar eventuais perdas e danos e lucros cessantes. sendo que todos os formandos aderentes a <strong> {{contrato.nomeComissao}}</strong>, responderão solidariamente. 
<br>
<br>
<strong>Parágrafo Primeiro.</strong> Em havendo interesse de rescisão do presente contrato pela CONTRATANTE, os valores devidos aos fornecedores e profissionais contratados deverão ser pagos no ato do pedido de rescisão, sob pena da CONTRATADA ingressar em juízo com a ação competente, acarretando outras despesas como honorários advocatícios, custas processuais e demais consectários legais.
<br>
<br>
<strong>Parágrafo Segundo.</strong> No caso de rescisão do presente contrato, além do pagamento integral da taxa de administração que cabe a CONTRATADA, dos pagamentos devidos aos fornecedores e profissionais contratados pela Descontei App Ltda., fica ainda estabelecida multa contratual, de 30% (trinta por cento) sobre o valor total do contrato estabelecido na Cláusula 19ª a ser pago a CONTRATADA.
<br>
<br>
<strong>Parágrafo Terceiro.</strong> Os percentuais e valores supracitados foram estabelecidos levando-se em consideração que a desistência da CONTRATANTE, não há exime do pagamento dos fornecedores e profissionais contratados pela CONTRATADA para execução do objeto do presente contrato, bem como implica também na indenização por danos morais e de imagem junto a CONTRATADA que está fazendo o planejamento, organização e realização de eventos de formatura <strong> {{contrato.nomeComissao}}</strong>.
<br>
<br>
<strong>Parágrafo Quarto.</strong> O presente contrato tem reflexo direto dos contratos individuais entre a Associação de Alunos e os Formandos, que deverão quitar seus valores individuais contratados junto a Associação de Alunos, sob pena de não o fazendo até 30 dias antecedentes a data da realização do jantar e baile de formatura, deixará o formando impedido de retirar a beca e os convites de entradas da formatura junto a CONTRATADA, e consequente impedimento de participar dos eventos com seus respectivos convidados. 
<br>
<br>
<strong>Cláusula 24ª.</strong> Em caso de cobrança judicial, devem ser acrescidas ainda custas processuais e 20% de honorários advocatícios.
<br>
<br>
<strong>Parágrafo Primeiro.</strong> As taxas decorrentes de cobrança judicial ou extrajudicial para adimplemento do valor ajustado no presente contrato são de responsabilidade da CONTRATANTE.
<br>
<br>
<strong>Cláusula 25ª.</strong> Se a iniciativa de rescisão do presente contrato partir da CONTRATADA, fica esta obrigada a devolução integral dos valores recebidos da CONTRATANTE.
<br>
<br>
<u>VI – DAS DISPOSIÇÕES GERAIS</u>
<br>
<br>
<strong>Cláusula 26ª:</strong> A Contratante é representante legal dos <strong>{{orcamento?.formandos}}</strong> (<strong>{{formandosExtenso}}</strong>) formandos <strong> {{contrato.nomeComissao}}</strong>, sendo que cada formando terá direito proporcional a todos os itens citados na proposta, Anexo I, e serão aderentes ao presente contrato.
<br>
<br>
<strong>Cláusula 27ª.</strong> Para a realização da colação de grau a CONTRATADA fornecerá (a título de empréstimo), beca capelo e canudo, para os formandos aderentes aos eventos de formatura, conforme contrato individual feito entre a Associação de Alunos e Formando, sendo que os formandos aderentes que não utilizarem a beca não poderão ceder a outros formandos que não aderiram a programação dos eventos de formatura.
<br>
<br>
<strong>Parágrafo Primeiro.</strong> As becas, faixas, capelos, canudos deverão ser devolvidos no prazo máximo de 05 dias após a realização da colação de grau, nas mesmas condições que as receberam, ou seja, em perfeito estado de conservação.
<br>
<br>
<strong>Parágrafo Segundo.</strong> Para a entrega das becas, capelos e faixas serão exigidos de cada formando um cheque caução ou promissória no valor de R$ 250,00 (duzentos e cinquenta reais) o qual será devolvido após o retorno dos objetos ora mencionados. Caso não sejam devolvidos no prazo de 05 dias após a colação de grau, fica desde já autorizada a Contratada a cobrar a promissória ou apresentar o cheque ao banco para compensação.
<br>
<br>
<strong>Parágrafo Terceiro.</strong> Em caso de extravio, dano ou outra forma de má utilização dos objetos acima dado em comodato, os contratantes pagarão a Contratada o valor estipulado no parágrafo anterior.
<br>
<br>
<strong>Parágrafo Quarto.</strong> O formando para ter direito ao empréstimo da beca, retirada dos convites para o jantar e baile e participar da cobertura fotográfica de estúdio, deverá ter quitado todos os valores por ele contratados 30 dias antes da data da formatura. Em caso de inadimplência, o formando ficará impedido de retirar a beca e seus convites para o jantar e baile. 
<br>
<br>
<strong>Cláusula 28ª.</strong> Fica acertado que a CONTRATADA tem exclusividade na cobertura e vendas de fotografias, Álbuns e DVDs das imagens registradas nos eventos, os quais serão elaborados pela CONTRATADA em forma de álbum montado e colocados à venda aos formandos através de vendedores, deixando-os disponíveis para aquisição dos formandos por um prazo máximo de 06 (seis) meses, sendo que após este prazo a CONTRATADA não terá mais a responsabilidade de guarda e conservação dos arquivos.
<br>
<br>
<strong>Parágrafo Primeiro.</strong> A CONTRATADA estabelece ainda que não será permitindo o uso de equipamentos profissionais ou que outros profissionais e empresas filmem ou fotografem os eventos, a lei brasileira sobre direitos autorais nº 9610 de 19/02/1998 nos garante a exclusividade, para tanto todas as fotos terão marcas impressas da logomarca da CONTRATADA.
<br>
<br>
<strong>Parágrafo Segundo.</strong>  As fotos serão vendidas pela CONTRATADA a preços da época de comercialização, no sistema de álbum montado.
<br>
<br>
<strong>Parágrafo Terceiro:</strong> As fotografias tiradas nos eventos de formatura são de direitos autorais da CONTRATADA, portanto, todas as imagens terão a logomarca da CONTRATADA, conforme estabelece a Lei de Direitos autorais.
<br>
<br>
<strong>Cláusula 29ª.</strong> O presente instrumento contratual terá sua vigência a contar da assinatura do contrato até o dia do último evento a ser realizado pelos formandos.
<br>
<br>
<strong>Cláusula 30ª.</strong> Havendo danos matérias nos eventos, a CONTRATANTE é responsável pelo ressarcimento dos materiais e equipamentos danificados. Caso haja identificação do respectivo culpado e em caso de danos morais o culpado responderá integralmente pelos seus atos.
<br>
<br>
<strong>Cláusula 31ª.</strong> todas as obrigações deste contrato são exigíveis independentes de quaisquer notificações judiciais e/ou extrajudiciais. 
<br>
<br>
<strong>Cláusula 32ª.</strong> Fica eleito o Foro da Comarca de Cascavel, estado do Paraná, com renúncia de qualquer outro, por mais privilegiado que seja toda a ação que se originar deste Contrato.
E por estarem justas e contratadas, as partes assinam o presente contrato, em duas vias de igual teor na presença de duas testemunhas.
<div class="page-break"></div>
Cascavel-PR, {{contrato.data | date:'d MMMM y' }}
<br><br>
<div class="row">
    <div class="col col-6">
        CONTRATANTE:
    </div>
    <div class="col col-6">
        CONTRATADA:
    </div>
</div>
<div class="row">
    <div class="col col-6" style="text-align: center;">
        <span *ngIf="turma.presidente">
        <br>
        <br>      
        ________________________________________________________<br>
        <strong>{{turma.presidente}}</strong>
        <br>
        Presidente – Associação de Alunos
        <br>
        <br>
        </span>
        <span *ngIf="turma.vicePresidente">
        <br>
        <br>
        ________________________________________________________<br>
        <strong>{{turma.vicePresidente}}</strong>
        <br>
        Vice-Presidente – Associação de Alunos
        <br>
        <br>
        </span>
        <span *ngIf="turma.primeiroSecretario">
        <br>
        <br>
        ________________________________________________________<br>
        <strong>{{turma.primeiroSecretario}}</strong>
        <br>
        1º Secretário(a) – Associação de Alunos
        <br>
        <br>
        </span>
        <span *ngIf="turma.tesoureiro">
        <br>
        <br>
        ________________________________________________________<br>
        <strong>{{turma.tesoureiro}}</strong>
        <br>
        Tesoureiro(a) – Associação de Alunos
        <br>
        <br>
        </span>
        <span *ngIf="turma.primeiroConsFiscal">
        <br>
        <br>
        ________________________________________________________<br>
        <strong>{{turma.primeiroConsFiscal}}</strong>
        <br>
        1º Conselheiro Fiscal(a) – Associação de Alunos
        <br>
        <br>
        </span>
    </div>
    <div class="col col-6" style="text-align: center;">
        <br>
        <br>
        ________________________________________________________<br>
        Anderson Alencar Novachinski
        <br>
        Diretor Comercial
    </div>
  </div>
<br><br>
<div class="row">
    <div class="col col-6">
        TESTEMUNHAS:
        <br>  
        <br>
        <br>    
        ________________________________________________________<br>
        Nome:
        <br>
        CPF:
    </div>
    <div class="col col-6">
        <br>
        <br>   
        <br>   
        ________________________________________________________<br>
        Nome:
        <br>
        CPF:
    </div>
  </div>			            
</div>
<!-- FIM CONTRATO --> 
<div class="page-break"></div>
<div style="padding-left: 5em; padding-right: 1.5em">
    <div style="width: 100%; height: 300px; text-align: center; padding-top: 80px">
        <img src="../assets/img/logo.png">
    </div>
<br>
<br> 
<div style="padding-left:60px">Nº da Proposta: {{orcamento?.nProposta}}</div>
<br>
<div class="capaOrcamento">
    <div class="row">
    <div class="col" style="color:rgb(122, 37, 156); text-align: center">CURSO</div>
    <div class="col" style="color:rgb(122, 37, 156); text-align: center">TURMA</div>
    <div class="col" style="color:rgb(122, 37, 156); text-align: center">ANO</div>
    <div class="col" style="color:rgb(122, 37, 156); text-align: center">PERIODO</div>
    <div class="col" style="color:rgb(122, 37, 156); text-align: center">INSTITUIÇÃO</div>
    </div>
    <div class="row" *ngFor="let turma of orcamento?.arrayTurmas">
    <div class="col" style="text-align: center">{{ turma.curso }}</div>
    <div class="col" style="text-align: center">{{ turma.turma }}</div>
    <div class="col" style="text-align: center">{{ turma.anoTurma }}</div>
    <div class="col" style="text-align: center">{{ turma.periodoTurma }}</div>
    <div class="col" style="text-align: center">{{ turma.instituicao }}</div>
    </div>
</div>
<br>
<div class="capaOrcamento">
    <div class="row">
        <div class="col" style="color:rgb(122, 37, 156)">Periodo</div>
        <div class="col" style="color:rgb(122, 37, 156)">Prestações</div>
        <div class="col" style="color:rgb(122, 37, 156)">Formandos</div>
        <div class="col" style="color:rgb(122, 37, 156)">Dias de Evento</div>
        </div>
        <div class="row">
        <div class="col">{{ orcamento?.dtInicio | date:'dd/MM/yyyy'}} a {{orcamento?.dtFim | date:'dd/MM/yyyy'}}</div>
        <div class="col">{{ orcamento?.parcelas }}</div>
        <div class="col">{{ orcamento?.formandos }}</div>
        <div class="col">{{ orcamento?.diasEvento }}</div>
        </div>
        <div class="row">
        <div class="col" style="color:rgb(122, 37, 156)">Local do Jantar Dançante</div>
        <div class="col" style="color:rgb(122, 37, 156)">Local do Baile de Gala</div>
        <div class="col" style="color:rgb(122, 37, 156)">Cidade</div>
        </div>
        <div class="row">
        <div class="col">{{orcamento?.localJantar}}</div>
        <div class="col">{{orcamento?.localBaile}}</div>
        <div class="col">{{orcamento?.cidade}}</div>
        </div>
    </div>
    <br>
    <br>
<hr>
<!-- FIM CABEÇALHO -->
<img src="../../../assets/img/tag-pre-eventos.png" style="width: 100%">
    <div *ngFor="let array of orcamento?.aulaSaudade; let odd = odd">
    <div class="row" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px;">
        <div class="col col-3" style="text-align: justify">{{array?.titulo}}</div>
        <div class="col col-5" style="text-align: justify">{{array?.descricao}}</div>
        <div class="col col-2" style="text-align: right">{{array?.subTotal | currency:'BRL' }}</div>
        <div class="col col-2" style="text-align: right">{{array?.valor | currency:'BRL' }}</div>
    </div>
    </div>
<div class="row" style="margin-top: 10px">
<div class="col" style="text-align: right"><strong>Total: {{ orcamento?.totalAulaSaudade | currency:'BRL' }}</strong></div>
</div>
<br>
<img src="../../../assets/img/tag-culto.png" style="width: 100%">
<div *ngFor="let array of orcamento?.missa; let odd = odd">
    <div class="row" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px;">
    <div class="col col-3" style="text-align: justify">{{array?.titulo}}</div>
    <div class="col col-5" style="text-align: justify">{{array?.descricao}}</div>
    <div class="col col-2" style="text-align: right">{{array?.subTotal | currency:'BRL' }}</div>
    <div class="col col-2" style="text-align: right">{{array?.valor | currency:'BRL'}}</div>
    </div>
</div>
<div class="row" style="margin-top: 10px">
    <div class="col" style="text-align: right"><strong>Total: {{ orcamento?.totalMissa | currency:'BRL' }}</strong></div>
</div>
<br>
<img src="../../../assets/img/tag-colacao.png" style="margin-left:10px; width: 100%">
<div *ngFor="let array of orcamento?.colacao; let odd = odd">
<div class="row" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px;">
    <div class="col col-3" style="text-align: justify">{{array?.titulo}}</div>
    <div class="col col-5" style="text-align: justify">{{array?.descricao}}</div>
    <div class="col col-2" style="text-align: right">{{array?.subTotal | currency:'BRL' }}</div>
    <div class="col col-2" style="text-align: right">{{array?.valor | currency:'BRL'}}</div>
</div>
</div>
<div class="row" style="margin-top: 10px">
<div class="col" style="text-align: right"><strong>Total: {{ orcamento?.totalColacao | currency:'BRL' }}</strong></div>
</div>
<br>
<img src="../../../assets/img/tag-jantar.png" style="margin-left:10px; width: 100%">
<div *ngFor="let array of orcamento?.jantar; let odd = odd">
<div class="row" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px;">
    <div class="col col-3" style="text-align: justify">{{array?.titulo}}</div>
    <div class="col col-5" style="text-align: justify">{{array?.descricao}}</div>
    <div class="col col-2" style="text-align: right">{{array?.subTotal | currency:'BRL' }}</div>
    <div class="col col-2" style="text-align: right">{{array?.valor | currency:'BRL'}}</div>
</div>
</div>
<div class="row" style="margin-top: 10px">
    <div class="col" style="text-align: right"><strong>Total: {{ orcamento?.totalJantar | currency:'BRL' }}</strong></div>
</div>
<br>
<img src="../../../assets/img/tag-baile.png" style="margin-left:10px; width: 100%">
<div *ngFor="let array of orcamento?.baile; let odd = odd">
<div class="row" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px;">
    <div class="col col-3"  style="text-align: justify">{{array?.titulo}}</div>
    <div class="col col-5"  style="text-align: justify">{{array?.descricao}}</div>
    <div class="col col-2" style="text-align: right">{{array?.subTotal | currency:'BRL' }}</div>
    <div class="col col-2" style="text-align: right">{{array?.valor | currency:'BRL'}}</div>
</div>
</div>
<div class="row" style="margin-top: 10px">
    <div class="col" style="text-align: right"><strong>Total: {{ orcamento?.totalBaile | currency:'BRL' }}</strong></div>
</div>
<br>
<img src="../../../assets/img/tag-assessoria.png" style="margin-left:10px; width: 100%">
<div>
<div class="row" style="padding: 5px;">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Assessoria e Cerimonial</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right"></div>
</div>
<div class="row" style="padding: 5px; background-color: rgb(240, 240, 240)">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Administrativo</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right"></div>
</div>
<div class="row" style="padding: 5px;">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Juridico</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right"></div>
</div>
<div class="row" style="padding: 5px; background-color: rgb(240, 240, 240)">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Cobrança</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right"></div>
</div>
</div>
<div class="row" style="margin-top: 10px">
    <div class="col" style="text-align: right"><strong>Total: {{ orcamento?.assessoriaCerimonial | currency:'BRL' }}</strong></div>
</div>
<br>
<img src="../../../assets/img/tag-foto-filmagem.png" style="margin-left:10px; width: 100%">
<div style="padding-left: 25px">
    <div class="row" style="padding: 5px; padding-left:25px">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Fotografia 22x30</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right">R$ 19,00</div>
    </div>
    <div class="row" style="padding: 5px; padding-left:25px; background-color: rgb(240, 240, 240)">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Fotografia 24x30</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right">R$ 21,00</div>
    </div>
    <div class="row" style="padding: 5px; padding-left:25px">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Fotografia 25x36</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right">R$ 21,00</div>
    </div>
    <div class="row" style="padding: 5px; padding-left:25px; background-color: rgb(240, 240, 240)">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Fotografia 30x40</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right">R$ 24,00</div>
    </div>
</div>
<div class="page-break"></div>
<!-- INICIO PLANOS DE CONVITES -->
<img src="../../../assets/img/tag-convites.png" style="margin-left:10px; width: 100%">
    <br>
<table class="table tableConvites table-striped" style="font-size: 0.95em; line-height: 1em; padding-left: 25px">
    <thead class="bgRoxo">
    <tr>
        <td></td>
        <td style="text-align: center"><strong>PARA O BAILE 10</strong></td>
        <td style="text-align: center"><strong>PARA O BAILE 16</strong></td>
        <td style="text-align: center"><strong>PARA O BAILE 20</strong></td>
    </tr>
    </thead>
    <tbody>
    <tr>
        <td><strong>PARA O JANTAR 8 CONVITES +</strong></td>
        <td style="text-align: center">{{ orcamento?.convite8jantar10baile | currency:'BRL'}}</td>
        <td style="text-align: center">{{ orcamento?.convite8jantar16baile | currency:'BRL'}}</td>
        <td style="text-align: center">{{ orcamento?.convite8jantar20baile | currency:'BRL'}}</td>
    </tr>
    <tr>
        <td>Total Por Formando</td>
        <td style="text-align: center">{{ orcamento?.convite8jantar10bailePorFormando | currency:'BRL'}}</td>
        <td style="text-align: center">{{ orcamento?.convite8jantar16bailePorFormando | currency:'BRL'}}</td>
        <td style="text-align: center">{{ orcamento?.convite8jantar20bailePorFormando | currency:'BRL'}}</td>
    </tr>
    <tr>
        <td>Valor da Parcela</td>
        <td style="text-align: center; font-weight: 700">{{ orcamento?.convite8jantar10baileVlrParcela | currency:'BRL'}}</td>
        <td style="text-align: center; font-weight: 700">{{ orcamento?.convite8jantar16baileVlrParcela | currency:'BRL'}}</td>
        <td style="text-align: center; font-weight: 700">{{ orcamento?.convite8jantar20baileVlrParcela | currency:'BRL'}}</td>
    </tr>
    </tbody>
    </table>
    <br>
    <table class="table tableConvites table-striped" style="font-size: 0.95em; line-height: 1em; padding-left: 25px">
    <thead class="bgRoxo">
        <tr>
            <td></td>
            <td style="text-align: center"><strong>PARA O BAILE 10</strong></td>
            <td style="text-align: center"><strong>PARA O BAILE 16</strong></td>
            <td style="text-align: center"><strong>PARA O BAILE 20</strong></td>
        </tr>
        </thead>
        <tbody>
    <tr>
        <td><strong>PARA O JANTAR 10 CONVITES +</strong></td>
        <td style="text-align: center">{{ orcamento?.convite10jantar10baile | currency:'BRL'}}</td>
        <td style="text-align: center">{{ orcamento?.convite10jantar16baile | currency:'BRL'}}</td>
        <td style="text-align: center">{{ orcamento?.convite10jantar20baile | currency:'BRL'}}</td>
    </tr>
    <tr>
        <td>Total Por Formando</td>
        <td style="text-align: center">{{ orcamento?.convite10jantar10bailePorFormando | currency:'BRL'}}</td>
        <td style="text-align: center">{{ orcamento?.convite10jantar16bailePorFormando | currency:'BRL'}}</td>
        <td style="text-align: center">{{ orcamento?.convite10jantar20bailePorFormando | currency:'BRL'}}</td>
    </tr>
    <tr>
        <td>Valor da Parcela</td>
        <td style="text-align: center; font-weight: 700">{{ orcamento?.convite10jantar10baileVlrParcela | currency:'BRL'}}</td>
        <td style="text-align: center; font-weight: 700">{{ orcamento?.convite10jantar16baileVlrParcela | currency:'BRL'}}</td>
        <td style="text-align: center; font-weight: 700">{{ orcamento?.convite10jantar20baileVlrParcela | currency:'BRL'}}</td>
    </tr>
    </tbody>
</table>
<br>
<table class="table tableConvites table-striped" style="font-size: 0.95em; line-height: 1em; padding-left: 25px">
    <thead class="bgRoxo">
        <tr>
        <td></td>
        <td style="text-align: center"><strong>PARA O BAILE 10</strong></td>
        <td style="text-align: center"><strong>PARA O BAILE 16</strong></td>
        <td style="text-align: center"><strong>PARA O BAILE 20</strong></td>
        </tr>
    </thead>
    <tbody>
    <tr>
        <td><strong>PARA O JANTAR 16 CONVITES +</strong></td>
        <td style="text-align: center">{{ orcamento?.convite16jantar10baile | currency:'BRL'}}</td>
        <td style="text-align: center">{{ orcamento?.convite16jantar16baile | currency:'BRL'}}</td>
        <td style="text-align: center">{{ orcamento?.convite16jantar20baile | currency:'BRL'}}</td>
    </tr>
    <tr>
        <td>Total Por Formando</td>
        <td style="text-align: center">{{ orcamento?.convite16jantar10bailePorFormando | currency:'BRL'}}</td>
        <td style="text-align: center">{{ orcamento?.convite16jantar16bailePorFormando | currency:'BRL'}}</td>
        <td style="text-align: center">{{ orcamento?.convite16jantar20bailePorFormando | currency:'BRL'}}</td>
    </tr>
    <tr>
        <td>Valor da Parcela</td>
        <td style="text-align: center; font-weight: 700">{{ orcamento?.convite16jantar10baileVlrParcela | currency:'BRL'}}</td>
        <td style="text-align: center; font-weight: 700">{{ orcamento?.convite16jantar16baileVlrParcela | currency:'BRL'}}</td>
        <td style="text-align: center; font-weight: 700">{{ orcamento?.convite16jantar20baileVlrParcela | currency:'BRL'}}</td>
    </tr>
    </tbody>

</table>
<br>
<table class="table tableConvites table-striped" style="font-size: 0.95em; line-height: 1em; padding-left: 25px">
    <thead class="bgRoxo">
        <tr>
        <td></td>
        <td style="text-align: center"><strong>PARA O BAILE 10</strong></td>
        <td style="text-align: center"><strong>PARA O BAILE 16</strong></td>
        <td style="text-align: center"><strong>PARA O BAILE 20</strong></td>
        </tr>
    </thead>
    <tbody>
    <tr>
        <td><strong>PARA O JANTAR 20 CONVITES +</strong></td>
        <td style="text-align: center">{{ orcamento?.convite20jantar10baile | currency:'BRL'}}</td>
        <td style="text-align: center">{{ orcamento?.convite20jantar16baile | currency:'BRL'}}</td>
        <td style="text-align: center">{{ orcamento?.convite20jantar20baile | currency:'BRL'}}</td>
    </tr>
    <tr>
        <td>Total Por Formando</td>
        <td style="text-align: center">{{ orcamento?.convite20jantar10bailePorFormando | currency:'BRL'}}</td>
        <td style="text-align: center">{{ orcamento?.convite20jantar16bailePorFormando | currency:'BRL'}}</td>
        <td style="text-align: center">{{ orcamento?.convite20jantar20bailePorFormando | currency:'BRL'}}</td>
    </tr>
    <tr>
        <td>Valor da Parcela</td>
        <td style="text-align: center; font-weight: 700">{{ orcamento?.convite20jantar10baileVlrParcela | currency:'BRL'}}</td>
        <td style="text-align: center; font-weight: 700">{{ orcamento?.convite20jantar16baileVlrParcela | currency:'BRL'}}</td>
        <td style="text-align: center; font-weight: 700">{{ orcamento?.convite20jantar20baileVlrParcela | currency:'BRL'}}</td>
    </tr>
    </tbody>
    </table>
</div>
<!-- FIM DO ANEXO I - ORCAMENTO -->
<div class="page-break"></div>
<!-- EXCLUSIVIDADE -->
<div class="contrato">
<h5>CONTRATO PARTICULAR DE EXCLUSIVIDADE PARA COBERTURA FOTOGRÁFICA E GRAVAÇÃO EM VÍDEO</h5>
<br>
<br>
Pelo presente instrumento particular de CONTRATO DE EXCLUSIVIDADE PARA COBERTURA FOTOGRÁFICA E GRAVAÇÃO EM VÍDEO, fazem entre si, de um lado  Descontei App Ltda., inscrita no CNPJ/MF sob o nº 30.538.802/0001-10, com sede e foro na Rua Marechal Deodoro, 3353, cidade de Cascavel, estado do Paraná, sendo representada oficialmente neste instrumento pelo Sr. Anderson Alencar Novachinski, neste ato denominada simplesmente CONTRATADA. E de outro lado, doravante denominada simplesmente CONTRATANTE a COMISSÃO DE FORMATURA do:
<br><br>
<strong>CURSO:</strong> <strong *ngFor="let turma of orcamento?.arrayTurmas">{{turma.curso}}</strong> 
<br><br>
<strong>ANO DE CONCLUSÃO:</strong> FINAL DE <strong>{{anoFim}}</strong>
<br><br>
<strong>INSTITUIÇÃO:</strong> <strong>{{orcamento?.instituicao}}</strong>
<br><br>
<strong>CIDADE:</strong> <strong>{{orcamento?.cidade}}</strong>-PR
<br><br>
<strong>COM FORMATURA PREVISTA PARA</strong>: JANEIRO DE <strong>{{anoFim+1}}</strong>
<br><br>
Sendo representada neste instrumento pelos seus membros, que seguem:
<br><br>
<span *ngIf="turma.presidente">NOME: <strong>{{turma.presidente}}, Presidente, </strong> portador do CPF n° <strong>{{turma.presidenteCpf}}</strong>, e RG n° <strong>{{turma.presidenteRg}}</strong>, <strong>{{turma.presidenteRgOrgao}}</strong>, <strong>{{turma.presidenteNacionalidade}}</strong>, <strong>{{turma.presidenteEstadoCivil}}</strong>, maior, residente e domiciliada na <strong>{{turma.presidenteEndereco}}</strong>
<br><br></span>
<span *ngIf="turma.vicePresidente">NOME: <strong>{{turma.vicePresidente}},  Vice-presidente, </strong> portador do CPF n° <strong>{{turma.vicePresidenteCpf}}</strong>, e RG n° <strong>{{turma.vicePresidenteRg}}</strong>, <strong>{{turma.vicePresidenteRgOrgao}}</strong>, <strong>{{turma.vicePresidenteNacionalidade}}</strong>, <strong>{{turma.vicePresidenteEstadoCivil}}</strong>, maior, residente e domiciliada na <strong>{{turma.vicePresidenteEndereco}}</strong>
<br><br></span>
<span *ngIf="turma.primeiroSecretario">NOME: <strong>{{turma.primeiroSecretario}}, 1º Secretário(a), </strong> portador do CPF n° <strong>{{turma.primeiroSecretarioCpf}}</strong>, e RG n° <strong>{{turma.primeiroSecretarioRg}}</strong>, <strong>{{turma.primeiroSecretarioRgOrgao}}</strong>, <strong>{{turma.primeiroSecretarioNacionalidade}}</strong>, <strong>{{turma.primeiroSecretarioEstadoCivil}}</strong>, maior, residente e domiciliada na <strong>{{turma.primeiroSecretarioEndereco}}</strong>
<br><br></span>
<span *ngIf="turma.segundoSecretario">NOME: <strong>{{turma.segundoSecretario}}, 2º Secratário(a), </strong> portador do CPF n° <strong>{{turma.segundoSecretarioCpf}}</strong>, e RG n° <strong>{{turma.segundoSecretarioRg}}</strong>, <strong>{{turma.segundoSecretarioRgOrgao}}</strong>, <strong>{{turma.segundoSecretarioNacionalidade}}</strong>, <strong>{{turma.segundoSecretarioEstadoCivil}}</strong>, maior, residente e domiciliada na <strong>{{turma.segundoSecretarioEndereco}}</strong> 
<br><br></span>
<span *ngIf="turma.terceiroSecretario">NOME: <strong>{{turma.terceiroSecretario}}, 3º Secratário(a), </strong> portador do CPF n° <strong>{{turma.terceiroSecretarioCpf}}</strong>, e RG n° <strong>{{turma.terceiroSecretarioRg}}</strong>, <strong>{{turma.terceiroSecretarioRgOrgao}}</strong>, <strong>{{turma.terceiroSecretarioNacionalidade}}</strong>, <strong>{{turma.terceiroSecretarioEstadoCivil}}</strong>, maior, residente e domiciliada na <strong>{{turma.terceiroSecretarioEndereco}}</strong>
<br><br></span>
<span *ngIf="turma.tesoureiro">NOME: <strong>{{turma.tesoureiro}}, Tesoureiro(a), </strong> portador do CPF n° <strong>{{turma.tesoureiroCpf}}</strong>, e RG n° <strong>{{turma.tesoureiroRg}}</strong>, <strong>{{turma.tesoureiroRgOrgao}}</strong>, <strong>{{turma.tesoureiroNacionalidade}}</strong>, <strong>{{turma.tesoureiroEstadoCivil}}</strong>, maior, residente e domiciliada na <strong>{{turma.tesoureiroEndereco}}</strong> 
<br><br></span>
<span *ngIf="turma.primeiroConsFiscal">NOME: <strong>{{turma.primeiroConsFiscal}}, 1º Conselheiro(a) Fiscal, </strong> portador do CPF n° <strong>{{turma.primeiroConsFiscalCpf}}</strong>, e RG n° <strong>{{turma.primeiroConsFiscalRg}}</strong>, <strong>{{turma.primeiroConsFiscalRgOrgao}}</strong>, <strong>{{turma.primeiroConsFiscalNacionalidade}}</strong>, <strong>{{turma.primeiroConsFiscalEstadoCivil}}</strong>, maior, residente e domiciliada na <strong>{{turma.primeiroConsFiscalEndereco}}</strong>  
<br><br></span>
<span *ngIf="turma.segundoConsFiscal">NOME: <strong>{{turma.segundoConsFiscal}}, 2º Conselheiro(a) Fiscal, </strong> portador do CPF n° <strong>{{turma.segundoConsFiscalCpf}}</strong>, e RG n° <strong>{{turma.segundoConsFiscalRg}}</strong>, <strong>{{turma.segundoConsFiscalRgOrgao}}</strong>, <strong>{{turma.segundoConsFiscalNacionalidade}}</strong>, <strong>{{turma.segundoConsFiscalEstadoCivil}}</strong>, maior, residente e domiciliada na <strong>{{turma.segundoConsFiscalEndereco}}</strong>  
<br><br></span>
<span *ngIf="turma.terceiroConsFiscal">NOME: <strong>{{turma.terceiroConsFiscal}}, 3º Conselheiro(a) Fiscal, </strong> portador do CPF n° <strong>{{turma.terceiroConsFiscalCpf}}</strong>, e RG n° <strong>{{turma.terceiroConsFiscalRg}}</strong>, <strong>{{turma.terceiroConsFiscalRgOrgao}}</strong>, <strong>{{turma.terceiroConsFiscalNacionalidade}}</strong>, <strong>{{turma.terceiroConsFiscalEstadoCivil}}</strong>, maior, residente e domiciliada na <strong>{{turma.terceiroConsFiscalEndereco}}</strong>  
<br><br></span>
Os membros acima identificados, maiores e capazes, se declaram legítimos representantes, eleitos e outorgados pela maioria dos alunos, e resolvem por este instrumento particular de contrato, contratar, os serviços profissionais da CONTRATADA para a cobertura fotográfica e gravação em vídeo dos eventos e solenidades que compreendem o processo de formatura. E combinam tudo mediante condições que seguem.
<br><br>
<strong>CLÁUSULA PRIMEIRA: DO OBJETO</strong>
<br><br>
<strong>1.1.</strong> Os eventos principais que compreendem à formatura do curso em questão serão: <strong *ngFor="let item of arrayItensContratados">{{item}}, </strong> cujas datas, locais e horários definitivos deverão ser informados oficialmente pelos CONTRATANTES à CONTRATADA. O mesmo procedimento deve ser seguido para os PRÉ-EVENTOS (encontros anteriores às festas de formatura), cujo atendimento da CONTRATADA será feito somente àqueles onde estarão presentes no mínimo 50% dos alunos deste curso. 
<br><br>
<strong>1.2.</strong> Os CONTRATANTES declaram que o número mínimo de formandos participantes dos eventos (Baile e/ou Jantar) referentes à formatura são de <strong>{{orcamento?.formandos}}</strong> formandos.                                                                                                                                                                                                      
<br><br>
<strong>1.3.</strong> Os itens que compreendem a cobertura de Fotografia e gravação em Vídeo são: ÁLBUNS, FOTOGRAFIAS e DVD’S que serão elaborados pela CONTRATADA e colocados à venda aos formandos.                                                                                                                                
<br><br>
<strong>1.4.</strong> Fica ajustado entre as partes que as fotografias terão o tamanho mínimo de 24 X 30 cm. 
<br><br>
<strong>1.5.</strong> O serviço de gravação em vídeo ora oferecidos será comum a todos, com personalização apenas de alguns momentos especiais onde se destaca o formando. Importante: Não se trata de uma edição exclusiva, pois conterá predominantemente imagens gerais do cerimonial, e individuais aleatórias de todos os formandos participantes, e não conterá todas as imagens de todos os formandos de quaisquer momentos específicos. 
<br><br>
<strong>1.6.</strong> Por se tratar de um trabalho de teor artístico, a CONTRATADA estará aplicando controle de qualidade nas imagens colhidas, fazendo pré-seleção e desclassificação, quando estas estiverem fora da qualidade desejada, podendo descartar e não disponibilizar materiais inapropriados ou inadequados. 
<br><br>
<strong>CLÁUSULA SEGUNDA: DAS COMUNICAÇÕES</strong>                                                                                                                                                                 
<br><br>
<strong>2.1.</strong> Para os eventos correlacionados à formatura, os CONTRATANTES precisam informar a CONTRATADA com antecedência mínima de 60 (SESSENTA) dias, às respectivas realizações, através de meios oficiais como: e-mail ou carta/ofício, devendo ter sempre a comprovação de recebimento pela CONTRATADA.     
<br><br>
<strong>2.2.</strong> Para as realizações de PRÉ-EVENTOS com cobertura de Foto e Vídeo, os CONTRATANTES deverão informar à CONTRATADA o local, data e horário dos mesmos através dos meios oficiais, com antecedência mínima de 10 (dez) dia úteis, sempre com confirmação de recebimento.                                                                                                                                                
<br><br>
<strong>2.3.</strong> Em caso de comunicação com prazo inferior a 10 (DEZ) dias úteis, a CONTRATADA deliberará acerca de cobertura de Foto e Vídeo do PRÉ-EVENTO.
<br><br>
<strong>CLÁUSULA TERCEIRA: DAS OBRIGAÇÕES</strong>  
<br><br>
<strong>3.1.</strong> A CONTRATADA deverá se fazer presente nos eventos relacionados a formatura com no mínimo uma hora de antecedência, e número compatível de profissionais a seu critério, equipados e vestidos de maneira apropriada para cada evento.                                                                                                                                                                                            
<br><br>
<strong>3.2.</strong> A CONTRATADA garante aos formandos o melhor padrão de qualidade disponível pela empresa na produção de fotografias e vídeos.                                                                                                                                                                                        
<br><br>
<strong>3.3.</strong> A cobertura das despesas (diárias, deslocamento e hospedagem) da equipe da CONTRATADA para cobertura dos eventos realizados na cidade da instituição será de responsabilidade da CONTRATADA. Para os eventos fora dos limites do município dos COTRATANTES, essas despesas serão de responsabilidade dos contratantes.  
<br><br>
<strong>3.4.</strong> A CONTRATADA não estará obrigada a disponibilizar equipe e equipamentos para atendimentos individuais de quaisquer formandos ou a fornecer imagens para uso de terceiros, comerciais ou não, ficando isto a seu inteiro critério. 
<br><br>
<strong>3.5.</strong> A cobertura fotográfica profissional e a gravação em vídeo profissional, objetos deste contrato são exclusivamente da CONTRATADA, não sendo permitida a presença de quaisquer fotógrafos e/ou serviços de gravação em vídeo profissionais, nos locais onde a CONTRATADA desempenhará suas funções, sem autorização prévia documentada, sendo que esta somente poderá ser concedida pela diretoria da CONTRATADA. 
<br><br>
<strong>3.6.</strong> Os direitos de propriedades e comercialização, de uso, ou de divulgação, das fotografias e imagens de vídeo originado da execução do presente contrato, serão de exclusividade da CONTRATADA, não podendo ser utilizada para fins comerciais por terceiros sem sua expressa autorização. Importante, ao aderirem ao presente contrato por meio da comissão de formatura, todos os alunos se submetem a presente cláusula, dando total e irrestrita autorização do uso das imagens e vídeos â CONTRATADA.                                                                                                                                                                                                                                                                                                                                                                                
<br><br>
<strong>3.7.</strong> Os CONTRATANTES comprometem-se a fornecer a CONTRATADA, uma lista de alunos participantes da formatura, que terão direito a participar de todos os eventos com antecedência mínima de 90 (noventa) dias do primeiro evento oficial (colação de grau /formatura). 
<br><br>
<strong>CLÁUSULA QUARTA: DA VALIDADE DAS CLÁUSULAS CONTRATUAIS</strong>                                                                                                                           
<br><br>
<strong>4.1.</strong> A eventual declaração de necessidade de revisão de uma determinada deposição deste instrumento não terá efeito sobre a validade e a eficácia das demais disposições.                                                                                                                                                                                             
<br><br>
<strong>CLÁUSULA QUINTA: DAS ALTERAÇÕES CONTRATUAIS</strong>                                                                                                                                                 
<br><br>
<strong>5.1 </strong>Quaisquer alterações do presente contrato somente serão válidas quando feitas por escrito e assinadas pelas PARTES.                                                                                                                                                                                            
<br><br>
<strong>CLÁUSULA SEXTA: PREÇO E FORMA DE PAGAMENTO</strong>                                                                                                                                                     
<br><br>
<strong>6.1.</strong> O preço por fotografia a ser praticado pela CONTRATADA é de R$ 21,00 ( vinte e um reais) para pagamento à vista em até 60 dias a partir da assinatura do presente contrato e após esse período, será o valor atualizado mensalmente pelo índice do IGPM, até data da venda (ou se extinto, pelo índice que o substitua, ou ainda, outro que represente a variação monetária do período). 
<br><br>
<strong>6.2.</strong> As vendas a prazo estarão sujeitas à aprovação de crédito, e serão acrescidas de custo financeiro, conforme apresentado no item (6.1). do presente contrato. 
<br><br>
<strong>6.3.</strong> A CONTRATADA não se responsabiliza pelo arquivamento e disponibilização dos materiais apresentados ao formando após 6 (seis) meses da oferta de venda.
<br><br>
<strong>CLÁUSULA SÉTIMA: DA MULTA CONTRATUAL</strong>	
<br><br>
<strong>7.1.</strong> Fica estabelecido a multa contratual de R$ 1.000,00  (hum mil reais), por aluno aderente a ata de constituição de formatura, devidamente corrigida pelo índice do IGPM, (ou se extinto, pelo índice que o substitua, ou ainda, outro que represente a variação monetária do período, pelo não cumprimento do presente contrato,
<br><br>
<strong>CLÁUSULA OITAVA: DO CONHECIMENTO PRÉVIO</strong>
<br><br>
<strong>8.1.</strong> Ao firmar este contrato, as PARTES declaram ter obtido todas as informações a respeito do seu conteúdo, bem como facultou a sua leitura antecipada, a fim de dar aos interessados pleno conhecimento dos seus termos e condições.                                                                                                                                                                                            
<br><br>
<strong>CLÁUSULA NONA: DO FORO</strong>
<br><br>
<strong>9.1.</strong> Fica eleito o Fórum da Comarca de Cascavel, estado do Paraná, para nele serem dirimidas todas e quaisquer questões oriundas do presente instrumento, sendo que o faltoso pagará todas as despesas e custos que houver no processo.                                                                                                                                                                                             
<br><br>
E, por assim estarem justos e contratados, firmam o presente instrumento, em duas vias de igual teor e forma, comprometendo-se a fazê-lo valer em quaisquer circunstâncias, assinando-o na presença de duas testemunhas maiores e capazes.  
<br>
<br>
<br>                                                                                                                                                                                   
Cascavel-PR, {{contrato.data | date:'d MMMM y' }}
<br>
<br>
<div class="row">
<div class="col col-6" style="text-align: center;">
    <span *ngIf="turma.presidente">
    <br>
    <br>      
    ________________________________________________________<br>
    <strong>{{turma.presidente}}</strong>
    <br>
    Presidente – Associação de Alunos
    <br>
    <br>
    </span>
    <span *ngIf="turma.vicePresidente">
    <br>
    <br>
    ________________________________________________________<br>
    <strong>{{turma.vicePresidente}}</strong>
    <br>
    Vice-Presidente – Associação de Alunos
    <br>
    <br>
    </span>
    <span *ngIf="turma.primeiroSecretario">
    <br>
    <br>
    ________________________________________________________<br>
    <strong>{{turma.primeiroSecretario}}</strong>
    <br>
    1º Secretário(a) – Associação de Alunos
    <br>
    <br>
    </span>
    <span *ngIf="turma.tesoureiro">
    <br>
    <br>
    ________________________________________________________<br>
    <strong>{{turma.tesoureiro}}</strong>
    <br>
    Tesoureiro(a) – Associação de Alunos
    <br>
    <br>
    </span>
    <span *ngIf="turma.primeiroConsFiscal">
    <br>
    <br>
    ________________________________________________________<br>
    <strong>{{turma.primeiroConsFiscal}}</strong>
    <br>
    1º Conselheiro Fiscal(a) – Associação de Alunos
    <br>
    <br>
    </span>
</div>
<div class="col col-6" style="text-align: center;">
    <br>
    <br>
    ________________________________________________________<br>
    Anderson Alencar Novachinski
    <br>
    Diretor Comercial
</div>
</div>                                                                                         
</div>