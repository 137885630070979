import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth-guard';
import { AgendaComponent } from './pages/agenda/agenda.component';
import { ArquivoComponent } from './pages/arquivo/arquivo.component';
import { DetailsContratoComponent } from './pages/details-contrato/details-contrato.component';
import { DetailsFormaturaComponent } from './pages/details-formatura/details-formatura.component';
import { DetailsOrcamentoInfantilComponent } from './pages/details-orcamento-infantil/details-orcamento-infantil.component';
import { DetailsOrcamentoComponent } from './pages/details-orcamento/details-orcamento.component';
import { FormadosComponent } from './pages/formados/formados.component';
import { GaleriaComponent } from './pages/galeria/galeria.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { LogsComponent } from './pages/logs/logs.component';
import { MinhaFormaturaComponent } from './pages/minha-formatura/minha-formatura.component';
import { OrcamentoColegioComponent } from './pages/orcamento-colegio/orcamento-colegio.component';
import { OrcamentoInfantilComponent } from './pages/orcamento-infantil/orcamento-infantil.component';
import { OrcamentoComponent } from './pages/orcamento/orcamento.component';
import { ParametrosColegioComponent } from './pages/parametros-colegio/parametros-colegio.component';
import { ParametrosInfantilComponent } from './pages/parametros-infantil/parametros-infantil.component';
import { ParametrosComponent } from './pages/parametros/parametros.component';
import { TurmasColegioComponent } from './pages/turmas-colegio/turmas-colegio.component';
import { TurmasEscolaComponent } from './pages/turmas-escola/turmas-escola.component';
import { TurmasInfantilComponent } from './pages/turmas-infantil/turmas-infantil.component';
import { TurmasComponent } from './pages/turmas/turmas.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';

const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'orcamento', component: OrcamentoComponent, canActivate: [AuthGuard] },
    { path: 'orcamento-colegio', component: OrcamentoColegioComponent, canActivate: [AuthGuard] },
    { path: 'orcamento-infantil', component: OrcamentoInfantilComponent, canActivate: [AuthGuard] },
    { path: 'details-orcamento/:key', component: DetailsOrcamentoComponent, canActivate: [AuthGuard] },
    { path: 'details-orcamento-infantil/:key', component: DetailsOrcamentoInfantilComponent, canActivate: [AuthGuard] },
    { path: 'details-formatura/:key', component: DetailsFormaturaComponent, canActivate: [AuthGuard] },
    { path: 'details-contrato/:key', component: DetailsContratoComponent, canActivate: [AuthGuard] },
    { path: 'turmas', component: TurmasComponent, canActivate: [AuthGuard] },
    { path: 'turmas/:key', component: TurmasComponent, canActivate: [AuthGuard] },
    { path: 'turmas-infantil', component: TurmasInfantilComponent, canActivate: [AuthGuard] },
    { path: 'turmas-infantil/:key', component: TurmasInfantilComponent, canActivate: [AuthGuard] },
    { path: 'turmas-escola', component: TurmasEscolaComponent, canActivate: [AuthGuard] },
    { path: 'turmas-escola/:key', component: TurmasEscolaComponent, canActivate: [AuthGuard] },
    { path: 'turmas-colegio', component: TurmasColegioComponent, canActivate: [AuthGuard] },
    { path: 'turmas-colegio/:key', component: TurmasColegioComponent, canActivate: [AuthGuard] },
    { path: 'formados', component: FormadosComponent, canActivate: [AuthGuard] },
    { path: 'formados/:key', component: FormadosComponent, canActivate: [AuthGuard] },
    { path: 'galeria', component: GaleriaComponent, canActivate: [AuthGuard] },
    { path: 'arquivo', component: ArquivoComponent, canActivate: [AuthGuard] },
    { path: 'agenda', component: AgendaComponent, canActivate: [AuthGuard] },
    { path: 'minha-formatura', component: MinhaFormaturaComponent, canActivate: [AuthGuard] },
    { path: 'usuarios', component: UsuariosComponent, canActivate: [AuthGuard] },
    { path: 'logs', component: LogsComponent, canActivate: [AuthGuard] },
    { path: 'parametros', component: ParametrosComponent, canActivate: [AuthGuard] },
    { path: 'parametros-colegio', component: ParametrosColegioComponent, canActivate: [AuthGuard] },
    { path: 'parametros-infantil', component: ParametrosInfantilComponent, canActivate: [AuthGuard] }
  ];

  @NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }