import { Component, OnInit } from '@angular/core';
import { DataBaseService } from 'src/app/provider';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-arquivo',
  templateUrl: './arquivo.component.html',
  styleUrls: ['./arquivo.component.css']
})
export class ArquivoComponent implements OnInit {
orcamentos:any = [];
listaCidades:any = [];
dataHoje:any = "";
dataComecoMes:any = "";
panelOpenState:boolean = false;
proposta:any = "";
instituicao:any = "";
curso:any = "";
contato:any = "";
cidade:any = "";
consultor:any = "";

  constructor(public location:Location, public db:DataBaseService, public _router:Router) {
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    let date = new Date();
    this.dataComecoMes = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];
   }

  ngOnInit() {
    this.db.getOrcamentoByData(this.dataComecoMes,this.dataHoje).subscribe((data:any) => this.orcamentos = data.sort((a:any,b:any) => {
      if(a.data > b.data){return -1}
      if(a.data < b.data){return 1}
      if(a.hora > b.hora){return -1}
      if(a.hora < b.hora){return 1}
      return 1;
    }));
    this.db.getCidades().subscribe((data:any) => this.listaCidades = data);
  }

  filterByData(dtInicio:any, dtFim:any){
    let inicio = (new Date(dtInicio)).toISOString().split('T')[0];
    let fim = (new Date(dtFim)).toISOString().split('T')[0];
    this.db.getOrcamentoByData(inicio,fim).subscribe((data:any) => this.orcamentos = data.sort((a:any,b:any) => {
      if(a.data < b.data){return -1}
      if(a.data < b.data){return 1}
      if(a.hora > b.hora){return -1}
      if(a.hora < b.hora){return 1}
      return 1;
    }));
  }

  filterByProposta(key:any){
    this.db.getOrcamentoByProposta(key).subscribe((data:any) => this.orcamentos = data.sort((a:any,b:any) => {
      if(a.data < b.data){return -1}
      if(a.data < b.data){return 1}
      if(a.hora > b.hora){return -1}
      if(a.hora < b.hora){return 1}
      return 1;
    }));
  }

  filterByInstituicao(key:any){
    this.db.getOrcamentoByInstituicao(key).subscribe((data:any) => this.orcamentos = data.sort((a:any,b:any) => {
      if(a.data < b.data){return -1}
      if(a.data < b.data){return 1}
      if(a.hora > b.hora){return -1}
      if(a.hora < b.hora){return 1}
      return 1;
    }));
  }

  filterByCurso(key:any){
    this.db.getOrcamentoByCurso(key).subscribe((data:any) => this.orcamentos = data.sort((a:any,b:any) => {
      if(a.data < b.data){return -1}
      if(a.data < b.data){return 1}
      if(a.hora > b.hora){return -1}
      if(a.hora < b.hora){return 1}
      return 1;
    }));
  }

  filterByContato(key:any){
    this.db.getOrcamentoByContato(key).subscribe((data:any) => this.orcamentos = data.sort((a:any,b:any) => {
      if(a.data < b.data){return -1}
      if(a.data < b.data){return 1}
      if(a.hora > b.hora){return -1}
      if(a.hora < b.hora){return 1}
      return 1;
    }));
  }

  filterByConsultor(key:any){
    this.db.getOrcamentoByConsultor(key).subscribe((data:any) => this.orcamentos = data.sort((a:any,b:any) => {
      if(a.data < b.data){return -1}
      if(a.data < b.data){return 1}
      if(a.hora > b.hora){return -1}
      if(a.hora < b.hora){return 1}
      return 1;
    }));
  }

  filterByCidade(key:any){
    this.db.getOrcamentoByCidade(key).subscribe((data:any) => this.orcamentos = data.sort((a:any,b:any) => {
      if(a.data < b.data){return -1}
      if(a.data < b.data){return 1}
      if(a.hora > b.hora){return -1}
      if(a.hora < b.hora){return 1}
      return 1;
    }));
  }
 
  goTo(tipo:any,key:any){
    if(tipo == "INFANTIL"){
      this._router.navigate(['/details-orcamento-infantil', key])

    }else{
      this._router.navigate(['/details-orcamento', key])
    }
  }

  goToTurma(tipo:any,key:any){
    switch (tipo) {
      case "UNIVERSIDADE":
        this._router.navigate(['/turmas', key])
      break;
      case "COLEGIO":
        this._router.navigate(['/turmas-colegio', key])
      break;
      default:
        this._router.navigate(['/turmas', key])
        break;
    }
  }

  goBack(){
    this.location.back();
   }

}
