<div class="topoLogo no-print">
  <div>
      <img src="../assets/img/logo.png" style="height:90px">
  </div>
</div>  
<div class="back no-print" (click)="goBack()"><fa-icon [icon]="['fas', 'arrow-alt-circle-left']" size="2x"></fa-icon></div>
<mat-horizontal-stepper linear="true" #stepper class="full-width" labelPosition="bottom"> 
  <mat-step [stepControl]="inicio" [editable]="!block">
  <form [formGroup]="inicio">
      <ng-template matStepLabel>Inicio</ng-template>
        <div class="row" style="margin-top: 10px; font-size:1.25em; padding:10px">
          <div class="col col-3">
                <label>Cidade</label>
                <select formControlName="cidadeEscolhida" (change)="selecionarCidade(inicio.get('cidadeEscolhida')?.value)" [ngClass]="{'is-invalid':!inicio.controls['cidadeEscolhida'].valid}" class="form-control">
                  <option [selected] value="">SELECIONAR</option>
                  <option *ngFor="let cidade of listaCidades" [value]="cidade?.nome">
                    {{cidade?.nome}}
                  </option>
                </select>
          </div>
          <div class="col col-6" *ngIf="cidadeCheck">
            <label>Instituição</label>
            <ng-select class="form-control" formControlName="instituicoes" [(ngModel)]="instituicaoInfantil" (change)="selecionarInstituicao()" [items]="listaInstituicoes" bindLabel="nome" appendTo="body" [ngClass]="{'is-invalid':!inicio.controls['instituicoes'].valid}"></ng-select>
          </div>
              <div class="col col-3" *ngIf="instituicaoCheck">
                <label>Curso</label>
                <select formControlName="curso" (change)="selecionarCurso(inicio.get('curso')?.value)" [ngClass]="{'is-invalid':!inicio.controls['curso'].valid}" class="form-control">
                  <option [selected]>SELECIONAR</option>
                  <option *ngFor="let curso of listaCursos" [value]="curso?.nome">
                      {{curso?.nome}}
                    </option>
                  </select>
              </div>
            </div>
            <table class="table table-striped" style="text-align:center" *ngIf="cursoCheck">
              <thead>
                <tr>
                  <th scope="col">Curso</th>
                  <th scope="col">Periodo</th>
                  <th scope="col">Turma</th>
                  <th scope="col">Ano</th>
                  <th scope="col">Sala</th>
                  <th scope="col">Diretor(a)</th>
                  <th scope="col">Situação</th>
                  <th scope="col">Consultor</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let turma of listaTurmas" class="turmasSelect" (click)="turmaSelected($event, turma?._id)">
                  <td>{{turma?.curso}}</td>
                  <td>{{turma?.periodo}}</td>
                  <td>{{turma?.turma}}</td>
                  <td>{{turma?.ano}}</td>
                  <td>{{turma?.sala}}</td>
                  <td>{{turma?.nomeComissao}}</td>
                  <th>{{turma?.situacao}}</th>
                  <td>{{turma?.consultor}}</td>
                </tr>
              </tbody>
            </table> 
      <hr>
      <h4>Turmas Selecionadas</h4>
      <table class="table table-striped" style="text-align:center">
        <thead>
          <tr>
            <th scope="col">Instituição</th>
            <th scope="col">Curso</th>
            <th scope="col">Periodo</th>
            <th scope="col">Turma</th>
            <th scope="col">Ano</th>
            <th scope="col">Diretor(a)</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let turma of arrayTurmas">
            <th>{{turma?.instituicao}}</th>
            <td>{{turma?.curso}}</td>
            <td>{{turma?.periodoTurma}}</td>
            <td>{{turma?.turma}}</td>
            <td>{{turma?.anoTurma}}</td>
            <td>{{turma?.nomeComissao}}</td>
            <td>
              <button class="btn btn-danger" type="button" (click)="removeTurma(turma.turmaId)">
                <fa-icon [icon]="['fas', 'trash']" size="1x"></fa-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table> 
        <div class="row" stepperButtons>
          <div class="col col-3">
            &nbsp;
          </div>
          <div class="col" style="text-align: right;">
            <button class="btn btn-success" (click)="limparTurmas()" [disabled]="listaTurmas.length < 1">Adicionar Outra Turma</button>
          </div>
          <div class="col" style="text-align: left;">
            <button class="btn btn-info" matStepperNext [disabled]="!inicio.valid" (click)="adicionarContatos()">Próximo</button>
          </div>
          <div class="col col-3">
            &nbsp;
          </div>
        </div>
      </form>             
  </mat-step> 
<mat-step [stepControl]="detalhes" [editable]="!block">
  <form [formGroup]="detalhes">
    <ng-template matStepLabel>Dados</ng-template>
      <div class="row" style="padding:10px">
        <div class="col col-2">
          <label>Formandos</label>
          <input type="number" class="form-control" formControlName="qtdeFormandos" [ngClass]="{'is-invalid':!detalhes.controls['qtdeFormandos'].valid}" [readonly]="block">
        </div>
        <div class="col col-3">
          <label>Data Inicio</label>
          <input type="date" class="form-control" formControlName="dataInicio" (change)="diferencaMeses(detalhes.get('dataInicio')?.value, detalhes.get('dataFim')?.value)"  [ngClass]="{'is-invalid':!detalhes.controls['dataInicio'].valid}">
        </div>
        <div class="col col-3">
          <label>Data da Formatura</label>
          <input type="date" class="form-control" formControlName="dataFim" (change)="diferencaMeses(detalhes.get('dataInicio')?.value, detalhes.get('dataFim')?.value)"  [ngClass]="{'is-invalid':!detalhes.controls['dataFim'].valid}">
        </div>
        <div class="col col-2">
          <label>Prestações</label>
          <input type="number" class="form-control" name="prestacoes" [ngModel]="prestacoes" [ngModelOptions]="{standalone: true}" readonly>
        </div>
      </div> 
    <form [formGroup]="detalhes">
    <div class="card-body" formArrayName="contacts">
      <div class="row">
        <div class="col col-12" style="font-size: 0.9em; color:rgb(116, 116, 116); font-weight: 500">Comissão:</div>
      </div>
        <div class="row">
          <div class="col-12" style="font-size: 0.85em" *ngFor="let contact of contactFormGroup.controls; let i = index;">
            <div [formGroupName]="i" class="row" style="align-items: flex-end;">
              <div class="form-group col-4">
                <label>Nome</label>
                <input type="text" class="form-control" formControlName="nome" [ngClass]="{'is-invalid':!detalhes.controls['contacts'].valid}">
              </div>
              <div class="form-group col-3">
                <label>Telefone</label>
                <input type="tel" class="form-control" mask="(00) 00000-0000" formControlName="telefone" [ngClass]="{'is-invalid':!detalhes.controls['contacts'].valid}">
              </div>
              <div class="form-group col-3">
                <label>E-mail</label>
                <input type="email" class="form-control" formControlName="email" [ngClass]="{'is-invalid':!detalhes.controls['contacts'].valid}">
              </div>  
              <div class="form-group col-2 text-right">
                <button class="btn btn-danger" type="button" (click)="removeContact(i)">
                  <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                </button>&nbsp;
                <button class="btn btn-success" type="button" (click)="addContact()">
                  <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>   
    </div>
    <br><br>
    <div class="row">
      <div class="col">
          <button class="btn btn-info" (click)="addContact()">
            <fa-icon [icon]="['fas', 'user-plus']" size="1x"></fa-icon>
            &nbsp;Adicionar Contato
          </button>
          &nbsp;
          <button class="btn btn-success" [disabled]="!detalhes.get('contacts').valid || !detalhes.controls['qtdeFormandos'].valid || block" (click)="salvarContatos()">
            <fa-icon [icon]="['fas', 'save']" size="1x"></fa-icon>
            &nbsp;Salvar Contatos
          </button>
          <span *ngIf="!this.detalhes.get('contacts')?.invalid && this.detalhes.invalid">&nbsp;&nbsp;<fa-icon [icon]="['fas', 'hand-point-left']" size="1x"></fa-icon>&nbsp;<strong>CLIQUE EM SALVAR CONTATOS!</strong></span>
       </div>
    </div>
  </form>
  <hr>
  <div class="stepperButtons">
      <button class="btn btn-dark" matStepperPrevious>Anterior</button>
        &nbsp;&nbsp;
      <button class="btn btn-info" matStepperNext [disabled]="this.detalhes.invalid">Próximo</button>
  </div>
  </form>             
</mat-step>
<mat-step [stepControl]="preEventos">
  <form>
    <ng-template matStepLabel>Pré Eventos</ng-template>
    <!-- SELECT OPTION -->
    <div *ngFor="let item of preEventosSelecionavel">
          <label>{{item?.titulo}}</label>
          <select (change)="aPreEventosSomaSelecionavel($event,item?.posicao)" class="form-control">
            <option selected [value]="['zerar',item?.titulo]"> -- Nenhum -- </option>
            <optgroup *ngFor="let itemOpt of (item?.opcoes | cidadeFilter: cidade)">
             <option [translate]="item?.formando" [value]="[itemOpt?.valor,item?.titulo]" [title]="itemOpt?.descricao">{{itemOpt?.nome}}</option>
             <option disabled style="font-size: 0.8em;">{{itemOpt?.descricao | replace}} </option>
            </optgroup>
          </select>
        <hr>
    </div>
    <!-- CHECKBOX -->
    <div *ngFor="let item of preEventosCheckbox; let i = index" style="font-size:1.2em; padding:5px">
      <div class="custom-checkbox">
        <input class="custom-control-input" *ngFor="let vlr of (item?.valores | cidadeFilter: cidade)" [id]="i+'aulaCheck'" type="checkbox" [required]="item?.formando" [checked]="item?.marcado" [disabled]="item?.marcado && tipoUsuario == 'Vendedor'" [value]="vlr?.valor" (change)="aPreEventosSoma($event, 'nao',item?.posicao)">
          <label class="custom-control-label" [for]="i+'aulaCheck'" style="cursor: pointer;">
            {{item?.titulo}}<br>
            <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{item?.descricao | replace}}</span>
          </label>
      </div>
      <br>
    </div>
      <div class="stepperButtons">
        <button class="btn btn-dark" matStepperPrevious>Anterior</button>
        &nbsp;&nbsp;
        <button class="btn btn-info" matStepperNext>Próximo</button>
      </div>
  </form>             
</mat-step> 
<mat-step [stepControl]="producaoFotografica">
    <form>
      <ng-template matStepLabel>Produção Fotográfica</ng-template>
<!-- SELECT OPTION -->
<div *ngFor="let item of producaoFotograficaSelecionavel">
  <label>{{item?.titulo}}</label>
  <select (change)="aProducaoFotograficaSomaSelecionavel($event,item?.posicao)" class="form-control">
    <option selected [value]="['zerar',item?.titulo]"> -- Nenhum -- </option>
      <optgroup *ngFor="let itemOpt of (item?.opcoes | cidadeFilter: cidade)">
        <option [translate]="item?.formando" [value]="[itemOpt?.valor,item?.titulo]" [title]="itemOpt?.descricao">{{itemOpt?.nome}}</option>
        <option disabled style="font-size: 0.8em;">{{itemOpt?.descricao | replace}} </option>
      </optgroup>
  </select>
<hr>
</div>
<!-- CHECKBOX -->
<div *ngFor="let item of producaoFotograficaCheckbox; let i = index" style="font-size:1.2em; padding:5px">
  <div class="custom-checkbox">
    <input class="custom-control-input" *ngFor="let vlr of (item?.valores | cidadeFilter: cidade)" [id]="i+'colacaoCheck'" type="checkbox" [required]="item?.formando" [checked]="item?.marcado" [disabled]="item?.marcado && tipoUsuario == 'Vendedor'" [value]="vlr?.valor" (change)="aProducaoFotograficaSoma($event, 'nao',item?.posicao)">
      <label class="custom-control-label" [for]="i+'colacaoCheck'" style="cursor: pointer;">
        {{item?.titulo}}<br>
        <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{item?.descricao | replace}}</span>
      </label>
  </div>
  <br>
</div>
      <div class="stepperButtons">
        <button class="btn btn-dark" matStepperPrevious>Anterior</button>
        &nbsp;&nbsp;
        <button class="btn btn-info" matStepperNext>Próximo</button>
      </div>
  </form>             
  </mat-step>
  <mat-step [stepControl]="formaturas">
    <form>
      <ng-template matStepLabel>Formaturas</ng-template>
<!-- SELECT OPTION -->
<div *ngFor="let item of formaturasSelecionavel">
  <label>{{item?.titulo}}</label>
  <select (change)="aFormaturasSomaSelecionavel($event,item?.posicao)" class="form-control">
    <option selected [value]="['zerar',item?.titulo]"> -- Nenhum -- </option>
      <optgroup *ngFor="let itemOpt of (item?.opcoes | cidadeFilter: cidade)">
        <option [translate]="item?.formando" [value]="[itemOpt?.valor,item?.titulo]" [title]="itemOpt?.descricao">{{itemOpt?.nome}}</option>
        <option disabled style="font-size: 0.8em;">{{itemOpt?.descricao | replace}} </option>
      </optgroup>
  </select>
<hr>
</div>
<!-- CHECKBOX -->
<div *ngFor="let item of formaturasCheckbox; let i = index" style="font-size:1.2em; padding:5px">
  <div class="custom-checkbox">
    <input class="custom-control-input" *ngFor="let vlr of (item?.valores | cidadeFilter: cidade)" [id]="i+'colacaoCheck'" type="checkbox" [required]="item?.formando" [checked]="item?.marcado" [disabled]="item?.marcado && tipoUsuario == 'Vendedor'" [value]="vlr?.valor" (change)="aFormaturasSoma($event, 'nao',item?.posicao)">
      <label class="custom-control-label" [for]="i+'colacaoCheck'" style="cursor: pointer;">
        {{item?.titulo}}<br>
        <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{item?.descricao | replace}}</span>
      </label>
  </div>
  <br>
</div>
    <div class="stepperButtons">
      <button class="btn btn-dark" matStepperPrevious>Anterior</button>
      &nbsp;&nbsp;
      <button class="btn btn-info" matStepperNext>Próximo</button>
    </div>
  </form>             
  </mat-step>
  <mat-step [stepControl]="imagens">
      <ng-template matStepLabel>Imagens</ng-template>
      <div class="containerGaleria">
          <div class="img-container" *ngFor="let image of images; let i = index">
            <mat-selection-list (selectionChange)="addImgGaleria($event)">
                <mat-list-option [value]="image.url">Incluir</mat-list-option>
              </mat-selection-list>
              <img [src]="image.url" class="imagemGaleria" (click)="openModal(image.url, i)" />
          </div>
      </div>
      <div id="myModalOrcamentoInf" class="modal">
          <span (click)="closeModal()" class="close">&times;</span>
          <div class="prev" (click)="prevImg()">&lt;</div>
          <img [src]="imagemSelecionada" class="imagemLightbox">
          <div class="next" (click)="nextImg()">&gt;</div>
        </div> 
      <hr>
          <div class="stepperButtons">
            <button class="btn btn-dark" matStepperPrevious>Anterior</button>
            &nbsp;&nbsp;
            <button class="btn btn-info" matStepperNext (click)="salvarOrcamento()">Próximo</button>
          </div> 
  </mat-step>
  <mat-step [stepControl]="financeiro">
    <ng-template matStepLabel>Financeiro</ng-template>
    <div class="print no-print" (click)="print()">
      <fa-icon [icon]="['fas', 'print']" size="2x"></fa-icon>
    </div>
    <!-- INICIO CABEÇALHO -->
    <div style="width: 100%; height: 300px; text-align: center; padding-top: 80px">
        <img src="../assets/img/icone.png" style="height:90px">
    </div>
    <br>
    <br> 
    <div style="padding-left:60px">Nº da Proposta: {{numeroProposta}}</div>
    <br>
    <div class="capaOrcamento">
      <div class="row">
        <div class="col" style="color:rgb(122, 37, 156); text-align: center">CURSO</div>
        <div class="col" style="color:rgb(122, 37, 156); text-align: center">TURMA</div>
        <div class="col" style="color:rgb(122, 37, 156); text-align: center">ANO</div>
        <div class="col" style="color:rgb(122, 37, 156); text-align: center">PERIODO</div>
        <div class="col" style="color:rgb(122, 37, 156); text-align: center">INSTITUIÇÃO</div>
      </div>
      <div class="row" *ngFor="let turma of arrayTurmas">
        <div class="col" style="text-align: center">{{ turma.curso }}</div>
        <div class="col" style="text-align: center">{{ turma.turma }}</div>
        <div class="col" style="text-align: center">{{ turma.anoTurma }}</div>
        <div class="col" style="text-align: center">{{ turma.periodoTurma }}</div>
        <div class="col" style="text-align: center">{{ turma.instituicao }}</div>
      </div>
    </div>
    <br>
    <div class="capaOrcamento">
        <div class="row">
            <div class="col" style="color:rgb(122, 37, 156)">Periodo</div>
            <div class="col" style="color:rgb(122, 37, 156)">Prestações</div>
            <div class="col" style="color:rgb(122, 37, 156)">Formandos</div>
            <div class="col" style="color:rgb(122, 37, 156)">Dias de Evento</div>
          </div>
          <div class="row">
            <div class="col">{{detalhes.get('dataInicio')?.value | date:'dd/MM/yyyy'}} a {{detalhes.get('dataFim')?.value  | date:'dd/MM/yyyy'}}</div>
            <div class="col">{{ prestacoes }}</div>
            <div class="col">{{ detalhes.get('qtdeFormandos')?.value }}</div>
            <div class="col">{{ detalhes.get('diasEvento')?.value }}</div>
          </div>
          <div class="row">
            <div class="col" style="color:rgb(122, 37, 156)">Cidade</div>
          </div>
          <div class="row">
            <div class="col">{{inicio.get('cidadeEscolhida')?.value}}</div>
          </div>
      </div>
      <br>
      <div class="capaOrcamento">    
        <div style="color:rgb(122, 37, 156)">CONTATOS DA COMISSÃO</div>
        <div class="row" *ngFor="let comissao of arrayContatos">
          <div class="col col-4">{{comissao.nome}}</div>
          <div class="col col-4 telefoneComissaoPrint" style="text-align: center">{{comissao?.telefone.slice(0,10) + '-****'}}</div>
          <div class="col col-4 telefoneComissao" style="text-align: center">{{comissao?.telefone }}</div>
          <div class="col col-4" style="text-align: center">{{comissao.email}}</div>
        </div>
      </div>   
      <br>
      <br>
  <hr>
    <!-- FIM CABEÇALHO -->
    <img src="../../../assets/img/tag-pre-eventos.png" style="margin-left:10px; width: 100%">
    <div *ngFor="let array of arrayPreEventos; let odd = odd" style="padding-left:1.5em">
      <div class="row" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px;">
        <div class="col col-3">{{array.titulo}}</div>
        <div class="col">{{array.descricao | replace}}</div>
        <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array.subTotal | currency:'BRL' }}</div>
        <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array.valor | currency:'BRL' }}</div>
      </div>
    </div>
<div class="row" style="margin-top: 10px">
  <div class="col no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'"><strong>Total: {{ preEventosCheckboxSoma + somaPreEventosSelecionavel | currency:'BRL' }}</strong></div>
</div>
<br>
  <img src="../../../assets/img/tag-producao-fotografica.png" style="margin-left:10px; width: 100%">
<div *ngFor="let array of arrayProdFoto; let odd = odd" style="padding-left:1.5em">
  <div class="row" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px;">
    <div class="col col-3">{{array.titulo}}</div>
    <div class="col">{{array.descricao | replace}}</div>
    <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array.subTotal | currency:'BRL' }}</div>
    <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array.valor | currency:'BRL'}}</div>
  </div>
</div>
<div class="row" style="margin-top: 10px">
  <div class="col no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'"><strong>Total: {{ producaoFotograficaCheckboxSoma + somaProducaoFotograficaSelecionavel | currency:'BRL' }}</strong></div>
</div>
<br>
  <img src="../../../assets/img/tag-formaturas.png" style="margin-left:10px; width: 100%">
<div *ngFor="let array of arrayFormaturas; let odd = odd" style="padding-left:1.5em">
  <div class="row" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px;">
    <div class="col col-3">{{array.titulo}}</div>
    <div class="col">{{array.descricao | replace}}</div>
    <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array.subTotal | currency:'BRL' }}</div>
    <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array.valor | currency:'BRL'}}</div>
  </div>
</div>
<div class="row" style="margin-top: 10px">
  <div class="col no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'"><strong>Total: {{ formaturasCheckboxSoma + somaFormaturasSelecionavel | currency:'BRL' }}</strong></div>
</div>
<br>
<img src="../../../assets/img/tag-assessoria.png" style="margin-left:10px; width: 100%">
<div>
  <div class="row" style="padding: 5px; padding-left:1.5em">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Assessoria e Cerimonial</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right"></div>
  </div>
  <div class="row" style="padding: 5px; padding-left:1.5em; background-color: rgb(240, 240, 240)">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Administrativo</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right"></div>
  </div>
  <div class="row" style="padding: 5px; padding-left:1.5em">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Juridico</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right"></div>
  </div>
  <div class="row" style="padding: 5px; padding-left:1.5em; background-color: rgb(240, 240, 240)">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Cobrança</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right"></div>
  </div>
</div>
<div class="row no-print" style="margin-top: 10px" *ngIf="tipoUsuario == 'Administrador'">
    <div class="col no-print" style="text-align: right"><strong>Total: {{ assessoriaCerimonial | currency:'BRL' }}</strong></div>
</div>
<img src="../../../assets/img/tag-foto-filmagem.png" style="margin-left:10px; width: 100%">
<br><br>
<div class="planoFotos">
  <div class="row"style="text-align:center;font-weight: bold;">
    <div class="col">
      Itens inclusos no projeto de formatura.
    </div>
  </div>
  <br>
  <div *ngFor="let foto of fotosPagas; let odd = odd">
    <div class="row"  [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px; padding-left:25px">
      <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">{{foto?.nome}}</div>
      <div class="col" style="font-size: 0.85em">{{foto?.descricao}}</div>
      <div class="col col-2 no-print" style="text-align: right">
        <span *ngIf="tipoUsuario == 'Administrador'">{{foto?.valor | currency:'BRL'}}</span>
      </div>
    </div>
  </div>
</div>
<br>
<div class="planoFotos">
  <div class="row"style="text-align:center;font-weight: bold;">
    <div class="col">
      O material fotográfico será comercializado em até 120 dias após o ultimo evento!<br> O formando não tem obrigatoriedade de compra.
    </div>
  </div>
  <br>
  <div *ngFor="let foto of fotosGratis; let odd = odd">
    <div class="row"  [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px; padding-left:25px">
      <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">{{foto?.nome}}</div>
      <div class="col col-7" style="font-size: 0.85em">{{foto?.descricao}}</div>
      <div class="col col-2" style="text-align: right">
        <span>{{foto?.valor | currency:'BRL'}}</span>
      </div>
    </div>
  </div>
</div>
<div class="page-break"></div>
<!-- INICIO GALERIA -->
<div *ngFor="let img of imagesArray" style="text-align: center;">
  <img [src]="img.url" class="imagemOrcamento"/> 
</div>
<!-- FIM GALERIA -->
<div class="page-break"></div>
<div style="height: 4em;"></div>
<img src="../../../assets/img/tag-financeiro.png" style="margin-left:10px; width: 100%">
<br><br>
<div class="planoFinanceiro">
<span>{{prestacoes}}x de </span><span>{{ totalPorFormandosPorMes | currency:'BRL' }}</span>
</div>
<br>
<div class="page-break"></div>
<hr>
<div class="page-break"></div>
<!-- INICIO DIFERENCIAIS -->
<img src="../../../assets/img/tag-diferenciais.png" style="margin-left:10px; width: 100%">
<table class="table table-striped" style="padding-left: 25px">
  <tbody>
    <tr>
      <td>Trends Rifa- Prêmios anuais para arrecadação em rifas. As rifas abrangem todas as turmas da Trends</td>
    </tr>
    <tr>
      <td>Trends On Line - Sistema de Informação Gerencial - exclusividade Trends Formaturas</td>
    </tr>
    <tr>
      <td>Bank Trends e Trends Express - Administração Financeira e atendimento personalizado</td>
    </tr>
    <tr>
      <td>Trends Idéias - Idéias Inovadoras nunca vistas ou realizadas no segmento de Formaturas</td>
    </tr>
    <tr>
       <td>Trends Criação - Engenheiros de Criação e Eventos Tematizados</td>
    </tr>
    <tr>
      <td>Atendimento Personalizado - Gerentes Executivos e Empresa Departamentalizada</td>
    </tr>
    <tr>
      <td>Fale com a Trends - contato@trendsformaturas.com.br</td>
    </tr>
    <tr>
      <td>Trends Qualidade - Padrão de Qualidade Trends Formaturas</td>
    </tr>
  </tbody>
  </table>
  <div style="height: 4em;"></div>
  <hr>
  <img src="../../../assets/img/tag-fotos.png" style="margin-left:10px; width: 100%">
  <table class="table table-striped" style="padding-left: 25px">
    <tbody>
      <tr>
        <td>Capacidade de Produção de 10.000 fotos/dia (prazo de entrega reduzido)</td>
      </tr>
      <tr>
        <td>Estrutura de estúdio fotográfico na Trends Formaturas (acompanhamento personalizado)</td>
      </tr>
      <tr>
        <td>Cobertura Fotográfica de todos os eventos oficiais da formatura e outros como churrascos, confratenizações, encontro dos formandos</td>
      </tr>
      <tr>
        <td>Foto tamanho 24 X 30 e preços menores dos que os praticados pela média do mercado do Paraná</td>
      </tr>
      <tr>
        <td>50% de desconto no valor da Produção Fotográfica dos convites</td>
      </tr>
      <tr>
        <td>O formando não terá a obrigação de comprar um minimo de fotos. Assim, cada formando pode comprar quantas fotos quiser</td>
      </tr>
      <tr>
        <td>Venda Pós e sem obrigatoriedade de compra</td>
      </tr>
      <tr>
        <td>Filmagem completa dos eventos da formatura, edição e produção de DVDs</td>
      </tr>
    </tbody>
    </table>
<!-- FIM DIFERENCIAIS -->
  </mat-step>
</mat-horizontal-stepper>