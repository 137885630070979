<div class="topoLogo no-print">
    <div>
        <img src="../assets/img/logo.png" style="height:90px">
    </div>
</div>  
<div class="back" (click)="goBack()"><fa-icon [icon]="['fas', 'arrow-alt-circle-left']" size="2x"></fa-icon></div>
<mat-tab-group (selectedTabChange)="getParams($event)" backgroundColor="primary" color="accent" style="padding-left: 6em;">
<mat-tab label="PRÉ EVENTOS">
<ng-template matTabContent>
<h4 style="text-align: center; color:blueviolet; padding:1em">Pré Eventos</h4>
<div *ngFor="let param of parametros; let odd = odd" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="width:95%">
        <div class="filtroContainer">
                <div class="col col-3">
                    <label>Titulo</label><br>
                    <span>{{param.titulo}}</span> 
                </div>
                <div class="col col-2">
                    <label>Tipo</label><br>
                        <span *ngIf="param.tipo == 'checkbox'">Checkbox</span>
                        <span *ngIf="param.tipo == 'selecionavel'">Selecionavel</span>
                </div>
                <div class="col col-2">
                    <div *ngIf="param.tipo != 'selecionavel'">
                    <label>Marcado</label><br>
                        <span *ngIf="param.marcado == true">Sim</span>
                        <span *ngIf="param.marcado == false">Não</span>
                     </div>
                </div>
                    <div class="col col-2">
                        <div *ngIf="param.tipo != 'fixo'">
                            <label>Formando</label><br>
                            <span *ngIf="param.formando == true">Sim</span>
                            <span *ngIf="param.formando == false">Não</span>
                        </div>
                    </div>
                    <div class="col col-1" style="text-align: center;">
                        <label>Posição</label><br>
                        <span>{{param?.posicao}}</span> 
                    </div>
                    <div class="col col-2">
                            <button class="btn btn-success btn-sm" (click)="openEditModal(param._id)">
                            <fa-icon [icon]="['fas', 'edit']" size="1x"></fa-icon>
                        </button>&nbsp;
                        <button class="btn btn-danger btn-sm" (click)="deleteParametro(param?._id)" [disabled]="param.tipo == 'fixo'">
                            <fa-icon [icon]="['fas', 'trash']" size="1x"></fa-icon>
                        </button>
                    </div>
                </div>
    <div class="filtroContainer">
        <div class="col col-12" *ngIf="param.tipo == 'checkbox'">
        <label>Valores</label>
            <div class="row" *ngFor="let opt of param.valores | sort:'cidade'">
                <div class="col">
                    <span>{{opt?.valor | currency:'BRL'}}</span>
                </div>            
                <div class="col">
                    <span>{{opt?.cidade}}</span>
                </div>                        
            </div>
            <div class="row">
                <div class="col col-12">
                    <label>Descrição:</label><br>
                    <span>{{param?.descricao}}</span>
                </div>
            </div>
        </div>
        <div class="col col-12" *ngIf="param.tipo == 'selecionavel'">
            <label>Opções</label>
                <div *ngFor="let opt of param.opcoes | sort:'cidade'">
                    <div class="row">
                        <div class="col">
                            <span>{{opt?.nome}}</span>
                        </div>
                        <div class="col">
                            <span>{{opt?.valor | currency:'BRL'}}</span>
                        </div>     
                        <div class="col">
                            <span>{{opt?.cidade}}</span>
                        </div>    
                    </div>     
                    <div class="row">
                        <div class="col">
                            <label>Descrição:</label>&nbsp;
                            {{opt?.descricao}}
                        </div>
                    </div>   
                    <br>                    
                </div>
        </div>
    </div>
    <hr>
</div>
<!-- BOTAO ADD NOVO PARAMETRO -->
<div style="position: fixed; bottom:0; width: 100%; z-index:1">  
    <button class="btn btn-success btn-lg" style="width: 100%" (click)="openModal()">
        <fa-icon [icon]="['fas', 'plus-square']" size="1x"></fa-icon>
        NOVO PARAMETRO
    </button>
</div> 
    </ng-template>
</mat-tab>
<mat-tab label="PRODUÇÃO FOTOGRÁFICA">
        <ng-template matTabContent>
        <h4 style="text-align: center; color:blueviolet; margin:1em">Produção Fotográfica</h4>
        <div *ngFor="let param of parametros; let odd = odd" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="width:95%">
            <div class="filtroContainer">
                <div class="col col-3">
                    <label>Titulo</label><br>
                    <span>{{param.titulo}}</span> 
                </div>
                <div class="col col-2">
                    <label>Tipo</label><br>
                        <span *ngIf="param.tipo == 'checkbox'">Checkbox</span>
                        <span *ngIf="param.tipo == 'selecionavel'">Selecionavel</span>
                        <span *ngIf="param.tipo == 'fixo'">Fixo</span>
                </div>
                    <div class="col col-2">
                        <div class="full-width">
                            <label>Formando</label><br>
                            <span *ngIf="param.formando == true">Sim</span>
                            <span *ngIf="param.formando == false">Não</span>
                        </div>
                    </div>
                    <div class="col col-2" *ngIf="param.tipo != 'selecionavel'">
                        <label>Marcado</label><br>
                            <span *ngIf="param.marcado == true">Sim</span>
                            <span *ngIf="param.marcado == false">Não</span>
                    </div>
                    <div class="col col-1" style="text-align: center;">
                        <label>Posição</label><br>
                        <span>{{param?.posicao}}</span> 
                    </div>
                    <div class="col col-2">
                            <button class="btn btn-success btn-sm" (click)="openEditModal(param._id)">
                            <fa-icon [icon]="['fas', 'edit']" size="1x"></fa-icon>
                        </button>&nbsp;
                        <button class="btn btn-danger btn-sm" (click)="deleteParametro(param?._id)" [disabled]="param.tipo == 'fixo'">
                            <fa-icon [icon]="['fas', 'trash']" size="1x"></fa-icon>
                        </button>
                    </div>
                </div>
    <div class="filtroContainer">
        <div class="col col-12" *ngIf="param.tipo == 'checkbox' || param.tipo == 'fixo'">
            <label>Valores</label>
                <div class="row" *ngFor="let opt of param.valores | sort:'cidade'">
                    <div class="col">
                        <span>{{opt?.valor | currency:'BRL'}}</span>
                    </div>            
                    <div class="col">
                        <span>{{opt?.cidade}}</span>
                    </div>                      
                </div>
                <div class="row">
                    <div class="col col-12">
                        <label>Descrição:</label><br>
                        <span>{{param?.descricao}}</span>
                    </div>
                </div>  
        </div>
        <div class="col col-12" *ngIf="param.tipo == 'selecionavel'">
            <label>Opções</label>
                <div *ngFor="let opt of param.opcoes | sort:'cidade'">
                    <div class="row">
                        <div class="col">
                            <span>{{opt?.nome}}</span>
                        </div>
                        <div class="col">
                            <span>{{opt?.valor | currency:'BRL'}}</span>
                        </div>     
                        <div class="col">
                            <span>{{opt?.cidade}}</span>
                        </div>    
                    </div>     
                    <div class="row">
                        <div class="col">
                            <label>Descrição:</label>&nbsp;
                            {{opt?.descricao}}
                        </div>
                    </div>   
                    <br>                    
                </div>
        </div>
    </div>
    <hr>
</div>
<!-- BOTAO ADD NOVO PARAMETRO -->
<div style="position: fixed; bottom:0; width: 100%; z-index:1">  
<button class="btn btn-success btn-lg" style="width: 100%" (click)="openModal()">
    <fa-icon [icon]="['fas', 'plus-square']" size="1x"></fa-icon>
    NOVO PARAMETRO
</button>
</div> 
</ng-template>
</mat-tab>
<mat-tab label="FORMATURAS">
        <ng-template matTabContent>
        <h4 style="text-align: center; color:blueviolet; margin:1em">Formaturas</h4>
        <div *ngFor="let param of parametros; let odd = odd" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="width:95%">
            <div class="filtroContainer">
                <div class="col col-3">
                    <label>Titulo</label><br>
                    <span>{{param.titulo}}</span> 
                </div>
                <div class="col col-2">
                    <label>Tipo</label><br>
                        <span *ngIf="param.tipo == 'checkbox'">Checkbox</span>
                        <span *ngIf="param.tipo == 'selecionavel'">Selecionavel</span>
                </div>
                <div class="col col-2">
                    <div class="full-width">
                        <label>Formando</label><br>
                        <span *ngIf="param.formando == true">Sim</span>
                        <span *ngIf="param.formando == false">Não</span>
                    </div>
                </div>
                <div class="col col-2" *ngIf="param.tipo != 'selecionavel'">
                    <label>Marcado</label><br>
                        <span *ngIf="param.marcado == true">Sim</span>
                        <span *ngIf="param.marcado == false">Não</span>
                </div>              
                <div class="col col-1" style="text-align: center;">
                    <label>Posição</label><br>
                    <span>{{param?.posicao}}</span> 
                </div>         
                    <div class="col col-2">
                            <button class="btn btn-success btn-sm" (click)="openEditModal(param._id)">
                            <fa-icon [icon]="['fas', 'edit']" size="1x"></fa-icon>
                        </button>&nbsp;
                        <button class="btn btn-danger btn-sm" (click)="deleteParametro(param?._id)" [disabled]="param.tipo == 'fixo'">
                            <fa-icon [icon]="['fas', 'trash']" size="1x"></fa-icon>
                        </button>
                    </div>
                </div>
    <div class="filtroContainer">
            <div class="col col-12" *ngIf="param.tipo == 'checkbox'">
                    <label>Valores</label>
                        <div class="row" *ngFor="let opt of param.valores | sort:'cidade'">
                            <div class="col">
                                <span>{{opt?.valor | currency:'BRL'}}</span>
                            </div>            
                            <div class="col">
                                <span>{{opt?.cidade}}</span>
                            </div>                        
                        </div>
                        <div class="row">
                            <div class="col col-12">
                                <label>Descrição:</label><br>
                                <span>{{param?.descricao}}</span>
                            </div>
                        </div>  
                </div>
        <div class="col col-12" *ngIf="param.tipo == 'selecionavel'">
            <label>Opções</label>
                <div *ngFor="let opt of param.opcoes | sort:'cidade'">
                    <div class="row">
                        <div class="col">
                            <span>{{opt?.nome}}</span>
                        </div>
                        <div class="col">
                            <span>{{opt?.valor | currency:'BRL'}}</span>
                        </div>     
                        <div class="col">
                            <span>{{opt?.cidade}}</span>
                        </div>    
                    </div>     
                    <div class="row">
                        <div class="col">
                            <label>Descrição:</label>&nbsp;
                            {{opt?.descricao}}
                        </div>
                    </div>   
                    <br>                    
                </div>
        </div>
    </div>
    <hr>
</div>
<!-- BOTAO ADD NOVO PARAMETRO -->
<div style="position: fixed; bottom:0; width: 100%; z-index:1">  
<button class="btn btn-success btn-lg" style="width: 100%" (click)="openModal()">
    <fa-icon [icon]="['fas', 'plus-square']" size="1x"></fa-icon>
    NOVO PARAMETRO
</button>
</div> 
</ng-template>
</mat-tab>
</mat-tab-group>
<div style="height: 60px"></div>
<!-- MODAL ADD PARAMETRO --> 
<div id="myModalParamsInf" class="modal">
    <span (click)="closeModal()" class="close">&times;</span>
    <div style="height: 100px"></div>
    <div class="filtroContainer">
        <div class="cadastroOption">
        <form [formGroup]="parametro" (ngSubmit)="novoParametro(
            parametro.get('sessao')?.value,
            parametro.get('titulo')?.value,
            parametro.get('valores')?.value,
            parametro.get('tipo')?.value,
            parametro.get('descricao')?.value,
            parametro.get('opcoes')?.value,
            parametro.get('formando')?.value,
            parametro.get('marcado')?.value,
            parametro.get('posicao')?.value)">
            <h4>ADICIONAR NOVO PARAMETRO</h4> 
            <div class="row">
                <div class="col col-2">
                    <label>Sessão</label>
                    <select class="form-control" formControlName="sessao">
                        <option value="Pré Eventos">Pré Eventos</option>
                        <option value="Produção Fotográfica">Produção Fotográfica</option>
                        <option value="Formaturas">Formaturas</option>
                    </select>
                </div>
                <div class="col col-2">
                    <label>Tipo</label>
                    <select class="form-control" formControlName="tipo">
                        <option value="checkbox">CheckBox</option>
                        <option value="selecionavel">Selecionavel</option>
                    </select>
                </div>
                <div class="col col-3">
                    <label>Titulo</label>
                    <input type="text" class="form-control" formControlName="titulo">
                </div>  
                <div class="col col-2">
                    <label>Por Aluno?</label>
                    <select class="form-control" formControlName="formando">
                        <option [ngValue]=true>Sim</option>
                        <option [ngValue]=false>Não</option>
                    </select>
                </div>
                <div class="col col-1" *ngIf="parametro.get('tipo')?.value != 'selecionavel'">
                    <label>Marcado?</label>
                    <select class="form-control" formControlName="marcado">
                        <option [ngValue]=true>Sim</option>
                        <option [ngValue]=false>Não</option>
                    </select>
                </div>
                <div class="col col-1">
                    <label>Posicao</label>
                    <input type="number" class="form-control" formControlName="posicao">
                </div>
            </div>
            <div class="row" *ngIf="parametro.get('tipo')?.value == 'checkbox'">
                <div class="col col-12">
                    <label>Descrição</label>
                    <textarea class="form-control" formControlName="descricao"></textarea>
                </div>
            </div>
            <div class="card-body" formArrayName="valores" *ngIf="parametro.get('tipo')?.value == 'checkbox'">
                    <div class="row">
                      <div class="col-12" *ngFor="let contact of valoresFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                          <div class="form-group col-3">
                            <label>Valor</label>
                            <input type="number" class="form-control" formControlName="valor">
                          </div>
                          <div class="form-group col-4">
                            <label>Cidade</label>
                                <select class="form-control" formControlName="cidade">
                                    <option *ngFor="let cidade of listaCidades" [value]="cidade?.nome">
                                    {{cidade?.nome}}
                                    </option>
                                </select>
                            </div>
                          <div class="form-group col-2 text-right" style="padding-top:20px">
                            <button class="btn btn-danger" type="button" (click)="removeOpcaoCheckbox(i)">
                              <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                            </button>&nbsp;
                            <button class="btn btn-success" type="button" (click)="addOpcaoCheckbox()">
                              <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-success" type="button" (click)="addOpcaoCheckbox()">Criar Valor</button>
                </div>
            </div>
            <div class="card-body" formArrayName="opcoes" *ngIf="parametro.get('tipo')?.value == 'selecionavel'">
                    <div class="row">
                      <div class="col col-12" *ngFor="let contact of opcoesFormGroup.controls; let i = index;" [formGroupName]="i">
                        <div class="row">
                          <div class="col col-4">
                            <label>Nome</label>
                            <input type="text" class="form-control" formControlName="nome">
                          </div>
                          <div class="col col-3">
                            <label>Valor</label>
                            <input type="number" class="form-control" formControlName="valor">
                          </div>
                          <div class="col col-3">
                                <label>Cidade</label>
                                <select class="form-control" formControlName="cidade">
                                    <option *ngFor="let cidade of listaCidades" [value]="cidade?.nome">
                                    {{cidade?.nome}}
                                    </option>
                                </select>
                            </div>
                          <div class="col col-2 text-right" style="padding-top: 20px;">
                            <button class="btn btn-danger" type="button" (click)="removeOpcao(i)">
                              <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                            </button>&nbsp;
                            <button class="btn btn-success" type="button" (click)="addOpcao()">
                              <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                            </button>
                          </div>
                        </div>
                        <div class="row">
                            <div class="col col-12">
                                <label>Descrição</label>
                                <textarea class="form-control" formControlName="descricao"></textarea>
                            </div>
                        </div>
                        <hr>
                      </div>
                      <button class="btn btn-success" type="button" (click)="addOpcao()">Criar Opção</button>
                </div>
            </div>
            <br>
        <div style="text-align: center"> 
            <button type="button" class="btn btn-danger" (click)="closeModal()">CANCELAR</button>
            &nbsp;&nbsp;
            <button type="submit" class="btn btn-info" *ngIf="parametro.get('tipo')?.value == 'checkbox'" [disabled]="parametro.get('sessao')?.invalid || parametro.get('tipo')?.invalid || parametro.get('titulo')?.invalid || parametro.get('valores')?.invalid">ADICIONAR CHECKBOX</button>
            <button type="submit" class="btn btn-info" *ngIf="parametro.get('tipo')?.value == 'selecionavel'" [disabled]="parametro.get('sessao')?.invalid || parametro.get('tipo')?.invalid || parametro.get('titulo')?.invalid || parametro.get('opcoes')?.invalid">ADICIONAR SELECIONAVEL</button>
        </div> 
        </form>
    </div>
</div>
</div>
<!-- EDITAR PARAMETRO --> 
<div id="myEditModalCol" class="modal">
        <span (click)="closeEditModal()" class="close">&times;</span>
        <div style="height: 100px"></div>
        <div class="filtroContainer">
            <div class="cadastroOption">
            <form [formGroup]="editarParametro" (ngSubmit)="editParametro(
                editarParametro.get('tipo')?.value,
                editarParametro.get('titulo')?.value,
                editarParametro.get('valoresEditar')?.value,
                editarParametro.get('descricao')?.value,
                editarParametro.get('opcoesEditar')?.value,
                editarParametro.get('formando')?.value,
                editarParametro.get('marcado')?.value,
                editarParametro.get('posicao')?.value)">
                <h4>EDITAR PARAMETRO</h4> 
                <div class="row">
                    <div class="col col-4" *ngIf="editarParametro.get('tipo')?.value != 'fixo'">
                        <label>Titulo</label>
                        <input type="text" class="form-control" formControlName="titulo">
                    </div>
                    <div class="col col-3" *ngIf="editarParametro.get('tipo')?.value == 'fixo'">
                        <label>Titulo</label>
                        <input type="text" class="form-control" formControlName="titulo" readonly>
                    </div>
                    <div class="col col-3" *ngIf="editarParametro.get('tipo')?.value != 'fixo'">
                        <label>Tipo</label>
                        <select class="form-control" formControlName="tipo">
                            <option value="checkbox">CheckBox</option>
                            <option value="selecionavel">Selecionavel</option>
                        </select>
                    </div>
                    <div class="col col-9" *ngIf="editarParametro.get('tipo')?.value == 'fixo'">
                        <label>Descrição</label>
                        <textarea class="form-control" formControlName="descricao"></textarea>
                    </div>
                    <div class="col col-2" *ngIf="editarParametro.get('tipo')?.value != 'fixo'">
                        <label>Por Aluno?</label>
                        <select class="form-control" formControlName="formando">
                            <option [ngValue]=true>Sim</option>
                            <option [ngValue]=false>Não</option>
                        </select>
                    </div>
                    <div class="col col-2" *ngIf="editarParametro.get('tipo')?.value != 'selecionavel'">
                        <label>Marcado?</label>
                        <select class="form-control" formControlName="marcado">
                            <option [ngValue]=true>Sim</option>
                            <option [ngValue]=false>Não</option>
                        </select>
                    </div>
                    <div class="col col-1">
                        <label>Posição</label>
                        <input type="number" class="form-control" formControlName="posicao">
                    </div>
                </div>
                <div class="row" *ngIf="editarParametro.get('tipo')?.value == 'checkbox'">
                    <div class="col col-12">
                        <label>Descrição</label>
                        <textarea class="form-control" formControlName="descricao"></textarea>
                    </div>
                </div>
                <div class="card-body" formArrayName="valoresEditar" *ngIf="editarParametro.get('tipo')?.value == 'checkbox'">
                    <label>Opções</label>
                    <div class="row">
                        <div class="col-12" *ngFor="let contact of valoresEditarFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="col col-3">
                                <label>Valor</label>
                                <input type="number" class="form-control" formControlName="valor">
                            </div>
                            <div class="col col-4">
                                <label>Cidade</label>
                                <select class="form-control" formControlName="cidade">
                                    <option *ngFor="let cidade of listaCidades" [value]="cidade?.nome">
                                    {{cidade?.nome}}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col-2 text-right" style="padding-top:25px;">
                            <button class="btn btn-danger" type="button" (click)="removeOpcaoCheckboxEditar(i)">
                                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                            </button>&nbsp;
                            <button class="btn btn-success" type="button" (click)="addOpcaoCheckboxEditar()">
                                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                            </button>
                            </div>
                        </div>
                    </div>
                        <div style="padding:15px">
                            <button class="btn btn-success" type="button" (click)="addOpcaoCheckboxEditar()">Criar Valor</button>
                        </div>
                    </div>
                </div>
                <div class="card-body" formArrayName="opcoesEditar" *ngIf="editarParametro.get('tipo')?.value == 'selecionavel'">
                        <div class="row">
                          <div class="col-12" *ngFor="let contact of opcoesEditarFormGroup.controls; let i = index;">
                            <div [formGroupName]="i">
                                <div class="row">
                                    <div class="col col-4">
                                        <label>Nome</label>
                                        <input type="text" class="form-control" formControlName="nome">
                                    </div>
                                    <div class="col col-3">
                                        <label>Valor</label>
                                        <input type="number" class="form-control" formControlName="valor">
                                    </div>
                                    <div class="col col-3">
                                        <label>Cidade</label>
                                        <select class="form-control" formControlName="cidade">
                                            <option *ngFor="let cidade of listaCidades" [value]="cidade?.nome">
                                            {{cidade?.nome}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col col-2 text-right" style="padding-top:20px">
                                        <button class="btn btn-danger" type="button" (click)="removeOpcaoEditar(i)">
                                        <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                        </button>&nbsp;
                                        <button class="btn btn-success" type="button" (click)="addOpcaoEditar()">
                                        <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col col-12">
                                        <label>Descrição</label>
                                        <textarea class="form-control" formControlName="descricao"></textarea>
                                    </div>
                                </div>
                              <hr>
                            </div>
                          </div>
                          <button class="btn btn-success" type="button" (click)="addOpcaoEditar()">Criar Opção</button>
                    </div>
                </div>
                <div class="card-body" formArrayName="valoresEditar" *ngIf="editarParametro.get('tipo')?.value == 'fixo'">
                    <div class="row">
                      <div class="col-12" *ngFor="let contact of valoresEditarFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                          <div class="form-group col-3">
                            <label>Valor</label>
                            <input type="number" class="form-control" formControlName="valor">
                          </div>
                          <div class="form-group col-4">
                            <label>Cidade</label>
                            <select class="form-control" formControlName="cidade">
                                <option *ngFor="let cidade of listaCidades" [value]="cidade?.nome">
                                {{cidade?.nome}}
                                </option>
                            </select>
                        </div>
                          <div class="form-group col-2 text-right">
                            <button class="btn btn-danger" type="button" (click)="removeOpcaoCheckboxEditar(i)">
                                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                            </button>&nbsp;
                            <button class="btn btn-success" type="button" (click)="addOpcaoCheckboxEditar()">
                                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-success" type="button" (click)="addOpcaoCheckboxEditar()">Criar Opção</button>
                </div>
            </div>
            <div style="text-align: center">
                <button type="button" class="btn btn-danger" (click)="closeEditModal()">CANCELAR</button>
                &nbsp;&nbsp;
                <button type="submit" class="btn btn-info" *ngIf="editarParametro.get('tipo')?.value == 'checkbox'" [disabled]="editarParametro.get('tipo')?.invalid || editarParametro.get('titulo')?.invalid || editarParametro.get('valoresEditar')?.invalid">SALVAR</button>
                <button type="submit" class="btn btn-info" *ngIf="editarParametro.get('tipo')?.value == 'selecionavel'" [disabled]="editarParametro.get('tipo')?.invalid || editarParametro.get('titulo')?.invalid || editarParametro.get('opcoesEditar')?.invalid">SALVAR</button>
                <button type="submit" class="btn btn-info" *ngIf="editarParametro.get('tipo')?.value == 'fixo'" [disabled]="editarParametro.get('valoresEditar')?.invalid">SALVAR</button>
            </div> 
            </form>
        </div>
    </div>
</div>
 <!-- FIM EDITAR PARAMETRO -->