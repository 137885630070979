import { Component, OnInit } from '@angular/core';
import { DataBaseService } from 'src/app/provider';
import { AuthService } from 'src/app/auth.service';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.css']
})

export class GaleriaComponent implements OnInit {
  imagens:any = [];
  selectedFiles:any = "";
  galeria:any = "";
  images:any = [];
  galerias:any = [];
  imagemSelecionada:any;
  tipoUsuario:any = "";
  indexImage:number = 0; 
  galeriaDelete:any = [];
  galeriaDeletada:any = "";
  nomeGaleriaDeletada:any = "";
  video:boolean = false;
  foto:boolean = false;
  galeriaOrcamento:boolean = false;
  grid:boolean = false;
  idGaleria:any = "";
  senhaAdm:any = "";
  dataHoje:any = "";
  nomeNovaGaleria:any = "";
  caracteres:any = ["?","\´","\`","\"","\'","_",";",":",".",",","!","@","#","$","&","/","<",">","|","(",")","=","-"," ","%","¨","*","'","+","~","^","[","]","{","}","º","\\"]
  defaultImage:any = "../../../assets/img/blurLoading.jpg";
  
  constructor(public app:AppComponent, public db: DataBaseService, public auth: AuthService){}
 
  ngOnInit(){
    let gridInit:any = document.getElementById('grid');
    gridInit.style.display = "none";
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0]; 
    this.tipoUsuario = localStorage.getItem('tipousuarioTrends');
    this.db.getGalerias().subscribe((data:any) => this.galerias = data);
  }

  onChange(event:any){
    let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4']
    this.selectedFiles = <FileList>event.srcElement.files;
    for(let i=0; i<this.selectedFiles.length; i++){
        if(!arrayTypes.includes(this.selectedFiles[i].type) || event.srcElement.files[0].size > 5120000){
         this.app.openAlert("ERRO: Tamanho Máximo Permitido 5MB ou Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG ou MP4")
        this.selectedFiles = "";
      }
    } 
    if(this.selectedFiles.length > 0){
      this.uploadFile();
    }
  }
   
  uploadFile(){
    let indice = this.galerias.map((gg:any) => {
      return gg.galeria;
    }).indexOf(this.galeria);
    if(indice >= 0){
      this.idGaleria = this.galerias[indice].id;
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      for(let i=0; i<this.selectedFiles.length; i++){
        let imagedata = new FormData();
        let random = Math.random().toString(32).slice(-8);
        imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name);
        let fileNameSplit = this.selectedFiles[i].name.split('.');
        let extensao = fileNameSplit[fileNameSplit.length-1]
        let obj = {
          galeria:this.galeria,
          url: 'http://app.trendsformaturas.com.br/upload/'+this.galeria+'_'+random+'.'+ extensao
        }
        this.db.postImagem(obj).subscribe(res => {
          console.log(res);
          this.db.enviarImagemGaleria(this.galeria, imagedata,random).subscribe(res => console.log(res),err => console.log(err));
          console.log(res);
          this.abrirGaleria(this.galeria,this.idGaleria);
        },err => {console.log(err)
      })
      if(this.selectedFiles.length == i+1){
        let ob = {
          data:this.dataHoje,
          hora:hora,
          usuario: localStorage.getItem('usuarioTrends'),
          menu: "GALERIA",
          descricao: "Imagem Enviada Para Galeria: "+this.galeria
        }
        this.db.postLogs(ob).subscribe(res => {
          console.log(res);
          this.selectedFiles = "";
          }, err => console.log(err));
        }
      }
    } 
  }

  abrirGaleria(galeria:any,id:any){
    this.idGaleria = id;
    this.galeria = galeria;
    this.images = [];
    let ctrl = 1;
    this.db.getImagensByGaleria(galeria).subscribe((data:any) => {
      this.imagens = data
      if(this.imagens.length > 0){
        this.imagens.forEach((img:any) => {
          if(img.galeria == galeria){
            this.images.push(img)
          }
          if(this.imagens.length == ctrl){
            this.videoCheck();
          }else{
            ctrl++
          }
        })
      }
      let grid:any = document.getElementById('grid');
      grid.style.display = "block";
      this.grid = true;
      let modGrid:any = document.getElementById('menuGrid');
      modGrid.style.display = "none";
    });
  }

deleteGallery(){
  let jsonDate = new Date().toJSON();
  let hora = new Date(jsonDate).toLocaleTimeString();
  this.db.getUsuarioByEmail(localStorage.getItem('emailTrends')).subscribe((data:any) => {
    let pwd = data[0].senha;
    if(pwd == this.senhaAdm){
      this.closeSenhaDeleteGaleria();
      if(this.imagens.length > 0){
        let ctrl = 1;
        this.imagens.forEach((iG:any) => {
          if(iG.idGaleria == this.idGaleria){
            this.db.deleteImagemGaleria(iG.id).subscribe(res => {
              console.log(res);
              this.db.removeUpload(iG.url.split('/')[4]).subscribe(res => {
                console.log(res);
              },err => {
                console.log(err)
              })
            }, err => console.log(err));
          }
          if(this.imagens.length == ctrl){
            this.db.deleteGaleria(this.idGaleria).subscribe(res => {
              console.log(res);
              let ob = {
                data:this.dataHoje,
                hora:hora,
                usuario: localStorage.getItem('usuarioTrends'),
                menu: "GALERIA",
                descricao: "Galeria Excluida: "+this.nomeGaleriaDeletada
              }
              this.db.postLogs(ob).subscribe(res => {
                console.log(res);
                 this.app.openAlert("Galeria Excluida com Sucesso!");
                this.closeGrid();
                this.galeria = "";
                this.db.getGalerias().subscribe((emp:any) => {
                  this.galerias = emp;
                  this.images = [];
                })
              },err => console.log(err));
            }, err => console.log(err));
          }else{
            ctrl++
          }
        })
      }else{
        this.db.deleteGaleria(this.idGaleria).subscribe(res => {
          console.log(res);
          let ob = {
            data:this.dataHoje,
            hora:hora,
            usuario: localStorage.getItem('usuarioTrends'),
            menu: "GALERIA",
            descricao: "Galeria Excluida: "+this.nomeGaleriaDeletada
          }
          this.db.postLogs(ob).subscribe(res => {
            console.log(res);
            this.closeGrid();
             this.app.openAlert("Galeria Excluida com Sucesso!");
            this.galeria = "";
            this.db.getGalerias().subscribe((emp:any) => {
              this.galerias = emp;
              this.images = [];
            })
          },err => console.log(err));
        }, err => console.log(err));
      }
      }else{
         this.app.openAlert("SENHA INCORRETA!")
      }
  })
}

openModal(url:any, i:any){
  this.indexImage = i;
  let modal:any = document.getElementById('myModalGaleria');
  modal.style.display = "block";
  this.imagemSelecionada = url;
  if(this.imagemSelecionada.slice(-3) == 'mp4'){
    this.video = true;
    this.foto = false;
  }else{
    this.video = false;
    this.foto = true;
  }
}

nextImg(){
  let i = this.indexImage
  if((this.images.length - 1) == i){
    this.imagemSelecionada = this.images[0].url;
    this.indexImage = 0;
  }else{
    i++
    this.imagemSelecionada = this.images[i].url;
    this.indexImage++
  }
  if(this.imagemSelecionada.slice(-3) == 'mp4'){
    this.video = true;
    this.foto = false;
  }else{
    this.video = false;
    this.foto = true;
  }
}

prevImg(){
  let i = this.indexImage
  if(i == 0){
    this.imagemSelecionada = this.images[this.images.length - 1].url;
    this.indexImage = this.images.length - 1;
  }else{
    i--
    this.imagemSelecionada = this.images[i].url;
    this.indexImage--
  }
  if(this.imagemSelecionada.slice(-3) == 'mp4'){
    this.video = true;
    this.foto = false;
  }else{
    this.video = false;
    this.foto = true;
  }
}

closeModal(){
  let modal:any = document.getElementById('myModalGaleria')
  modal.style.display = "none";
  this.video = false;
}

newGallery(nomeNovaGaleria:any){
  let jsonDate = new Date().toJSON();
  let hora = new Date(jsonDate).toLocaleTimeString();
    let obj = {
      galeria: nomeNovaGaleria,
    }
    this.db.postGaleria(obj).subscribe(res=>{
      console.log(res);
      let ob = {
        data:this.dataHoje,
        hora:hora,
        usuario: localStorage.getItem('usuarioTrends'),
        menu: "GALERIA",
        descricao: "Galeria Criada: "+nomeNovaGaleria
      }
      this.db.postLogs(ob).subscribe(res => {
        console.log(res);
         this.app.openAlert("Galeria Cadastrada Com Sucesso!");
        this.galeria = "";
        this.db.getGalerias().subscribe((emp:any) => {
          this.galerias = emp;
          this.images = [];
          this.closeNovaGaleria();
        })
      }, err=>{
        console.log(err);
         this.app.openAlert("Não Foi Possivel Cadastrar Galeria, Tente Novamente!")
      })
    }, err=>{
      console.log(err);
       this.app.openAlert("Não Foi Possivel Cadastrar Galeria, Tente Novamente!")
    })
}

delButton(i:any){
  let buttonDiv:any = document.getElementById('img'+i);
  buttonDiv.toggleAttribute("hidden");
    if(buttonDiv.className == "delButton"){
      buttonDiv.className = "delButtonShow";
    }else{
      buttonDiv.className = "delButton";
    }
}

delImage(id:any, pathAddress:any){
  let jsonDate = new Date().toJSON();
  let hora = new Date(jsonDate).toLocaleTimeString();
  if(window.confirm('Deseja Realmente Excluir Essa Imagem? Esta ação não pode ser desfeita!')){
    this.db.removeUpload(pathAddress.split('/')[4]).subscribe(res => {
      console.log(res);
    },err => {
      console.log(err)
    })
    this.db.deleteImagemGaleria(id).subscribe(res => {
      console.log(res);
      let ob = {
        data:this.dataHoje,
        hora:hora,
        usuario: localStorage.getItem('usuarioTrends'),
        menu: "GALERIA",
        descricao: "Imagem Excluida da Galeria: "+this.galeria
      }
      this.db.postLogs(ob).subscribe(res => {
         console.log(res);
         this.abrirGaleria(this.galeria,id);
      },err=>{
        console.log(err);
         this.app.openAlert("Não Foi Possivel Excluir a Imagem, Tente Novamente!")
          })
    }, err => console.log(err));
  }
}

videoCheck(){
  var videoAttr:any = { 'autoplay': true, 'loop': true, 'mute': true, 'playsinline': true };
  var imgMP4s:any = Array.prototype.map.call(
    document.querySelectorAll('img[src*=".mp4"]'),
    function(img){
      var src = img.src;
      img.src = null;
      img.addEventListener('error', function(e:any){
        var video = document.createElement('video');
        for (var key in videoAttr) { video.setAttribute(key, videoAttr[key]); }
        for (
          var imgAttr = img.attributes, 
          len = imgAttr.length,
          i = 0; 
          i < len; 
          i++
        ) { 
          video.setAttribute(imgAttr[i].name,  imgAttr[i].value); 
        }
        img.parentNode.insertBefore(video, img);
        img.parentNode.removeChild(img);
      });
      img.src = src;
    });
  }

  openSenhaDeleteGaleria(){
    let modalSenha:any = document.getElementById('senhaModalGaleria');
    modalSenha.style.display = 'block';
  }

  closeSenhaDeleteGaleria(){
    let modalSenha:any = document.getElementById('senhaModalGaleria');
    modalSenha.style.display = 'none';
    this.senhaAdm = null;
  }

  closeGrid(){
    let grid:any = document.getElementById('grid');
    grid.style.display = "none";
    this.grid = false;
    let modGrid:any = document.getElementById('menuGrid');
    modGrid.style.display = "block";
  }

  openNovaGaleria(){
    let newGallery:any = document.getElementById('novaGaleria');
    newGallery.style.display = "block";
    const input:any = document.querySelector("#nomeGaleria");
    input.addEventListener("keypress", (e:any) => {
        if(this.caracteres.includes(e.key)){
          e.preventDefault();
        }
      });
  }

  closeNovaGaleria(){
    let newGallery:any = document.getElementById('novaGaleria');
    newGallery.style.display = "none";
    this.nomeNovaGaleria = "";
  }


}