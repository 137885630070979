import { Component, OnInit } from '@angular/core';
import { DataBaseService } from 'src/app/provider';
import { AuthService } from 'src/app/auth.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Location } from '@angular/common';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-parametros-infantil',
  templateUrl: './parametros-infantil.component.html',
  styleUrls: ['./parametros-infantil.component.css']
})

export class ParametrosInfantilComponent implements OnInit {
  parametros:any;
  proposta:any;
  dataBase:any;
  parametroSelecionado:any;
  idParametro:any;
  listaCidades:any;
  fotos:any;
  parametro!: FormGroup;
  editarParametro!: FormGroup;
  public opcoesList!: FormArray;
  public opcoesListCheckbox!: FormArray;
  public opcoesListEditar!: FormArray;
  public opcoesListCheckboxEditar!: FormArray;
  get opcoesFormGroup(){return this.parametro.get('opcoes') as FormArray;}
  get valoresFormGroup(){return this.parametro.get('valores') as FormArray;}
  get opcoesEditarFormGroup(){return this.editarParametro.get('opcoesEditar') as FormArray;}
  get valoresEditarFormGroup(){return this.editarParametro.get('valoresEditar') as FormArray;}

  constructor(public app:AppComponent, private location:Location, private formBuilder: FormBuilder, private db:DataBaseService, public auth: AuthService) { }

  ngOnInit() {
    this.db.getPreEventosInfantil().subscribe((data:any) => {
      this.parametros = data.sort((a:any,b:any) => {
        if(a.tipo > b.tipo){return 1;}
        if(a.tipo < b.tipo){return -1;}
        if(a.posicao > b.posicao){return 1;}
        if(a.posicao < b.posicao){return -1;}
        return 1;
      });
    this.dataBase = "Pré Eventos";
    this.db.getCidades().subscribe((dt:any) => this.listaCidades = dt.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}})); 
    });   
    this.parametro = this.formBuilder.group({
      sessao : ["", Validators.required],
      valores: this.formBuilder.array([this.createOpcaoCheckbox()]),
      tipo: ["", Validators.required],
      marcado: [""],
      titulo: ["", Validators.required],
      descricao: [""],
      formando: [""],
      posicao:[""],
      opcoes: this.formBuilder.array([this.createOpcao()])
    });
    this.editarParametro = this.formBuilder.group({
      valoresEditar: this.formBuilder.array([this.createOpcaoCheckboxEditar()]),
      tipo: ["", Validators.required],
      titulo: ["", Validators.required],
      descricao: [""],
      posicao:[""],
      marcado: [""],
      formando: [""],
      opcoesEditar: this.formBuilder.array([this.createOpcaoEditar()])
  });
    this.opcoesList = this.parametro.get('opcoes') as FormArray;
    this.opcoesListCheckbox = this.parametro.get('valores') as FormArray;
    this.opcoesListEditar = this.editarParametro.get('opcoesEditar') as FormArray;
    this.opcoesListCheckboxEditar = this.editarParametro.get('valoresEditar') as FormArray;
  }

  getParams(e:any){
    let i = e.index;
    switch(i) {
      case 0 :
        this.db.getPreEventosInfantil().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
          this.dataBase = "Pré Eventos";
          break;
      case 1 :
          this.db.getProdFotoInfantil().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
            if(a.tipo > b.tipo){return 1;}
            if(a.tipo < b.tipo){return -1;}
            if(a.posicao > b.posicao){return 1;}
            if(a.posicao < b.posicao){return -1;}
            return 1;
          });
        });
          this.dataBase = "Produção Fotográfica";
          break;
      case 2 :
          this.db.getFormaturaInfantil().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
            if(a.tipo > b.tipo){return 1;}
            if(a.tipo < b.tipo){return -1;}
            if(a.posicao > b.posicao){return 1;}
            if(a.posicao < b.posicao){return -1;}
            return 1;
          });
        });
          this.dataBase = "Formaturas";
          break;   
    }
  }

  atualizarParams(banco:any,obj:any){
    switch(banco){
    case "Pré Eventos" :
      this.db.patchPreEventosInfantil(this.idParametro,obj).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Editado Com Sucesso!");
        this.db.getPreEventosInfantil().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeEditModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Editar! Tente Novamente!")})
        break;
    case "Produção Fotográfica" :
      this.db.patchProdFotoInfantil(this.idParametro,obj).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Editado Com Sucesso!");
        this.db.getProdFotoInfantil().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeEditModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Editar! Tente Novamente!")})
        break;
    case "Formaturas" :
      this.db.patchFormaturaInfantil(this.idParametro,obj).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Editado Com Sucesso!");
        this.db.getFormaturaInfantil().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeEditModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Editar! Tente Novamente!")})
        break;  
     }
    }
    
  criarParams(banco:any,obj:any){
    console.log(banco)
    switch(banco){
    case "Pré Eventos" :
      this.db.postPreEventosInfantil(obj).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Criado Com Sucesso!");
        this.db.getPreEventosInfantil().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Criar! Tente Novamente!")})
        break;
    case "Produção Fotográfica" :
      this.db.postProdFotoInfantil(obj).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Criado Com Sucesso!");
        this.db.getProdFotoInfantil().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Criar! Tente Novamente!")})
        break;
    case "Formaturas" :
      this.db.postFormaturaInfantil(obj).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Criado Com Sucesso!");
        this.db.getFormaturaInfantil().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Criar! Tente Novamente!")})
        break;  
      }
    }

  createOpcao(): FormGroup {
    return this.formBuilder.group({
      nome: [null, Validators.compose([Validators.required])],
      valor: [null, Validators.compose([Validators.required])],
      cidade:  [null, Validators.compose([Validators.required])],
      descricao: [null, Validators.compose([Validators.required])]
    });
  }

  createOpcaoCheckbox(): FormGroup {
    return this.formBuilder.group({
      valor: [null, Validators.compose([Validators.required])],
      cidade:  [null, Validators.compose([Validators.required])]
    });
  }

  createOpcaoEditar(): FormGroup {
    return this.formBuilder.group({
      nome: [null, Validators.compose([Validators.required])],
      valor: [null, Validators.compose([Validators.required])],
      cidade: [null, Validators.compose([Validators.required])],
      descricao: [null, Validators.compose([Validators.required])]
    });
    } 

  createOpcaoCheckboxEditar(): FormGroup {
    return this.formBuilder.group({
      valor: [null, Validators.compose([Validators.required])],
      cidade: [null, Validators.compose([Validators.required])]
    });
    } 

  addOpcao() {
    this.opcoesList.push(this.createOpcao());
  }

  addOpcaoCheckbox() {
    this.opcoesListCheckbox.push(this.createOpcaoCheckbox());
  }

  addOpcaoEditar() {
    this.opcoesListEditar.push(this.createOpcaoEditar());
  }

  addOpcaoCheckboxEditar() {
    this.opcoesListCheckboxEditar.push(this.createOpcaoCheckboxEditar());
  }

  removeOpcao(index:any){
    this.opcoesList.removeAt(index);
  }

  removeOpcaoCheckbox(index:any){
    this.opcoesListCheckbox.removeAt(index);
  }

  removeOpcaoEditar(index:any){
    this.opcoesListEditar.removeAt(index);
  }

  removeOpcaoCheckboxEditar(index:any){
    this.opcoesListCheckboxEditar.removeAt(index);
  }

  replaceStrings(string:any){
    if(string){
      let str = string.replace(/\%/gi,'%25');
      return str.replace(/\+/gi,'%2B').replace(/\!/gi,'%21').replace(/\&/gi,'%26').replace(/\#/gi,'%23').replace(/\;/gi,'%3B');
    }else{
      return string;
    }
  }

  reverseStrings(string:any){
    if(string){
      return string.replace(/%252B/gi,'+').replace(/%2B/gi,'+').replace(/%21/gi,'!').replace(/%25/gi,'%').replace(/%26/gi,'&').replace(/%3B/gi,';');
    }else{
      return string;
    }
  }

editParametro(tpo:any, tit:any, vlr:any, descricao:any, opt:any, form:any, checked:any,pos:any){
  let desc = this.replaceStrings(descricao);
  if(window.confirm('Deseja Realmente Editar esse Parametro?')){
    if(tpo == 'fixo'){
      let obj  = {
        descricao : desc,
        valores: vlr,
        marcado: checked,
        posicao:pos
      }
      this.atualizarParams(this.dataBase,obj);
      }else{
        if(tpo == 'checkbox'){
          opt = null;
        }else{
          vlr = null;
        }
        let objeto = {
            titulo : tit,
            descricao : desc,
            valores: vlr,
            tipo : tpo,
            opcoes : opt,
            formando: form,
            marcado: checked,
            posicao:pos
          }
          this.atualizarParams(this.dataBase,objeto);
      }
    }
}

novoParametro(db:any,tit:any,vlr:any,tpo:any,descricao:any,opt:any,form:any,checked:any,pos:any){
  console.log(db)
  let desc = this.replaceStrings(descricao);
  if(window.confirm('Deseja Realmente Criar esse Parametro?')){
    if(tpo == 'checkbox'){
      if(this.parametro.get('opcoes')?.value == null){
        opt = "";
        }else{
          this.clearFormArray(this.parametro.get('opcoes') as FormArray)
          opt = "";
        }
    }else{
      if(this.parametro.get('valores')?.value == null){
        vlr = "";
        }else{
          this.clearFormArray(this.parametro.get('valores') as FormArray)
          vlr = "";
        }
    }
    let obj = {
      titulo : tit,
      descricao : desc,
      valores: vlr,
      tipo : tpo,
      opcoes : opt,
      formando: form,
      marcado: checked,
      posicao:pos
      }
    this.criarParams(db,obj);
    }
}
  
deleteParametro(id:any){
  if(window.confirm('Deseja Realmente Excluir esse Parametro?')){
    switch(this.dataBase){
    case "Pré Eventos" :
      this.db.delPreEventosInfantil(id).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Editado Com Sucesso!");
        this.db.getPreEventosInfantil().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeEditModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Editar! Tente Novamente!")})
        break;
    case "Produção Fotográfica" :
      this.db.delProdFotoInfantil(id).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Editado Com Sucesso!");
        this.db.getProdFotoInfantil().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeEditModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Editar! Tente Novamente!")})
        break;
    case "Formaturas" :
      this.db.delFormaturaInfantil(id).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Editado Com Sucesso!");
        this.db.getFormaturaInfantil().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeEditModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Editar! Tente Novamente!")})
        break;  
     }
  }
}

openModal(){
  let modal:any = document.getElementById('myModalParamsInf')
  modal.style.display = "block";
}

closeModal(){
  this.parametro.reset();
  let modal:any = document.getElementById('myModalParamsInf')
  modal.style.display = "none";
  this.parametro.reset();
}

clearFormArray = (formArray: FormArray) => {
  while (formArray.length !== 0) {
    formArray.removeAt(0)
  }
}

openEditModal(id:any){
  this.idParametro = id;
  this.clearFormArray(this.editarParametro.get('opcoesEditar') as FormArray);
  this.clearFormArray(this.editarParametro.get('valoresEditar') as FormArray);
  switch(this.dataBase){
    case "Pré Eventos" :
        this.db.getPreEventosInfantilById(id).subscribe((data:any) => {
          this.parametroSelecionado = data;
          this.editarParametro.get('titulo')?.setValue(this.parametroSelecionado.titulo);
          this.editarParametro.get('descricao')?.setValue(this.reverseStrings(this.parametroSelecionado.descricao));
          this.editarParametro.get('tipo')?.setValue(this.parametroSelecionado.tipo);
          this.editarParametro.get('formando')?.setValue(this.parametroSelecionado.formando);
          this.editarParametro.get('marcado')?.setValue(this.parametroSelecionado.marcado);
          this.editarParametro.get('posicao')?.setValue(this.parametroSelecionado.posicao);
            if(this.parametroSelecionado.opcoes == null || this.parametroSelecionado.opcoes == undefined){
              this.clearFormArray(this.editarParametro.get('opcoesEditar') as FormArray);
              }else{
                let opEditar = this.editarParametro.get('opcoesEditar') as FormArray;
                for (var i=0; i<this.parametroSelecionado.opcoes.length; i++){
                    opEditar.push(this.formBuilder.group({
                      nome: this.parametroSelecionado.opcoes[i].nome, 
                      valor: this.parametroSelecionado.opcoes[i].valor,
                      cidade: this.parametroSelecionado.opcoes[i].cidade,
                      descricao: this.reverseStrings(this.parametroSelecionado.opcoes[i].descricao)
                    })
                )};
            };
            if(this.parametroSelecionado.valores == null || this.parametroSelecionado.valores == undefined){
              this.clearFormArray(this.editarParametro.get('valoresEditar') as FormArray);
              }else{
                let vlrEditar = this.editarParametro.get('valoresEditar') as FormArray;
                for (var i=0; i<this.parametroSelecionado.valores.length; i++){
                    vlrEditar.push(this.formBuilder.group({
                      valor: this.parametroSelecionado.valores[i].valor,
                      cidade: this.parametroSelecionado.valores[i].cidade
                    })
                )}
            };
          let modal:any = document.getElementById('myEditModalCol')
          modal.style.display = "block";
        });
        break;
    case "Produção Fotográfica" :
      this.db.getProdFotoInfantilById(id).subscribe((data:any) => {
        this.parametroSelecionado = data;
        this.editarParametro.get('titulo')?.setValue(this.parametroSelecionado.titulo);
        this.editarParametro.get('descricao')?.setValue(this.reverseStrings(this.parametroSelecionado.descricao));
        this.editarParametro.get('tipo')?.setValue(this.parametroSelecionado.tipo);
        this.editarParametro.get('formando')?.setValue(this.parametroSelecionado.formando);
        this.editarParametro.get('marcado')?.setValue(this.parametroSelecionado.marcado);
        this.editarParametro.get('posicao')?.setValue(this.parametroSelecionado.posicao);
          if(this.parametroSelecionado.opcoes == null || this.parametroSelecionado.opcoes == undefined){
            this.clearFormArray(this.editarParametro.get('opcoesEditar') as FormArray);
            }else{
              let opEditar = this.editarParametro.get('opcoesEditar') as FormArray;
              for (var i=0; i<this.parametroSelecionado.opcoes.length; i++){
                  opEditar.push(this.formBuilder.group({
                    nome: this.parametroSelecionado.opcoes[i].nome, 
                    valor: this.parametroSelecionado.opcoes[i].valor,
                    cidade: this.parametroSelecionado.opcoes[i].cidade,
                    descricao: this.reverseStrings(this.parametroSelecionado.opcoes[i].descricao)
                  })
              )};
          };
          if(this.parametroSelecionado.valores == null || this.parametroSelecionado.valores == undefined){
            this.clearFormArray(this.editarParametro.get('valoresEditar') as FormArray);
            }else{
              let vlrEditar = this.editarParametro.get('valoresEditar') as FormArray;
              for (var i=0; i<this.parametroSelecionado.valores.length; i++){
                  vlrEditar.push(this.formBuilder.group({
                    valor: this.parametroSelecionado.valores[i].valor,
                    cidade: this.parametroSelecionado.valores[i].cidade
                  })
              )}
          };
        let modal:any = document.getElementById('myEditModalCol')
        modal.style.display = "block";
      });
      break;
    case "Formaturas" :
      this.db.getFormaturaInfantilById(id).subscribe((data:any) => {
        this.parametroSelecionado = data;
        this.editarParametro.get('titulo')?.setValue(this.parametroSelecionado.titulo);
        this.editarParametro.get('descricao')?.setValue(this.reverseStrings(this.parametroSelecionado.descricao));
        this.editarParametro.get('tipo')?.setValue(this.parametroSelecionado.tipo);
        this.editarParametro.get('formando')?.setValue(this.parametroSelecionado.formando);
        this.editarParametro.get('marcado')?.setValue(this.parametroSelecionado.marcado);
        this.editarParametro.get('posicao')?.setValue(this.parametroSelecionado.posicao);
          if(this.parametroSelecionado.opcoes == null || this.parametroSelecionado.opcoes == undefined){
            this.clearFormArray(this.editarParametro.get('opcoesEditar') as FormArray);
            }else{
              let opEditar = this.editarParametro.get('opcoesEditar') as FormArray;
              for (var i=0; i<this.parametroSelecionado.opcoes.length; i++){
                  opEditar.push(this.formBuilder.group({
                    nome: this.parametroSelecionado.opcoes[i].nome, 
                    valor: this.parametroSelecionado.opcoes[i].valor,
                    cidade: this.parametroSelecionado.opcoes[i].cidade,
                    descricao: this.reverseStrings(this.parametroSelecionado.opcoes[i].descricao)
                  })
              )};
          };
          if(this.parametroSelecionado.valores == null || this.parametroSelecionado.valores == undefined){
            this.clearFormArray(this.editarParametro.get('valoresEditar') as FormArray);
            }else{
              let vlrEditar = this.editarParametro.get('valoresEditar') as FormArray;
              for (var i=0; i<this.parametroSelecionado.valores.length; i++){
                  vlrEditar.push(this.formBuilder.group({
                    valor: this.parametroSelecionado.valores[i].valor,
                    cidade: this.parametroSelecionado.valores[i].cidade
                  })
              )}
          };
        let modal:any = document.getElementById('myEditModalCol')
        modal.style.display = "block";
      });
      break;
    }
}

closeEditModal(){
  this.clearFormArray(this.editarParametro.get('opcoesEditar') as FormArray);
  this.clearFormArray(this.editarParametro.get('valoresEditar') as FormArray);
  this.parametroSelecionado = "";
  let modal:any = document.getElementById('myEditModalCol')
  modal.style.display = "none";
  this.editarParametro.reset();
}

goBack(){
  this.location.back();
 }

 
}
