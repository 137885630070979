import { Component, OnDestroy, OnInit} from '@angular/core';
import { DataBaseService } from 'src/app/provider';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-turmas-escola',
  templateUrl: './turmas-escola.component.html',
  styleUrls: ['./turmas-escola.component.css']
})

export class TurmasEscolaComponent implements OnInit,OnDestroy {
  panelOpenState:boolean = false;
  panelOpenStateFilter:boolean = false;
  excluirCidadeCadastro:any = "";
  instituicaoExcluirInstituicao:any = "";
  selectedFiles:any = "";
  public tipoUsuario:any = "";
  public usuario:any = "";
  orcamentos:any = [];
  turmas:any = [];
  documentos:any = [];
  listaCidades:any = [];
  cidadeCadastrarTurma:any;
  turmaSelecionadaDetails:any = "";
  listaCidadesDetails:any = [];
  listaInstituicoesDetails:any = [];
  turmaCursoSelecionado:any;
  cursoEscolhidoDetails:any;
  backup:any = [];
  arrayLogs:any = [];
  listaCidadesNovaInstituicao:any = [];
  listaCidadesCadastrarCurso:any = [];
  listaCidadesCadastroNovaTurma:any = [];
  listaCidadesExcluir:any = [];
  listaCidadesExcluirInstituicao:any = [];
  listaCidadesExcluirCurso:any = [];
  listaInstituicoes:any = [];
  listaInstituicoesCadastrarCurso:any = [];
  listaInstituicoesNovaTurma:any = [];
  listaInstituicoesExcluirInstituicao:any = [];
  listaInstituicoesExcluirCurso:any = [];
  listaCursosNovaTurma:any = [];
  listaCursosExcluirCurso:any = [];
  cidadeTurmaSelecionado:any = "";
  instituicaoTurmaSelecionado:any = "";
  cursoTurmaSelecionado:any = "";
  periodoTurmaSelecionado:any = "";
  dataInicioTurmaSelecionado:any = "";
  dataFimTurmaSelecionado:any = "";
  anoTurmaSelecionado:any = "";
  duracaoTurmaSelecionado:any = "";
  relatorios:any = [];
  newRelatorio:boolean = false;
  novaTurma!: FormGroup; 
  public turmaId:any;
  imagemSelecionada:any;
  indexImage:number = 0; 
  contratos:any = [];
  historicosTurma:any = [];
  // DADOS CONTRATO
  presidente:any = "";
  presidenteCpf:any = "";
  presidenteRg:any = "";
  presidenteEndereco:any = "";
  presidenteRgOrgao:any = "";
  presidenteEstadoCivil:any = "";
  presidenteNacionalidade:any = "";
  presidenteTelefone:any = "";
  presidenteEmail:any = "";
  vicePresidente:any = "";
  vicePresidenteCpf:any = "";
  vicePresidenteRg:any = "";
  vicePresidenteEndereco:any = "";
  vicePresidenteRgOrgao:any = "";
  vicePresidenteEstadoCivil:any = "";
  vicePresidenteNacionalidade:any = "";
  vicePresidenteTelefone:any = "";
  vicePresidenteEmail:any = "";
  primeiroSecretario:any = "";
  primeiroSecretarioCpf:any = "";
  primeiroSecretarioRg:any = "";
  primeiroSecretarioEndereco:any = "";
  primeiroSecretarioRgOrgao:any = "";
  primeiroSecretarioEstadoCivil:any = "";
  primeiroSecretarioNacionalidade:any = "";
  primeiroSecretarioTelefone:any = "";
  primeiroSecretarioEmail:any = "";
  segundoSecretario:any = "";
  segundoSecretarioCpf:any = "";
  segundoSecretarioRg:any = "";
  segundoSecretarioEndereco:any = "";
  segundoSecretarioRgOrgao:any = "";
  segundoSecretarioEstadoCivil:any = "";
  segundoSecretarioNacionalidade:any = "";
  segundoSecretarioTelefone:any = "";
  segundoSecretarioEmail:any = "";
  terceiroSecretario:any = "";
  terceiroSecretarioCpf:any = "";
  terceiroSecretarioRg:any = "";
  terceiroSecretarioEndereco:any = "";
  terceiroSecretarioRgOrgao:any = "";
  terceiroSecretarioEstadoCivil:any = "";
  terceiroSecretarioNacionalidade:any = "";
  terceiroSecretarioTelefone:any = "";
  terceiroSecretarioEmail:any = "";
  tesoureiro:any = "";
  tesoureiroCpf:any = "";
  tesoureiroRg:any = "";
  tesoureiroEndereco:any = "";
  tesoureiroRgOrgao:any = "";
  tesoureiroEstadoCivil:any = "";
  tesoureiroNacionalidade:any = "";
  tesoureiroTelefone:any = "";
  tesoureiroEmail:any = "";
  primeiroConsFiscal:any = "";
  primeiroConsFiscalCpf:any = "";
  primeiroConsFiscalRg:any = "";
  primeiroConsFiscalEndereco:any = "";
  primeiroConsFiscalRgOrgao:any = "";
  primeiroConsFiscalEstadoCivil:any = "";
  primeiroConsFiscalNacionalidade:any = "";
  primeiroConsFiscalTelefone:any = "";
  primeiroConsFiscalEmail:any = "";
  segundoConsFiscal:any = "";
  segundoConsFiscalCpf:any = "";
  segundoConsFiscalRg:any = "";
  segundoConsFiscalEndereco:any = "";
  segundoConsFiscalRgOrgao:any = "";
  segundoConsFiscalEstadoCivil:any = "";
  segundoConsFiscalNacionalidade:any = "";
  segundoConsFiscalTelefone:any = "";
  segundoConsFiscalEmail:any = "";
  terceiroConsFiscal:any = "";
  terceiroConsFiscalCpf:any = "";
  terceiroConsFiscalRg:any = "";
  terceiroConsFiscalEndereco:any = "";
  terceiroConsFiscalRgOrgao:any = "";
  terceiroConsFiscalEstadoCivil:any = "";
  terceiroConsFiscalNacionalidade:any = "";
  terceiroConsFiscalTelefone:any = "";
  terceiroConsFiscalEmail:any = "";

  //DADOS COMISSAO
  nomeComissao:any = "";
  razaoSocialComissao:any = "";
  cnpjComissao:any = "";
  cepComissao:any = "";
  enderecoComissao:any = "";
  bairroComissao:any = "";
  cidadeComissao:any = "";
  ufComissao:any = "";
  temContrato:any = "";
  idOrcamento:any = "";
  ultimaCidadeExcluir:any = "";
  ultimaInstituicaoExcluir:any = "";
  assuntos:any = ["Apresentação Turma","Atendimento Presencial","Email", "Orçamento","Proposta Web","Reunião Comissão","Reunião Online","Telefonema","WhatsApp"];
  idTurma:any = "";
  senhaAdm:any = "";
  vendedores:any = [];
  salasTurma:any = "";
  salaAntiga:any = "";
  salaOkey:boolean = true;
  periodoTurma:number = 0;
  alunos:number = 0;
  alunosAntigo:any = 0;
  alunosHistorico:any = [];
  novaEmpresa:any = "";
  todasEmpresas:any = [];
  assuntoRelatorio:any = "";
  descricaoRelatorio:any = "";
  assuntoAgenda:any = "";
  descricaoAgenda:any = "";
  dataAgenda:any = "";
  totalPre:number = 0;
  totalFormado:number = 0;
  totalFechado:number = 0;
  totalAberto:number = 0;
  totalUrgente:number = 0;
  totalFechadoTrends:number = 0;
  totalNegociacao:number = 0;
  dtInicio:any = "";
  dtFim:any = "";
  curso:any = "";
  instituicao:any = "";
  cidadeFiltro:any = "";
  situacaoFiltro:any = "";
  consultor:any = "";
  dataHoje:any = "";
  cidadeExcluirInstituicao:any = "";
  instituicaoExcluirCurso:any = "";
  cidadeExcluirCurso:any = "";
  statusTurma:boolean = false;
  cursosEscola = [{"nome":"1º ANO"},{"nome":"2º ANO"},{"nome":"3º ANO"},{"nome":"4º ANO"},{"nome":"5º ANO"},{"nome":"PRÉ I"},{"nome":"PRÉ II"},{"nome":"PRÉ III"},{"nome":"INFANTIL 4"},{"nome":"INFANTIL 5"}];
  pastas:boolean = true;
  novaCidadeCadastro:any = "";
  cidadeCadastroNovaInstituicao:any = "";
  novaInstituicaoCadastro:any = "";
  arrayTurmas:any = [];
  turmasColegioShow:boolean = false;
  instituicoes:any = [];

  constructor(public app:AppComponent, private location:Location, private route: ActivatedRoute, public auth:AuthService, public _router:Router, private formBuilder: FormBuilder, private db: DataBaseService) {
    this.tipoUsuario = auth.tipoUsuario;
    this.usuario = localStorage.getItem('usuarioTrends');
    if(this.tipoUsuario == ""){
      this.tipoUsuario = localStorage.getItem('tipoUsuarioTrends')
    }
    if(this.usuario == ""){
      this.usuario = localStorage.getItem('usuarioTrends')
    }
   }

   ngOnDestroy(){
     this.closeModalDetails();
     this.closeModal();
   }

  ngOnInit(){
    this.turmasColegioShow = false;
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.turmaId = this.route.snapshot.paramMap.get('key');
    if(this.turmaId != null){
      this.openModalDetails(this.turmaId)
    } 
    this.getTurmas();
    this.getEmpresas();
    this.db.getUsuarios().subscribe((data:any) => this.vendedores = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}}));
     this.db.getCidades().subscribe((data:any) => this.listaCidades = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}}));
     this.novaTurma = this.formBuilder.group({
      cidade : [null, Validators.required],
      instituicao: [null, Validators.required],
      curso: [null, Validators.required],
      periodo: [null],
      dataInicio: [null, Validators.required],
      dataFim: [null, Validators.required],
      ano: [null],
      duracao: [null],
      turma: [null, Validators.required],
      sala: [null, Validators.required],
      alunos: [null, Validators.required],
      situacao: [null, Validators.required],
      consultor: [this.usuario],
      empresa: [null],
      nomeComissao: [null],
      razaoSocialComissao: [null],
      cnpjComissao: [null],
      cepComissao : [null],
      enderecoComissao: [null],
      bairroComissao: [null],
      cidadeComissao: [null],
      ufComissao: [null],
      presidente : [null],
      presidenteCpf : [null],
      presidenteRg : [null],
      presidenteEndereco : [null],
      presidenteRgOrgao : [null],
      presidenteEstadoCivil : [null],
      presidenteNacionalidade : [null],
      presidenteTelefone : [null],
      presidenteEmail : [null],
      vicePresidente : [null],
      vicePresidenteCpf : [null],
      vicePresidenteRg : [null],
      vicePresidenteEndereco : [null],
      vicePresidenteRgOrgao : [null],
      vicePresidenteEstadoCivil : [null],
      vicePresidenteNacionalidade : [null],
      vicePresidenteTelefone : [null],
      vicePresidenteEmail : [null],
      primeiroSecretario : [null],
      primeiroSecretarioCpf : [null],
      primeiroSecretarioRg : [null],
      primeiroSecretarioEndereco : [null],
      primeiroSecretarioRgOrgao : [null],
      primeiroSecretarioEstadoCivil : [null],
      primeiroSecretarioNacionalidade : [null],
      primeiroSecretarioTelefone : [null],
      primeiroSecretarioEmail : [null],
      segundoSecretario : [null],
      segundoSecretarioCpf : [null],
      segundoSecretarioRg : [null],
      segundoSecretarioEndereco : [null],
      segundoSecretarioRgOrgao : [null],
      segundoSecretarioEstadoCivil : [null],
      segundoSecretarioNacionalidade : [null],
      segundoSecretarioTelefone : [null],
      segundoSecretarioEmail : [null],
      terceiroSecretario : [null],
      terceiroSecretarioCpf : [null],
      terceiroSecretarioRg : [null],
      terceiroSecretarioEndereco : [null],
      terceiroSecretarioRgOrgao : [null],
      terceiroSecretarioEstadoCivil : [null],
      terceiroSecretarioNacionalidade : [null],
      terceiroSecretarioTelefone : [null],
      terceiroSecretarioEmail : [null],
      tesoureiro : [null],
      tesoureiroCpf : [null],
      tesoureiroRg : [null],
      tesoureiroEndereco : [null],
      tesoureiroRgOrgao : [null],
      tesoureiroEstadoCivil : [null],
      tesoureiroNacionalidade : [null],
      tesoureiroTelefone : [null],
      tesoureiroEmail : [null],
      primeiroConsFiscal : [null],
      primeiroConsFiscalCpf : [null],
      primeiroConsFiscalRg : [null],
      primeiroConsFiscalEndereco : [null],
      primeiroConsFiscalRgOrgao : [null],
      primeiroConsFiscalEstadoCivil : [null],
      primeiroConsFiscalNacionalidade : [null],
      primeiroConsFiscalTelefone : [null],
      primeiroConsFiscalEmail : [null],
      segundoConsFiscal : [null],
      segundoConsFiscalCpf : [null],
      segundoConsFiscalRg : [null],
      segundoConsFiscalEndereco : [null],
      segundoConsFiscalRgOrgao : [null],
      segundoConsFiscalEstadoCivil : [null],
      segundoConsFiscalNacionalidade : [null],
      segundoConsFiscalTelefone : [null],
      segundoConsFiscalEmail : [null],
      terceiroConsFiscal : [null],
      terceiroConsFiscalCpf : [null],
      terceiroConsFiscalRg : [null],
      terceiroConsFiscalEndereco : [null],
      terceiroConsFiscalRgOrgao : [null],
      terceiroConsFiscalEstadoCivil : [null],
      terceiroConsFiscalNacionalidade : [null],
      terceiroConsFiscalTelefone : [null],
      terceiroConsFiscalEmail : [null],
      periodoTurma : [null]
      });
      this.db.getInstituicoesEscola().subscribe((ints:any) => {
        let ins:any = [];
        let ctrl = 1;
        ints.forEach((e:any) => {
         let i = ins.map((ew:any) => {
           return ew;
         }).indexOf(e.nome);
         if(i < 0){
           ins.push(e.nome)
         }
         if(ints.length == ctrl){
           this.instituicoes = ins.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}return 1})
         }else{
           ctrl++;
         }
        })
     })
  }

  getEmpresas(){
    this.db.getEmpresas().subscribe((data:any) => {
      this.todasEmpresas = data;
    })
  }

  getTurmas(){
    this.pastas = true;
    this.turmas = [];
    let array:any = [];
    let ctrl = 1;
    this.db.getTodasTurmas("ESCOLA",false).subscribe((data:any) => {
      data.forEach((item:any) => {
        let idx = array.map((i:any) => {
          return i.cidade
        }).indexOf(item.cidade);
        if(idx >= 0){
         let indice = array[idx].instituicao.map((c:any) => {
          return Object.entries(c)[0][0]
          }).indexOf(item.instituicao)
         if(indice >= 0){
          array[idx].instituicao[indice][item.instituicao].turmas.push(item);
         }else{
          array[idx].instituicao.push({
               [item.instituicao]:{
                  "turmas":[item]
                }
          });
         }
        }else{
          array.push({
            "cidade":item.cidade,
            "instituicao": [{
               [item.instituicao]:{
                  "turmas":[item]
                }
              }]
          });
        }
        if(data.length == ctrl){
          this.turmas = array.sort((a:any,b:any) => {
            if(a.cidade < b.cidade){return -1}
            if(a.cidade > b.cidade){return 1}
            if(a.turma?.instituicao < b.turma?.instituicao){return -1}
            if(a.turma?.instituicao > b.turma?.instituicao){return 1}
            return 1;
          });
            this.contarTurmas();
        }else{
          ctrl++
        }
      })
    });
  }

  todasTurmas(){
    this.turmas = [];
    this.db.getTodasTurmas("ESCOLA",false).subscribe((data:any) => {
      this.turmas = data.sort((a:any,b:any) => {
        if(a.cidade < b.cidade){return -1}
        if(a.cidade > b.cidade){return 1}
        if(a.instituicao < b.instituicao){return -1}
        if(a.instituicao > b.instituicao){return 1}
        if(a.curso < b.curso){return -1}
        if(a.curso > b.curso){return 1}
        return 1;
      });
      this.pastas = false;
      this.contarTurmas();
    });
  }

  contarTurmas(){
    this.totalPre = 0;
    this.totalFechado = 0;
    this.totalAberto = 0;
    this.totalUrgente = 0;
    this.totalFechadoTrends = 0;
    this.totalNegociacao = 0;
    this.totalFormado = 0;
    this.turmas.forEach((item:any) => {
      if(item.situacao == "ABERTO"){
        this.totalAberto++
      }
      if(item.situacao == "FECHADO" && item.empresa != 'TRENDS FORMATURAS'){
        this.totalFechado++;
      }
      if(item.situacao == "FECHADO" && item.empresa == 'TRENDS FORMATURAS'){
        this.totalFechadoTrends++;
      }
      if(item.situacao == "ATENDIMENTO URGENTE"){
        this.totalUrgente++;
      }
      if(item.situacao == "PRÉ-CADASTRO"){
        this.totalPre++;
      }
      if(item.situacao == "NEGOCIAÇÃO"){
        this.totalNegociacao++;
      }
      if(item.situacao == "FORMADO"){
        this.totalFormado++;
      }
    })
  }
  
  buscarTurmas(){
    this.pastas = false;
    this.db.getTurmasGeralColegio(this.dtInicio,this.dtFim,this.curso,this.instituicao,this.cidadeFiltro,this.situacaoFiltro,this.consultor).subscribe((data:any) => {
      this.turmas = data.sort((a:any,b:any) => {
        if(a.cidade < b.cidade){return -1}
        if(a.cidade > b.cidade){return 1}
        if(a.instituicao < b.instituicao){return -1}
        if(a.instituicao > b.instituicao){return 1}
        if(a.curso < b.curso){return -1}
        if(a.curso > b.curso){return 1}
        return 1;
      })
      this.contarTurmas();
    })
  }

  addTurma(){
    let modal:any = document.getElementById('addTurmaModalCol')
    modal.style.display = "block";
    modal.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    this.db.getCidades().subscribe((data:any) => {
      this.listaCidadesNovaInstituicao = data.sort((a:any,b:any) => {
        if(a.nome < b.nome){return -1}
        if(a.nome > b.nome){return 1}
        return 1;
      });
      this.listaCidadesCadastrarCurso = data.sort((a:any,b:any) => {
        if(a.nome < b.nome){return -1}
        if(a.nome > b.nome){return 1}
        return 1;
      });
      this.listaCidadesCadastroNovaTurma = data.sort((a:any,b:any) => {
        if(a.nome < b.nome){return -1}
        if(a.nome > b.nome){return 1}
        return 1;
      });
      this.listaCidadesExcluir = data.sort((a:any,b:any) => {
        if(a.nome < b.nome){return -1}
        if(a.nome > b.nome){return 1}
        return 1;
      });
      this.listaCidadesExcluirInstituicao = data.sort((a:any,b:any) => {
        if(a.nome < b.nome){return -1}
        if(a.nome > b.nome){return 1}
        return 1;
      });
      this.listaCidadesExcluirCurso = data.sort((a:any,b:any) => {
        if(a.nome < b.nome){return -1}
        if(a.nome > b.nome){return 1}
        return 1;
      });
    });
  }

  closeAddTurma(){
      let modal:any = document.getElementById('addTurmaModalCol')
      modal.style.display = "none";
      this.novaTurma.reset();
      this.novaTurma.get('consultor')?.setValue(this.usuario);
      this.limparSelects();
  }

  openModal(){
    let modal:any = document.getElementById('myModalColegio')
    modal.style.display = "block";
    this.db.getCidades().subscribe((data:any) => {
      this.listaCidadesNovaInstituicao = data.sort((a:any,b:any) => {
        if(a.nome < b.nome){return -1}
        if(a.nome > b.nome){return 1}
        return 1;
      });
      this.listaCidadesCadastrarCurso = data.sort((a:any,b:any) => {
        if(a.nome < b.nome){return -1}
        if(a.nome > b.nome){return 1}
        return 1;
      });
      this.listaCidadesCadastroNovaTurma = data.sort((a:any,b:any) => {
        if(a.nome < b.nome){return -1}
        if(a.nome > b.nome){return 1}
        return 1;
      });
      this.listaCidadesExcluir = data.sort((a:any,b:any) => {
        if(a.nome < b.nome){return -1}
        if(a.nome > b.nome){return 1}
        return 1;
      });
      this.listaCidadesExcluirInstituicao = data.sort((a:any,b:any) => {
        if(a.nome < b.nome){return -1}
        if(a.nome > b.nome){return 1}
        return 1;
      });
      this.listaCidadesExcluirCurso = data.sort((a:any,b:any) => {
        if(a.nome < b.nome){return -1}
        if(a.nome > b.nome){return 1}
        return 1;
      });
    });
  }

  closeModal(){
      let modal:any = document.getElementById('myModalColegio')
      modal.style.display = "none";
      this.novaTurma.reset();
      this.novaTurma.get('consultor')?.setValue(this.usuario);
      this.salaOkey = true;
      this.periodoTurma = 0;
      this.alunos = 0;
      this.novaEmpresa = "";
  }

  openModalDetails(id:any){
      this.turmaId = id;
      let modal:any = document.getElementById('myModalDetailsCol');
      modal.style.display = "block";
      this.db.getCidades().subscribe((data:any) => {
        this.listaCidadesDetails = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
        this.db.getTurmaById(id).subscribe((data:any) => {
            this.turmaSelecionadaDetails = data;
            this.backup = data;
            this.cidadeTurmaSelecionado = this.turmaSelecionadaDetails.cidade;
            this.instituicaoTurmaSelecionado = this.turmaSelecionadaDetails.instituicao;
            this.cursoTurmaSelecionado = this.turmaSelecionadaDetails.curso;
            this.periodoTurmaSelecionado = this.turmaSelecionadaDetails.periodo;
            this.dataInicioTurmaSelecionado = this.turmaSelecionadaDetails.dataInicio;
            this.dataFimTurmaSelecionado = this.turmaSelecionadaDetails.dataFim;
            this.anoTurmaSelecionado = this.turmaSelecionadaDetails.ano;
            this.duracaoTurmaSelecionado = this.turmaSelecionadaDetails.duracao;
            this.periodoTurma = this.turmaSelecionadaDetails.periodoTurma;
            this.alunos = this.turmaSelecionadaDetails.alunos;
            this.alunosAntigo = this.turmaSelecionadaDetails.alunos;
            this.salaAntiga = this.turmaSelecionadaDetails.sala;
            this.presidente = this.turmaSelecionadaDetails.presidente;
            this.presidenteCpf = this.turmaSelecionadaDetails.presidenteCpf;
            this.presidenteRg = this.turmaSelecionadaDetails.presidenteRg;
            this.presidenteEndereco = this.turmaSelecionadaDetails.presidenteEndereco;
            this.presidenteRgOrgao = this.turmaSelecionadaDetails.presidenteRgOrgao;
            this.presidenteEstadoCivil = this.turmaSelecionadaDetails.presidenteEstadoCivil;
            this.presidenteNacionalidade = this.turmaSelecionadaDetails.presidenteNacionalidade;
            this.presidenteTelefone = this.turmaSelecionadaDetails.presidenteTelefone;
            this.vicePresidente = this.turmaSelecionadaDetails.vicePresidente;
            this.vicePresidenteCpf = this.turmaSelecionadaDetails.vicePresidenteCpf;
            this.vicePresidenteRg = this.turmaSelecionadaDetails.vicePresidenteRg;
            this.vicePresidenteEndereco = this.turmaSelecionadaDetails.vicePresidenteEndereco;
            this.vicePresidenteRgOrgao = this.turmaSelecionadaDetails.vicePresidenteRgOrgao;
            this.vicePresidenteEstadoCivil = this.turmaSelecionadaDetails.vicePresidenteEstadoCivil;
            this.vicePresidenteNacionalidade = this.turmaSelecionadaDetails.vicePresidenteNacionalidade;
            this.vicePresidenteTelefone = this.turmaSelecionadaDetails.vicePresidenteTelefone;
            this.primeiroSecretario = this.turmaSelecionadaDetails.primeiroSecretario;
            this.primeiroSecretarioCpf = this.turmaSelecionadaDetails.primeiroSecretarioCpf;
            this.primeiroSecretarioRg = this.turmaSelecionadaDetails.primeiroSecretarioRg;
            this.primeiroSecretarioEndereco = this.turmaSelecionadaDetails.primeiroSecretarioEndereco;
            this.primeiroSecretarioRgOrgao = this.turmaSelecionadaDetails.primeiroSecretarioRgOrgao;
            this.primeiroSecretarioEstadoCivil = this.turmaSelecionadaDetails.primeiroSecretarioEstadoCivil;
            this.primeiroSecretarioNacionalidade = this.turmaSelecionadaDetails.primeiroSecretarioNacionalidade;
            this.primeiroSecretarioTelefone = this.turmaSelecionadaDetails.primeiroSecretarioTelefone;
            this.segundoSecretario = this.turmaSelecionadaDetails.segundoSecretario;
            this.segundoSecretarioCpf = this.turmaSelecionadaDetails.segundoSecretarioCpf;
            this.segundoSecretarioRg = this.turmaSelecionadaDetails.segundoSecretarioRg;
            this.segundoSecretarioEndereco = this.turmaSelecionadaDetails.segundoSecretarioEndereco;
            this.segundoSecretarioRgOrgao = this.turmaSelecionadaDetails.segundoSecretarioRgOrgao;
            this.segundoSecretarioEstadoCivil = this.turmaSelecionadaDetails.segundoSecretarioEstadoCivil;
            this.segundoSecretarioNacionalidade = this.turmaSelecionadaDetails.segundoSecretarioNacionalidade;
            this.segundoSecretarioTelefone = this.turmaSelecionadaDetails.segundoSecretarioTelefone;
            this.terceiroSecretario = this.turmaSelecionadaDetails.terceiroSecretario;
            this.terceiroSecretarioCpf = this.turmaSelecionadaDetails.terceiroSecretarioCpf;
            this.terceiroSecretarioRg = this.turmaSelecionadaDetails.terceiroSecretarioRg;
            this.terceiroSecretarioEndereco = this.turmaSelecionadaDetails.terceiroSecretarioEndereco;
            this.terceiroSecretarioRgOrgao = this.turmaSelecionadaDetails.terceiroSecretarioRgOrgao;
            this.terceiroSecretarioEstadoCivil = this.turmaSelecionadaDetails.terceiroSecretarioEstadoCivil;
            this.terceiroSecretarioNacionalidade = this.turmaSelecionadaDetails.terceiroSecretarioNacionalidade;
            this.terceiroSecretarioTelefone = this.turmaSelecionadaDetails.terceiroSecretarioTelefone;
            this.tesoureiro = this.turmaSelecionadaDetails.tesoureiro;
            this.tesoureiroCpf = this.turmaSelecionadaDetails.tesoureiroCpf;
            this.tesoureiroRg = this.turmaSelecionadaDetails.tesoureiroRg;
            this.tesoureiroEndereco = this.turmaSelecionadaDetails.tesoureiroEndereco;
            this.tesoureiroRgOrgao = this.turmaSelecionadaDetails.tesoureiroRgOrgao;
            this.tesoureiroEstadoCivil = this.turmaSelecionadaDetails.tesoureiroEstadoCivil;
            this.tesoureiroNacionalidade = this.turmaSelecionadaDetails.tesoureiroNacionalidade;
            this.tesoureiroTelefone = this.turmaSelecionadaDetails.tesoureiroTelefone;
            this.primeiroConsFiscal = this.turmaSelecionadaDetails.primeiroConsFiscal;
            this.primeiroConsFiscalCpf = this.turmaSelecionadaDetails.primeiroConsFiscalCpf;
            this.primeiroConsFiscalRg = this.turmaSelecionadaDetails.primeiroConsFiscalRg;
            this.primeiroConsFiscalEndereco = this.turmaSelecionadaDetails.primeiroConsFiscalEndereco;
            this.primeiroConsFiscalRgOrgao = this.turmaSelecionadaDetails.primeiroConsFiscalRgOrgao;
            this.primeiroConsFiscalEstadoCivil = this.turmaSelecionadaDetails.primeiroConsFiscalEstadoCivil;
            this.primeiroConsFiscalNacionalidade = this.turmaSelecionadaDetails.primeiroConsFiscalNacionalidade;
            this.primeiroConsFiscalTelefone = this.turmaSelecionadaDetails.primeiroConsFiscalTelefone;
            this.segundoConsFiscal = this.turmaSelecionadaDetails.segundoConsFiscal;
            this.segundoConsFiscalCpf = this.turmaSelecionadaDetails.segundoConsFiscalCpf;
            this.segundoConsFiscalRg = this.turmaSelecionadaDetails.segundoConsFiscalRg;
            this.segundoConsFiscalEndereco = this.turmaSelecionadaDetails.segundoConsFiscalEndereco;
            this.segundoConsFiscalRgOrgao = this.turmaSelecionadaDetails.segundoConsFiscalRgOrgao;
            this.segundoConsFiscalEstadoCivil = this.turmaSelecionadaDetails.segundoConsFiscalEstadoCivil;
            this.segundoConsFiscalNacionalidade = this.turmaSelecionadaDetails.segundoConsFiscalNacionalidade;
            this.segundoConsFiscalTelefone = this.turmaSelecionadaDetails.segundoConsFiscalTelefone;
            this.terceiroConsFiscal = this.turmaSelecionadaDetails.terceiroConsFiscal;
            this.terceiroConsFiscalCpf = this.turmaSelecionadaDetails.terceiroConsFiscalCpf;
            this.terceiroConsFiscalRg = this.turmaSelecionadaDetails.terceiroConsFiscalRg;
            this.terceiroConsFiscalEndereco = this.turmaSelecionadaDetails.terceiroConsFiscalEndereco;
            this.terceiroConsFiscalRgOrgao = this.turmaSelecionadaDetails.terceiroConsFiscalRgOrgao;
            this.terceiroConsFiscalEstadoCivil = this.turmaSelecionadaDetails.terceiroConsFiscalEstadoCivil;
            this.terceiroConsFiscalNacionalidade = this.turmaSelecionadaDetails.terceiroConsFiscalNacionalidade;
            this.terceiroConsFiscalTelefone = this.turmaSelecionadaDetails.terceiroConsFiscalTelefone;
            this.nomeComissao = this.turmaSelecionadaDetails.nomeComissao;
            this.razaoSocialComissao = this.turmaSelecionadaDetails.razaoSocialComissao;
            this.cnpjComissao = this.turmaSelecionadaDetails.cnpjComissao;
            this.cepComissao = this.turmaSelecionadaDetails.cepComissao;
            this.enderecoComissao = this.turmaSelecionadaDetails.enderecoComissao;
            this.bairroComissao = this.turmaSelecionadaDetails.bairroComissao;
            this.cidadeComissao = this.turmaSelecionadaDetails.cidadeComissao;
            this.ufComissao = this.turmaSelecionadaDetails.ufComissao;
            this.temContrato = this.turmaSelecionadaDetails.contrato;
            this.presidenteEmail = this.turmaSelecionadaDetails.presidenteEmail;
            this.vicePresidenteEmail = this.turmaSelecionadaDetails.vicePresidenteEmail;
            this.primeiroSecretarioEmail = this.turmaSelecionadaDetails.primeiroSecretarioEmail;
            this.segundoSecretarioEmail = this.turmaSelecionadaDetails.segundoSecretarioEmail;
            this.terceiroSecretarioEmail = this.turmaSelecionadaDetails.terceiroSecretarioEmail;
            this.tesoureiroEmail = this.turmaSelecionadaDetails.tesoureiroEmail;
            this.primeiroConsFiscalEmail = this.turmaSelecionadaDetails.primeiroConsFiscalEmail;
            this.segundoConsFiscalEmail = this.turmaSelecionadaDetails.segundoConsFiscalEmail;
            this.terceiroConsFiscalEmail = this.turmaSelecionadaDetails.terceiroConsFiscalEmail;
            this.historicosTurma = this.turmaSelecionadaDetails.historico;
            if(this.turmaSelecionadaDetails.salas){
              this.salasTurma = [];
              let ctrl = 1;
              this.turmaSelecionadaDetails.salas.reverse().forEach((sl:any) => {
                this.salasTurma.push(sl.data.split('-').reverse().join('/')+" - "+sl.sala);
                if(this.turmaSelecionadaDetails.salas.length == ctrl){
                  this.salasTurma = this.salasTurma.join(' \n ')
                }else{
                  ctrl++;
                }
              })
            }else{
              this.salasTurma = "";
            }
            //
            if(this.turmaSelecionadaDetails.alunosHistorico){
              this.alunosHistorico = [];
              let ctrl = 1;
              this.turmaSelecionadaDetails.alunosHistorico.reverse().forEach((sl:any) => {
                this.alunosHistorico.push(sl.data.split('-').reverse().join('/')+" - "+sl.alunos);
                if(this.turmaSelecionadaDetails.alunosHistorico.length == ctrl){
                  this.alunosHistorico = this.alunosHistorico.join(' \n ')
                }else{
                  ctrl++;
                }
              })
            }else{
              this.alunosHistorico = "";
            }
            this.db.getInstituicoesEscolaByCidade(this.cidadeTurmaSelecionado).subscribe((data:any) => {
              this.listaInstituicoesDetails = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
                  this.db.getOrcamentoByTurmaId(id).subscribe((data:any) => {
                    this.orcamentos = data;
                    this.db.getDocumentosByTurmaId(id).subscribe((data:any) => {
                      this.documentos = data;
                      this.db.getRelatoriosByTurmaId(id).subscribe((data:any) => {
                        this.relatorios = data;
                        this.db.getContratosByTurmaId(id).subscribe((data:any) => this.contratos = data)
                })
              })
            })
        });           
      });
    });
  }

  closeModalDetails(){
    let modal:any = document.getElementById('myModalDetailsCol');
    modal.style.display = "none";
    this.backup = [];
    this.arrayLogs = [];
    this.turmaSelecionadaDetails = [];
    this.cidadeTurmaSelecionado = "";
    this.instituicaoTurmaSelecionado = "";
    this.cursoTurmaSelecionado = "";
    this.periodoTurmaSelecionado = "";
    this.relatorios = [];
    this.documentos = [];
    this.presidenteCpf = "";
    this.presidenteRg = "";
    this.presidenteEndereco = "";
    this.presidenteRgOrgao = "";
    this.presidenteEstadoCivil = "";
    this.presidenteNacionalidade = "";
    this.vicePresidente = "";
    this.vicePresidenteCpf = "";
    this.vicePresidenteRg = "";
    this.vicePresidenteEndereco = "";
    this.vicePresidenteRgOrgao = "";
    this.vicePresidenteEstadoCivil = "";
    this.vicePresidenteNacionalidade = "";
    this.primeiroSecretario = "";
    this.primeiroSecretarioCpf = "";
    this.primeiroSecretarioRg = "";
    this.primeiroSecretarioEndereco = "";
    this.primeiroSecretarioRgOrgao = "";
    this.primeiroSecretarioEstadoCivil = "";
    this.primeiroSecretarioNacionalidade = "";
    this.segundoSecretario = "";
    this.segundoSecretarioCpf = "";
    this.segundoSecretarioRg = "";
    this.segundoSecretarioEndereco = "";
    this.segundoSecretarioRgOrgao = "";
    this.segundoSecretarioEstadoCivil = "";
    this.segundoSecretarioNacionalidade = "";
    this.terceiroSecretario = "";
    this.terceiroSecretarioCpf = "";
    this.terceiroSecretarioRg = "";
    this.terceiroSecretarioEndereco = "";
    this.terceiroSecretarioRgOrgao = "";
    this.terceiroSecretarioEstadoCivil = "";
    this.terceiroSecretarioNacionalidade = "";
    this.tesoureiro = "";
    this.tesoureiroCpf = "";
    this.tesoureiroRg = "";
    this.tesoureiroEndereco = "";
    this.tesoureiroRgOrgao = "";
    this.tesoureiroEstadoCivil = "";
    this.tesoureiroNacionalidade = "";
    this.primeiroConsFiscal = "";
    this.primeiroConsFiscalCpf = "";
    this.primeiroConsFiscalRg = "";
    this.primeiroConsFiscalEndereco = "";
    this.primeiroConsFiscalRgOrgao = "";
    this.primeiroConsFiscalEstadoCivil = "";
    this.primeiroConsFiscalNacionalidade = "";
    this.segundoConsFiscal = "";
    this.segundoConsFiscalCpf = "";
    this.segundoConsFiscalRg = "";
    this.segundoConsFiscalEndereco = "";
    this.segundoConsFiscalRgOrgao = "";
    this.segundoConsFiscalEstadoCivil = "";
    this.segundoConsFiscalNacionalidade = "";
    this.terceiroConsFiscal = "";
    this.terceiroConsFiscalCpf = "";
    this.terceiroConsFiscalRg = "";
    this.terceiroConsFiscalEndereco = "";
    this.terceiroConsFiscalRgOrgao = "";
    this.terceiroConsFiscalEstadoCivil = "";
    this.terceiroConsFiscalNacionalidade = "";
    this.nomeComissao = "";
    this.razaoSocialComissao = "";
    this.cnpjComissao = "";
    this.cepComissao = "";
    this.enderecoComissao = "";
    this.bairroComissao = "";
    this.cidadeComissao = "";
    this.ufComissao = "";
    this.temContrato = "";
    this.salaAntiga = "";
    this.alunosAntigo = 0;
    this.presidenteTelefone = "";
    this.vicePresidenteTelefone = "";
    this.primeiroSecretarioTelefone = "";
    this.segundoSecretarioTelefone = "";
    this.terceiroSecretarioTelefone = "";
    this.tesoureiroTelefone = "";
    this.primeiroConsFiscalTelefone = "";
    this.segundoConsFiscalTelefone = "";
    this.terceiroConsFiscalTelefone = "";
    this.presidenteEmail = "";
    this.vicePresidenteEmail = "";
    this.primeiroSecretarioEmail = "";
    this.segundoSecretarioEmail = "";
    this.terceiroSecretarioEmail = "";
    this.tesoureiroEmail = "";
    this.primeiroConsFiscalEmail = "";
    this.segundoConsFiscalEmail = "";
    this.terceiroConsFiscalEmail = "";
    this.salaOkey = true;
    this.periodoTurma = 0;
    this.alunos = 0;
    this.historicosTurma = [];
    this.getTurmas();
  }

  mudarCidadeDetailsTurma(cidade:any){
  this.cidadeTurmaSelecionado = cidade;
  this.instituicaoTurmaSelecionado = "";
  this.cursoTurmaSelecionado = "";
  this.periodoTurmaSelecionado = "";
  this.db.getInstituicoesEscolaByCidade(cidade).subscribe((data:any) => {
    this.listaInstituicoesDetails = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});;
      if(this.listaInstituicoesDetails == ""){
         this.app.openAlert("Não Há Instituições Cadastradas Neste Cidade!")
      }
  });
  } 

  mudarInstituicaoDetailsTurma(instituicao:any){
    this.instituicaoTurmaSelecionado = instituicao;
    this.cursoTurmaSelecionado = "";
    this.periodoTurmaSelecionado = "";
  } 

  diferencaMesesDetails(dataInicio:any, dataFim:any){
    let dataHoje = new Date(Date.now() - 10800000)
    let dtInicio = new Date(dataInicio);
    let dtFim = new Date(dataFim);
    var d1Y = dtInicio.getFullYear();
    var d2Y = dataHoje.getFullYear();
    var d1M = dtInicio.getMonth();
    var d2M = dataHoje.getMonth();  
    var d1Y2 = dtInicio.getFullYear();
    var d2Y2 = dtFim.getFullYear();
    var d1M2 = dtInicio.getMonth();
    var d2M2 = dtFim.getMonth();
    let meses = Math.abs((d2M+12*d2Y)-(d1M+12*d1Y));
    let duracao = Math.trunc(((d2M2+12*d2Y2)-(d1M2+12*d1Y2))/12);
    let ano:number = 0;
    let diffMeses = Math.abs(dtInicio.getMonth() - dataHoje.getMonth() +  (12 * (dtInicio.getFullYear() - dataHoje.getFullYear())));
    let periodo = 0; 
    if(diffMeses >= 0 && diffMeses <= 5){periodo = 1;}
    if(diffMeses >= 6 && diffMeses <= 11){periodo = 2;}
    if(diffMeses >= 12 && diffMeses <= 17){periodo = 3;}
    if(diffMeses >= 18 && diffMeses <= 23){periodo = 4;}
    if(diffMeses >= 24 && diffMeses <= 29){periodo = 5;}
    if(diffMeses >= 30 && diffMeses <= 35){periodo = 6;}
    if(diffMeses >= 36 && diffMeses <= 41){periodo = 7;}
    if(diffMeses >= 42 && diffMeses <= 49){periodo = 8;}
    if(diffMeses >= 48 && diffMeses <= 53){periodo = 9;}
    if(diffMeses >= 54 && diffMeses <= 59){periodo = 10;}
    if(diffMeses >= 60 && diffMeses <= 65){periodo = 11;}
    if(diffMeses >= 66 && diffMeses <= 71){periodo = 12;}
    if(diffMeses >= 72 && diffMeses <= 77){periodo = 13;}
    if(diffMeses >= 78 && diffMeses <= 83){periodo = 14;}
    if(meses <= 11){ano = 1;}
    if(meses >= 12 && meses < 24){ano = 2;}
    if(meses >= 24 && meses < 36){ano = 3;}
    if(meses >= 36 && meses < 48){ano = 4;}
    if(meses >= 48 && meses < 60){ano = 5;}
    if(meses >= 60 && meses < 72){ano = 6;}
    if(meses >= 72){ano = 0;}
    this.anoTurmaSelecionado = ano;
    this.duracaoTurmaSelecionado = duracao;
    this.periodoTurma = periodo;
  } 

  selecionarCidadeCadastrarNovaTurma(cidade:any){
    this.novaTurma.get('instituicao')?.setValue("");
    this.novaTurma.get('curso')?.setValue("");
    this.cidadeCadastrarTurma = cidade;
    this.listaCursosNovaTurma = [];
    this.listaInstituicoesNovaTurma = [];
    this.novaTurma.patchValue({periodo : ""});
      this.db.getInstituicoesEscolaByCidade(cidade).subscribe((data:any) => {
        this.listaInstituicoesNovaTurma = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});;
        if(this.listaInstituicoesNovaTurma == ""){
           this.app.openAlert("Não há Escolas Cadastradas Para Essa Cidade!")
        }
      });
  }

  cadastrarNovaCidade(novaCidade:any){
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    if(window.confirm('Deseja Adicionar a Nova Cidade?')){
      let obj = {
        nome: novaCidade
      }
      this.db.postCidade(obj).subscribe(res => {
      let ob = {
          data:this.dataHoje,
          hora:hora,
          usuario: localStorage.getItem('usuarioTrends'),
          menu: "TURMAS",
          descricao: "Nova Cidade Cadastrada: "+novaCidade
        }
        this.db.postLogs(ob).subscribe(res => {
        console.log(res);
        this.db.getCidades().subscribe((data:any) => {
          this.listaCidadesNovaInstituicao = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
          this.listaCidadesCadastrarCurso = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
          this.listaCidadesCadastroNovaTurma = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
          this.listaCidadesExcluir = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
          this.listaCidadesExcluirInstituicao = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
          this.listaCidadesExcluirCurso = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
        });
         this.app.openAlert("Cidade Cadastrada Com Sucesso!")},err => {
        console.log(err);
         this.app.openAlert("Não Foi Possivel Cadastrar Cidade! Tente Novamente!")
        });},err => console.log(err))
      }
  }

  cadastrarNovaInstituicao(cidadeInstituicao:any, novaInstituicao:any){
    if(window.confirm('Deseja Adicionar a Nova Escola?')){
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let obj = {
        nome : novaInstituicao,
        cidade : cidadeInstituicao,
        tipo:"ESCOLA"
      }
      this.db.postInstituicao(obj).subscribe(res => {
        console.log(res);
            let ob = {
                data:this.dataHoje,
                hora:hora,
                usuario: localStorage.getItem('usuarioTrends'),
                menu: "TURMAS",
                descricao: "Nova Escola Cadastrado: "+novaInstituicao+" na cidade: "+cidadeInstituicao
              }
              this.db.postLogs(ob).subscribe(res => {
                console.log(res);
                 this.app.openAlert("Escola Cadastrada Com Sucesso!");
                this.limparSelects();
              },err => {
                console.log(err);
                 this.app.openAlert("Não Foi Possivel Cadastrar a Escola! Tente Novamente!")
              })
          },err => console.log(err))
        }
  }

  cadastrarNovaTurma(){
    if(window.confirm('Deseja Adicionar a Nova Turma?')){
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let inst = this.novaTurma.get('instituicao')?.value.slice(0,3);
      let cur = this.novaTurma.get('curso')?.value.slice(0,3);
      let codigo = (inst+cur+Math.random().toString().slice(2,11)).normalize('NFD').replace(/[\u0300-\u036f]/g,"");
      let obj = {
        codigoTurma:codigo,
        cidade : this.novaTurma.get('cidade')?.value,
        instituicao: this.novaTurma.get('instituicao')?.value,
        curso: this.novaTurma.get('curso')?.value,
        graduacao: "PRESENCIAL",    
        periodo: this.novaTurma.get('periodo')?.value,
        dataInicio: this.novaTurma.get('dataInicio')?.value,
        dataFim: this.novaTurma.get('dataFim')?.value,
        ano: this.novaTurma.get('ano')?.value,
        alunos:this.novaTurma.get('alunos')?.value,
        duracao: this.novaTurma.get('duracao')?.value,
        turma: this.novaTurma.get('turma')?.value,
        sala: this.novaTurma.get('sala')?.value,
        situacao: this.novaTurma.get('situacao')?.value,
        empresa: this.novaTurma.get('empresa')?.value,
        consultor: this.novaTurma.get('consultor')?.value,
        periodoTurma: this.novaTurma.get('periodoTurma')?.value,
        nomeComissao: this.novaTurma.get('nomeComissao')?.value,
        razaoSocialComissao: this.novaTurma.get('razaoSocialComissao')?.value,
        cnpjComissao: this.novaTurma.get('cnpjComissao')?.value,
        cepComissao : this.novaTurma.get('cepComissao')?.value,
        enderecoComissao: this.novaTurma.get('enderecoComissao')?.value,
        bairroComissao: this.novaTurma.get('bairroComissao')?.value,
        cidadeComissao: this.novaTurma.get('cidadeComissao')?.value,
        ufComissao: this.novaTurma.get('ufComissao')?.value ,
        presidente : this.novaTurma.get('presidente')?.value,
        presidenteCpf : this.novaTurma.get('presidenteCpf')?.value,
        presidenteRg : this.novaTurma.get('presidenteRg')?.value,
        presidenteEndereco : this.novaTurma.get('presidenteEndereco')?.value,
        presidenteRgOrgao : this.novaTurma.get('presidenteRgOrgao')?.value,
        presidenteEstadoCivil : this.novaTurma.get('presidenteEstadoCivil')?.value,
        presidenteNacionalidade : this.novaTurma.get('presidenteNacionalidade')?.value,
        vicePresidente : this.novaTurma.get('vicePresidente')?.value,
        vicePresidenteCpf : this.novaTurma.get('vicePresidenteCpf')?.value,
        vicePresidenteRg : this.novaTurma.get('vicePresidenteRg')?.value,
        vicePresidenteEndereco : this.novaTurma.get('vicePresidenteEndereco')?.value,
        vicePresidenteRgOrgao : this.novaTurma.get('vicePresidenteRgOrgao')?.value,
        vicePresidenteEstadoCivil : this.novaTurma.get('vicePresidenteEstadoCivil')?.value,
        vicePresidenteNacionalidade : this.novaTurma.get('vicePresidenteNacionalidade')?.value,
        primeiroSecretario : this.novaTurma.get('primeiroSecretario')?.value,
        primeiroSecretarioCpf : this.novaTurma.get('primeiroSecretarioCpf')?.value,
        primeiroSecretarioRg : this.novaTurma.get('primeiroSecretarioRg')?.value,
        primeiroSecretarioEndereco : this.novaTurma.get('primeiroSecretarioEndereco')?.value,
        primeiroSecretarioRgOrgao : this.novaTurma.get('primeiroSecretarioRgOrgao')?.value,
        primeiroSecretarioEstadoCivil : this.novaTurma.get('primeiroSecretarioEstadoCivil')?.value,
        primeiroSecretarioNacionalidade : this.novaTurma.get('primeiroSecretarioNacionalidade')?.value,
        segundoSecretario : this.novaTurma.get('segundoSecretario')?.value,
        segundoSecretarioCpf : this.novaTurma.get('segundoSecretarioCpf')?.value,
        segundoSecretarioRg : this.novaTurma.get('segundoSecretarioRg')?.value,
        segundoSecretarioEndereco : this.novaTurma.get('segundoSecretarioEndereco')?.value,
        segundoSecretarioRgOrgao : this.novaTurma.get('segundoSecretarioRgOrgao')?.value,
        segundoSecretarioEstadoCivil : this.novaTurma.get('segundoSecretarioEstadoCivil')?.value,
        segundoSecretarioNacionalidade : this.novaTurma.get('segundoSecretarioNacionalidade')?.value,
        terceiroSecretario : this.novaTurma.get('terceiroSecretario')?.value,
        terceiroSecretarioCpf : this.novaTurma.get('terceiroSecretarioCpf')?.value,
        terceiroSecretarioRg : this.novaTurma.get('terceiroSecretarioRg')?.value,
        terceiroSecretarioEndereco : this.novaTurma.get('terceiroSecretarioEndereco')?.value,
        terceiroSecretarioRgOrgao : this.novaTurma.get('terceiroSecretarioRgOrgao')?.value,
        terceiroSecretarioEstadoCivil : this.novaTurma.get('terceiroSecretarioEstadoCivil')?.value,
        terceiroSecretarioNacionalidade : this.novaTurma.get('terceiroSecretarioNacionalidade')?.value,
        tesoureiro : this.novaTurma.get('tesoureiro')?.value,
        tesoureiroCpf : this.novaTurma.get('tesoureiroCpf')?.value,
        tesoureiroRg : this.novaTurma.get('tesoureiroRg')?.value,
        tesoureiroEndereco : this.novaTurma.get('tesoureiroEndereco')?.value,
        tesoureiroRgOrgao : this.novaTurma.get('tesoureiroRgOrgao')?.value,
        tesoureiroEstadoCivil : this.novaTurma.get('tesoureiroEstadoCivil')?.value,
        tesoureiroNacionalidade : this.novaTurma.get('tesoureiroNacionalidade')?.value,
        primeiroConsFiscal : this.novaTurma.get('primeiroConsFiscal')?.value,
        primeiroConsFiscalCpf : this.novaTurma.get('primeiroConsFiscalCpf')?.value,
        primeiroConsFiscalRg : this.novaTurma.get('primeiroConsFiscalRg')?.value,
        primeiroConsFiscalEndereco : this.novaTurma.get('primeiroConsFiscalEndereco')?.value,
        primeiroConsFiscalRgOrgao : this.novaTurma.get('primeiroConsFiscalRgOrgao')?.value,
        primeiroConsFiscalEstadoCivil : this.novaTurma.get('primeiroConsFiscalEstadoCivil')?.value ,
        primeiroConsFiscalNacionalidade : this.novaTurma.get('primeiroConsFiscalNacionalidade')?.value,
        segundoConsFiscal : this.novaTurma.get('segundoConsFiscal')?.value ,
        segundoConsFiscalCpf : this.novaTurma.get('segundoConsFiscalCpf')?.value,
        segundoConsFiscalRg : this.novaTurma.get('segundoConsFiscalRg')?.value ,
        segundoConsFiscalEndereco : this.novaTurma.get('segundoConsFiscalEndereco')?.value,
        segundoConsFiscalRgOrgao : this.novaTurma.get('segundoConsFiscalRgOrgao')?.value,
        segundoConsFiscalEstadoCivil : this.novaTurma.get('segundoConsFiscalEstadoCivil')?.value,
        segundoConsFiscalNacionalidade : this.novaTurma.get('segundoConsFiscalNacionalidade')?.value,
        terceiroConsFiscal : this.novaTurma.get('terceiroConsFiscal')?.value,
        terceiroConsFiscalCpf : this.novaTurma.get('terceiroConsFiscalCpf')?.value,
        terceiroConsFiscalRg : this.novaTurma.get('terceiroConsFiscalRg')?.value,
        terceiroConsFiscalEndereco : this.novaTurma.get('terceiroConsFiscalEndereco')?.value,
        terceiroConsFiscalRgOrgao :this.novaTurma.get('terceiroConsFiscalRgOrgao')?.value,
        terceiroConsFiscalEstadoCivil : this.novaTurma.get('terceiroConsFiscalEstadoCivil')?.value,
        terceiroConsFiscalNacionalidade : this.novaTurma.get('terceiroConsFiscalNacionalidade')?.value,
        presidenteTelefone : this.novaTurma.get('presidenteTelefone')?.value,
        vicePresidenteTelefone : this.novaTurma.get('vicePresidenteTelefone')?.value,
        primeiroSecretarioTelefone : this.novaTurma.get('primeiroSecretarioTelefone')?.value,
        segundoSecretarioTelefone : this.novaTurma.get('segundoSecretarioTelefone')?.value,
        terceiroSecretarioTelefone : this.novaTurma.get('terceiroSecretarioTelefone')?.value,
        tesoureiroTelefone : this.novaTurma.get('tesoureiroTelefone')?.value,
        primeiroConsFiscalTelefone : this.novaTurma.get('primeiroConsFiscalTelefone')?.value,
        segundoConsFiscalTelefone : this.novaTurma.get('segundoConsFiscalTelefone')?.value,
        terceiroConsFiscalTelefone : this.novaTurma.get('terceiroConsFiscalTelefone')?.value,
        presidenteEmail : this.novaTurma.get('presidenteEmail')?.value,
        vicePresidenteEmail : this.novaTurma.get('vicePresidenteEmail')?.value,
        primeiroSecretarioEmail : this.novaTurma.get('primeiroSecretarioEmail')?.value,
        segundoSecretarioEmail : this.novaTurma.get('segundoSecretarioEmail')?.value,
        terceiroSecretarioEmail : this.novaTurma.get('terceiroSecretarioEmail')?.value,
        tesoureiroEmail : this.novaTurma.get('tesoureiroEmail')?.value,
        primeiroConsFiscalEmail : this.novaTurma.get('primeiroConsFiscalEmail')?.value,
        segundoConsFiscalEmail : this.novaTurma.get('segundoConsFiscalEmail')?.value,
        terceiroConsFiscalEmail : this.novaTurma.get('terceiroConsFiscalEmail')?.value,
        salas:[],
        alunosHistorico:[],
        tipo:"ESCOLA"
      }
      this.db.postTurma(obj).subscribe(res => {
        console.log(res);
        let ob = {
          data:this.dataHoje,
          hora:hora,
          usuario: localStorage.getItem('usuarioTrends'),
          menu: "TURMAS",
          descricao: "Nova Turma Cadastrada: "+this.novaTurma.get('turma')?.value+", Sala "+this.novaTurma.get('sala')?.value+" do Curso "+this.novaTurma.get('curso')?.value+" na "+this.novaTurma.get('instituicao')?.value+" na cidade: "+this.novaTurma.get('cidade')?.value
        }
        this.db.postLogs(ob).subscribe(res => {
          console.log(res);
         this.app.openAlert("Turma Cadastrada Com Sucesso!");
        this.novaTurma.reset();
        this.novaTurma.get('consultor')?.setValue(this.usuario);
        let modal:any = document.getElementById('myModalColegio')
        modal.style.display = "none"
        this.closeAddTurma();
        this.getTurmas();
        },err => console.log(err))
      },err => {
        console.log(err);
         this.app.openAlert("Não Foi Possivel Cadastrar Turma! Tente Novamente!")
        });
      }
    }

  instituicaoCadastroCurso(cidade:any){
    this.listaInstituicoesCadastrarCurso = [];
      this.db.getInstituicoesEscolaByCidade(cidade).subscribe((data:any)=>{
      this.listaInstituicoesCadastrarCurso = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
      if (this.listaInstituicoesCadastrarCurso == ""){
         this.app.openAlert("Não há Instituições Cadastradas Nesta Cidade!");
      }
    })
  }

  excluirCidade(city:any){
    if(window.confirm('Deseja Realmente Excluir essa Cidade?')){
      let cidade = city.split(',');
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      this.db.deleteCidade(cidade[0]).subscribe( res => {
        console.log(res);
        this.db.getCidades().subscribe((data:any) => {
          this.listaCidadesNovaInstituicao = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
          this.listaCidadesCadastrarCurso = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
          this.listaCidadesCadastroNovaTurma = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
          this.listaCidadesExcluir = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
          this.listaCidadesExcluirInstituicao = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
          this.listaCidadesExcluirCurso = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
        });
        let ob = {
          data:this.dataHoje,
          hora:hora,
          usuario: localStorage.getItem('usuarioTrends'),
          menu: "TURMAS",
          descricao: "Cidade Excluida: "+cidade[1]
        }
        this.db.postLogs(ob).subscribe(res => {
          console.log(res)
         this.app.openAlert("Cidade EXCLUIDA Com Sucesso!");
        this.limparSelects();
      },err => console.log(err))
      },
      err => {
        console.log(err);
         this.app.openAlert("Não Foi Possivel Excluir Esta Cidade, Tente Novamente!")
      });
  }
  }

  excluirInstituicao(faculdade:any){
    if(window.confirm('Deseja Realmente Excluir essa Escola?')){
      let facul = faculdade.split(',');
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      this.db.deleteInstituicao(facul[0]).subscribe(res => {
        console.log(res);
        let ob = {
          data:this.dataHoje,
          hora:hora,
          usuario: localStorage.getItem('usuarioTrends'),
          menu: "TURMAS",
          descricao: "Escola Excluida: "+facul[1]+" da cidade "+this.cidadeExcluirInstituicao
        }
        this.db.postLogs(ob).subscribe(res => {
          console.log(res)
           this.app.openAlert("ESCOLA EXCLUIDA Com Sucesso!");
          this.limparSelects();
          this.db.getInstituicoesEscolaByCidade(this.cidadeExcluirInstituicao).subscribe((data:any) => {
            this.listaInstituicoesExcluirInstituicao = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
          })
        },err => console.log(err))
        },
      err => {
        console.log(err);
         this.app.openAlert("Não Foi Possivel Excluir Esta Escola, Tente Novamente!")
      });
    }
  }

  instituicaoExcluirCadastro(cidade:any){
    this.db.getInstituicoesEscolaByCidade(cidade).subscribe((data:any) => {
      this.listaInstituicoesExcluirInstituicao = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
      if(this.listaInstituicoesExcluirInstituicao == ""){
         this.app.openAlert("Não há Instituições Cadastradas Para Essa Cidade!")
      }
      this.ultimaCidadeExcluir = cidade;
    });
  }

  selecionarCidadeExcluirCurso(key:any){
    this.db.getInstituicoesEscolaByCidade(key).subscribe((data:any) => {
    this.listaInstituicoesExcluirCurso = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
    this.listaCursosExcluirCurso = [];
    if(this.listaInstituicoesExcluirCurso == ""){
       this.app.openAlert("Não há Instituições Cadastradas Nesta Cidade!");
    }
    this.ultimaCidadeExcluir = key;
  })
  }

  selecionarInstituicaoExcluirCurso(cidade:any, instituicao:any){
    this.db.getCursosByInstituicaoCidade(instituicao,cidade).subscribe((data:any) => {
      this.listaCursosExcluirCurso = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});  
    })
    this.ultimaInstituicaoExcluir = instituicao;
  }

  diferencaMeses(dataInicio:any, dataFim:any){
    let dataHoje = new Date(Date.now() - 10800000)
    let dtInicio = new Date(dataInicio);
    let dtFim = new Date(dataFim);
    var d1Y = dtInicio.getFullYear();
    var d2Y = dataHoje.getFullYear();
    var d1M = dtInicio.getMonth();
    var d2M = dataHoje.getMonth();  
    var d1Y2 = dtInicio.getFullYear();
    var d2Y2 = dtFim.getFullYear();
    var d1M2 = dtInicio.getMonth();
    var d2M2 = dtFim.getMonth();
    let meses = (d2M+12*d2Y)-(d1M+12*d1Y);
    let duracao = Math.trunc(((d2M2+12*d2Y2)-(d1M2+12*d1Y2))/12);
    let ano:number = 0;
    let diffMeses = Math.abs(dtInicio.getMonth() - dataHoje.getMonth() +  (12 * (dtInicio.getFullYear() - dataHoje.getFullYear())));
    let periodo = 0; 
    if(diffMeses >= 0 && diffMeses <= 5){periodo = 1;}
    if(diffMeses >= 6 && diffMeses <= 11){periodo = 2;}
    if(diffMeses >= 12 && diffMeses <= 17){periodo = 3;}
    if(diffMeses >= 18 && diffMeses <= 23){periodo = 4;}
    if(diffMeses >= 24 && diffMeses <= 29){periodo = 5;}
    if(diffMeses >= 30 && diffMeses <= 35){periodo = 6;}
    if(diffMeses >= 36 && diffMeses <= 41){periodo = 7;}
    if(diffMeses >= 42 && diffMeses <= 49){periodo = 8;}
    if(diffMeses >= 48 && diffMeses <= 53){periodo = 9;}
    if(diffMeses >= 54 && diffMeses <= 59){periodo = 10;}
    if(diffMeses >= 60 && diffMeses <= 65){periodo = 11;}
    if(diffMeses >= 66 && diffMeses <= 71){periodo = 12;}
    if(diffMeses >= 72 && diffMeses <= 77){periodo = 13;}
    if(diffMeses >= 78 && diffMeses <= 83){periodo = 14;}
    if(meses <= 11){ano = 1;}
    if(meses >= 12 && meses < 24){ano = 2;}
    if(meses >= 24 && meses < 36){ano = 3;}
    if(meses >= 36 && meses < 48){ano = 4;}
    if(meses >= 48 && meses < 60){ano = 5;}
    if(meses >= 60 && meses < 72){ano = 6;}
    if(meses >= 72){ano = 0;}
    this.novaTurma.patchValue({ano : ano});
    this.novaTurma.patchValue({duracao : duracao});
    this.novaTurma.patchValue({periodoTurma : periodo});  
  }

  editTurma(id:any,cidade:any,instituicao:any,curso:any, periodo:any, dataInicio:any, dataFim:any, ano:any, duracao:any, turma:any,sala:any,situacao:any,consultor:any, empresa:any){
  if(window.confirm('Deseja Editar essa Turma?')){
    let salas = [];
    let histAlunos = [];
    if(this.turmaSelecionadaDetails.salas){
      salas = this.turmaSelecionadaDetails.salas
    }else{
      salas = [];
    }
    if(sala != this.salaAntiga){
      let dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
      let ob = {
        data: dataHoje,
        sala: this.salaAntiga
      }
      salas.push(ob);
    }else{
      salas = this.turmaSelecionadaDetails.salas
    }

    if(this.turmaSelecionadaDetails.alunosHistorico){
      histAlunos = this.turmaSelecionadaDetails.alunosHistorico
    }else{
      histAlunos = [];
    }
    if(this.alunos != this.alunosAntigo){
      let dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
      let ob = {
        data: dataHoje,
        alunos: this.alunosAntigo.toString()
      }
      histAlunos.push(ob);
    }else{
      histAlunos = this.turmaSelecionadaDetails.alunosHistorico
    }
    let obj = {
      cidade : cidade,
      instituicao: instituicao,
      curso: curso,
      periodo: periodo,
      dataInicio: dataInicio,
      dataFim: dataFim,
      ano: ano,
      duracao: duracao,
      turma: turma,
      sala: sala,
      situacao: situacao,
      consultor: consultor,
      periodoTurma: this.periodoTurma,
      alunos: this.alunos,
      empresa : empresa,
      nomeComissao: this.nomeComissao,
      razaoSocialComissao: this.razaoSocialComissao,
      cnpjComissao: this.cnpjComissao,
      cepComissao : this.cepComissao,
      enderecoComissao: this.enderecoComissao,
      bairroComissao: this.bairroComissao,
      cidadeComissao: this.cidadeComissao,
      ufComissao: this.ufComissao,
      presidente : this.presidente,
      presidenteCpf : this.presidenteCpf,
      presidenteRg : this.presidenteRg,
      presidenteEndereco : this.presidenteEndereco,
      presidenteRgOrgao : this.presidenteRgOrgao,
      presidenteEstadoCivil : this.presidenteEstadoCivil,
      presidenteNacionalidade : this.presidenteNacionalidade,
      vicePresidente : this.vicePresidente,
      vicePresidenteCpf : this.vicePresidenteCpf,
      vicePresidenteRg : this.vicePresidenteRg,
      vicePresidenteEndereco : this.vicePresidenteEndereco,
      vicePresidenteRgOrgao : this.vicePresidenteRgOrgao,
      vicePresidenteEstadoCivil : this.vicePresidenteEstadoCivil,
      vicePresidenteNacionalidade : this.vicePresidenteNacionalidade,
      primeiroSecretario : this.primeiroSecretario,
      primeiroSecretarioCpf : this.primeiroSecretarioCpf,
      primeiroSecretarioRg : this.primeiroSecretarioRg,
      primeiroSecretarioEndereco : this.primeiroSecretarioEndereco,
      primeiroSecretarioRgOrgao : this.primeiroSecretarioRgOrgao,
      primeiroSecretarioEstadoCivil : this.primeiroSecretarioEstadoCivil,
      primeiroSecretarioNacionalidade : this.primeiroSecretarioNacionalidade,
      segundoSecretario : this.segundoSecretario,
      segundoSecretarioCpf : this.segundoSecretarioCpf,
      segundoSecretarioRg : this.segundoSecretarioRg,
      segundoSecretarioEndereco : this.segundoSecretarioEndereco,
      segundoSecretarioRgOrgao : this.segundoSecretarioRgOrgao,
      segundoSecretarioEstadoCivil : this.segundoSecretarioEstadoCivil,
      segundoSecretarioNacionalidade : this.segundoSecretarioNacionalidade,
      terceiroSecretario : this.terceiroSecretario,
      terceiroSecretarioCpf : this.terceiroSecretarioCpf,
      terceiroSecretarioRg : this.terceiroSecretarioRg,
      terceiroSecretarioEndereco : this.terceiroSecretarioEndereco,
      terceiroSecretarioRgOrgao : this.terceiroSecretarioRgOrgao,
      terceiroSecretarioEstadoCivil : this.terceiroSecretarioEstadoCivil,
      terceiroSecretarioNacionalidade : this.terceiroSecretarioNacionalidade,
      tesoureiro : this.tesoureiro,
      tesoureiroCpf : this.tesoureiroCpf,
      tesoureiroRg : this.tesoureiroRg,
      tesoureiroEndereco : this.tesoureiroEndereco,
      tesoureiroRgOrgao : this.tesoureiroRgOrgao,
      tesoureiroEstadoCivil : this.tesoureiroEstadoCivil,
      tesoureiroNacionalidade : this.tesoureiroNacionalidade,
      primeiroConsFiscal : this.primeiroConsFiscal,
      primeiroConsFiscalCpf : this.primeiroConsFiscalCpf,
      primeiroConsFiscalRg : this.primeiroConsFiscalRg,
      primeiroConsFiscalEndereco : this.primeiroConsFiscalEndereco,
      primeiroConsFiscalRgOrgao : this.primeiroConsFiscalRgOrgao,
      primeiroConsFiscalEstadoCivil : this.primeiroConsFiscalEstadoCivil,
      primeiroConsFiscalNacionalidade : this.primeiroConsFiscalNacionalidade,
      segundoConsFiscal : this.segundoConsFiscal,
      segundoConsFiscalCpf : this.segundoConsFiscalCpf,
      segundoConsFiscalRg : this.segundoConsFiscalRg,
      segundoConsFiscalEndereco : this.segundoConsFiscalEndereco,
      segundoConsFiscalRgOrgao : this.segundoConsFiscalRgOrgao,
      segundoConsFiscalEstadoCivil : this.segundoConsFiscalEstadoCivil,
      segundoConsFiscalNacionalidade : this.segundoConsFiscalNacionalidade,
      terceiroConsFiscal : this.terceiroConsFiscal,
      terceiroConsFiscalCpf : this.terceiroConsFiscalCpf,
      terceiroConsFiscalRg : this.terceiroConsFiscalRg,
      terceiroConsFiscalEndereco : this.terceiroConsFiscalEndereco,
      terceiroConsFiscalRgOrgao : this.terceiroConsFiscalRgOrgao,
      terceiroConsFiscalEstadoCivil : this.terceiroConsFiscalEstadoCivil,
      terceiroConsFiscalNacionalidade : this.terceiroConsFiscalNacionalidade,
      presidenteTelefone : this.presidenteTelefone,
      vicePresidenteTelefone : this.vicePresidenteTelefone,
      primeiroSecretarioTelefone : this.primeiroSecretarioTelefone,
      segundoSecretarioTelefone : this.segundoSecretarioTelefone,
      terceiroSecretarioTelefone : this.terceiroSecretarioTelefone,
      tesoureiroTelefone : this.tesoureiroTelefone,
      primeiroConsFiscalTelefone : this.primeiroConsFiscalTelefone,
      segundoConsFiscalTelefone : this.segundoConsFiscalTelefone,
      terceiroConsFiscalTelefone : this.terceiroConsFiscalTelefone,
      presidenteEmail : this.presidenteEmail,
      vicePresidenteEmail : this.vicePresidenteEmail,
      primeiroSecretarioEmail : this.primeiroSecretarioEmail,
      segundoSecretarioEmail : this.segundoSecretarioEmail,
      terceiroSecretarioEmail : this.terceiroSecretarioEmail,
      tesoureiroEmail : this.tesoureiroEmail,
      primeiroConsFiscalEmail : this.primeiroConsFiscalEmail,
      segundoConsFiscalEmail : this.segundoConsFiscalEmail,
      terceiroConsFiscalEmail : this.terceiroConsFiscalEmail,
      salas:salas,
      alunosHistorico:histAlunos
      }
    this.db.patchTurma(id,obj).subscribe(res => {
      console.log(res);
       this.app.openAlert("Turma Editada Com Sucesso!");
      this.gravarLogsAlteracao();
      this.closeModalDetails();
    },err => {
      console.log(err);
       this.app.openAlert("Não Foi Possivel Editar Turma! Tente Novamente!")
      });
    }
  }

  openSenhaDeleteTurma(id:any){
    this.idTurma = id;
    let modalSenha:any = document.getElementById('senhaModalCol');
    modalSenha.style.display = 'block';
  }

  closeSenhaDeleteTurma(){
    this.idTurma = "";
    let modalSenha:any = document.getElementById('senhaModalCol');
    modalSenha.style.display = 'none';
  }

  deleteTurma(){
    this.db.getUsuarioByEmail(localStorage.getItem('emailTrends')).subscribe((data:any) => {
      let pwd = data[0].senha;
      if(pwd == this.senhaAdm){
        let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
        this.db.deleteTurma(this.idTurma).subscribe(res => {
          console.log(res);
          let ob = {
            data:this.dataHoje,
            hora:hora,
            usuario: localStorage.getItem('usuarioTrends'),
            menu: "TURMAS",
            descricao: "Turma Excluida do Curso: "+this.turmaSelecionadaDetails.curso+" na "+this.turmaSelecionadaDetails.instituicao+" de "+this.turmaSelecionadaDetails.cidade+", Turma "+this.turmaSelecionadaDetails.turma+", Sala "+this.turmaSelecionadaDetails.sala
          }
          this.db.postLogs(ob).subscribe(res => {
          console.log(res);
           this.app.openAlert("Turma EXCLUIDA Com Sucesso!");
          this.closeSenhaDeleteTurma();
          this.closeModalDetails();
        },err => console.log(err));
        },
        err => {
          console.log(err);
           this.app.openAlert("Não Foi Possivel Excluir Esta Turma, Tente Novamente!")
        });
      }else{
         this.app.openAlert("SENHA INCORRETA!")
      }
    })
  }

  detailOrcamento(key:any){
    this._router.navigate(['/details-orcamento', key])
  }

  detailContrato(key:any){
    this._router.navigate(['/details-contrato', key])
  }

  goBack(){
    this.location.back();
    }

  onChange(event:any){
    let inp:any = document.getElementById('customFileLabelCol');
    let arrayTypes = ['image/png', 'image/jpg', 'image/jpeg']
    this.selectedFiles = <FileList>event.srcElement.files;
    for (let i=0; i<this.selectedFiles.length; i++){
      if(!arrayTypes.includes(this.selectedFiles[i].type)){
       this.app.openAlert("Tipo de Arquivo Não Suportado!\nEscolha uma imagem com a extensão .JPG, .JPEG, .PNG")
      this.selectedFiles = "";
      inp.innerHTML = "";
    }} 
      const fileNames = [];
      for (let i=0; i<this.selectedFiles.length; i++){
        fileNames.push(this.selectedFiles[i].name);
      }
    inp.innerHTML = fileNames.join(', ');
  }

  uploadFile(){
  let inpu:any = document.getElementById('customFileLabelCol');
  for (let i=0; i<this.selectedFiles.length; i++){
    let imagedata = new FormData();
    let random = Math.random().toString(32).slice(-8);
    imagedata.append('files[]', this.selectedFiles[i], this.selectedFiles[i].name);
    let fileNameSplit = this.selectedFiles[i].name.split('.');
    let extensao = fileNameSplit[fileNameSplit.length-1]
    let obj = {
      turmaId:this.turmaId,
      url: 'http://app.trendsformaturas.com.br/documentos/'+this.turmaId+'_'+random+'.'+ extensao
    }
    this.db.postDocumentos(obj).subscribe(res => {
      console.log(res);
      this.db.enviarDocTurma(this.turmaId, imagedata,random).subscribe(res => console.log(res),err => console.log(err));
    },err => {
      console.log(err)
    })
    if(this.selectedFiles.length == i+1){
       this.app.openAlert("Documento Enviado com Sucesso!");
      this.selectedFiles = "";
      inpu.innerHTML = "";
      this.db.getDocumentosByTurmaId(this.turmaId).subscribe((data:any) => {
        this.documentos = data;
        let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
          let ob = {
            data:this.dataHoje,
            hora:hora,
            usuario: localStorage.getItem('usuarioTrends'),
            menu: "TURMAS",
            descricao: "Documento Enviado do Curso: "+this.turmaSelecionadaDetails.curso+" na "+this.turmaSelecionadaDetails.instituicao+" de "+this.turmaSelecionadaDetails.cidade+", Turma "+this.turmaSelecionadaDetails.turma+", Sala "+this.turmaSelecionadaDetails.sala
          }
          this.db.postLogs(ob).subscribe(res => {
          console.log(res);
          },err => console.log(err));
      })
    }
  }      
  }

  openModalDoc(url:any, i:any){
    this.indexImage = i;
    let modal:any = document.getElementById('modalDocCol')
    modal.style.display = "block";
    this.imagemSelecionada = url;
  }

  nextImg(){
    let i = this.indexImage
    if((this.documentos.length - 1) == i){
      this.imagemSelecionada = this.documentos[0].url;
      this.indexImage = 0;
    }else{
      i++
      this.imagemSelecionada = this.documentos[i].url;
      this.indexImage++
    }
  }

  prevImg(){
    let i = this.indexImage
    if(i == 0){
      this.imagemSelecionada = this.documentos[this.documentos.length - 1].url;
      this.indexImage = this.documentos.length - 1;
    }else{
      i--
      this.imagemSelecionada = this.documentos[i].url;
      this.indexImage--
    }
  }

  closeModalDoc(){
    let modal:any = document.getElementById('modalDocCol')
    modal.style.display = "none";
  }

  delButton(){
    let buttonDiv:any = document.getElementsByName("delButton")
    for(let i=0; i < buttonDiv.length; i++ ){
      buttonDiv[i].toggleAttribute("hidden")
      if(buttonDiv[i].className == "delButton"){
        buttonDiv[i].className = "delButtonShow"
        let button:any = document.getElementById("deletarDocs");
        button.innerText = "Cancelar"
      }else{
        buttonDiv[i].className = "delButton"
        let button:any = document.getElementById("deletarDocs");
        button.innerText = "Excluir Documento"
      }
    }  
  }

  delImage(id:any, pathAddress:any){
    if(window.confirm('Deseja Realmente Excluir Esse Documento? Esta ação não pode ser desfeita!')){
      let pathURL = pathAddress.split('/')[4]
      this.db.deleteDocumento(id).subscribe(res=>{
      console.log(res);
    this.db.removeDocTurma(pathURL).subscribe((data) => {
        console.log(data);
      },(error) =>{
        console.log(error);
         this.app.openAlert("Documento Excluido Com Sucesso!");
          this.db.getDocumentosByTurmaId(this.turmaId).subscribe((data:any) => {
            this.documentos = data;
            let jsonDate = new Date().toJSON();
            let hora = new Date(jsonDate).toLocaleTimeString();
              let ob = {
                data:this.dataHoje,
                hora:hora,
                usuario: localStorage.getItem('usuarioTrends'),
                menu: "TURMAS",
                descricao: "Documento Excluido do Curso: "+this.turmaSelecionadaDetails.curso+" na "+this.turmaSelecionadaDetails.instituicao+" de "+this.turmaSelecionadaDetails.cidade+", Turma "+this.turmaSelecionadaDetails.turma+", Sala "+this.turmaSelecionadaDetails.sala
              }
              this.db.postLogs(ob).subscribe(res => {
              console.log(res);
              },err => console.log(err));
          })
        })
        },err=>{
    console.log(err);
     this.app.openAlert("Não Foi Possivel Excluir o Documento, Tente Novamente!")
    })
    }
  }

  enviarContrato(id:any){
    if(window.confirm('Deseja Realmente Enviar o Contrato Deste Orçamento?')){
      this.idOrcamento = id;
      let dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0]; 
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let obj = {
        data: dataHoje,
        hora: hora,
        vendedor: localStorage.getItem('usuarioTrends'),
        orcamentoId: id,
        turmaId: this.turmaId,
        situacao: "Pendente",
        nomeComissao: this.nomeComissao,
        razaoSocialComissao: this.razaoSocialComissao,
        cnpjComissao: this.cnpjComissao,
        cepComissao: this.cepComissao,
        enderecoComissao: this.enderecoComissao,
        bairroComissao: this.bairroComissao,
        cidadeComissao: this.cidadeComissao,
        ufComissao: this.ufComissao
        }
      this.db.postContrato(obj).subscribe(res => {
        console.log(res);
        let o = {
          contrato: "Sim"
        }
        this.db.patchTurma(this.turmaId,o).subscribe(resu => {
          console.log(resu); 
          let p = {
            contrato: "Sim",
            situacaoContrato: "Pendente"
          }
          this.db.patchOrcamento(id,p).subscribe(result => {
            console.log(result);
            let jsonDate = new Date().toJSON();
            let hora = new Date(jsonDate).toLocaleTimeString();
              let ob = {
                data:this.dataHoje,
                hora:hora,
                usuario: localStorage.getItem('usuarioTrends'),
                menu: "TURMAS",
                descricao: "Contrato Enviado do Curso: "+this.turmaSelecionadaDetails.curso+" na "+this.turmaSelecionadaDetails.instituicao+" de "+this.turmaSelecionadaDetails.cidade+", Turma "+this.turmaSelecionadaDetails.turma+", Sala "+this.turmaSelecionadaDetails.sala
              }
              this.db.postLogs(ob).subscribe(res => {
              console.log(res);
              },err => console.log(err));
             this.app.openAlert("Contrato Enviado Com Sucesso! O Mesmo Será Analisado!");
            this.openModalDetails(this.turmaId);
        }, err => {
          console.log(err);
           this.app.openAlert("Erro ao Enviar Contrato! Por Favor, Tente Novamente!")
        })
      },
      er => {
        console.log(er)
      })
    },
    errr => {
      console.log(errr)
    })
  }
  }

  aprovarContrato(id:any){
    let obj = {
      situacao: "Aprovado"
    }
    this.db.patchContrato(id,obj).subscribe(res => {
      console.log(res);
      let o = {
        situacaoContrato:"Aprovado"
      }
      this.db.patchOrcamento(this.idOrcamento,o).subscribe(resu => {
        console.log(resu);
         this.app.openAlert("Contrato Aprovado Com Sucesso!");
        let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
          let ob = {
            data:this.dataHoje,
            hora:hora,
            usuario: localStorage.getItem('usuarioTrends'),
            menu: "TURMAS",
            descricao: "Contrato Aprovado do Curso: "+this.turmaSelecionadaDetails.curso+" na "+this.turmaSelecionadaDetails.instituicao+" de "+this.turmaSelecionadaDetails.cidade+", Turma "+this.turmaSelecionadaDetails.turma+", Sala "+this.turmaSelecionadaDetails.sala
          }
          this.db.postLogs(ob).subscribe(res => {
          console.log(res);
          },err => console.log(err));
        this.openModalDetails(this.turmaId);
      }, err => {
        console.log(err);
         this.app.openAlert("Falha ao aprovar Contrato, tente novamente!");
      })},
      errr => console.log(errr))
  }

  rejeitarContrato(id:any){
    let obj = {
      situacao: "Rejeitado"
    }
    this.db.patchContrato(id,obj).subscribe(res => {
      console.log(res);
      let o = {
        contrato: "Não"
      }
      this.db.patchTurma(this.turmaId,o).subscribe(resu => {
        console.log(resu);
        let p = {
          contrato: "Não",
          situacaoContrato:"Rejeitado"
        }
        this.db.patchOrcamento(this.idOrcamento,p).subscribe(ress => {
            console.log(ress);
            let jsonDate = new Date().toJSON();
            let hora = new Date(jsonDate).toLocaleTimeString();
              let ob = {
                data:this.dataHoje,
                hora:hora,
                usuario: localStorage.getItem('usuarioTrends'),
                menu: "TURMAS",
                descricao: "Contrato Rejeitado do Curso: "+this.turmaSelecionadaDetails.curso+" na "+this.turmaSelecionadaDetails.instituicao+" de "+this.turmaSelecionadaDetails.cidade+", Turma "+this.turmaSelecionadaDetails.turma+", Sala "+this.turmaSelecionadaDetails.sala
              }
              this.db.postLogs(ob).subscribe(res => {
              console.log(res);
              },err => console.log(err));
         this.app.openAlert("Contrato Rejeitado Com Sucesso!");
        this.openModalDetails(this.turmaId);
        }, er => console.log(er))
      }, err => console.log(err))
    }, errr => console.log(errr))
  }

  delContrato(id:any){
    if(window.confirm('Deseja Realmente Excluir esse Contrato?')){
      this.db.deleteContrato(id).subscribe(res => {
        console.log(res);
        let obj = {
          contrato: "Não"
        }
        this.db.patchTurma(this.turmaId,obj).subscribe(res => {
          console.log(res);
          let o = {
            contrato: "Não",
            situacaoContrato:""
          }
          this.db.patchOrcamento(this.idOrcamento,o).subscribe(rees => {
              console.log(rees);
              let jsonDate = new Date().toJSON();
              let hora = new Date(jsonDate).toLocaleTimeString();
                let ob = {
                  data:this.dataHoje,
                  hora:hora,
                  usuario: localStorage.getItem('usuarioTrends'),
                  menu: "TURMAS",
                  descricao: "Contrato Excluido do Curso: "+this.turmaSelecionadaDetails.curso+" na "+this.turmaSelecionadaDetails.instituicao+" de "+this.turmaSelecionadaDetails.cidade+", Turma "+this.turmaSelecionadaDetails.turma+", Sala "+this.turmaSelecionadaDetails.sala
                }
                this.db.postLogs(ob).subscribe(res => {
                console.log(res);
                },err => console.log(err));
             this.app.openAlert("Contrato EXCLUIDO Com Sucesso!");
            this.openModalDetails(this.turmaId);
        }, er => console.log(er))
      }, err => console.log(err))
      },
      errr => {
        console.log(errr);
         this.app.openAlert("Não Foi Possivel Excluir Este Contrato!")
      });
  }
  }

  novoRelatorio(){
    if (this.newRelatorio == false){
      this.newRelatorio = true
    } else {
      this.newRelatorio = false
    }
  }

  enviarRelatorio(assuntoRelatorio:any, descricaoRelatorio:any, assunto:any, descricao:any, start:any){
    if(window.confirm('Deseja Cadastrar Esse Novo Relatório?')){
      let dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
      let jsonDate = new Date().toJSON();
      let horaHoje = new Date(jsonDate).toLocaleTimeString();
      let obj = {
        data : dataHoje, 
        hora : horaHoje, 
        assunto : assuntoRelatorio, 
        descricao : "Motivo: "+descricaoRelatorio+" |  Novo Agendamento: "+start.split('-').reverse().join('/')+" - "+assunto+" - "+"Motivo: "+descricao, 
        usuario : this.usuario, 
        idTurma : this.turmaSelecionadaDetails._id
        }
      this.db.postRelatorio(obj).subscribe(res => {
        console.log(res);
        let startOriginal = start+'T12:00:00.000Z';
        let o = {
          assunto : assunto, 
          turma : this.cursoTurmaSelecionado + ' - Ano ' + this.anoTurmaSelecionado +' - ' + this.instituicaoTurmaSelecionado, 
          descricao : descricao, 
          start : startOriginal,
          end : startOriginal,
          vendedor : localStorage.getItem('usuarioTrends'),
          turmaId : this.turmaId,
          resolvido: false
          }
        this.db.postAgenda(o).subscribe(res => {
          console.log(res);
          this.db.getRelatoriosByTurmaId(this.turmaSelecionadaDetails._id).subscribe((data:any) => {
            this.relatorios = data;
            this.newRelatorio = false;
            this.assuntoRelatorio = "";
            this.descricaoRelatorio = "";
            this.assuntoAgenda = "";
            this.descricaoAgenda = "";
            this.dataAgenda = "";
           this.app.openAlert("Relatório Salvo Com Sucesso!");
          let jsonDate = new Date().toJSON();
          let hora = new Date(jsonDate).toLocaleTimeString();
            let ob = {
              data:this.dataHoje,
              hora:hora,
              usuario: localStorage.getItem('usuarioTrends'),
              menu: "TURMAS",
              descricao: "Novo Relatório Enviado do Curso: "+this.turmaSelecionadaDetails.curso+" na "+this.turmaSelecionadaDetails.instituicao+" de "+this.turmaSelecionadaDetails.cidade+", Turma "+this.turmaSelecionadaDetails.turma+", Sala "+this.turmaSelecionadaDetails.sala
            }
            this.db.postLogs(ob).subscribe(res => {
            console.log(res);
            },err => console.log(err));
          });
        }, err => {
          console.log(err);
           this.app.openAlert("Não Foi Possivel Cadastrar Este Relatório, Tente Novamente!")}
          )
      }, err => {
        console.log(err);
      })
    }
  }

  enviarRelatorioFechado(assuntoRelatorio:any, descricaoRelatorio:any){
    if(window.confirm('Deseja Cadastrar Esse Novo Relatório?')){
      let dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
      let jsonDate = new Date().toJSON();
      let horaHoje = new Date(jsonDate).toLocaleTimeString();
      let obj = {
        data : dataHoje, 
        hora : horaHoje, 
        assunto : assuntoRelatorio, 
        descricao : "Motivo: "+descricaoRelatorio, 
        usuario : this.usuario, 
        idTurma : this.turmaSelecionadaDetails._id
        }
      this.db.postRelatorio(obj).subscribe(res => {
        console.log(res);
        this.db.getRelatoriosByTurmaId(this.turmaSelecionadaDetails._id).subscribe((data:any) => {
          this.relatorios = data;
          this.newRelatorio = false;
          this.assuntoRelatorio = "";
          this.descricaoRelatorio = "";
          this.assuntoAgenda = "";
          this.descricaoAgenda = "";
          this.dataAgenda = "";
          let jsonDate = new Date().toJSON();
          let hora = new Date(jsonDate).toLocaleTimeString();
            let ob = {
              data:this.dataHoje,
              hora:hora,
              usuario: localStorage.getItem('usuarioTrends'),
              menu: "TURMAS",
              descricao: "Novo Relatório Enviado do Curso: "+this.turmaSelecionadaDetails.curso+" na "+this.turmaSelecionadaDetails.instituicao+" de "+this.turmaSelecionadaDetails.cidade+", Turma "+this.turmaSelecionadaDetails.turma+", Sala "+this.turmaSelecionadaDetails.sala
            }
            this.db.postLogs(ob).subscribe(res => {
            console.log(res);
            },err => console.log(err));
         this.app.openAlert("Relatório Salvo Com Sucesso!");
        });
      }, err => {
        console.log(err);
      })
    }
  }

  cancelarRelatorio(){
    this.newRelatorio = false
  }

  limparSelects(){
    this.listaCidadesCadastroNovaTurma = [];
    this.listaInstituicoesNovaTurma = [];
    this.listaCursosNovaTurma = [];
    this.listaCidadesCadastrarCurso = [];
    this.listaInstituicoesCadastrarCurso = [];
    this.listaCidadesExcluirInstituicao = [];
    this.listaInstituicoesExcluirInstituicao = [];
    this.listaCidadesExcluirCurso = [];
    this.listaInstituicoesExcluirCurso = [];
    this.listaCursosExcluirCurso = [];
    this.novaCidadeCadastro = "";
    this.cidadeCadastroNovaInstituicao = "";
    this.novaInstituicaoCadastro = "";
  }

  turmaUnica(turma:any){
    this.db.getTurmaUnica(turma.cidade,turma.instituicao,turma.curso,turma.sala,turma.periodo).subscribe((data:any) => {
      if(data.length > 0 && data[0]._id != turma._id){
         this.app.openAlert("Já possui uma Turma de "+turma.curso+" na Escola "+turma.instituicao+" com a sala "+turma.sala+" neste Periodo "+turma.periodo+" na cidade de "+turma.cidade);
        this.salaOkey = false;
      }else{
        this.salaOkey = true;
      }
    })
  }

  turmaUnicaNova(cidade:any,instituicao:any,curso:any,sala:any,periodo:any){
    this.db.getTurmaUnica(cidade,instituicao,curso,sala,periodo).subscribe((data:any) => {
      if(data.length > 0){
         this.app.openAlert("Já possui uma Turma de "+curso+" na Escola "+instituicao+" com a sala "+sala+" neste Periodo "+periodo+" na cidade de "+cidade);
        this.salaOkey = false;
      }else{
        this.salaOkey = true;
      }
    })
  }

  cadastrarNovaEmpresa(novaEmpresa:any){
    let idx = this.todasEmpresas.map((item:any) =>{
      return item.nome
    }).indexOf(novaEmpresa)
    if(idx >= 0){
       this.app.openAlert("Empresa já Cadastrada!");
    }else{
      let obj = {
        nome: novaEmpresa
      }
      this.db.novaEmpresa(obj).subscribe(res => {
        console.log(res);
         this.app.openAlert("Empresa Cadastrada com Sucesso!");
        let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
          let ob = {
            data:this.dataHoje,
            hora:hora,
            usuario: localStorage.getItem('usuarioTrends'),
            menu: "TURMAS",
            descricao: "Nova Empresa Cadastrada: "+novaEmpresa
          }
          this.db.postLogs(ob).subscribe(res => {
          console.log(res);
          },err => console.log(err));
        this.getEmpresas();
        this.novaEmpresa = "";
      }, err => {
        console.log(err);
         this.app.openAlert("Erro ao Cadastrar Empresa, por favor tente novamente!");
      })  
    }
  }

  excluirEmpresa(id:any,nomeEmpresa:any){
    if(window.confirm('Deseja Realmente Excluir essa Empresa?')){
      this.db.deleteEmpresa(id).subscribe(res => {
        console.log(res);
         this.app.openAlert("Empresa Excluida com Sucesso!");
        let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
          let ob = {
            data:this.dataHoje,
            hora:hora,
            usuario: localStorage.getItem('usuarioTrends'),
            menu: "TURMAS",
            descricao: "Empresa Excluida: "+nomeEmpresa
          }
          this.db.postLogs(ob).subscribe(res => {
          console.log(res);
          },err => console.log(err));
        this.getEmpresas();
      }, err => {
        console.log(err);
         this.app.openAlert("Erro ao Cadastrar Empresa, por favor tente novamente!");
      })
    }
  }

  postAlteracao(nome:any,campo:any,valor:any){
    if(this.arrayLogs.length > 0){
      let idx = this.arrayLogs.map((item:any) =>{
        return item.campo
      }).indexOf(campo);
      if(idx >= 0){
        this.arrayLogs.splice(idx,1);
        let valorAntigo = this.backup[campo];
        let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
        let ob = {
        campo:campo,
        antigo:valorAntigo,
        novo:valor,
        data:this.dataHoje,
        hora:hora,
        usuario: localStorage.getItem('usuarioTrends'),
        menu: "TURMAS",
        descricao: nome+" alterado de "+valorAntigo+" para "+valor+" no curso "+this.turmaSelecionadaDetails.curso+" na "+this.turmaSelecionadaDetails.instituicao+" de "+this.turmaSelecionadaDetails.cidade+", Turma "+this.turmaSelecionadaDetails.turma+", Sala "+this.turmaSelecionadaDetails.sala
      }
      this.arrayLogs.push(ob);
      }else{
      let valorAntigo = this.backup[campo];
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let ob = {
      campo:campo,
      antigo:valorAntigo,
      novo:valor,
      data:this.dataHoje,
      hora:hora,
      usuario: localStorage.getItem('usuarioTrends'),
      menu: "TURMAS",
      descricao: nome+" alterado de "+valorAntigo+" para "+valor+" no curso "+this.turmaSelecionadaDetails.curso+" na "+this.turmaSelecionadaDetails.instituicao+" de "+this.turmaSelecionadaDetails.cidade+", Turma "+this.turmaSelecionadaDetails.turma+", Sala "+this.turmaSelecionadaDetails.sala
    }
    this.arrayLogs.push(ob);
      }
    }else{
      let valorAntigo = this.backup[campo];
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let ob = {
      campo:campo,
      antigo:valorAntigo,
      novo:valor,
      data:this.dataHoje,
      hora:hora,
      usuario: localStorage.getItem('usuarioTrends'),
      menu: "TURMAS",
      descricao: nome+" alterado de "+valorAntigo+" para "+valor+" no curso "+this.turmaSelecionadaDetails.curso+" na "+this.turmaSelecionadaDetails.instituicao+" de "+this.turmaSelecionadaDetails.cidade+", Turma "+this.turmaSelecionadaDetails.turma+", Sala "+this.turmaSelecionadaDetails.sala
    }
    this.arrayLogs.push(ob);
    }
  }

  gravarLogsAlteracao(){
    if(this.arrayLogs.length > 0){
      this.arrayLogs.forEach((item:any) => {
        if(item.antigo != item.novo){
          this.db.postLogs(item).subscribe(res => console.log(res),err => console.log(err))
        }
      })
    }
  }

  mudarStatus(){
    this.db.getUsuarioByEmail(localStorage.getItem('emailTrends')).subscribe((data:any) => {
      let pwd = data[0].senha;
      if(pwd == this.senhaAdm){
        let obj;
        obj = {check : this.statusTurma}
        this.db.patchTurma(this.idTurma,obj).subscribe(res => {
          console.log(res);
           this.app.openAlert("Status da Turma Alterado com Sucesso!")
          this.closeSenhaStatus();
        },err => console.log(err));  
      }else{
         this.app.openAlert("SENHA INCORRETA!")
      }
    })
  }

  openSenhaStatus(status:any,id:any){
    this.statusTurma = status;
    this.idTurma = id;
    let modalSenha:any = document.getElementById('senhaModalStatusCol');
    modalSenha.style.display = 'block';
  }

  closeSenhaStatus(){
    this.statusTurma = false;
    this.idTurma = "";
    let modalSenha:any = document.getElementById('senhaModalStatusCol');
    modalSenha.style.display = 'none';
  }

  openTurmas(inst:any){
    setTimeout(() => {
      window.scroll(0,0);
      let divTurmas:any = document.getElementById('myModalDetailsCol');
      divTurmas.scroll(0,0);
    }, 1000);
    this.turmasColegioShow = true;
    this.arrayTurmas = [];
    let o:any = Object.entries(inst)[0][1];
    let arr:any = [];
    let ctrl = 1;
    o.turmas.forEach((i:any) => {
      arr.push(i);
      if(ctrl == o.turmas.length){
        this.arrayTurmas = arr.sort((a:any,b:any) => {if(a.curso < b.curso){return -1}return 1});
        this.contarTurmasCol();
      }else{
        ctrl++
      }
    });
  }

  contarTurmasCol(){
    this.totalPre = 0;
    this.totalFechado = 0;
    this.totalAberto = 0;
    this.totalUrgente = 0;
    this.totalFechadoTrends = 0;
    this.totalNegociacao = 0;
    this.arrayTurmas.forEach((item:any) => {
      if(item.situacao == "ABERTO"){
        this.totalAberto++
      }
      if(item.situacao == "FECHADO" && item.empresa != 'TRENDS FORMATURAS'){
        this.totalFechado++
      }
      if(item.situacao == "FECHADO" && item.empresa == 'TRENDS FORMATURAS'){
        this.totalFechadoTrends++
      }
      if(item.situacao == "ATENDIMENTO URGENTE"){
        this.totalUrgente++
      }
      if(item.situacao == "PRÉ-CADASTRO"){
        this.totalPre++
      }
      if(item.situacao == "NEGOCIAÇÃO"){
        this.totalNegociacao++
      }
    })
  }

  closeModalTurmaCol(){
    this.turmasColegioShow = false;
    this.pastas = true;
  }

}