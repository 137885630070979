import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DataBaseService {
   urlDataBase:any = "https://sa-east-1.aws.data.mongodb-api.com/app/application-0-ugppx/endpoint/http/get";
   API_URL_SAUDADE:any = this.urlDataBase+'?coll=saudade';
   API_URL_BAILE:any = this.urlDataBase+'?coll=baile';
   API_URL_CIDADES:any = this.urlDataBase+'?coll=cidades';
   API_URL_COLACAO:any = this.urlDataBase+'?coll=colacao';
   API_URL_CURSOS:any = this.urlDataBase+'?coll=cursos';
   API_URL_TODOS_CURSOS:any = this.urlDataBase+'?coll=todoscursos';
   API_URL_EVENTOS:any = this.urlDataBase+'?coll=eventos';
   API_URL_INSTITUICOES:any = this.urlDataBase+'?coll=instituicoes';
   API_URL_JANTAR:any = this.urlDataBase+'?coll=jantar';
   API_URL_MISSA:any = this.urlDataBase+'?coll=missa';
   API_URL_TURMAS:any = this.urlDataBase+'?coll=turmas';
   API_URL_USUARIOS:any = this.urlDataBase+'?coll=usuarios';
   API_URL_GALERIAS:any = this.urlDataBase+'?coll=galerias';
   API_URL_IMAGENS:any = this.urlDataBase+'?coll=imagens';
   API_URL_SAUDADE_COL:any = this.urlDataBase+'?coll=saudadecol';
   API_URL_MISSA_COL:any = this.urlDataBase+'?coll=missacol';
   API_URL_JANTAR_COL:any = this.urlDataBase+'?coll=jantarcol';
   API_URL_COLACAO_COL:any = this.urlDataBase+'?coll=colacaocol';
   API_URL_BAILE_COL:any = this.urlDataBase+'?coll=bailecol';
   API_URL_RELATORIOS:any = this.urlDataBase+'?coll=relatorios';
   API_URL_CONFIGS:any = this.urlDataBase+'?coll=configs';
   API_URL_ORCAMENTOS:any = this.urlDataBase+'?coll=orcamentos';
   API_URL_DOCUMENTOS:any = this.urlDataBase+'?coll=documentos';
   API_URL_AGENDA:any = this.urlDataBase+'?coll=agenda';
   API_URL_CONTRATOS:any = this.urlDataBase+'?coll=contratos';
   API_URL_EMPRESAS:any = this.urlDataBase+'?coll=empresas';
   API_URL_FOTOS:any = this.urlDataBase+'?coll=fotos';
   API_URL_LOGS:any = this.urlDataBase+'?coll=logs';
   API_URL_MINHA_FORMATURA:any = this.urlDataBase+'?coll=minhaformatura';
   API_URL_PERSONALIZADOS_BAILE:any = this.urlDataBase+'?coll=personalizadosBaile';
   API_URL_PERSONALIZADOS_BAILE_COLEGIO:any = this.urlDataBase+'?coll=personalizadosBaileColegio';
   API_URL_FORMATURA_INFANTIL:any = this.urlDataBase+'?coll=formaturainf';
   API_URL_PROD_FOTO_INFANTIL:any = this.urlDataBase+'?coll=prodfotoinf';
   API_URL_PRE_EVENTOS_INFANTIL:any = this.urlDataBase+'?coll=preeventosinf';

   urlSite:any = 'https://app.trendsformaturas.com.br';
   tema:any = "#795896"
   constructor(public http:HttpClient){}

   getOrcamentoByTurmaId(key:any){
      return this.http.get(this.API_URL_ORCAMENTOS+'&method=GET&filtro={"turmaId":"'+key+'"}&sort={"data":-1, "hora":-1}')
   }

   getDocumentosByTurmaId(key:any){
      return this.http.get(this.API_URL_DOCUMENTOS+'&method=GET&filtro={"turmaId":"'+key+'"}')
   }

   getRelatoriosByTurmaId(key:any){
      return this.http.get(this.API_URL_RELATORIOS+'&method=GET&filtro={"idTurma":"'+key+'"}&sort={"data":-1, "hora":-1}')
   }

   getContratosByTurmaId(key:any){
      return this.http.get(this.API_URL_CONTRATOS+'&method=GET&filtro={"turmaId":"'+key+'"}')
   }

   getCursosByInstituicao(key:any){
      return this.http.get(this.API_URL_CURSOS+'&method=GET&filtro={"instituicao":"'+key+'"}')
   }

   getCursosByNomeInstituicao(curso:any,faculdade:any){
      return this.http.get(this.API_URL_CURSOS+'&method=GET&filtro={"nome":"'+curso+'", "instituicao":"'+faculdade+'"}')
   }

   getCursosByInstituicaoCidade(instituicao:any,cidade:any){
      return this.http.get(this.API_URL_CURSOS+'&method=GET&filtro={"instituicao":"'+instituicao+'", "cidade":"'+cidade+'"}&sort={"nome":1}')
   }

   getCursosByInstituicaoCidadeNome(instituicao:any,cidade:any,nome:any){
      return this.http.get(this.API_URL_CURSOS+'&method=GET&filtro={"instituicao":"'+instituicao+'", "cidade":"'+cidade+'","nome":"'+nome+'"}&sort={"nome":1}')
   }
   
   getInstituicoesByCidade(key:any){
      return this.http.get(this.API_URL_INSTITUICOES+'&method=GET&filtro={"tipo":"UNIVERSIDADE","cidade":"'+key+'"}&sort={"nome":1}')
   } 

   getInstituicoesColegioByCidade(key:any){
      return this.http.get(this.API_URL_INSTITUICOES+'&method=GET&filtro={"cidade":"'+key+'","tipo":"COLEGIO"}&sort={"nome":1}')
   }

   getInstituicoesEscolaColegioByCidade(key:any){
      return this.http.get(this.API_URL_INSTITUICOES+'&method=GET&filtro={"cidade":"'+key+'","$or":[{"tipo":"COLEGIO"},{"tipo":"ESCOLA"}]}&sort={"nome":1}')
   }

   getInstituicoesEscolaByCidade(key:any){
      return this.http.get(this.API_URL_INSTITUICOES+'&method=GET&filtro={"cidade":"'+key+'","tipo":"ESCOLA"}&sort={"nome":1}')
   }

   getInstituicoesInfantilByCidade(key:any){
      return this.http.get(this.API_URL_INSTITUICOES+'&method=GET&filtro={"cidade":"'+key+'","tipo":"INFANTIL"}&sort={"nome":1}')
   }

   getProposta(){
      return this.http.get(this.API_URL_CONFIGS+'&method=GET&filtro={"nome":"nProposta"}')
   }

   getSms(){
      return this.http.get(this.API_URL_CONFIGS+'&method=GET&filtro={"nome":"sms"}')
   }

   patchProposta(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_CONFIGS+'&method=PATCH&id='+id+'&body='+body)
   }

   patchCursos(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_CONFIGS+'&method=PATCH&id='+id+'&body='+body)
   }

   patchAssessorias(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_CONFIGS+'&method=PATCH&id='+id+'&body='+body)
   }

   patchPersonalizadosBaile(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_PERSONALIZADOS_BAILE+'&method=PATCH&id='+id+'&body='+body)
   }

   patchPersonalizadosBaileColegio(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_PERSONALIZADOS_BAILE_COLEGIO+'&method=PATCH&id='+id+'&body='+body)
   }
   
   getAssessoria(){
      return this.http.get(this.API_URL_CONFIGS+'&method=GET&filtro={"nome":"assessoria"}')
   }

   getAssessorias(){
      return this.http.get(this.API_URL_CONFIGS+'&method=GET&filtro={"nome":"assessorias}')
   }

   getCursosConfig(){
      return this.http.get(this.API_URL_CONFIGS+'&method=GET&filtro={"nome":"cursos"}')
   }

   getConfigs(){
      return this.http.get(this.API_URL_CONFIGS+'&method=GET')
   }

   patchAssessoria(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_CONFIGS+'&method=PATCH&id='+id+'&body='+body)
   }

   getEventos(){
      return this.http.get(this.API_URL_EVENTOS+'&method=GET');
   }

   getLogs(){
      return this.http.get(this.API_URL_LOGS+'&method=GET');
   }

   getLogsFilter(dtInicio:any,dtFim:any,descricao:any){
      return this.http.get(this.API_URL_LOGS+'&method=GET&filtro={"data":{"$gte":"'+dtInicio+'","$lte":"'+dtFim+'"},"descricao":{"$regex":"'+descricao+'","$options":"i"}}');
   }

   getFotos(){
      return this.http.get(this.API_URL_FOTOS+'&method=GET');
   }

   getFotosInfantil(){
      return this.http.get(this.API_URL_FOTOS+'&method=GET&filtro={"galeria":"INFANTIL"}');
   }

   getFotosById(id:any){
      return this.http.get(this.API_URL_FOTOS+'&method=GET&id='+id);
   }

   getEventosById(id:any){
      return this.http.get(this.API_URL_EVENTOS+'&method=GET&id='+id);
   }

   getEventosByCidade(key:any){
      return this.http.get(this.API_URL_EVENTOS+'&method=GET&filtro={"cidade":"'+key+'"}');
   }

   patchEventos(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_EVENTOS+'&method=PATCH&id='+id+'&body='+body)
   }

   patchFotos(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_FOTOS+'&method=PATCH&id='+id+'&body='+body)
   }

   postFotos(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_FOTOS+'&method=POST&body='+body);
   }

   postLogs(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_LOGS+'&method=POST&body='+body);
   }

   postEventos(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_EVENTOS+'&method=POST&body='+body);
   }

   novaEmpresa(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_EMPRESAS+'&method=POST&body='+body);
   }

   postOrcamento(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_ORCAMENTOS+'&method=POST&body='+body);
   }

   delEventos(id:any){
      return this.http.get(this.API_URL_EVENTOS+'&method=DELETE&id='+id);
     }

   delItemFotos(id:any){
   return this.http.get(this.API_URL_FOTOS+'&method=DELETE&id='+id);
   }

   getBaile(){
      return this.http.get(this.API_URL_BAILE+'&method=GET&sort={"titulo":1}');
   }

   getBaileById(id:any){
      return this.http.get(this.API_URL_BAILE+'&method=GET&id='+id);
   }

   patchBaile(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_BAILE+'&method=PATCH&id='+id+'&body='+body)
   }

   postBaile(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_BAILE+'&method=POST&body='+body);
   }

   delBaile(id:any){
      return this.http.get(this.API_URL_BAILE+'&method=DELETE&id='+id);
     }

   getJantar(){
      return this.http.get(this.API_URL_JANTAR+'&method=GET&sort={"titulo":1}');
   }

   getJantarById(id:any){
      return this.http.get(this.API_URL_JANTAR+'&method=GET&id='+id);
   }

   patchJantar(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_JANTAR+'&method=PATCH&id='+id+'&body='+body)
   }

   postJantar(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_JANTAR+'&method=POST&body='+body);
   }

   delJantar(id:any){
      return this.http.get(this.API_URL_JANTAR+'&method=DELETE&id='+id);
     }

   getColacao(){
      return this.http.get(this.API_URL_COLACAO+'&method=GET&sort={"titulo":1}');
   }

   getColacaoById(id:any){
      return this.http.get(this.API_URL_COLACAO+'&method=GET&id='+id);
   }

   patchColacao(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_COLACAO+'&method=PATCH&id='+id+'&body='+body)
   }

   postColacao(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_COLACAO+'&method=POST&body='+body);
   }

   delColacao(id:any){
      return this.http.get(this.API_URL_COLACAO+'&method=DELETE&id='+id);
     }

   getMissa(){
      return this.http.get(this.API_URL_MISSA+'&method=GET&sort={"titulo":1}');
   }

   getMissaById(id:any){
      return this.http.get(this.API_URL_MISSA+'&method=GET&id='+id);
   }

   patchMissa(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_MISSA+'&method=PATCH&id='+id+'&body='+body)
   }

   postMissa(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_MISSA+'&method=POST&body='+body);
   }

   delMissa(id:any){
      return this.http.get(this.API_URL_MISSA+'&method=DELETE&id='+id);
     }

   getSaudade(){
      return this.http.get(this.API_URL_SAUDADE+'&method=GET&sort={"titulo":1}');
   }

   getSaudadeById(id:any){
      return this.http.get(this.API_URL_SAUDADE+'&method=GET&id='+id);
   }

   patchSaudade(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_SAUDADE+'&method=PATCH&id='+id+'&body='+body)
   }

   postSaudade(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_SAUDADE+'&method=POST&body='+body);
   }

   delSaudade(id:any){
      return this.http.get(this.API_URL_SAUDADE+'&method=DELETE&id='+id);
     }

   postGaleria(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_GALERIAS+'&method=POST&body='+body)
    }

    patchGaleria(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_GALERIAS+'&method=PATCH&id='+id+'&body='+body)
    }


   deleteImagemGaleria(id:any){
      return this.http.get(this.API_URL_IMAGENS+'&method=DELETE&id='+id);
     }

   removeDoc(fullUrl:any){
      let url = this.urlSite+'/deleteDoc.php?path='+fullUrl;
      return this.http.post(url,{});
    }

    removeUpload(fullUrl:any){
      let url = this.urlSite+'/deleteImagem.php?path='+fullUrl;
      return this.http.post(url,{});
    }

    removeDocTurma(fullUrl:any){
      let url = this.urlSite+'/deleteDocTurma.php?path='+fullUrl;
      return this.http.post(url,{});
    }

   getImagensByUrl(nomeGaleria:any){
      return this.http.get(this.API_URL_IMAGENS+'&method=GET&filtro={"url":{"$regex":"'+nomeGaleria+'"}}')
   }

   postImagem(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_IMAGENS+'&method=POST&body='+body)
   }

   postDocumentos(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_DOCUMENTOS+'&method=POST&body='+body)
   }

   deleteGaleria(id:any){
      return this.http.get(this.API_URL_GALERIAS+'&method=DELETE&id='+id);
   }

   getGaleriaByName(galeria:any){
      return this.http.get(this.API_URL_IMAGENS+'&method=GET&filtro={"galeria":"'+galeria+'"}')
   }

   enviarImagemGaleria(galeria:any,imagedata:any,random:any){
      let url = this.urlSite+'/uploadDoc.php?galeria='+galeria+'&random='+random;
      return this.http.post(url, imagedata);
   }

   getGalerias(){
      return this.http.get(this.API_URL_GALERIAS+'&method=GET&sort={"galeria":1}')
   }

   getGaleriaById(id:any){
      return this.http.get(this.API_URL_GALERIAS+'&method=GET&id='+id);
    }

   getOrcamentoById(id:any){
      return this.http.get(this.API_URL_ORCAMENTOS+'&method=GET&id='+id);
    }

    getTodasTurmas(tipo:any,formado:boolean){
     let formados = !formado ? '"situacao":{"$not":{"$regex":"FORMADO","$options":"i"}},' : '"situacao":"FORMADO",';
      return this.http.get(this.API_URL_TURMAS+'&method=GET&filtro={'+formados+'"tipo":"'+tipo+'"}&sort={"cidade":1, "instituicao":1, "curso":1,"ano":1,"periodoTurma":1,"turma":1,"sala":1,"periodo":1}');
    }

    getEmpresas(){
      return this.http.get(this.API_URL_EMPRESAS+'&method=GET&sort={"nome":1}');
    }    

    getTurmasByData(dataInicio:any,dataFim:any){
      return this.http.get(this.API_URL_TURMAS+'&method=GET&filtro={"dataFim":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"}}&sort={"cidade":1, "instituicao":1, "curso":1,"ano":1,"periodoTurma":1,"turma":1,"sala":1,"periodo":1}');
    }

    getTurmasByCidade(key:any){
      return this.http.get(this.API_URL_TURMAS+'&method=GET&filtro={"cidade":{"$regex":"'+key+'","$options":"i"}}&sort={"cidade":1, "instituicao":1, "curso":1,"ano":1,"periodoTurma":1,"turma":1,"sala":1,"periodo":1}');
    }
    
    getTurmasByGraduacao(key:any){
      return this.http.get(this.API_URL_TURMAS+'&method=GET&filtro={"graduacao":{"$regex":"'+key+'","$options":"i"}}&sort={"cidade":1, "instituicao":1, "curso":1,"ano":1,"periodoTurma":1,"turma":1,"sala":1,"periodo":1}');
    }

    getTurmasByInstituicao(key:any){
      return this.http.get(this.API_URL_TURMAS+'&method=GET&filtro={"instituicao":{"$regex":"'+key+'","$options":"i"}}&sort={"cidade":1, "instituicao":1, "curso":1,"ano":1,"periodoTurma":1,"turma":1,"sala":1,"periodo":1}');
    }

    getTurmasByCurso(key:any){
      return this.http.get(this.API_URL_TURMAS+'&method=GET&filtro={"curso":{"$regex":"'+key+'","$options":"i"}}&sort={"cidade":1, "instituicao":1, "curso":1,"ano":1,"periodoTurma":1,"turma":1,"sala":1,"periodo":1}');
    }

    getTurmasBySituacao(key:any){
      return this.http.get(this.API_URL_TURMAS+'&method=GET&filtro={"situacao":{"$regex":"'+key+'","$options":"i"}}&sort={"cidade":1, "instituicao":1, "curso":1,"ano":1,"periodoTurma":1,"turma":1,"sala":1,"periodo":1}');
    }

    getTurmasByConsultor(key:any){
      return this.http.get(this.API_URL_TURMAS+'&method=GET&filtro={"consultor":{"$regex":"'+key+'","$options":"i"}}&sort={"cidade":1, "instituicao":1, "curso":1,"ano":1,"periodoTurma":1,"turma":1,"sala":1,"periodo":1}');
    }  

    getTurmasGeral(dataInicio:any,dataFim:any,curso:any,instituicao:any,graduacao:any,cidade:any,situacao:any,consultor:any,tipoturmas:any){
      let sit:any = "";
      let tipo:any = tipoturmas ? ',"tipo":"'+tipoturmas+'"' : '';
      if(dataInicio == ""){dataInicio = "2000-01-01"}
       if(dataFim == ""){dataFim = "5000-01-01"}
       if(situacao == ""){sit = '"situacao":{"$ne":"FORMADO"},'}else{sit = '"situacao":{"$regex":"'+situacao+'","$options":"i"},'}

      return this.http.get(this.API_URL_TURMAS+'&method=GET&filtro={'+sit+'"dataInicio":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"dataFim":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"curso":{"$regex":"'+curso+'","$options":"i"},"instituicao":{"$regex":"'+instituicao+'","$options":"i"},"graduacao":{"$regex":"'+graduacao+'","$options":"i"},"cidade":{"$regex":"'+cidade+'","$options":"i"},"consultor":{"$regex":"'+consultor+'","$options":"i"}}&sort={"cidade":1,"instituicao":1, "curso":1,"ano":1,"periodoTurma":1,"turma":1,"sala":1,"periodo":1}');
    }  

    getTurmasGeralFormados(dataInicio:any,dataFim:any,curso:any,instituicao:any,graduacao:any,cidade:any,consultor:any){
      if(dataInicio == ""){
         dataInicio = "2000-01-01"
      }
      if(dataFim == ""){
         dataFim = "5000-01-01"
      }
     return this.http.get(this.API_URL_TURMAS+'&method=GET&filtro={"situacao":"FORMADO","dataInicio":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"dataFim":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"curso":{"$regex":"'+curso+'","$options":"i"},"instituicao":{"$regex":"'+instituicao+'","$options":"i","$not":{"$regex":"COLÉGIO","$options":"i"}},"graduacao":{"$regex":"'+graduacao+'","$options":"i"},"cidade":{"$regex":"'+cidade+'","$options":"i"},"consultor":{"$regex":"'+consultor+'","$options":"i"}}&sort={"cidade":1, "instituicao":1, "curso":1,"ano":1,"periodoTurma":1,"turma":1,"sala":1,"periodo":1}');
   }  

    getTurmasGeralColegio(dataInicio:any,dataFim:any,curso:any,instituicao:any,cidade:any,situacao:any,consultor:any){
      if(dataInicio == ""){
         dataInicio = "2000-01-01"
      }
      if(dataFim == ""){
         dataFim = "5000-01-01"
      }
     return this.http.get(this.API_URL_TURMAS+'&method=GET&filtro={"dataInicio":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"dataFim":{"$gte":"'+dataInicio+'","$lte":"'+dataFim+'"},"curso":{"$regex":"'+curso+'","$options":"i"},"instituicao":{"$regex":"COLÉGIO'+instituicao+'","$options":"i"},"cidade":{"$regex":"'+cidade+'","$options":"i"},"situacao":{"$regex":"'+situacao+'","$options":"i"},"consultor":{"$regex":"'+consultor+'","$options":"i"}}');
   }  

    getTurmasByCursoOrcamento(curso:any,instituicao:any,cidade:any){
      return this.http.get(this.API_URL_TURMAS+'&method=GET&filtro={"curso":"'+curso+'", "instituicao":"'+instituicao+'", "cidade":"'+cidade+'","situacao":{"$not":{"$regex":"FECHADO","$options":"i"}}}');
    }

   getTurmaById(id:any){
      return this.http.get(this.API_URL_TURMAS+'&method=GET&id='+id);
    }

    getTurmaBySituacao(key:any){
      return this.http.get(this.API_URL_TURMAS+'&method=GET&filtro={"situacao":"'+key+'"}');
    }

    getTurmaInicio(){
      return this.http.get(this.API_URL_TURMAS+'&method=GET&filtro={"situacao":{"$not":{"$regex":"FORMADO","$options":"i"}},"tipo":"UNIVERSIDADE","$or":[{"situacao":"ATENDIMENTO URGENTE"},{"situacao":"NEGOCIAÇÃO"}]}&sort={"cidade":1, "instituicao":1, "curso":1,"ano":1,"periodoTurma":1,"turma":1,"sala":1,"periodo":1}');
    }

    getTurmaColegioInicio(){
      return this.http.get(this.API_URL_TURMAS+'&method=GET&filtro={"instituicao":{"$regex":"COLÉGIO","$options":"i"},"$or":[{"situacao":"ATENDIMENTO URGENTE"},{"situacao":"NEGOCIAÇÃO"}]}&sort={"cidade":1, "instituicao":1, "curso":1,"ano":1,"periodoTurma":1,"turma":1,"sala":1,"periodo":1}');
    }

    patchTurma(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_TURMAS+'&method=PATCH&id='+id+'&body='+body)
   }

   getContratoById(id:any){
      return this.http.get(this.API_URL_CONTRATOS+'&method=GET&id='+id);
    }

   deleteAgenda(id:any){
      return this.http.get(this.API_URL_AGENDA+'&method=DELETE&id='+id);
     }

   patchAgenda(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_AGENDA+'&method=PATCH&id='+id+'&body='+body)
   }

   getAllAgenda(){
      return this.http.get(this.API_URL_AGENDA+'&method=GET')
    }

   postAgenda(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_AGENDA+'&method=POST&body='+body)
    }

   getAgendaByVendedorData(vendedor:any,start:any,end:any){
      return this.http.get(this.API_URL_AGENDA+'&method=GET&filtro={"vendedor":"'+vendedor+'","start":{"$gte":"'+start+'"},"end":{"$lte":"'+end+'"},"resolvido":false}')
   }

   getAgendaByVendedor(vendedor:any){
      return this.http.get(this.API_URL_AGENDA+'&method=GET&filtro={"vendedor":"'+vendedor+'"}')
   }

   getUsuarios(){
      return this.http.get(this.API_URL_USUARIOS+'&method=GET&sort={"nome":1}')
   }

   getAllVendedores(){
      return this.http.get(this.API_URL_USUARIOS+'&method=GET&filtro={"tipo":"Vendedor"}')
   }

   getUsuarioByEmail(key:any){
      return this.http.get(this.API_URL_USUARIOS+'&method=GET&filtro={"email":"'+key+'"}')
   }

   getCidades(){
      return this.http.get(this.API_URL_CIDADES+'&method=GET&sort={"nome":1}')
   }

   getOrcamentoByData(inicio:any,fim:any){
      return this.http.get(this.API_URL_ORCAMENTOS+'&method=GET&filtro={"data":{"$gte":"'+inicio+'","$lte":"'+fim+'"}}')
   }

   getOrcamentoByProposta(key:any){
      return this.http.get(this.API_URL_ORCAMENTOS+'&method=GET&filtro={"nProposta":"'+key+'"}')
   }

   getOrcamentoByInstituicao(key:any){
      return this.http.get(this.API_URL_ORCAMENTOS+'&method=GET&filtro={"instituicao":{"$regex":"'+key+'", "$options":"i"}}')
   }

   getOrcamentoByCurso(key:any){
      return this.http.get(this.API_URL_ORCAMENTOS+'&method=GET&filtro={"curso":{"$regex":"'+key+'", "$options":"i"}}')
   }
   
   getOrcamentoByContato(key:any){
      return this.http.get(this.API_URL_ORCAMENTOS+'&method=GET&filtro={"contatos.nome":{"$regex":"'+key+'", "$options":"i"}}')
   }
   
   getOrcamentoByConsultor(key:any){
      return this.http.get(this.API_URL_ORCAMENTOS+'&method=GET&filtro={"consultor":{"$regex":"'+key+'", "$options":"i"}}')
   }

   getOrcamentoByCidade(key:any){
      return this.http.get(this.API_URL_ORCAMENTOS+'&method=GET&filtro={"cidade":"'+key+'"}')
   }

   postCidade(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_CIDADES+'&method=POST&body='+body)
   }

   postInstituicao(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_INSTITUICOES+'&method=POST&body='+body)
   }

   postCurso(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_CURSOS+'&method=POST&body='+body)
   }

   postTurma(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_TURMAS+'&method=POST&body='+body)
   }

   deleteCidade(id:any){
      return this.http.get(this.API_URL_CIDADES+'&method=DELETE&id='+id);
     }

   deleteEmpresa(id:any){
   return this.http.get(this.API_URL_EMPRESAS+'&method=DELETE&id='+id);
   }

   deleteInstituicao(id:any){
      return this.http.get(this.API_URL_INSTITUICOES+'&method=DELETE&id='+id);
   }

   deleteCurso(id:any){
      return this.http.get(this.API_URL_CURSOS+'&method=DELETE&id='+id);
   }

   deleteTurma(id:any){
      return this.http.get(this.API_URL_TURMAS+'&method=DELETE&id='+id);
   }

   deleteDocumento(id:any){
      return this.http.get(this.API_URL_DOCUMENTOS+'&method=DELETE&id='+id);
   }

   deleteContrato(id:any){
      return this.http.get(this.API_URL_CONTRATOS+'&method=DELETE&id='+id);
   }

   deleteUsuario(id:any){
      return this.http.get(this.API_URL_USUARIOS+'&method=DELETE&id='+id);
   }

   enviarDocTurma(turmaId:any,imagedata:any,random:any){
      let url = this.urlSite+'/uploadDocTurma.php?turma='+turmaId+'&random='+random;
      return this.http.post(url, imagedata);
   }

   postContrato(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_CONTRATOS+'&method=POST&body='+body);
   }

   postUsuario(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_USUARIOS+'&method=POST&body='+body);
   }

   postRelatorio(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_RELATORIOS+'&method=POST&body='+body);
   }

   patchOrcamento(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_ORCAMENTOS+'&method=PATCH&id='+id+'&body='+body)
   }

   patchContrato(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_CONTRATOS+'&method=PATCH&id='+id+'&body='+body)
   }

   patchUsuario(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_USUARIOS+'&method=PATCH&id='+id+'&body='+body)
   }

   getImagensByGaleria(key:any){
      return this.http.get(this.API_URL_IMAGENS+'&method=GET&filtro={"galeria":"'+key+'"}')     
   }

   getMissaByTipo(key:any){
     return this.http.get(this.API_URL_MISSA+'&method=GET&filtro={"tipo":"'+key+'"}')
   }

   getColacaoByTipo(key:any){
      return this.http.get(this.API_URL_COLACAO+'&method=GET&filtro={"tipo":"'+key+'"}')
    }

   getSaudadeByTipo(key:any){
      return this.http.get(this.API_URL_SAUDADE+'&method=GET&filtro={"tipo":"'+key+'"}')
    }

   getJantarByTipo(key:any){
      return this.http.get(this.API_URL_JANTAR+'&method=GET&filtro={"tipo":"'+key+'"}')
   }

   getBaileByTipo(key:any){
      return this.http.get(this.API_URL_BAILE+'&method=GET&filtro={"tipo":"'+key+'"}')
   }

   getPreEventosByTipo(key:any){
      return this.http.get(this.API_URL_PRE_EVENTOS_INFANTIL+'&method=GET&filtro={"tipo":"'+key+'"}')
    }

    getProducaoFotograficaByTipo(key:any){
      return this.http.get(this.API_URL_PROD_FOTO_INFANTIL+'&method=GET&filtro={"tipo":"'+key+'"}')
    }

    getFormaturasByTipo(key:any){
      return this.http.get(this.API_URL_FORMATURA_INFANTIL+'&method=GET&filtro={"tipo":"'+key+'"}')
    }

   getTurmaUnica(cidade:any,instituicao:any,curso:any,sala:any,periodo:any){
      return this.http.get(this.API_URL_TURMAS+'&method=GET&filtro={"cidade":"'+cidade+'","instituicao":"'+instituicao+'","curso":"'+curso+'","sala":"'+sala+'","periodo":"'+periodo+'"}&sort={"curso":1, "ano":-1}');
   }

   getMinhaFormaturaByData(inicio:any,fim:any){
      return this.http.get(this.API_URL_MINHA_FORMATURA+'&method=GET&filtro={"data":{"$gte":"'+inicio+'","$lte":"'+fim+'"}}')
   }

   getMinhaFormaturaByInstituicao(key:any){
      return this.http.get(this.API_URL_MINHA_FORMATURA+'&method=GET&filtro={"instituicao":{"$regex":"'+key+'", "$options":"i"}}')
   }

   getMinhaFormaturaByCurso(key:any){
      return this.http.get(this.API_URL_MINHA_FORMATURA+'&method=GET&filtro={"curso":{"$regex":"'+key+'", "$options":"i"}}')
   }
 
   getMinhaFormaturaByCidade(key:any){
      return this.http.get(this.API_URL_MINHA_FORMATURA+'&method=GET&filtro={"cidade":"'+key+'"}')
   }

   getMinhaFormatura(){
      return this.http.get(this.API_URL_MINHA_FORMATURA+'&method=GET')
   }

   getCursos(){
      return this.http.get(this.API_URL_TODOS_CURSOS+'&method=GET');
   }

   getInstituicoes(){
      return this.http.get(this.API_URL_INSTITUICOES+'&method=GET')
   }

   getInstituicoesFaculdade(){
      return this.http.get(this.API_URL_INSTITUICOES+'&method=GET&filtro={"tipo":"UNIVERSIDADE"}')
   }

   getInstituicoesColegio(){
      return this.http.get(this.API_URL_INSTITUICOES+'&method=GET&filtro={"tipo":"COLEGIO"}')
   }

   getInstituicoesInfantil(){
      return this.http.get(this.API_URL_INSTITUICOES+'&method=GET&filtro={"tipo":"INFANTIL"}')
   }

   getInstituicoesEscola(){
      return this.http.get(this.API_URL_INSTITUICOES+'&method=GET&filtro={"tipo":"ESCOLA"}')
   }

   getFormaturaById(id:any){
      return this.http.get(this.API_URL_MINHA_FORMATURA+'&method=GET&id='+id);
    }

   getPersonalizadosBaile(){
      return this.http.get(this.API_URL_PERSONALIZADOS_BAILE+'&method=GET');
   }

   getPersonalizadosBaileColegio(){
      return this.http.get(this.API_URL_PERSONALIZADOS_BAILE_COLEGIO+'&method=GET');
   }

   getFormaturaInfantil(){
      return this.http.get(this.API_URL_FORMATURA_INFANTIL+'&method=GET');
   }

   getFormaturaInfantilById(id:any){
      return this.http.get(this.API_URL_FORMATURA_INFANTIL+'&method=GET&id='+id);
   }

   patchFormaturaInfantil(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_FORMATURA_INFANTIL+'&method=PATCH&id='+id+'&body='+body)
   }

   postFormaturaInfantil(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_FORMATURA_INFANTIL+'&method=POST&body='+body);
   }

   delFormaturaInfantil(id:any){
      return this.http.get(this.API_URL_FORMATURA_INFANTIL+'&method=DELETE&id='+id);
     }

   getProdFotoInfantil(){
      return this.http.get(this.API_URL_PROD_FOTO_INFANTIL+'&method=GET');
   }

   getProdFotoInfantilById(id:any){
      return this.http.get(this.API_URL_PROD_FOTO_INFANTIL+'&method=GET&id='+id);
   }
   
   patchProdFotoInfantil(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_PROD_FOTO_INFANTIL+'&method=PATCH&id='+id+'&body='+body)
   }

   postProdFotoInfantil(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_PROD_FOTO_INFANTIL+'&method=POST&body='+body);
   }

   delProdFotoInfantil(id:any){
      return this.http.get(this.API_URL_PROD_FOTO_INFANTIL+'&method=DELETE&id='+id);
     }

   getPreEventosInfantil(){
      return this.http.get(this.API_URL_PRE_EVENTOS_INFANTIL+'&method=GET');
   }

   getPreEventosInfantilById(id:any){
      return this.http.get(this.API_URL_PRE_EVENTOS_INFANTIL+'&method=GET&id='+id);
   }

   patchPreEventosInfantil(id:any,dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_PRE_EVENTOS_INFANTIL+'&method=PATCH&id='+id+'&body='+body)
   }

   postPreEventosInfantil(dados:any){
      let body = JSON.stringify(dados);
      return this.http.get(this.API_URL_PRE_EVENTOS_INFANTIL+'&method=POST&body='+body);
   }

   delPreEventosInfantil(id:any){
      return this.http.get(this.API_URL_PRE_EVENTOS_INFANTIL+'&method=DELETE&id='+id);
     }
   

}