import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'; 
import { DataBaseService } from 'src/app/provider';
import { AuthService } from 'src/app/auth.service';
import { Location } from '@angular/common';
import extenso from 'extenso'

@Component({
  selector: 'app-details-contrato',
  templateUrl: './details-contrato.component.html',
  styleUrls: ['./details-contrato.component.css']
})

export class DetailsContratoComponent implements OnInit {
contrato:any = [];
orcamento:any = [];
turma:any = [];
arrayItensContratados:any = [];
valorPorExtenso:any = "";
formandosExtenso:any = "";
valorFormandoExtenso:any = "";
parcelasExtenso:any = "";
totalPorFormandosPorMesExtenso:any = "";
assessoriaCerimonialExtenso:any = "";
percentualCerimonialNr:any;
percentualCerimonialExtenso:any = "";
dataInicio:any = "";
dataFim:any = "";
anoFim:any = "";
arrayPgtosAssessoria:any = [];
key:any = "";

constructor(private location:Location, public auth:AuthService, private route: ActivatedRoute, private db:DataBaseService) {
  this.key = this.route.snapshot.paramMap.get('key');
}
 
ngOnInit(){
  this.db.getContratoById(this.key).subscribe((data:any) => {
    this.contrato = data;
    this.db.getTurmaById(this.contrato.turmaId).subscribe((data:any) => {
      this.turma = data;
     this.db.getOrcamentoById(this.contrato.orcamentoId).subscribe((data:any) => {
        this.orcamento = data;
        if(this.orcamento.aulaSaudade.length != 0){this.arrayItensContratados.push("Aula Saudade")}
        if(this.orcamento.missa.length != 0){this.arrayItensContratados.push("Missa")}
        if(this.orcamento.colacao.length != 0){this.arrayItensContratados.push("Colação de Grau")}
        if(this.orcamento.jantar.length != 0){this.arrayItensContratados.push("Jantar Dançante")}
        if(this.orcamento.baile.length != 0){this.arrayItensContratados.push("Baile de Gala")}
        this.formandosExtenso = extenso(this.orcamento.formandos)
        this.parcelasExtenso = extenso(this.orcamento.parcelas)
        this.dataInicio = this.orcamento.dtInicio;
        this.dataFim = this.orcamento.dtFim;
        let dataInicioSplit =  this.dataInicio.split('T')[0];
        let dataFimSplit =  this.dataFim.split('T')[0];
        let mesInicio = dataInicioSplit.split('-')[1];
        let anoInicio = dataInicioSplit.split('-')[0];
        let mesFim = dataFimSplit.split('-')[1];
        this.anoFim = dataFimSplit.split('-')[0];
        let difAnos = this.anoFim - anoInicio
        let vlrParceladoAssessoria = (this.orcamento.assessoriaCerimonial/difAnos)
        if(mesFim < 12){
          this.anoFim = this.anoFim - 1;
          let ano = this.anoFim;
          for(let i = 0; i < difAnos; i++){
            this.arrayPgtosAssessoria.push({
              data: "10/12/"+(ano),
              vlr:vlrParceladoAssessoria
            })
            ano = ano - 1;
          }
        }else{
          this.anoFim = this.anoFim;
          difAnos = difAnos+1;
          let ano = this.anoFim;
          for(let i = 0; i < difAnos; i++){
            this.arrayPgtosAssessoria.push({
              data: "10/12/"+(ano),
              vlr:vlrParceladoAssessoria
            })
            ano = ano - 1;
          }
        }
        // VALOR EXTENSO TOTAL SOMA
        let origem = this.orcamento.totalSomas;
        let vlrOrigem = origem.toFixed(2).toString().split('.');
        let reaisNr = vlrOrigem[0];
        let centsNr = vlrOrigem[1];
        let vlr;
          if (centsNr != 0){
          vlr = extenso(reaisNr)+' reais e '+extenso(centsNr)+' centavos'
          }
          else{
          vlr = extenso(reaisNr)+' reais'
          }
        this.valorPorExtenso = vlr;

        //// VALOR EXTENSO TOTAL POR FORMANDO
        let origemFormando = this.orcamento.totalPorFormandos.toFixed(2).toString()
        let vlrOrigemFormando = origemFormando.split('.');
        let reaisNrFormando = vlrOrigemFormando[0];
        let centsNrFormando = vlrOrigemFormando[1];
        let vlrFormando;
          if (centsNrFormando != 0){
          vlrFormando = extenso(reaisNrFormando)+' reais e '+extenso(centsNrFormando)+' centavos'
          }
          else{
          vlrFormando = extenso(reaisNrFormando)+' reais'
          }
        this.valorFormandoExtenso = vlrFormando;

        //// VALOR EXTENSO MENSAL POR FORMANDO
        let origemFormandoMes = this.orcamento.totalPorFormandosPorMes.toFixed(2).toString()
        let vlrOrigemFormandoMes = origemFormandoMes.split('.');
        let reaisNrFormandoMes = vlrOrigemFormandoMes[0];
        let centsNrFormandoMes = vlrOrigemFormandoMes[1];
        let vlrFormandoMes;
          if (centsNrFormandoMes != 0){
          vlrFormandoMes = extenso(reaisNrFormandoMes)+' reais e '+extenso(centsNrFormandoMes)+' centavos'
          }
          else{
          vlrFormandoMes = extenso(reaisNrFormandoMes)+' reais'
          }
        this.totalPorFormandosPorMesExtenso = vlrFormandoMes;

        //// VALOR CERIMONIAL
        let origemCerimonial = this.orcamento.assessoriaCerimonial.toFixed(2).toString()
        let vlrOrigemCerimonial = origemCerimonial.split('.');
        let reaisNrCerimonial = vlrOrigemCerimonial[0];
        let centsNrCerimonial = vlrOrigemCerimonial[1];
        let vlrCerimonial;
          if (centsNrCerimonial != 0){
          vlrCerimonial = extenso(reaisNrCerimonial)+' reais e '+extenso(centsNrCerimonial)+' centavos'
          }
          else{
          vlrCerimonial = extenso(reaisNrCerimonial)+' reais'
          }
        this.assessoriaCerimonialExtenso = vlrCerimonial;

        //// PORCENTAGEM CERIMONIAL
        this.percentualCerimonialNr = (this.orcamento.assessoriaCerimonial/this.orcamento.totalSomas)*100
        if(Number.isInteger(this.percentualCerimonialNr)){
          this.percentualCerimonialExtenso = extenso(this.percentualCerimonialNr)+' por cento'
        }else{
          this.percentualCerimonialNr = ((this.orcamento.assessoriaCerimonial/this.orcamento.totalSomas)*100).toFixed(2)
          let inteiros = this.percentualCerimonialNr.toString().split('.')[0]
          let decimos = this.percentualCerimonialNr.toString().split('.')[1]
          let inteirosExtenso = extenso(inteiros)
          let decimosExtenso = extenso(decimos)
          this.percentualCerimonialExtenso = inteirosExtenso+' inteiros e '+decimosExtenso+' decimos por cento'
        }
      })
    })
  }) 
}

  printContrato() {
     window.print();
  }

  goBack(){
    this.location.back();
   }

}
