<div class="topoLogo">
    <div>
        <img src="../assets/img/logo.png" style="height:90px">
    </div>
</div>  
<div  style="padding-left: 5.5em">
<table class="table table-striped">
    <thead>
        <th>Usuário</th>
        <th>E-mail</th>
        <th>Tipo</th>
        <th>Situação</th>
        <th></th>
        <th></th>
    </thead>
    <tr *ngFor="let users of usuarios">
        <td>{{ users?.nome }}</td>
        <td>{{ users?.email }}</td>
        <td>{{ users?.tipo }}</td>
        <td>{{ users?.situacao }}</td>
        <td>
            <button class="btn btn-outline-info" (click)="openModalEdit(users?._id, users?.nome, users?.email, users?.tipo, users?.senha,users?.situacao,users?.imprimirValores)">
                <fa-icon [icon]="['fas', 'edit']" size="1x"></fa-icon>
            </button>
        </td>
        <td>
            <button class="btn btn-outline-danger" (click)="deleteUser(users)">
                <fa-icon [icon]="['fas', 'trash-alt']" size="1x"></fa-icon>
            </button>
        </td>
    </tr>
</table>
</div>
<!-- BOTAO ADD NOVO USUARIO -->
<div style="position: fixed; bottom:0; width: 100%; z-index:1;text-align: center;">  
        <button class="btn btn-success btn-block btn-lg" (click)="openModal()">
            <fa-icon [icon]="['fas', 'plus-square']" size="1x"></fa-icon>
            CADASTRAR NOVO USUÁRIO
        </button>
    </div> 
<!-- MODAL ADD USUARIO -->
<div id="modalAdd" class="modal">
    <span (click)="closeModal()" class="close">&times;</span>
    <div style="height: 100px"></div>
    <div class="filtroContainer" style="padding-left: 2em">
        <div class="cadastroOption">
        <form [formGroup]="novoUsuarioForm" (ngSubmit)="novoUsuario(
            novoUsuarioForm.get('nome')?.value,
            novoUsuarioForm.get('email')?.value,
            novoUsuarioForm.get('tipo')?.value,
            novoUsuarioForm.get('senha')?.value,
            novoUsuarioForm.get('imprimirValores')?.value
            )">
            <h5>ADICIONAR NOVO USUÁRIO</h5> 
            <div class="row">
                <div class="col">
                    <label>Nome</label>
                    <input type="text" class="form-control" formControlName="nome">
                </div>
                <div class="col">
                    <label>E-mail</label>
                    <input class="form-control" formControlName="email">
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label>Senha</label>
                    <input type="password" class="form-control" formControlName="senha">
                </div>
                <div class="col">
                    <label>Tipo</label>
                    <select class="form-control" formControlName="tipo">
                        <option value="Administrador">Administrador</option>
                        <option value="Gestor">Gestor</option>
                        <option value="Vendedor">Vendedor</option>
                    </select>
                </div>
                <div class="col" style="display:flex;align-items: center;">
                    <label for="checkValores">Imprimir Valores</label><br>
                    <mat-checkbox class="example-margin" id="checkValores" color="primary" formControlName="imprimirValores"></mat-checkbox>
                </div>
            </div>
        <div style="text-align: center; margin-top:5em"> 
            <button type="button" class="btn btn-danger" (click)="closeModal()">CANCELAR</button>
            &nbsp;&nbsp;
            <button type="submit" class="btn btn-info" [disabled]="novoUsuarioForm.invalid">ADICIONAR USUÁRIO</button>
        </div> 
    </form>
    </div>
</div>
</div>

   
<!-- MODAL EDIT USUARIO -->
<div id="modalEdit" class="modal">
        <span (click)="closeModalEdit()" class="close">&times;</span>
        <div style="height: 100px"></div>
        <div class="filtroContainer" style="padding-left: 2em">
            <div class="cadastroOption">
            <form [formGroup]="editUsuarioForm" (ngSubmit)="editarUsuario(
                editUsuarioForm.get('nome')?.value,
                editUsuarioForm.get('email')?.value,
                editUsuarioForm.get('tipo')?.value,
                editUsuarioForm.get('senha')?.value,
                editUsuarioForm.get('situacao')?.value,
                editUsuarioForm.get('imprimirValores')?.value)
                ">
                <h5>EDITAR USUÁRIO</h5> 
                <div class="row">
                    <div class="col">
                        <label>Nome</label>
                        <input type="text" class="form-control" formControlName="nome">
                    </div>
                    <div class="col">
                        <label>E-mail</label>
                        <input class="form-control" formControlName="email">
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-3">
                        <label>Senha</label>
                        <input type="password" class="form-control" formControlName="senha">
                    </div>
                    <div class="col col-3">
                        <label>Tipo</label>
                        <select class="form-control" formControlName="tipo">
                            <option value="Administrador">Administrador</option>
                            <option value="Gestor">Gestor</option>
                            <option value="Vendedor">Vendedor</option>
                        </select>
                    </div>
                    <div class="col" style="display:flex;align-items: center;">
                        <label for="checkValores">Imprimir Valores</label><br>
                        <mat-checkbox class="example-margin" id="checkValores" color="primary" formControlName="imprimirValores"></mat-checkbox>
                    </div>
                    <div class="col">
                        <label>Situação</label>
                        <select class="form-control" formControlName="situacao">
                            <option value="ATIVO">ATIVO</option>
                            <option value="BLOQUEADO">BLOQUEADO</option>
                        </select>
                    </div>
                </div>
            <div style="text-align: center; margin-top:5em"> 
                <button type="button" class="btn btn-danger" (click)="closeModalEdit()">CANCELAR</button>
                &nbsp;&nbsp;
                <button type="submit" class="btn btn-info" [disabled]="editUsuarioForm.invalid">EDITAR USUÁRIO</button>
            </div> 
        </form>
        </div>
    </div>
    </div>