<div class="no-print">
  <div class="back" (click)="goBack()">
    <fa-icon [icon]="['fas', 'arrow-alt-circle-left']" size="2x"></fa-icon>
  </div>
  <div class="print" (click)="printOrcamento()">
    <fa-icon [icon]="['fas', 'print']" size="2x"></fa-icon>
  </div>
</div>
<div class="margem">
<div style="width: 100%; height: 300px; text-align: center; padding-top: 80px">
    <img src="../assets/img/icone.png" style="height:90px">
</div>
<br> 
<div style="padding-left:60px">Nº da Proposta: {{orcamento?.nProposta}}</div>
  <br>
  <div class="capaOrcamento">
    <div class="row">
      <div class="col" style="color:rgb(122, 37, 156); text-align: center">CURSO</div>
      <div class="col" style="color:rgb(122, 37, 156); text-align: center">TURMA</div>
      <div class="col" style="color:rgb(122, 37, 156); text-align: center">ANO</div>
      <div class="col" style="color:rgb(122, 37, 156); text-align: center">PERIODO</div>
      <div class="col" style="color:rgb(122, 37, 156); text-align: center">INSTITUIÇÃO</div>
    </div>
    <div class="row" *ngFor="let turma of orcamento?.arrayTurmas">
      <div class="col" style="text-align: center">{{ turma?.curso }}</div>
      <div class="col" style="text-align: center">{{ turma?.turma }}</div>
      <div class="col" style="text-align: center">{{ turma?.anoTurma }}</div>
      <div class="col" style="text-align: center">{{ turma?.periodoTurma }}</div>
      <div class="col" style="text-align: center">{{ turma?.instituicao }}</div>
    </div>
  </div>
  <br>
  <div class="capaOrcamento">
      <div class="row">
          <div class="col" style="color:rgb(122, 37, 156)">Periodo</div>
          <div class="col" style="color:rgb(122, 37, 156)">Prestações</div>
          <div class="col" style="color:rgb(122, 37, 156)">Formandos</div>
          <div class="col" style="color:rgb(122, 37, 156)">Dias de Evento</div>
        </div>
        <div class="row">
          <div class="col">{{ orcamento?.dtInicio | date:'dd/MM/yyyy'}} a {{orcamento?.dtFim | date:'dd/MM/yyyy'}}</div>
          <div class="col">{{ orcamento?.parcelas }}</div>
          <div class="col">{{ orcamento?.formandos }}</div>
          <div class="col">{{ orcamento?.diasEvento }}</div>
        </div>
        <div class="row">
          <div class="col" style="color:rgb(122, 37, 156)">Local do Jantar Dançante</div>
          <div class="col" style="color:rgb(122, 37, 156)">Local do Baile de Gala</div>
          <div class="col" style="color:rgb(122, 37, 156)">Cidade</div>
        </div>
        <div class="row">
          <div class="col">{{orcamento?.localJantar}}</div>
          <div class="col">{{orcamento?.localBaile}}</div>
          <div class="col">{{orcamento?.cidade}}</div>
        </div>
    </div>
    <br>
    <div class="capaOrcamento">    
      <div style="color:rgb(122, 37, 156)">CONTATOS DA COMISSÃO</div>
      <div class="row" *ngFor="let comissao of orcamento?.contatos">
        <div class="col col-4">{{comissao?.nome}}</div>
        <div class="col col-4 telefoneComissaoPrint" style="text-align: center">{{comissao?.telefone.slice(0,10) + '-****'}}</div>
        <div class="col col-4 telefoneComissao" style="text-align: center">{{comissao?.telefone }}</div>
        <div class="col col-4" style="text-align: center">{{comissao?.email}}</div>
      </div>
    </div>   
    <br>
<hr>
  <!-- FIM CABEÇALHO -->
  <img src="../../../assets/img/tag-pre-eventos.png" style="margin-left:10px; width: 100%">
  <div *ngFor="let array of aulaSaudadeArray; let odd = odd" style="padding-left:10px">
  <div class="row" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px;">
    <div class="col col-3" style="text-align: justify">{{array?.titulo}}</div>
    <div class="col" style="text-align: justify">{{array?.descricao}}</div>
    <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array?.subTotal | currency:'BRL' }}</div>
    <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array?.valor | currency:'BRL' }}</div>
  </div>
</div>
<div class="row" style="margin-top: 10px">
<div class="col" style="text-align: right"><strong>Total: {{ orcamento?.totalAulaSaudade | currency:'BRL' }}</strong></div>
</div>
<br>
<img src="../../../assets/img/tag-culto.png" style="margin-left:10px; width: 100%">
<div *ngFor="let array of missaArray; let odd = odd" style="padding-left:10px">
  <div class="row" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px;">
    <div class="col col-3" style="text-align: justify">{{array?.titulo}}</div>
    <div class="col" style="text-align: justify">{{array?.descricao}}</div>
    <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array?.subTotal | currency:'BRL' }}</div>
    <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array?.valor | currency:'BRL'}}</div>
  </div>
</div>
<div class="row" style="margin-top: 10px">
  <div class="col" style="text-align: right"><strong>Total: {{ orcamento?.totalMissa | currency:'BRL' }}</strong></div>
</div>
<br>
<img src="../../../assets/img/tag-colacao.png" style="margin-left:10px; width: 100%">
<div *ngFor="let array of colacaoArray; let odd = odd" style="padding-left:10px">
<div class="row" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px;">
  <div class="col col-3" style="text-align: justify">{{array?.titulo}}</div>
  <div class="col" style="text-align: justify">{{array?.descricao}}</div>
  <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array?.subTotal | currency:'BRL' }}</div>
  <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array?.valor | currency:'BRL'}}</div>
</div>
</div>
<div class="row" style="margin-top: 10px">
<div class="col no-print" style="text-align: right"><strong>Total: {{ orcamento?.totalColacao | currency:'BRL' }}</strong></div>
</div>
<br>
<img src="../../../assets/img/tag-jantar.png" style="margin-left:10px; width: 100%">
<div *ngFor="let array of jantarArray; let odd = odd" style="padding-left:10px">
<div class="row" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px;">
  <div class="col col-3" style="text-align: justify">{{array?.titulo}}</div>
  <div class="col" style="text-align: justify">{{array?.descricao}}</div>
  <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array?.subTotal | currency:'BRL' }}</div>
  <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array?.valor | currency:'BRL'}}</div>
</div>
</div>
<div class="row" style="margin-top: 10px">
  <div class="col" style="text-align: right"><strong>Total: {{ orcamento?.totalJantar | currency:'BRL' }}</strong></div>
</div>
<br>
<img src="../../../assets/img/tag-baile.png" style="margin-left:10px; width: 100%">
<div *ngFor="let array of baileArray; let odd = odd" style="padding-left:10px">
<div class="row" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px;">
  <div class="col col-3" style="text-align: justify">{{array?.titulo}}</div>
  <div class="col" style="text-align: justify">{{array?.descricao}}</div>
  <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'" [hidden]="array.subTotal == 0">{{array.subTotal | currency:'BRL' }}</div>
  <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'" [hidden]="array.valor == 0">{{array.valor | currency:'BRL'}}</div>
  <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'" [hidden]="array.subTotal != 0">Incluso</div>
  <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'" [hidden]="array.valor != 0">Incluso</div>
</div>
</div>
<div class="row" style="margin-top: 10px">
  <div class="col" style="text-align: right"><strong>Total: {{ orcamento?.totalBaile | currency:'BRL' }}</strong></div>
</div>
<br>
<img src="../../../assets/img/tag-assessoria.png" style="margin-left:10px; width: 100%">
<div>
    <div class="row" style="padding: 5px; padding-left:10px">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Assessoria e Cerimonial</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right"></div>
  </div>
  <div class="row" style="padding: 5px; padding-left:10px; background-color: rgb(240, 240, 240)">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Administrativo</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right"></div>
  </div>
  <div class="row" style="padding: 5px; padding-left:10px">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Juridico</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right"></div>
  </div>
  <div class="row" style="padding: 5px; padding-left:10px; background-color: rgb(240, 240, 240)">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Cobrança</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right"></div>
  </div>
</div>
<div class="page-break"></div>
<div class="row" style="margin-top: 10px">
  <div class="col no-print" style="text-align: right"><strong>Total: {{ orcamento?.assessoriaCerimonial | currency:'BRL' }}</strong></div>
</div>
<br>
<img src="../../../assets/img/tag-foto-filmagem.png" style="margin-left:10px; width: 100%">
<div class="page-break"></div>
<div class="planoFotos">
  <div class="row"style="text-align:center;font-weight: bold;">
    <div class="col">
      Itens inclusos no projeto de formatura.
    </div>
  </div>
  <br>
  <div *ngFor="let foto of fotosPagas; let odd = odd">
    <div class="row"  [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px; padding-left:10px">
      <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">{{foto?.nome}}</div>
      <div class="col" style="font-size: 0.85em">{{foto?.descricao}}</div>
      <div class="col col-2 no-print" style="text-align: right">
        <span *ngIf="tipoUsuario == 'Administrador'">{{foto?.valor | currency:'BRL'}}</span>
      </div>
    </div>
  </div>
</div>
<div class="page-break"></div>
<div class="planoFotos">
  <div class="row"style="text-align:center;font-weight: bold;">
    <div class="col">
      O material fotográfico será comercializado em até 120 dias após o ultimo evento!<br> O formando não tem obrigatoriedade de compra.
    </div>
  </div>
  <br>
  <div *ngFor="let foto of fotosGratis; let odd = odd">
    <div class="row"  [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px; padding-left:10px">
      <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">{{foto?.nome}}</div>
      <div class="col col-7" style="font-size: 0.85em">{{foto?.descricao}}</div>
      <div class="col col-2" style="text-align: right">
        <span>{{foto?.valor | currency:'BRL'}}</span>
      </div>
    </div>
  </div>
</div>
<div class="page-break"></div>
<!-- INICIO GALERIA -->
<div *ngFor="let img of orcamento?.imagensOrcamento" style="text-align: center;">
    <img [src]="img.url" class="imagemOrcamento"> 
</div>
<!-- FIM GALERIA -->
<div class="page-break"></div>
<!-- INICIO PLANO FINANCEIRO -->
<div style="height: 4em;"></div>
<img src="../../../assets/img/tag-financeiro.png" style="margin-left:10px; width: 100%">
<br><br>
<div class="planoFinanceiro">
<span>{{orcamento?.parcelas}}x de </span><span>{{ orcamento?.totalPorFormandosPorMes | currency:'BRL' }}</span>
</div>
<br>
<br>
<!-- FIM PLANO FINANCEIRO -->
<!-- INICIO RIFA -->
<div style="height: 4em;"></div>
<img src="../../../assets/img/tag-rifas.png" style="margin-left:10px; width: 100%">
<table class="table table-striped" style="padding-left: 25px">
  <thead>
    <tr style="text-align: center">
      <th></th>
      <th>POR SEMESTRE</th>
      <th>POR ANO</th>
  </tr>
  </thead>
  <tbody>
   <tr>
    <td>01 Rifa  (50 números, a R$ 10,00 cada) será reduzido da formatura o valor de:</td>
    <td style="text-align: center">{{ orcamento?.umaRifaSemestre | currency:'BRL' }}</td>
    <td style="text-align: center">{{ orcamento?.umaRifaAno | currency:'BRL' }}</td>
  </tr>
  <tr>
    <td>02 Rifa  (50 números, a R$ 10,00 cada) será reduzido da formatura o valor de:</td>
    <td style="text-align: center">{{ orcamento?.duasRifaSemestre | currency:'BRL' }}</td>
    <td style="text-align: center">{{ orcamento?.duasRifaAno | currency:'BRL' }}</td>
  </tr>
  <tr>
    <td>03 Rifa  (50 números, a R$ 10,00 cada) será reduzido da formatura o valor de:</td>
    <td style="text-align: center">{{ orcamento?.tresRifaSemestre | currency:'BRL' }}</td>
    <td style="text-align: center">{{ orcamento?.tresRifaAno | currency:'BRL' }}</td>
  </tr>
  </tbody>
</table>
<div class="row" style="text-align: center; padding:10px; background-color:#dee2e6">
    <div class="col col-12">
      <strong>ITENS RIFADOS FORNECIDOS PELA TRENDS FORMATURAS</strong>
    </div>
    <div class="col" style="text-align: center">
      <p>1º Prêmio uma Smartphone</p>
      <p>2º Prêmio um Notebook</p>
      <p>3º Prêmio um Netbook</p>
    </div>
</div>
<!-- FIM RIFA -->
<hr>
<!-- INICIO CERVEJADA -->
<div style="height: 4em;"></div>
<img src="../../../assets/img/tag-cervejada.png" style="margin-left:10px; width: 100%">
<table class="table table-striped" style="padding-left: 25px">
    <thead>
      <tr style="text-align: center">
      <th></th>
      <th>QTDE. INGRESSOS X FORMANDOS</th>
      <th>TOTAL INGRESSOS</th>
      <th>LUCRO</th>
      <th>LUCRO TOTAL DA TURMA</th>
    </tr>
    </thead>
    <tbody>
      <tr>
        <td>01 Cervejada Por Ano</td>
        <td style="text-align: center">6</td>
        <td style="text-align: center">{{ orcamento?.cervejada6totalIngressos }}</td>
        <td style="text-align: center">{{ orcamento?.cervejada6lucro | currency:'BRL' }}</td>
        <td style="text-align: center">{{ orcamento?.cervejada6lucroTotalTurma | currency:'BRL' }}</td>
      </tr>
      <tr>
        <td>02 Cervejadas Por Ano</td>
        <td style="text-align: center">12</td>
        <td style="text-align: center">{{ orcamento?.cervejada12totalIngressos }}</td>
        <td style="text-align: center">{{ orcamento?.cervejada12lucro | currency:'BRL' }}</td>
        <td style="text-align: center">{{ orcamento?.cervejada12lucroTotalTurma | currency:'BRL' }}</td>
      </tr>
    </tbody>
  </table>
<!-- FIM CERVEJADA -->
<div class="page-break"></div>
<!-- INICIO PLANOS DE CONVITES -->
<img src="../../../assets/img/tag-convites.png" style="margin-left:10px; width: 100%">
  <br>
<table class="table tableConvites table-striped" style="font-size: 0.95em; line-height: 1em; padding-left: 25px">
  <thead>
    <tr>
      <td></td>
      <td class="bgVerde baile"><strong>PARA O BAILE 10</strong></td>
      <td class="bgVerde baile"><strong>PARA O BAILE 16</strong></td>
      <td class="bgVerde baile"><strong>PARA O BAILE 20</strong></td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="esquerda"><strong>PARA O JANTAR 8 CONVITES +&nbsp;&nbsp;</strong></td>
      <td class="bgVerde">{{ orcamento?.convite8jantar10baile | currency:'BRL'}}</td>
      <td class="bgVerde">{{ orcamento?.convite8jantar16baile | currency:'BRL'}}</td>
      <td class="bgVerde">{{ orcamento?.convite8jantar20baile | currency:'BRL'}}</td>
    </tr>
    <tr>
      <td class="esquerda">Total Por Formando</td>
      <td class="bgVerde">{{ orcamento?.convite8jantar10bailePorFormando | currency:'BRL'}}</td>
      <td class="bgVerde">{{ orcamento?.convite8jantar16bailePorFormando | currency:'BRL'}}</td>
      <td class="bgVerde">{{ orcamento?.convite8jantar20bailePorFormando | currency:'BRL'}}</td>
    </tr>
    <tr>
      <td class="esquerda">Valor da Parcela</td>
      <td class="bgVerde">{{ orcamento?.convite8jantar10baileVlrParcela | currency:'BRL'}}</td>
      <td class="bgVerde">{{ orcamento?.convite8jantar16baileVlrParcela | currency:'BRL'}}</td>
      <td class="bgVerde">{{ orcamento?.convite8jantar20baileVlrParcela | currency:'BRL'}}</td>
    </tr>
    </tbody>
  </table>
  <table class="table tableConvites table-striped" style="font-size: 0.95em; line-height: 1em; padding-left: 25px">
    <thead>
        <tr>
          <td></td>
          <td class="bgAzul baile"><strong>PARA O BAILE 10</strong></td>
          <td class="bgAzul baile"><strong>PARA O BAILE 16</strong></td>
          <td class="bgAzul baile"><strong>PARA O BAILE 20</strong></td>
        </tr>
      </thead>
      <tbody>
    <tr>
      <td class="esquerda"><strong>PARA O JANTAR 10 CONVITES +</strong></td>
      <td class="bgAzul">{{ orcamento?.convite10jantar10baile | currency:'BRL'}}</td>
      <td class="bgAzul">{{ orcamento?.convite10jantar16baile | currency:'BRL'}}</td>
      <td class="bgAzul">{{ orcamento?.convite10jantar20baile | currency:'BRL'}}</td>
    </tr>
    <tr>
      <td class="esquerda">Total Por Formando</td>
      <td class="bgAzul">{{ orcamento?.convite10jantar10bailePorFormando | currency:'BRL'}}</td>
      <td class="bgAzul">{{ orcamento?.convite10jantar16bailePorFormando | currency:'BRL'}}</td>
      <td class="bgAzul">{{ orcamento?.convite10jantar20bailePorFormando | currency:'BRL'}}</td>
    </tr>
    <tr>
      <td class="esquerda">Valor da Parcela</td>
      <td class="bgAzul">{{ orcamento?.convite10jantar10baileVlrParcela | currency:'BRL'}}</td>
      <td class="bgAzul">{{ orcamento?.convite10jantar16baileVlrParcela | currency:'BRL'}}</td>
      <td class="bgAzul">{{ orcamento?.convite10jantar20baileVlrParcela | currency:'BRL'}}</td>
    </tr>
  </tbody>
</table>
<table class="table tableConvites table-striped" style="font-size: 0.95em; line-height: 1em; padding-left: 25px">
  <thead>
      <tr>
        <td></td>
        <td class="bgAzulEscuro baile"><strong>PARA O BAILE 10</strong></td>
        <td class="bgAzulEscuro baile"><strong>PARA O BAILE 16</strong></td>
        <td class="bgAzulEscuro baile"><strong>PARA O BAILE 20</strong></td>
      </tr>
    </thead>
    <tbody>
    <tr>
      <td class="esquerda"><strong>PARA O JANTAR 16 CONVITES +</strong></td>
      <td class="bgAzulEscuro">{{ orcamento?.convite16jantar10baile | currency:'BRL'}}</td>
      <td class="bgAzulEscuro">{{ orcamento?.convite16jantar16baile | currency:'BRL'}}</td>
      <td class="bgAzulEscuro">{{ orcamento?.convite16jantar20baile | currency:'BRL'}}</td>
    </tr>
    <tr>
      <td class="esquerda">Total Por Formando</td>
      <td class="bgAzulEscuro">{{ orcamento?.convite16jantar10bailePorFormando | currency:'BRL'}}</td>
      <td class="bgAzulEscuro">{{ orcamento?.convite16jantar16bailePorFormando | currency:'BRL'}}</td>
      <td class="bgAzulEscuro">{{ orcamento?.convite16jantar20bailePorFormando | currency:'BRL'}}</td>
    </tr>
    <tr>
      <td class="esquerda">Valor da Parcela</td>
      <td class="bgAzulEscuro">{{ orcamento?.convite16jantar10baileVlrParcela | currency:'BRL'}}</td>
      <td class="bgAzulEscuro">{{ orcamento?.convite16jantar16baileVlrParcela | currency:'BRL'}}</td>
      <td class="bgAzulEscuro">{{ orcamento?.convite16jantar20baileVlrParcela | currency:'BRL'}}</td>
    </tr>
  </tbody>
</table>
<table class="table tableConvites table-striped" style="font-size: 0.95em; line-height: 1em; padding-left: 25px">
  <thead>
      <tr>
        <td></td>
        <td class="bgVermelho baile"><strong>PARA O BAILE 10</strong></td>
        <td class="bgVermelho baile"><strong>PARA O BAILE 16</strong></td>
        <td class="bgVermelho baile"><strong>PARA O BAILE 20</strong></td>
      </tr>
    </thead>
    <tbody>
    <tr>
      <td class="esquerda"><strong>PARA O JANTAR 20 CONVITES +</strong></td>
      <td class="bgVermelho">{{ orcamento?.convite20jantar10baile | currency:'BRL'}}</td>
      <td class="bgVermelho">{{ orcamento?.convite20jantar16baile | currency:'BRL'}}</td>
      <td class="bgVermelho">{{ orcamento?.convite20jantar20baile | currency:'BRL'}}</td>
    </tr>
    <tr>
      <td class="esquerda">Total Por Formando</td>
      <td class="bgVermelho">{{ orcamento?.convite20jantar10bailePorFormando | currency:'BRL'}}</td>
      <td class="bgVermelho">{{ orcamento?.convite20jantar16bailePorFormando | currency:'BRL'}}</td>
      <td class="bgVermelho">{{ orcamento?.convite20jantar20bailePorFormando | currency:'BRL'}}</td>
    </tr>
    <tr>
      <td class="esquerda">Valor da Parcela</td>
      <td class="bgVermelho">{{ orcamento?.convite20jantar10baileVlrParcela | currency:'BRL'}}</td>
      <td class="bgVermelho">{{ orcamento?.convite20jantar16baileVlrParcela | currency:'BRL'}}</td>
      <td class="bgVermelho">{{ orcamento?.convite20jantar20baileVlrParcela | currency:'BRL'}}</td>
    </tr>
  </tbody>
</table>
<!-- FIM PLANOS DE CONVITES -->
<!-- INICIO PLANO DE RENDIMENTOS -->
<div style="height: 4em;"></div>
<img src="../../../assets/img/tag-rendimento.png" style="margin-left:10px; width: 100%">
<table class="table table-striped" style="padding-left: 25px">
    <thead>
      <tr style="text-align: center">
      <th></th>
      <th>MENSAL</th>
      <th>TOTAL</th>
    </tr>
    </thead>
    <tbody>
      <tr>
        <td>RENDIMENTO INDIVIDUAL</td>
        <td style="text-align: center">{{ orcamento?.rendimentoMensalIndividual | currency:'BRL' }}</td>
        <td style="text-align: center">{{ orcamento?.rendimentoTotalIndividual | currency:'BRL' }}</td>
      </tr>
      <tr>
        <td>RENDIMENTO DA TURMA</td>
        <td style="text-align: center">{{ orcamento?.rendimentoMensalTurma | currency:'BRL' }}</td>
        <td style="text-align: center">{{ orcamento?.rendimentoTotalTurma | currency:'BRL' }}</td>
      </tr>
      <tr>
        <td style="text-align: center">Valor corrigido com taxa estimada de:  {{ orcamento?.rendimentoVlrCorrigido | currency:'BRL' }}</td>
        <td colspan="2" style="text-align: center">RENDIMENTO TOTAL DA TURMA: {{ orcamento?.rendimentoTotal | currency:'BRL' }}</td>
      </tr>
      <tr style="background-color: rgb(233, 233, 233); font-weight: 700; text-align: center">
          <td colspan="3">Obs: Todo o dinheiro arrecado com os eventos será destinado a turma para usarem da forma que convém.</td>
        </tr>
    </tbody>
  </table>
<!-- FIM PLANO DE RENDIMENTOS -->
<div class="page-break"></div>
<!-- INICIO DIFERENCIAIS -->
<img src="../../../assets/img/tag-diferenciais.png" style="margin-left:10px; width: 100%">
<table class="table table-striped" style="padding-left: 25px">
  <tbody>
    <tr>
      <td>Trends Rifa- Prêmios anuais para arrecadação em rifas. As rifas abrangem todas as turmas da Trends</td>
    </tr>
    <tr>
      <td>Trends On Line - Sistema de Informação Gerencial - exclusividade Trends Formaturas</td>
    </tr>
    <tr>
      <td>Bank Trends e Trends Express - Administração Financeira e atendimento personalizado</td>
    </tr>
    <tr>
      <td>Trends Idéias - Idéias Inovadoras nunca vistas ou realizadas no segmento de Formaturas</td>
    </tr>
    <tr>
       <td>Trends Criação - Engenheiros de Criação e Eventos Tematizados</td>
    </tr>
    <tr>
      <td>Atendimento Personalizado - Gerentes Executivos e Empresa Departamentalizada</td>
    </tr>
    <tr>
      <td>Fale com a Trends - contato@trendsformaturas.com.br</td>
    </tr>
    <tr>
      <td>Trends Qualidade - Padrão de Qualidade Trends Formaturas</td>
    </tr>
  </tbody>
  </table>
  <div style="height: 4em;"></div>
  <hr>
  <img src="../../../assets/img/tag-fotos.png" style="margin-left:10px; width: 100%">
  <table class="table table-striped" style="padding-left: 25px">
    <tbody>
      <tr>
        <td>Capacidade de Produção de 10.000 fotos/dia (prazo de entrega reduzido)</td>
      </tr>
      <tr>
        <td>Estrutura de estúdio fotográfico na Trends Formaturas (acompanhamento personalizado)</td>
      </tr>
      <tr>
        <td>Cobertura Fotográfica de todos os eventos oficiais da formatura e outros como churrascos, confratenizações, encontro dos formandos</td>
      </tr>
      <tr>
        <td>Foto tamanho 24 X 30 e preços menores dos que os praticados pela média do mercado do Paraná</td>
      </tr>
      <tr>
        <td>50% de desconto no valor da Produção Fotográfica dos convites</td>
      </tr>
      <tr>
        <td>O formando não terá a obrigação de comprar um minimo de fotos. Assim, cada formando pode comprar quantas fotos quiser</td>
      </tr>
      <tr>
        <td>Venda Pós e sem obrigatoriedade de compra</td>
      </tr>
      <tr>
        <td>Filmagem completa dos eventos da formatura, edição e produção de DVDs</td>
      </tr>
    </tbody>
  </table>

  </div>