<div class="modal-screen" id="aviso">
  <div class="inner">
      <div class="topo-modal">
         AVISO
      </div>
      <div class="content-modal">
          <p class="p-2" style="font-weight:500">{{mensagem}}</p>
          <div class="row align-items-center">
              <div class="col col-12 col-md-12">
                  <button class="btn btn-outline-primary btn-block" (click)="closeAlert()">OK</button>
              </div>
          </div>
      </div>
  </div>
</div>
<div id="modalTurma" class="modal">
  <div class="topVoltar fixo">
    <fa-icon [icon]="['fas', 'arrow-circle-left']" size="2x" (click)="closeModalTurmas()"></fa-icon>
  </div>
    <div class="bgInicio">
          <div class="contentHome">
            <div class="menuCol" routerLink="/turmas-infantil" (click)="closeModalTurmas()">
              <fa-icon [icon]="['fas', 'baby']" size="3x"></fa-icon>
              <br>Infantil
              <br><span class="legendaContent">Maternal I à Pré III</span>
            </div>
            <div class="menuCol" routerLink="/turmas-escola" (click)="closeModalTurmas()">
                <fa-icon [icon]="['fas', 'child']" size="3x"></fa-icon>
                <br>Escola
                <br><span class="legendaContent">1º à 5º Ano</span>
            </div>
            <div class="menuCol" routerLink="/turmas-colegio" (click)="closeModalTurmas()">
                <fa-icon [icon]="['fas', 'chalkboard-teacher']" size="3x"></fa-icon>
                <br>Colegial
                <br><span class="legendaContent">9º à 3º Ano</span>
            </div>
            <div class="menuCol" routerLink="/turmas" (click)="closeModalTurmas()">
                <fa-icon [icon]="['fas', 'graduation-cap']" size="3x"></fa-icon>
                <br>Universidade
            </div>
          </div>
      </div>
</div>
<div id="modalOrcamento" class="modal">
  <div class="topVoltar fixo">
    <fa-icon [icon]="['fas', 'arrow-circle-left']" size="2x" (click)="closeModalOrcamentos()"></fa-icon>
  </div>
  <div class="bgInicio">
    <div class="contentHome">
      <div class="menuCol" routerLink="/orcamento-infantil" (click)="closeModalOrcamentos()">
        <fa-icon [icon]="['fas', 'chalkboard-teacher']" size="3x"></fa-icon>
        <br>Infantil
        <br><span class="legendaContent">Maternal I à Pré III</span>
    </div>
      <div class="menuCol" routerLink="/orcamento-colegio" (click)="closeModalOrcamentos()">
        <fa-icon [icon]="['fas', 'chalkboard-teacher']" size="3x"></fa-icon>
        <br>Escola e Colegial
        <br><span class="legendaContent">1º Fund. à 3º Col.</span>
      </div>
      <div class="menuCol" routerLink="/orcamento" (click)="closeModalOrcamentos()">
          <fa-icon [icon]="['fas', 'graduation-cap']" size="3x"></fa-icon>
          <br>Universidade
      </div>
    </div>
  </div>
</div>
<div id="modalParametros" class="modal">
    <div class="topVoltar fixo">
      <fa-icon [icon]="['fas', 'arrow-circle-left']" size="2x" (click)="closeModalParametros()"></fa-icon>
    </div>
    <div class="bgInicio">
        <div class="contentHome">
            <div class="menuCol" routerLink="/parametros-infantil" (click)="closeModalParametros()">
              <fa-icon [icon]="['fas', 'chalkboard-teacher']" size="3x"></fa-icon>
              <br>Infantil
              <br><span class="legendaContent">Maternal I à Pré III</span>
          </div>
          <div class="menuCol" routerLink="/parametros-colegio" (click)="closeModalParametros()">
              <fa-icon [icon]="['fas', 'chalkboard-teacher']" size="3x"></fa-icon>
              <br>Escola e Colegial
              <br><span class="legendaContent">1º Fund. à 3º Col.</span>
            </div>
          <div class="menuCol" routerLink="/parametros" (click)="closeModalParametros()">
              <fa-icon [icon]="['fas', 'graduation-cap']" size="3x"></fa-icon>
              <br>Universidade
          </div>
        </div>
    </div>
</div>
<div *ngIf="auth.isLoggedIn()" class="no-print">
  <div id="sidebarMenu">
    <ul class="sidebarMenuInner">
      <li routerLink="/home" (click)="fecharAgenda()"><fa-icon [icon]="['fas', 'home']" size="2x" ></fa-icon>
        <br><span class="textMenu">INICIO</span>
      </li>
      <li (click)="openModalTurmas();fecharAgenda()"><fa-icon [icon]="['fas', 'chalkboard']" size="2x"></fa-icon>
        <br> <span class="textMenu">TURMAS</span>
      </li>
      <li (click)="openModalOrcamentos();fecharAgenda()"><fa-icon [icon]="['fas', 'edit']" size="2x"></fa-icon>
        <br> <span class="textMenu">ORÇAMENTO</span>
      </li>
      <li routerLink="/galeria" (click)="fecharAgenda()"><fa-icon [icon]="['fas', 'camera']" size="2x"></fa-icon>
        <br> <span class="textMenu">GALERIA</span>
      </li>
      <li routerLink="/arquivo" (click)="fecharAgenda()"><fa-icon [icon]="['fas', 'folder-open']" size="2x"></fa-icon>
        <br><span class="textMenu">ARQUIVO</span>
      </li>
      <li routerLink="/minha-formatura" (click)="fecharAgenda()"><fa-icon [icon]="['fas', 'globe']" size="2x"></fa-icon>
        <br><span class="textMenu">WEB</span>
      </li>
      <li (click)="openModalParametros();fecharAgenda()" *ngIf="tipoUsuario == 'Administrador' || tipoUsuario == 'Gestor'"><fa-icon [icon]="['fas', 'tasks']" size="2x"></fa-icon>
        <br><span class="textMenu">PADRÕES</span>
      </li>
      <li routerLink="/usuarios" (click)="fecharAgenda()" *ngIf="tipoUsuario == 'Administrador' || tipoUsuario == 'Gestor'"><fa-icon [icon]="['fas', 'users']" size="2x"></fa-icon>
        <br><span class="textMenu">USUÁRIOS</span>
      </li>   
      <li routerLink="/logs" (click)="fecharAgenda()" *ngIf="tipoUsuario == 'Administrador' || tipoUsuario == 'Gestor'"><fa-icon [icon]="['fas', 'list-alt']" size="2x"></fa-icon>
        <br><span class="textMenu">LOGS</span>
      </li>
      <li routerLink="/formados" (click)="fecharAgenda()"><fa-icon [icon]="['fas', 'graduation-cap']" size="2x"></fa-icon>
        <br><span class="textMenu">FORMADOS</span>
      </li>
      <li routerLink="/agenda" (click)="fecharAgenda()" *ngIf="email == 'novachinski@hotmail.com'"><fa-icon [icon]="['fas', 'address-book']" size="2x"></fa-icon>
        <br><span class="textMenu">AGENDAS</span>
      </li>  
      <li (click)="auth.logOut();fecharAgenda()"><fa-icon [icon]="['fas', 'sign-out-alt']" size="2x"></fa-icon>
        <br><span class="textMenu">SAIR</span>
      </li>
    </ul>
  </div>
  <div *ngIf="agenda" class="btnAgenda" (click)="fecharAgenda()">
    &nbsp;&nbsp;<fa-icon [icon]="['fas', 'address-book']" size="2x"></fa-icon>&nbsp;&nbsp;
  </div>
</div>
<div class="modalAgenda" id="agendaModal">
  <div class="agendaContent">
    <jqxScheduler #scheduler
                [date]="date"
                [localization]="localization"
                [source]="dataAdapter"
                [showLegend]="true"
                [view]="'monthView'"
                [resources]="resources"
                [views]="views"
                [editDialogCreate]="editDialogCreate"
                [editDialogOpen]="editDialogOpen"
                [appointmentDataFields]="appointmentDataFields"
                (onAppointmentAdd)="appointmentAdd($event)"
                (onAppointmentChange)="appointmentChange($event)"
                (onAppointmentDelete)="appointmentDelete($event)">
    </jqxScheduler>
  </div>
</div>
<div *ngIf="!agenda">
<div class="floatBottom no-print" [hidden]="loginScreen">
  <div style="text-align: right;">
        <button class="btnAgendaScreen" (click)="openAgenda()" [matBadge]="badgeAgenda" matBadgeColor="accent" matBadgePosition="after">
          &nbsp;&nbsp;<fa-icon [icon]="['fas', 'address-book']" size="2x"></fa-icon>&nbsp;&nbsp;
        </button>
        <button class="btnAgendaScreen" (click)="openAgendaBox()">
          &nbsp;&nbsp;<fa-icon [icon]="['fas', 'tasks']" size="2x"></fa-icon>&nbsp;&nbsp;
      </button>
  </div>
  <div id="box">
      <div class="agendaBox" id="agendaBox">
          <div class="row align-items-center" *ngFor="let agenda of agendaHoje" style="padding-left:5px;padding-top:5px;border-bottom:1px solid #fff;margin-right:0px !important">
            <div class="col col-9">
                <strong>{{ agenda?.vendedor }} - {{ agenda?.start?.split('T')[0] | date:'dd/MM/yyyy' }}<br>{{agenda?.turma}} - </strong>{{agenda?.assunto}}
                <p>{{agenda?.descricao}}</p>
            </div>
            <div class="col col-3" style="text-align: right">
              <button class="btn btn-sm btn-warning" (click)="goToTurma(agenda?.turmaId)" [disabled]="!agenda?.turmaId">
                <fa-icon [icon]="['fas', 'graduation-cap']"></fa-icon>
              </button>&nbsp;&nbsp;
              <button class="btn btn-sm btn-success" (click)="fecharCompromisso(agenda?._id)">
                <fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon>
              </button>
            </div>
          </div>
      </div>
  </div>
</div>
<router-outlet></router-outlet>
</div>
<ng-http-loader></ng-http-loader>