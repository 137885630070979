<div class="row topoLogo no-print">
    <div class="col col-12">
        <a target="_self" routerLink="/home">
            <img src="../assets/img/logo-vibe-branca.png">
        </a>
    </div>
</div>
<div class="back" (click)="goBack()"><fa-icon [icon]="['fas', 'arrow-alt-circle-left']" size="2x"></fa-icon></div>
<mat-tab-group (selectedTabChange)="getParams($event)" backgroundColor="primary" color="accent" style="padding-left: 6em;">
<mat-tab label="Aula da Saudade">
<ng-template matTabContent>
<h4 style="text-align: center; color:blueviolet; padding:1em">Aula da Saudade</h4>
<div *ngFor="let param of parametros; let odd = odd" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="width:95%">
        <div class="filtroContainer">
                <div class="col col-3">
                    <label>Titulo</label><br>
                    <span>{{param.titulo}}</span> 
                </div>
                <div class="col col-2">
                    <label>Tipo</label><br>
                        <span *ngIf="param.tipo == 'checkbox'">Checkbox</span>
                        <span *ngIf="param.tipo == 'selecionavel'">Selecionavel</span>
                </div>
                <div class="col col-2">
                    <div *ngIf="param.tipo != 'selecionavel'">
                    <label>Marcado</label><br>
                        <span *ngIf="param.marcado == true">Sim</span>
                        <span *ngIf="param.marcado == false">Não</span>
                     </div>
                </div>
                    <div class="col col-2">
                        <div *ngIf="param.tipo != 'fixo'">
                            <label>Formando</label><br>
                            <span *ngIf="param.formando == true">Sim</span>
                            <span *ngIf="param.formando == false">Não</span>
                        </div>
                    </div>
                    <div class="col col-1" style="text-align: center;">
                        <label>Posição</label><br>
                        <span>{{param?.posicao}}</span> 
                    </div>
                    <div class="col col-2">
                            <button class="btn btn-success btn-sm" (click)="openEditModal(param._id)">
                            <fa-icon [icon]="['fas', 'edit']" size="1x"></fa-icon>
                        </button>&nbsp;
                        <button class="btn btn-danger btn-sm" (click)="deleteParametro(param?._id)" [disabled]="param.tipo == 'fixo' || (param.titulo == 'Mestre de Cerimônias' || param.titulo== 'Gerador de Energia' || param.titulo == 'Taxa de Eletricidade' || param.titulo == 'Taxa de Água' || param.titulo == 'Coordenador de Eventos')">
                            <fa-icon [icon]="['fas', 'trash']" size="1x"></fa-icon>
                        </button>
                    </div>
                </div>
    <div class="filtroContainer">
        <div class="col col-12" *ngIf="param.tipo == 'checkbox'">
        <label>Valores</label>
            <div class="row" *ngFor="let opt of param.valores | sort:'cidade'">
                <div class="col">
                    <span>{{opt?.valor | currency:'BRL'}}</span>
                </div>            
                <div class="col">
                    <span>{{opt?.cidade}}</span>
                </div>                        
            </div>
            <div class="row">
                <div class="col col-12">
                    <label>Descrição:</label><br>
                    <span>{{param?.descricao}}</span>
                </div>
            </div>
        </div>
        <div class="col col-12" *ngIf="param.tipo == 'selecionavel'">
            <label>Opções</label>
                <div *ngFor="let opt of param.opcoes | sort:'cidade'">
                    <div class="row">
                        <div class="col">
                            <span>{{opt?.nome}}</span>
                        </div>
                        <div class="col">
                            <span>{{opt?.valor | currency:'BRL'}}</span>
                        </div>     
                        <div class="col">
                            <span>{{opt?.cidade}}</span>
                        </div>    
                    </div>     
                    <div class="row">
                        <div class="col">
                            <label>Descrição:</label>&nbsp;
                            {{opt?.descricao}}
                        </div>
                    </div>   
                    <br>                    
                </div>
        </div>
    </div>
    <hr>
</div>
<!-- BOTAO ADD NOVO PARAMETRO -->
<div style="position: fixed; bottom:0; width: 100%; z-index:1">  
    <button class="btn btn-success btn-lg" style="width: 100%" (click)="openModal()">
        <fa-icon [icon]="['fas', 'plus-square']" size="1x"></fa-icon>
        NOVO PARAMETRO
    </button>
</div> 
    </ng-template>
    </mat-tab>
        <mat-tab label="Missa">
            <ng-template matTabContent>
            <h4 style="text-align: center; color:blueviolet; margin:1em">Missa</h4>
            <div *ngFor="let param of parametros; let odd = odd" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="width:95%">
                <div class="filtroContainer">
                    <div class="col col-3">
                        <label>Titulo</label><br>
                        <span>{{param.titulo}}</span> 
                    </div>
                    <div class="col col-2">
                        <label>Tipo</label><br>
                            <span *ngIf="param.tipo == 'checkbox'">Checkbox</span>
                            <span *ngIf="param.tipo == 'selecionavel'">Selecionavel</span>
                            <span *ngIf="param.tipo == 'fixo'">Fixo</span>
                    </div>
                        <div class="col col-2">
                            <div class="full-width">
                                <label>Formando</label><br>
                                <span *ngIf="param.formando == true">Sim</span>
                                <span *ngIf="param.formando == false">Não</span>
                            </div>
                        </div>
                        <div class="col col-2" *ngIf="param.tipo != 'selecionavel'">
                            <label>Marcado</label><br>
                                <span *ngIf="param.marcado == true">Sim</span>
                                <span *ngIf="param.marcado == false">Não</span>
                        </div>
                        <div class="col col-1" style="text-align: center;">
                            <label>Posição</label><br>
                            <span>{{param?.posicao}}</span> 
                        </div>
                        <div class="col col-2">
                                <button class="btn btn-success btn-sm" (click)="openEditModal(param._id)">
                                <fa-icon [icon]="['fas', 'edit']" size="1x"></fa-icon>
                            </button>&nbsp;
                            <button class="btn btn-danger btn-sm" (click)="deleteParametro(param?._id)" [disabled]="param.tipo == 'fixo' || (param.titulo == 'Mestre de Cerimônias' || param.titulo== 'Gerador de Energia' || param.titulo == 'Taxa de Eletricidade' || param.titulo == 'Taxa de Água' || param.titulo == 'Coordenador de Eventos')">
                                <fa-icon [icon]="['fas', 'trash']" size="1x"></fa-icon>
                            </button>
                        </div>
                    </div>
        <div class="filtroContainer">
            <div class="col col-12" *ngIf="param.tipo == 'checkbox' || param.tipo == 'fixo'">
                <label>Valores</label>
                    <div class="row" *ngFor="let opt of param.valores | sort:'cidade'">
                        <div class="col">
                            <span>{{opt?.valor | currency:'BRL'}}</span>
                        </div>            
                        <div class="col">
                            <span>{{opt?.cidade}}</span>
                        </div>                      
                    </div>
                    <div class="row">
                        <div class="col col-12">
                            <label>Descrição:</label><br>
                            <span>{{param?.descricao}}</span>
                        </div>
                    </div>  
            </div>
            <div class="col col-12" *ngIf="param.tipo == 'selecionavel'">
                <label>Opções</label>
                    <div *ngFor="let opt of param.opcoes | sort:'cidade'">
                        <div class="row">
                            <div class="col">
                                <span>{{opt?.nome}}</span>
                            </div>
                            <div class="col">
                                <span>{{opt?.valor | currency:'BRL'}}</span>
                            </div>     
                            <div class="col">
                                <span>{{opt?.cidade}}</span>
                            </div>    
                        </div>     
                        <div class="row">
                            <div class="col">
                                <label>Descrição:</label>&nbsp;
                                {{opt?.descricao}}
                            </div>
                        </div>   
                        <br>                    
                    </div>
            </div>
        </div>
        <hr>
    </div>
<!-- BOTAO ADD NOVO PARAMETRO -->
<div style="position: fixed; bottom:0; width: 100%; z-index:1">  
    <button class="btn btn-success btn-lg" style="width: 100%" (click)="openModal()">
        <fa-icon [icon]="['fas', 'plus-square']" size="1x"></fa-icon>
        NOVO PARAMETRO
    </button>
</div> 
    </ng-template>
    </mat-tab>
        <mat-tab label="Colação de Grau">
            <ng-template matTabContent>
            <h4 style="text-align: center; color:blueviolet; margin:1em">Colação de Grau</h4>
            <div *ngFor="let param of parametros; let odd = odd" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="width:95%">
                <div class="filtroContainer">
                    <div class="col col-3">
                        <label>Titulo</label><br>
                        <span>{{param.titulo}}</span> 
                    </div>
                    <div class="col col-2">
                        <label>Tipo</label><br>
                            <span *ngIf="param.tipo == 'checkbox'">Checkbox</span>
                            <span *ngIf="param.tipo == 'selecionavel'">Selecionavel</span>
                    </div>
                    <div class="col col-2">
                        <div class="full-width">
                            <label>Formando</label><br>
                            <span *ngIf="param.formando == true">Sim</span>
                            <span *ngIf="param.formando == false">Não</span>
                        </div>
                    </div>
                    <div class="col col-2" *ngIf="param.tipo != 'selecionavel'">
                        <label>Marcado</label><br>
                            <span *ngIf="param.marcado == true">Sim</span>
                            <span *ngIf="param.marcado == false">Não</span>
                    </div>              
                    <div class="col col-1" style="text-align: center;">
                        <label>Posição</label><br>
                        <span>{{param?.posicao}}</span> 
                    </div>         
                        <div class="col col-2">
                                <button class="btn btn-success btn-sm" (click)="openEditModal(param._id)">
                                <fa-icon [icon]="['fas', 'edit']" size="1x"></fa-icon>
                            </button>&nbsp;
                            <button class="btn btn-danger btn-sm" (click)="deleteParametro(param?._id)" [disabled]="param.tipo == 'fixo' || (param.titulo == 'Mestre de Cerimônias' || param.titulo== 'Gerador de Energia' || param.titulo == 'Taxa de Eletricidade' || param.titulo == 'Taxa de Água' || param.titulo == 'Coordenador de Eventos')">
                                <fa-icon [icon]="['fas', 'trash']" size="1x"></fa-icon>
                            </button>
                        </div>
                    </div>
        <div class="filtroContainer">
                <div class="col col-12" *ngIf="param.tipo == 'checkbox'">
                        <label>Valores</label>
                            <div class="row" *ngFor="let opt of param.valores | sort:'cidade'">
                                <div class="col">
                                    <span>{{opt?.valor | currency:'BRL'}}</span>
                                </div>            
                                <div class="col">
                                    <span>{{opt?.cidade}}</span>
                                </div>                        
                            </div>
                            <div class="row">
                                <div class="col col-12">
                                    <label>Descrição:</label><br>
                                    <span>{{param?.descricao}}</span>
                                </div>
                            </div>  
                    </div>
            <div class="col col-12" *ngIf="param.tipo == 'selecionavel'">
                <label>Opções</label>
                    <div *ngFor="let opt of param.opcoes | sort:'cidade'">
                        <div class="row">
                            <div class="col">
                                <span>{{opt?.nome}}</span>
                            </div>
                            <div class="col">
                                <span>{{opt?.valor | currency:'BRL'}}</span>
                            </div>     
                            <div class="col">
                                <span>{{opt?.cidade}}</span>
                            </div>    
                        </div>     
                        <div class="row">
                            <div class="col">
                                <label>Descrição:</label>&nbsp;
                                {{opt?.descricao}}
                            </div>
                        </div>   
                        <br>                    
                    </div>
            </div>
        </div>
        <hr>
    </div>
<!-- BOTAO ADD NOVO PARAMETRO -->
<div style="position: fixed; bottom:0; width: 100%; z-index:1">  
    <button class="btn btn-success btn-lg" style="width: 100%" (click)="openModal()">
        <fa-icon [icon]="['fas', 'plus-square']" size="1x"></fa-icon>
        NOVO PARAMETRO
    </button>
</div> 
    </ng-template>
    </mat-tab>
        <mat-tab label="Jantar Dançante">
            <ng-template matTabContent>
            <h4 style="text-align: center; color:blueviolet; margin:1em">Jantar Dançante</h4>
            <div *ngFor="let param of parametros; let odd = odd" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="width:95%">
                <div class="filtroContainer">
                    <div class="col col-3">
                        <label>Titulo</label><br>
                        <span>{{param.titulo}}</span> 
                    </div>
                    <div class="col col-2">
                        <label>Tipo</label><br>
                            <span *ngIf="param.tipo == 'checkbox'">Checkbox</span>
                            <span *ngIf="param.tipo == 'selecionavel'">Selecionavel</span>
                            <span *ngIf="param.tipo == 'fixo'">Fixo</span>
                    </div>
                    <div class="col col-2">
                        <div class="full-width">
                            <label>Formando</label><br>
                            <span *ngIf="param.formando == true">Sim</span>
                            <span *ngIf="param.formando == false">Não</span>
                        </div>
                    </div>
                    <div class="col col-2" *ngIf="param.tipo != 'selecionavel'">
                        <label>Marcado</label><br>
                            <span *ngIf="param.marcado == true">Sim</span>
                            <span *ngIf="param.marcado == false">Não</span>
                    </div>         
                    <div class="col col-1" style="text-align: center;">
                        <label>Posição</label><br>
                        <span>{{param?.posicao}}</span> 
                    </div>              
                        <div class="col col-2">
                                <button class="btn btn-success btn-sm" (click)="openEditModal(param._id)">
                                <fa-icon [icon]="['fas', 'edit']" size="1x"></fa-icon>
                            </button>&nbsp;
                            <button class="btn btn-danger btn-sm" (click)="deleteParametro(param?._id)" [disabled]="param.tipo == 'fixo' || (param.titulo == 'Mestre de Cerimônias' || param.titulo== 'Gerador de Energia' || param.titulo == 'Taxa de Eletricidade' || param.titulo == 'Taxa de Água' || param.titulo == 'Coordenador de Eventos')">
                                <fa-icon [icon]="['fas', 'trash']" size="1x"></fa-icon>
                            </button>
                        </div>
                    </div>
        <div class="filtroContainer">
                <div class="col col-12" *ngIf="param.tipo == 'checkbox' || param.tipo == 'fixo'">
                        <label>Valores</label>
                            <div class="row" *ngFor="let opt of param.valores | sort:'cidade'">
                                <div class="col">
                                    <span>{{opt?.valor | currency:'BRL'}}</span>
                                </div>            
                                <div class="col">
                                    <span>{{opt?.cidade}}</span>
                                </div>                        
                            </div>
                            <div class="row">
                                <div class="col col-12">
                                    <label>Descrição:</label><br>
                                    <span>{{param?.descricao}}</span>
                                </div>
                            </div>  
                    </div>
            <div class="col col-12" *ngIf="param.tipo == 'selecionavel'">
                <label>Opções</label>
                    <div *ngFor="let opt of param.opcoes | sort:'cidade'">
                        <div class="row">
                            <div class="col">
                                <span>{{opt?.nome}}</span>
                            </div>
                            <div class="col">
                                <span>{{opt?.valor | currency:'BRL'}}</span>
                            </div>     
                            <div class="col">
                                <span>{{opt?.cidade}}</span>
                            </div>    
                        </div>     
                        <div class="row">
                            <div class="col">
                                <label>Descrição:</label>&nbsp;
                                {{opt?.descricao}}
                            </div>
                        </div>   
                        <br>                    
                    </div>
            </div>
        </div>
        <hr>
    </div>
<!-- BOTAO ADD NOVO PARAMETRO -->
<div style="position: fixed; bottom:0; width: 100%; z-index:1">  
    <button class="btn btn-success btn-lg" style="width: 100%" (click)="openModal()">
        <fa-icon [icon]="['fas', 'plus-square']" size="1x"></fa-icon>
        NOVO PARAMETRO
    </button>
</div> 
    </ng-template>
    </mat-tab>
        <mat-tab label="Baile de Gala">
            <ng-template matTabContent>
            <h4 style="text-align: center; color:blueviolet; margin:1em">Baile de Gala</h4>
            <div *ngFor="let param of parametros; let odd = odd" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="width:95%">
                <div class="filtroContainer">
                    <div class="col col-3">
                        <label>Titulo</label><br>
                        <span>{{param?.titulo}}</span> 
                    </div>
                    <div class="col col-2">
                        <label>Tipo</label><br>
                            <span *ngIf="param.tipo == 'checkbox'">Checkbox</span>
                            <span *ngIf="param.tipo == 'selecionavel'">Selecionavel</span>
                            <span *ngIf="param.tipo == 'fixo'">Fixo</span>
                    </div>
                    <div class="col col-2">
                        <div class="full-width">
                            <label>Formando</label><br>
                            <span *ngIf="param.formando == true">Sim</span>
                            <span *ngIf="param.formando == false">Não</span>
                        </div>
                    </div>
                    <div class="col col-2" *ngIf="param.tipo != 'selecionavel'">
                        <label>Marcado</label><br>
                            <span *ngIf="param.marcado == true">Sim</span>
                            <span *ngIf="param.marcado == false">Não</span>
                    </div>        
                    <div class="col col-1" style="text-align: center;">
                        <label>Posição</label><br>
                        <span>{{param?.posicao}}</span> 
                    </div>           
                    <div class="col col-2">
                            <button class="btn btn-success btn-sm" (click)="openEditModal(param._id)">
                            <fa-icon [icon]="['fas', 'edit']" size="1x"></fa-icon>
                        </button>&nbsp;
                        <button class="btn btn-danger btn-sm" (click)="deleteParametro(param?._id)" [disabled]="param.tipo == 'fixo' || (param.titulo == 'Mestre de Cerimônias' || param.titulo== 'Gerador de Energia' || param.titulo == 'Taxa de Eletricidade' || param.titulo == 'Taxa de Água' || param.titulo == 'Coordenador de Eventos')">
                            <fa-icon [icon]="['fas', 'trash']" size="1x"></fa-icon>
                        </button>
                    </div>
                </div>
        <div class="filtroContainer">
                <div class="col col-12" *ngIf="param.tipo == 'checkbox'">
                        <label>Valores</label>
                            <div class="row" *ngFor="let opt of param.valores | sort:'cidade'">
                                <div class="col">
                                    <span>{{opt?.valor | currency:'BRL'}}</span>
                                </div>            
                                <div class="col">
                                    <span>{{opt?.cidade}}</span>
                                </div>                        
                            </div>
                            <div class="row">
                                <div class="col col-12">
                                    <label>Descrição:</label><br>
                                    <span>{{param?.descricao}}</span>
                                </div>
                            </div>  
                    </div>
            <div class="col col-12" *ngIf="param.tipo == 'selecionavel' || param.tipo == 'fixo'">
                <label>Opções</label>
                <div *ngFor="let opt of param.opcoes | sort:'cidade'">
                    <div class="row">
                        <div class="col">
                            <span>{{opt?.nome}}</span>
                        </div>
                        <div class="col">
                            <span>{{opt?.valor | currency:'BRL'}}</span>
                        </div>     
                        <div class="col">
                            <span>{{opt?.cidade}}</span>
                        </div>    
                    </div>     
                    <div class="row">
                        <div class="col">
                            <label>Descrição:</label>&nbsp;
                            {{opt?.descricao}}
                        </div>
                    </div>   
                    <br>                    
                </div>
            </div>
        </div>
        <hr>
    </div>
<!-- BOTAO ADD NOVO PARAMETRO -->
<div style="position: fixed; bottom:0; width: 100%; z-index:1">  
    <button class="btn btn-success btn-lg" style="width: 100%" (click)="openModal()">
        <fa-icon [icon]="['fas', 'plus-square']" size="1x"></fa-icon>
        NOVO PARAMETRO
    </button>
</div> 
    </ng-template>
    </mat-tab>
    <mat-tab label="Fotos">
        <ng-template matTabContent>
        <h4 style="text-align: center; color:blueviolet; margin:1em">Fotos</h4>
        <table class="table table-striped">
            <tr style="text-align: center;">
                <th>Titulo</th>
                <th>Descrição</th>
                <th>Valor</th>
                <th>Cobra</th>
                <th>Posição</th>
                <th></th>    
                <th></th>
            </tr>
            <tr *ngFor="let foto of fotos" style="text-align: center;">
                <td>{{ foto?.nome }}</td>
                <td>{{ foto?.descricao }}</td>
                <td>{{ foto?.valor | currency:'BRL' }}</td>
                <td>
                    <span *ngIf="foto?.cobra == true">Sim</span>
                    <span *ngIf="foto?.cobra == false">Não</span>
                </td>
                <td>{{ foto?.posicao }}</td>
                <td>
                    <button class="btn btn-success" (click)="openModalFoto(foto._id)">
                        <fa-icon [icon]="['fas', 'edit']" size="1x"></fa-icon>
                    </button>
                </td>
                <td>
                    <button class="btn btn-danger" (click)="deleteItemFoto(foto._id)">
                        <fa-icon [icon]="['fas', 'trash']" size="1x"></fa-icon>
                    </button>
                </td>
            </tr>
        </table>
<div style="position: fixed; bottom:0; width: 100%; z-index:1">  
    <button class="btn btn-info btn-lg" style="width: 100%" (click)="openModalNovoFoto()">
        <fa-icon [icon]="['fas', 'plus-square']" size="1x"></fa-icon>
        NOVO ITEM FOTOS
    </button>
</div>
</ng-template>
</mat-tab>
    <mat-tab label="Locais de Evento">
        <ng-template matTabContent>
        <h4 style="text-align: center; color:blueviolet; margin:1em">Locais de Evento</h4>
        <table class="table table-striped">
            <tr>
                <th>Local</th>
                <th>Descrição</th>
                <th>Valor</th>
                <th>Reajuste (%)</th>
                <th>Hora Extra (%)</th>
                <th>Cidade</th>
                <th></th>    
                <th></th>
            </tr>
            <tr *ngFor="let local of localEvento" style="text-align: center;">
                <td>{{ local?.nome }}</td>
                <td>{{ local?.descricao }}</td>
                <td>{{ local?.valor | currency:'BRL' }}</td>
                <td>{{ local?.reajusteAnual }}</td>
                <td>{{ local?.horaExtra }}</td>
                <td>{{ local?.cidade }}</td>
                <td>
                    <button class="btn btn-success" (click)="openModalEvento(local._id)">
                        <fa-icon [icon]="['fas', 'edit']" size="1x"></fa-icon>
                    </button>
                </td>
                <td>
                    <button class="btn btn-danger" (click)="deleteLocalEvento(local._id)">
                        <fa-icon [icon]="['fas', 'trash']" size="1x"></fa-icon>
                    </button>
                </td>
            </tr>
        </table>
<div style="position: fixed; bottom:0; width: 100%; z-index:1">  
    <button class="btn btn-info btn-lg" style="width: 100%" (click)="openModalNovoEvento()">
        <fa-icon [icon]="['fas', 'plus-square']" size="1x"></fa-icon>
        NOVO LOCAL DE EVENTO
    </button>
</div>
</ng-template>
</mat-tab>
<mat-tab label="Outros">
    <ng-template matTabContent>
        <h4 style="text-align: center; color:blueviolet; padding:1em">Outros</h4>
        <div class="filtroContainer">
            <div class="col col-3">
                <label>Titulo</label><br>
                <span>{{assessoria?.titulo}}</span>
            </div>
            <div class="col col-8">
                <label>Descrição</label><br>
                <span>{{assessoria?.descricao}}</span>
            </div>
            <div class="col col-1">
                <label>Editar</label><br>
                <button class="btn btn-success" (click)="openEditModal(assessoria?._id)">
                    <fa-icon [icon]="['fas', 'edit']" size="1x"></fa-icon>
                </button>  
            </div>
        </div>
        <hr>
        <div class="filtroContainer">
            <div class="col col-12">
            <label>Opções</label>
                <div class="row" *ngFor="let opt of (assessoria?.opcoes | sort:'cidade'); let odd = odd" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'">
                    <div class="col">
                        <span>{{opt?.nome}}</span>
                    </div>
                    <div class="col">
                        <span>{{opt?.valor}}%</span>
                    </div>  
                    <div class="col">
                        <span>{{opt?.cidade}}</span>
                    </div>                                  
                </div>
            </div>
        </div>
        <hr>
        <div class="filtroContainer">
                <div class="col col-3">
                    <label>Nome</label><br>
                    <span>Nº Proposta</span> 
                </div>
                <div class="col col-2">
                    <label>Sequência das Propostas</label><br>
                    <input type="text" class="form-control" style="text-align: center;" [(ngModel)]="proposta.valor">
                </div>
                <div class="col col-6">
                    <label>Descrição</label><br>
                   <span>Esta sequência se refere ao número exibido nos orçamentos!</span>
                </div>
                <div class="col col-1">
                    <label>Salvar</label><br>
                    <button class="btn btn-success" (click)="editarNProposta(proposta.valor)">
                        <fa-icon [icon]="['fas', 'save']" size="1x"></fa-icon>
                    </button>                               
                </div>
            </div>    
            <hr>
            <div class="filtroContainer">
                    <div class="col col-3">
                        <label>Propostas Web</label><br>
                        <span>Nº Receber SMS</span> 
                    </div>
                    <div class="col col-2">
                        <label>Celular</label><br>
                        <input type="text" class="form-control" style="text-align: center;" [(ngModel)]="sms.valor">
                    </div>
                    <div class="col col-1">
                        <label>Salvar</label><br>
                        <button class="btn btn-success" (click)="editarNrSMS(sms.valor)">
                            <fa-icon [icon]="['fas', 'save']" size="1x"></fa-icon>
                        </button>                               
                    </div>
                    <div class="col col-6"></div>
                </div>   
        </ng-template>
    </mat-tab>
    <mat-tab label="Personalizados Baile">
        <ng-template matTabContent>
            <div style="padding:15px">
                <label>Finger Foods</label>
                <div class="row" style="align-items: center;">
                    <div class="col col-2">
                        <label>Valor</label>
                        <input type="number" class="form-control" [(ngModel)]="valorPersonalizadosFinger">
                    </div>
                    <div class="col col-10">
                        <label>Descrição</label>
                        <textarea class="form-control" [(ngModel)]="descricaoPersonalizadosFinger"></textarea>
                    </div>
                </div>
                <hr>
                <br>
                <label>Bar Temático</label>
                <div class="row" style="align-items: center;">
                    <div class="col col-2">
                        <label>Valor</label>
                        <input type="number" class="form-control" [(ngModel)]="valorPersonalizadosBarTematico">
                    </div>
                    <div class="col col-10">
                        <label>Descrição</label>
                        <textarea class="form-control" [(ngModel)]="descricaoPersonalizadosBarTematico"></textarea>
                    </div>
                </div>
                <hr>
                <br>
                <label>Café da Manhã</label>
                <div class="row" style="align-items: center;">
                    <div class="col col-2">
                        <label>Valor</label>
                        <input type="number" class="form-control" [(ngModel)]="valorPersonalizadosCafeManha">
                    </div>
                    <div class="col col-10">
                        <label>Descrição</label>
                        <textarea class="form-control" [(ngModel)]="descricaoPersonalizadosCafeManha"></textarea>
                    </div>
                </div>
                <hr>
                <br>
                <label>Carrinho de Chopp</label>
                <div class="row" style="align-items: center;">
                    <div class="col col-2">
                        <label>Valor</label>
                        <input type="number" class="form-control" [(ngModel)]="valorPersonalizadosCarrinhoChopp">
                    </div>
                    <div class="col col-10">
                        <label>Descrição</label>
                        <textarea class="form-control" [(ngModel)]="descricaoPersonalizadosCarrinhoChopp"></textarea>
                    </div>
                </div>
                <hr>
                <br>
                <label>Bebidas para After</label>
                <div class="row" style="align-items: center;">
                    <div class="col col-2">
                        <label>Valor</label>
                        <input type="number" class="form-control" [(ngModel)]="valorPersonalizadosBebidasAfter">
                    </div>
                    <div class="col col-10">
                        <label>Descrição</label>
                        <textarea class="form-control" [(ngModel)]="descricaoPersonalizadosBebidasAfter"></textarea>
                    </div>
                </div>
                <hr>
                <br>
                <div class="row no-gutters" style="text-align: center">
                    <div class="col">
                        <button class="btn btn-info" (click)="salvarPersonalizadosBaile()" [disabled]="!descricaoPersonalizadosFinger || !descricaoPersonalizadosBarTematico || !descricaoPersonalizadosCafeManha || !descricaoPersonalizadosCarrinhoChopp || !descricaoPersonalizadosBebidasAfter || !valorPersonalizadosFinger || !valorPersonalizadosBarTematico || !valorPersonalizadosCafeManha || !valorPersonalizadosCarrinhoChopp || !valorPersonalizadosBebidasAfter">SALVAR</button>
                    </div>
                </div>
            </div>
        </ng-template>
    </mat-tab>
</mat-tab-group>
<div style="height: 60px"></div>
<!-- MODAL ADD PARAMETRO -->
<div id="myModalParamsCol" class="modal">
    <span (click)="closeModal()" class="close">&times;</span>
    <div style="height: 100px"></div>
    <div class="filtroContainer">
        <div class="cadastroOption">
        <form [formGroup]="parametro" (ngSubmit)="novoParametro(
            parametro.get('sessao')?.value,
            parametro.get('titulo')?.value,
            parametro.get('valores')?.value,
            parametro.get('tipo')?.value,
            parametro.get('descricao')?.value,
            parametro.get('opcoes')?.value,
            parametro.get('formando')?.value,
            parametro.get('marcado')?.value,
            parametro.get('posicao')?.value)">
            <h4>ADICIONAR NOVO PARAMETRO</h4> 
            <div class="row">
                <div class="col col-2">
                    <label>Sessão</label>
                    <select class="form-control" formControlName="sessao">
                        <option value="saudade">Aula da Saudade</option>
                        <option value="missa">Missa</option>
                        <option value="colacao">Colação de Grau</option>
                        <option value="jantar">Jantar</option>
                        <option value="baile">Baile de Gala</option>
                    </select>
                </div>
                <div class="col col-2">
                    <label>Tipo</label>
                    <select class="form-control" formControlName="tipo">
                        <option value="checkbox">CheckBox</option>
                        <option value="selecionavel">Selecionavel</option>
                    </select>
                </div>
                <div class="col col-3">
                    <label>Titulo</label>
                    <input type="text" class="form-control" formControlName="titulo">
                </div>  
                <div class="col col-2">
                    <label>Por Formando?</label>
                    <select class="form-control" formControlName="formando">
                        <option [ngValue]=true>Sim</option>
                        <option [ngValue]=false>Não</option>
                    </select>
                </div>
                <div class="col col-1" *ngIf="parametro.get('tipo')?.value != 'selecionavel'">
                    <label>Marcado?</label>
                    <select class="form-control" formControlName="marcado">
                        <option [ngValue]=true>Sim</option>
                        <option [ngValue]=false>Não</option>
                    </select>
                </div>
                <div class="col col-1">
                    <label>Posicao</label>
                    <input type="number" class="form-control" formControlName="posicao">
                </div>
            </div>
            <div class="row" *ngIf="parametro.get('tipo')?.value == 'checkbox'">
                <div class="col col-12">
                    <label>Descrição</label>
                    <textarea class="form-control" formControlName="descricao"></textarea>
                </div>
            </div>
            <div class="card-body" formArrayName="valores" *ngIf="parametro.get('tipo')?.value == 'checkbox'">
                    <div class="row">
                      <div class="col-12" *ngFor="let contact of valoresFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                          <div class="form-group col-3">
                            <label>Valor</label>
                            <input type="number" class="form-control" formControlName="valor">
                          </div>
                          <div class="form-group col-4">
                            <label>Cidade</label>
                                <select class="form-control" formControlName="cidade">
                                    <option *ngFor="let cidade of listaCidades" [value]="cidade?.nome">
                                    {{cidade?.nome}}
                                    </option>
                                </select>
                            </div>
                          <div class="form-group col-2 text-right" style="padding-top:20px">
                            <button class="btn btn-danger" type="button" (click)="removeOpcaoCheckbox(i)">
                              <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                            </button>&nbsp;
                            <button class="btn btn-success" type="button" (click)="addOpcaoCheckbox()">
                              <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-success" type="button" (click)="addOpcaoCheckbox()">Criar Valor</button>
                </div>
            </div>
            <div class="card-body" formArrayName="opcoes" *ngIf="parametro.get('tipo')?.value == 'selecionavel'">
                    <div class="row">
                      <div class="col col-12" *ngFor="let contact of opcoesFormGroup.controls; let i = index;" [formGroupName]="i">
                        <div class="row">
                          <div class="col col-4">
                            <label>Nome</label>
                            <input type="text" class="form-control" formControlName="nome">
                          </div>
                          <div class="col col-3">
                            <label>Valor</label>
                            <input type="number" class="form-control" formControlName="valor">
                          </div>
                          <div class="col col-3">
                                <label>Cidade</label>
                                <select class="form-control" formControlName="cidade">
                                    <option *ngFor="let cidade of listaCidades" [value]="cidade?.nome">
                                    {{cidade?.nome}}
                                    </option>
                                </select>
                            </div>
                          <div class="col col-2 text-right" style="padding-top: 20px;">
                            <button class="btn btn-danger" type="button" (click)="removeOpcao(i)">
                              <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                            </button>&nbsp;
                            <button class="btn btn-success" type="button" (click)="addOpcao()">
                              <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                            </button>
                          </div>
                        </div>
                        <div class="row">
                            <div class="col col-12">
                                <label>Descrição</label>
                                <textarea class="form-control" formControlName="descricao"></textarea>
                            </div>
                        </div>
                        <hr>
                      </div>
                      <button class="btn btn-success" type="button" (click)="addOpcao()">Criar Opção</button>
                </div>
            </div>
            <br>
        <div style="text-align: center"> 
            <button type="button" class="btn btn-danger" (click)="closeModal()">CANCELAR</button>
            &nbsp;&nbsp;
            <button type="submit" class="btn btn-info" *ngIf="parametro.get('tipo')?.value == 'checkbox'" [disabled]="parametro.get('sessao')?.invalid || parametro.get('tipo')?.invalid || parametro.get('titulo')?.invalid || parametro.get('valores')?.invalid">ADICIONAR CHECKBOX</button>
            <button type="submit" class="btn btn-info" *ngIf="parametro.get('tipo')?.value == 'selecionavel'" [disabled]="parametro.get('sessao')?.invalid || parametro.get('tipo')?.invalid || parametro.get('titulo')?.invalid || parametro.get('opcoes')?.invalid">ADICIONAR SELECIONAVEL</button>
        </div> 
    </form>
    </div>
</div>
</div>

<!-- EDITAR PARAMETRO -->
<div id="myEditModalCol" class="modal">
        <span (click)="closeEditModal()" class="close">&times;</span>
        <div style="height: 100px"></div>
        <div class="filtroContainer">
            <div class="cadastroOption">
            <form [formGroup]="editarParametro" (ngSubmit)="editParametro(
                editarParametro.get('tipo')?.value,
                editarParametro.get('titulo')?.value,
                editarParametro.get('valoresEditar')?.value,
                editarParametro.get('descricao')?.value,
                editarParametro.get('opcoesEditar')?.value,
                editarParametro.get('formando')?.value,
                editarParametro.get('marcado')?.value,
                editarParametro.get('posicao')?.value)">
                <h4>EDITAR PARAMETRO</h4> 
                <div class="row">
                    <div class="col col-4" *ngIf="editarParametro.get('tipo')?.value != 'fixo'">
                        <label>Titulo</label>
                        <input type="text" class="form-control" formControlName="titulo" [style.pointer-events]="(editarParametro.get('titulo')?.value == 'Mestre de Cerimônias' || editarParametro.get('titulo')?.value == 'Gerador de Energia' || editarParametro.get('titulo')?.value == 'Taxa de Eletricidade' || editarParametro.get('titulo')?.value == 'Taxa de Água' || editarParametro.get('titulo')?.value == 'Coordenador de Eventos') ? 'none' : 'all'">
                    </div>
                    <div class="col col-3" *ngIf="editarParametro.get('tipo')?.value == 'fixo'">
                        <label>Titulo</label>
                        <input type="text" class="form-control" formControlName="titulo" readonly>
                    </div>
                    <div class="col col-3" *ngIf="editarParametro.get('tipo')?.value != 'fixo'">
                        <label>Tipo</label>
                        <select class="form-control" formControlName="tipo">
                            <option value="checkbox">CheckBox</option>
                            <option value="selecionavel">Selecionavel</option>
                        </select>
                    </div>
                    <div class="col col-9" *ngIf="editarParametro.get('tipo')?.value == 'fixo'">
                        <label>Descrição</label>
                        <textarea class="form-control" formControlName="descricao"></textarea>
                    </div>
                    <div class="col col-2" *ngIf="editarParametro.get('tipo')?.value != 'fixo'">
                        <label>Por Formando?</label>
                        <select class="form-control" formControlName="formando">
                            <option [ngValue]=true>Sim</option>
                            <option [ngValue]=false>Não</option>
                        </select>
                    </div>
                    <div class="col col-2" *ngIf="editarParametro.get('tipo')?.value != 'selecionavel'">
                        <label>Marcado?</label>
                        <select class="form-control" formControlName="marcado" [style.pointer-events]="(editarParametro.get('titulo')?.value == 'Mestre de Cerimônias' || editarParametro.get('titulo')?.value == 'Gerador de Energia' || editarParametro.get('titulo')?.value == 'Taxa de Eletricidade' || editarParametro.get('titulo')?.value == 'Taxa de Água' || editarParametro.get('titulo')?.value == 'Coordenador de Eventos') ? 'none' : 'all'">
                            <option [ngValue]=true>Sim</option>
                            <option [ngValue]=false>Não</option>
                        </select>
                    </div>
                    <div class="col col-1">
                        <label>Posição</label>
                        <input type="number" class="form-control" formControlName="posicao">
                    </div>
                </div>
                <div class="row" *ngIf="editarParametro.get('tipo')?.value == 'checkbox'">
                    <div class="col col-12">
                        <label>Descrição</label>
                        <textarea class="form-control" formControlName="descricao"></textarea>
                    </div>
                </div>
                <div class="row" style="color:red;font-weight: bold;" *ngIf="editarParametro.get('titulo')?.value == 'Mestre de Cerimônias' || editarParametro.get('titulo')?.value == 'Gerador de Energia' || editarParametro.get('titulo')?.value == 'Taxa de Eletricidade' || editarParametro.get('titulo')?.value == 'Taxa de Água' || editarParametro.get('titulo')?.value == 'Coordenador de Eventos'">
                    <div class="col">
                        O TITULO E A OPÇÃO MARCADO (SIM/NÃO) NÃO PODEM SER EDITADOS PARA NÃO COMPROMETER O CÓDIGO FINAL!
                    </div>
                </div>
                <div class="card-body" formArrayName="valoresEditar" *ngIf="editarParametro.get('tipo')?.value == 'checkbox'">
                    <label>Opções</label>
                    <div class="row">
                        <div class="col-12" *ngFor="let contact of valoresEditarFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="col col-3">
                                <label>Valor</label>
                                <input type="number" class="form-control" formControlName="valor">
                            </div>
                            <div class="col col-4">
                                <label>Cidade</label>
                                <select class="form-control" formControlName="cidade">
                                    <option *ngFor="let cidade of listaCidades" [value]="cidade?.nome">
                                    {{cidade?.nome}}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col-2 text-right" style="padding-top:25px;">
                            <button class="btn btn-danger" type="button" (click)="removeOpcaoCheckboxEditar(i)">
                                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                            </button>&nbsp;
                            <button class="btn btn-success" type="button" (click)="addOpcaoCheckboxEditar()">
                                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                            </button>
                            </div>
                        </div>
                    </div>
                        <div style="padding:15px">
                            <button class="btn btn-success" type="button" (click)="addOpcaoCheckboxEditar()">Criar Valor</button>
                        </div>
                    </div>
                </div>
                <div class="card-body" formArrayName="opcoesEditar" *ngIf="editarParametro.get('tipo')?.value == 'selecionavel'">
                        <div class="row">
                          <div class="col-12" *ngFor="let contact of opcoesEditarFormGroup.controls; let i = index;">
                            <div [formGroupName]="i">
                                <div class="row">
                                    <div class="col col-4">
                                        <label>Nome</label>
                                        <input type="text" class="form-control" formControlName="nome">
                                    </div>
                                    <div class="col col-3">
                                        <label>Valor</label>
                                        <input type="number" class="form-control" formControlName="valor">
                                    </div>
                                    <div class="col col-3">
                                        <label>Cidade</label>
                                        <select class="form-control" formControlName="cidade">
                                            <option *ngFor="let cidade of listaCidades" [value]="cidade?.nome">
                                            {{cidade?.nome}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col col-2 text-right" style="padding-top:20px">
                                        <button class="btn btn-danger" type="button" (click)="removeOpcaoEditar(i)">
                                        <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                                        </button>&nbsp;
                                        <button class="btn btn-success" type="button" (click)="addOpcaoEditar()">
                                        <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col col-12">
                                        <label>Descrição</label>
                                        <textarea class="form-control" formControlName="descricao"></textarea>
                                    </div>
                                </div>
                              <hr>
                            </div>
                          </div>
                          <button class="btn btn-success" type="button" (click)="addOpcaoEditar()">Criar Opção</button>
                    </div>
                </div>
                <div class="card-body" formArrayName="valoresEditar" *ngIf="editarParametro.get('tipo')?.value == 'fixo'">
                    <div class="row">
                      <div class="col-12" *ngFor="let contact of valoresEditarFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                          <div class="form-group col-3">
                            <label>Valor</label>
                            <input type="number" class="form-control" formControlName="valor">
                          </div>
                          <div class="form-group col-4">
                            <label>Cidade</label>
                            <select class="form-control" formControlName="cidade">
                                <option *ngFor="let cidade of listaCidades" [value]="cidade?.nome">
                                {{cidade?.nome}}
                                </option>
                            </select>
                        </div>
                          <div class="form-group col-2 text-right">
                            <button class="btn btn-danger" type="button" (click)="removeOpcaoCheckboxEditar(i)">
                                <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                            </button>&nbsp;
                            <button class="btn btn-success" type="button" (click)="addOpcaoCheckboxEditar()">
                                <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                      <button class="btn btn-success" type="button" (click)="addOpcaoCheckboxEditar()">Criar Opção</button>
                </div>
            </div>
            <div style="text-align: center">
                <button type="button" class="btn btn-danger" (click)="closeEditModal()">CANCELAR</button>
                &nbsp;&nbsp;
                <button type="submit" class="btn btn-info" *ngIf="editarParametro.get('tipo')?.value == 'checkbox'" [disabled]="editarParametro.get('tipo')?.invalid || editarParametro.get('titulo')?.invalid || editarParametro.get('valoresEditar')?.invalid">SALVAR</button>
                <button type="submit" class="btn btn-info" *ngIf="editarParametro.get('tipo')?.value == 'selecionavel'" [disabled]="editarParametro.get('tipo')?.invalid || editarParametro.get('titulo')?.invalid || editarParametro.get('opcoesEditar')?.invalid">SALVAR</button>
                <button type="submit" class="btn btn-info" *ngIf="editarParametro.get('tipo')?.value == 'fixo'" [disabled]="editarParametro.get('valoresEditar')?.invalid">SALVAR</button>
            </div> 
        </form>
        </div>
    </div>
</div>
<!-- FIM EDITAR PARAMETRO -->
<!-- EDITAR FOTO -->
<div id="myEditFotoCol" class="modal">
    <span (click)="closeModalFotos()" class="close">&times;</span>
    <div style="height: 100px"></div>
    <div class="filtroContainer">
        <div class="cadastroOption">
                <h4>EDITAR ITEM DE FOTOS</h4> 
            <div class="row">
                <div class="col col-3">
                    <label>Nome</label>
                    <input type="text" class="form-control" [(ngModel)]="fotoNomeEditar">
                </div>
                <div class="col col-4">
                    <label>Descrição</label>
                    <textarea class="form-control" [(ngModel)]="fotoDescricaoEditar"></textarea>
                </div>
                <div class="col col-2">
                    <label>Valor</label>
                    <input type="number" class="form-control" [(ngModel)]="fotoValorEditar">
                </div>
                <div class="col col-2">
                    <label>Cobra</label>
                    <select class="form-control" [(ngModel)]="fotoCobraEditar">
                        <option [ngValue]=true>Sim</option>
                        <option [ngValue]=false>Não</option>
                    </select>
                </div>
                 <div class="col col-1">
                    <label>Posição</label>
                    <input type="number" class="form-control" [(ngModel)]="posicaoFotoEditar">
                </div>
            </div>
        </div>
    </div>
    <div style="text-align: center">
        <button type="button" class="btn btn-danger" (click)="closeModalFotos()">CANCELAR</button>
        &nbsp;&nbsp;
        <button type="submit" class="btn btn-info" (click)="editFotos(fotoNomeEditar,fotoValorEditar,fotoDescricaoEditar,posicaoFotoEditar,fotoCobraEditar)" [disabled]="!fotoNomeEditar ||  !fotoValorEditar || !posicaoFotoEditar">EDITAR</button>
    </div> 
</div>
<!-- FIM EDITAR FOTO -->

<!-- EDITAR LOCAL EVENTO -->
<div id="myEditEventoCol" class="modal">
    <span (click)="closeModalEvento()" class="close">&times;</span>
    <div style="height: 100px"></div>
    <div class="filtroContainer">
        <div class="cadastroOption">
                <h4>EDITAR LOCAL DE EVENTO</h4> 
            <div class="row">
                <div class="col col-3">
                    <label>Local</label>
                    <input type="text" class="form-control" [(ngModel)]="localNome">
                </div>
                <div class="col col-6">
                    <label>Descrição</label>
                    <textarea class="form-control" [(ngModel)]="descricaoLocalEditar"></textarea>
                </div>
                <div class="col col-3">
                    <label>Cidade</label>
                    <select class="form-control" [(ngModel)]="localCidade">
                        <option *ngFor="let cidade of listaCidades" [value]="cidade?.nome">{{cidade?.nome}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col col-4">
                    <label>Valor</label>
                    <input type="number" class="form-control" [(ngModel)]="localValor">
                </div>
                <div class="col col-4">
                    <label>Reajuste Anual (%)</label>
                    <input type="number" class="form-control" [(ngModel)]="localReajusteAnual">
                </div>
                <div class="col col-4">
                    <label>Hora Extra (%)</label>
                    <input type="number" class="form-control" [(ngModel)]="localHoraExtra">
                </div>
            </div>
        </div>
    </div>
    <div style="text-align: center">
        <button type="button" class="btn btn-danger" (click)="closeModalEvento()">CANCELAR</button>
        &nbsp;&nbsp;
        <button type="submit" class="btn btn-info" (click)="editLocalEvento(localNome, localCidade, localValor, localReajusteAnual, localHoraExtra,descricaoLocalEditar)" [disabled]="!localNome ||  !localCidade ||  !localValor ||  !localReajusteAnual || !localHoraExtra">EDITAR</button>
    </div> 
</div>
<!-- FIM EDITAR LOCAL EVENTO -->

<!-- NOVO ITEM FOTOS -->
<div id="myNovoFotoCol" class="modal">
    <span (click)="closeModalNovoFoto()" class="close">&times;</span>
    <div style="height: 100px"></div>
    <div class="filtroContainer">
        <div class="cadastroOption">
                <h4>NOVO ITEM DE FOTOS</h4> 
            <div class="row">
                <div class="col col-3">
                    <label>Nome</label>
                    <input type="text" class="form-control" [(ngModel)]="nomeFoto">
                </div>
                <div class="col col-4">
                    <label>Descrição</label>
                    <textarea class="form-control" [(ngModel)]="descricaoFoto"></textarea>
                </div>
                <div class="col col-2">
                    <label>Valor</label>
                    <input type="number" class="form-control" [(ngModel)]="valorFoto">
                </div>
                <div class="col col-2">
                    <label>Cobra</label>
                    <select class="form-control" [(ngModel)]="cobraFoto">
                        <option [ngValue]=true>Sim</option>
                        <option [ngValue]=false>Não</option>
                    </select>
                </div>
                <div class="col col-1">
                    <label>Posição</label>
                    <input type="number" class="form-control" [(ngModel)]="posicaoFoto">
                </div>
            </div>
        </div>
    </div>
    <div style="text-align: center">
        <button type="button" class="btn btn-danger" (click)="closeModalNovoFoto()">CANCELAR</button>
        &nbsp;&nbsp;
        <button type="submit" class="btn btn-info" (click)="novoFoto(nomeFoto,valorFoto,descricaoFoto,posicaoFoto,cobraFoto)" [disabled]="!nomeFoto || !valorFoto || !posicaoFoto">CADASTRAR</button>
    </div> 
</div>
<!-- FIM NOVO ITEM FOTOS -->
<!-- NOVO LOCAL EVENTO -->
<div id="myNovoEventoCol" class="modal">
        <span (click)="closeModalNovoEvento()" class="close">&times;</span>
        <div style="height: 100px"></div>
        <div class="filtroContainer">
            <div class="cadastroOption">
                    <h4>NOVO LOCAL DE EVENTO</h4> 
                <div class="row">
                    <div class="col col-3">
                        <label>Local</label>
                        <input type="text" class="form-control" [(ngModel)]="nomeLocal">
                    </div>
                    <div class="col col-6">
                        <label>Descrição</label>
                        <textarea class="form-control" [(ngModel)]="descricaoLocal"></textarea>
                    </div>
                    <div class="col col-3">
                        <label>Cidade</label>
                            <select class="form-control" [(ngModel)]="cidadeLocal">
                            <option *ngFor="let cidade of listaCidades" [value]="cidade?.nome">{{cidade?.nome}}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-4">
                        <label>Valor</label>
                        <input type="number" class="form-control" [(ngModel)]="valorLocal">
                    </div>
                    <div class="col col-4">
                        <label>Reajuste Anual (%)</label>
                        <input type="number" class="form-control" [(ngModel)]="reajusteAnualLocal">
                    </div>
                    <div class="col col-4">
                        <label>Hora Extra (%)</label>
                        <input type="number" class="form-control" [(ngModel)]="horaExtraLocal">
                    </div>
                </div>
            </div>
        </div>
        <div style="text-align: center">
            <button type="button" class="btn btn-danger" (click)="closeModalNovoEvento()">CANCELAR</button>
            &nbsp;&nbsp;
            <button type="submit" class="btn btn-info" (click)="novoLocalEvento(nomeLocal, cidadeLocal, valorLocal, reajusteAnualLocal, horaExtraLocal,descricaoLocal)" [disabled]="!nomeLocal ||  !cidadeLocal ||  !valorLocal ||  !reajusteAnualLocal || !horaExtraLocal">CADASTRAR</button>
        </div> 
    </div>