import { Component, OnInit } from '@angular/core';
import { DataBaseService } from 'src/app/provider';
import { AuthService } from 'src/app/auth.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Location } from '@angular/common';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-parametros',
  templateUrl: './parametros.component.html',
  styleUrls: ['./parametros.component.css']
})

export class ParametrosComponent implements OnInit {
  parametros:any;
  assessoria:any;
  proposta:any;
  dataBase:any;
  parametroSelecionado:any;
  idParametro:any;
  listaCidades:any;
  localEvento:any;
  fotos:any;
  parametro!: FormGroup;
  editarParametro!: FormGroup;
  cursos!:FormGroup;
  assessorias!:FormGroup;
  public opcoesList!: FormArray;
  public opcoesListCheckbox!: FormArray;
  public opcoesListEditar!: FormArray;
  public opcoesListCheckboxEditar!: FormArray;
  public cursosList!: FormArray;
  public assessoriasList!: FormArray;
  eventoSelecionado:any;
  localNome:any = "";
  localValor:number = 0;
  descricaoLocal:any = "";
  localReajusteAnual:number = 0;
  localHoraExtra:number = 0;
  localCidade:any = "";
  localEventoId:any = "";
  descricaoLocalEditar:any = "";
  nomeLocal:any = "";
  cidadeLocal:any = "";
  valorLocal:number = 0;
  reajusteAnualLocal:number = 0;
  horaExtraLocal:number = 0;
  cobraFoto:boolean = true;
  fotoCobraEditar:boolean = true;
  descricaoFoto:any = "";
  nomeFoto:any = "";
  valorFoto:number = 0;
  fotoNomeEditar:any = "";
  fotoValorEditar:number = 0;
  fotoId:any = "";
  fotoDescricaoEditar:any = "";
  posicaoFoto:number = 0;
  posicaoFotoEditar:number = 0;
  sms:any = "";
  valorPersonalizadosFinger:number = 0;
  valorPersonalizadosBarTematico:number = 0;
  valorPersonalizadosCafeManha:number = 0;
  valorPersonalizadosCarrinhoChopp:number = 0;
  valorPersonalizadosBebidasAfter:number = 0;
  descricaoPersonalizadosFinger:any = "";
  descricaoPersonalizadosBarTematico:any = "";
  descricaoPersonalizadosCafeManha:any = "";
  descricaoPersonalizadosCarrinhoChopp:any = "";
  descricaoPersonalizadosBebidasAfter:any = "";
  personalizadosBaile:any = [];
  idPersonalizadosBaile:any = "";
  cursosCadastrados:any = [];
  idCurso:any = "";
  idAssessorias:any = "";
  dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0]; 
  get opcoesFormGroup(){return this.parametro.get('opcoes') as FormArray;}
  get valoresFormGroup(){return this.parametro.get('valores') as FormArray;}
  get opcoesEditarFormGroup(){    return this.editarParametro.get('opcoesEditar') as FormArray;}
  get valoresEditarFormGroup(){return this.editarParametro.get('valoresEditar') as FormArray;}
  get cursosFormGroup(){return this.cursos.get('curso') as FormArray;}
  get assessoriasFormGroup(){return this.assessorias.get('assessoria') as FormArray;}

  constructor(public app:AppComponent, private location:Location, private formBuilder: FormBuilder, private db:DataBaseService, public auth: AuthService) { }

  ngOnInit() {
    this.db.getSaudade().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
      if(a.tipo > b.tipo){return 1;}
      if(a.tipo < b.tipo){return -1;}
      if(a.posicao > b.posicao){return 1;}
      if(a.posicao < b.posicao){return -1;}
      return 1;
    });
      this.db.getProposta().subscribe((d:any) => this.proposta = d[0]);
    });
    this.dataBase = "SAUDADE";
    this.db.getCidades().subscribe((dt:any) => this.listaCidades = dt.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}}));    
    this.parametro = this.formBuilder.group({
      sessao : ["", Validators.required],
      valores: this.formBuilder.array([this.createOpcaoCheckbox()]),
      tipo: ["", Validators.required],
      marcado: [""],
      titulo: ["", Validators.required],
      descricao: [""],
      formando: [""],
      posicao:[""],
      opcoes: this.formBuilder.array([this.createOpcao()])
    });
    this.editarParametro = this.formBuilder.group({
      valoresEditar: this.formBuilder.array([this.createOpcaoCheckboxEditar()]),
      tipo: ["", Validators.required],
      titulo: ["", Validators.required],
      descricao: [""],
      posicao:[""],
      marcado: [""],
      formando: [""],
      opcoesEditar: this.formBuilder.array([this.createOpcaoEditar()])
  });
    this.opcoesList = this.parametro.get('opcoes') as FormArray;
    this.opcoesListCheckbox = this.parametro.get('valores') as FormArray;
    this.opcoesListEditar = this.editarParametro.get('opcoesEditar') as FormArray;
    this.opcoesListCheckboxEditar = this.editarParametro.get('valoresEditar') as FormArray;
    this.db.getPersonalizadosBaile().subscribe((pB:any) => {
      this.personalizadosBaile = pB[0]
      this.idPersonalizadosBaile = pB[0]._id;
      this.valorPersonalizadosFinger = this.personalizadosBaile.finger.valor;
      this.valorPersonalizadosBarTematico = this.personalizadosBaile.barTematico.valor;
      this.valorPersonalizadosCafeManha = this.personalizadosBaile.cafeManha.valor;
      this.valorPersonalizadosCarrinhoChopp = this.personalizadosBaile.carrinhoChopp.valor;
      this.valorPersonalizadosBebidasAfter = this.personalizadosBaile.bebidasAfter.valor;
      this.descricaoPersonalizadosFinger = this.personalizadosBaile.finger.descricao;
      this.descricaoPersonalizadosBarTematico = this.personalizadosBaile.barTematico.descricao;
      this.descricaoPersonalizadosCafeManha = this.personalizadosBaile.cafeManha.descricao;
      this.descricaoPersonalizadosCarrinhoChopp = this.personalizadosBaile.carrinhoChopp.descricao;
      this.descricaoPersonalizadosBebidasAfter = this.personalizadosBaile.bebidasAfter.descricao;
    });
    this.cursos = this.formBuilder.group({
      curso: this.formBuilder.array([this.createCurso()])
    });
    this.cursosList = this.cursos.get('curso') as FormArray;
    this.assessorias = this.formBuilder.group({
      assessoria: this.formBuilder.array([this.createAssessorias()])
    });
    this.assessoriasList = this.assessorias.get('assessoria') as FormArray;
    this.db.getCursosConfig().subscribe((conf:any) => {
      this.cursosCadastrados = conf[0].cursos;
      this.idCurso = conf[0]._id;
      if(this.cursosCadastrados.length > 0){
        this.cursosList.clear();
        let cc = this.cursosCadastrados.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
        cc.forEach((item:any) => {
          this.addCursoStart(item);
        });
      }
    });
    this.db.getAssessorias().subscribe((confi:any) => {
      this.idAssessorias = confi[0]._id;
      if(confi[0].assessorias.length > 0){
        this.assessoriasList.clear();
        let ac = confi[0].assessorias.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
        ac.forEach((item:any) => {
          this.addAssessoriasStart(item);
        });
      }
    });
  }
  
  createCursoStart(item:any):FormGroup{
    return this.formBuilder.group({
      nome: [item.nome, Validators.compose([Validators.required])],
      tempo: [item.tempo, Validators.compose([Validators.required])]
    });
  }

  addCursoStart(item:any){
    this.cursosList.push(this.createCursoStart(item));
  }
  
  createCurso():FormGroup{
    return this.formBuilder.group({
      nome: [null, Validators.compose([Validators.required])],
      tempo: [null, Validators.compose([Validators.required])]
    });
  }

  addCurso(){
    this.cursosList.push(this.createCurso());
  }

  removeCurso(index:any) {
    this.cursosList.removeAt(index);
  }

  salvarCursos(){
    if(window.confirm('Deseja Realmente Salvar esses Cursos?')){
      let obj = {
        cursos : this.cursosList.value
      }
      this.db.patchCursos(this.idCurso,obj).subscribe(res => {
        console.log(res);
        let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
        let ob = {
          data:this.dataHoje,
          hora:hora,
          usuario: localStorage.getItem('usuarioTrends'),
          menu: "PARAMETROS",
          descricao: "Cursos Alterados"
        }
        this.db.postLogs(ob).subscribe(res => {
          console.log(res);
         this.app.openAlert("Salvo Com Sucesso!");
      }, err => console.log(err))
    }, err => console.log(err))
    }
  }

  //
  createAssessoriasStart(item:any):FormGroup{
    return this.formBuilder.group({
      nome: [item.nome, Validators.compose([Validators.required])]
    });
  }

  addAssessoriasStart(item:any){
    this.assessoriasList.push(this.createAssessoriasStart(item));
  }
  
  createAssessorias():FormGroup{
    return this.formBuilder.group({
      nome: [null, Validators.compose([Validators.required])]
    });
  }

  addAssessorias(){
    this.assessoriasList.push(this.createAssessorias());
  }

  removeAssessorias(index:any) {
    this.cursosList.removeAt(index);
  }

  salvarAssessorias(){
    if(window.confirm('Deseja Realmente Salvar esses Itens?')){
      let obj = {
        assessorias : this.assessoriasList.value
      }
      this.db.patchAssessorias(this.idAssessorias,obj).subscribe(res => {
        console.log(res);
        let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
        let ob = {
          data:this.dataHoje,
          hora:hora,
          usuario: localStorage.getItem('usuarioTrends'),
          menu: "PARAMETROS",
          descricao: "Assessorias Alteradas"
        }
        this.db.postLogs(ob).subscribe(res => {
          console.log(res);
         this.app.openAlert("Salvo Com Sucesso!");
      }, err => console.log(err))
    }, err => console.log(err))
    }
  }

  //

  getParams(e:any){
    let i = e.index;
    switch(i) {
      case 0 :
        this.db.getSaudade().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
          this.dataBase = "SAUDADE";
          break;
      case 1 :
          this.db.getMissa().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
            if(a.tipo > b.tipo){return 1;}
            if(a.tipo < b.tipo){return -1;}
            if(a.posicao > b.posicao){return 1;}
            if(a.posicao < b.posicao){return -1;}
            return 1;
          });
        });
          this.dataBase = "MISSA";
          break;
      case 2 :
          this.db.getColacao().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
            if(a.tipo > b.tipo){return 1;}
            if(a.tipo < b.tipo){return -1;}
            if(a.posicao > b.posicao){return 1;}
            if(a.posicao < b.posicao){return -1;}
            return 1;
          });
        });
          this.dataBase = "COLAÇÃO";
          break;  
      case 3 :
          this.db.getJantar().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
            if(a.tipo > b.tipo){return 1;}
            if(a.tipo < b.tipo){return -1;}
            if(a.posicao > b.posicao){return 1;}
            if(a.posicao < b.posicao){return -1;}
            return 1;
          });
        });
          this.dataBase = "JANTAR";
          break;
      case 4 :
          this.db.getBaile().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
            if(a.tipo > b.tipo){return 1;}
            if(a.tipo < b.tipo){return -1;}
            if(a.posicao > b.posicao){return 1;}
            if(a.posicao < b.posicao){return -1;}
            return 1;
          });
        });
          this.dataBase = "BAILE";
          break;  
          case 5 :
          this.db.getFotos().subscribe((data:any) => { this.fotos = data.sort((a:any,b:any) => {
            if(a.posicao > b.posicao){return 1;}
            if(a.posicao < b.posicao){return -1;}
            return 1;
          });
        });
          this.dataBase = "FOTOS";
          break; 
      case 6 :
          this.db.getEventos().subscribe((data:any) => {this.localEvento = data.sort((a:any,b:any) => {
            if(a.cidade > b.cidade){return 1;}
            if(a.cidade < b.cidade){return -1;}
            if(a.nome > b.nome){return 1;}
            if(a.nome < b.nome){return -1;}
            return 1;
          });
        });
          this.dataBase = "EVENTOS";
          break; 
      case 7 :
        this.db.getAssessoria().subscribe((data:any) => this.assessoria = data[0]);
        this.db.getProposta().subscribe((data:any) => this.proposta = data[0]);
        this.db.getSms().subscribe((data:any) => this.sms = data[0]);
        this.dataBase = "CONFIGS";
        break;  
    }
  }

  atualizarParams(banco:any,obj:any){
    switch(banco){
    case "SAUDADE" :
      this.db.patchSaudade(this.idParametro,obj).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Editado Com Sucesso!");
        this.db.getSaudade().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeEditModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Editar! Tente Novamente!")})
        break;
    case "MISSA" :
      this.db.patchMissa(this.idParametro,obj).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Editado Com Sucesso!");
        this.db.getMissa().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeEditModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Editar! Tente Novamente!")})
        break;
    case "COLAÇÃO" :
      this.db.patchColacao(this.idParametro,obj).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Editado Com Sucesso!");
        this.db.getColacao().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeEditModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Editar! Tente Novamente!")})
        break;  
    case "JANTAR" :
      this.db.patchJantar(this.idParametro,obj).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Editado Com Sucesso!");
        this.db.getJantar().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeEditModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Editar! Tente Novamente!")})
        break;
    case "BAILE" :
      this.db.patchBaile(this.idParametro,obj).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Editado Com Sucesso!");
        this.db.getBaile().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeEditModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Editar! Tente Novamente!")})
        break;  
    case "CONFIGS" :
      this.db.patchAssessoria(this.idParametro,obj).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Editado Com Sucesso!");
        this.db.getAssessoria().subscribe((data:any) => this.assessoria = data[0]);
        this.closeEditModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Editar! Tente Novamente!")})
        break;  
     }
     let jsonDate = new Date().toJSON();
     let hora = new Date(jsonDate).toLocaleTimeString();
     let ob = {
       data:this.dataHoje,
       hora:hora,
       usuario: localStorage.getItem('usuarioTrends'),
       menu: "PARAMETROS",
       descricao: "Parametro Alterado: "+obj.titulo
     }
     this.db.postLogs(ob).subscribe(res => {
       console.log(res);
     }, err => console.log(err))
    }
    
  criarParams(banco:any,obj:any){
    switch(banco){
    case "saudade" :
      this.db.postSaudade(obj).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Criado Com Sucesso!");
        this.db.getSaudade().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Criar! Tente Novamente!")})
        break;
    case "missa" :
      this.db.postMissa(obj).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Criado Com Sucesso!");
        this.db.getMissa().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Criar! Tente Novamente!")})
        break;
    case "colacao" :
      this.db.postColacao(obj).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Criado Com Sucesso!");
        this.db.getColacao().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Criar! Tente Novamente!")})
        break;  
    case "jantar" :
      this.db.postJantar(obj).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Criado Com Sucesso!");
        this.db.getJantar().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Criar! Tente Novamente!")})
        break;
    case "baile" :
      this.db.postBaile(obj).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Criado Com Sucesso!");
        this.db.getBaile().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Criar! Tente Novamente!")})
        break;  
    case "eventos" :
      this.db.postEventos(obj).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Criado Com Sucesso!");
        this.db.getEventos().subscribe((data:any) =>this.parametros = data);
        this.closeModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Criar! Tente Novamente!")})
        break; 
     }
     let jsonDate = new Date().toJSON();
     let hora = new Date(jsonDate).toLocaleTimeString();
     let ob = {
       data:this.dataHoje,
       hora:hora,
       usuario: localStorage.getItem('usuarioTrends'),
       menu: "PARAMETROS",
       descricao: "Parametro Criado: "+obj.titulo
     }
     this.db.postLogs(ob).subscribe(res => {
       console.log(res);
     },err => console.log(err))
    }

  createOpcao(): FormGroup {
    return this.formBuilder.group({
      nome: [null, Validators.compose([Validators.required])],
      valor: [null, Validators.compose([Validators.required])],
      cidade:  [null, Validators.compose([Validators.required])],
      descricao: [null, Validators.compose([Validators.required])]
    });
  }

  createOpcaoCheckbox(): FormGroup {
    return this.formBuilder.group({
      valor: [null, Validators.compose([Validators.required])],
      cidade:  [null, Validators.compose([Validators.required])]
    });
  }

  createOpcaoEditar(): FormGroup {
    return this.formBuilder.group({
      nome: [null, Validators.compose([Validators.required])],
      valor: [null, Validators.compose([Validators.required])],
      cidade: [null, Validators.compose([Validators.required])],
      descricao: [null, Validators.compose([Validators.required])]
    });
    } 

  createOpcaoCheckboxEditar(): FormGroup {
    return this.formBuilder.group({
      valor: [null, Validators.compose([Validators.required])],
      cidade: [null, Validators.compose([Validators.required])]
    });
    } 

  addOpcao() {
    this.opcoesList.push(this.createOpcao());
  }

  addOpcaoCheckbox() {
    this.opcoesListCheckbox.push(this.createOpcaoCheckbox());
  }

  addOpcaoEditar() {
    this.opcoesListEditar.push(this.createOpcaoEditar());
  }

  addOpcaoCheckboxEditar() {
    this.opcoesListCheckboxEditar.push(this.createOpcaoCheckboxEditar());
  }

  removeOpcao(index:any){
    this.opcoesList.removeAt(index);
  }

  removeOpcaoCheckbox(index:any){
    this.opcoesListCheckbox.removeAt(index);
  }

  removeOpcaoEditar(index:any){
    this.opcoesListEditar.removeAt(index);
  }

  removeOpcaoCheckboxEditar(index:any){
    this.opcoesListCheckboxEditar.removeAt(index);
  }

  replaceStrings(string:any){
    if(string){
      let str = string.replace(/\%/gi,'%25');
      return str.replace(/\+/gi,'%2B').replace(/\!/gi,'%21').replace(/\&/gi,'%26').replace(/\#/gi,'%23').replace(/\;/gi,'%3B');
    }else{
      return string;
    }
  }

  reverseStrings(string:any){
    if(string){
      return string.replace(/%252B/gi,'+').replace(/%2B/gi,'+').replace(/%21/gi,'!').replace(/%25/gi,'%').replace(/%26/gi,'&').replace(/%3B/gi,';');
    }else{
      return string;
    }
  }

editParametro(tpo:any,tit:any,vlr:any,descricao:any,opt:any,form:any,checked:any,pos:any){
  let desc = this.replaceStrings(descricao);
  if(window.confirm('Deseja Realmente Editar esse Parametro?')){
    if(tpo == 'fixo'){
      let obj  = {
        descricao : desc,
        valores: vlr,
        marcado: checked,
        posicao:pos
      }
      this.atualizarParams(this.dataBase,obj);
      }else{
        if(tpo == 'checkbox'){
          opt = null;
        }else{
          vlr = null;
        }
        let objeto = {
            titulo : tit,
            descricao : desc,
            valores: vlr,
            tipo : tpo,
            opcoes : opt,
            formando: form,
            marcado: checked,
            posicao:pos
          }
          this.atualizarParams(this.dataBase,objeto);
      }
    }
}

novoParametro(db:any,tit:any,vlr:any,tpo:any,descricao:any,opt:any,form:any,checked:any,pos:any){
  let desc = this.replaceStrings(descricao);
  if(window.confirm('Deseja Realmente Criar esse Parametro?')){
    if(tpo == 'checkbox'){
      if(this.parametro.get('opcoes')?.value == null){
        opt = "";
        }else{
          this.clearFormArray(this.parametro.get('opcoes') as FormArray)
          opt = "";
        }
    }else{
      if(this.parametro.get('valores')?.value == null){
        vlr = "";
        }else{
          this.clearFormArray(this.parametro.get('valores') as FormArray)
          vlr = "";
        }
    }
    let obj = {
      titulo : tit,
      descricao : desc,
      valores: vlr,
      tipo : tpo,
      opcoes : opt,
      formando: form,
      marcado: checked,
      posicao:pos
      }
    this.criarParams(db,obj);
    }
}
  
deleteParametro(id:any){
  if(window.confirm('Deseja Realmente Excluir esse Parametro?')){
    switch(this.dataBase){
    case "SAUDADE" :
      this.db.delSaudade(id).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Editado Com Sucesso!");
        this.db.getSaudade().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeEditModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Editar! Tente Novamente!")})
        break;
    case "MISSA" :
      this.db.delMissa(id).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Editado Com Sucesso!");
        this.db.getMissa().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeEditModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Editar! Tente Novamente!")})
        break;
    case "COLAÇÃO" :
      this.db.delColacao(id).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Editado Com Sucesso!");
        this.db.getColacao().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeEditModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Editar! Tente Novamente!")})
        break;  
    case "JANTAR" :
      this.db.delJantar(id).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Editado Com Sucesso!");
        this.db.getJantar().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeEditModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Editar! Tente Novamente!")})
        break;
    case "BAILE" :
      this.db.delBaile(id).subscribe(res => {
        console.log(res);
         this.app.openAlert("Parametro Editado Com Sucesso!");
        this.db.getBaile().subscribe((data:any) => {this.parametros = data.sort((a:any,b:any) => {
          if(a.tipo > b.tipo){return 1;}
          if(a.tipo < b.tipo){return -1;}
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeEditModal();
        },err => {console.log(err); this.app.openAlert("Não Foi Possivel Editar! Tente Novamente!")})
        break;  
     }
     let jsonDate = new Date().toJSON();
     let hora = new Date(jsonDate).toLocaleTimeString();
     let ob = {
       data:this.dataHoje,
       hora:hora,
       usuario: localStorage.getItem('usuarioTrends'),
       menu: "PARAMETROS",
       descricao: "Parametro Excluido do "+this.dataBase
     }
     this.db.postLogs(ob).subscribe(res => {
       console.log(res);
     },err => console.log(err))
  }
}

deleteLocalEvento(id:any){
  this.db.delEventos(id).subscribe(res => {
    console.log(res);
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let ob = {
      data:this.dataHoje,
      hora:hora,
      usuario: localStorage.getItem('usuarioTrends'),
      menu: "PARAMETROS",
      descricao: "Local Evento Excluido"
    }
    this.db.postLogs(ob).subscribe(res => {
      console.log(res);
      this.db.getEventos().subscribe((data:any) => {this.localEvento = data.sort((a:any,b:any) => {
        if(a.cidade > b.cidade){return 1;}
        if(a.cidade < b.cidade){return -1;}
        if(a.nome > b.nome){return 1;}
        if(a.nome < b.nome){return -1;}
        return 1;
      });
    });
  }, err => console.log(err));
  },err => {console.log(err); this.app.openAlert("Não Foi Possivel Excluir! Tente Novamente!")})
}

deleteItemFoto(id:any){
  this.db.delItemFotos(id).subscribe(res => {
    console.log(res);
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let ob = {
      data:this.dataHoje,
      hora:hora,
      usuario: localStorage.getItem('usuarioTrends'),
      menu: "PARAMETROS",
      descricao: "Item de Foto Excluido"
    }
    this.db.postLogs(ob).subscribe(res => {
      console.log(res);
      this.db.getFotos().subscribe((data:any) => {this.fotos = data.sort((a:any,b:any) => {
        if(a.posicao > b.posicao){return 1;}
        if(a.posicao < b.posicao){return -1;}
        return 1;
      });
    });
  }, err => console.log(err));
  },err => {console.log(err); this.app.openAlert("Não Foi Possivel Excluir! Tente Novamente!")})
}

openModal(){
  let modal:any = document.getElementById('myModalParams')
  modal.style.display = "block";
}

closeModal(){
  this.parametro.reset();
  let modal:any = document.getElementById('myModalParams')
  modal.style.display = "none";
  this.parametro.reset();
}

clearFormArray = (formArray: FormArray) => {
  while (formArray.length !== 0) {
    formArray.removeAt(0)
  }
}

openEditModal(id:any){
  this.idParametro = id;
  this.clearFormArray(this.editarParametro.get('opcoesEditar') as FormArray);
  this.clearFormArray(this.editarParametro.get('valoresEditar') as FormArray);
  switch(this.dataBase){
    case "SAUDADE" :
        this.db.getSaudadeById(id).subscribe((data:any) => {
          this.parametroSelecionado = data;
          this.editarParametro.get('titulo')?.setValue(this.parametroSelecionado.titulo);
          this.editarParametro.get('descricao')?.setValue(this.reverseStrings(this.parametroSelecionado.descricao));
          this.editarParametro.get('tipo')?.setValue(this.parametroSelecionado.tipo);
          this.editarParametro.get('formando')?.setValue(this.parametroSelecionado.formando);
          this.editarParametro.get('marcado')?.setValue(this.parametroSelecionado.marcado);
          this.editarParametro.get('posicao')?.setValue(this.parametroSelecionado.posicao);
            if(this.parametroSelecionado.opcoes == null || this.parametroSelecionado.opcoes == undefined){
              this.clearFormArray(this.editarParametro.get('opcoesEditar') as FormArray);
              }else{
                let opEditar = this.editarParametro.get('opcoesEditar') as FormArray;
                for (var i=0; i<this.parametroSelecionado.opcoes.length; i++){
                    opEditar.push(this.formBuilder.group({
                      nome: this.parametroSelecionado.opcoes[i].nome, 
                      valor: this.parametroSelecionado.opcoes[i].valor,
                      cidade: this.parametroSelecionado.opcoes[i].cidade,
                      descricao: this.reverseStrings(this.parametroSelecionado.opcoes[i].descricao)
                    })
                )};
            };
            if(this.parametroSelecionado.valores == null || this.parametroSelecionado.valores == undefined){
              this.clearFormArray(this.editarParametro.get('valoresEditar') as FormArray);
              }else{
                let vlrEditar = this.editarParametro.get('valoresEditar') as FormArray;
                for (var i=0; i<this.parametroSelecionado.valores.length; i++){
                    vlrEditar.push(this.formBuilder.group({
                      valor: this.parametroSelecionado.valores[i].valor,
                      cidade: this.parametroSelecionado.valores[i].cidade
                    })
                )}
            };
          let modal:any = document.getElementById('myEditModal')
          modal.style.display = "block";
        });
        break;
    case "MISSA":
      this.db.getMissaById(id).subscribe((data:any) => {
        this.parametroSelecionado = data;
        this.editarParametro.get('titulo')?.setValue(this.parametroSelecionado.titulo);
        this.editarParametro.get('descricao')?.setValue(this.reverseStrings(this.parametroSelecionado.descricao));
        this.editarParametro.get('tipo')?.setValue(this.parametroSelecionado.tipo);
        this.editarParametro.get('formando')?.setValue(this.parametroSelecionado.formando);
        this.editarParametro.get('marcado')?.setValue(this.parametroSelecionado.marcado);
        this.editarParametro.get('posicao')?.setValue(this.parametroSelecionado.posicao);
          if(this.parametroSelecionado.opcoes == null || this.parametroSelecionado.opcoes == undefined){
            this.clearFormArray(this.editarParametro.get('opcoesEditar') as FormArray);
            }else{
              let opEditar = this.editarParametro.get('opcoesEditar') as FormArray;
              for (var i=0; i<this.parametroSelecionado.opcoes.length; i++){
                  opEditar.push(this.formBuilder.group({
                    nome: this.parametroSelecionado.opcoes[i].nome, 
                    valor: this.parametroSelecionado.opcoes[i].valor,
                    cidade: this.parametroSelecionado.opcoes[i].cidade,
                    descricao: this.reverseStrings(this.parametroSelecionado.opcoes[i].descricao)
                  })
              )};
          };
          if(this.parametroSelecionado.valores == null || this.parametroSelecionado.valores == undefined){
            this.clearFormArray(this.editarParametro.get('valoresEditar') as FormArray);
            }else{
              let vlrEditar = this.editarParametro.get('valoresEditar') as FormArray;
              for (var i=0; i<this.parametroSelecionado.valores.length; i++){
                  vlrEditar.push(this.formBuilder.group({
                    valor: this.parametroSelecionado.valores[i].valor,
                    cidade: this.parametroSelecionado.valores[i].cidade
                  })
              )}
          };
        let modal:any = document.getElementById('myEditModal')
        modal.style.display = "block";
      });
      break;
    case "COLAÇÃO" :
      this.db.getColacaoById(id).subscribe((data:any) => {
        this.parametroSelecionado = data;
        this.editarParametro.get('titulo')?.setValue(this.parametroSelecionado.titulo);
        this.editarParametro.get('descricao')?.setValue(this.reverseStrings(this.parametroSelecionado.descricao));
        this.editarParametro.get('tipo')?.setValue(this.parametroSelecionado.tipo);
        this.editarParametro.get('formando')?.setValue(this.parametroSelecionado.formando);
        this.editarParametro.get('marcado')?.setValue(this.parametroSelecionado.marcado);
        this.editarParametro.get('posicao')?.setValue(this.parametroSelecionado.posicao);
          if(this.parametroSelecionado.opcoes == null || this.parametroSelecionado.opcoes == undefined){
            this.clearFormArray(this.editarParametro.get('opcoesEditar') as FormArray);
            }else{
              let opEditar = this.editarParametro.get('opcoesEditar') as FormArray;
              for (var i=0; i<this.parametroSelecionado.opcoes.length; i++){
                  opEditar.push(this.formBuilder.group({
                    nome: this.parametroSelecionado.opcoes[i].nome, 
                    valor: this.parametroSelecionado.opcoes[i].valor,
                    cidade: this.parametroSelecionado.opcoes[i].cidade,
                    descricao: this.reverseStrings(this.parametroSelecionado.opcoes[i].descricao)
                  })
              )};
          };
          if(this.parametroSelecionado.valores == null || this.parametroSelecionado.valores == undefined){
            this.clearFormArray(this.editarParametro.get('valoresEditar') as FormArray);
            }else{
              let vlrEditar = this.editarParametro.get('valoresEditar') as FormArray;
              for (var i=0; i<this.parametroSelecionado.valores.length; i++){
                  vlrEditar.push(this.formBuilder.group({
                    valor: this.parametroSelecionado.valores[i].valor,
                    cidade: this.parametroSelecionado.valores[i].cidade
                  })
              )}
          };
        let modal:any = document.getElementById('myEditModal')
        modal.style.display = "block";
      });
        break;  
    case "JANTAR" :
      this.db.getJantarById(id).subscribe((data:any) => {
        this.parametroSelecionado = data;
        this.editarParametro.get('titulo')?.setValue(this.parametroSelecionado.titulo);
        this.editarParametro.get('descricao')?.setValue(this.reverseStrings(this.parametroSelecionado.descricao));
        this.editarParametro.get('tipo')?.setValue(this.parametroSelecionado.tipo);
        this.editarParametro.get('formando')?.setValue(this.parametroSelecionado.formando);
        this.editarParametro.get('marcado')?.setValue(this.parametroSelecionado.marcado);
        this.editarParametro.get('posicao')?.setValue(this.parametroSelecionado.posicao);
          if(this.parametroSelecionado.opcoes == null || this.parametroSelecionado.opcoes == undefined){
            this.clearFormArray(this.editarParametro.get('opcoesEditar') as FormArray);
            }else{
              let opEditar = this.editarParametro.get('opcoesEditar') as FormArray;
              for (var i=0; i<this.parametroSelecionado.opcoes.length; i++){
                  opEditar.push(this.formBuilder.group({
                    nome: this.parametroSelecionado.opcoes[i].nome, 
                    valor: this.parametroSelecionado.opcoes[i].valor,
                    cidade: this.parametroSelecionado.opcoes[i].cidade,
                    descricao: this.reverseStrings(this.parametroSelecionado.opcoes[i].descricao)
                  })
              )};
          };
          if(this.parametroSelecionado.valores == null || this.parametroSelecionado.valores == undefined){
            this.clearFormArray(this.editarParametro.get('valoresEditar') as FormArray);
            }else{
              let vlrEditar = this.editarParametro.get('valoresEditar') as FormArray;
              for (var i=0; i<this.parametroSelecionado.valores.length; i++){
                  vlrEditar.push(this.formBuilder.group({
                    valor: this.parametroSelecionado.valores[i].valor,
                    cidade: this.parametroSelecionado.valores[i].cidade
                  })
              )}
          };
        let modal:any = document.getElementById('myEditModal')
        modal.style.display = "block";
      });
        break;
    case "BAILE" :
      this.db.getBaileById(id).subscribe((data:any) => {
        this.parametroSelecionado = data;
        this.editarParametro.get('titulo')?.setValue(this.parametroSelecionado.titulo);
        this.editarParametro.get('descricao')?.setValue(this.reverseStrings(this.parametroSelecionado.descricao));
        this.editarParametro.get('tipo')?.setValue(this.parametroSelecionado.tipo);
        this.editarParametro.get('formando')?.setValue(this.parametroSelecionado.formando);
        this.editarParametro.get('marcado')?.setValue(this.parametroSelecionado.marcado);
        this.editarParametro.get('posicao')?.setValue(this.parametroSelecionado.posicao);
          if(this.parametroSelecionado.opcoes == null || this.parametroSelecionado.opcoes == undefined){
            this.clearFormArray(this.editarParametro.get('opcoesEditar') as FormArray);
            }else{
              let opEditar = this.editarParametro.get('opcoesEditar') as FormArray;
              for (var i=0; i<this.parametroSelecionado.opcoes.length; i++){
                  opEditar.push(this.formBuilder.group({
                    nome: this.parametroSelecionado.opcoes[i].nome, 
                    valor: this.parametroSelecionado.opcoes[i].valor,
                    cidade: this.parametroSelecionado.opcoes[i].cidade,
                    descricao: this.reverseStrings(this.parametroSelecionado.opcoes[i].descricao)
                  })
              )};
          };
          if(this.parametroSelecionado.valores == null || this.parametroSelecionado.valores == undefined){
            this.clearFormArray(this.editarParametro.get('valoresEditar') as FormArray);
            }else{
              let vlrEditar = this.editarParametro.get('valoresEditar') as FormArray;
              for (var i=0; i<this.parametroSelecionado.valores.length; i++){
                  vlrEditar.push(this.formBuilder.group({
                    valor: this.parametroSelecionado.valores[i].valor,
                    cidade: this.parametroSelecionado.valores[i].cidade
                  })
              )}
          };
        let modal:any = document.getElementById('myEditModal')
        modal.style.display = "block";
      });
        break;  
        case "CONFIGS" :
        this.db.getAssessoria().subscribe((data:any) => {
        this.parametroSelecionado = data[0];
        this.editarParametro.get('titulo')?.setValue(this.parametroSelecionado.titulo);
        this.editarParametro.get('descricao')?.setValue(this.reverseStrings(this.parametroSelecionado.descricao));
        this.editarParametro.get('tipo')?.setValue(this.parametroSelecionado.tipo);
        this.editarParametro.get('formando')?.setValue(this.parametroSelecionado.formando);
        this.editarParametro.get('marcado')?.setValue(this.parametroSelecionado.marcado);
        this.editarParametro.get('posicao')?.setValue(0);
          if(this.parametroSelecionado.opcoes == null || this.parametroSelecionado.opcoes == undefined){
            this.clearFormArray(this.editarParametro.get('opcoesEditar') as FormArray);
            }else{
              let opEditar = this.editarParametro.get('opcoesEditar') as FormArray;
              for (var i=0; i<this.parametroSelecionado.opcoes.length; i++){
                  opEditar.push(this.formBuilder.group({
                    nome: this.parametroSelecionado.opcoes[i].nome, 
                    valor: this.parametroSelecionado.opcoes[i].valor,
                    cidade: this.parametroSelecionado.opcoes[i].cidade,
                    descricao: this.reverseStrings(this.parametroSelecionado.opcoes[i].descricao)
                  })
              )};
          };
          if(this.parametroSelecionado.valores == null || this.parametroSelecionado.valores == undefined){
            this.clearFormArray(this.editarParametro.get('valoresEditar') as FormArray);
            }else{
              let vlrEditar = this.editarParametro.get('valoresEditar') as FormArray;
              for (var i=0; i<this.parametroSelecionado.valores.length; i++){
                  vlrEditar.push(this.formBuilder.group({
                    valor: this.parametroSelecionado.valores[i].valor,
                    cidade: this.parametroSelecionado.valores[i].cidade
                  })
              )}
          };
        let modal:any = document.getElementById('myEditModal')
        modal.style.display = "block";
      });
        break;  
     }
}

closeEditModal(){
  this.clearFormArray(this.editarParametro.get('opcoesEditar') as FormArray);
  this.clearFormArray(this.editarParametro.get('valoresEditar') as FormArray);
  this.parametroSelecionado = "";
  let modal:any = document.getElementById('myEditModal')
  modal.style.display = "none";
  this.editarParametro.reset();
}

openModalEvento(id:any){
  this.db.getEventosById(id).subscribe((data:any) => {
    this.eventoSelecionado = data;
    this.localEventoId = id;
    this.localNome = this.eventoSelecionado.nome;
    this.descricaoLocalEditar = this.eventoSelecionado.descricao;
    this.localValor = this.eventoSelecionado.valor;
    this.localReajusteAnual = this.eventoSelecionado.reajusteAnual;
    this.localHoraExtra = this.eventoSelecionado.horaExtra;
    this.localCidade = this.eventoSelecionado.cidade;
  })
  let modal:any = document.getElementById('myEditEvento')
  modal.style.display = "block";
}

closeModalEvento(){
  let modal:any = document.getElementById('myEditEvento');
  modal.style.display = "none";
  this.eventoSelecionado = "";
  this.localNome = "";
  this.localValor = 0;
  this.localReajusteAnual = 0;
  this.localHoraExtra = 0;
  this.localCidade = "";
  this.localEventoId = "";
  this.descricaoLocalEditar = "";
}

editLocalEvento(nome:any,cidade:any,valor:any,reajuste:any,hora:any,descricao:any){
  if(window.confirm('Deseja Realmente Editar esse Local de Evento?')){
    let obj = {
      nome : nome, 
      valor : valor, 
      cidade : cidade, 
      horaExtra : hora, 
      reajusteAnual : reajuste,
      descricao: this.replaceStrings(descricao)
    }
    this.db.patchEventos(this.localEventoId,obj).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let ob = {
        data:this.dataHoje,
        hora:hora,
        usuario: localStorage.getItem('usuarioTrends'),
        menu: "PARAMETROS",
        descricao: "Local de Evento Alterado: "+nome+" - "+cidade
      }
      this.db.postLogs(ob).subscribe(res => {
        console.log(res);
         this.app.openAlert("Local de Evento Editado Com Sucesso!");
        this.db.getEventos().subscribe((data:any) => {this.localEvento = data.sort((a:any,b:any) => {
          if(a.cidade > b.cidade){return 1;}
          if(a.cidade < b.cidade){return -1;}
          if(a.nome > b.nome){return 1;}
          if(a.nome < b.nome){return -1;}
          return 1;
        });
      });
      this.closeModalEvento();
    }, err => console.log(err));
  }, err => console.log(err))
  }
}

editFotos(nome:any,valor:any,descricao:any,posicao:any,cobra:any){
  if(window.confirm('Deseja Realmente Editar esse Item de Fotos?')){
    let obj = {
      nome : nome, 
      valor : valor, 
      descricao: this.replaceStrings(descricao),
      posicao: posicao,
      cobra:cobra
    }
  this.db.patchFotos(this.fotoId,obj).subscribe(res => {
    console.log(res);
     this.app.openAlert("Item de Fotos Editado Com Sucesso!");
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let ob = {
      data:this.dataHoje,
      hora:hora,
      usuario: localStorage.getItem('usuarioTrends'),
      menu: "PARAMETROS",
      descricao: "Foto Alterada: "+nome
    }
    this.db.postLogs(ob).subscribe(res => {
      console.log(res);
    this.db.getFotos().subscribe((data:any) => {this.fotos = data.sort((a:any,b:any) => {
      if(a.posicao > b.posicao){return 1;}
      if(a.posicao < b.posicao){return -1;}
      return 1;
    });
  });
    this.closeModalFotos();
}, err => console.log(err));
  }, err => console.log(err))
  }
}

openModalNovoFoto(){
  let modal:any = document.getElementById('myNovoFoto')
  modal.style.display = "block";
}

closeModalNovoFoto(){
  let modal:any = document.getElementById('myNovoFoto');
  modal.style.display = "none";
  this.descricaoFoto = "";
  this.nomeFoto = "";
  this.valorFoto = 0;
  this.posicaoFoto = 0;
  this.cobraFoto = true;
}

openModalFoto(id:any){
  this.db.getFotosById(id).subscribe((data:any) => {
    this.fotoId = id;
    this.fotoNomeEditar = data.nome;
    this.fotoCobraEditar = data.cobra;
    this.fotoDescricaoEditar = data.descricao;
    this.posicaoFotoEditar = data.posicao
    this.fotoValorEditar = data.valor;
  })
  let modal:any = document.getElementById('myEditFoto')
  modal.style.display = "block";
}

closeModalFotos(){
  let modal:any = document.getElementById('myEditFoto');
  modal.style.display = "none";
  this.fotoNomeEditar = "";
  this.fotoValorEditar = 0;
  this.fotoId = "";
  this.fotoDescricaoEditar = "";
  this.fotoCobraEditar = true;
  this.posicaoFotoEditar = 0;
}

novoFoto(nome:any,valor:any,descricao:any,posicaoFoto:any,cobra:any){
  if(window.confirm('Deseja Realmente Adicionar esse Item ao Fotos?')){
    let obj = {
      nome : nome, 
      valor : valor, 
      descricao : this.replaceStrings(descricao),
      posicao : posicaoFoto,
      cobra: cobra
    }
    this.db.postFotos(obj).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let ob = {
        data:this.dataHoje,
        hora:hora,
        usuario: localStorage.getItem('usuarioTrends'),
        menu: "PARAMETROS",
        descricao: "Nova Foto Adicionada: "+nome
      }
      this.db.postLogs(ob).subscribe(res => {
        console.log(res);
         this.app.openAlert("Item Adicionado Com Sucesso!");
        this.db.getFotos().subscribe((data:any) => {this.fotos = data.sort((a:any,b:any) => {
          if(a.posicao > b.posicao){return 1;}
          if(a.posicao < b.posicao){return -1;}
          return 1;
        });
      });
        this.closeModalNovoFoto();
    }, err => console.log(err));
  }, err => console.log(err))
  }
}

openModalNovoEvento(){
  let modal:any = document.getElementById('myNovoEvento')
  modal.style.display = "block";
}

closeModalNovoEvento(){
  let modal:any = document.getElementById('myNovoEvento');
  modal.style.display = "none";
  this.descricaoLocal = "";
  this.nomeLocal = "";
  this.cidadeLocal = "";
  this.valorLocal = 0;
  this.reajusteAnualLocal = 0;
  this.horaExtraLocal = 0;
}

novoLocalEvento(nome:any,cidade:any,valor:any,reajuste:any,hora:any,descricao:any){
  if(window.confirm('Deseja Realmente Adicionar esse Local de Evento?')){
    let obj = {
      nome : nome, 
      valor : valor, 
      cidade : cidade, 
      horaExtra : hora, 
      reajusteAnual : reajuste,
      descricao : this.replaceStrings(descricao)
    }
    this.db.postEventos(obj).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let ob = {
        data:this.dataHoje,
        hora:hora,
        usuario: localStorage.getItem('usuarioTrends'),
        menu: "PARAMETROS",
        descricao: "Novo Local de Evento Adicionado: "+nome+" - "+cidade
      }
      this.db.postLogs(ob).subscribe(res => {
        console.log(res);
         this.app.openAlert("Local de Evento Adicionado Com Sucesso!");
        this.db.getEventos().subscribe((data:any) => {this.localEvento = data.sort((a:any,b:any) => {
          if(a.cidade > b.cidade){return 1;}
          if(a.cidade < b.cidade){return -1;}
          if(a.nome > b.nome){return 1;}
          if(a.nome < b.nome){return -1;}
          return 1;
        });
      });
        this.closeModalNovoEvento();
    }, err => console.log(err));
  }, err => console.log(err))
  }
}

editarNProposta(vlr:any){
  if(window.confirm('Deseja Realmente Editar a Sequência das Propostas?')){
    let obj = {
      valor : vlr
    }
    this.db.patchProposta(this.proposta._id,obj).subscribe(res => {
      console.log(res);
       this.app.openAlert("Sequência das Propostas Editado Com Sucesso!");
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let ob = {
        data:this.dataHoje,
        hora:hora,
        usuario: localStorage.getItem('usuarioTrends'),
        menu: "PARAMETROS",
        descricao: "Número da proposta alterada: "+vlr
      }
      this.db.postLogs(ob).subscribe(res => {
        console.log(res);
      this.db.getProposta().subscribe((data:any) => this.proposta = data[0]);
      }, err => console.log(err));
    }, err => console.log(err))
  }
}

goBack(){
  this.location.back();
 }

 editarNrSMS(vlr:any){
  if(window.confirm('Deseja Realmente Editar a Telefone para SMS?')){
    let obj = {
      valor : vlr
    }
    this.db.patchProposta(this.sms._id,obj).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let ob = {
        data:this.dataHoje,
        hora:hora,
        usuario: localStorage.getItem('usuarioTrends'),
        menu: "PARAMETROS",
        descricao: "Número SMS alterado: "+vlr
      }
      this.db.postLogs(ob).subscribe(res => {
        console.log(res);
       this.app.openAlert("Número de Telefone Editado Com Sucesso!");
      this.db.getSms().subscribe((data:any) => this.sms = data[0]);
      }, err => console.log(err));
    }, err => console.log(err))
  }
}

salvarPersonalizadosBaile(){
  let obj = {
      finger :  {
        valor: this.valorPersonalizadosFinger,
        descricao: this.descricaoPersonalizadosFinger
      },
      barTematico :  {
        valor: this.valorPersonalizadosBarTematico,
        descricao: this.descricaoPersonalizadosBarTematico
      },
      cafeManha :  {
        valor: this.valorPersonalizadosCafeManha,
        descricao: this.descricaoPersonalizadosCafeManha
      },
      carrinhoChopp :  {
        valor: this.valorPersonalizadosCarrinhoChopp,
        descricao: this.descricaoPersonalizadosCarrinhoChopp
      },
      bebidasAfter :  {
        valor: this.valorPersonalizadosBebidasAfter,
        descricao: this.descricaoPersonalizadosBebidasAfter
      }
  }
  this.db.patchPersonalizadosBaile(this.idPersonalizadosBaile,obj).subscribe(res => {
    console.log(res);
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    let ob = {
      data:this.dataHoje,
      hora:hora,
      usuario: localStorage.getItem('usuarioTrends'),
      menu: "PARAMETROS",
      descricao: "Personalizados do Baile Alterados"
    }
    this.db.postLogs(ob).subscribe(res => {
      console.log(res);
     this.app.openAlert("Personalizados do Baile Atualizado com Sucesso!");
    }, err => console.log(err));
  }, err => {
    console.log(err);
     this.app.openAlert("Erro ao Salvar Personalizados do Baile, tente novamente!")
  })
}


}
