import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule,FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons'; 
import { AuthService } from './auth.service';
import { AuthGuard } from './auth-guard';
import { HttpClientModule } from '@angular/common/http';
import { DataBaseService } from './provider';
import { NgHttpLoaderModule } from 'ng-http-loader'; 
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { OrcamentoComponent } from './pages/orcamento/orcamento.component';
import { TurmasComponent } from './pages/turmas/turmas.component';
import { FormadosComponent } from './pages/formados/formados.component';
import { TurmasColegioComponent } from './pages/turmas-colegio/turmas-colegio.component';
import { ArquivoComponent } from './pages/arquivo/arquivo.component';
import { GaleriaComponent } from './pages/galeria/galeria.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { ParametrosComponent } from './pages/parametros/parametros.component';
import { cidadeFilterPipe } from './pipes/selPipe.pipe';
import { DetailsContratoComponent } from './pages/details-contrato/details-contrato.component';
import { DetailsOrcamentoComponent } from './pages/details-orcamento/details-orcamento.component';
import { OrcamentoColegioComponent } from './pages/orcamento-colegio/orcamento-colegio.component';
import { OrcamentoInfantilComponent } from './pages/orcamento-infantil/orcamento-infantil.component';
import { ParametrosColegioComponent } from './pages/parametros-colegio/parametros-colegio.component';
import { ParametrosInfantilComponent } from './pages/parametros-infantil/parametros-infantil.component';
import { ArraySortPipe } from './pipes/order.pipe';
import { jqxSchedulerModule } from 'jqwidgets-ng/jqxscheduler';
import { registerLocaleData } from '@angular/common';
import { LogsComponent } from './pages/logs/logs.component';
import { ReplacePipe } from './pipes/replace.pipe';
import { MinhaFormaturaComponent } from './pages/minha-formatura/minha-formatura.component';
import { DetailsFormaturaComponent } from './pages/details-formatura/details-formatura.component';
import { AgendaComponent } from './pages/agenda/agenda.component';
import { AppRoutingModule } from './app-routing.modules';
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { TurmasInfantilComponent } from './pages/turmas-infantil/turmas-infantil.component';
import { TurmasEscolaComponent } from './pages/turmas-escola/turmas-escola.component';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'
import { NgSelectModule } from '@ng-select/ng-select';
import { DetailsOrcamentoInfantilComponent } from './pages/details-orcamento-infantil/details-orcamento-infantil.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

registerLocaleData(localePt);

@NgModule({ 
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    OrcamentoComponent,
    OrcamentoColegioComponent,
    OrcamentoInfantilComponent,
    TurmasComponent,
    TurmasColegioComponent,
    TurmasInfantilComponent,
    TurmasEscolaComponent,
    FormadosComponent,
    ArquivoComponent,
    GaleriaComponent,
    UsuariosComponent,
    ParametrosComponent,
    ParametrosColegioComponent,
    ParametrosInfantilComponent,
    cidadeFilterPipe,
    ArraySortPipe,
    DetailsOrcamentoComponent,
    DetailsOrcamentoInfantilComponent,
    DetailsContratoComponent,
    LogsComponent,
    ReplacePipe,
    MinhaFormaturaComponent,
    DetailsFormaturaComponent,
    AgendaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MatTooltipModule,
    MatMenuModule,
    MatTabsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatStepperModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatBadgeModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    jqxSchedulerModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgSelectModule
    ],
  providers: [provideNgxMask(),AuthGuard,DataBaseService,AuthService,{ provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
    { provide: LOCALE_ID, useValue: "pt-BR" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fab);
   }
 }