<div class="topoLogo no-print">
  <div>
      <img src="../assets/img/logo.png" style="height:90px">
  </div>
</div>  
<div class="back no-print" (click)="goBack()"><fa-icon [icon]="['fas', 'arrow-alt-circle-left']" size="2x"></fa-icon></div>
<mat-horizontal-stepper linear="true" #stepper class="full-width" labelPosition="bottom"> 
  <mat-step [stepControl]="inicio" [editable]="!block">
  <form [formGroup]="inicio">
      <ng-template matStepLabel>Inicio</ng-template>
        <div class="row" style="margin-top: 10px; font-size:1.25em; padding:10px">
          <div class="col col-4">
                <label>Cidade</label>
                <select formControlName="cidadeEscolhida" (change)="selecionarCidade(inicio.get('cidadeEscolhida')?.value)" [ngClass]="{'is-invalid':!inicio.controls['cidadeEscolhida'].valid}" class="form-control">
                  <option [selected] value="">SELECIONAR</option>
                  <option *ngFor="let cidade of listaCidades" [value]="cidade?.nome">
                    {{cidade?.nome}}
                  </option>
                </select>
          </div>
          <div class="col col-4" *ngIf="cidadeCheck">
                  <label>Instituição</label>
                  <select formControlName="instituicoes" (change)="selecionarInstituicao(inicio.get('instituicoes')?.value)" [ngClass]="{'is-invalid':!inicio.controls['instituicoes'].valid}" class="form-control">
                   <option [selected] value="">SELECIONAR</option>
                    <option *ngFor="let facul of listaInstituicoes" [value]="facul?.nome">
                      {{facul?.nome}}
                    </option>
                  </select>
          </div>
              <div class="col col-4" *ngIf="instituicaoCheck">
                <label>Curso</label>
                <select formControlName="curso" (change)="selecionarCurso(inicio.get('curso')?.value)" [ngClass]="{'is-invalid':!inicio.controls['curso'].valid}" class="form-control">
                  <option [selected]>SELECIONAR</option>
                  <option *ngFor="let curso of listaCursos" [value]="curso">
                    {{curso}}
                    </option>
                  </select>
              </div>
            </div>
            <table class="table table-striped" style="text-align:center" *ngIf="cursoCheck">
              <thead>
                <tr>
                  <th scope="col">Curso</th>
                  <th scope="col">Periodo</th>
                  <th scope="col">Turma</th>
                  <th scope="col">Ano</th>
                  <th scope="col">Sala</th>
                  <th scope="col">Comissão</th>
                  <th scope="col">Situação</th>
                  <th scope="col">Consultor</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let turma of listaTurmas" class="turmasSelect" (click)="turmaSelected($event, turma?._id)">
                  <td>{{turma?.curso}}</td>
                  <td>{{turma?.periodo}}</td>
                  <td>{{turma?.turma}}</td>
                  <td>{{turma?.ano}}</td>
                  <td>{{turma?.sala}}</td>
                  <td>{{turma?.nomeComissao}}</td>
                  <th>{{turma?.situacao}}</th>
                  <td>{{turma?.consultor}}</td>
                </tr>
              </tbody>
            </table> 
      <hr>
      <h4>Turmas Selecionadas</h4>
      <table class="table table-striped" style="text-align:center">
        <thead>
          <tr>
            <th scope="col">Instituição</th>
            <th scope="col">Curso</th>
            <th scope="col">Periodo</th>
            <th scope="col">Turma</th>
            <th scope="col">Ano</th>
            <th scope="col">Comissão</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let turma of arrayTurmas">
            <th>{{turma?.instituicao}}</th>
            <td>{{turma?.curso}}</td>
            <td>{{turma?.periodoTurma}}</td>
            <td>{{turma?.turma}}</td>
            <td>{{turma?.anoTurma}}</td>
            <td>{{turma?.nomeComissao}}</td>
            <td>
              <button class="btn btn-danger" type="button" (click)="removeTurma(turma.turmaId)">
                <fa-icon [icon]="['fas', 'trash']" size="1x"></fa-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table> 
        <div class="row" stepperButtons>
          <div class="col col-3">
            &nbsp;
          </div>
          <div class="col" style="text-align: right;">
            <button class="btn btn-success" (click)="limparTurmas()" [disabled]="listaTurmas.length < 1">Adicionar Outra Turma</button>
          </div>
          <div class="col" style="text-align: left;">
            <button class="btn btn-info" matStepperNext [disabled]="!inicio.valid" (click)="adicionarContatos()">Próximo</button>
          </div>
          <div class="col col-3">
            &nbsp;
          </div>
        </div>
      </form>             
  </mat-step> 
<mat-step [stepControl]="detalhes" [editable]="!block">
  <form [formGroup]="detalhes">
    <ng-template matStepLabel>Dados</ng-template>
      <div class="row" style="padding:10px">
        <div class="col col-2">
          <label>Formandos</label>
          <input type="number" class="form-control" formControlName="qtdeFormandos" [ngClass]="{'is-invalid':!detalhes.controls['qtdeFormandos'].valid}" [readonly]="block">
        </div>
        <div class="col col-3">
          <label>Data Inicio</label>
          <input type="date" class="form-control" formControlName="dataInicio" (change)="diferencaMeses(detalhes.get('dataInicio')?.value, detalhes.get('dataFim')?.value)"  [ngClass]="{'is-invalid':!detalhes.controls['dataInicio'].valid}">
        </div>
        <div class="col col-3">
          <label>Data da Formatura</label>
          <input type="date" class="form-control" formControlName="dataFim" (change)="diferencaMeses(detalhes.get('dataInicio')?.value, detalhes.get('dataFim')?.value)"  [ngClass]="{'is-invalid':!detalhes.controls['dataFim'].valid}">
        </div>
        <div class="col col-2">
          <label>Prestações</label>
          <input type="number" class="form-control" name="prestacoes" [ngModel]="prestacoes" [ngModelOptions]="{standalone: true}" readonly>
        </div>
        <div class="col col-2">
            <label>Dias</label>
            <select (change)="selecionarDiaEvento()" formControlName="diasEvento" class="form-control" [ngClass]="{'is-invalid':!detalhes.controls['diasEvento'].valid}">
              <option value=1>1</option>
              <option value=2>2</option>
            </select>
        </div>
      </div> 
    <form [formGroup]="detalhes">
    <div class="card-body" formArrayName="contacts">
      <div class="row">
        <div class="col col-12" style="font-size: 0.9em; color:rgb(116, 116, 116); font-weight: 500">Comissão:</div>
      </div>
        <div class="row">
          <div class="col-12" style="font-size: 0.85em" *ngFor="let contact of contactFormGroup.controls; let i = index;">
            <div [formGroupName]="i" class="row" style="align-items: flex-end;">
              <div class="form-group col-4">
                <label>Nome</label>
                <input type="text" class="form-control" formControlName="nome" [ngClass]="{'is-invalid':!detalhes.controls['contacts'].valid}">
              </div>
              <div class="form-group col-3">
                <label>Telefone</label>
                <input type="tel" class="form-control" mask="(00) 00000-0000" formControlName="telefone" [ngClass]="{'is-invalid':!detalhes.controls['contacts'].valid}">
              </div>
              <div class="form-group col-3">
                <label>E-mail</label>
                <input type="email" class="form-control" formControlName="email" [ngClass]="{'is-invalid':!detalhes.controls['contacts'].valid}">
              </div>  
              <div class="form-group col-2 text-right">
                <button class="btn btn-danger" type="button" (click)="removeContact(i)">
                  <fa-icon [icon]="['fas', 'minus-circle']" size="1x"></fa-icon>
                </button>&nbsp;
                <button class="btn btn-success" type="button" (click)="addContact()">
                  <fa-icon [icon]="['fas', 'plus-circle']" size="1x"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>   
    </div>
    <div class="row">
      <div class="col col-1">
        &nbsp;
      </div>
      <div class="col">
          <button class="btn btn-info" (click)="addContact()">
            <fa-icon [icon]="['fas', 'user-plus']" size="1x"></fa-icon>
            &nbsp;Adicionar Contato
          </button>
          &nbsp;
          <button class="btn btn-success" [disabled]="!detalhes.get('contacts').valid || !detalhes.controls['qtdeFormandos'].valid || block" (click)="salvarContatos()">
            <fa-icon [icon]="['fas', 'save']" size="1x"></fa-icon>
            &nbsp;Salvar Contatos
          </button>
          <span *ngIf="!this.detalhes.get('contacts')?.invalid && this.detalhes.invalid">&nbsp;&nbsp;<fa-icon [icon]="['fas', 'hand-point-left']" size="1x"></fa-icon>&nbsp;<strong>CLIQUE EM SALVAR CONTATOS!</strong></span>
       </div>
    </div>
  </form>
  <hr>
  <div class="stepperButtons">
      <button class="btn btn-dark" matStepperPrevious>Anterior</button>
        &nbsp;&nbsp;
      <button class="btn btn-info" matStepperNext [disabled]="this.detalhes.invalid">Próximo</button>
  </div>
  </form>             
</mat-step>
<mat-step [stepControl]="aulaSaudade">
  <form>
    <ng-template matStepLabel>Aula Saudade</ng-template>
    <!-- SELECT OPTION -->
    <div *ngFor="let item of aulaSaudadeSelecionavel">
          <label>{{item?.titulo}}</label>
          <select (change)="aSaudadeSomaSelecionavel($event,item?.posicao)" class="form-control">
            <option selected [value]="['zerar',item?.titulo]"> -- Nenhum -- </option>
            <optgroup *ngFor="let itemOpt of (item?.opcoes | cidadeFilter: cidade)">
             <option [translate]="item?.formando" [value]="[itemOpt?.valor,item?.titulo]" [title]="itemOpt?.descricao">{{itemOpt?.nome}}</option>
             <option disabled style="font-size: 0.8em;">{{itemOpt?.descricao | replace}} </option>
            </optgroup>
          </select>
        <hr>
    </div>
    <!-- CHECKBOX -->
    <div *ngFor="let item of aulaSaudadeCheckbox; let i = index" style="font-size:1.2em; padding:5px">
      <div class="custom-control custom-checkbox">
        <input class="custom-control-input" *ngFor="let vlr of (item?.valores | cidadeFilter: cidade)" [id]="i+'aulaCheck'" type="checkbox" [required]="item?.formando" [checked]="item?.marcado" [disabled]="item?.marcado && tipoUsuario == 'Vendedor'" [value]="vlr?.valor" (change)="aSaudadeSoma($event, 'nao',item?.posicao)">
          <label class="custom-control-label" [for]="i+'aulaCheck'" style="cursor: pointer;">
            {{item?.titulo}}<br>
            <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{item?.descricao | replace}}</span>
          </label>
      </div>
      <br>
    </div>
      <div class="stepperButtons">
        <button class="btn btn-dark" matStepperPrevious>Anterior</button>
        &nbsp;&nbsp;
        <button class="btn btn-info" matStepperNext>Próximo</button>
      </div>
  </form>             
</mat-step> 
<mat-step [stepControl]="missa">
    <form>
      <ng-template matStepLabel>Culto ou Missa</ng-template>
<!-- SELECT OPTION -->
<div *ngFor="let item of missaSelecionavel">
  <label>{{item?.titulo}}</label>
  <select (change)="aMissaSomaSelecionavel($event,item?.posicao)" class="form-control">
    <option selected [value]="['zerar',item?.titulo]"> -- Nenhum -- </option>
    <optgroup *ngFor="let itemOpt of (item?.opcoes | cidadeFilter: cidade)">
     <option [translate]="item?.formando" [value]="[itemOpt?.valor,item?.titulo]" [title]="itemOpt?.descricao">{{itemOpt?.nome}}</option>
     <option disabled style="font-size: 0.8em;">{{itemOpt?.descricao | replace}} </option>
    </optgroup>
  </select>
<hr>
</div>
<!-- CHECKBOX FIXOS-->
<div *ngFor="let item of fixosMissa; let i = index" style="font-size:1.2em; padding:5px">
  <div class="custom-control custom-checkbox">
    <input class="custom-control-input" *ngFor="let vlr of (item?.valores | cidadeFilter: cidade)" [id]="i+'missaFixo'" type="checkbox" [required]="item?.formando" [checked]="item?.marcado" [disabled]="item?.marcado && tipoUsuario == 'Vendedor'" [value]="vlr?.valor" (change)="somarFixosMissa($event,'nao',item?.posicao)">
      <label class="custom-control-label" [for]="i+'missaFixo'" style="cursor: pointer;">
        {{item?.titulo}}<br>
        <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{item?.descricao | replace}}</span>
      </label>
  </div>
  <br>
</div>
<!-- CHECKBOX -->
<div *ngFor="let item of missaCheckbox; let i = index" style="font-size:1.2em; padding:5px">
  <div class="custom-control custom-checkbox">
    <input class="custom-control-input" *ngFor="let vlr of (item?.valores | cidadeFilter: cidade)" [id]="i+'missaCheck'" type="checkbox" [required]="item?.formando" [checked]="item?.marcado" [disabled]="item?.marcado && tipoUsuario == 'Vendedor'" [value]="vlr?.valor" (change)="aMissaSoma($event, 'nao',item?.posicao)">
      <label class="custom-control-label" [for]="i+'missaCheck'" style="cursor: pointer;">
        {{item?.titulo}}<br>
        <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{item?.descricao | replace}}</span>
      </label>
  </div>
  <br>
</div>
      <div class="stepperButtons">
          <button class="btn btn-dark" matStepperPrevious>Anterior</button>
          &nbsp;&nbsp;
          <button class="btn btn-info" matStepperNext>Próximo</button>
        </div>
    </form>             
  </mat-step>  
<mat-step [stepControl]="colacaoGrau">
    <form>
      <ng-template matStepLabel>Colação de Grau</ng-template>
<!-- SELECT OPTION -->
<div *ngFor="let item of colacaoSelecionavel">
  <label>{{item?.titulo}}</label>
  <select (change)="aColacaoSomaSelecionavel($event,item?.posicao)" class="form-control">
    <option selected [value]="['zerar',item?.titulo]"> -- Nenhum -- </option>
      <optgroup *ngFor="let itemOpt of (item?.opcoes | cidadeFilter: cidade)">
        <option [translate]="item?.formando" [value]="[itemOpt?.valor,item?.titulo]" [title]="itemOpt?.descricao">{{itemOpt?.nome}}</option>
        <option disabled style="font-size: 0.8em;">{{itemOpt?.descricao | replace}} </option>
      </optgroup>
  </select>
<hr>
</div>
<!-- CHECKBOX -->
<div *ngFor="let item of colacaoCheckbox; let i = index" style="font-size:1.2em; padding:5px">
  <div class="custom-control custom-checkbox">
    <input class="custom-control-input" *ngFor="let vlr of (item?.valores | cidadeFilter: cidade)" [id]="i+'colacaoCheck'" type="checkbox" [required]="item?.formando" [checked]="item?.marcado" [disabled]="item?.marcado && tipoUsuario == 'Vendedor'" [value]="vlr?.valor" (change)="aColacaoSoma($event, 'nao',item?.posicao)">
      <label class="custom-control-label" [for]="i+'colacaoCheck'" style="cursor: pointer;">
        {{item?.titulo}}<br>
        <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{item?.descricao | replace}}</span>
      </label>
  </div>
  <br>
</div>
       <div class="stepperButtons">
          <button class="btn btn-dark" matStepperPrevious>Anterior</button>
          &nbsp;&nbsp;
          <button class="btn btn-info" matStepperNext>Próximo</button>
        </div>
    </form>             
  </mat-step>
<mat-step [stepControl]="jantarDancante">
    <form>
      <ng-template matStepLabel>Jantar Dançante</ng-template>
        <label>Local Jantar Dançante</label>
        <select (change)="selecionarLocalJantar($event)" class="form-control" [ngStyle]="{'border-color': !localJantar ? '#dd443e' : '#3cb42c'}">
          <option disabled selected value> -- Escolher -- </option>
          <optgroup *ngFor="let local of listaEventos">
            <option [value]="local?._id">{{local?.nome}}</option>
            <option disabled style="font-size: 0.8em;">{{local?.descricao | replace}} </option>
          </optgroup>
        </select>
<!-- SELECT OPTION -->
<div *ngFor="let item of jantarSelecionavel">
  <label>{{item?.titulo}}</label>
  <select (change)="aJantarSomaSelecionavel($event,item?.posicao)" class="form-control">
    <option selected [value]="['zerar',item?.titulo]"> -- Nenhum -- </option>
        <optgroup *ngFor="let itemOpt of (item?.opcoes | cidadeFilter: cidade)">
        <option [translate]="item?.formando" [value]="[itemOpt?.valor,item?.titulo]" [title]="itemOpt?.descricao">{{itemOpt?.nome}}</option>
        <option disabled style="font-size: 0.8em;">{{itemOpt?.descricao | replace}} </option>
      </optgroup>
  </select>
<hr>
</div>
<!-- CHECKBOX FIXOS-->
<div *ngFor="let item of fixosJantar; let i = index" style="font-size:1.2em; padding:5px">
  <div class="custom-control custom-checkbox">
    <input class="custom-control-input" *ngFor="let vlr of (item?.valores | cidadeFilter: cidade)" [id]="i+'jantarFixo'" type="checkbox" [required]="item?.formando" [checked]="item?.marcado" [disabled]="item?.marcado && tipoUsuario == 'Vendedor'" [value]="vlr?.valor" (change)="somarFixosJantar($event,'nao',item?.posicao)">
      <label class="custom-control-label" [for]="i+'jantarFixo'" style="cursor: pointer;">
        {{item?.titulo}}<br>
        <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{item?.descricao | replace}}</span>
      </label>
  </div>
  <br>
</div>
<!-- CHECKBOX -->
<div *ngFor="let item of jantarCheckbox; let i = index" style="font-size:1.2em; padding:5px">
  <div class="custom-control custom-checkbox">
    <input class="custom-control-input" *ngFor="let vlr of (item?.valores | cidadeFilter: cidade)" [id]="i+'jantarCheck'" type="checkbox" [required]="item?.formando" [checked]="item?.marcado" [disabled]="item?.marcado && tipoUsuario == 'Vendedor'" [value]="vlr?.valor" (change)="aJantarSoma($event, 'nao',item?.posicao)">
      <label class="custom-control-label" [for]="i+'jantarCheck'" style="cursor: pointer;">
        {{item?.titulo}}<br>
        <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{item?.descricao | replace}}</span>
      </label>
  </div>
  <br>
</div>
      <div class="stepperButtons">
          <button class="btn btn-dark" matStepperPrevious>Anterior</button>
          &nbsp;&nbsp;
          <button class="btn btn-info" matStepperNext>Próximo</button>
        </div>
    </form>             
  </mat-step>
<mat-step [stepControl]="baileGala">
      <ng-template matStepLabel>Baile de Gala</ng-template>
      <!-- LOCAL DO BAILE -->
      <label>Local Baile de Gala</label>
      <select (change)="selecionarLocalBaile($event)" class="form-control" [disabled]="detalhes.get('diasEvento')?.value == 1">
        <option disabled selected value> -- Escolher -- </option>
        <option *ngFor="let local of listaEventos" [value]="local?._id">
          {{local?.nome}}
        </option>
        <option *ngIf="baileDeGala != ''" selected disabled>{{baileDeGala}}</option>
      </select>
    <br>
      <!-- ASSESSORIA CERIMONIAL -->
        <label>Assessoria e Cerimonial</label>
          <select (change)="selecionarAssessoria($event)" class="form-control">
            <option disabled selected value=""> -- Escolher -- </option>
            <option [value]="vlr?.valor" *ngFor="let vlr of (assessoria?.opcoes | cidadeFilter: cidade)">
                  {{vlr?.nome}}
              </option>
          </select>
          <br>
      <!-- INICIO: FINGER FOODS / BAR TEMATICO / PROLONGAMENTO -->    
          <label>Finger Foods</label>          
          <select (change)="somarFingerFoodsBaile($event,998)" class="form-control">
            <option disabled selected value> -- Escolher -- </option>
            <option value=0>Nenhum</option>
          <optgroup>
            <option value=1>1 Tipo</option>
            <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.finger?.descricao}}</option>
          </optgroup>
          <optgroup>
            <option value=2>2 Tipos</option>
            <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.finger?.descricao}}</option>
          </optgroup>
          <optgroup>
            <option value=3>3 Tipos</option>
            <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.finger?.descricao}}</option>
          </optgroup>
          <optgroup>
            <option value=4>4 Tipos</option>
            <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.finger?.descricao}}</option>
          </optgroup>
          <optgroup>
            <option value=5>5 Tipos</option>
            <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.finger?.descricao}}</option>
          </optgroup>
          <optgroup>
            <option value=6>6 Tipos</option>
            <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.finger?.descricao}}</option>
          </optgroup>
          <optgroup>
            <option value=7>7 Tipos</option>
            <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.finger?.descricao}}</option>
          </optgroup>
          <optgroup>
            <option value=8>8 Tipos</option>
            <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.finger?.descricao}}</option>
          </optgroup>
          <optgroup>
            <option value=9>9 Tipos</option>
            <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.finger?.descricao}}</option>
          </optgroup>
          <optgroup>
            <option value=10>10 Tipos</option>
            <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.finger?.descricao}}</option>
          </optgroup>
          </select>
          <br>
          <label>Bar Temático</label>          
            <select (change)="somarBarTematicoBaile($event,997)" class="form-control">
              <option disabled selected value> -- Escolher -- </option>
              <option value="0">Nenhum</option>
              <optgroup>
                <option value=1>1</option>
                <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.barTematico?.descricao}}</option>
              </optgroup>
              <optgroup>
                <option value=2>2</option>
                <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.barTematico?.descricao}}</option>
              </optgroup>
              <optgroup>
                <option value=3>3</option>
                <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.barTematico?.descricao}}</option>
              </optgroup>
              <optgroup>
                <option value=4>4</option>
                <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.barTematico?.descricao}}</option>
              </optgroup>
              <optgroup>
                <option value=5>5</option>
                <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.barTematico?.descricao}}</option>
              </optgroup>
              <optgroup>
                <option value=6>6</option>
                <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.barTematico?.descricao}}</option>
              </optgroup>
              <optgroup>
                <option value=7>7</option>
                <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.barTematico?.descricao}}</option>
              </optgroup>
              <optgroup>
                <option value=8>8</option>
                <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.barTematico?.descricao}}</option>
              </optgroup>
              <optgroup>
                <option value=9>9</option>
                <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.barTematico?.descricao}}</option>
              </optgroup>
              <optgroup>
                <option value=10>10</option>
                <option disabled style="font-size: 0.8em;">{{personalizadosBaile?.barTematico?.descricao}}</option>
              </optgroup>
          </select>
          <br>
            <label>Prolongamento do Evento (After)</label>          
            <select (change)="somarProlongamentoEventoBaile($event,999)" class="form-control">
              <option disabled selected value> -- Escolher -- </option>
              <option value="0">Nenhum</option>
                <option value="1">1 Hora</option>
                <option value="2">2 Horas</option>
                <option value="3">3 Horas</option>
                <option value="4">4 Horas</option>
                <option value="5">5 Horas</option>
                <option value="6">6 Horas</option>
            </select>
            <br>
      <!-- FIM: FINGER FOODS / BAR TEMATICO / PROLONGAMENTO -->
      <!-- SELECT OPTION -->
<div *ngFor="let item of baileGalaSelecionavel">
  <label>{{item?.titulo}}</label>
  <select (change)="aBaileSomaSelecionavel($event,item?.posicao)" class="form-control">
    <option selected [value]="['zerar',item?.titulo]"> -- Nenhum -- </option>
    <optgroup *ngFor="let itemOpt of (item?.opcoes | cidadeFilter: cidade)">
     <option [translate]="item?.formando" [value]="[itemOpt?.valor,item?.titulo]" [title]="itemOpt?.descricao">{{itemOpt?.nome}}</option>
     <option disabled style="font-size: 0.8em;">{{itemOpt?.descricao | replace}} </option>
    </optgroup>
  </select>
<hr>
</div>
<!-- INICIO: CAFÉ DA MANHÃ / CARRINHO DE CHOPP / BEBIDAS AFTER -->
<div style="font-size:1.2em; padding:5px">
  <div class="custom-control custom-checkbox">
    <input class="custom-control-input" id="cafeManha" type="checkbox" (change)="somarCafeManhaEventoBaile($event,999)">
      <label class="custom-control-label" for="cafeManha" style="cursor: pointer;">
        Café da Manhã<br>
        <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{personalizadosBaile?.cafeManha?.descricao}}</span>
      </label>
  </div>
  <br>
</div>
<div style="font-size:1.2em; padding:5px">
  <div class="custom-control custom-checkbox">
    <input class="custom-control-input" id="carroChopp" type="checkbox" (change)="somarCarrinhoChoppEventoBaile($event,999)">
      <label class="custom-control-label" for="carroChopp" style="cursor: pointer;">
        Carrinho de Chopp<br>
        <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{personalizadosBaile?.carrinhoChopp?.descricao}}Carrinho de Chopp</span>
      </label>
  </div>
  <br>
</div>
<div style="font-size:1.2em; padding:5px">
  <div class="custom-control custom-checkbox">
    <input class="custom-control-input" id="bebidasAfter" type="checkbox" (change)="somarBebidasAfterEventoBaile($event,999)">
      <label class="custom-control-label" for="bebidasAfter" style="cursor: pointer;">
        Bebidas Prolongamento<br>
        <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{personalizadosBaile?.bebidasAfter?.descricao}}</span>
      </label>
  </div>
  <br>
</div>
<!-- FIM: CAFÉ DA MANHÃ / CARRINHO DE CHOPP / BEBIDAS AFTER -->
<!-- CHECKBOX FIXOS-->
<div *ngFor="let item of fixosBaile; let i = index" style="font-size:1.2em; padding:5px">
  <div class="custom-control custom-checkbox">
    <input class="custom-control-input" *ngFor="let vlr of (item?.valores | cidadeFilter: cidade)" [id]="i+'baileFixo'" type="checkbox" [required]="item?.formando" [checked]="item?.marcado" [disabled]="item?.marcado && tipoUsuario == 'Vendedor'" [value]="vlr?.valor" (change)="somarFixosBaile($event,'nao',item?.posicao)">
      <label class="custom-control-label" [for]="i+'baileFixo'" style="cursor: pointer;">
        {{item?.titulo}}<br>
        <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{item?.descricao | replace}}</span>
      </label>
  </div>
  <br>
</div>
<!-- CHECKBOX -->
<div *ngFor="let item of baileGalaCheckbox; let i = index" style="font-size:1.2em; padding:5px">
  <div class="custom-control custom-checkbox">
    <input class="custom-control-input" *ngFor="let vlr of (item?.valores | cidadeFilter: cidade)" [id]="i+'baileCheck'" type="checkbox" [required]="item?.formando" [checked]="item?.marcado" [disabled]="item?.marcado && tipoUsuario == 'Vendedor'" [value]="vlr?.valor" (change)="aBaileGalaSoma($event, 'nao',item?.posicao)">
      <label class="custom-control-label" [for]="i+'baileCheck'" style="cursor: pointer;">
        {{item?.titulo}}<br>
        <span style="font-size:0.85em; color:rgb(98, 98, 98)">{{item?.descricao | replace}}</span>
      </label>
  </div>
  <br>
</div>
  <div class="stepperButtons">
    <button class="btn btn-dark" matStepperPrevious>Anterior</button>
    &nbsp;&nbsp;
    <button class="btn btn-info" matStepperNext>Próximo</button>
  </div>
  </mat-step>
  <mat-step [stepControl]="imagens">
      <ng-template matStepLabel>Imagens</ng-template>
      <div class="containerGaleria">
          <div class="img-container" *ngFor="let image of images; let i = index">
            <mat-selection-list (selectionChange)="addImgGaleria($event)">
                <mat-list-option [value]="image.url">Incluir</mat-list-option>
              </mat-selection-list>
              <img [src]="image.url" class="imagemGaleria" (click)="openModal(image.url, i)" />
          </div>
      </div>
      <div id="myModalOrcamento" class="modal">
          <span (click)="closeModal()" class="close">&times;</span>
          <div class="prev" (click)="prevImg()">&lt;</div>
          <img [src]="imagemSelecionada" class="imagemLightbox">
          <div class="next" (click)="nextImg()">&gt;</div>
        </div> 
      <hr>
          <div class="stepperButtons">
            <button class="btn btn-dark" matStepperPrevious>Anterior</button>
            &nbsp;&nbsp;
            <button class="btn btn-info" matStepperNext (click)="salvarOrcamento()">Próximo</button>
          </div> 
  </mat-step>
  <mat-step [stepControl]="financeiro">
    <ng-template matStepLabel>Financeiro</ng-template>
    <div class="print no-print" (click)="print()">
      <fa-icon [icon]="['fas', 'print']" size="2x"></fa-icon>
    </div>
    <!-- INICIO CABEÇALHO -->
    <div id="divToPrint">
    <div style="width: 100%; height: 300px; text-align: center; padding-top: 80px">
        <img src="../assets/img/icone.png" style="height:90px">
    </div>
    <br>
    <br> 
    <div style="padding-left:60px">Nº da Proposta: {{numeroProposta}}</div>
    <br>
    <div class="capaOrcamento">
      <div class="row">
        <div class="col" style="color:rgb(122, 37, 156); text-align: center">CURSO</div>
        <div class="col" style="color:rgb(122, 37, 156); text-align: center">TURMA</div>
        <div class="col" style="color:rgb(122, 37, 156); text-align: center">ANO</div>
        <div class="col" style="color:rgb(122, 37, 156); text-align: center">PERIODO</div>
        <div class="col" style="color:rgb(122, 37, 156); text-align: center">INSTITUIÇÃO</div>
      </div>
      <div class="row" *ngFor="let turma of arrayTurmas">
        <div class="col" style="text-align: center">{{ turma.curso }}</div>
        <div class="col" style="text-align: center">{{ turma.turma }}</div>
        <div class="col" style="text-align: center">{{ turma.anoTurma }}</div>
        <div class="col" style="text-align: center">{{ turma.periodoTurma }}</div>
        <div class="col" style="text-align: center">{{ turma.instituicao }}</div>
      </div>
    </div>
    <br>
    <div class="capaOrcamento">
        <div class="row">
            <div class="col" style="color:rgb(122, 37, 156)">Periodo</div>
            <div class="col" style="color:rgb(122, 37, 156)">Prestações</div>
            <div class="col" style="color:rgb(122, 37, 156)">Formandos</div>
            <div class="col" style="color:rgb(122, 37, 156)">Dias de Evento</div>
          </div>
          <div class="row">
            <div class="col">{{detalhes.get('dataInicio')?.value | date:'dd/MM/yyyy'}} a {{detalhes.get('dataFim')?.value  | date:'dd/MM/yyyy'}}</div>
            <div class="col">{{ prestacoes }}</div>
            <div class="col">{{ detalhes.get('qtdeFormandos')?.value }}</div>
            <div class="col">{{ detalhes.get('diasEvento')?.value }}</div>
          </div>
          <div class="row">
            <div class="col" style="color:rgb(122, 37, 156)">Local do Jantar Dançante</div>
            <div class="col" style="color:rgb(122, 37, 156)">Local do Baile de Gala</div>
            <div class="col" style="color:rgb(122, 37, 156)">Cidade</div>
          </div>
          <div class="row">
            <div class="col">{{localJantar}}</div>
            <div class="col">{{localBaile}}</div>
            <div class="col">{{inicio.get('cidadeEscolhida')?.value}}</div>
          </div>
      </div>
      <br>
      <div class="capaOrcamento">    
        <div style="color:rgb(122, 37, 156)">CONTATOS DA COMISSÃO</div>
        <div class="row" *ngFor="let comissao of arrayContatos">
          <div class="col col-4">{{comissao.nome}}</div>
          <div class="col col-4 telefoneComissaoPrint" style="text-align: center">{{comissao?.telefone.slice(0,10) + '-****'}}</div>
          <div class="col col-4 telefoneComissao" style="text-align: center">{{comissao?.telefone }}</div>
          <div class="col col-4" style="text-align: center">{{comissao.email}}</div>
        </div>
      </div>   
      <br>
      <br>
  <hr>
    <!-- FIM CABEÇALHO -->
    <img src="../../../assets/img/tag-pre-eventos.png" style="margin-left:10px; width: 100%">
    <div *ngFor="let array of arraySaudade; let odd = odd" style="padding-left:1.5em">
      <div class="row" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px;">
        <div class="col col-3">{{array.titulo}}</div>
        <div class="col">{{array?.descricao | replace}}</div>
        <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array.subTotal | currency:'BRL' }}</div>
        <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array.valor | currency:'BRL' }}</div>
      </div>
    </div>
<div class="row" style="margin-top: 10px">
  <div class="col no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'"><strong>Total: {{ aulaSaudadeCheckboxSoma + somaSaudadeSelecionavel | currency:'BRL' }}</strong></div>
</div>
<br>
<img src="../../../assets/img/tag-culto.png" style="margin-left:10px; width: 100%">
  <div *ngFor="let array of arrayMissa; let odd = odd" style="padding-left:1.5em">
    <div class="row" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px;">
      <div class="col col-3">{{array.titulo}}</div>
      <div class="col">{{array?.descricao | replace}}</div>
      <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array.subTotal | currency:'BRL' }}</div>
      <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array.valor | currency:'BRL'}}</div>
    </div>
  </div>
     <div class="row" style="margin-top: 10px">
    <div class="col no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'"><strong>Total: {{ missaCheckboxSoma + somaMissaSelecionavel | currency:'BRL' }}</strong></div>
  </div>
  <br>
  <img src="../../../assets/img/tag-colacao.png" style="margin-left:10px; width: 100%">
<div *ngFor="let array of arrayColacao; let odd = odd" style="padding-left:1.5em">
  <div class="row" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px;">
    <div class="col col-3">{{array.titulo}}</div>
    <div class="col">{{array?.descricao | replace}}</div>
    <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array.subTotal | currency:'BRL' }}</div>
    <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array.valor | currency:'BRL'}}</div>
  </div>
</div>
<div class="row" style="margin-top: 10px">
  <div class="col no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'"><strong>Total: {{ colacaoCheckboxSoma + somaColacaoSelecionavel | currency:'BRL' }}</strong></div>
</div>
<br>
<img src="../../../assets/img/tag-jantar.png" style="margin-left:10px; width: 100%">
<div *ngFor="let array of arrayJantar; let odd = odd" style="padding-left:1.5em">
  <div class="row" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px;">
    <div class="col col-3">{{array.titulo}}</div>
    <div class="col">{{array?.descricao | replace}}</div>
    <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array.subTotal | currency:'BRL' }}</div>
    <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'">{{array.valor | currency:'BRL'}}</div>
  </div>
</div>
<div class="row" style="margin-top: 10px">
    <div class="col no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'"><strong>Total: {{ jantarCheckboxSoma + somaJantarSelecionavel | currency:'BRL' }}</strong></div>
</div>
<br>
<img src="../../../assets/img/tag-baile.png" style="margin-left:10px; width: 100%">
<div *ngFor="let array of arrayBaile; let odd = odd" style="padding-left:1.5em">
  <div class="row" [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px;">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">{{array.titulo}}</div>
    <div class="col">{{array?.descricao | replace}}</div>
    <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'" [hidden]="array.subTotal == 0">{{array.subTotal | currency:'BRL' }}</div>
    <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'" [hidden]="array.valor == 0">{{array.valor | currency:'BRL'}}</div>
    <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'" [hidden]="array.subTotal != 0">Incluso</div>
    <div class="col col-2 no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'" [hidden]="array.valor != 0">Incluso</div>
  </div>
</div>
<div class="row" style="margin-top: 10px">
    <div class="col no-print" style="text-align: right" *ngIf="tipoUsuario == 'Administrador'"><strong>Total: {{ baileGalaCheckboxSoma + somaBaileSelecionavel + somaPersonalizadosFixos | currency:'BRL' }}</strong></div>
</div>
<br>
<img src="../../../assets/img/tag-assessoria.png" style="margin-left:10px; width: 100%">
<div>
  <div class="row" style="padding: 5px; padding-left:1.5em">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Assessoria e Cerimonial</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right"></div>
  </div>
  <div class="row" style="padding: 5px; padding-left:1.5em; background-color: rgb(240, 240, 240)">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Administrativo</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right"></div>
  </div>
  <div class="row" style="padding: 5px; padding-left:1.5em">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Juridico</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right"></div>
  </div>
  <div class="row" style="padding: 5px; padding-left:1.5em; background-color: rgb(240, 240, 240)">
    <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">Cobrança</div>
    <div class="col col-7" style="font-size: 0.85em"></div>
    <div class="col col-2" style="text-align: right"></div>
  </div>
</div>
<div class="row no-print" style="margin-top: 10px" *ngIf="tipoUsuario == 'Administrador'">
    <div class="col no-print" style="text-align: right"><strong>Total: {{ assessoriaCerimonial | currency:'BRL' }}</strong></div>
</div>
<img src="../../../assets/img/tag-foto-filmagem.png" style="margin-left:10px; width: 100%">
<br><br>
<div class="planoFotos">
  <div class="row"style="text-align:center;font-weight: bold;">
    <div class="col">
      Itens inclusos no projeto de formatura.
    </div>
  </div>
  <br>
  <div *ngFor="let foto of fotosPagas; let odd = odd">
    <div class="row"  [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px; padding-left:25px">
      <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">{{foto?.nome}}</div>
      <div class="col" style="font-size: 0.85em">{{foto?.descricao}}</div>
      <div class="col col-2 no-print" style="text-align: right">
        <span *ngIf="tipoUsuario == 'Administrador'">{{foto?.valor | currency:'BRL'}}</span>
      </div>
    </div>
  </div>
</div>
<br>
<div class="planoFotos">
  <div class="row"style="text-align:center;font-weight: bold;">
    <div class="col">
      O material fotográfico será comercializado em até 120 dias após o ultimo evento!<br> O formando não tem obrigatoriedade de compra.
    </div>
  </div>
  <br>
  <div *ngFor="let foto of fotosGratis; let odd = odd">
    <div class="row"  [style.background-color]="odd ? 'rgb(240, 240, 240)' : 'white'" style="padding: 5px; padding-left:25px">
      <div class="col col-3" style="font-size: 1em; color:rgb(84, 53, 112)">{{foto?.nome}}</div>
      <div class="col col-7" style="font-size: 0.85em">{{foto?.descricao}}</div>
      <div class="col col-2" style="text-align: right">
        <span>{{foto?.valor | currency:'BRL'}}</span>
      </div>
    </div>
  </div>
</div>
<div class="page-break"></div>
<!-- INICIO GALERIA -->
<div *ngFor="let img of imagesArray" style="text-align: center;">
  <img [src]="img.url" class="imagemOrcamento"/> 
</div>
<!-- FIM GALERIA -->
<div class="page-break"></div>
<div style="height: 4em;"></div>
<img src="../../../assets/img/tag-financeiro.png" style="margin-left:10px; width: 100%">
<br><br>
<div class="planoFinanceiro">
<span>{{prestacoes}}x de </span><span>{{ totalPorFormandosPorMes | currency:'BRL' }}</span>
</div>
<br>
<br>
<!-- INICIO RIFA -->
<div style="height: 4em;"></div>
<img src="../../../assets/img/tag-rifas.png" style="margin-left:10px; width: 100%">
<table class="table table-striped" style="padding-left: 25px">
    <thead>
      <tr style="text-align: center">
        <th></th>
        <th>POR SEMESTRE</th>
        <th>POR ANO</th>
    </tr>
    </thead>
    <tbody>
     <tr>
      <td>01 Rifa  (50 números, a R$ 10,00 cada) será reduzido da formatura o valor de:</td>
      <td style="text-align: center">{{ umaRifaSemestre | currency:'BRL' }}</td>
      <td style="text-align: center">{{ umaRifaAno | currency:'BRL' }}</td>
    </tr>
    <tr>
      <td>02 Rifa  (50 números, a R$ 10,00 cada) será reduzido da formatura o valor de:</td>
      <td style="text-align: center">{{ duasRifaSemestre | currency:'BRL' }}</td>
      <td style="text-align: center">{{ duasRifaAno | currency:'BRL' }}</td>
    </tr>
    <tr>
      <td>03 Rifa  (50 números, a R$ 10,00 cada) será reduzido da formatura o valor de:</td>
      <td style="text-align: center">{{ tresRifaSemestre | currency:'BRL' }}</td>
      <td style="text-align: center">{{ tresRifaAno | currency:'BRL' }}</td>
    </tr>
    </tbody>
  </table>
  <div class="row" style="text-align: center; padding:10px; background-color:#dee2e6">
      <div class="col col-12">
        <strong>ITENS RIFADOS FORNECIDOS PELA TRENDS FORMATURAS</strong>
      </div>
      <div class="col" style="text-align: center">
        <p>1º Prêmio uma Smartphone</p>
        <p>2º Prêmio um Notebook</p>
        <p>3º Prêmio um Netbook</p>
      </div>
  </div>
<!-- FIM RIFA -->
<hr>
<!-- INICIO CERVEJADA -->
<div style="height: 4em;"></div>
<img src="../../../assets/img/tag-cervejada.png" style="margin-left:10px; width: 100%">
<table class="table table-striped" style="padding-left: 25px">
    <thead>
      <tr style="text-align: center">
      <th></th>
      <th>QTDE. INGRESSOS X FORMANDOS</th>
      <th>TOTAL INGRESSOS</th>
      <th>LUCRO</th>
      <th>LUCRO TOTAL DA TURMA</th>
    </tr>
    </thead>
    <tbody>
      <tr>
        <td>01 Cervejada Por Ano</td>
        <td style="text-align: center">6</td>
        <td style="text-align: center">{{ cervejada6totalIngressos }}</td>
        <td style="text-align: center">{{ cervejada6lucro | currency:'BRL' }}</td>
        <td style="text-align: center">{{ cervejada6lucroTotalTurma | currency:'BRL' }}</td>
      </tr>
      <tr>
        <td>02 Cervejadas Por Ano</td>
        <td style="text-align: center">12</td>
        <td style="text-align: center">{{ cervejada12totalIngressos }}</td>
        <td style="text-align: center">{{ cervejada12lucro | currency:'BRL' }}</td>
        <td style="text-align: center">{{ cervejada12lucroTotalTurma | currency:'BRL' }}</td>
      </tr>
    </tbody>
  </table>
<!-- FIM CERVEJADA -->
<div class="page-break"></div>
<!-- INICIO PLANOS DE CONVITES -->
<img src="../../../assets/img/tag-convites.png" style="margin-left:10px; width: 100%">
  <br>
<table class="table tableConvites table-striped" style="font-size: 0.95em; line-height: 1em; padding-left: 25px">
  <thead>
    <tr>
      <td></td>
      <td class="bgVerde baile"><strong>PARA O BAILE 10</strong></td>
      <td class="bgVerde baile"><strong>PARA O BAILE 16</strong></td>
      <td class="bgVerde baile"><strong>PARA O BAILE 20</strong></td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="esquerda"><strong>PARA O JANTAR 8 CONVITES +&nbsp;&nbsp;</strong></td>
      <td class="bgVerde">{{ convite8jantar10baile | currency:'BRL'}}</td>
      <td class="bgVerde">{{ convite8jantar16baile | currency:'BRL'}}</td>
      <td class="bgVerde">{{ convite8jantar20baile | currency:'BRL'}}</td>
    </tr>
    <tr>
      <td class="esquerda">Total Por Formando</td>
      <td class="bgVerde">{{ convite8jantar10bailePorFormando | currency:'BRL'}}</td>
      <td class="bgVerde">{{ convite8jantar16bailePorFormando | currency:'BRL'}}</td>
      <td class="bgVerde">{{ convite8jantar20bailePorFormando | currency:'BRL'}}</td>
    </tr>
    <tr>
      <td class="esquerda">Valor da Parcela</td>
      <td class="bgVerde">{{ convite8jantar10baileVlrParcela | currency:'BRL'}}</td>
      <td class="bgVerde">{{ convite8jantar16baileVlrParcela | currency:'BRL'}}</td>
      <td class="bgVerde">{{ convite8jantar20baileVlrParcela | currency:'BRL'}}</td>
    </tr>
    </tbody>
  </table>
  <table class="table tableConvites table-striped" style="font-size: 0.95em; line-height: 1em; padding-left: 25px">
    <thead>
        <tr>
          <td></td>
          <td class="bgAzul baile"><strong>PARA O BAILE 10</strong></td>
          <td class="bgAzul baile"><strong>PARA O BAILE 16</strong></td>
          <td class="bgAzul baile"><strong>PARA O BAILE 20</strong></td>
        </tr>
      </thead>
      <tbody>
    <tr>
      <td class="esquerda"><strong>PARA O JANTAR 10 CONVITES +</strong></td>
      <td class="bgAzul">{{ convite10jantar10baile | currency:'BRL'}}</td>
      <td class="bgAzul">{{ convite10jantar16baile | currency:'BRL'}}</td>
      <td class="bgAzul">{{ convite10jantar20baile | currency:'BRL'}}</td>
    </tr>
    <tr>
      <td class="esquerda">Total Por Formando</td>
      <td class="bgAzul">{{ convite10jantar10bailePorFormando | currency:'BRL'}}</td>
      <td class="bgAzul">{{ convite10jantar16bailePorFormando | currency:'BRL'}}</td>
      <td class="bgAzul">{{ convite10jantar20bailePorFormando | currency:'BRL'}}</td>
    </tr>
    <tr>
      <td class="esquerda">Valor da Parcela</td>
      <td class="bgAzul">{{ convite10jantar10baileVlrParcela | currency:'BRL'}}</td>
      <td class="bgAzul">{{ convite10jantar16baileVlrParcela | currency:'BRL'}}</td>
      <td class="bgAzul">{{ convite10jantar20baileVlrParcela | currency:'BRL'}}</td>
    </tr>
  </tbody>
</table>
<table class="table tableConvites table-striped" style="font-size: 0.95em; line-height: 1em; padding-left: 25px">
  <thead>
      <tr>
        <td></td>
        <td class="bgAzulEscuro baile"><strong>PARA O BAILE 10</strong></td>
        <td class="bgAzulEscuro baile"><strong>PARA O BAILE 16</strong></td>
        <td class="bgAzulEscuro baile"><strong>PARA O BAILE 20</strong></td>
      </tr>
    </thead>
    <tbody>
    <tr>
      <td class="esquerda"><strong>PARA O JANTAR 16 CONVITES +</strong></td>
      <td class="bgAzulEscuro">{{ convite16jantar10baile | currency:'BRL'}}</td>
      <td class="bgAzulEscuro">{{ convite16jantar16baile | currency:'BRL'}}</td>
      <td class="bgAzulEscuro">{{ convite16jantar20baile | currency:'BRL'}}</td>
    </tr>
    <tr>
      <td class="esquerda">Total Por Formando</td>
      <td class="bgAzulEscuro">{{ convite16jantar10bailePorFormando | currency:'BRL'}}</td>
      <td class="bgAzulEscuro">{{ convite16jantar16bailePorFormando | currency:'BRL'}}</td>
      <td class="bgAzulEscuro">{{ convite16jantar20bailePorFormando | currency:'BRL'}}</td>
    </tr>
    <tr>
      <td class="esquerda">Valor da Parcela</td>
      <td class="bgAzulEscuro">{{ convite16jantar10baileVlrParcela | currency:'BRL'}}</td>
      <td class="bgAzulEscuro">{{ convite16jantar16baileVlrParcela | currency:'BRL'}}</td>
      <td class="bgAzulEscuro">{{ convite16jantar20baileVlrParcela | currency:'BRL'}}</td>
    </tr>
  </tbody>
</table>
<table class="table tableConvites table-striped" style="font-size: 0.95em; line-height: 1em; padding-left: 25px">
  <thead>
      <tr>
        <td></td>
        <td class="bgVermelho baile"><strong>PARA O BAILE 10</strong></td>
        <td class="bgVermelho baile"><strong>PARA O BAILE 16</strong></td>
        <td class="bgVermelho baile"><strong>PARA O BAILE 20</strong></td>
      </tr>
    </thead>
    <tbody>
    <tr>
      <td class="esquerda"><strong>PARA O JANTAR 20 CONVITES +</strong></td>
      <td class="bgVermelho">{{ convite20jantar10baile | currency:'BRL'}}</td>
      <td class="bgVermelho">{{ convite20jantar16baile | currency:'BRL'}}</td>
      <td class="bgVermelho">{{ convite20jantar20baile | currency:'BRL'}}</td>
    </tr>
    <tr>
      <td class="esquerda">Total Por Formando</td>
      <td class="bgVermelho">{{ convite20jantar10bailePorFormando | currency:'BRL'}}</td>
      <td class="bgVermelho">{{ convite20jantar16bailePorFormando | currency:'BRL'}}</td>
      <td class="bgVermelho">{{ convite20jantar20bailePorFormando | currency:'BRL'}}</td>
    </tr>
    <tr>
      <td class="esquerda">Valor da Parcela</td>
      <td class="bgVermelho">{{ convite20jantar10baileVlrParcela | currency:'BRL'}}</td>
      <td class="bgVermelho">{{ convite20jantar16baileVlrParcela | currency:'BRL'}}</td>
      <td class="bgVermelho">{{ convite20jantar20baileVlrParcela | currency:'BRL'}}</td>
    </tr>
  </tbody>
  </table>
<!-- FIM PLANOS DE CONVITES -->
<!-- INICIO PLANO DE RENDIMENTOS -->
<div style="height: 4em;"></div>
<img src="../../../assets/img/tag-rendimento.png" style="margin-left:10px; width: 100%">
<table class="table table-striped" style="padding-left: 25px">
    <thead>
      <tr style="text-align: center">
      <th></th>
      <th>MENSAL</th>
      <th>TOTAL</th>
    </tr>
    </thead>
    <tbody>
      <tr>
        <td>RENDIMENTO INDIVIDUAL</td>
        <td style="text-align: center">{{ rendimentoMensalIndividual | currency:'BRL' }}</td>
        <td style="text-align: center">{{ rendimentoTotalIndividual | currency:'BRL' }}</td>
      </tr>
      <tr>
        <td>RENDIMENTO DA TURMA</td>
        <td style="text-align: center">{{ rendimentoMensalTurma | currency:'BRL' }}</td>
        <td style="text-align: center">{{ rendimentoTotalTurma | currency:'BRL' }}</td>
      </tr>
      <tr>
        <td style="text-align: center">Valor corrigido com taxa estimada de:  {{ rendimentoVlrCorrigido | currency:'BRL' }}</td>
        <td colspan="2" style="text-align: center">RENDIMENTO TOTAL DA TURMA: {{ rendimentoTotal | currency:'BRL' }}</td>
      </tr>
      <tr style="background-color: rgb(233, 233, 233); font-weight: 700; text-align: center">
          <td colspan="3">Obs: Todo o dinheiro arrecado com os eventos será destinado a turma para usarem da forma que convém.</td>
        </tr>
    </tbody>
  </table>
<!-- FIM PLANO DE RENDIMENTOS -->
<hr>
<div class="page-break"></div>
<!-- INICIO DIFERENCIAIS -->
<img src="../../../assets/img/tag-diferenciais.png" style="margin-left:10px; width: 100%">
<table class="table table-striped" style="padding-left: 25px">
  <tbody>
    <tr>
      <td>Trends Rifa- Prêmios anuais para arrecadação em rifas. As rifas abrangem todas as turmas da Trends</td>
    </tr>
    <tr>
      <td>Trends On Line - Sistema de Informação Gerencial - exclusividade Trends Formaturas</td>
    </tr>
    <tr>
      <td>Bank Trends e Trends Express - Administração Financeira e atendimento personalizado</td>
    </tr>
    <tr>
      <td>Trends Idéias - Idéias Inovadoras nunca vistas ou realizadas no segmento de Formaturas</td>
    </tr>
    <tr>
       <td>Trends Criação - Engenheiros de Criação e Eventos Tematizados</td>
    </tr>
    <tr>
      <td>Atendimento Personalizado - Gerentes Executivos e Empresa Departamentalizada</td>
    </tr>
    <tr>
      <td>Fale com a Trends - contato@trendsformaturas.com.br</td>
    </tr>
    <tr>
      <td>Trends Qualidade - Padrão de Qualidade Trends Formaturas</td>
    </tr>
  </tbody>
  </table>
  <div style="height: 4em;"></div>
  <hr>
  <img src="../../../assets/img/tag-fotos.png" style="margin-left:10px; width: 100%">
  <table class="table table-striped" style="padding-left: 25px">
    <tbody>
      <tr>
        <td>Capacidade de Produção de 10.000 fotos/dia (prazo de entrega reduzido)</td>
      </tr>
      <tr>
        <td>Estrutura de estúdio fotográfico na Trends Formaturas (acompanhamento personalizado)</td>
      </tr>
      <tr>
        <td>Cobertura Fotográfica de todos os eventos oficiais da formatura e outros como churrascos, confratenizações, encontro dos formandos</td>
      </tr>
      <tr>
        <td>Foto tamanho 24 X 30 e preços menores dos que os praticados pela média do mercado do Paraná</td>
      </tr>
      <tr>
        <td>50% de desconto no valor da Produção Fotográfica dos convites</td>
      </tr>
      <tr>
        <td>O formando não terá a obrigação de comprar um minimo de fotos. Assim, cada formando pode comprar quantas fotos quiser</td>
      </tr>
      <tr>
        <td>Venda Pós e sem obrigatoriedade de compra</td>
      </tr>
      <tr>
        <td>Filmagem completa dos eventos da formatura, edição e produção de DVDs</td>
      </tr>
    </tbody>
    </table>
  </div>
<!-- FIM DIFERENCIAIS -->
  </mat-step>
</mat-horizontal-stepper>