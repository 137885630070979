<div class="topoLogo">
    <div>
        <img src="../assets/img/logo.png" style="height:90px">
    </div>
  </div>  
<div class="back" (click)="goBack()"><fa-icon [icon]="['fas', 'arrow-alt-circle-left']" size="2x"></fa-icon></div>
<!-- INICIO FILTROS -->
<div style="padding-left: 5.5em">
<mat-accordion>
<mat-expansion-panel (opened)="panelOpenState = true"
(closed)="panelOpenState = false">
<mat-expansion-panel-header>
<mat-panel-description> 
{{panelOpenState ? 'Esconder Filtros' : 'Mostrar Filtros'}}
</mat-panel-description>
</mat-expansion-panel-header>
<div class="row"> 
    <div class="col col-3">
        <label>Data Inicio</label>
        <input type="date" class="form-control" [(ngModel)]="dataComecoMes">
    </div>
    <div class="col col-2">
        <label>Data Fim</label>
        <input type="date" class="form-control" [(ngModel)]="dataHoje">
    </div>
    <div class="col col-1">
        <br>
        <button class="btn btn-info" (click)="filterByData(dataComecoMes, dataHoje)" [disabled]="!dataComecoMes || !dataHoje">
            <fa-icon [icon]="['fas', 'search']" size="1x"></fa-icon>
        </button>
    </div>
    <div class="col col-2">
        <label>Proposta</label>
        <input type="text" class="form-control" [(ngModel)]="proposta">
    </div>
    <div class="col col-1">
        <br>
        <button class="btn btn-info" (click)="filterByProposta(proposta)" [disabled]="!proposta">
                <fa-icon [icon]="['fas', 'search']" size="1x"></fa-icon>
        </button>
    </div>
    <div class="col col-2">
        <label>Instituição</label>
        <input type="text" class="form-control" [(ngModel)]="instituicao">
    </div>
    <div class="col col-1">
        <br>
        <button class="btn btn-info" (click)="filterByInstituicao(instituicao)" [disabled]="!instituicao">
                <fa-icon [icon]="['fas', 'search']" size="1x"></fa-icon>
        </button>
    </div>
</div>
<div class="row">
    <div class="col col-2">
        <label>Curso</label>
        <input type="text" class="form-control" [(ngModel)]="curso">
    </div>
    <div class="col col-1">
        <br>
        <button class="btn btn-info" (click)="filterByCurso(curso)" [disabled]="!curso">
            <fa-icon [icon]="['fas', 'search']" size="1x"></fa-icon>
        </button>
    </div>
    <div class="col col-2">
        <label>Contato</label>
        <input class="form-control" [(ngModel)]="contato">
    </div>
    <div class="col col-1">
        <br>
        <button class="btn btn-info" (click)="filterByContato(contato)" [disabled]="!contato">
                <fa-icon [icon]="['fas', 'search']" size="1x"></fa-icon>
        </button>
    </div>
    <div class="col col-2">
        <label>Cidade</label>
        <select class="form-control" [(ngModel)]="cidade">
            <option [value]="city.nome" *ngFor="let city of listaCidades">{{city.nome}}</option>
        </select>
    </div>
    <div class="col col-1">
        <br>
        <button class="btn btn-info" (click)="filterByCidade(cidade)" [disabled]="!cidade">
                <fa-icon [icon]="['fas', 'search']" size="1x"></fa-icon>
        </button>
    </div>
    <div class="col col-2">
        <label>Consultor</label>
        <input class="form-control" type="text" [(ngModel)]="consultor">
    </div>
    <div class="col col-1">
        <br>
        <button class="btn btn-info" (click)="filterByConsultor(consultor)" [disabled]="!consultor">
                <fa-icon [icon]="['fas', 'search']" size="1x"></fa-icon>
        </button>
    </div>
</div>
</mat-expansion-panel>
</mat-accordion>
</div>
<br>
<!-- FIM FILTROS -->
<div  style="padding-left: 5.5em">
<table class="table table-striped" style="font-size:0.7em; text-align:center">
    <tr>
        <th>Data</th>
        <th>Proposta</th>
        <th>Instituição</th>
        <th>Curso</th>
        <th>Ano</th>
        <th>Turma</th>
        <th>Contato</th>
        <th>Cidade</th>
        <th>Consultor</th>
        <th>Ver</th>
        <th>Turma</th>

    </tr>
    <tr *ngFor="let orca of orcamentos">
        <td>{{ orca.data | date:'dd/MM/yyyy'}}</td>
        <td>{{ orca.nProposta }}</td>
        <td>{{ orca.instituicao }}</td>
        <td>{{ orca.curso }}</td>
        <td>{{ orca.ano }}</td>
        <td>{{ orca.turma }}</td>
        <td>{{ orca.contatos[0].nome }}</td>
        <td>{{ orca.cidade }}</td>
        <td>{{ orca.consultor }}</td>
        <td>
            <button class="btn btn-success btn-sm" (click)="goTo(orca.tipo,orca._id)">
                <fa-icon [icon]="['fas', 'eye']" size="1x"></fa-icon>
            </button>
        </td>
        <td>
            <button class="btn btn-info btn-sm" (click)="goToTurma(orca.tipo,orca.turmaId)">
                <fa-icon [icon]="['fas', 'external-link-square-alt']" size="1x"></fa-icon>
            </button>
        </td>
    </tr>
</table>
<div style="height: 100px;"></div>
</div>