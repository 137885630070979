<div id="myModalDetailsFormados" class="modalDetails">
    <span (click)="closeModalDetails()" class="closeDetails">&times;</span>
        <div style="height: 10px"></div>
            <div class="filtroContainer">
                <div class="cadastroOption">
                    <h4>DETALHES TURMA ({{turmaSelecionadaDetails?.codigoTurma}})</h4>
                    <div>
                        <div class="row">
                            <div class="col col-3">
                                <label>Cidade</label> 
                                <select [(ngModel)]="cidadeTurmaSelecionado" class="form-control" disabled>
                                    <option *ngFor="let cidade of listaCidadesDetails" [value]="cidade.nome">{{cidade?.nome}}</option>
                                </select>
                            </div>
                            <div class="col col-2">
                                <label>Instituição</label>
                                <select [(ngModel)]="instituicaoTurmaSelecionado" class="form-control" disabled>
                                    <option *ngFor="let facul of listaInstituicoesDetails" [value]="facul.nome">{{facul?.nome}}</option>
                                </select>
                            </div>
                            <div class="col col-4">
                                <label>Curso</label>
                                <select [(ngModel)]="cursoTurmaSelecionado" class="form-control" disabled>
                                    <option *ngFor="let curso of listaCursosDetails" [value]="curso.nome">{{curso?.nome}}</option>
                                </select>
                            </div>
                            <div class="col col-3">
                                <label>Graduação</label>
                                <input type="text" class="form-control" [(ngModel)]="graduacaoTurmaSelecionado" readonly>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col col-2" *ngIf="!periodoEAD">
                                <label>Turno</label>
                                <select [(ngModel)]="periodoTurmaSelecionado" class="form-control" disabled>
                                    <option value="MATUTINO">MATUTINO</option>
                                    <option value="VESPERTINO">VESPERTINO</option>
                                    <option value="NOTURNO">NOTURNO</option>
                                    <option value="INTEGRAL">INTEGRAL</option>
                                </select>
                            </div>
                            <div class="col col-3">
                                <label>Data Inicio</label>
                                <input type="date" class="form-control" [(ngModel)]="dataInicioTurmaSelecionado" readonly>
                            </div>
                            <div class="col col-3">
                                <label>Data Fim</label>
                                <input type="date" class="form-control" [(ngModel)]="dataFimTurmaSelecionado" readonly>
                            </div>
                            <div class="col col-1">
                                <label>Ano</label>
                                <input type="text" class="form-control" [(ngModel)]="anoTurmaSelecionado" readonly>
                            </div>
                            <div class="col col-1">
                                <label>Duração</label>
                                <input type="text" class="form-control" [(ngModel)]="duracaoTurmaSelecionado" readonly>
                            </div>
                            <div class="col col-1">
                                <label>Turma</label>
                                <input type="text" class="form-control" [(ngModel)]="turmaSelecionadaDetails.turma" readonly>
                            </div>
                            <div class="col col-1">
                                <label>Sala <fa-icon [icon]="['fas', 'info-circle']" size="1x" style="cursor: pointer" #tooltip="matTooltip" matTooltipPosition="left" matTooltipHideDelay="100" [matTooltip]="'Salas Anteriores:\n'+salasTurma" [matTooltipClass]="'my-tooltip'"></fa-icon></label>
                                <input type="text" class="form-control" [(ngModel)]="turmaSelecionadaDetails.sala" readonly>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col col-1">
                                <label>Alunos <fa-icon [icon]="['fas', 'info-circle']" size="1x" style="cursor: pointer" #tooltip="matTooltip" matTooltipPosition="left" matTooltipHideDelay="100" [matTooltip]="'Alunos Anteriores:\n'+alunosHistorico" [matTooltipClass]="'my-tooltip'"></fa-icon></label>
                                <input type="number" class="form-control" [(ngModel)]="alunos" readonly>
                            </div>
                            <div class="col col-1">
                                <label>Periodo</label>
                                <input type="text" class="form-control" [(ngModel)]="periodoTurma" readonly>
                            </div>
                            <div class="col col-2">
                                <label>Situação</label>
                                <select [(ngModel)]="turmaSelecionadaDetails.situacao" class="form-control" disabled>
                                    <option value=""></option>
                                    <option value="ABERTO">ABERTO</option>
                                    <option value="FECHADO">FECHADO</option>
                                    <option value="NEGOCIAÇÃO">NEGOCIAÇÃO</option>
                                    <option value="PRÉ-CADASTRO">PRÉ-CADASTRO</option>
                                    <option value="ATENDIMENTO URGENTE">ATENDIMENTO URGENTE</option>
                                    <option value="FORMADO">FORMADO</option>
                                </select>
                            </div>
                            <div class="col col-3">
                                <label>Consultor</label>
                                <select [(ngModel)]="turmaSelecionadaDetails.consultor" class="form-control" disabled>
                                    <option *ngFor="let consultor of vendedores" [value]="consultor.nome">{{consultor.nome}}</option>
                                </select>
                            </div>
                            <div class="col col-2" *ngIf="tipoUsuario == 'Administrador'">
                                <label>Status</label>
                                <select [(ngModel)]="turmaSelecionadaDetails.check" class="form-control" disabled>
                                    <option [ngValue]=true>VERIFICADO</option>
                                    <option [ngValue]=false>NÃO VERIFICADO</option>
                                </select>
                            </div>
                        </div>
                         <hr>
                        <div class="row">
                            <div class="col col-12" style="text-align: center;">
                                <h4>Turma Ata</h4>
                            </div>
                            <div class="col col-5">
                                <label>Nome Comissão</label>
                                <input type="text" class="form-control" [(ngModel)]="nomeComissao" readonly>
                            </div>
                            <div class="col col-5">
                                <label>Razão Social</label>
                                <input type="text" class="form-control" [(ngModel)]="razaoSocialComissao" readonly>
                            </div>
                            <div class="col col-2">
                                <label>CNPJ</label>
                                <input type="text" class="form-control" [(ngModel)]="cnpjComissao" readonly>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col col-2">
                                <label>CEP</label>
                                <input type="text" class="form-control" [(ngModel)]="cepComissao" readonly>
                            </div>
                            <div class="col col-4">
                                <label>Endereço</label>
                                <input type="text" class="form-control" [(ngModel)]="enderecoComissao" readonly>
                            </div>
                            <div class="col col-2">
                                <label>Bairro</label>
                                <input type="text" class="form-control" [(ngModel)]="bairroComissao" readonly>
                            </div>
                            <div class="col col-3">
                                <label>Cidade</label>
                                <input type="text" class="form-control" [(ngModel)]="cidadeComissao" readonly>
                            </div>
                            <div class="col col-1">
                                <label>UF</label>
                                <select [(ngModel)]="ufComissao" class="form-control" disabled>
                                    <option value="AC">AC</option>
                                    <option value="AL">AL</option>
                                    <option value="AP">AP</option>
                                    <option value="AM">AM</option>
                                    <option value="BA">BA</option>
                                    <option value="CE">CE</option>
                                    <option value="DF">DF</option>
                                    <option value="ES">ES</option>
                                    <option value="GO">GO</option>
                                    <option value="MA">MA</option>
                                    <option value="MT">MT</option>
                                    <option value="MS">MS</option>
                                    <option value="MG">MG</option>
                                    <option value="PA">PA</option>
                                    <option value="PB">PB</option>
                                    <option value="PE">PE</option>
                                    <option value="PI">PI</option>
                                    <option value="PR">PR</option>
                                    <option value="RJ">RJ</option>
                                    <option value="RN">RN</option>
                                    <option value="RS">RS</option>
                                    <option value="RO">RO</option>
                                    <option value="RR">RR</option>
                                    <option value="SC">SC</option>
                                    <option value="SP">SP</option>
                                    <option value="SE">SE</option>
                                    <option value="TO">TO</option>
                                </select>
                            </div>
                        </div>
                    </div>
                <hr>
                <mat-accordion>
                    <mat-expansion-panel style="background-color: rgb(246, 246, 246)" (opened)="panelOpenState" (closed)="!panelOpenState">
                        <mat-expansion-panel-header>
                            <mat-panel-description> 
                            {{panelOpenState ? 'Esconder Dados da Comissão' : 'Mostrar Dados da Comissão'}}
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                    <div>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-4">
                            <label>Presidente</label>
                            <input type="text" class="form-control" [(ngModel)]="presidente" readonly>
                        </div>
                        <div class="col col-3">
                            <label>E-mail</label>
                            <input type="text" class="form-control" [(ngModel)]="presidenteEmail" readonly>
                        </div>
                        <div class="col col-2">
                            <label>CPF</label>
                            <input type="text" class="form-control" [(ngModel)]="presidenteCpf" readonly>
                        </div>
                        <div class="col col-2">
                            <label>RG</label>
                            <input type="text" class="form-control" [(ngModel)]="presidenteRg" readonly>
                        </div>
                        <div class="col col-1">
                            <label>Órgão</label>
                            <input type="text" class="form-control" [(ngModel)]="presidenteRgOrgao" readonly>
                        </div>
                    </div>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-6">
                            <label>Endereço</label>
                            <input type="text" class="form-control" [(ngModel)]="presidenteEndereco" readonly>
                        </div>
                        <div class="col col-2">
                            <label>Estado Civil</label>
                            <select class="form-control" [(ngModel)]="presidenteEstadoCivil" disabled>
                                <option value="SOLTEIRO">SOLTEIRO</option>
                                    <option value="CASADO">CASADO</option>
                                    <option value="DIVORCIADO">DIVORCIADO</option>
                                    <option value="VIÚVO">VIÚVO</option>
                                    <option value="SEPARADO">SEPARADO</option>
                                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Telefone</label>
                            <input type="text" class="form-control" [(ngModel)]="presidenteTelefone" readonly>
                        </div>
                        <div class="col col-2">
                            <label>Nacionalidade</label>
                            <input type="text" class="form-control" [(ngModel)]="presidenteNacionalidade" readonly>
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-4">
                            <label>Vice-Presidente</label>
                            <input type="text" class="form-control" [(ngModel)]="vicePresidente" readonly>
                        </div>
                        <div class="col col-3">
                            <label>E-mail</label>
                            <input type="text" class="form-control" [(ngModel)]="vicePresidenteEmail" readonly>
                        </div>
                        <div class="col col-2">
                            <label>CPF</label>
                            <input type="text" class="form-control" [(ngModel)]="vicePresidenteCpf" readonly>
                        </div>
                        <div class="col col-2">
                            <label>RG</label>
                            <input type="text" class="form-control" [(ngModel)]="vicePresidenteRg" readonly>
                        </div>
                        <div class="col col-1">
                            <label>Órgão</label>
                            <input type="text" class="form-control" [(ngModel)]="vicePresidenteRgOrgao" readonly>
                        </div>
                    </div>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-6">
                            <label>Endereço</label>
                            <input type="text" class="form-control" [(ngModel)]="vicePresidenteEndereco" readonly>
                        </div>
                        <div class="col col-2">
                            <label>Estado Civil</label>
                            <select class="form-control" [(ngModel)]="vicePresidenteEstadoCivil" disabled>
                                <option value="SOLTEIRO">SOLTEIRO</option>
                                    <option value="CASADO">CASADO</option>
                                    <option value="DIVORCIADO">DIVORCIADO</option>
                                    <option value="VIÚVO">VIÚVO</option>
                                    <option value="SEPARADO">SEPARADO</option>
                                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Telefone</label>
                            <input type="text" class="form-control" [(ngModel)]="vicePresidenteTelefone" readonly>
                        </div>
                        <div class="col col-2">
                            <label>Nacionalidade</label>
                            <input type="text" class="form-control" [(ngModel)]="vicePresidenteNacionalidade" readonly>
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-4">
                            <label>1º Presidente</label>
                            <input type="text" class="form-control" [(ngModel)]="primeiroSecretario" readonly>
                        </div>
                        <div class="col col-3">
                            <label>E-mail</label>
                            <input type="text" class="form-control" [(ngModel)]="primeiroSecretarioEmail" readonly>
                        </div>
                        <div class="col col-2">
                            <label>CPF</label>
                            <input type="text" class="form-control" [(ngModel)]="primeiroSecretarioCpf" readonly>
                        </div>
                        <div class="col col-2">
                            <label>RG</label>
                            <input type="text" class="form-control" [(ngModel)]="primeiroSecretarioRg" readonly>
                        </div>
                        <div class="col col-1">
                            <label>Órgão</label>
                            <input type="text" class="form-control" [(ngModel)]="primeiroSecretarioRgOrgao" readonly>
                        </div>
                    </div>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-6">
                            <label>Endereço</label>
                            <input type="text" class="form-control" [(ngModel)]="primeiroSecretarioEndereco" readonly>
                        </div>
                        <div class="col col-2">
                            <label>Estado Civil</label>
                            <select class="form-control" [(ngModel)]="primeiroSecretarioEstadoCivil" disabled>
                                <option value="SOLTEIRO">SOLTEIRO</option>
                                    <option value="CASADO">CASADO</option>
                                    <option value="DIVORCIADO">DIVORCIADO</option>
                                    <option value="VIÚVO">VIÚVO</option>
                                    <option value="SEPARADO">SEPARADO</option>
                                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Telefone</label>
                            <input type="text" class="form-control" [(ngModel)]="primeiroSecretarioTelefone" readonly>
                        </div>
                        <div class="col col-2">
                            <label>Nacionalidade</label>
                            <input type="text" class="form-control" [(ngModel)]="primeiroSecretarioNacionalidade" readonly>
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-4">
                            <label>2º Secretário</label>
                            <input type="text" class="form-control" [(ngModel)]="segundoSecretario" readonly>
                        </div>
                        <div class="col col-3">
                            <label>E-mail</label>
                            <input type="text" class="form-control" [(ngModel)]="segundoSecretarioEmail" readonly>
                        </div>
                        <div class="col col-2">
                            <label>CPF</label>
                            <input type="text" class="form-control" [(ngModel)]="segundoSecretarioCpf" readonly>
                        </div>
                        <div class="col col-2">
                            <label>RG</label>
                            <input type="text" class="form-control" [(ngModel)]="segundoSecretarioRg" readonly>
                        </div>
                        <div class="col col-1">
                            <label>Órgão</label>
                            <input type="text" class="form-control" [(ngModel)]="segundoSecretarioRgOrgao" readonly>
                        </div>
                    </div>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-6">
                            <label>Endereço</label>
                            <input type="text" class="form-control" [(ngModel)]="segundoSecretarioEndereco" readonly>
                        </div>
                        <div class="col col-2">
                            <label>Estado Civil</label>
                            <select class="form-control" [(ngModel)]="segundoSecretarioEstadoCivil" disabled>
                                <option value="SOLTEIRO">SOLTEIRO</option>
                                    <option value="CASADO">CASADO</option>
                                    <option value="DIVORCIADO">DIVORCIADO</option>
                                    <option value="VIÚVO">VIÚVO</option>
                                    <option value="SEPARADO">SEPARADO</option>
                                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Telefone</label>
                            <input type="text" class="form-control" [(ngModel)]="segundoSecretarioTelefone" readonly>
                        </div>
                        <div class="col col-2">
                            <label>Nacionalidade</label>
                            <input type="text" class="form-control" [(ngModel)]="segundoSecretarioNacionalidade" readonly>
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-4">
                            <label>3º Secretário</label>
                            <input type="text" class="form-control" [(ngModel)]="terceiroSecretario" readonly>
                        </div>
                        <div class="col col-3">
                            <label>E-mail</label>
                            <input type="text" class="form-control" [(ngModel)]="terceiroSecretarioEmail" readonly>
                        </div>
                        <div class="col col-2">
                            <label>CPF</label>
                            <input type="text" class="form-control" [(ngModel)]="terceiroSecretarioCpf" readonly>
                        </div>
                        <div class="col col-2">
                            <label>RG</label>
                            <input type="text" class="form-control" [(ngModel)]="terceiroSecretarioRg" readonly>
                        </div>
                        <div class="col col-1">
                            <label>Órgão</label>
                            <input type="text" class="form-control" [(ngModel)]="terceiroSecretarioRgOrgao" readonly>
                        </div>
                    </div>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-6">
                            <label>Endereço</label>
                            <input type="text" class="form-control" [(ngModel)]="terceiroSecretarioEndereco" readonly>
                        </div>
                        <div class="col col-2">
                            <label>Estado Civil</label>
                            <select class="form-control" [(ngModel)]="terceiroSecretarioEstadoCivil" disabled>
                                <option value="SOLTEIRO">SOLTEIRO</option>
                                    <option value="CASADO">CASADO</option>
                                    <option value="DIVORCIADO">DIVORCIADO</option>
                                    <option value="VIÚVO">VIÚVO</option>
                                    <option value="SEPARADO">SEPARADO</option>
                                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Telefone</label>
                            <input type="text" class="form-control" [(ngModel)]="terceiroSecretarioTelefone" readonly>
                        </div>
                        <div class="col col-2">
                            <label>Nacionalidade</label>
                            <input type="text" class="form-control" [(ngModel)]="terceiroSecretarioNacionalidade" readonly>
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-4">
                            <label>Tesoureiro</label>
                            <input type="text" class="form-control" [(ngModel)]="tesoureiro" readonly>
                        </div>
                        <div class="col col-3">
                            <label>E-mail</label>
                            <input type="text" class="form-control" [(ngModel)]="tesoureiroEmail" readonly>
                        </div>
                        <div class="col col-2">
                            <label>CPF</label>
                            <input type="text" class="form-control" [(ngModel)]="tesoureiroCpf" readonly>
                        </div>
                        <div class="col col-2">
                            <label>RG</label>
                            <input type="text" class="form-control" [(ngModel)]="tesoureiroRg" readonly>
                        </div>
                        <div class="col col-1">
                            <label>Órgão</label>
                            <input type="text" class="form-control" [(ngModel)]="tesoureiroRgOrgao" readonly>
                        </div>
                    </div>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-6">
                            <label>Endereço</label>
                            <input type="text" class="form-control" [(ngModel)]="tesoureiroEndereco" readonly>
                        </div>
                        <div class="col col-2">
                            <label>Estado Civil</label>
                            <select class="form-control" [(ngModel)]="tesoureiroEstadoCivil" disabled>
                                <option value="SOLTEIRO">SOLTEIRO</option>
                                    <option value="CASADO">CASADO</option>
                                    <option value="DIVORCIADO">DIVORCIADO</option>
                                    <option value="VIÚVO">VIÚVO</option>
                                    <option value="SEPARADO">SEPARADO</option>
                                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Telefone</label>
                            <input type="text" class="form-control" [(ngModel)]="tesoureiroTelefone" readonly>
                        </div>
                        <div class="col col-2">
                            <label>Nacionalidade</label>
                            <input type="text" class="form-control" [(ngModel)]="tesoureiroNacionalidade" readonly>
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-4">
                            <label>1º Conselheiro Fiscal</label>
                            <input type="text" class="form-control" [(ngModel)]="primeiroConsFiscal" readonly>
                        </div>
                        <div class="col col-3">
                            <label>E-mail</label>
                            <input type="text" class="form-control" [(ngModel)]="primeiroConsFiscalEmail" readonly>
                        </div>
                        <div class="col col-2">
                            <label>CPF</label>
                            <input type="text" class="form-control" [(ngModel)]="primeiroConsFiscalCpf" readonly>
                        </div>
                        <div class="col col-2">
                            <label>RG</label>
                            <input type="text" class="form-control" [(ngModel)]="primeiroConsFiscalRg" readonly>
                        </div>
                        <div class="col col-1">
                            <label>Órgão</label>
                            <input type="text" class="form-control" [(ngModel)]="primeiroConsFiscalRgOrgao" readonly>
                        </div>
                    </div>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-6">
                            <label>Endereço</label>
                            <input type="text" class="form-control" [(ngModel)]="primeiroConsFiscalEndereco" readonly>
                        </div>
                        <div class="col col-2">
                            <label>Estado Civil</label>
                            <select class="form-control" [(ngModel)]="primeiroConsFiscalEstadoCivil" disabled>
                                <option value="SOLTEIRO">SOLTEIRO</option>
                                    <option value="CASADO">CASADO</option>
                                    <option value="DIVORCIADO">DIVORCIADO</option>
                                    <option value="VIÚVO">VIÚVO</option>
                                    <option value="SEPARADO">SEPARADO</option>
                                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Telefone</label>
                            <input type="text" class="form-control" [(ngModel)]="primeiroConsFiscalTelefone" readonly>
                        </div>
                        <div class="col col-2">
                            <label>Nacionalidade</label>
                            <input type="text" class="form-control" [(ngModel)]="primeiroConsFiscalNacionalidade" readonly>
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-4">
                            <label>2º Conselheiro Fiscal</label>
                            <input type="text" class="form-control" [(ngModel)]="segundoConsFiscal" readonly>
                        </div>
                        <div class="col col-3">
                            <label>E-mail</label>
                            <input type="text" class="form-control" [(ngModel)]="segundoConsFiscalEmail" readonly>
                        </div>
                        <div class="col col-2">
                            <label>CPF</label>
                            <input type="text" class="form-control" [(ngModel)]="segundoConsFiscalCpf" readonly>
                        </div>
                        <div class="col col-2">
                            <label>RG</label>
                            <input type="text" class="form-control" [(ngModel)]="segundoConsFiscalRg" readonly>
                        </div>
                        <div class="col col-1">
                            <label>Órgão</label>
                            <input type="text" class="form-control" [(ngModel)]="segundoConsFiscalRgOrgao" readonly>
                        </div>
                    </div>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-6">
                            <label>Endereço</label>
                            <input type="text" class="form-control" [(ngModel)]="segundoConsFiscalEndereco" readonly>
                        </div>
                        <div class="col col-2">
                            <label>Estado Civil</label>
                            <select class="form-control" [(ngModel)]="segundoConsFiscalEstadoCivil" disabled>
                                <option value="SOLTEIRO">SOLTEIRO</option>
                                    <option value="CASADO">CASADO</option>
                                    <option value="DIVORCIADO">DIVORCIADO</option>
                                    <option value="VIÚVO">VIÚVO</option>
                                    <option value="SEPARADO">SEPARADO</option>
                                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Telefone</label>
                            <input type="text" class="form-control" [(ngModel)]="segundoConsFiscalTelefone" readonly>
                        </div>
                        <div class="col col-2">
                            <label>Nacionalidade</label>
                            <input type="text" class="form-control" [(ngModel)]="segundoConsFiscalNacionalidade" readonly>
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-4">
                            <label>3º Conselheiro Fiscal</label>
                            <input type="text" class="form-control" [(ngModel)]="terceiroConsFiscal" readonly>
                        </div>
                        <div class="col col-3">
                            <label>E-mail</label>
                            <input type="text" class="form-control" [(ngModel)]="terceiroConsFiscalEmail" readonly>
                        </div>
                        <div class="col col-2">
                            <label>CPF</label>
                            <input type="text" class="form-control" [(ngModel)]="terceiroConsFiscalCpf" readonly>
                        </div>
                        <div class="col col-2">
                            <label>RG</label>
                            <input type="text" class="form-control" [(ngModel)]="terceiroConsFiscalRg" readonly>
                        </div>
                        <div class="col col-1">
                            <label>Órgão</label>
                            <input type="text" class="form-control" [(ngModel)]="terceiroConsFiscalRgOrgao" readonly>
                        </div>
                    </div>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-6">
                            <label>Endereço</label>
                            <input type="text" class="form-control" [(ngModel)]="terceiroConsFiscalEndereco" readonly>
                        </div>
                        <div class="col col-2">
                            <label>Estado Civil</label>
                            <select class="form-control" [(ngModel)]="terceiroConsFiscalEstadoCivil" disabled>
                                <option value="SOLTEIRO">SOLTEIRO</option>
                                    <option value="CASADO">CASADO</option>
                                    <option value="DIVORCIADO">DIVORCIADO</option>
                                    <option value="VIÚVO">VIÚVO</option>
                                    <option value="SEPARADO">SEPARADO</option>
                                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Telefone</label>
                            <input type="text" class="form-control" [(ngModel)]="terceiroConsFiscalTelefone" readonly>
                        </div>
                        <div class="col col-2">
                            <label>Nacionalidade</label>
                            <input type="text" class="form-control" [(ngModel)]="terceiroConsFiscalNacionalidade" readonly>
                        </div>
                    </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <hr>
            <div style="text-align: center; margin:20px">
                <button type="submit" class="btn btn-info" (click)="closeModalDetails()">FECHAR</button>
        </div>
        <nav class="navbar" style="background-color: #6f42c1!important;">
            <div class="col col-10" style="color:white; font-size: 1.5em; font-weight: 500;">Relatórios</div>
        </nav>
        <div *ngIf="newRelatorio" style="padding:10px; margin-bottom:10px; border:solid 1px #4d3280">
            <div class="row">
                <div class="col">
                    <div class="card-header" style="background-color: #4d3280; color:white;">
                        <strong>Motivo:</strong>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label class="labelRelatorio">Assunto</label>
                            <select class="form-control" [(ngModel)]="assuntoRelatorio">
                                <option value selected></option>
                                <option *ngFor="let assunto of assuntos">{{assunto}}</option>
                            </select> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label class="labelRelatorio">Descrição</label>
                            <textarea class="form-control" [(ngModel)]="descricaoRelatorio"></textarea>  
                        </div>
                    </div>
                </div>
            <div class="col" [ngStyle]="{'pointer-events' : turmaSelecionadaDetails.situacao == 'FECHADO' ? 'none' : 'all'}" [hidden]="turmaSelecionadaDetails.situacao == 'FECHADO'">
                <div class="card-header" style="background-color: #4d3280; color:white;">
                    <strong>Novo Agendamento:</strong>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="labelRelatorio">Data</label>
                        <input type="date" class="form-control" [(ngModel)]="dataAgenda">   
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="labelRelatorio">Forma de Contato</label>
                        <select class="form-control" [(ngModel)]="assuntoAgenda">
                            <option value selected></option>
                            <option *ngFor="let assunto of assuntos">{{assunto}}</option>
                        </select>  
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label class="labelRelatorio">Descrição</label>
                        <textarea class="form-control" [(ngModel)]="descricaoAgenda"></textarea>  
                    </div>
                </div>
            </div>              
            </div>
        </div>
        <mat-expansion-panel *ngFor="let rel of relatorios">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ rel?.data | date: 'dd/MM/yyyy' }} - {{ rel?.hora }} - {{ rel?.usuario }} 
                </mat-panel-title>
                <mat-panel-description>
                    <h4>{{ rel?.assunto }}</h4>
                </mat-panel-description>
            </mat-expansion-panel-header>
                <p>{{ rel?.descricao }}</p>
        </mat-expansion-panel>
    <br>
    <br>
<!-- INICIO TABS - DETAILS TURMA -->
<mat-tab-group backgroundColor="primary" color="accent"  mat-align-tabs="center">
        <mat-tab label="Documentação">
            <ng-template matTabContent>
                    <div style="height: 10px;"></div>
                <div class="row" style="text-align:center; width: 90%; margin-left: 10px">
                    <div class="col">
                        <label>Documentos Para Contrato</label>
                    </div>
                </div>
                <div style="height: 10px;"></div>
                <div class="row" style="width:95%; margin-left: 10px">
                    <div class="col col-3" style="padding:2px; text-align: center;" *ngFor="let doc of documentos; let i = index">
                        <img [src]="doc.url" class="imagemGaleria" (click)="openModalDoc(doc.url, i)">
                    </div>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="Orçamentos">
            <ng-template matTabContent>
                <table class="table table-striped" style="text-align:center">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nº Proposta</th>
                        <th scope="col">Data</th>
                        <th scope="col">Turmas</th>
                        <th scope="col">Data Fim</th>
                        <th scope="col">Ver</th>
                        <th scope="col">Contrato</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let orca of orcamentos; let i = index" class="turmasSelect" >
                        <td>{{ i + 1 }}</td>
                        <td>{{orca?.nProposta}}</td>
                        <td>{{orca?.data | date:'dd/MM/yyyy'}}</td>
                        <td>{{orca?.arrayTurmas.length}}</td>
                        <td>{{orca?.dtFim | date:'dd/MM/yyyy'}}</td>
                        <td>
                            <button class="btn btn-info" (click)="detailOrcamento(orca?._id)">
                                <fa-icon [icon]="['fas', 'eye']" size="1x"></fa-icon>
                            </button>
                        </td>
                        <td>
                            <button *ngIf="temContrato == 'Sim' && orca?.contrato == 'Sim'" class="btn btn-success" [disabled]="temContrato == 'Sim'">
                                <fa-icon [icon]="['fas', 'copy']" size="1x"></fa-icon>
                            </button>
                            <button *ngIf="temContrato == 'Sim' && orca?.contrato != 'Sim' && orca?.contrato == 'Pendente'" class="btn btn-warning" [disabled]="temContrato == 'Sim'" [disabled]="temContrato == 'Sim'">
                                <fa-icon [icon]="['fas', 'copy']" size="1x"></fa-icon>
                            </button>
                            <button *ngIf="temContrato == 'Sim' && orca?.contrato != 'Sim' && orca?.contrato == 'Rejeitado'" class="btn btn-danger" [disabled]="temContrato == 'Sim'" [disabled]="temContrato == 'Sim'">
                                <fa-icon [icon]="['fas', 'copy']" size="1x"></fa-icon>
                            </button>
                        </td>
                        </tr>
                    </tbody>
                </table>
            </ng-template>
        </mat-tab>
        <mat-tab label="Contratos">
            <ng-template matTabContent>
                <table class="table table-striped" style="width: 90%;text-align: center">
                <thead>
                    <th>Data</th>
                    <th>Vendedor</th>
                    <th>Situação</th>
                    <th>Ver</th>
                    <th>Aprovar</th>
                    <th>Rejeitar</th>
                    <th>Excluir</th>
                </thead>
                <tbody *ngFor="let contrato of contratos">
                    <tr>
                        <td>{{contrato?.data | date:'dd/MM/yyyy'}}</td>
                        <td>{{contrato?.vendedor}}</td>
                        <td>{{contrato?.situacao}}</td>
                        <td>
                            <button class="btn btn-info btn-sm" (click)="detailContrato(contrato?._id)">
                                <fa-icon [icon]="['fas', 'eye']" size="1x"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
                </table>
            </ng-template>
    </mat-tab>
</mat-tab-group>
<div style="height: 100px;"></div>
        </div>
    </div>
</div>
    <div id="modalDocFormados" class="modalDoc">
        <span (click)="closeModalDoc()" class="close">&times;</span>
        <div class="prev" (click)="prevImg()">&lt;</div>
        <img [src]="imagemSelecionada" class="imagemLightbox">
        <div class="next" (click)="nextImg()">&gt;</div>
    </div>  
<div class="row topoLogo">
    <div class="col col-12">
        <a target="_self" routerLink="/home">
            <img src="../assets/img/logo-vibe-branca.png">
        </a>
    </div>
</div>
<div class="back" (click)="goBack()"><fa-icon [icon]="['fas', 'arrow-alt-circle-left']" size="2x"></fa-icon></div>
<div style="right:10px; top:60px; position: absolute">
    <button class="btn btn-light" (click)="todasTurmas()" style="font-size: 0.75em">
            <fa-icon [icon]="['fas', 'border-all']" size="1x"></fa-icon>&nbsp;&nbsp;TODAS TURMAS
    </button>
</div>
<!-- INICIO FILTROS -->
<div style="padding-left: 5em">
<mat-accordion>
<mat-expansion-panel (opened)="panelOpenStateFilter = true"
(closed)="panelOpenStateFilter = false">
<mat-expansion-panel-header>
<mat-panel-description> 
{{panelOpenStateFilter ? 'Esconder Filtros' : 'Mostrar Filtros'}}
</mat-panel-description>
</mat-expansion-panel-header>
<div class="row">
    <div class="col col-3">
        <label>Data Inicio</label>
        <input type="date" class="form-control" [(ngModel)]="dtInicio">
    </div>
    <div class="col col-3">
        <label>Data Fim</label>
        <input type="date" class="form-control" [(ngModel)]="dtFim">
    </div>
    <div class="col col-3">
        <label>Cidade</label>
        <select [(ngModel)]="cidadeFiltro" class="form-control">
            <option value="">SELECIONAR</option>
            <option [value]="city.nome" *ngFor="let city of listaCidades">{{city.nome}}</option>
        </select>
    </div>
    <div class="col col-3">
        <label>Graduação</label>
        <select [(ngModel)]="graduacaoFiltro" class="form-control">
            <option value="PRESENCIAL">PRESENCIAL</option>
            <option value="EAD">EAD</option>
            <option value="ENSINO TÉCNICO">ENSINO TÉCNICO</option>
            <option value="ENSINO MÉDIO">ENSINO MÉDIO</option>
            <option value="ENSINO FUNDAMENTAL">ENSINO FUNDAMENTAL</option>
        </select>
    </div>
</div>
<br>
<div class="row">
    <div class="col col-4">
        <label>Instituição</label>
        <input type="text" class="form-control" [(ngModel)]="instituicao" oninput="this.value = this.value.toUpperCase()">
    </div>
    <div class="col col-3">
        <label>Curso</label>
        <input type="text" class="form-control" [(ngModel)]="curso" oninput="this.value = this.value.toUpperCase()">
    </div>
    <div class="col col-4">
        <label>Consultor</label>
        <select [(ngModel)]="consultor" class="form-control">
            <option value=""></option>
            <option *ngFor="let consultor of vendedores" [value]="consultor.nome">{{consultor.nome}}</option>
        </select>
    </div>
    <div class="col col-1">
        <br>
        <button class="btn btn-info" (click)="buscarTurmas()">
            <fa-icon [icon]="['fas', 'search']" size="1x"></fa-icon>
        </button>
    </div>
</div>
</mat-expansion-panel>
</mat-accordion>
</div>
<br>
<!-- FIM FILTROS -->
<div class="containerTurmas">
    <div class="turmaBox" *ngFor="let turma of turmas;let i = index" [ngStyle]="{'background': turma?.situacao == 'PRÉ-CADASTRO' ? 'rgb(87, 148, 213)' : turma?.situacao == 'ABERTO' ? 'rgb(31, 206, 69)' : (turma?.situacao == 'FECHADO' && turma?.empresa == 'VIBE FORMATURAS') ? 'rgb(202, 58, 246)' : (turma?.situacao == 'FECHADO' && turma?.empresa != 'VIBE FORMATURAS') ? 'rgb(246, 58, 58)' : turma?.situacao == 'ATENDIMENTO URGENTE' ? 'rgb(246, 243, 58)' : 'rgb(253,148,72)'}">
      <div (click)="openModalDetails(turma?._id)">
        <div class="row">
                <div class="col">
                    <h5>{{turma?.cidade}} - {{turma?.instituicao}}</h5>
                </div>
                <div class="check">
                    <fa-icon [icon]="['fas', 'check-double']" size="1x" [hidden]="!turma?.check"></fa-icon>
                    <fa-icon [icon]="['far', 'square']" size="1x" [hidden]="turma?.check"></fa-icon>
                </div>
            </div>
            <div class="row">
                <div class="col" >
                    <p style="font-size: 0.9em;"><strong>{{turma?.curso}}</strong></p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h5>ANO</h5>
                    <p>{{turma?.ano}}</p>
                </div>
                <div class="col">
                    <h5>PERIODO</h5>
                    <p>{{turma?.periodoTurma}}</p>
                </div>
                <div class="col">
                    <h5>TURMA</h5>
                    <p>{{turma?.turma}}</p>
                </div>
                <div class="col">
                    <h5>SALA</h5>
                    <p>{{turma?.sala}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h5>TURNO</h5>
                    <p>{{turma?.periodo}}</p>
                </div>
                <div class="col">
                    <h5>ANO FIM</h5>
                    <p>{{turma?.dataFim | date:'yyyy'}}</p>
                </div>
                <div class="col">
                    <h5>CONSULTOR</h5>
                    <p>{{turma?.consultor.split(' ')[0]}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h5>SITUAÇÃO</h5>
                    <p [hidden]="turma?.situacao == 'ATENDIMENTO URGENTE'">{{turma?.situacao}}</p>
                    <p [hidden]="turma?.situacao != 'ATENDIMENTO URGENTE'">URGENTE</p>
                </div>
                <div class="col">
                    <h5>COMISSÃO</h5>
                    <p>{{turma?.nomeComissao}}</p>
                </div>
            </div>
        </div>
    </div>
</div>