import { Component, OnInit } from '@angular/core';
import { DataBaseService } from 'src/app/provider';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-minha-formatura',
  templateUrl: './minha-formatura.component.html',
  styleUrls: ['./minha-formatura.component.css']
})
export class MinhaFormaturaComponent implements OnInit {
orcamentos:any = [];
listaCidades:any = [];
listaCursos:any = [];
dataHoje:any = "";
dataComecoMes:any = "";
cidade:any = "";
instituicao:any = "";
curso:any = "";
panelOpenState:boolean = false;

  constructor(private location:Location, private db:DataBaseService, private _router:Router) {
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    let date = new Date();
    this.dataComecoMes = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];
   }

  ngOnInit() {
    this.db.getMinhaFormaturaByData(this.dataComecoMes,this.dataHoje).subscribe((data:any) => this.orcamentos = data.sort((a:any,b:any) => {
      if(a.data > b.data){return -1}
      if(a.data < b.data){return 1}
      if(a.hora > b.hora){return -1}
      if(a.hora < b.hora){return 1}
      return 1;
    }));
    this.db.getCidades().subscribe((data:any) => this.listaCidades = data);
    this.db.getCursos().subscribe((data:any) => this.listaCursos = data);
  }

  filterByData(dtInicio:any, dtFim:any){
    let inicio = (new Date(dtInicio)).toISOString().split('T')[0];
    let fim = (new Date(dtFim)).toISOString().split('T')[0];
    this.db.getMinhaFormaturaByData(inicio,fim).subscribe((data:any) => this.orcamentos = data.sort((a:any,b:any) => {
      if(a.data < b.data){return -1}
      if(a.data < b.data){return 1}
      if(a.hora > b.hora){return -1}
      if(a.hora < b.hora){return 1}
      return 1;
    }));
  }

  filterByInstituicao(key:any){
    this.db.getMinhaFormaturaByInstituicao(key).subscribe((data:any) => this.orcamentos = data.sort((a:any,b:any) => {
      if(a.data < b.data){return -1}
      if(a.data < b.data){return 1}
      if(a.hora > b.hora){return -1}
      if(a.hora < b.hora){return 1}
      return 1;
    }));
  }

  filterByCurso(key:any){
    this.db.getMinhaFormaturaByCurso(key).subscribe((data:any) => this.orcamentos = data.sort((a:any,b:any) => {
      if(a.data < b.data){return -1}
      if(a.data < b.data){return 1}
      if(a.hora > b.hora){return -1}
      if(a.hora < b.hora){return 1}
      return 1;
    }));
  }

  filterByCidade(key:any){
    this.db.getMinhaFormaturaByCidade(key).subscribe((data:any) => this.orcamentos = data.sort((a:any,b:any) => {
      if(a.data < b.data){return -1}
      if(a.data < b.data){return 1}
      if(a.hora > b.hora){return -1}
      if(a.hora < b.hora){return 1}
      return 1;
    }));
  }
 
  goTo(key:any){
    this._router.navigate(['/details-formatura', key])
  }

  goBack(){
    this.location.back();
   }

}
