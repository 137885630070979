import { Component, OnInit } from '@angular/core';
import { DataBaseService } from 'src/app/provider';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {
  dataHoje:any = "";
  logs:any = [];
  dtInicio:any = "";
  dtFim:any = "";
  descricaoLogs:any = "";

  constructor(public db:DataBaseService) { }

  ngOnInit(){
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0]; 
    let hjSplit = this.dataHoje.split('-');
    this.dtInicio = hjSplit[0]+'-'+hjSplit[1]+'-01';
    this.dtFim = this.dataHoje;
    this.db.getLogsFilter(this.dtInicio,this.dataHoje,'').subscribe((data:any) => {
      this.logs = data.sort((a:any,b:any) => {
        if(a.data < b.data){return -1}
        if(a.data > b.data){return 1}
        if(a.hora < b.hora){return 1}
        if(a.hora > b.hora){return -1}
        return 1;
      });
    })
  }

  getLogs(){
    this.db.getLogsFilter(this.dtInicio,this.dtFim,this.descricaoLogs).subscribe((data:any) => {
      this.logs = data.sort((a:any,b:any) => {
        if(a.data < b.data){return -1}
        if(a.data > b.data){return 1}
        if(a.hora < b.hora){return 1}
        if(a.hora > b.hora){return -1}
        return 1;
      });
    })
  }

  getAllLogs(){
    this.db.getLogs().subscribe((data:any) => {
      this.logs = data.sort((a:any,b:any) => {
        if(a.data < b.data){return -1}
        if(a.data > b.data){return 1}
        if(a.hora < b.hora){return 1}
        if(a.hora > b.hora){return -1}
        return 1;
      });
    })
  }

}
