import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'; 
import { DataBaseService } from 'src/app/provider';
import { AuthService } from 'src/app/auth.service';
import { Location } from '@angular/common';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-details-orcamento-infantil',
  templateUrl: './details-orcamento-infantil.component.html',
  styleUrls: ['./details-orcamento-infantil.component.css']
})

export class DetailsOrcamentoInfantilComponent implements OnInit {
orcamento:any;
arrayTurmas:any;
dtFim:any;
dataFim:any;
nProposta:any;
tipoUsuario:any;
key:any = "";
preEventosArray:any = [];
prodFotograficaArray:any = [];
formaturasArray:any = [];
fotosPagas:any = [];
fotosGratis:any = [];
assessoriasArray:any = [];

  constructor(public app:AppComponent ,private location:Location, public auth:AuthService, private route: ActivatedRoute, private db:DataBaseService) {
    this.key = this.route.snapshot.paramMap.get('key');
   }

  ngOnInit(){
    this.db.getOrcamentoById(this.key).subscribe((data:any) => {
      this.orcamento = data;
      this.arrayTurmas = this.orcamento.arrayTurmas;
      this.dtFim = this.orcamento.dtFim;
      let dtFimAlterado = ((new Date(this.dtFim)).toISOString().split('T')[0]);
      this.dataFim = dtFimAlterado.split('-')[0];
      this.nProposta = this.orcamento.nProposta;
      this.preEventosArray = data.preEventos.sort((a:any,b:any) => {if(a.tipo < b.tipo){return 1;}if(a.tipo > b.tipo){return -1;}if(a.posicao > b.posicao){return 1;}if(a.posicao < b.posicao){return -1;}return 1;});
      this.prodFotograficaArray = data.producaoFotografica.sort((a:any,b:any) => {if(a.tipo < b.tipo){return 1;}if(a.tipo > b.tipo){return -1;}if(a.posicao > b.posicao){return 1;}if(a.posicao < b.posicao){return -1;}return 1;});
      this.formaturasArray = data.formaturas.sort((a:any,b:any) => {if(a.tipo < b.tipo){return 1;}if(a.tipo > b.tipo){return -1;}if(a.posicao > b.posicao){return 1;}if(a.posicao < b.posicao){return -1;}return 1;});
       });
      let ctrl = 1;
      let fotosPagas:any = [];
      let fotosGratis:any = [];
      this.db.getFotos().subscribe((data:any) => { 
        data.forEach((item:any) => {
          if(item.cobra == true){
            fotosPagas.push(item)
          }else{
            fotosGratis.push(item)
          }
          if(data.length == ctrl){
            this.fotosPagas = fotosPagas.sort((a:any,b:any) => {
              if(a.posicao > b.posicao){return 1;}
              if(a.posicao < b.posicao){return -1;}
              return 1;
            });
            this.fotosGratis = fotosGratis.sort((a:any,b:any) => {
              if(a.posicao > b.posicao){return 1;}
              if(a.posicao < b.posicao){return -1;}
              return 1;
            });
          }else{
            ctrl++
          }
        })
      });
      this.tipoUsuario = this.auth.tipoUsuario;
      if(this.tipoUsuario == ""){
        this.tipoUsuario = localStorage.getItem('tipoUsuarioTrends')
      }
      this.app.closeMenu();
   }

  printOrcamento(){
    let turmas:any = "";
    let estrutura:any = "";
    this.arrayTurmas.forEach((item:any) => {
      estrutura = item.curso+"-"+item.turma+"-"+item.anoTurma+"-"+item.instituicao+"-";
      turmas = turmas+estrutura
    })
    document.title = turmas+this.dataFim+"-P"+this.nProposta;
    window.print();
    document.title = "Trends";
  }

  goBack(){
    this.app.showMenu();
    this.location.back();
   }
}
