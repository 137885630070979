<div class="topoLogo">
    <div>
        <img src="../assets/img/logo.png" style="height:90px">
    </div>
</div>  
<div class="margem">
    <div class="row">
        <div class="col col-3">
            <label>Data Inicio</label>
            <input type="date" class="form-control" [(ngModel)]="dtInicio">
        </div>
        <div class="col col-3">
            <label>Data Fim</label>
            <input type="date" class="form-control" [(ngModel)]="dtFim">
        </div>
        <div class="col col-3">
            <label>Descrição</label>
            <input type="text" class="form-control" [(ngModel)]="descricaoLogs">
        </div>
        <div class="col col-1">
            <br>
            <button class="btn btn-info" (click)="getLogs()" [disabled]="!dtInicio || !dtFim">
                <fa-icon [icon]="['fas', 'search']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-2">
            <br>
            <button class="btn btn-info" (click)="getAllLogs()">
                TODOS
            </button>
        </div>
    </div>
<br><br>
<table class="table table-striped" style="text-align: center;">
    <thead>
        <tr>
            <th>#</th>
            <th>Data</th>
            <th>Hora</th>
            <th>Usuário</th>
            <th>Menu</th>
            <th>Descrição</th>
        </tr>
    </thead>
    <tbody *ngFor="let log of logs; let i = index">
        <tr>
            <td>{{i+1}}</td>
            <td>{{ log?.data | date:'dd/MM/yyyy' }}</td>
            <td>{{ log?.hora }}</td>
            <td>{{ log?.usuario }}</td>
            <td>{{ log?.menu }}</td>
            <td>{{ log?.descricao | replace }}</td>
        </tr>
    </tbody>
</table>
</div>