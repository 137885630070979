import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { User } from '../../models/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: User = new User();

  constructor(public auth: AuthService, private route:Router) {}

  ngOnInit() {
    if (this.auth.isLoggedIn() == true){
      this.route.navigate(['home']);
    }
  }


}
