<div class="modalSenha" id="novaGaleria">
    <div class="box">
        <div class="row" style="text-align: center;">
            <div class="col">
                <h4>NOVA GALERIA</h4>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <input class="form-control" type="text" id="nomeGaleria" [(ngModel)]="nomeNovaGaleria">
            </div>
        </div>
        <br>
        <div class="row" style="text-align:center">
            <div class="col">
                <button class="btn btn-danger btn-block" (click)="closeNovaGaleria()">CANCELAR</button>
            </div>
            <div class="col">
                <button class="btn btn-success btn-block" (click)="newGallery(nomeNovaGaleria)">CRIAR GALERIA</button>
            </div>
        </div>
    </div>
</div>
<div class="modalSenha" id="senhaModalGaleria">
    <div class="box">
        <div class="row" style="text-align: center;">
            <div class="col">
                <h4>CONFIRME SUA SENHA</h4>
                <br>
                <p style="font-weight: bold;">Para continuar por favor digite sua senha de acesso</p>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <input class="form-control" type="password" [(ngModel)]="senhaAdm">
            </div>
        </div>
        <br>
        <div class="row" style="text-align:center">
            <div class="col">
                <button class="btn btn-danger btn-block" (click)="closeSenhaDeleteGaleria()">CANCELAR</button>
            </div>
            <div class="col">
                <button class="btn btn-success btn-block" (click)="deleteGallery()">CONFIRMAR</button>
            </div>
        </div>
    </div>
</div>
<div class="topoLogo" *ngIf="!grid">
    <div>
        <img src="../assets/img/logo.png" style="height:90px">
    </div>
    <div class="btnInner">
        <button class="btn btn-light" (click)="openNovaGaleria()" style="font-size: 0.75em">
            <fa-icon [icon]="['fas', 'plus']" size="1x"></fa-icon>&nbsp;&nbsp;ADD GALERIA
        </button>
    </div>
</div>  
<div id="menuGrid">
    <div style="padding-left:6em; padding-top:4em">
        <div *ngIf="galerias.length < 1" style="color:rgb(190, 190, 190); font-weight: 700; text-align: center">
            Não Galerias Cadastradas!
        </div>
        <div class="container-galerias">
            <div class="box-galeria" *ngFor="let gallery of galerias" (click)="abrirGaleria(gallery?.galeria,gallery?._id)">
                {{gallery?.galeria}}
            </div>
        </div>
    </div>
    <div style="height:6em"></div>
</div>
<div style="margin-left: 2.5em;" id="grid">
    <div class="topVoltar">
        <div class="row align-items-center" style="margin-right: 15px;">
            <div class="col col-2">
                <fa-icon [icon]="['fas', 'arrow-circle-left']" size="2x" (click)="closeGrid()"></fa-icon>
            </div>
            <div class="col" style="text-align: right;">
                <button class="btn btn-light" (click)="openSenhaDeleteGaleria()">EXCLUIR GALERIA</button>
            </div>
        </div>
    </div>
    <div class="containerGaleria">
        <div class="img-container" *ngFor="let image of images; let i = index" (mouseover)="delButton(i)" (mouseout)="delButton(i)">
            <div class="delButton" name="delButton" hidden="false" id="img{{i}}" [style.background]="db.tema+'b3'">
                <button class="btn btn-sm" (click)="delImage(image._id,image.url)"><strong>X</strong></button>
            </div>
            <video [src]="image.url" *ngIf="image.url.slice(-3) == 'mp4'" class="imagemGaleria" (click)="openModal(image.url, i)"></video>
            <img [src]="image.url" *ngIf="image.url.slice(-3) != 'mp4'" class="imagemGaleria" (click)="openModal(image.url, i)"/>
        </div>
        <div class="imagemGaleria" [style.background]="db.tema+'b3'" style="display:flex;justify-content: center;flex-direction: column;" >
            <label for="customFileImagem">
                <input id="customFileImagem" type="file" accept="image/jpg, image/jpeg" (change)="onChange($event)" multiple>
                ADICIONAR IMAGENS<br>
                <fa-icon [icon]="['fas', 'plus']" size="4x"></fa-icon>
            </label>
        </div>
    </div>
    <div *ngIf="images == ''" class="col" style="color:rgb(190, 190, 190); font-weight: 700; text-align: center;margin-top: 3em;">Não há Imagens Nessa Galeria!</div>
    <div style="height:6em"></div>
</div>
<div id="myModalGaleria" class="modalFotos">
    <span (click)="closeModal()" class="close">&times;</span>
    <div class="prev" (click)="prevImg()">&lt;</div>
    <video [src]="imagemSelecionada" *ngIf="video" class="imagemLightbox" autoplay="true" loop="true"></video>
    <img [src]="imagemSelecionada" *ngIf="foto" class="imagemLightbox">
    <div class="next" (click)="nextImg()">&gt;</div>
</div>   
