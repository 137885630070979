<div class="topoLogo">
    <div>
        <img src="../assets/img/logo.png" style="height:90px">
    </div>
</div>  
<div class="back" (click)="goBack()"><fa-icon [icon]="['fas', 'arrow-alt-circle-left']" size="2x"></fa-icon></div>
<!-- INICIO FILTROS -->
<div style="padding-left: 5.5em">
<mat-accordion>
<mat-expansion-panel (opened)="panelOpenState = true"
(closed)="panelOpenState = false">
<mat-expansion-panel-header>
<mat-panel-description> 
{{panelOpenState ? 'Esconder Filtros' : 'Mostrar Filtros'}}
</mat-panel-description>
</mat-expansion-panel-header>
    <div class="row"> 
        <div class="col col-3">
            <label>Data Inicio</label>
            <input type="date" class="form-control" [(ngModel)]="dataComecoMes">
        </div>
        <div class="col col-3">
            <label>Data Fim</label>
            <input type="date" class="form-control" [(ngModel)]="dataHoje">
        </div>
        <div class="col col-1">
            <br>
            <button class="btn btn-info" (click)="filterByData(dataComecoMes, dataHoje)" [disabled]="!dataComecoMes || !dataHoje">
                <fa-icon [icon]="['fas', 'search']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-4">
            <label>Cidade</label>
            <select class="form-control" [(ngModel)]="cidade">
                <option value="" selected></option>
                <option [value]="city.nome" *ngFor="let city of listaCidades">{{city.nome}}</option>
            </select>
        </div>
        <div class="col col-1">
            <br>
            <button class="btn btn-info" (click)="filterByCidade(cidade)" [disabled]="!cidade">
                    <fa-icon [icon]="['fas', 'search']" size="1x"></fa-icon>
            </button>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col col-3">
            <label>Instituição</label>
            <input type="text" class="form-control" [(ngModel)]="instituicao">
        </div>
        <div class="col col-1">
            <br>
            <button class="btn btn-info" (click)="filterByInstituicao(instituicao)" [disabled]="!instituicao">
                    <fa-icon [icon]="['fas', 'search']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-4">
            <label>Curso</label>
            <select class="form-control" [(ngModel)]="curso">
                <option value="" selected></option>
                <option [value]="cur.nome" *ngFor="let cur of listaCursos">{{cur.nome}}</option>
            </select>  
        </div>
        <div class="col col-1">
            <br>
            <button class="btn btn-info" (click)="filterByCurso(curso)" [disabled]="!curso">
                <fa-icon [icon]="['fas', 'search']" size="1x"></fa-icon>
            </button>
        </div>
        <div class="col col-3" style="text-align: center;">
            <br>
            <button class="btn btn-info btn-lg" (click)="ngOnInit()">
                TODOS
            </button>
        </div>
    </div>
</mat-expansion-panel>
</mat-accordion>
</div>
<br>
<!-- FIM FILTROS -->
<div  style="padding-left: 5.5em">
<table class="table table-striped">
    <tr>
        <th>Data</th>
        <th>Hora</th>
        <th>Visitante</th>
        <th>Email</th>
        <th>Celular</th>
        <th>Instituição</th>
        <th>Curso</th>
        <th>Ano</th>
        <th>Turma</th>
        <th>Cidade</th>
        <th>Ver</th>
    </tr>
    <tr *ngFor="let orca of orcamentos">
        <td>{{ orca?.data | date:'dd/MM/yyyy'}}</td>
        <td>{{ orca?.hora }}</td>
        <td>{{ orca?.nomeVisitante }}</td>
        <td>{{ orca?.emailVisitante }}</td>
        <td>{{ orca?.celularVisitante }}</td>
        <td>{{ orca?.instituicao }}</td>
        <td>{{ orca?.curso }}</td>
        <td>{{ orca?.ano }}</td>
        <td>{{ orca?.turma }}</td>
        <td>{{ orca?.cidade }}</td>
        <td>
            <button class="btn btn-outline-success" (click)="goTo(orca?._id)">
                <fa-icon [icon]="['fas', 'eye']" size="1x"></fa-icon>
            </button>
        </td>
    </tr>
</table>
<div style="height: 100px;"></div>
</div>