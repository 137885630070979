import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { DataBaseService } from '../../provider';
import { AuthService } from '../../auth.service';
import { Location } from '@angular/common';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-orcamento-colegio',
  templateUrl: './orcamento-colegio.component.html',
  styleUrls: ['./orcamento-colegio.component.css']
})

export class OrcamentoColegioComponent implements OnInit,OnDestroy {
  aulaSaudade:any;
  missa:any;
  colacaoGrau:any;
  jantarDancante:any;
  baileGala:any;
  imagens:any;
  financeiro:any; 
  proposta:any;
  assessoria:any;
  dataHoje:any;
  consultor:any;
  tipoUsuario:any;
  arrayTurmas:any = [];
  baileDeGala:any = "";
  contatosDasTurmas:any = [];
  cidadeCheck:boolean = false;
  instituicaoCheck:boolean = false;
  turmaCheck:boolean = false;
  cursoCheck:boolean = false;
  chooseJantar:boolean = false;
  chooseBaile:boolean = false;
  listaCidades:any = [];
  listaEventos:any = [];
  listaCursos:any = [];
  listaTurmas:any = [];
  listaInstituicoes:any = [];
  turmaSelecionada:any = [];
  turmaTurmaSelecionada:any = "";
  dtHojePuro:any = "";
  inicio: any;
  detalhes:any;
  public contactList: any;
  public contatos: any;
  images:any = [];
  indexImage:number = 0;
  imagemSelecionada:any;
  imagesArray:any = [];
  aulaSaudadeCheckbox:any;
  aulaSaudadeSelecionavel:any;
  missaCheckbox:any;
  missaSelecionavel:any;
  colacaoCheckbox:any;
  colacaoSelecionavel:any;
  jantarCheckbox:any;
  jantarSelecionavel:any;
  baileGalaCheckbox:any;
  baileGalaSelecionavel:any;
  fixosMissa:any;
  fixosJantar:any;
  fixosBaile:any;
  localJantarSelecionado:any;
  localBaileSelecionado:any;
  aulaSaudadeCheckboxSoma:number = 0;
  somaSaudadeSelecionavel:number = 0;
  missaCheckboxSoma:number = 0;
  somaMissaSelecionavel:number = 0;
  colacaoCheckboxSoma:number = 0;
  somaColacaoSelecionavel:number = 0;
  jantarCheckboxSoma:number = 0;
  somaJantarSelecionavel:number = 0;
  baileGalaCheckboxSoma:number = 0;
  somaBaileSelecionavel:number = 0;
  fingerFoods:number = 0;
  barTematico:number = 0;
  prolongamento:number = 0;
  cafeManha:number = 0;
  carrinhoChopp:number = 0;
  bebidasProlongamento:number = 0;
  arraySaudade:any = [];
  arrayMissa:any = [];
  arrayColacao:any = [];
  arrayJantar:any = [];
  arrayBaile:any = [];
  ultimoLocalBaile:any = "";
  ultimoLocalJantar:any = "";
  reajusteLocalJantar:any = "";
  reajusteLocalBaile:any = "";
  horaExtraLocalBaile:any = "";
  horaExtraLocalJantar:any = "";
  anoDatFim:any = "";
  cidade:any = "";
  formandos:number = 0;
  percentualAssessoria:number = 0.2;
  assessoriaCerimonial:number = 19990;
  somaPersonalizadosFixos:number = 0;
  numeroProposta:any = "";
  prestacoes:number = 0;
  localJantar:any = "";
  valorLocalJantar:number = 0;
  localBaile:any = "";
  valorLocalBaile:number = 0;
  arrayContatos:any = [];
  totalSomas:number = 0;
  totalPorFormandos:number = 0;
  totalPorFormandosPorMes:number = 0;
  umaRifaSemestre:number = 0;
  umaRifaAno:number = 0;
  duasRifaSemestre:number = 0;
  duasRifaAno:number = 0;
  tresRifaSemestre:number = 0;
  tresRifaAno:number = 0;
  cervejada6totalIngressos:number = 0;
  cervejada6lucro:number = 0;
  cervejada6lucroTotalTurma:number = 0;
  cervejada12totalIngressos:number = 0;
  cervejada12lucro:number = 0;
  cervejada12lucroTotalTurma:number = 0;
  convite8jantar10baile:number = 0;
  convite8jantar10bailePorFormando:number = 0;
  convite8jantar10baileVlrParcela:number = 0;
  convite8jantar16baile:number = 0;
  convite8jantar16bailePorFormando:number = 0;
  convite8jantar16baileVlrParcela:number = 0;
  convite8jantar20baile:number = 0;
  convite8jantar20bailePorFormando:number = 0;
  convite8jantar20baileVlrParcela:number = 0;
  convite10jantar10baile:number = 0;
  convite10jantar10bailePorFormando:number = 0;
  convite10jantar10baileVlrParcela:number = 0;
  convite10jantar16baile:number = 0;
  convite10jantar16bailePorFormando:number = 0;
  convite10jantar16baileVlrParcela:number = 0;
  convite10jantar20baile:number = 0;
  convite10jantar20bailePorFormando:number = 0;
  convite10jantar20baileVlrParcela:number = 0;
  convite16jantar10baile:number = 0;
  convite16jantar10bailePorFormando:number = 0;
  convite16jantar10baileVlrParcela:number = 0;
  convite16jantar16baile:number = 0;
  convite16jantar16bailePorFormando:number = 0;
  convite16jantar16baileVlrParcela:number = 0;
  convite16jantar20baile:number = 0;
  convite16jantar20bailePorFormando:number = 0;
  convite16jantar20baileVlrParcela:number = 0;
  convite20jantar10baile:number = 0;
  convite20jantar10bailePorFormando:number = 0;
  convite20jantar10baileVlrParcela:number = 0;
  convite20jantar16baile:number = 0;
  convite20jantar16bailePorFormando:number = 0;
  convite20jantar16baileVlrParcela:number = 0;
  convite20jantar20baile:number = 0;
  convite20jantar20bailePorFormando:number = 0;
  convite20jantar20baileVlrParcela:number = 0;
  rendimentoMensalIndividual:number = 0;
  rendimentoTotalIndividual:number = 0;
  rendimentoMensalTurma:number = 0;
  rendimentoTotalTurma:number = 0;
  rendimentoVlrCorrigido:number = 0;
  rendimentoTotal:number = 0;
  hostessJantar:number = 0;
  hostessMissa:number = 0;
  segurancaJantar:number = 0;
  segurancaBaile:number = 0;
  ecadJantar:number = 0;
  ecadBaile:number = 0;
  block:boolean = false;
  fotosPagas:any = [];
  fotosGratis:any = [];
  totalFotos:number = 0;
  personalizadosBaile:any = [];
  tipoInstituicao:any = "";
  get contactFormGroup(){return this.detalhes.get('contacts') as FormArray;}

  constructor(public app:AppComponent, private location:Location, public auth:AuthService, private formBuilder: FormBuilder, private db: DataBaseService){
    this.consultor = auth.usuario; 
    if(this.consultor == ""){
      this.consultor = localStorage.getItem('usuarioTrends');
    }
    this.tipoUsuario = auth.tipoUsuario;
    if(this.tipoUsuario == ""){
      this.tipoUsuario = localStorage.getItem('tipoUsuarioTrends');
    }
   }

   ngOnDestroy(){
    this.contatosDasTurmas = [];
    this.block = false;
    this.numeroProposta = "";
    this.arrayTurmas = [];
    this.formandos = 0;
    this.localBaile = "";
    this.localJantar = "";
    this.prestacoes = 0;
    this.arrayContatos = [];
    this.arraySaudade = [];
    this.aulaSaudadeCheckboxSoma = 0;
    this.somaSaudadeSelecionavel = 0;
    this.tipoInstituicao = "";
    this.arrayMissa = [];
    this.missaCheckboxSoma = 0;
    this.somaMissaSelecionavel = 0;
    this.arrayColacao = [];
    this.colacaoCheckboxSoma = 0;
    this.somaColacaoSelecionavel = 0;
    this.arrayJantar = [];
    this.jantarCheckboxSoma = 0;
    this.somaJantarSelecionavel = 0;
    this.arrayBaile = [];
    this.baileGalaCheckboxSoma = 0;
    this.somaBaileSelecionavel = 0;
    this.somaPersonalizadosFixos = 0;
    this.assessoriaCerimonial = 19990;
    this.totalSomas = 0;
    this.totalPorFormandos = 0;
    this.totalPorFormandosPorMes = 0;
    this.imagesArray = [];
    this.umaRifaSemestre = 0;
    this.umaRifaAno = 0;
    this.duasRifaSemestre = 0;
    this.duasRifaAno = 0;
    this.tresRifaSemestre = 0;
    this.tresRifaAno = 0;
    this.cervejada6totalIngressos = 0;
    this.cervejada6lucro = 0;
    this.cervejada6lucroTotalTurma = 0;
    this.cervejada12totalIngressos = 0;
    this.cervejada12lucro = 0;
    this.cervejada12lucroTotalTurma = 0;
    this.convite8jantar10baile = 0;
    this.convite8jantar16baile = 0;
    this.convite8jantar20baile = 0;
    this.convite8jantar10bailePorFormando = 0;
    this.convite8jantar16bailePorFormando = 0;
    this.convite8jantar20bailePorFormando = 0;
    this.convite8jantar10baileVlrParcela = 0;
    this.convite8jantar16baileVlrParcela = 0;
    this.convite8jantar20baileVlrParcela = 0;
    this.convite10jantar10baile = 0;
    this.convite10jantar16baile = 0;
    this.convite10jantar20baile = 0;
    this.convite10jantar10bailePorFormando = 0;
    this.convite10jantar16bailePorFormando = 0;
    this.convite10jantar20bailePorFormando = 0;
    this.convite10jantar10baileVlrParcela = 0;
    this.convite10jantar16baileVlrParcela = 0;
    this.convite10jantar20baileVlrParcela = 0;
    this.convite16jantar10baile = 0;
    this.convite16jantar16baile = 0;
    this.convite16jantar20baile = 0;
    this.convite16jantar10bailePorFormando = 0;
    this.convite16jantar16bailePorFormando = 0;
    this.convite16jantar20bailePorFormando = 0;
    this.convite16jantar10baileVlrParcela = 0;
    this.convite16jantar16baileVlrParcela = 0;
    this.convite16jantar20baileVlrParcela = 0;
    this.convite20jantar10baile = 0;
    this.convite20jantar16baile = 0;
    this.convite20jantar20baile = 0;
    this.convite20jantar10bailePorFormando = 0;
    this.convite20jantar16bailePorFormando = 0;
    this.convite20jantar20bailePorFormando = 0;
    this.convite20jantar10baileVlrParcela = 0;
    this.convite20jantar16baileVlrParcela = 0;
    this.convite20jantar20baileVlrParcela = 0;
    this.rendimentoMensalIndividual = 0;
    this.rendimentoTotalIndividual = 0;
    this.rendimentoMensalTurma = 0;
    this.rendimentoTotalTurma = 0;
    this.rendimentoVlrCorrigido = 0;
    this.rendimentoTotal = 0;
    this.inicio.reset();
    this.detalhes.reset();
   }

  ngOnInit(){
    this.block = false;
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0]; 
    this.dtHojePuro = new Date(Date.now() - 10800000)
    this.db.getCidades().subscribe((data:any) => {this.listaCidades = data.sort((a:any,b:any) => {
      if(a.nome < b.nome){return -1}
      if(a.nome > b.nome){return 1}
      return 1;
    });});
    this.db.getAssessoria().subscribe((data:any) => this.assessoria = data[0]);
    this.db.getImagensByGaleria("Orçamento").subscribe((data:any) => {this.images = data;});
    this.db.getPersonalizadosBaileColegio().subscribe((data:any) => this.personalizadosBaile = data[0]);
    this.inicio = this.formBuilder.group({
      cidadeEscolhida : [null, Validators.required],
      instituicoes: [null, Validators.required], 
      curso: [null, Validators.required],
      turma: [null, Validators.required]
    });
    this.detalhes = this.formBuilder.group({
      qtdeFormandos : [null, [Validators.required, Validators.min(1)]],
      dataInicio: [null, Validators.required],
      dataFim: [null, Validators.required],
      diasEvento: [1, Validators.required],
      comissao: [false, Validators.requiredTrue],
      contacts: this.formBuilder.array([])
  });
    this.contactList = this.detalhes.get('contacts') as FormArray;
    this.detalhes.get('dataInicio')?.setValue(this.dtHojePuro);
    let ctrl = 1;
    let fotosPagas:any = [];
    let fotosGratis:any = [];
    this.db.getFotos().subscribe((data:any) => { 
      data.forEach((item:any) => {
        if(item.cobra == true){
          fotosPagas.push(item)
        }else{
          fotosGratis.push(item)
        }
        if(data.length == ctrl){
          this.fotosPagas = fotosPagas.sort((a:any,b:any) => {
            if(a.posicao > b.posicao){return 1;}
            if(a.posicao < b.posicao){return -1;}
            return 1;
          });
          this.fotosGratis = fotosGratis.sort((a:any,b:any) => {
            if(a.posicao > b.posicao){return 1;}
            if(a.posicao < b.posicao){return -1;}
            return 1;
          });
        }else{
          ctrl++
        }
      })
    });
}

  selecionarCidade(key:any){
    if(key != "SELECIONAR"){
      this.cidade = key;
      this.inicio.get('curso')?.setValue("");
      this.inicio.get('turma')?.setValue("");
      this.inicio.get('instituicoes')?.setValue("");
      this.arrayTurmas = [];
      this.listaInstituicoes = [];
      this.instituicaoCheck = false
      this.cursoCheck = false;
      this.db.getInstituicoesEscolaColegioByCidade(key).subscribe((data:any)=>{
      this.listaInstituicoes = data;
      console.log(this.listaInstituicoes)
      if(this.listaInstituicoes.length == 0){
          this.app.openAlert("Não há Colégios Cadastrados Nesta Cidade!");
        this.cidadeCheck = false;
      }else{
        this.cidadeCheck = true;
      }
    });
      this.db.getEventosByCidade(key).subscribe((data:any) => this.listaEventos = data);
    }else{
      this.arrayTurmas = [];
      this.listaInstituicoes = [];
      this.instituicaoCheck = false
      this.cursoCheck = false;
      this.listaTurmas = []; 
      this.turmaCheck = false;
      this.inicio.get('curso')?.setValue(null);
      this.inicio.get('turma')?.setValue(null);
      this.inicio.get('instituicoes')?.setValue(null);
    }
  }
  
  selecionarInstituicao(){
    this.inicio.get('curso')?.setValue(null);
    this.listaTurmas = []; 
    this.tipoInstituicao = "";
    let idx = this.listaInstituicoes.map((i:any) => {
      return i.nome;
    }).indexOf(this.inicio.get('instituicoes')?.value);
    if(idx >= 0){
      this.tipoInstituicao = this.listaInstituicoes[idx].tipo;
      console.log(this.tipoInstituicao)
      if(this.tipoInstituicao == "ESCOLA"){
        this.listaCursos = [{"nome":"1º ANO"},{"nome":"2º ANO"},{"nome":"3º ANO"},{"nome":"4º ANO"},{"nome":"5º ANO"}];
      }else{
        this.listaCursos = [{"nome":"9º ANO"},{"nome":"1º ANO"},{"nome":"2º ANO"},{"nome":"3º ANO"}];
      }
      this.instituicaoCheck = true;
    }else{
      this.instituicaoCheck = false;
    }
  }

  limparTurmas(){
    this.listaTurmas = [];
    this.inicio.get('curso')?.setValue(null);
  }

  selecionarCurso(key:any){
    this.db.getTurmasByCursoOrcamento(key,this.inicio.get('instituicoes')?.value,this.inicio.get('cidadeEscolhida')?.value).subscribe((data:any)=>{
      this.listaTurmas = data.sort((a:any,b:any) => {if(a.ano < b.ano){return -1}else{return 1}});
    if(this.listaTurmas.length == 0){
       this.app.openAlert("Não há Turmas Cadastradas Neste Curso!");
      this.cursoCheck = false;
    }else{
      this.cursoCheck = true;
    }})
  }

  turmaSelected(e:any, id:any){
    if(window.confirm("Deseja Adicionar Essa Turma ao Projeto?")){
      this.db.getTurmaById(id).subscribe((data:any)=>{
        this.turmaSelecionada = data;
        this.turmaTurmaSelecionada = this.turmaSelecionada.turma;
      //DESTACA A TURMA SELECIONADA
      if(e.srcElement.parentElement.className == "turmasSelect ng-star-inserted"){
        e.srcElement.parentElement.className = "turmasSelected ng-star-inserted";
      }
      //INSERE OS DADOS NO ARRAY TURMAS
      if(this.arrayTurmas == ""){
        this.arrayTurmas.push({
          turmaId : id,
          turma : this.turmaSelecionada.turma,
          curso: this.turmaSelecionada.curso,
          periodoTurma : this.turmaSelecionada.periodo,
          instituicao : this.turmaSelecionada.instituicao,
          anoTurma : this.turmaSelecionada.ano,
          nomeComissao : this.turmaSelecionada.nomeComissao,
        })
        this.detalhes.get('dataFim')?.setValue(this.turmaSelecionada.dataFim);
        this.detalhes.get('dataInicio')?.setValue(this.dataHoje);
        this.diferencaMeses(this.dtHojePuro, this.turmaSelecionada.dataFim);
        this.addContatoTurma(this.turmaSelecionada,id);
        }else{
        let idx = this.arrayTurmas.map((items:any) => {
          return items.turmaId
          }).indexOf(id); 
        if(idx >= 0){
           this.app.openAlert("Essa Turma Já Foi Selecionada!")
          return;
          }else{
            this.arrayTurmas.push({
              turmaId : id,
              turma : this.turmaSelecionada.turma,
              curso: this.turmaSelecionada.curso,
              periodoTurma : this.turmaSelecionada.periodo,
              instituicao : this.turmaSelecionada.instituicao,
              anoTurma : this.turmaSelecionada.ano,
              nomeComissao : this.turmaSelecionada.nomeComissao,
            });
            this.addContatoTurma(this.turmaSelecionada,id);
          }
       }
    this.inicio.get('turma')?.setValue(this.arrayTurmas);
      });
    }
  }

  addContatoTurma(turma:any,id:any){
    if(turma.presidente != null && turma.presidente != ""){
      this.contatosDasTurmas.push({
        nome:turma.presidente,
        telefone:turma.presidenteTelefone,
        email:turma.presidenteEmail,
        funcao:"Presidente",
        turmaId:id
      })
    };
    if(turma.vicePresidente != null && turma.vicePresidente != ""){
      this.contatosDasTurmas.push({
        nome:turma.vicePresidente,
        telefone:turma.vicePresidenteTelefone,
        email:turma.vicePresidenteEmail,
        funcao:"Vice Presidente",
        turmaId:id
      })
    };
    if(turma.primeiroSecretario != null && turma.primeiroSecretario != ""){
      this.contatosDasTurmas.push({
        nome:turma.primeiroSecretario,
        telefone:turma.primeiroSecretarioTelefone,
        email:turma.primeiroSecretarioEmail,
        funcao:"1º Secretário",
        turmaId:id
      })
    };
    if(turma.segundoSecretario != null && turma.segundoSecretario != ""){
      this.contatosDasTurmas.push({
        nome:turma.segundoSecretario,
        telefone:turma.segundoSecretarioTelefone,
        email:turma.segundoSecretarioEmail,
        funcao:"2º Secretário",
        turmaId:id
      })
    };
    if(turma.terceiroSecretario != null && turma.terceiroSecretario != ""){
      this.contatosDasTurmas.push({
        nome:turma.terceiroSecretario,
        telefone:turma.terceiroSecretarioTelefone,
        email:turma.terceiroSecretarioEmail,
        funcao:"3º Secretário",
        turmaId:id
      })
    };
    if(turma.tesoureiro != null && turma.tesoureiro != ""){
      this.contatosDasTurmas.push({
        nome:turma.tesoureiro,
        telefone:turma.tesoureiroTelefone,
        email:turma.tesoureiroEmail,
        funcao:"Tesoureiro",
        turmaId:id
      })
    };
    if(turma.primeiroConsFical != null && turma.primeiroConsFical != ""){
      this.contatosDasTurmas.push({
        nome:turma.primeiroConsFical,
        telefone:turma.primeiroConsFicalTelefone,
        email:turma.primeiroConsFicalEmail,
        funcao:"1º Conselheiro Fiscal",
        turmaId:id
      })
    };
    if(turma.segundoConsFical != null && turma.segundoConsFical != ""){
      this.contatosDasTurmas.push({
        nome:turma.segundoConFicals,
        telefone:turma.segundoConsFicalTelefone,
        email:turma.segundoConsFicalEmail,
        funcao:"2º Conselheiro Fiscal",
        turmaId:id
      })
    };
    if(turma.terceiroConsFiscal != null && turma.terceiroConsFiscal != ""){
      this.contatosDasTurmas.push({
        nome:turma.terceiroConsFiscal,
        telefone:turma.terceiroConsFiscalTelefone,
        email:turma.terceiroConsFiscalEmail,
        funcao:"3º Conselheiro Fiscal",
        turmaId:id
      })
    };
    this.contatosDasTurmas.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}return 1});
  }

  removeContatoTurma(id:any){
    if(this.contatosDasTurmas.length > 0){
      for(let i = 0; i < this.contatosDasTurmas.length; i++){
        if(this.contatosDasTurmas[i].turmaId == id){this.contatosDasTurmas.splice(i, 1);}
      }
    }
  }

  adicionarContatos(){
    if(this.contatosDasTurmas.length > 0){
      this.contatosDasTurmas.forEach((item:any) => {
        this.addContactTurma(item.nome,item.telefone,item.email)
      })
    }else{
      this.addContact();
      this.addContact();
      this.addContact();
    }
  }

removeTurma(id:any){
  if(window.confirm("Deseja Remover Essa Turma do Projeto?")){
  var removeIndex = this.arrayTurmas.map((item:any) => {
      return item.turmaId;   
     }).indexOf(id); 
     if(removeIndex >= 0){
      this.removeContatoTurma(id);
      this.arrayTurmas.splice(removeIndex, 1);
     } 
     this.inicio.get('turma')?.setValue(this.arrayTurmas);
  }
}

  diferencaMeses(dtInicio:any, dtFim:any){
    let dataFim = new Date(dtFim);
    let dataInicio = new Date(dtInicio);
    if(dtInicio < this.dataHoje){
       this.app.openAlert("A Data Inicio Não Pode Ser Menor do que a Data de Hoje!");
      this.detalhes.get('dataInicio')?.setValue(null);
      return;
    }
    if(dtFim < this.dataHoje){
       this.app.openAlert("A Data Fim Não Pode Ser Menor do que a Data de Hoje!");
      this.detalhes.get('dataFim')?.setValue(null);
      return;
    }
    var d1Y = dataInicio.getFullYear();
    var d2Y = dataFim.getFullYear();
    var d1M = dataInicio.getMonth();
    var d2M = dataFim.getMonth();  
    this.prestacoes = (d2M+12*d2Y)-(d1M+12*d1Y);
    this.anoDatFim = d2Y;
}

  selecionarDiaEvento(){
    let ultimoBaile = this.ultimoLocalBaile;
    let ultimoJantar = this.ultimoLocalJantar;
    let vlrItemJantarEcad;
    let vlrItemBaileEcad;
    let vlrItemJantar;
    let vlrItemBaile;
    if(this.localBaile != "" || this.localJantar != ""){
       var removeIndex = this.arrayBaile.map((item:any) => {
        return item.titulo;   
       }).indexOf(ultimoBaile); 
       if(removeIndex >= 0){
          vlrItemBaile = this.arrayBaile[removeIndex].valor;
          this.arrayBaile.splice(removeIndex, 1);
          this.baileGalaCheckboxSoma -= vlrItemBaile;
        }
      var removeIndexEcad = this.arrayBaile.map((item:any) => {
      return item.titulo;   
      }).indexOf("ECAD"); 
      if(removeIndexEcad >= 0){
        vlrItemBaileEcad = this.arrayBaile[removeIndex].valor;
        this.arrayBaile.splice(removeIndexEcad, 1);
        this.baileGalaCheckboxSoma -= vlrItemBaileEcad;
      }
       var removeIndexJantar = this.arrayJantar.map((item:any) => {
       return item.titulo;   
       }).indexOf(ultimoJantar); 
       if(removeIndexJantar >= 0){
        vlrItemJantar = this.arrayJantar[removeIndexJantar].valor;
        this.arrayJantar.splice(removeIndexJantar, 1);
        this.jantarCheckboxSoma -= vlrItemJantar;
       }
      var removeIndexEcadJantar = this.arrayJantar.map((item:any) => {
      return item.titulo;   
      }).indexOf("ECAD"); 
      if(removeIndexEcadJantar >= 0){
        vlrItemJantarEcad = this.arrayJantar[removeIndexEcadJantar].valor;
        this.arrayJantar.splice(removeIndexEcadJantar, 1);
        this.jantarCheckboxSoma -= vlrItemJantarEcad;
      }
     }
  }

  createContact(): FormGroup {
    return this.formBuilder.group({
      nome: [null, Validators.compose([Validators.required])],
      telefone: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required])]
    });
  }

  createContactTurma(nome:any,telefone:any,email:any): FormGroup {
    return this.formBuilder.group({
      nome: [nome, Validators.compose([Validators.required])],
      telefone: [telefone, Validators.compose([Validators.required])],
      email: [email, Validators.compose([Validators.required])]
    });
  }

  addContact() {
    this.contactList.push(this.createContact());
  }

  addContactTurma(nome:any,telefone:any,email:any) {
    this.contactList.push(this.createContactTurma(nome,telefone,email));
  }

  removeContact(index:any) {
    this.contactList.removeAt(index);
  }

  getContactsFormGroup(index:any): FormGroup {
    const formGroup = this.contactList.controls[index] as FormGroup;
    return formGroup;
  }

  salvarContatos() {
   if(this.detalhes.get('contacts').status == "INVALID"){
      this.app.openAlert("Preencha os Dados da Comissão Corretamente!")
   }else{
    this.detalhes.get('contacts').status;
    this.arrayContatos = [];
    this.arrayContatos = this.contactList.value;
    if(this.arrayContatos.length < 3){
      this.detalhes.get('comissao')?.setValue(false);
       this.app.openAlert("A Comissão Deve Ter no Minimo 3 Integrantes!");
    }else{
    this.detalhes.get('comissao')?.setValue(true);
    this.formandos = this.detalhes.get('qtdeFormandos')?.value;
    this.puxarPrecos();
    this.block = true;
     this.app.openAlert("Contatos Adicionados Com Sucesso!");
    }
  }
}

puxarPrecos(){
  this.db.getFotos().subscribe((data:any) => {
    data.forEach((item:any) => {
      if(item.cobra == true){
        this.totalFotos += item.valor
      }
    })
  })
   // DataBase Selecionavel e CheckBox
    this.db.getMissaByTipo("fixo").subscribe((data:any) => {
    this.fixosMissa = data;
    let cidadeMissa = this.inicio.get('cidadeEscolhida')?.value;
      this.fixosMissa.forEach((item:any) => {
        if(item.marcado == true){
          let idx = item.valores.map((p:any) => {
            return p.cidade;
          }).indexOf(cidadeMissa);
        let obj = [
          item.titulo, 
          this.replaceString(item.descricao), 
          item.valores[idx].valor,
          item.marcado,
          item.formando
        ];
        this.somarFixosMissa(obj,'marcado',item.posicao);
      }
      })
    });
    this.db.getMissaByTipo("checkbox").subscribe((data:any) => {
    this.missaCheckbox = data.sort((a:any,b:any) => {
      if(a.posicao > b.posicao){    return 1;}else{return -1}
    });
    let cidadeMissa = this.inicio.get('cidadeEscolhida')?.value;
    this.missaCheckbox.forEach((item:any) => {
      if(item.marcado == true){
        let idx = item.valores.map((p:any) => {
          return p.cidade;
        }).indexOf(cidadeMissa);
      let obj = [
        item.titulo, 
        this.replaceString(item.descricao), 
        item.valores[idx].valor,
        item.marcado,
        item.formando
      ];
      this.aMissaSoma(obj,'marcado',item.posicao);
    }
    })
  });
  this.db.getMissaByTipo("selecionavel").subscribe((data:any) => {
    this.missaSelecionavel = data.sort((a:any,b:any) => {
      if(a.posicao > b.posicao){    return 1;}else{return -1}
    });
  });

  // DataBase Colação de Grau Selecionavel e CheckBox
  this.db.getColacaoByTipo("selecionavel").subscribe((data:any) => {
  this.colacaoSelecionavel = data.sort((a:any,b:any) => {
    if(a.posicao > b.posicao){return 1;}else{return -1;}
  })
});

  this.db.getColacaoByTipo("checkbox").subscribe((data:any) => {
    this.colacaoCheckbox = data.sort((a:any,b:any) => {
      if(a.posicao > b.posicao){    return 1;}else{return -1}
    });
    let cidadeColacao = this.inicio.get('cidadeEscolhida')?.value;
    this.colacaoCheckbox.forEach((item:any) => {
      if(item.marcado == true){
        let idx = item.valores.map((p:any) => {
          return p.cidade;
        }).indexOf(cidadeColacao);
        if(idx >= 0){
          let obj = [
            item.titulo, 
             this.replaceString(item.descricao),  
            item.valores[idx].valor,
            item.marcado,
            item.formando
          ];
          this.aColacaoSoma(obj,'marcado',item.posicao);
        }
    }
    })
  });

// DataBase Aula Saudade Selecionavel e CheckBox  
this.db.getSaudadeByTipo("checkbox").subscribe((data:any) => {
  this.aulaSaudadeCheckbox = data.sort((a:any,b:any) => {
    if(a.posicao > b.posicao){return 1;}else{return -1;}
  });
  let cidadeSaudade = this.inicio.get('cidadeEscolhida')?.value;
  this.aulaSaudadeCheckbox.forEach((item:any) => {
    if(item.marcado == true){
      let idx = item.valores.map((p:any) => {
        return p.cidade;
      }).indexOf(cidadeSaudade);
      if(idx >= 0){
        let obj = [
          item.titulo, 
           this.replaceString(item.descricao),  
          item.valores[idx].valor,
          item.marcado,
          item.formando
        ];
        this.aSaudadeSoma(obj,'marcado',item.posicao);
      }
   }
  })
});
this.db.getSaudadeByTipo("selecionavel").subscribe((data:any) => {
  this.aulaSaudadeSelecionavel = data.sort((a:any,b:any) => {
    if(a.posicao > b.posicao){return 1;}else{return -1;}
  });
});

    // DataBase Jantar Dançante Selecionavel e CheckBox
    this.db.getJantarByTipo("checkbox").subscribe((data:any) => {
      this.jantarCheckbox = data.sort((a:any,b:any) => {
        if(a.posicao > b.posicao){return 1;}else{return -1;}
      });
      let cidadeJantar = this.inicio.get('cidadeEscolhida')?.value;
      this.jantarCheckbox.forEach((item:any) => {
        if(item.marcado == true){
          let idx = item.valores.map((p:any) => {
            return p.cidade;
          }).indexOf(cidadeJantar);
          if(idx >= 0){
            let obj = [
              item.titulo, 
               this.replaceString(item.descricao),  
              item.valores[idx].valor,
              item.marcado,
              item.formando
            ];
            this.aJantarSoma(obj,'marcado',item.posicao);
          }
      }
    })
  });

    this.db.getJantarByTipo("selecionavel").subscribe((data:any) => {
      this.jantarSelecionavel = data.sort((a:any,b:any) => {
        if(a.posicao > b.posicao){return 1;}else{return -1;}
      });
    });

    this.db.getJantarByTipo("fixo").subscribe((data:any) => {
    this.fixosJantar = data.sort((a:any,b:any) => {
      if(a.posicao > b.posicao){    return 1;}else{return -1}
    });
    let cidadeJantar = this.inicio.get('cidadeEscolhida')?.value;
      this.fixosJantar.forEach((item:any) => {
        if(item.marcado == true){
          let idx = item.valores.map((p:any) => {
            return p.cidade;
          }).indexOf(cidadeJantar);
        let obj = [
          item.titulo, 
           this.replaceString(item.descricao),  
          item.valores[idx].valor,
          item.marcado,
          item.formando
        ];
        this.somarFixosJantar(obj,'marcado',item.posicao);
      }
      })
    });
    
    // DataBase Baile de Gala Selecionavel e CheckBox
    this.db.getBaileByTipo("checkbox").subscribe((data:any) => {
      this.baileGalaCheckbox = data.sort((a:any,b:any) => {
        if(a.posicao > b.posicao){return 1;}else{return -1;}
      });
      let cidadeBaile = this.inicio.get('cidadeEscolhida')?.value;
      this.baileGalaCheckbox.forEach((item:any) => {
        if(item.marcado == true){
            let idx = item.valores.map((p:any) => {
              return p.cidade;
            }).indexOf(cidadeBaile);
            if(idx >= 0){
              let obj = [
                item.titulo, 
                 this.replaceString(item.descricao),  
                item.valores[idx].valor,
                item.marcado,
                item.formando
              ];
              this.aBaileGalaSoma(obj,'marcado',item.posicao);
            }
        }
      })
    });
    this.db.getBaileByTipo("fixo").subscribe((data:any) => {
      this.fixosBaile = data.sort((a:any,b:any) => {
        if(a.posicao > b.posicao){return 1;}else{return -1;}
      });
      let cidadeBaile = this.inicio.get('cidadeEscolhida')?.value;
        this.fixosBaile.forEach((item:any) => {
          if(item.marcado == true){
            let idx = item.valores.map((p:any) => {
              return p.cidade;
            }).indexOf(cidadeBaile);
          let obj = [
            item.titulo, 
             this.replaceString(item.descricao),  
            item.valores[idx].valor,
            item.marcado,
            item.formando
          ];
          this.somarFixosBaile(obj,'marcado',item.posicao);
        }
        })
      }); 
    this.db.getBaileByTipo("selecionavel").subscribe((data:any) => {
      this.baileGalaSelecionavel = data.sort((a:any,b:any) => {
        if(a.posicao > b.posicao){return 1;}else{return -1;}
      });
    });
  }

selecionarLocalJantar(e:any){
  this.db.getEventosById(e.srcElement.value).subscribe((data:any) => {
    this.localJantarSelecionado = data;
    let descricaoJantar = this.localJantarSelecionado.descricao;
    let vlrLocalJantar = this.localJantarSelecionado.valor;
    this.reajusteLocalJantar = this.localJantarSelecionado.reajusteAnual;
    this.horaExtraLocalJantar = this.localJantarSelecionado.horaExtra;
    this.localJantar = this.localJantarSelecionado.nome;;
    let ultimoBaile = this.ultimoLocalBaile;
    let vlrItemBaile;
    let vlrItemEcadBaile;
    let vlrItem;
    let vlrItemEcad;
    if(this.localBaile != ""){
      var removeIndex = this.arrayBaile.map((item:any) => {
        return item.titulo;   
      }).indexOf(ultimoBaile); 
      if(removeIndex >= 0){
        vlrItemBaile = this.arrayBaile[removeIndex].valor;
        this.arrayBaile.splice(removeIndex, 1);
        this.baileGalaCheckboxSoma -= vlrItemBaile;
      }
      var removeIndexEcad = this.arrayBaile.map((item:any) => {
      return item.titulo;   
      }).indexOf("ECAD"); 
      if(removeIndexEcad >= 0){
        vlrItemEcadBaile = this.arrayBaile[removeIndexEcad].valor;
        this.arrayBaile.splice(removeIndexEcad, 1);
        this.baileGalaCheckboxSoma -= vlrItemEcadBaile;
      }
    }    
    let anos = Math.round(this.prestacoes/12)
    let indice = Math.pow(1 + (this.reajusteLocalJantar/100),anos)
    this.valorLocalJantar = vlrLocalJantar * indice;
    let ecad = (this.valorLocalJantar / 100)*10;
    this.ecadJantar = ecad;
    let ultimoJantar = this.ultimoLocalJantar;
    let idx = this.arrayJantar.map((items:any) => {
      return items.titulo;
    }).indexOf(ultimoJantar)
    if(idx >= 0){
      var removeIndex = this.arrayJantar.map((item:any) =>{
        return item.titulo;   
      }).indexOf(ultimoJantar); 
      if(removeIndex >= 0){
        vlrItem = this.arrayJantar[removeIndex].valor;
        this.arrayJantar.splice(removeIndex, 1);
        this.jantarCheckboxSoma -= vlrItem;
      }
      var removeIndexEcad = this.arrayJantar.map((item:any) =>{
      return item.titulo;   
      }).indexOf("ECAD"); 
      if(removeIndexEcad >= 0){
        vlrItemEcad = this.arrayJantar[removeIndexEcad].valor;
        this.arrayJantar.splice(removeIndexEcad, 1);
        this.jantarCheckboxSoma -= vlrItemEcad;
      }
    }
    this.arrayJantar.push({
      titulo: this.localJantar,
      descricao: this.replaceString(descricaoJantar),
      subTotal: this.valorLocalJantar,
      valor: this.valorLocalJantar,
      tipo:"selecionavel",
      posicao:-1
    });
    this.jantarCheckboxSoma += this.valorLocalJantar;
    this.arrayJantar.push({
      titulo: "ECAD",
      descricao: "ECAD (Escritório Central de Arrecadação)  tem o objetivo cobranças sob a exibição de direitos autorais ligados a obras musicais A Lei Federal nº 5.988/73 e mantida pela atual Lei de Direitos Autorais brasileira – 9.610/98",
      subTotal: ecad,
      valor: ecad,
      tipo:"selecionavel",
      posicao:0
    });
    this.jantarCheckboxSoma += ecad;
    this.ultimoLocalJantar = this.localJantar;
    this.somarTotal();
    if(this.detalhes.get('diasEvento')?.value == 1){
      this.baileDeGala = this.localJantar;
      this.selecionarLocalBaile(e);
    }
  })
}

selecionarAssessoria(e:any){
  let valor = Number(e.srcElement.value);
  this.percentualAssessoria = valor/100;
}

selecionarLocalBaile(e:any){
  this.db.getEventosById(e.srcElement.value).subscribe((data:any) => {
    this.localBaileSelecionado = data;
    let descricaoBaile = this.localBaileSelecionado.descricao;
    this.valorLocalBaile = this.localBaileSelecionado.valor;
    this.reajusteLocalBaile = this.localBaileSelecionado.reajusteAnual;
    this.horaExtraLocalBaile = this.localBaileSelecionado.horaExtra;
    this.localBaile = this.localBaileSelecionado.nome;
  let anos = Math.round(this.prestacoes/12)
  let indice = Math.pow(1 + (this.reajusteLocalBaile/100),anos)
  this.valorLocalBaile = this.valorLocalBaile * indice;
  let vlr:number = 0;
  let ecad:number = (this.valorLocalBaile / 100)*10;
  this.ecadBaile = ecad;
  let vlrItem;
  let vlrItemEcad;
  let ultimoBaile = this.ultimoLocalBaile;
  let diasEvento = this.detalhes.get('diasEvento')?.value;
  if (diasEvento == 1 && this.localBaile == this.localJantar){
    vlr = 0;
    ecad = 0;
  } else{
    vlr = this.valorLocalBaile
    ecad = ecad
  }
  let idx = this.arrayBaile.map((items:any) => {
    return items.titulo;
  }).indexOf(ultimoBaile); 
  if(idx >= 0){
    var removeIndex = this.arrayBaile.map((item:any) => {
      return item.titulo;   
     }).indexOf(ultimoBaile); 
     if(removeIndex >= 0){
      vlrItem = this.arrayBaile[removeIndex].valor;
      this.arrayBaile.splice(removeIndex, 1);
      this.baileGalaCheckboxSoma -= vlrItem;
     }
    var removeIndexEcad = this.arrayBaile.map((item:any) => {
    return item.titulo;   
    }).indexOf("ECAD"); 
    if(removeIndexEcad >= 0){
      vlrItemEcad = this.arrayBaile[removeIndexEcad].valor;
      this.arrayBaile.splice(removeIndexEcad, 1);
      this.baileGalaCheckboxSoma -= vlrItemEcad;
    }
   }  
  this.arrayBaile.push({
    titulo: this.localBaile,
    descricao: this.replaceString(descricaoBaile),
    subTotal: vlr,
    valor: vlr,
    tipo:"selecionavel",
    posicao:-1
  });
  this.baileGalaCheckboxSoma += vlr;
  this.arrayBaile.push({
    titulo: "ECAD",
    descricao: "ECAD (Escritório Central de Arrecadação)  tem o objetivo cobranças sob a exibição de direitos autorais ligados a obras musicais A Lei Federal nº 5.988/73 e mantida pela atual Lei de Direitos Autorais brasileira – 9.610/98",
    subTotal: ecad,
    valor: ecad,
    tipo:"selecionavel",
    posicao:0
  });
  this.baileGalaCheckboxSoma += ecad;
  this.ultimoLocalBaile = this.localBaile;
  this.somarTotal();
})
}

aSaudadeSoma(e:any, origem:any,pos:any){
  let tituloArray;
  let descricaoArray;
  let formandos = this.formandos;
  let valores;
  let marcado;
  let porFormando;
  let valor;
    if(origem == 'marcado'){
      tituloArray = e[0];
       descricaoArray = this.replaceString(e[1]);
      valores =  Number(e[2]);
      marcado = e[3];
      porFormando = e[4];
    }else{
      let innerText = e.srcElement.labels[0].innerText
      let pos = innerText.indexOf('\n');
      tituloArray = innerText.slice(0, pos);
      descricaoArray =  this.replaceString(innerText.slice(pos+1));
      valores = Number(e.srcElement.value);
      marcado = e.srcElement.checked;
      porFormando = e.srcElement.required;
    }
      if (porFormando == true){
          valor = valores * formandos;
          }else{
          valor = valores
          }
      if (marcado == true){
        this.aulaSaudadeCheckboxSoma += valor;
        this.arraySaudade.push({
          titulo: tituloArray,
          descricao: descricaoArray,
          subTotal: valores,
          valor: valor,
          tipo:"checkbox",
          posicao:pos
        });
        this.somarTotal();
        }else {
        this.aulaSaudadeCheckboxSoma -= valor;
        var removeIndex = this.arraySaudade.map((item:any) => {
          return item.titulo;   
        }).indexOf(tituloArray); 
          this.arraySaudade.splice(removeIndex, 1);
          this.somarTotal();
        }
}

aSaudadeSomaSelecionavel(e:any,pos:any){
let titulo = e.srcElement.value.split(',')[1];
let jaTem:any;
let valorTirar = 0;
let porFormando = e.srcElement.selectedOptions[0].translate;
let descricao = e.srcElement.selectedOptions[0].title;
let vlrTotal = 0;
let vlr = e.srcElement.value.split(',')[0]; 
if(e.srcElement.value.split(',')[0] == "zerar"){
if(this.arraySaudade == ""){
this.somarTotal();
  }else{
    this.arraySaudade.map((items:any) => {
      if(titulo == items.titulo){
        jaTem = true;
        valorTirar = items.valor;
      }
    });
    if(jaTem == true){
      this.somaSaudadeSelecionavel -= valorTirar;
      var removeIndex = this.arraySaudade.map((items:any) =>  {
        return items.titulo;   
      }).indexOf(titulo); 
      this.arraySaudade.splice(removeIndex, 1);
      this.somarTotal();
    }else{
      this.somarTotal();
     }
  }
  }else{
    vlr = Number(e.srcElement.value.split(',')[0]);
  if(porFormando){
    vlrTotal = ((vlr * this.formandos) * 8);
  }else{
    vlrTotal = vlr;
  }
  if(this.arraySaudade == ""){
    this.arraySaudade.push({
      titulo: titulo,
      descricao: descricao,
      subTotal: vlr,
      valor: vlrTotal,
      tipo:"selecionavel",
      posicao:pos
    })
    this.somaSaudadeSelecionavel += vlrTotal;
    this.somarTotal();
  }else{
    this.arraySaudade.map((items:any) => {
    if(titulo == items.titulo){
      jaTem = true;
      valorTirar = items.valor;
    }
  });
  if(jaTem == true){
    this.somaSaudadeSelecionavel -= valorTirar;
    this.somarTotal();
    var removeIndex = this.arraySaudade.map((items:any) =>  {
      return items.titulo;   
     }).indexOf(titulo); 
     this.arraySaudade.splice(removeIndex, 1);
     this.arraySaudade.push({
      titulo: titulo,
      descricao: descricao,
      subTotal: vlr,
      valor: vlrTotal,
      tipo:"selecionavel",
      posicao:pos
    })
    this.somaSaudadeSelecionavel += vlrTotal;
    this.somarTotal();
  }else{
    this.arraySaudade.push({
    titulo: titulo,
    descricao: descricao,
    subTotal: vlr,
    valor: vlrTotal,
    tipo:"selecionavel",
    posicao:pos
  })
  this.somaSaudadeSelecionavel += vlrTotal;
  this.somarTotal();
    }
  }
}
} 

somarFixosMissa(e:any, origem:any,posicao:any){
  let tituloArray;
  let descricaoArray;
  let formandos = this.formandos;
  let valores;
  let marcado;
    if(origem == 'marcado'){
      tituloArray = e[0];
       descricaoArray = this.replaceString(e[1]);
      valores =  Number(e[2]);
      marcado = e[3];
    }else{
      let innerText = e.srcElement.labels[0].innerText
      let pos = innerText.indexOf('\n');
      tituloArray = innerText.slice(0, pos);
      descricaoArray =  this.replaceString(innerText.slice(pos+1));
      valores = Number(e.srcElement.value);
      marcado = e.srcElement.checked;
    }
let qtde8 = formandos * 8;
if(tituloArray == "Hostess"){
  this.hostessMissa = (qtde8 / 150) * valores;
    if (marcado == true){
      this.missaCheckboxSoma += this.hostessMissa;
      this.somarTotal();
      this.arrayMissa.push({
        titulo: tituloArray,
        descricao: descricaoArray,
        subTotal: valores,
        valor: this.hostessMissa,
        tipo:"checkbox",
        posicao:posicao
      });
      }else {
      this.missaCheckboxSoma -= this.hostessMissa;
      this.somarTotal();
      var removeIndex = this.arrayMissa.map((item:any) => {
        return item.titulo;   
      }).indexOf(tituloArray); 
        this.arrayMissa.splice(removeIndex, 1);
      }
  }
}

aMissaSoma(e:any, origem:any,pos:any){
  let tituloArray;
  let descricaoArray;
  let formandos = this.formandos;
  let valores;
  let marcado;
  let porFormando;
  let valor;
    if(origem == 'marcado'){
      tituloArray = e[0];
       descricaoArray = this.replaceString(e[1]);
      valores =  Number(e[2]);
      marcado = e[3];
      porFormando = e[4];
    }else{
      let innerText = e.srcElement.labels[0].innerText
      let pos = innerText.indexOf('\n');
      tituloArray = innerText.slice(0, pos);
      descricaoArray =  this.replaceString(innerText.slice(pos+1));
      valores = Number(e.srcElement.value);
      marcado = e.srcElement.checked;
      porFormando = e.srcElement.required;
    }
      if (porFormando == true){
          valor = valores * formandos;
          }else{
          valor = valores
          }
      if (marcado == true){
        this.missaCheckboxSoma += valor;
        this.arrayMissa.push({
          titulo: tituloArray,
          descricao: descricaoArray,
          subTotal: valores,
          valor: valor,
          tipo:"checkbox",
          posicao:pos
        });
        this.somarTotal();
        }else{
        this.missaCheckboxSoma -= valor;
        var removeIndex = this.arrayMissa.map((item:any) => {
          return item.titulo;   
        }).indexOf(tituloArray); 
          this.arrayMissa.splice(removeIndex, 1);
          this.somarTotal();
        }
}

aMissaSomaSelecionavel(e:any,pos:any){
  let titulo = e.srcElement.value.split(',')[1];
  let descricao = e.srcElement.selectedOptions[0].title;
  let jaTem:any;
  let valorTirar = 0;
  let porFormando = e.srcElement.selectedOptions[0].translate;
  let vlrTotal = 0;
  let vlr = e.srcElement.value.split(',')[0]; 
  if(e.srcElement.value.split(',')[0] == "zerar"){
  if(this.arrayMissa == ""){
  this.somarTotal();
    }else{
      this.arrayMissa.map((items:any) => {
        if(titulo == items.titulo){
          jaTem = true;
          valorTirar = items.valor;
        }
      });
      if(jaTem == true){
        this.somaMissaSelecionavel -= valorTirar;
        var removeIndex = this.arrayMissa.map((items:any) =>  {
          return items.titulo;   
        }).indexOf(titulo); 
        this.arrayMissa.splice(removeIndex, 1);
        this.somarTotal();
      }else{
        this.somarTotal();
       }
    }
    }else{
      vlr = Number(e.srcElement.value.split(',')[0]);
    if (porFormando){
      vlrTotal = (vlr * this.formandos) * 8;
    }else{
      vlrTotal = vlr;
    }
    if(this.arrayMissa == ""){
      this.arrayMissa.push({
        titulo: titulo,
        descricao: descricao,
        subTotal: vlr,
        valor: vlrTotal,
        tipo:"selecionavel",
        posicao:pos
      })
      this.somaMissaSelecionavel += vlrTotal;
      this.somarTotal();
    }else{
      this.arrayMissa.map((items:any) => {
      if(titulo == items.titulo){
        jaTem = true;
        valorTirar = items.valor;
      }
    });
    if(jaTem == true){
      this.somaMissaSelecionavel -= valorTirar;
      this.somarTotal();
      var removeIndex = this.arrayMissa.map((items:any) =>  {
        return items.titulo;   
       }).indexOf(titulo); 
       this.arrayMissa.splice(removeIndex, 1);
       this.arrayMissa.push({
        titulo: titulo,
        descricao: descricao,
        subTotal: vlr,
        valor: vlrTotal,
        tipo:"selecionavel",
        posicao:pos
      })
      this.somaMissaSelecionavel += vlrTotal;
      this.somarTotal();
    }else{
      this.arrayMissa.push({
      titulo: titulo,
      descricao: descricao,
      subTotal: vlr,
      valor: vlrTotal,
      tipo:"selecionavel",
      posicao:pos
    })
    this.somaMissaSelecionavel += vlrTotal;
    this.somarTotal();
      }
    }
  }
} 

aColacaoSoma(e:any, origem:any,pos:any){
  let tituloArray;
  let descricaoArray;
  let formandos = this.formandos;
  let valores;
  let marcado;
  let porFormando;
  let valor;
    if(origem == 'marcado'){
      tituloArray = e[0];
       descricaoArray = this.replaceString(e[1]);
      valores =  Number(e[2]);
      marcado = e[3];
      porFormando = e[4];
    }else{
      let innerText = e.srcElement.labels[0].innerText
      let pos = innerText.indexOf('\n');
      tituloArray = innerText.slice(0, pos);
      descricaoArray =  this.replaceString(innerText.slice(pos+1));
      valores = Number(e.srcElement.value);
      marcado = e.srcElement.checked;
      porFormando = e.srcElement.required;
    }
      if (porFormando == true){
          valor = valores * formandos;
          }else{
          valor = valores
          }
      if (marcado == true){
        this.colacaoCheckboxSoma += valor;
        this.arrayColacao.push({
          titulo: tituloArray,
          descricao: descricaoArray,
          subTotal: valores,
          valor: valor,
          tipo:"checkbox",
          posicao:pos
        });
        this.somarTotal();
        }else {
        this.colacaoCheckboxSoma -= valor;
        var removeIndex = this.arrayColacao.map((item:any) => {
          return item.titulo;   
        }).indexOf(tituloArray); 
          this.arrayColacao.splice(removeIndex, 1);
          this.somarTotal();
        }
}

aColacaoSomaSelecionavel(e:any,pos:any){
  let titulo = e.srcElement.value.split(',')[1];
  let descricao = e.srcElement.selectedOptions[0].title;
  let jaTem:any;
  let valorTirar = 0;
  let porFormando = e.srcElement.selectedOptions[0].translate;
  let vlrTotal = 0;
  let vlr = e.srcElement.value.split(',')[0]; 
  if(e.srcElement.value.split(',')[0] == "zerar"){
  if(this.arrayColacao == ""){
  this.somarTotal();
    }else{
      this.arrayColacao.map((items:any) => {
        if(titulo == items.titulo){
          jaTem = true;
          valorTirar = items.valor;
        }
      });
      if(jaTem == true){
        this.somaColacaoSelecionavel -= valorTirar;
        var removeIndex = this.arrayColacao.map((items:any) => {
          return items.titulo;   
        }).indexOf(titulo); 
        this.arrayColacao.splice(removeIndex, 1);
        this.somarTotal();
      }else{
        this.somarTotal();
       }
    }
    }else{
      vlr = Number(e.srcElement.value.split(',')[0]);
    if (porFormando){
      vlrTotal = (vlr * this.formandos) * 8;
    }else{
      vlrTotal = vlr;
    }
    if(this.arrayColacao == ""){
      this.arrayColacao.push({
        titulo: titulo,
        descricao: descricao,
        subTotal: vlr,
        valor: vlrTotal,
        tipo:"selecionavel",
        posicao:pos
      })
      this.somaColacaoSelecionavel += vlrTotal;
      this.somarTotal();
    }else{
      this.arrayColacao.map((items:any) => {
      if(titulo == items.titulo){
        jaTem = true;
        valorTirar = items.valor;
      }
    });
    if(jaTem == true){
      this.somaColacaoSelecionavel -= valorTirar;
      this.somarTotal();
      var removeIndex = this.arrayColacao.map((items:any) =>  {
        return items.titulo;   
       }).indexOf(titulo); 
       this.arrayColacao.splice(removeIndex, 1);
       this.arrayColacao.push({
        titulo: titulo,
        descricao: descricao,
        subTotal: vlr,
        valor: vlrTotal,
        tipo:"selecionavel",
        posicao:pos
      })
      this.somaColacaoSelecionavel += vlrTotal;
      this.somarTotal();
    }else{
      this.arrayColacao.push({
      titulo: titulo,
      descricao: descricao,
      subTotal: vlr,
      valor: vlrTotal,
      tipo:"selecionavel",
      posicao:pos
    })
    this.somaColacaoSelecionavel += vlrTotal;
    this.somarTotal();
      }
    }
  }
}

somarFixosJantar(e:any, origem:any,posicao:any){
  let tituloArray;
  let descricaoArray;
  let formandos = this.formandos;
  let valores;
  let marcado;
  let qtde8 = formandos * 8;
    if(origem == 'marcado'){
      tituloArray = e[0];
       descricaoArray = this.replaceString(e[1]);
      valores =  Number(e[2]);
      marcado = e[3];
    }else{
      let innerText = e.srcElement.labels[0].innerText
      let pos = innerText.indexOf('\n');
      tituloArray = innerText.slice(0, pos);
      descricaoArray =  this.replaceString(innerText.slice(pos+1));
      valores = Number(e.srcElement.value);
      marcado = e.srcElement.checked;
    }

if(tituloArray == "Hostess"){
  this.hostessJantar = (qtde8 / 150) * valores;
    if (marcado == true){
      this.somaJantarSelecionavel += this.hostessJantar;
      this.somarTotal();
      this.arrayJantar.push({
        titulo: tituloArray,
        descricao: descricaoArray,
        subTotal: valores,
        valor: this.hostessJantar,
        tipo:"checkbox",
        posicao:posicao
      });
      }else {
      this.somaJantarSelecionavel -= this.hostessJantar;
      var removeIndex = this.arrayJantar.map((item:any) => {
        return item.titulo;   
      }).indexOf(tituloArray); 
        this.arrayJantar.splice(removeIndex, 1);
        this.somarTotal();
      }
  }

  if(tituloArray == "Segurança Profissional"){
    this.segurancaJantar = (qtde8 / 40) * valores;
      if(marcado == true){
        this.somaJantarSelecionavel += this.segurancaJantar;
        this.somarTotal();
        this.arrayJantar.push({
          titulo: tituloArray,
          descricao: descricaoArray,
          subTotal: valores,
          valor: this.segurancaJantar,
          tipo:"checkbox",
          posicao:posicao
        });
      }else{
        this.somaJantarSelecionavel -= this.segurancaJantar;
        var removeIndex = this.arrayJantar.map((item:any) => {
          return item.titulo;   
        }).indexOf(tituloArray); 
          this.arrayJantar.splice(removeIndex, 1);
          this.somarTotal();
        }
    }
}

aJantarSoma(e:any, origem:any,pos:any){
  let tituloArray;
  let descricaoArray;
  let formandos = this.formandos;
  let valores;
  let marcado;
  let porFormando;
  let valor;
    if(origem == 'marcado'){
      tituloArray = e[0];
       descricaoArray = this.replaceString(e[1]);
      valores =  Number(e[2]);
      marcado = e[3];
      porFormando = e[4];
    }else{
      let innerText = e.srcElement.labels[0].innerText
      let pos = innerText.indexOf('\n');
      tituloArray = innerText.slice(0, pos);
      descricaoArray =  this.replaceString(innerText.slice(pos+1));
      valores = Number(e.srcElement.value);
      marcado = e.srcElement.checked;
      porFormando = e.srcElement.required;
    }
      if (porFormando == true){
          valor = valores * formandos;
          }else{
          valor = valores
          }
      if (marcado == true){
        this.jantarCheckboxSoma += valor;
        this.arrayJantar.push({
          titulo: tituloArray,
          descricao: descricaoArray,
          subTotal: valores,
          valor: valor,
          tipo:"checkbox",
          posicao:pos
        });
        this.somarTotal();
        }else {
        this.jantarCheckboxSoma -= valor;
        var removeIndex = this.arrayJantar.map((item:any) => {
          return item.titulo;   
        }).indexOf(tituloArray); 
          this.arrayJantar.splice(removeIndex, 1);
          this.somarTotal();
        } 
}

aJantarSomaSelecionavel(e:any,pos:any){
  let titulo = e.srcElement.value.split(',')[1];
  let descricao = e.srcElement.selectedOptions[0].title;
  let jaTem:any;
  let valorTirar = 0;
  let porFormando = e.srcElement.selectedOptions[0].translate;
  let vlrTotal = 0;
  let vlr = e.srcElement.value.split(',')[0]; 
  if(e.srcElement.value.split(',')[0] == "zerar"){
  if(this.arrayJantar == ""){
  this.somarTotal();
    }else{
      this.arrayJantar.map((items:any) => {
        if(titulo == items.titulo){
          jaTem = true;
          valorTirar = items.valor;
        }
      });
      if(jaTem == true){
        this.somaJantarSelecionavel -= valorTirar;
        var removeIndex = this.arrayJantar.map((items:any) =>  {
          return items.titulo;   
        }).indexOf(titulo); 
        this.arrayJantar.splice(removeIndex, 1);
        this.somarTotal();
      }else{
        this.somarTotal();
       }
    }
    }else{
      vlr = Number(e.srcElement.value.split(',')[0]);
    if (porFormando){
      vlrTotal = ((vlr * this.formandos) * 8);
    }else{
      vlrTotal = vlr;
    }
    if(this.arrayJantar == ""){
      this.arrayJantar.push({
        titulo: titulo,
        descricao: descricao,
        subTotal: vlr,
        valor: vlrTotal,
        tipo:"selecionavel",
        posicao:pos
      })
      this.somaJantarSelecionavel += vlrTotal;
      this.somarTotal();
    }else{
      this.arrayJantar.map((items:any) => {
      if(titulo == items.titulo){
        jaTem = true;
        valorTirar = items.valor;
      }
    });
    if(jaTem == true){
      this.somaJantarSelecionavel -= valorTirar;
      this.somarTotal();
      var removeIndex = this.arrayJantar.map((items:any) =>  {
        return items.titulo;   
       }).indexOf(titulo); 
       this.arrayJantar.splice(removeIndex, 1);
       this.arrayJantar.push({
        titulo: titulo,
        descricao: descricao,
        subTotal: vlr,
        valor: vlrTotal,
        tipo:"selecionavel",
        posicao:pos
      })
      this.somaJantarSelecionavel += vlrTotal;
      this.somarTotal();
    }else{
      this.arrayJantar.push({
      titulo: titulo,
      descricao: descricao,
      subTotal: vlr,
      valor: vlrTotal,
      tipo:"selecionavel",
      posicao:pos
    })
    this.somaJantarSelecionavel += vlrTotal;
    this.somarTotal();
      }
    }
  }
} 

somarCafeManhaEventoBaile(e:any,pos:any){
let tituloArray = "Café da Manhã";
let descricaoArray = "Café da manhã tradicional: oferecido das 5hrs às 7hrs, é preferível as opções mais leves, como pães, café, leite, bolos e frutas.";
let formandos = this.formandos;
let valores = this.personalizadosBaile.cafeManha.valor;
let marcado = e.srcElement.checked;
let valor = valores * formandos * 16;
  if(marcado == true){
    this.arrayBaile.push({
      titulo: tituloArray,
      descricao: descricaoArray,
      subTotal: valores,
      valor: valor,
      tipo:"checkbox",
      posicao:pos
    });
    this.cafeManha = valor;
    this.somarTotal();
     }else{
    this.cafeManha = 0;
     var removeIndex = this.arrayBaile.map((item:any) =>{
      return item.titulo;   
     }).indexOf(tituloArray); 
      this.arrayBaile.splice(removeIndex, 1);
     }
     this.somarTotal();
}

somarCarrinhoChoppEventoBaile(e:any,pos:any){
  let tituloArray = "Carrinho de Chopp";
  let descricaoArray = "Carrinho de Chopp";
  let formandos = this.formandos;
  let valores = this.personalizadosBaile.carrinhoChopp.valor;
  let marcado = e.srcElement.checked;
  let valor = valores * formandos * 16;
    if (marcado == true){
      this.arrayBaile.push({
        titulo: tituloArray,
        descricao: descricaoArray,
        subTotal: valores,
        valor: valor,
        tipo:"checkbox",
        posicao:pos
      });
      this.carrinhoChopp = valor;
      this.somarTotal();
       }else{
      this.carrinhoChopp = 0;
       var removeIndex = this.arrayBaile.map((item:any) =>{
        return item.titulo;   
       }).indexOf(tituloArray); 
        this.arrayBaile.splice(removeIndex, 1);
        this.somarTotal();
       }
  }

  somarBebidasAfterEventoBaile(e:any,pos:any){
    let tituloArray = "Bebidas Prolongamento";
    let descricaoArray = "Bebidas para o Prolongamento";
    let formandos = this.formandos;
    let valores = this.personalizadosBaile.bebidasAfter.valor;
    let marcado = e.srcElement.checked;
    let valor = valores * formandos * 16;
      if (marcado == true){
        this.arrayBaile.push({
          titulo: tituloArray,
          descricao: descricaoArray,
          subTotal: valores,
          valor: valor,
          tipo:"checkbox",
          posicao:pos
        });
        this.bebidasProlongamento = valor;
        this.somarTotal();
         }else{
        this.bebidasProlongamento = 0;
         var removeIndex = this.arrayBaile.map((item:any) =>{
          return item.titulo;   
         }).indexOf(tituloArray); 
          this.arrayBaile.splice(removeIndex, 1);
          this.somarTotal();
         }
    }

somarFingerFoodsBaile(e:any,pos:any){
let formandos = this.formandos;
let qtde = Number(e.srcElement.value);
let valor = this.personalizadosBaile.finger.valor;
let totalFingerFoods = qtde * valor * formandos * 16;
let tituloArray = "Finger Foods";
if(this.arrayBaile == ""){
  this.arrayBaile.push({
    titulo: tituloArray,
    descricao: qtde+" Tipos - Mini Hambúrguer Gourmet com Cheddar e Cebola Caramelizada, Mini Pizza nos mais variados sabores, Mini Cachorro Quente, Mini Churros, Escondinho de Carne Seca e Aipim, Penne ao Mediterrâneo, Canapé com pasta de Salmão e Pimenta, Biscoitinho de Parmesão e Alecrim e Tomate Cereja Tostado, Mini Sanduiche de Rosbife, Mini Casquinha de Saladas Caesar, Mix de Saladas, Espetinho de Frutas, Algodão Doce, Crepe Suíço, Picolé, Paletas Mexicanas, Sorvete Tailandês, Mesa de Doce. São algumas sugestões que podemos montar o cardápio da madrugada.",
    subTotal: valor,
    valor: totalFingerFoods,
    tipo:"selecionavel",
    posicao:pos
  })
  this.fingerFoods = totalFingerFoods;
  this.somarTotal();
}else{
   let removeIndex = this.arrayBaile.map((items:any) => {
     return items.titulo;
    }).indexOf(tituloArray);
  if(removeIndex >= 0){
     this.arrayBaile.splice(removeIndex, 1);
     if(qtde != 0){
      this.arrayBaile.push({
        titulo: tituloArray,
        descricao: qtde+" Tipos - Mini Hambúrguer Gourmet com Cheddar e Cebola Caramelizada, Mini Pizza nos mais variados sabores, Mini Cachorro Quente, Mini Churros, Escondinho de Carne Seca e Aipim, Penne ao Mediterrâneo, Canapé com pasta de Salmão e Pimenta, Biscoitinho de Parmesão e Alecrim e Tomate Cereja Tostado, Mini Sanduiche de Rosbife, Mini Casquinha de Saladas Caesar, Mix de Saladas, Espetinho de Frutas, Algodão Doce, Crepe Suíço, Picolé, Paletas Mexicanas, Sorvete Tailandês, Mesa de Doce. São algumas sugestões que podemos montar o cardápio da madrugada.",
        subTotal: valor,
        valor: totalFingerFoods,
        tipo:"selecionavel",
        posicao:pos
      })
      this.fingerFoods = totalFingerFoods;
      this.somarTotal();
     }else{
      this.fingerFoods = 0;
      this.somarTotal();
     }
  }else{
    this.arrayBaile.push({
      titulo: tituloArray,
      descricao: qtde+" Tipos - Mini Hambúrguer Gourmet com Cheddar e Cebola Caramelizada, Mini Pizza nos mais variados sabores, Mini Cachorro Quente, Mini Churros, Escondinho de Carne Seca e Aipim, Penne ao Mediterrâneo, Canapé com pasta de Salmão e Pimenta, Biscoitinho de Parmesão e Alecrim e Tomate Cereja Tostado, Mini Sanduiche de Rosbife, Mini Casquinha de Saladas Caesar, Mix de Saladas, Espetinho de Frutas, Algodão Doce, Crepe Suíço, Picolé, Paletas Mexicanas, Sorvete Tailandês, Mesa de Doce. São algumas sugestões que podemos montar o cardápio da madrugada.",
      subTotal: valor,
      valor: totalFingerFoods,
      tipo:"selecionavel",
      posicao:pos
    })
    this.fingerFoods = totalFingerFoods;
    this.somarTotal();
    }
  }
}

somarBarTematicoBaile(e:any,pos:any){
let formandos = this.formandos;
let qtde = Number(e.srcElement.value);
let valor = this.personalizadosBaile.barTematico.valor;
let totalBarTematico = qtde * valor * formandos * 16;
let tituloArray = "Bar Temático";
let p;
if(qtde == 1){
  p = qtde+" Bar Temático"
}else{
  p = qtde+" Bares Temáticos"
}
if(this.arrayBaile == ""){
  this.arrayBaile.push({
    titulo: tituloArray,
    descricao: p+" servindo variados drinks a base de vodka, cachaça artesanal ou gin como (sex on the beach, moscou, variados tipos de caipiroska, cod collins,mojitos, caipirinha e drinks desenvolvidos especialmente para a turma) Bares como: Heineken, Amstel, Eisenbah, Budweiser,  Drinks a base de Vodka, Whisky, Gin são algumas opções que a Trends Formaturas pode proporcionar para os nossos formandos",
    subTotal: valor,
    valor: totalBarTematico,
    tipo:"selecionavel",
    posicao:pos
  })
  this.barTematico = totalBarTematico;
  this.somarTotal();
}else{
  let removeIndex = this.arrayBaile.map((items:any) => {
    return items.titulo;
   }).indexOf(tituloArray);
 if(removeIndex >= 0){
  this.arrayBaile.splice(removeIndex, 1);
  if(qtde != 0){
    this.arrayBaile.push({
      titulo: tituloArray,
      descricao: p+" servindo variados drinks a base de vodka, cachaça artesanal ou gin como (sex on the beach, moscou, variados tipos de caipiroska, cod collins,mojitos, caipirinha e drinks desenvolvidos especialmente para a turma) Bares como: Heineken, Amstel, Eisenbah, Budweiser,  Drinks a base de Vodka, Whisky, Gin são algumas opções que a Trends Formaturas pode proporcionar para os nossos formandos",
      subTotal: valor,
      valor: totalBarTematico,
      tipo:"selecionavel",
      posicao:pos
    })
    this.barTematico = totalBarTematico;
    this.somarTotal();
   }else{
    this.barTematico = 0;
    this.somarTotal();
   }
  }else{
    this.arrayBaile.push({
      titulo: tituloArray,
      descricao: p+" servindo variados drinks a base de vodka, cachaça artesanal ou gin como (sex on the beach, moscou, variados tipos de caipiroska, cod collins,mojitos, caipirinha e drinks desenvolvidos especialmente para a turma) Bares como: Heineken, Amstel, Eisenbah, Budweiser,  Drinks a base de Vodka, Whisky, Gin são algumas opções que a Trends Formaturas pode proporcionar para os nossos formandos",
      subTotal: valor,
      valor: totalBarTematico,
      tipo:"selecionavel",
      posicao:pos
    })
    this.barTematico = totalBarTematico;
    this.somarTotal();
    }
  }
}

somarProlongamentoEventoBaile(e:any,pos:any){
let qtde = Number(e.srcElement.value);
let valor = this.valorLocalBaile * (this.horaExtraLocalBaile/100);
let totalProlongamento = qtde * valor;
let tituloArray = "Prolongamento do Evento";
if(this.arrayBaile == ""){
  this.arrayBaile.push({
    titulo: tituloArray,
    descricao: "Prolongamento do Evento - After Party ("+qtde+" horas)",
    subTotal: valor,
    valor: totalProlongamento,
    tipo:"selecionavel",
    posicao:pos
  })
  this.prolongamento = totalProlongamento;
  this.somarTotal();
}else{
  let removeIndex = this.arrayBaile.map((items:any) => {
    return items.titulo;
   }).indexOf(tituloArray);
 if(removeIndex >= 0){
  this.arrayBaile.splice(removeIndex, 1);
  if(qtde != 0){
    this.arrayBaile.push({
      titulo: tituloArray,
      descricao: "Prolongamento do Evento - After Party ("+qtde+" horas)",
      subTotal: valor,
      valor: totalProlongamento,
      tipo:"selecionavel",
      posicao:pos
    })
    this.prolongamento = totalProlongamento;
    this.somarTotal();
   }else{
    this.prolongamento = 0;
    this.somarTotal();
   }
  }else{
    this.arrayBaile.push({
      titulo: tituloArray,
      descricao: "Prolongamento do Evento - After Party ("+qtde+" horas)",
      subTotal: valor,
      valor: totalProlongamento,
      tipo:"selecionavel",
      posicao:pos
    })
    this.prolongamento = totalProlongamento;
    this.somarTotal();
    }
  }
}

somarFixosBaile(e:any,origem:any,posicao:any){
  let tituloArray;
  let descricaoArray;
  let formandos = this.formandos;
  let valores;
  let marcado;
    if(origem == 'marcado'){
      tituloArray = e[0];
       descricaoArray = this.replaceString(e[1]);
      valores =  Number(e[2]);
      marcado = e[3];
    }else{
      let innerText = e.srcElement.labels[0].innerText
      let pos = innerText.indexOf('\n');
      tituloArray = innerText.slice(0, pos);
      descricaoArray =  this.replaceString(innerText.slice(pos+1));
      valores = Number(e.srcElement.value);
      marcado = e.srcElement.checked;
    }
let qtde8 = formandos * 8;
if(tituloArray == "Segurança Profissional"){
  this.segurancaBaile = (qtde8 / 40) * valores;
    if(marcado == true){
      this.baileGalaCheckboxSoma += this.segurancaBaile;
      this.somarTotal();
      this.arrayBaile.push({
        titulo: tituloArray,
        descricao: descricaoArray,
        subTotal: valores,
        valor: this.segurancaBaile,
        tipo:"checkbox",
        posicao:posicao
      });
    }else{
      this.baileGalaCheckboxSoma -= this.segurancaBaile;
      var removeIndex = this.arrayBaile.map((item:any) =>{
        return item.titulo;   
      }).indexOf(tituloArray); 
        this.arrayBaile.splice(removeIndex, 1);
        this.somarTotal();
      }
  }
}

aBaileGalaSoma(e:any, origem:any,pos:any){
  let tituloArray;
  let descricaoArray;
  let formandos = this.formandos;
  let valores;
  let marcado;
  let porFormando;
  let valor;
  if(origem == 'marcado'){
      tituloArray = e[0];
      descricaoArray = this.replaceString(e[1]);
      valores =  Number(e[2]);
      marcado = e[3];
      porFormando = e[4];
    }else{
      let innerText = e.srcElement.labels[0].innerText
      let pos = innerText.indexOf('\n');
      tituloArray = innerText.slice(0, pos);
      descricaoArray =  this.replaceString(innerText.slice(pos+1));
      valores = Number(e.srcElement.value);
      marcado = e.srcElement.checked;
      porFormando = e.srcElement.required;
    }
    if(tituloArray == "Mestre de Cerimônias" || tituloArray == "Gerador de Energia" || tituloArray == "Taxa de Eletricidade" || tituloArray == "Taxa de Água" || tituloArray == "Coordenador de Eventos"){
      if(origem == 'marcado'){
        if(this.detalhes.get('diasEvento')?.value == 1){
            valores = 0;
        }else{
            valores =  Number(e[2]);
        }
      }else{
        let i = this.arrayJantar.map((m:any) => {
          return m.titulo;
          }).indexOf(tituloArray);
        if(i >= 0 && this.detalhes.get('diasEvento')?.value == 1){
              valores = 0;
          }else{
              valores = Number(e.srcElement.value);
          }
      }
      }else{
        if(origem == 'marcado'){ 
          valores =  Number(e[2]);
        }else{
          valores = Number(e.srcElement.value);
        }
      }
     if(porFormando == true){
          valor = valores * formandos;
      }else{
          valor = valores;
        }
      if(marcado == true){
        this.baileGalaCheckboxSoma += valor;
        this.arrayBaile.push({
          titulo: tituloArray,
          descricao: descricaoArray,
          subTotal: valores,
          valor: valor,
          tipo:"checkbox",
          posicao:pos
        });
        this.somarTotal();
        }else{
        this.baileGalaCheckboxSoma -= valor;
        var removeIndex = this.arrayBaile.map((item:any) => {
          return item.titulo;   
        }).indexOf(tituloArray); 
          this.arrayBaile.splice(removeIndex, 1);
          this.somarTotal();
        }
  } 

aBaileSomaSelecionavel(e:any,pos:any){
  let titulo = e.srcElement.value.split(',')[1];
  let descricao = e.srcElement.selectedOptions[0].title;
  let jaTem:any;
  let valorTirar = 0;
  let porFormando = e.srcElement.selectedOptions[0].translate;
  let vlrTotal = 0;
  let vlr = e.srcElement.value.split(',')[0]; 
  if(e.srcElement.value.split(',')[0] == "zerar"){
  if(this.arrayBaile == ""){
  this.somarTotal();
    }else{
      this.arrayBaile.map((items:any) => {
        if(titulo == items.titulo){
          jaTem = true;
          valorTirar = items.valor;
        }
      });
      if(jaTem == true){
        if(titulo == "Bandas"){
          this.baileGalaCheckboxSoma -= valorTirar;
        }else{
          this.somaBaileSelecionavel -= valorTirar;
        }
        var removeIndex = this.arrayBaile.map((items:any) =>  {
          return items.titulo;   
        }).indexOf(titulo); 
        this.arrayBaile.splice(removeIndex, 1);
        this.somarTotal();
      }else{
        this.somarTotal();
       }
    }
    }else{
      vlr = Number(e.srcElement.value.split(',')[0]);
    if (porFormando){
      vlrTotal = ((vlr * this.formandos) * 8);
    }else{
      vlrTotal = vlr;
    }
    if(this.arrayBaile == ""){
      this.arrayBaile.push({
        titulo: titulo,
        descricao: descricao,
        subTotal: vlr,
        valor: vlrTotal,
        tipo:"selecionavel",
        posicao:pos
      })
      if(titulo == "Bandas"){
        this.baileGalaCheckboxSoma += vlrTotal;
      }else{
        this.somaBaileSelecionavel += vlrTotal;
      }
      this.somarTotal();
    }else{
      this.arrayBaile.map((items:any) => {
      if(titulo == items.titulo){
        jaTem = true;
        valorTirar = items.valor;
      }
    });
    if(jaTem == true){
      if(titulo == "Bandas"){
        this.baileGalaCheckboxSoma -= valorTirar;
      }else{
        this.somaBaileSelecionavel -= valorTirar;
      }
      this.somarTotal();
      var removeIndex = this.arrayBaile.map((items:any) =>  {
        return items.titulo;   
       }).indexOf(titulo); 
       this.arrayBaile.splice(removeIndex, 1);
       this.arrayBaile.push({
        titulo: titulo,
        descricao: descricao,
        subTotal: vlr,
        valor: vlrTotal,
        tipo:"selecionavel",
        posicao:pos
      })
      if(titulo == "Bandas"){
        this.baileGalaCheckboxSoma += vlrTotal;
      }else{
        this.somaBaileSelecionavel += vlrTotal;
      }
      this.somarTotal();
    }else{
      this.arrayBaile.push({
      titulo: titulo,
      descricao: descricao,
      subTotal: vlr,
      valor: vlrTotal,
      tipo:"selecionavel",
      posicao:pos
    })
    if(titulo == "Bandas"){
      this.baileGalaCheckboxSoma += vlrTotal;
    }else{
      this.somaBaileSelecionavel += vlrTotal;
    }
    this.somarTotal();
      }
    }
  }
}

somarTotal(){
  // SOMA FINGER FOODS, BAR TEMATICO, AFTER
  this.somaPersonalizadosFixos = this.fingerFoods + this.barTematico + this.carrinhoChopp + this.cafeManha + this.bebidasProlongamento;
  //Total Orçamento
  let totalSemAssessoria = 0;
  let vlrLocalBaile;
  let ecadLocalBaile;
  if(this.localBaile == this.localJantar && this.detalhes.get('diasEvento')?.value == 1){
    vlrLocalBaile = 0;
    ecadLocalBaile = 0;
  }else{
    vlrLocalBaile = this.valorLocalBaile;
    ecadLocalBaile = this.ecadBaile;
  }
  
  let somaReferenteBaile = (this.baileGalaCheckboxSoma - vlrLocalBaile - ecadLocalBaile) + this.somaBaileSelecionavel;
  totalSemAssessoria = this.aulaSaudadeCheckboxSoma + this.somaSaudadeSelecionavel + this.missaCheckboxSoma + this.somaMissaSelecionavel + this.colacaoCheckboxSoma + this.somaColacaoSelecionavel + this.somaJantarSelecionavel + this.jantarCheckboxSoma + somaReferenteBaile + this.somaPersonalizadosFixos + vlrLocalBaile + ecadLocalBaile + this.prolongamento;
  let calculoAssessoria = 0;
  calculoAssessoria = totalSemAssessoria * this.percentualAssessoria;
  if(calculoAssessoria <= 19990){
    this.assessoriaCerimonial = 19990;
    this.totalSomas = totalSemAssessoria + 19990 + this.totalFotos;
  } else {
    this.assessoriaCerimonial = calculoAssessoria;
    this.totalSomas = totalSemAssessoria + calculoAssessoria + this.totalFotos;
  }

  // Total Orçamento Por Formandos
  this.totalPorFormandos =  this.totalSomas / this.formandos;

  //Total Orçamento Por Formandos Por Mês
  this.totalPorFormandosPorMes = this.totalPorFormandos / this.prestacoes

  // PLANOS DE RIFA
  this.umaRifaSemestre = ((this.prestacoes / 6)*9.5) * 50;
  this.umaRifaAno = ((this.prestacoes / 12)*9.5) * 50;
  this.duasRifaSemestre = this.umaRifaSemestre * 2;
  this.duasRifaAno = this.umaRifaAno * 2;
  this.tresRifaSemestre = this.umaRifaSemestre * 3;
  this.tresRifaAno = this.umaRifaAno * 3;

  // PLANOS CERVEJADA
  this.cervejada6totalIngressos = 6 * this.formandos;
  this.cervejada6lucro = this.cervejada6totalIngressos *17.5;
  this.cervejada6lucroTotalTurma = this.cervejada6lucro * (this.prestacoes / 12);
  this.cervejada12totalIngressos = 12 * this.formandos;
  this.cervejada12lucro = this.cervejada12totalIngressos *17.5;
  this.cervejada12lucroTotalTurma = this.cervejada12lucro * (this.prestacoes / 12);

  // PLANOS DE CONVITES
  this.convite8jantar10baile = this.totalSomas + ((this.somaBaileSelecionavel/8) * 2) + ((this.somaPersonalizadosFixos/16)*2);
  this.convite8jantar10bailePorFormando = this.convite8jantar10baile / this.formandos;
  this.convite8jantar10baileVlrParcela = this.convite8jantar10bailePorFormando / this.prestacoes;

  this.convite8jantar16baile = this.totalSomas + this.somaBaileSelecionavel + ((this.somaPersonalizadosFixos/16)*8);
  this.convite8jantar16bailePorFormando = this.convite8jantar16baile / this.formandos;
  this.convite8jantar16baileVlrParcela = this.convite8jantar16bailePorFormando / this.prestacoes;

  this.convite8jantar20baile = this.totalSomas + ((this.somaBaileSelecionavel/8) * 12) + ((this.somaPersonalizadosFixos/16)*12);
  this.convite8jantar20bailePorFormando = this.convite8jantar20baile / this.formandos;
  this.convite8jantar20baileVlrParcela = this.convite8jantar20bailePorFormando / this.prestacoes;

  this.convite10jantar10baile = this.totalSomas + ((this.somaJantarSelecionavel/8) * 2) + ((this.somaBaileSelecionavel/8) * 2) + ((this.somaPersonalizadosFixos/16)*4);
  this.convite10jantar10bailePorFormando = this.convite10jantar10baile / this.formandos;
  this.convite10jantar10baileVlrParcela = this.convite10jantar10bailePorFormando / this.prestacoes;

  this.convite10jantar16baile = this.totalSomas + ((this.somaJantarSelecionavel/8) * 2) +  this.somaBaileSelecionavel + ((this.somaPersonalizadosFixos/16)*10);
  this.convite10jantar16bailePorFormando = this.convite10jantar16baile / this.formandos;
  this.convite10jantar16baileVlrParcela = this.convite10jantar16bailePorFormando / this.prestacoes;

  this.convite10jantar20baile = this.totalSomas + ((this.somaJantarSelecionavel/8) * 2) + ((this.somaBaileSelecionavel/8) * 12) + ((this.somaPersonalizadosFixos/16)*14);
  this.convite10jantar20bailePorFormando = this.convite10jantar20baile / this.formandos;
  this.convite10jantar20baileVlrParcela = this.convite10jantar20bailePorFormando / this.prestacoes;

  this.convite16jantar10baile = this.totalSomas + this.somaJantarSelecionavel + ((this.somaBaileSelecionavel/8) * 2) + ((this.somaPersonalizadosFixos/16)*10);
  this.convite16jantar10bailePorFormando = this.convite16jantar10baile / this.formandos;
  this.convite16jantar10baileVlrParcela = this.convite16jantar10bailePorFormando / this.prestacoes;

  this.convite16jantar16baile = this.totalSomas + this.somaJantarSelecionavel + this.somaBaileSelecionavel + this.somaPersonalizadosFixos;
  this.convite16jantar16bailePorFormando = this.convite16jantar16baile / this.formandos;
  this.convite16jantar16baileVlrParcela = this.convite16jantar16bailePorFormando / this.prestacoes;

  this.convite16jantar20baile = this.totalSomas + this.somaJantarSelecionavel + ((this.somaBaileSelecionavel/8) * 12) + ((this.somaPersonalizadosFixos/16)*20);
  this.convite16jantar20bailePorFormando = this.convite16jantar20baile / this.formandos;
  this.convite16jantar20baileVlrParcela = this.convite16jantar20bailePorFormando / this.prestacoes;

  this.convite20jantar10baile = this.totalSomas + ((this.somaJantarSelecionavel/8) * 12) + ((this.somaBaileSelecionavel/8) * 2) + ((this.somaPersonalizadosFixos/16)*14);
  this.convite20jantar10bailePorFormando = this.convite20jantar10baile / this.formandos;
  this.convite20jantar10baileVlrParcela = this.convite20jantar10bailePorFormando / this.prestacoes;

  this.convite20jantar16baile = this.totalSomas + ((this.somaJantarSelecionavel/8) * 12) + this.somaBaileSelecionavel + ((this.somaPersonalizadosFixos/16)*20);
  this.convite20jantar16bailePorFormando = this.convite20jantar16baile / this.formandos;
  this.convite20jantar16baileVlrParcela = this.convite20jantar16bailePorFormando / this.prestacoes;

  this.convite20jantar20baile = this.totalSomas + ((this.somaJantarSelecionavel/8) * 12) + ((this.somaBaileSelecionavel/8) * 12) + ((this.somaPersonalizadosFixos/16)*24);
  this.convite20jantar20bailePorFormando = this.convite20jantar20baile / this.formandos;
  this.convite20jantar20baileVlrParcela = this.convite20jantar20bailePorFormando / this.prestacoes;

  // PLANOS DE RENDIMENTO
  let taxaEstimulada = 0.005;
  this.rendimentoMensalIndividual = this.totalPorFormandosPorMes;
  this.rendimentoTotalIndividual = this.rendimentoMensalIndividual * this.prestacoes;
  this.rendimentoMensalTurma = this.rendimentoMensalIndividual * this.formandos;
  this.rendimentoTotalTurma = this.rendimentoMensalTurma * this.prestacoes;

  let ret1 = 1+taxaEstimulada;
  let ret2 = Math.pow(ret1,this.prestacoes) - 1;
  let ret3 = ret2/taxaEstimulada
  let ret4 = this.formandos*this.rendimentoMensalIndividual

  this.rendimentoVlrCorrigido = ret1*ret3*ret4;
  this.rendimentoTotal = this.rendimentoVlrCorrigido - this.rendimentoTotalTurma;  
}

openModal(url:any, i:any){
  this.indexImage = i;
  let modal:any = document.getElementById('myModalOrcamentoCol')
  modal.style.display = "block";
  this.imagemSelecionada = url;
}
 
addImgGaleria(e:any){
  let selecionado = e.options[0]._selected;
  let urlImg = e.options[0]._value;
  if(selecionado == true){
    this.imagesArray.push({
      url : urlImg
    })
  }else{
    var items = this.imagesArray.map((item:any) => {
      return item.url;   
    }).indexOf(urlImg); 
    this.imagesArray.splice(items, 1);
  }
}

nextImg(){
  let i = this.indexImage
  if((this.images.length - 1) == i){
    this.imagemSelecionada = this.images[0].url;
    this.indexImage = 0;
  }else{
    i++
    this.imagemSelecionada = this.images[i].url;
    this.indexImage++
  }
}

prevImg(){
  let i = this.indexImage
  if(i == 0){
    this.imagemSelecionada = this.images[this.images.length - 1].url;
    this.indexImage = this.images.length - 1;
  }else{
    i--
    this.imagemSelecionada = this.images[i].url;
    this.indexImage--
  }
}

closeModal(){
  let modal:any = document.getElementById('myModalOrcamentoCol')
  modal.style.display = "none";
}

salvarOrcamento(){
  this.somarTotal(); 
  let jsonDate = new Date().toJSON();
  let hora = new Date(jsonDate).toLocaleTimeString();
  this.db.getProposta().subscribe((prop:any) => {
  this.proposta = prop[0];
  this.numeroProposta = this.proposta.valor;
  let numPropostaSplit;
  let numPropostaNovaVersion;
  let qtdeItemsArray = this.arrayTurmas.length
  let contagemFor = 0;
  let numeroProposta = this.numeroProposta;
  let propNew = false;
  this.arrayTurmas.forEach((item:any) => {
    this.db.getOrcamentoByTurmaId(item.turmaId).subscribe((data:any) => {
      if(data.length > 0){
        numPropostaSplit = data[0].nProposta.split('/');
        numPropostaNovaVersion = String(parseInt(numPropostaSplit[1]) + 1);
        numeroProposta = String(numPropostaSplit[0]+"/"+numPropostaNovaVersion);
        this.numeroProposta = numeroProposta;
        propNew = false;
      }else{
        this.numeroProposta = numeroProposta;
        propNew = true;
      }
      let obj = { 
        nProposta : this.numeroProposta,
        data : this.dataHoje, 
        hora: hora,
        consultor : this.consultor,
        tipo:this.tipoInstituicao,
        cidade :  this.inicio.get('cidadeEscolhida')?.value, 
        instituicao : item.instituicao, 
        curso : item.curso, 
        turmaId : item.turmaId,
        turma :  item.turma,
        ano :  item.anoTurma,
        arrayTurmas : this.arrayTurmas,
        formandos : this.formandos, 
        localBaile : this.localBaile,
        localJantar : this.localJantar,
        dtInicio : this.detalhes.get('dataInicio')?.value, 
        dtFim : this.detalhes.get('dataFim')?.value, 
        diasEvento : this.detalhes.get('diasEvento')?.value,
        parcelas : this.prestacoes, 
        contatos : this.arrayContatos, 
        aulaSaudade : this.arraySaudade.sort((a:any,b:any) => {if(a.tipo < b.tipo){return 1;}if(a.tipo > b.tipo){return -1;}if(a.posicao > b.posicao){return 1;}if(a.posicao < b.posicao){return -1;}return 1}),
        totalAulaSaudade : this.aulaSaudadeCheckboxSoma + this.somaSaudadeSelecionavel,
        missa : this.arrayMissa.sort((a:any,b:any) => {if(a.tipo < b.tipo){return 1;}if(a.tipo > b.tipo){return -1;}if(a.posicao > b.posicao){return 1;}if(a.posicao < b.posicao){return -1;}return 1}),
        totalMissa : this.missaCheckboxSoma + this.somaMissaSelecionavel,
        colacao : this.arrayColacao.sort((a:any,b:any) => {if(a.tipo < b.tipo){return 1;}if(a.tipo > b.tipo){return -1;}if(a.posicao > b.posicao){return 1;}if(a.posicao < b.posicao){return -1;}return 1}),
        totalColacao : this.colacaoCheckboxSoma + this.somaColacaoSelecionavel,
        jantar : this.arrayJantar.sort((a:any,b:any) => {if(a.tipo < b.tipo){return 1;}if(a.tipo > b.tipo){return -1;}if(a.posicao > b.posicao){return 1;}if(a.posicao < b.posicao){return -1;}return 1}),
        totalJantar : this.jantarCheckboxSoma + this.somaJantarSelecionavel,
        baile : this.arrayBaile.sort((a:any,b:any) => {if(a.tipo < b.tipo){return 1;}if(a.tipo > b.tipo){return -1;}if(a.posicao > b.posicao){return 1;}if(a.posicao < b.posicao){return -1;}return 1}),
        totalBaile : this.baileGalaCheckboxSoma + this.somaBaileSelecionavel + this.somaPersonalizadosFixos,
        assessoriaCerimonial : this.assessoriaCerimonial,
        totalSomas : this.totalSomas, 
        totalPorFormandos : this.totalPorFormandos, 
        totalPorFormandosPorMes : this.totalPorFormandosPorMes, 
        imagensOrcamento : this.imagesArray, 
        umaRifaSemestre : this.umaRifaSemestre, 
        umaRifaAno : this.umaRifaAno, 
        duasRifaSemestre : this.duasRifaSemestre, 
        duasRifaAno : this.duasRifaAno, 
        tresRifaSemestre : this.tresRifaSemestre, 
        tresRifaAno : this.tresRifaAno, 
        cervejada6totalIngressos : this.cervejada6totalIngressos, 
        cervejada6lucro : this.cervejada6lucro, 
        cervejada6lucroTotalTurma : this.cervejada6lucroTotalTurma, 
        cervejada12totalIngressos : this.cervejada12totalIngressos, 
        cervejada12lucro : this.cervejada12lucro, 
        cervejada12lucroTotalTurma : this.cervejada12lucroTotalTurma, 
        convite8jantar10baile : this.convite8jantar10baile, 
        convite8jantar16baile : this.convite8jantar16baile, 
        convite8jantar20baile : this.convite8jantar20baile, 
        convite8jantar10bailePorFormando : this.convite8jantar10bailePorFormando, 
        convite8jantar16bailePorFormando : this.convite8jantar16bailePorFormando, 
        convite8jantar20bailePorFormando : this.convite8jantar20bailePorFormando, 
        convite8jantar10baileVlrParcela : this.convite8jantar10baileVlrParcela, 
        convite8jantar16baileVlrParcela : this.convite8jantar16baileVlrParcela, 
        convite8jantar20baileVlrParcela : this.convite8jantar20baileVlrParcela, 
        convite10jantar10baile : this.convite10jantar10baile, 
        convite10jantar16baile : this.convite10jantar16baile, 
        convite10jantar20baile : this.convite10jantar20baile, 
        convite10jantar10bailePorFormando : this.convite10jantar10bailePorFormando, 
        convite10jantar16bailePorFormando : this.convite10jantar16bailePorFormando, 
        convite10jantar20bailePorFormando : this.convite10jantar20bailePorFormando, 
        convite10jantar10baileVlrParcela : this.convite10jantar10baileVlrParcela, 
        convite10jantar16baileVlrParcela : this.convite10jantar16baileVlrParcela, 
        convite10jantar20baileVlrParcela : this.convite10jantar20baileVlrParcela, 
        convite16jantar10baile : this.convite16jantar10baile, 
        convite16jantar16baile : this.convite16jantar16baile, 
        convite16jantar20baile : this.convite16jantar20baile, 
        convite16jantar10bailePorFormando : this.convite16jantar10bailePorFormando, 
        convite16jantar16bailePorFormando : this.convite16jantar16bailePorFormando, 
        convite16jantar20bailePorFormando : this.convite16jantar20bailePorFormando, 
        convite16jantar10baileVlrParcela : this.convite16jantar10baileVlrParcela, 
        convite16jantar16baileVlrParcela : this.convite16jantar16baileVlrParcela, 
        convite16jantar20baileVlrParcela : this.convite16jantar20baileVlrParcela, 
        convite20jantar10baile : this.convite20jantar10baile, 
        convite20jantar16baile : this.convite20jantar16baile, 
        convite20jantar20baile : this.convite20jantar20baile, 
        convite20jantar10bailePorFormando : this.convite20jantar10bailePorFormando, 
        convite20jantar16bailePorFormando : this.convite20jantar16bailePorFormando, 
        convite20jantar20bailePorFormando : this.convite20jantar20bailePorFormando, 
        convite20jantar10baileVlrParcela : this.convite20jantar10baileVlrParcela, 
        convite20jantar16baileVlrParcela : this.convite20jantar16baileVlrParcela, 
        convite20jantar20baileVlrParcela : this.convite20jantar20baileVlrParcela, 
        rendimentoMensalIndividual : this.rendimentoMensalIndividual, 
        rendimentoTotalIndividual : this.rendimentoTotalIndividual, 
        rendimentoMensalTurma : this.rendimentoMensalTurma, 
        rendimentoTotalTurma : this.rendimentoTotalTurma, 
        rendimentoVlrCorrigido : this.rendimentoVlrCorrigido, 
        rendimentoTotal : this.rendimentoTotal
        }
      this.db.postOrcamento(obj).subscribe(res => {
        console.log(res);
        let jsonDate = new Date().toJSON();
        let horaHoje = new Date(jsonDate).toLocaleTimeString();
        let o = {
          data : this.dataHoje, 
          hora : horaHoje, 
          assunto : "Orçamento", 
          descricao : "Orçamento feito no dia "+this.dataHoje.split('-').reverse().join('/')+" às "+hora+" pelo Vendedor: "+this.consultor, 
          usuario : this.consultor, 
          idTurma : item.turmaId
          }
        this.db.postRelatorio(o).subscribe(res => {
          console.log(res);
          let ob = {
            data:this.dataHoje,
            hora:horaHoje,
            usuario: localStorage.getItem('usuarioTrends'),
            menu: "ORÇAMENTO",
            descricao: "Orçamento Criado para: "+item.instituicao+" / "+this.inicio.get('cidadeEscolhida')?.value+" - "+item.curso+" - "+item.turma+" - "+item.anoTurma
          }
          this.db.postLogs(ob).subscribe(res => {
            console.log(res);
            contagemFor++;
            if(contagemFor == qtdeItemsArray && propNew){
              this.attNumProposta();
            }
          },err => console.log(err));
        },err => console.log(err));
      }, err => {
        console.log(err);
         this.app.openAlert("Não Foi Possivel Salvar este Orçamento, Por Favor Tente Novamente!");
      });
    })
  }); 
})
}

attNumProposta(){
   let numPropSplit = this.numeroProposta.split('/');
   let numPropNovaVersion = String(parseInt(numPropSplit[0]) + 1);
   let numProp = String(numPropNovaVersion+'/1');
   let obj = {
    valor : numProp
  }
  this.db.patchProposta(this.proposta._id,obj).subscribe(res => {
     console.log(res);
    },err => {console.log(err);
        this.app.openAlert("Não Foi Possivel Atualizar o Número da Proposta, Contate o Programador!")
     });
}

print() {
  let arrTurmas = this.inicio.get('turma')?.value;
  let turmas = "";
  let estrutura;
  arrTurmas.forEach((item:any) => {
    estrutura = item.curso+"-"+item.turma+"-"+item.anoTurma+"-"+item.instituicao+"-";
    turmas = turmas+estrutura
  })
  document.title = turmas+this.anoDatFim+"-P"+this.numeroProposta;
  window.print();
  document.title = "Trends"
}

goBack(){
  this.location.back();
 }

 replaceString(string:any){
   if(string){
    let str = string.replace(/\%/gi,'%25');
    return str.replace(/\+/gi,'%2B').replace(/\!/gi,'%21').replace(/\&/gi,'%26').replace(/\#/gi,'%23').replace(/\;/gi,'%3B');
   }else{
     return string;
   }
}

}