import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { DataBaseService } from '../../provider';
import { AuthService } from '../../auth.service';
import { Location } from '@angular/common';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-orcamento-infantil',
  templateUrl: './orcamento-infantil.component.html',
  styleUrls: ['./orcamento-infantil.component.css']
})

export class OrcamentoInfantilComponent implements OnInit,OnDestroy {
  imagens:any;
  financeiro:any; 
  proposta:any;
  assessoria:any;
  dataHoje:any;
  consultor:any;
  tipoUsuario:any;
  arrayTurmas:any = [];
  contatosDasTurmas:any = [];
  cidadeCheck:boolean = false;
  instituicaoCheck:boolean = false;
  turmaCheck:boolean = false;
  cursoCheck:boolean = false;
  listaCidades:any = [];
  listaEventos:any = [];
  listaCursos:any = [];
  listaTurmas:any = [];
  listaInstituicoes:any = [];
  turmaSelecionada:any = [];
  turmaTurmaSelecionada:any = "";
  dtHojePuro:any = "";
  inicio: any;
  detalhes:any;
  public contactList: any;
  public contatos: any;
  images:any = [];
  indexImage:number = 0;
  imagemSelecionada:any;
  imagesArray:any = [];
  preEventos:any;
  preEventosCheckbox:any;
  preEventosSelecionavel:any;
  preEventosCheckboxSoma:number = 0;
  somaPreEventosSelecionavel:number = 0;
  producaoFotografica:any;
  producaoFotograficaCheckbox:any;
  producaoFotograficaSelecionavel:any;
  producaoFotograficaCheckboxSoma:number = 0;
  somaProducaoFotograficaSelecionavel:any = 0;
  formaturas:any;
  formaturasCheckbox:any;
  formaturasSelecionavel:any;
  formaturasCheckboxSoma:number = 0;
  somaFormaturasSelecionavel:number = 0;
  arrayPreEventos:any = [];
  arrayProdFoto:any = [];
  arrayFormaturas:any = [];
  anoDatFim:any = "";
  cidade:any = "";
  formandos:number = 0;
  percentualAssessoria:number = 0.2;
  assessoriaCerimonial:number = 19990;
  numeroProposta:any = "";
  prestacoes:number = 0;
  arrayContatos:any = [];
  totalSomas:number = 0;
  totalPorFormandos:number = 0;
  totalPorFormandosPorMes:number = 0;
  block:boolean = false;
  fotosPagas:any = [];
  fotosGratis:any = [];
  totalFotos:number = 0;
  instituicaoInfantil:any = "";

  get contactFormGroup(){return this.detalhes.get('contacts') as FormArray;}

  constructor(public app:AppComponent, private location:Location, public auth:AuthService, private formBuilder: FormBuilder, private db: DataBaseService){
    this.consultor = auth.usuario; 
    if(this.consultor == ""){
      this.consultor = localStorage.getItem('usuarioTrends');
    }
    this.tipoUsuario = auth.tipoUsuario;
    if(this.tipoUsuario == ""){
      this.tipoUsuario = localStorage.getItem('tipoUsuarioTrends');
    }
   }

   ngOnDestroy(){
    this.contatosDasTurmas = [];
    this.block = false;
    this.numeroProposta = "";
    this.arrayTurmas = [];
    this.formandos = 0;
    this.prestacoes = 0;
    this.arrayContatos = [];
    this.arrayPreEventos = [];
    this.arrayProdFoto = [];
    this.arrayFormaturas = [];
    this.preEventosCheckboxSoma = 0;
    this.somaPreEventosSelecionavel = 0;
    this.producaoFotograficaCheckboxSoma = 0;
    this.somaProducaoFotograficaSelecionavel = 0;
    this.preEventosCheckboxSoma = 0;
    this.somaPreEventosSelecionavel = 0;
    this.assessoriaCerimonial = 19990;
    this.totalSomas = 0;
    this.totalPorFormandos = 0;
    this.totalPorFormandosPorMes = 0;
    this.imagesArray = [];
    this.inicio.reset();
    this.detalhes.reset();
   }

  ngOnInit(){
    this.block = false;
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0]; 
    this.dtHojePuro = new Date(Date.now() - 10800000)
    this.db.getCidades().subscribe((data:any) => {this.listaCidades = data.sort((a:any,b:any) => {
      if(a.nome < b.nome){return -1}
      if(a.nome > b.nome){return 1}
      return 1;
    });});
    this.db.getAssessoria().subscribe((data:any) => this.assessoria = data[0]);
    this.db.getImagensByGaleria("INFANTIL").subscribe((data:any) => {this.images = data;});
    this.inicio = this.formBuilder.group({
      cidadeEscolhida : [null, Validators.required],
      instituicoes: [null, Validators.required], 
      curso: [null, Validators.required],
      turma: [null, Validators.required]
    });
    this.detalhes = this.formBuilder.group({
      qtdeFormandos : [null, [Validators.required, Validators.min(1)]],
      dataInicio: [null, Validators.required],
      dataFim: [null, Validators.required],
      comissao: [false, Validators.requiredTrue],
      contacts: this.formBuilder.array([])
  });
    this.contactList = this.detalhes.get('contacts') as FormArray;
    this.detalhes.get('dataInicio')?.setValue(this.dtHojePuro);
    let ctrl = 1;
    let fotosPagas:any = [];
    let fotosGratis:any = [];
    this.db.getFotos().subscribe((data:any) => { 
      data.forEach((item:any) => {
        if(item.cobra == true){
          fotosPagas.push(item)
        }else{
          fotosGratis.push(item)
        }
        if(data.length == ctrl){
          this.fotosPagas = fotosPagas.sort((a:any,b:any) => {
            if(a.posicao > b.posicao){return 1;}
            if(a.posicao < b.posicao){return -1;}
            return 1;
          });
          this.fotosGratis = fotosGratis.sort((a:any,b:any) => {
            if(a.posicao > b.posicao){return 1;}
            if(a.posicao < b.posicao){return -1;}
            return 1;
          });
        }else{
          ctrl++
        }
      })
    });
  }
 
  selecionarCidade(key:any){
    if(key != "SELECIONAR"){
      this.cidade = key;
      this.inicio.get('curso')?.setValue("");
      this.inicio.get('turma')?.setValue("");
      this.inicio.get('instituicoes')?.setValue("");
      this.arrayTurmas = [];
      this.listaInstituicoes = [];
      this.instituicaoCheck = false
      this.cursoCheck = false;
      this.db.getInstituicoesInfantilByCidade(key).subscribe((data:any)=>{
      this.listaInstituicoes = data.sort((a:any,b:any) => {
        if(a.nome < b.nome){return -1}
        if(a.nome > b.nome){return 1}
        return 1;
      });
      if(this.listaInstituicoes.length == 0){
        this.app.openAlert("Não há Instituições Cadastradas Nesta Cidade!");
        this.cidadeCheck = false;
      }else {
        this.cidadeCheck = true;
      }});
      this.db.getEventosByCidade(key).subscribe((data:any) => this.listaEventos = data);
    }else{
      this.arrayTurmas = [];
      this.listaInstituicoes = [];
      this.instituicaoCheck = false
      this.cursoCheck = false;
      this.listaTurmas = []; 
      this.turmaCheck = false;
      this.inicio.get('curso')?.setValue(null);
      this.inicio.get('turma')?.setValue(null);
      this.inicio.get('instituicoes')?.setValue(null);
    }
  }
 
  selecionarInstituicao(){
    this.inicio.get('instituicoes')?.setValue(this.instituicaoInfantil.nome)
    this.inicio.get('curso')?.setValue(null);
    this.listaTurmas = []; 
    this.listaCursos = [{"nome":"Maternal I"},{"nome":"Maternal II"},{"nome":"Maternal III"},{"nome":"Pré I"},{"nome":"Pré II"},{"nome":"Pré III"}];
    this.instituicaoCheck = true
  }

  limparTurmas(){
    this.listaTurmas = [];
    this.inicio.get('curso')?.setValue(null);
  }

  selecionarCurso(key:any){
    this.db.getTurmasByCursoOrcamento(key,this.inicio.get('instituicoes')?.value,this.inicio.get('cidadeEscolhida')?.value).subscribe((data:any)=>{
      this.listaTurmas = data.sort((a:any,b:any) => {if(a.ano < b.ano){return -1}else{return 1}});
      if(this.listaTurmas.length == 0){
        this.app.openAlert("Não há Turmas Cadastradas Neste Curso!");
        this.cursoCheck = false;
      }else{
        this.cursoCheck = true;
      }
    })
  }

  turmaSelected(e:any, id:any){
    if(window.confirm("Deseja Adicionar Essa Turma ao Projeto?")){
      this.db.getTurmaById(id).subscribe((data:any)=>{
        this.turmaSelecionada = data;
        this.turmaTurmaSelecionada = this.turmaSelecionada.turma;
        e.srcElement.parentElement.className = e.srcElement.parentElement.className == "turmasSelect ng-star-inserted" ? "turmasSelected ng-star-inserted" : '';
      if(this.arrayTurmas == ""){
        this.arrayTurmas.push({
          turmaId : id,
          turma : this.turmaSelecionada.turma,
          curso: this.turmaSelecionada.curso,
          periodoTurma : this.turmaSelecionada.periodo,
          instituicao : this.turmaSelecionada.instituicao,
          anoTurma : this.turmaSelecionada.ano,
          nomeComissao : this.turmaSelecionada.nomeComissao,
        })
        this.detalhes.get('dataFim')?.setValue(this.turmaSelecionada.dataFim);
        this.detalhes.get('dataInicio')?.setValue(this.dataHoje);
        this.diferencaMeses(this.dtHojePuro, this.turmaSelecionada.dataFim);
        this.addContatoTurma(this.turmaSelecionada,id);
        }else{
        let idx = this.arrayTurmas.map((items:any) => {
          return items.turmaId
          }).indexOf(id); 
        if(idx >= 0){
           this.app.openAlert("Essa Turma Já Foi Selecionada!")
          return;
          }else{
            this.arrayTurmas.push({
              turmaId : id,
              turma : this.turmaSelecionada.turma,
              curso: this.turmaSelecionada.curso,
              periodoTurma : this.turmaSelecionada.periodo,
              instituicao : this.turmaSelecionada.instituicao,
              anoTurma : this.turmaSelecionada.ano,
              nomeComissao : this.turmaSelecionada.nomeComissao,
            });
            this.addContatoTurma(this.turmaSelecionada,id);
          }
       }
      this.inicio.get('turma')?.setValue(this.arrayTurmas);
    });
  }
 }

  addContatoTurma(turma:any,id:any){
    if(turma.presidente != null && turma.presidente != ""){
      this.contatosDasTurmas.push({
        nome:turma.presidente,
        telefone:turma.presidenteTelefone,
        email:turma.presidenteEmail,
        funcao:"Presidente",
        turmaId:id
      })
    };
    if(turma.vicePresidente != null && turma.vicePresidente != ""){
      this.contatosDasTurmas.push({
        nome:turma.vicePresidente,
        telefone:turma.vicePresidenteTelefone,
        email:turma.vicePresidenteEmail,
        funcao:"Vice Presidente",
        turmaId:id
      })
    };
    if(turma.primeiroSecretario != null && turma.primeiroSecretario != ""){
      this.contatosDasTurmas.push({
        nome:turma.primeiroSecretario,
        telefone:turma.primeiroSecretarioTelefone,
        email:turma.primeiroSecretarioEmail,
        funcao:"1º Secretário",
        turmaId:id
      })
    };
    if(turma.segundoSecretario != null && turma.segundoSecretario != ""){
      this.contatosDasTurmas.push({
        nome:turma.segundoSecretario,
        telefone:turma.segundoSecretarioTelefone,
        email:turma.segundoSecretarioEmail,
        funcao:"2º Secretário",
        turmaId:id
      })
    };
    if(turma.terceiroSecretario != null && turma.terceiroSecretario != ""){
      this.contatosDasTurmas.push({
        nome:turma.terceiroSecretario,
        telefone:turma.terceiroSecretarioTelefone,
        email:turma.terceiroSecretarioEmail,
        funcao:"3º Secretário",
        turmaId:id
      })
    };
    if(turma.tesoureiro != null && turma.tesoureiro != ""){
      this.contatosDasTurmas.push({
        nome:turma.tesoureiro,
        telefone:turma.tesoureiroTelefone,
        email:turma.tesoureiroEmail,
        funcao:"Tesoureiro",
        turmaId:id
      })
    };
    if(turma.primeiroConsFical != null && turma.primeiroConsFical != ""){
      this.contatosDasTurmas.push({
        nome:turma.primeiroConsFical,
        telefone:turma.primeiroConsFicalTelefone,
        email:turma.primeiroConsFicalEmail,
        funcao:"1º Conselheiro Fiscal",
        turmaId:id
      })
    };
    if(turma.segundoConsFical != null && turma.segundoConsFical != ""){
      this.contatosDasTurmas.push({
        nome:turma.segundoConFicals,
        telefone:turma.segundoConsFicalTelefone,
        email:turma.segundoConsFicalEmail,
        funcao:"2º Conselheiro Fiscal",
        turmaId:id
      })
    };
    if(turma.terceiroConsFiscal != null && turma.terceiroConsFiscal != ""){
      this.contatosDasTurmas.push({
        nome:turma.terceiroConsFiscal,
        telefone:turma.terceiroConsFiscalTelefone,
        email:turma.terceiroConsFiscalEmail,
        funcao:"3º Conselheiro Fiscal",
        turmaId:id
      })
    };
    this.contatosDasTurmas.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}return 1});
  }

  removeContatoTurma(id:any){
    if(this.contatosDasTurmas.length > 0){
      for(let i = 0; i < this.contatosDasTurmas.length; i++){
        if(this.contatosDasTurmas[i].turmaId == id){this.contatosDasTurmas.splice(i, 1);}
      }
    }
  }

  adicionarContatos(){
    if(this.contatosDasTurmas.length > 0){
      this.contatosDasTurmas.forEach((item:any) => {
        this.addContactTurma(item.nome,item.telefone,item.email)
      })
    }else{
      this.addContact();
      this.addContact();
      this.addContact();
    }
  }

  removeTurma(id:any){
    if(window.confirm("Deseja Remover Essa Turma do Projeto?")){
    var removeIndex = this.arrayTurmas.map((item:any) => {
        return item.turmaId;   
      }).indexOf(id); 
      if(removeIndex >= 0){
        this.removeContatoTurma(id);
        this.arrayTurmas.splice(removeIndex, 1);
      } 
      this.inicio.get('turma')?.setValue(this.arrayTurmas);
    }
  }

  diferencaMeses(dtInicio:any, dtFim:any){
      let dataFim = new Date(dtFim);
      let dataInicio = new Date(dtInicio);
      if(dtInicio < this.dataHoje){
        this.app.openAlert("A Data Inicio Não Pode Ser Menor do que a Data de Hoje!");
        this.detalhes.get('dataInicio')?.setValue(null);
        return;
      }
      if(dtFim < this.dataHoje){
        this.app.openAlert("A Data Fim Não Pode Ser Menor do que a Data de Hoje!");
        this.detalhes.get('dataFim')?.setValue(null);
        return;
      }
      var d1Y = dataInicio.getFullYear();
      var d2Y = dataFim.getFullYear();
      var d1M = dataInicio.getMonth();
      var d2M = dataFim.getMonth();  
      this.prestacoes = (d2M+12*d2Y)-(d1M+12*d1Y);
      this.anoDatFim = d2Y;
  }

  createContact(): FormGroup {
    return this.formBuilder.group({
      nome: [null, Validators.compose([Validators.required])],
      telefone: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required])]
    });
  }

  createContactTurma(nome:any,telefone:any,email:any): FormGroup {
    return this.formBuilder.group({
      nome: [nome, Validators.compose([Validators.required])],
      telefone: [telefone, Validators.compose([Validators.required])],
      email: [email, Validators.compose([Validators.required])]
    });
  }

  addContact() {
    this.contactList.push(this.createContact());
  }

  addContactTurma(nome:any,telefone:any,email:any) {
    this.contactList.push(this.createContactTurma(nome,telefone,email));
  }

  removeContact(index:any) {
    this.contactList.removeAt(index);
  }

  getContactsFormGroup(index:any): FormGroup {
    const formGroup = this.contactList.controls[index] as FormGroup;
    return formGroup;
  }

  salvarContatos(){
   if(this.detalhes.get('contacts').status == "INVALID"){
      this.app.openAlert("Preencha os Dados da Comissão Corretamente!")
   }else{
    this.detalhes.get('contacts').status;
    this.arrayContatos = [];
    this.arrayContatos = this.contactList.value;
    if(this.arrayContatos.length < 3){
      this.detalhes.get('comissao')?.setValue(false);
       this.app.openAlert("A Comissão Deve Ter no Minimo 3 Integrantes!");
    }else{
    this.detalhes.get('comissao')?.setValue(true);
    this.formandos = this.detalhes.get('qtdeFormandos')?.value;
    this.puxarPrecos();
    this.block = true;
     this.app.openAlert("Contatos Adicionados Com Sucesso!");
    }
  }
}

puxarPrecos(){
  this.db.getFotos().subscribe((data:any) => {
    data.forEach((item:any) => {
      if(item.cobra == true){
        this.totalFotos += item.valor
      }
    })
  })
  let cidade:any = this.inicio.get('cidadeEscolhida')?.value;
  //Pré Eventos Selecionavel e CheckBox
  this.db.getPreEventosByTipo("selecionavel").subscribe((data:any) => {
  this.preEventosSelecionavel = data.sort((a:any,b:any) => {
    if(a.posicao > b.posicao){return 1;}else{return -1;}
  })
  this.db.getPreEventosByTipo("checkbox").subscribe((data:any) => {
    this.preEventosCheckbox = data.sort((a:any,b:any) => {
      if(a.posicao > b.posicao){    return 1;}else{return -1}
    });
    this.preEventosCheckbox.forEach((item:any) => {
      if(item.marcado == true){
        let idx = item.valores.map((p:any) => {
          return p.cidade;
        }).indexOf(cidade);
        if(idx >= 0){
          let obj = [
            item.titulo, 
             this.replaceString(item.descricao),  
            item.valores[idx].valor,
            item.marcado,
            item.formando
          ];
          this.aPreEventosSoma(obj,'marcado',item.posicao);
        }
     }
    })
  });
});
// Produção Fotográfica Selecionavel e CheckBox  
this.db.getProducaoFotograficaByTipo("selecionavel").subscribe((data:any) => {
  this.producaoFotograficaSelecionavel = data.sort((a:any,b:any) => {
    if(a.posicao > b.posicao){return 1;}else{return -1;}
  });
  this.db.getProducaoFotograficaByTipo("checkbox").subscribe((data:any) => {
    this.producaoFotograficaCheckbox = data.sort((a:any,b:any) => {
      if(a.posicao > b.posicao){return 1;}else{return -1;}
    });
    this.producaoFotograficaCheckbox.forEach((item:any) => {
      if(item.marcado == true){
        let idx = item.valores.map((p:any) => {
          return p.cidade;
        }).indexOf(cidade);
        if(idx >= 0){
          let obj = [
            item.titulo, 
            this.replaceString(item.descricao),  
            item.valores[idx].valor,
            item.marcado,
            item.formando
          ];
          this.aProducaoFotograficaSoma(obj,'marcado',item.posicao);
        }
      }
    })
  });
});
// Formaturas Selecionavel e CheckBox  
this.db.getFormaturasByTipo("selecionavel").subscribe((data:any) => {
  this.formaturasSelecionavel = data.sort((a:any,b:any) => {
    if(a.posicao > b.posicao){return 1;}else{return -1;}
  });
  this.db.getFormaturasByTipo("checkbox").subscribe((data:any) => {
    this.formaturasCheckbox = data.sort((a:any,b:any) => {
      if(a.posicao > b.posicao){return 1;}else{return -1;}
    });
    this.formaturasCheckbox.forEach((item:any) => {
      if(item.marcado == true){
        let idx = item.valores.map((p:any) => {
          return p.cidade;
        }).indexOf(cidade);
        if(idx >= 0){
          let obj = [
            item.titulo, 
            this.replaceString(item.descricao),  
            item.valores[idx].valor,
            item.marcado,
            item.formando
          ];
          this.aFormaturasSoma(obj,'marcado',item.posicao);
        }
        }
      })
    });
  });
}

selecionarAssessoria(e:any){
  let valor = Number(e.srcElement.value);
  this.percentualAssessoria = valor/100;
}

aPreEventosSoma(e:any, origem:any,pos:any){
  let tituloArray;
  let descricaoArray;
  let formandos = this.formandos;
  let valores;
  let marcado;
  let porFormando;
  let valor;
  if(origem == 'marcado'){
    tituloArray = e[0];
      descricaoArray = this.replaceString(e[1]);
    valores =  Number(e[2]);
    marcado = e[3];
    porFormando = e[4];
  }else{
    let innerText = e.srcElement.labels[0].innerText
    let pos = innerText.indexOf('\n');
    tituloArray = innerText.slice(0, pos);
    descricaoArray =  this.replaceString(innerText.slice(pos+1));
    valores = Number(e.srcElement.value);
    marcado = e.srcElement.checked;
    porFormando = e.srcElement.required;
  }
  if (porFormando == true){
      valor = valores * formandos;
      }else{
      valor = valores
      }
  if (marcado == true){
    this.preEventosCheckboxSoma += valor;
    this.arrayPreEventos.push({
      titulo: tituloArray,
      descricao: descricaoArray,
      subTotal: valores,
      valor: valor,
      tipo:"checkbox",
      posicao:pos
    });
    this.somarTotal();
    }else {
    this.preEventosCheckboxSoma -= valor;
    var removeIndex = this.arrayPreEventos.map((item:any) => {
      return item.titulo;   
    }).indexOf(tituloArray); 
      this.arrayPreEventos.splice(removeIndex, 1);
      this.somarTotal();
    }
  }

aPreEventosSomaSelecionavel(e:any,pos:any){
  let titulo = e.srcElement.value.split(',')[1];
  let descricao = e.srcElement.selectedOptions[0].title;
  let jaTem:any;
  let valorTirar = 0;
  let porFormando = e.srcElement.selectedOptions[0].translate;
  let vlrTotal = 0;
  let vlr = e.srcElement.value.split(',')[0]; 
  if(e.srcElement.value.split(',')[0] == "zerar"){
  if(this.arrayPreEventos == ""){
  this.somarTotal();
    }else{
      this.arrayPreEventos.map((items:any) => {
        if(titulo == items.titulo){
          jaTem = true;
          valorTirar = items.valor;
        }
      });
      if(jaTem == true){
        this.somaPreEventosSelecionavel -= valorTirar;
        var removeIndex = this.arrayPreEventos.map((items:any) => {
          return items.titulo;   
        }).indexOf(titulo); 
        this.arrayPreEventos.splice(removeIndex, 1);
        this.somarTotal();
      }else{
        this.somarTotal();
       }
    }
    }else{
      vlr = Number(e.srcElement.value.split(',')[0]);
    if (porFormando){
      vlrTotal = (vlr * this.formandos) * 8;
    }else{
      vlrTotal = vlr;
    }
    if(this.arrayPreEventos == ""){
      this.arrayPreEventos.push({
        titulo: titulo,
        descricao: descricao,
        subTotal: vlr,
        valor: vlrTotal,
        tipo:"selecionavel",
        posicao:pos
      })
      this.somaPreEventosSelecionavel += vlrTotal;
      this.somarTotal();
    }else{
      this.arrayPreEventos.map((items:any) => {
      if(titulo == items.titulo){
        jaTem = true;
        valorTirar = items.valor;
      }
    });
    if(jaTem == true){
      this.somaPreEventosSelecionavel -= valorTirar;
      this.somarTotal();
      var removeIndex = this.arrayPreEventos.map((items:any) =>  {
        return items.titulo;   
       }).indexOf(titulo); 
       this.arrayPreEventos.splice(removeIndex, 1);
       this.arrayPreEventos.push({
        titulo: titulo,
        descricao: descricao,
        subTotal: vlr,
        valor: vlrTotal,
        tipo:"selecionavel",
        posicao:pos
      })
      this.somaPreEventosSelecionavel += vlrTotal;
      this.somarTotal();
    }else{
      this.arrayPreEventos.push({
      titulo: titulo,
      descricao: descricao,
      subTotal: vlr,
      valor: vlrTotal,
      tipo:"selecionavel",
      posicao:pos
    })
    this.somaPreEventosSelecionavel += vlrTotal;
    this.somarTotal();
      }
    }
  }
}

aProducaoFotograficaSoma(e:any, origem:any,pos:any){
  let tituloArray;
  let descricaoArray;
  let formandos = this.formandos;
  let valores;
  let marcado;
  let porFormando;
  let valor;
    if(origem == 'marcado'){
      tituloArray = e[0];
       descricaoArray = this.replaceString(e[1]);
      valores =  Number(e[2]);
      marcado = e[3];
      porFormando = e[4];
    }else{
      let innerText = e.srcElement.labels[0].innerText
      let pos = innerText.indexOf('\n');
      tituloArray = innerText.slice(0, pos);
      descricaoArray =  this.replaceString(innerText.slice(pos+1));
      valores = Number(e.srcElement.value);
      marcado = e.srcElement.checked;
      porFormando = e.srcElement.required;
    }
    if (porFormando == true){
        valor = valores * formandos;
        }else{
        valor = valores
        }
    if (marcado == true){
      this.producaoFotograficaCheckboxSoma += valor;
      this.arrayProdFoto.push({
        titulo: tituloArray,
        descricao: descricaoArray,
        subTotal: valores,
        valor: valor,
        tipo:"checkbox",
        posicao:pos
      });
      this.somarTotal();
      }else {
      this.producaoFotograficaCheckboxSoma -= valor;
      var removeIndex = this.arrayProdFoto.map((item:any) => {
        return item.titulo;   
      }).indexOf(tituloArray); 
        this.arrayProdFoto.splice(removeIndex, 1);
        this.somarTotal();
      }
}

aProducaoFotograficaSomaSelecionavel(e:any,pos:any){
let titulo = e.srcElement.value.split(',')[1];
let jaTem:any;
let valorTirar = 0;
let porFormando = e.srcElement.selectedOptions[0].translate;
let descricao = e.srcElement.selectedOptions[0].title;
let vlrTotal = 0;
let vlr = e.srcElement.value.split(',')[0]; 
if(e.srcElement.value.split(',')[0] == "zerar"){
if(this.arrayProdFoto == ""){
this.somarTotal();
  }else{
    this.arrayProdFoto.map((items:any) => {
      if(titulo == items.titulo){
        jaTem = true;
        valorTirar = items.valor;
      }
    });
    if(jaTem == true){
      this.somaProducaoFotograficaSelecionavel -= valorTirar;
      var removeIndex = this.arrayProdFoto.map((items:any) =>  {
        return items.titulo;   
      }).indexOf(titulo); 
      this.arrayProdFoto.splice(removeIndex, 1);
      this.somarTotal();
    }else{
      this.somarTotal();
     }
  }
  }else{
    vlr = Number(e.srcElement.value.split(',')[0]);
  if(porFormando){
    vlrTotal = ((vlr * this.formandos) * 8);
  }else{
    vlrTotal = vlr;
  }
  if(this.arrayProdFoto == ""){
    this.arrayProdFoto.push({
      titulo: titulo,
      descricao: descricao,
      subTotal: vlr,
      valor: vlrTotal,
      tipo:"selecionavel",
      posicao:pos
    })
    this.somaProducaoFotograficaSelecionavel += vlrTotal;
    this.somarTotal();
  }else{
    this.arrayProdFoto.map((items:any) => {
    if(titulo == items.titulo){
      jaTem = true;
      valorTirar = items.valor;
    }
  });
  if(jaTem == true){
    this.somaProducaoFotograficaSelecionavel -= valorTirar;
    this.somarTotal();
    var removeIndex = this.arrayProdFoto.map((items:any) =>  {
      return items.titulo;   
     }).indexOf(titulo); 
     this.arrayProdFoto.splice(removeIndex, 1);
     this.arrayProdFoto.push({
      titulo: titulo,
      descricao: descricao,
      subTotal: vlr,
      valor: vlrTotal,
      tipo:"selecionavel",
      posicao:pos
    })
    this.somaProducaoFotograficaSelecionavel += vlrTotal;
    this.somarTotal();
  }else{
    this.arrayProdFoto.push({
    titulo: titulo,
    descricao: descricao,
    subTotal: vlr,
    valor: vlrTotal,
    tipo:"selecionavel",
    posicao:pos
  })
  this.somaProducaoFotograficaSelecionavel += vlrTotal;
  this.somarTotal();
    }
  }
}
} 

aFormaturasSoma(e:any, origem:any,pos:any){
  let tituloArray;
  let descricaoArray;
  let formandos = this.formandos;
  let valores;
  let marcado;
  let porFormando;
  let valor;
  if(origem == 'marcado'){
    tituloArray = e[0];
      descricaoArray = this.replaceString(e[1]);
    valores =  Number(e[2]);
    marcado = e[3];
    porFormando = e[4];
  }else{
    let innerText = e.srcElement.labels[0].innerText
    let pos = innerText.indexOf('\n');
    tituloArray = innerText.slice(0, pos);
    descricaoArray =  this.replaceString(innerText.slice(pos+1));
    valores = Number(e.srcElement.value);
    marcado = e.srcElement.checked;
    porFormando = e.srcElement.required;
  }
  if (porFormando == true){
      valor = valores * formandos;
      }else{
      valor = valores
      }
  if (marcado == true){
    this.formaturasCheckboxSoma += valor;
    this.arrayFormaturas.push({
      titulo: tituloArray,
      descricao: descricaoArray,
      subTotal: valores,
      valor: valor,
      tipo:"checkbox",
      posicao:pos
    });
    this.somarTotal();
    }else {
    this.formaturasCheckboxSoma -= valor;
    var removeIndex = this.arrayFormaturas.map((item:any) => {
      return item.titulo;   
    }).indexOf(tituloArray); 
      this.arrayFormaturas.splice(removeIndex, 1);
      this.somarTotal();
    }
  }

aFormaturasSomaSelecionavel(e:any,pos:any){
  let titulo = e.srcElement.value.split(',')[1];
  let descricao = e.srcElement.selectedOptions[0].title;
  let jaTem:any;
  let valorTirar = 0;
  let porFormando = e.srcElement.selectedOptions[0].translate;
  let vlrTotal = 0;
  let vlr = e.srcElement.value.split(',')[0]; 
  if(e.srcElement.value.split(',')[0] == "zerar"){
  if(this.arrayFormaturas == ""){
  this.somarTotal();
    }else{
      this.arrayFormaturas.map((items:any) => {
        if(titulo == items.titulo){
          jaTem = true;
          valorTirar = items.valor;
        }
      });
      if(jaTem == true){
        this.somaFormaturasSelecionavel -= valorTirar;
        var removeIndex = this.arrayFormaturas.map((items:any) => {
          return items.titulo;   
        }).indexOf(titulo); 
        this.arrayFormaturas.splice(removeIndex, 1);
        this.somarTotal();
      }else{
        this.somarTotal();
       }
    }
    }else{
      vlr = Number(e.srcElement.value.split(',')[0]);
    if (porFormando){
      vlrTotal = (vlr * this.formandos) * 8;
    }else{
      vlrTotal = vlr;
    }
    if(this.arrayFormaturas == ""){
      this.arrayFormaturas.push({
        titulo: titulo,
        descricao: descricao,
        subTotal: vlr,
        valor: vlrTotal,
        tipo:"selecionavel",
        posicao:pos
      })
      this.somaFormaturasSelecionavel += vlrTotal;
      this.somarTotal();
    }else{
      this.arrayFormaturas.map((items:any) => {
      if(titulo == items.titulo){
        jaTem = true;
        valorTirar = items.valor;
      }
    });
    if(jaTem == true){
      this.somaFormaturasSelecionavel -= valorTirar;
      this.somarTotal();
      var removeIndex = this.arrayFormaturas.map((items:any) =>  {
        return items.titulo;   
       }).indexOf(titulo); 
       this.arrayFormaturas.splice(removeIndex, 1);
       this.arrayFormaturas.push({
        titulo: titulo,
        descricao: descricao,
        subTotal: vlr,
        valor: vlrTotal,
        tipo:"selecionavel",
        posicao:pos
      })
      this.somaFormaturasSelecionavel += vlrTotal;
      this.somarTotal();
    }else{
      this.arrayFormaturas.push({
      titulo: titulo,
      descricao: descricao,
      subTotal: vlr,
      valor: vlrTotal,
      tipo:"selecionavel",
      posicao:pos
    })
    this.somaFormaturasSelecionavel += vlrTotal;
    this.somarTotal();
      }
    }
  }
}

somarTotal(){
  let totalSemAssessoria = 0;
  totalSemAssessoria = this.preEventosCheckboxSoma + this.somaPreEventosSelecionavel + this.producaoFotograficaCheckboxSoma + this.somaProducaoFotograficaSelecionavel + this.formaturasCheckboxSoma + this.somaFormaturasSelecionavel;
  let calculoAssessoria = 0;
  calculoAssessoria = totalSemAssessoria * this.percentualAssessoria;
  if(calculoAssessoria <= 19990){
    this.assessoriaCerimonial = 19990;
    this.totalSomas = totalSemAssessoria + 19990 + this.totalFotos;
  } else {
    this.assessoriaCerimonial = calculoAssessoria;
    this.totalSomas = totalSemAssessoria + calculoAssessoria + this.totalFotos;
  }
  // Total Orçamento Por Formandos
  this.totalPorFormandos =  this.totalSomas / this.formandos;
  //Total Orçamento Por Formandos Por Mês
  this.totalPorFormandosPorMes = this.totalPorFormandos / this.prestacoes;

}

openModal(url:any, i:any){
  this.indexImage = i;
  let modal:any = document.getElementById('myModalOrcamentoInf')
  modal.style.display = "block";
  this.imagemSelecionada = url;
}
 
addImgGaleria(e:any){
  let selecionado = e.options[0]._selected;
  let urlImg = e.options[0]._value;
  if(selecionado == true){
    this.imagesArray.push({
      url : urlImg
    })
  }else{
    var items = this.imagesArray.map((item:any) => {
      return item.url;   
    }).indexOf(urlImg); 
    this.imagesArray.splice(items, 1);
  }
}

nextImg(){
  let i = this.indexImage
  if((this.images.length - 1) == i){
    this.imagemSelecionada = this.images[0].url;
    this.indexImage = 0;
  }else{
    i++
    this.imagemSelecionada = this.images[i].url;
    this.indexImage++
  }
}

prevImg(){
  let i = this.indexImage
  if(i == 0){
    this.imagemSelecionada = this.images[this.images.length - 1].url;
    this.indexImage = this.images.length - 1;
  }else{
    i--
    this.imagemSelecionada = this.images[i].url;
    this.indexImage--
  }
}

closeModal(){
  let modal:any = document.getElementById('myModalOrcamentoInf')
  modal.style.display = "none";
}

salvarOrcamento(){
  this.somarTotal(); 
  let jsonDate = new Date().toJSON();
  let hora = new Date(jsonDate).toLocaleTimeString();
  this.db.getProposta().subscribe((prop:any) => {
  this.proposta = prop[0];
  this.numeroProposta = this.proposta.valor;
  let numPropostaSplit;
  let numPropostaNovaVersion;
  let qtdeItemsArray = this.arrayTurmas.length
  let contagemFor = 0;
  let numeroProposta = this.numeroProposta;
  let propNew = false;
  this.arrayTurmas.forEach((item:any) => {
    this.db.getOrcamentoByTurmaId(item.turmaId).subscribe((data:any) => {
      if(data.length > 0){
        numPropostaSplit = data[0].nProposta.split('/');
        numPropostaNovaVersion = String(parseInt(numPropostaSplit[1]) + 1);
        numeroProposta = String(numPropostaSplit[0]+"/"+numPropostaNovaVersion);
        this.numeroProposta = numeroProposta;
        propNew = false;
      }else{
        this.numeroProposta = numeroProposta;
        propNew = true;
      }
      let obj = { 
        nProposta : this.numeroProposta,
        data : this.dataHoje, 
        hora: hora,
        consultor : this.consultor, 
        tipo:"INFANTIL",
        cidade :  this.inicio.get('cidadeEscolhida')?.value, 
        instituicao : item.instituicao, 
        curso : item.curso, 
        turmaId : item.turmaId,
        turma :  item.turma,
        ano :  item.anoTurma,
        arrayTurmas : this.arrayTurmas,
        formandos : this.formandos, 
        localBaile : null,
        localJantar : null,
        dtInicio : this.detalhes.get('dataInicio')?.value, 
        dtFim : this.detalhes.get('dataFim')?.value, 
        diasEvento : 1,
        parcelas : this.prestacoes, 
        contatos : this.arrayContatos, 
        preEventos : this.arrayPreEventos.sort((a:any,b:any) => {if(a.tipo < b.tipo){return 1;}if(a.tipo > b.tipo){return -1;}if(a.posicao > b.posicao){return 1;}if(a.posicao < b.posicao){return -1;}return 1}),
        producaoFotografica : this.arrayProdFoto.sort((a:any,b:any) => {if(a.tipo < b.tipo){return 1;}if(a.tipo > b.tipo){return -1;}if(a.posicao > b.posicao){return 1;}if(a.posicao < b.posicao){return -1;}return 1}),
        formaturas : this.arrayFormaturas.sort((a:any,b:any) => {if(a.tipo < b.tipo){return 1;}if(a.tipo > b.tipo){return -1;}if(a.posicao > b.posicao){return 1;}if(a.posicao < b.posicao){return -1;}return 1}),
        totalpreEventos : this.preEventosCheckboxSoma + this.somaPreEventosSelecionavel,
        totalProducaoFotografica : this.producaoFotograficaCheckboxSoma + this.somaProducaoFotograficaSelecionavel,
        totalFormaturas : this.formaturasCheckboxSoma + this.somaFormaturasSelecionavel,
        missa : null,
        totalMissa : null,
        colacao:null,
        totalColacao : null,
        jantar : null,
        totalJantar : null,
        baile : null,
        totalBaile : null,
        assessoriaCerimonial : this.assessoriaCerimonial,
        totalSomas : this.totalSomas, 
        totalPorFormandos : this.totalPorFormandos, 
        totalPorFormandosPorMes : this.totalPorFormandosPorMes, 
        imagensOrcamento : this.imagesArray, 
        umaRifaSemestre : null, 
        umaRifaAno : null, 
        duasRifaSemestre : null, 
        duasRifaAno : null, 
        tresRifaSemestre : null, 
        tresRifaAno : null, 
        cervejada6totalIngressos : null, 
        cervejada6lucro : null, 
        cervejada6lucroTotalTurma : null, 
        cervejada12totalIngressos : null, 
        cervejada12lucro : null, 
        cervejada12lucroTotalTurma : null, 
        convite8jantar10baile : null, 
        convite8jantar16baile : null, 
        convite8jantar20baile : null, 
        convite8jantar10bailePorFormando : null, 
        convite8jantar16bailePorFormando : null, 
        convite8jantar20bailePorFormando : null, 
        convite8jantar10baileVlrParcela : null, 
        convite8jantar16baileVlrParcela : null, 
        convite8jantar20baileVlrParcela : null, 
        convite10jantar10baile : null, 
        convite10jantar16baile : null, 
        convite10jantar20baile : null, 
        convite10jantar10bailePorFormando : null, 
        convite10jantar16bailePorFormando : null, 
        convite10jantar20bailePorFormando : null, 
        convite10jantar10baileVlrParcela : null, 
        convite10jantar16baileVlrParcela : null, 
        convite10jantar20baileVlrParcela : null, 
        convite16jantar10baile : null, 
        convite16jantar16baile : null, 
        convite16jantar20baile : null, 
        convite16jantar10bailePorFormando : null, 
        convite16jantar16bailePorFormando : null, 
        convite16jantar20bailePorFormando : null, 
        convite16jantar10baileVlrParcela : null, 
        convite16jantar16baileVlrParcela : null, 
        convite16jantar20baileVlrParcela : null, 
        convite20jantar10baile : null, 
        convite20jantar16baile : null, 
        convite20jantar20baile : null, 
        convite20jantar10bailePorFormando : null, 
        convite20jantar16bailePorFormando : null, 
        convite20jantar20bailePorFormando : null, 
        convite20jantar10baileVlrParcela : null, 
        convite20jantar16baileVlrParcela : null, 
        convite20jantar20baileVlrParcela : null, 
        rendimentoMensalIndividual : null, 
        rendimentoTotalIndividual : null, 
        rendimentoMensalTurma : null, 
        rendimentoTotalTurma : null, 
        rendimentoVlrCorrigido : null, 
        rendimentoTotal : null
        }
      this.db.postOrcamento(obj).subscribe(res => {
        console.log(res);
        let jsonDate = new Date().toJSON();
        let horaHoje = new Date(jsonDate).toLocaleTimeString();
        let o = {
          data : this.dataHoje, 
          hora : horaHoje, 
          assunto : "Orçamento", 
          descricao : "Orçamento Infantil feito no dia "+this.dataHoje.split('-').reverse().join('/')+" às "+hora+" pelo Vendedor: "+this.consultor, 
          usuario : this.consultor, 
          idTurma : item.turmaId
          }
        this.db.postRelatorio(o).subscribe(res => {
          console.log(res);
          let ob = {
            data:this.dataHoje,
            hora:horaHoje,
            usuario: localStorage.getItem('usuarioTrends'),
            menu: "ORÇAMENTO",
            descricao: "Orçamento Infantil Criado para: "+item.instituicao+" / "+this.inicio.get('cidadeEscolhida')?.value+" - "+item.curso+" - "+item.turma+" - "+item.anoTurma
          }
          this.db.postLogs(ob).subscribe(res => {
            console.log(res);
            contagemFor++;
            if(contagemFor == qtdeItemsArray && propNew){
              this.attNumProposta();
            }
          },err => console.log(err));
        },err => console.log(err));
      }, err => {
        console.log(err);
         this.app.openAlert("Não Foi Possivel Salvar este Orçamento, Por Favor Tente Novamente!");
      });
    })
  }); 
})
}

attNumProposta(){
   let numPropSplit = this.numeroProposta.split('/');
   let numPropNovaVersion = String(parseInt(numPropSplit[0]) + 1);
   let numProp = String(numPropNovaVersion+'/1');
   let obj = {
    valor : numProp
  }
  this.db.patchProposta(this.proposta._id,obj).subscribe(res => {
     console.log(res);
    },err => {console.log(err);
        this.app.openAlert("Não Foi Possivel Atualizar o Número da Proposta, Contate o Programador!")
     });
}

print() {
  let arrTurmas = this.inicio.get('turma')?.value;
  let turmas = "";
  let estrutura;
  arrTurmas.forEach((item:any) => {
    estrutura = item.curso+"-"+item.turma+"-"+item.anoTurma+"-"+item.instituicao+"-";
    turmas = turmas+estrutura
  })
  document.title = turmas+this.anoDatFim+"-P"+this.numeroProposta;
  window.print();
  document.title = "Trends"
}

goBack(){
  this.location.back();
 }

 replaceString(string:any){
   if(string){
    let str = string.replace(/\%/gi,'%25');
    return str.replace(/\+/gi,'%2B').replace(/\!/gi,'%21').replace(/\&/gi,'%26').replace(/\#/gi,'%23').replace(/\;/gi,'%3B');
   }else{
     return string;
   }
}

}