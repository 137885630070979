import { Injectable } from '@angular/core';
import { DataBaseService } from './provider';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  pass:any = "";
  tipoUsuario:any = '';
  usuario:any = '';
  constructor(public db: DataBaseService, public route:Router) {}

  login(user:any){
    this.db.getUsuarioByEmail(user.email).toPromise().then((res:any) => {
        if(res == ''){
          alert("USUARIO NÃO ENCONTRADO!")
          return;
        }else{
          this.pass = res[0].senha;
       if(this.pass == user.password){
         if(res[0].situacao != "ATIVO"){
          alert("USUÁRIO BLOQUEADO! POR FAVOR ENTRAR EM CONTATO COM A TRENDS FORMATURAS!")
         }else{
          let storage = Math.random().toString(36).slice(-8);
          localStorage.setItem('tokenTrends', JSON.stringify(storage));
          this.tipoUsuario = res[0].tipo;
          this.usuario = res[0].nome;
          localStorage.setItem('tipoUsuarioTrends', this.tipoUsuario);
          localStorage.setItem('usuarioTrends', this.usuario);
          localStorage.setItem('emailTrends', user.email);
          this.isLoggedIn();
          this.route.navigate(['/home']);
          setTimeout(() => {
            location.reload()
          },1000)
         }
      }else{
        alert("SENHA INCORRETA!")
        return;
      }}
    },
      err => {
        if(err.status < 200){
          alert("Por Favor Verifique Sua Conexão com a Internet")
        }
      }
    ).catch(err => console.log(err))
}

  logOut(){
    this.route.navigate(['/login']);
    localStorage.clear();
    this.isLoggedIn();
  }

  isLoggedIn(): boolean {
    var token: String = this.getToken();
    return token && token.length > 0;
}

getToken(): String {
  var token = localStorage.getItem('tokenTrends');
  return token ? token : "";
}

}   