import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataBaseService } from 'src/app/provider';
import { Location } from '@angular/common';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css']
})
export class AgendaComponent implements OnInit {
  agenda:any = [];
  todaAgenda:any = [];
  vendedores:any = [];
  dataInicio:any = "";
  dataFim:any = "";
  vendedor:any = "";
  assunto:any = "";
  descricao:any = "";
  turma:any = "";
  panelOpenState:boolean = false;
  dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0]; 
  assuntos:any = ["Apresentação Turma","Atendimento Presencial","Email", "Orçamento","Proposta Web","Reunião Comissão","Reunião Online","Telefonema","WhatsApp"];
  
  constructor(public app:AppComponent, public location:Location, public db:DataBaseService, public _router:Router) {}

  ngOnInit() {
    this.db.getAllAgenda().subscribe((data:any) => {
      this.agenda = data;
      this.todaAgenda = data;
    });
    this.db.getUsuarios().subscribe((vend:any) => {
      this.vendedores = vend;
    })
  }

  filterAgenda(dtInicio:any,dtFim:any,vendedor:any,assunto:any,descricao:any,turma:any){
    if(assunto || vendedor || dtInicio || dtFim || descricao || turma){
      if(!dtInicio){
        dtInicio = "2000-01-01T12:00:00.000Z";
      }else{
        dtInicio = dtInicio+'T12:00:00.000Z';
      }
      if(!dtFim){
        dtFim = "2200-01-01T12:00:00.000Z";
      }else{
        dtFim = dtFim+'T12:00:00.000Z';
      }
      this.agenda = this.todaAgenda.filter((item:any) => {
        return item.assunto.toLowerCase().indexOf(assunto.toLowerCase()) > -1 && item.vendedor.toLowerCase().indexOf(vendedor.toLowerCase()) > -1 && (item.start >= dtInicio && item.end <= dtFim) && item.descricao.toLowerCase().indexOf(descricao.toLowerCase()) > -1 && item.assunto.toLowerCase().indexOf(assunto.toLowerCase()) > -1 && item.turma.toLowerCase().indexOf(turma.toLowerCase()) > -1;
      });
    }else{
      this.agenda = this.todaAgenda;
    }
  }
  
fecharCompromisso(key:any){
  if(window.confirm('Deseja Realmente Colocar Esse Compromisso Como RESOLVIDO?')){
      let obj = {
        resolvido: true,
        background: '%231baa02'
      }
      this.db.patchAgenda(key._id,obj).subscribe(res => {
      console.log(res);
      let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let ob = {
        data:this.dataHoje,
        hora:hora,
        usuario: localStorage.getItem('usuarioTrends'),
        menu: "AGENDA",
        descricao: "Compromisso "+key.assunto+" da turma: "+key.turma+" marcado como resolvido"
      }
      this.db.postLogs(ob).subscribe(res => {
        console.log(res);
        this.app.openAlert("Compromisso Alterado para RESOLVIDO!")
      },err => console.log(err))
    }, err => console.log(err))
  }
}

goToTurma(id:any){
  if(id){
    this.db.getTurmaById(id).subscribe((t:any) => {
      if(t){
        this._router.navigate(['/turmas']);
        setTimeout(() => {
          this._router.navigate(['/turmas/'+id]);
        },100)
      }else{
        this.app.openAlert("Turma Inexistente!")
        }
    })
  }
}

goBack(){
  this.location.back();
  }

  excluirCompromisso(id:any){
    this.db.deleteAgenda(id).subscribe(res => {
      console.log(res);
      this.ngOnInit();
    },
    err => {
      console.log(err);
    });
  }

}
