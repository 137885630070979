<div id="myModalTurmasHome" class="modal">
    <div class="topVoltar fixo">
        <fa-icon [icon]="['fas', 'arrow-circle-left']" size="2x" (click)="closeModalTurmas()"></fa-icon>
      </div>
      <div class="bgInicio">
        <div class="contentHome">
            <div class="menuCol" routerLink="/turmas-infantil">
                <fa-icon [icon]="['fas', 'baby']" size="3x"></fa-icon>
                <br>Infantil
                <br><span class="legendaContent">Maternal I à Pré III</span>
            </div>
            <div class="menuCol" routerLink="/turmas-escola">
                <fa-icon [icon]="['fas', 'child']" size="3x"></fa-icon>
                <br>Escola
                <br><span class="legendaContent">1º à 5º Ano</span>
            </div>
            <div class="menuCol" routerLink="/turmas-colegio">
                <fa-icon [icon]="['fas', 'chalkboard-teacher']" size="3x"></fa-icon>
                <br>Colegial
                <br><span class="legendaContent">9º à 3º Ano</span>
            </div>
            <div class="menuCol" routerLink="/turmas">
                <fa-icon [icon]="['fas', 'graduation-cap']" size="3x"></fa-icon>
                <br>Universidade
            </div>
        </div>
    </div>
</div>
<div id="myModalHome" class="modal">
    <div class="topVoltar fixo">
        <fa-icon [icon]="['fas', 'arrow-circle-left']" size="2x" (click)="closeModal()"></fa-icon>
      </div>
      <div class="bgInicio">
        <div class="contentHome">
            <div class="menuCol" routerLink="/orcamento-infantil">
              <fa-icon [icon]="['fas', 'chalkboard-teacher']" size="3x"></fa-icon>
              <br>Infantil
              <br><span class="legendaContent">Maternal I à Pré III</span>
          </div>
            <div class="menuCol" routerLink="/orcamento-colegio">
              <fa-icon [icon]="['fas', 'chalkboard-teacher']" size="3x"></fa-icon>
              <br>Escola e Colegial
              <br><span class="legendaContent">1º Fund. à 3º Col.</span>
          </div>
            <div class="menuCol" routerLink="/orcamento">
                <fa-icon [icon]="['fas', 'graduation-cap']" size="3x"></fa-icon>
                <br>Universidade
            </div>
        </div>
    </div>
</div>
<div class="bgInicio">
    <div style="text-align:right;width:100%;font-size:1em;color:#484848;font-weight:500;padding:10px;">
        Bem Vindo, {{ usuario }}!
    </div>
    <div class="contentHome">
        <div class="menuCol"  (click)="openModalTurmas()">
            <fa-icon [icon]="['fas', 'chalkboard']" size="3x"></fa-icon>
           <br>Turmas
        </div>
        <div class="menuCol" (click)="openModal()">
            <fa-icon [icon]="['fas', 'edit']" size="3x"></fa-icon>
            <br>Orçamento
        </div>
        <div class="menuCol" routerLink="/galeria">
            <fa-icon [icon]="['fas', 'camera']" size="3x"></fa-icon>
            <br>Galeria
        </div>
        <div class="menuCol" routerLink="/arquivo">
            <fa-icon [icon]="['fas', 'folder-open']" size="3x"></fa-icon>
            <br>Arquivo
        </div>
    </div>
</div>