<div class="turmaAutomatica" id="alertaTurma">
    <div class="boxTurma">
        <div class="tituloAlerta">
            Esse curso tem {{anos}} anos de duração, caso queira pode adicionar todas as turmas ou deixar apenas as que desejar marcado
        </div>
        <div class="table-responsive">
            <table class="table" style="text-align: center;">
                <thead>
                    <tr>
                        <th></th>
                        <th>Data Inicio</th>
                        <th>Data Fim</th>
                        <th>Ano</th>
                        <th>Periodo</th>
                        <th>Alunos</th>
                        <th>Turma</th>
                        <th>Sala</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let arr of arrayTurmas" [ngClass]="{'desabilitado' : arr?.marcado != true}">
                        <td>
                            <input type="checkbox" [(ngModel)]="arr.marcado" [checked]="arr.marcado" style="pointer-events:all !important">
                        </td>
                        <td>
                            <input type="date" class="form-control" [(ngModel)]="arr.dataInicio">
                        </td>
                        <td>
                            <input type="date" class="form-control" [(ngModel)]="arr.dataFim">
                        </td>
                        <td>
                            <input type="number" class="form-control" [(ngModel)]="arr.ano">
                        </td>
                         <td>
                            <input type="number" class="form-control" [(ngModel)]="arr.periodoTurma">
                        </td>
                        <td>
                            <input type="number" class="form-control" [(ngModel)]="arr.alunos">
                        </td>
                        <td>
                            <input type="text" class="form-control" [(ngModel)]="arr.turma">
                        </td>
                        <td>
                            <input type="text" class="form-control" [(ngModel)]="arr.sala">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row" style="text-align: right;">
            <div class="col">
                <button class="btn btn-primary" (click)="addMaisTurma()" [disabled]="arrayTurmas.length > 5"><strong>+</strong></button>
            </div>
        </div>
        <div class="row" style="text-align: center;margin-top:4em">
            <div class="col col-6">
                <button class="btn btn-danger" (click)="closeModalVariasTurmas()">CANCELAR</button>
            </div>
            <div class="col col-6">
                <button class="btn btn-success" (click)="cadastrarVariasTurmas()">CADASTRAR TURMAS</button>
            </div>
        </div>
    </div>
    <div style="height: 3em"></div>
</div>
<div class="modalSenha" id="senhaModalStatus">
    <div class="box">
        <div class="row" style="text-align: center;">
            <div class="col">
                <h4>CONFIRME SUA SENHA</h4>
                <br>
                <p style="font-weight: bold;">Para continuar por favor digite sua senha de acesso</p>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <input class="form-control" type="password" [(ngModel)]="senhaAdm">
            </div>
        </div>
        <br>
        <div class="row" style="text-align:center">
            <div class="col">
                <button class="btn btn-danger btn-block" (click)="closeSenhaStatus()">CANCELAR</button>
            </div>
            <div class="col">
                <button class="btn btn-success btn-block" (click)="mudarStatus()">CONFIRMAR</button>
            </div>
        </div>
    </div>
</div>
<div class="modalSenha" id="senhaModal">
    <div class="box">
        <div class="row" style="text-align: center;">
            <div class="col">
                <h4>CONFIRME SUA SENHA</h4>
                <br>
                <p style="font-weight: bold;">Para continuar por favor digite sua senha de acesso</p>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col">
                <input class="form-control" type="password" [(ngModel)]="senhaAdm">
            </div>
        </div>
        <br>
        <div class="row" style="text-align:center">
            <div class="col">
                <button class="btn btn-danger btn-block" (click)="closeSenhaDeleteTurma()">CANCELAR</button>
            </div>
            <div class="col">
                <button class="btn btn-success btn-block" (click)="deleteTurma()">CONFIRMAR</button>
            </div>
        </div>
    </div>
</div>
<div id="myModalDetailsTurmas" class="modalDetails">
    <div class="topVoltar fixo">
        <fa-icon [icon]="['fas', 'arrow-circle-left']" size="2x" (click)="closeModalDetails()"></fa-icon>
    </div>
    <div style="height: 10px"></div>
    <div class="filtroContainer">
        <div class="cadastroOption">
            <h4>DETALHES TURMA ({{turmaSelecionadaDetails?.codigoTurma}})</h4>
            <div [style.pointer-events]="temContrato == 'Sim' ? 'none' : null">
                <div class="row" [style.pointer-events]="(tipoUsuario == 'Administrador' || usuario == turmaSelecionadaDetails?.consultor || turmaSelecionadaDetails?.consultor == '' || turmaSelecionadaDetails?.consultor == 'Sem Consultor' ) ? null : 'none'">
                    <div class="col col-3">
                        <label>Cidade</label> 
                        <select [(ngModel)]="cidadeTurmaSelecionado" (change)="mudarCidadeDetailsTurma(cidadeTurmaSelecionado);postAlteracao('Cidade','cidade',cidadeTurmaSelecionado)" class="form-control">
                            <option *ngFor="let cidade of listaCidadesDetails" [value]="cidade.nome">{{cidade?.nome}}</option>
                        </select>
                    </div>
                    <div class="col col-2">
                        <label>Instituição</label>
                        <select [(ngModel)]="instituicaoTurmaSelecionado" (change)="mudarInstituicaoDetailsTurma(instituicaoTurmaSelecionado, cidadeTurmaSelecionado);postAlteracao('Instituição','instituicao',instituicaoTurmaSelecionado)" class="form-control">
                            <option *ngFor="let facul of listaInstituicoesDetails" [value]="facul.nome">{{facul?.nome}}</option>
                        </select>
                    </div>
                    <div class="col col-4">
                        <label>Curso</label>
                        <select [(ngModel)]="cursoTurmaSelecionado" (change)="mudarCursoDetailsTurma(instituicaoTurmaSelecionado, cidadeTurmaSelecionado, cursoTurmaSelecionado);postAlteracao('Curso','curso',cursoTurmaSelecionado)" class="form-control">
                            <option *ngFor="let curso of cursosCadastrados" [value]="curso.nome">{{curso?.nome}}</option>
                        </select>
                    </div>
                    <div class="col col-3">
                        <label>Graduação</label>
                        <select [(ngModel)]="graduacaoTurmaSelecionado" class="form-control">
                            <option value="">TODOS</option>
                            <option value="PRESENCIAL">PRESENCIAL</option>
                            <option value="EAD">EAD</option>
                            <option value="ENSINO TÉCNICO">ENSINO TÉCNICO</option>
                            <option value="ENSINO MÉDIO">ENSINO MÉDIO</option>
                            <option value="ENSINO FUNDAMENTAL">ENSINO FUNDAMENTAL</option>
                        </select>
                    </div>
                </div>
                <br>
                <div class="row" [style.pointer-events]="(tipoUsuario == 'Administrador' || usuario == turmaSelecionadaDetails?.consultor || turmaSelecionadaDetails?.consultor == '' || turmaSelecionadaDetails?.consultor == 'Sem Consultor' ) ? null : 'none'">
                    <div class="col col-2" *ngIf="!periodoEAD">
                        <label>Turno</label>
                        <select [(ngModel)]="periodoTurmaSelecionado" class="form-control" [ngStyle]="{'border-color': !periodoEAD && (periodoTurmaSelecionado == '' || periodoTurmaSelecionado == null) ? '#dd443e' : '#3cb42c'}" (change)="postAlteracao('Turno','periodo',periodoTurmaSelecionado)">
                            <option value="MATUTINO">MATUTINO</option>
                            <option value="VESPERTINO">VESPERTINO</option>
                            <option value="NOTURNO">NOTURNO</option>
                            <option value="INTEGRAL">INTEGRAL</option>
                        </select>
                    </div>
                    <div class="col col-3">
                        <label>Data Inicio</label>
                        <input type="date" class="form-control" [(ngModel)]="dataInicioTurmaSelecionado" (change)="diferencaMesesDetails(dataInicioTurmaSelecionado, dataFimTurmaSelecionado);postAlteracao('Data Inicio','dataInicio',dataInicioTurmaSelecionado)">
                    </div>
                    <div class="col col-3">
                        <label>Data Fim</label>
                        <input type="date" class="form-control" [(ngModel)]="dataFimTurmaSelecionado" (change)="diferencaMesesDetails(dataInicioTurmaSelecionado, dataFimTurmaSelecionado);postAlteracao('Data Fim','dataFim',dataFimTurmaSelecionado)">
                    </div>
                    <div class="col col-1">
                        <label>Ano</label>
                        <input type="text" class="form-control" [(ngModel)]="anoTurmaSelecionado" readonly>
                    </div>
                    <div class="col col-1">
                        <label>Duração</label>
                        <input type="text" class="form-control" [(ngModel)]="duracaoTurmaSelecionado" readonly>
                    </div>
                    <div class="col col-1">
                        <label>Turma</label>
                        <input type="text" class="form-control" [(ngModel)]="turmaTurmaSelecionado" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Turma','turma',turmaTurmaSelecionado)">
                    </div>
                    <div class="col col-1">
                        <label>Sala <fa-icon [icon]="['fas', 'info-circle']" size="1x" style="cursor: pointer" #tooltip="matTooltip" matTooltipPosition="left" matTooltipHideDelay="100" [matTooltip]="'Salas Anteriores:\n'+salasTurma" [matTooltipClass]="'my-tooltip'"></fa-icon></label>
                        <input type="text" class="form-control" [(ngModel)]="salaTurmaSelecionado" (keyup)="turmaUnica(turmaSelecionadaDetails,salaTurmaSelecionado)" [ngStyle]="{'border-color': !salaTurmaSelecionado || !salaOkey ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Sala','sala',salaTurmaSelecionado)">
                    </div>
                </div>
                <br>
                <div class="row" [style.pointer-events]="(tipoUsuario == 'Administrador' || usuario == turmaSelecionadaDetails?.consultor || turmaSelecionadaDetails?.consultor == '' || turmaSelecionadaDetails?.consultor == 'Sem Consultor' ) ? null : 'none'">
                    <div class="col col-1">
                        <label>Alunos <fa-icon [icon]="['fas', 'info-circle']" size="1x" style="cursor: pointer" #tooltip="matTooltip" matTooltipPosition="left" matTooltipHideDelay="100" [matTooltip]="'Alunos Anteriores:\n'+alunosHistorico" [matTooltipClass]="'my-tooltip'"></fa-icon></label>
                        <input type="number" class="form-control" [(ngModel)]="alunos" [ngStyle]="{'border-color': !alunos ? '#dd443e' : '#3cb42c'}" (change)="postAlteracao('Alunos','alunos',alunos)">
                    </div>
                    <div class="col col-1">
                        <label>Periodo</label>
                        <input type="text" class="form-control" [(ngModel)]="periodoTurma" readonly>
                    </div>
                    <div class="col col-2">
                        <label>Situação</label>
                        <select [(ngModel)]="situacaoTurmaSelecionado" class="form-control" (change)="postAlteracao('Situação','situacao',situacaoTurmaSelecionado)">
                            <option value=""></option>
                            <option value="ABERTO">ABERTO</option>
                            <option value="FECHADO">FECHADO</option>
                            <option value="NEGOCIAÇÃO">NEGOCIAÇÃO</option>
                            <option value="PRÉ-CADASTRO">PRÉ-CADASTRO</option>
                            <option value="ATENDIMENTO URGENTE">ATENDIMENTO URGENTE</option>
                        </select>
                    </div>
                    <div class="col col-3" *ngIf="situacaoTurmaSelecionado == 'FECHADO'">
                        <label>Empresa</label>
                        <select class="form-control" [(ngModel)]="empresaTurmaSelecionado" [ngStyle]="{'border-color': !empresaTurmaSelecionado ? '#dd443e' : '#3cb42c'}" (change)="postAlteracao('Empresa','empresa',empresaTurmaSelecionado)">
                            <option value="" disabled selected>SELECIONAR</option>
                            <option *ngFor="let emp of todasEmpresas" [value]="emp.nome">{{emp.nome}}</option>
                        </select>
                    </div>
                    <div class="col col-3">
                        <label>Consultor</label>
                        <select [(ngModel)]="consultorTurmaSelecionado" class="form-control" (change)="postAlteracao('Consultor','consultor',consultorTurmaSelecionado)">
                            <option value="Sem Consultor">Sem Consultor</option>
                            <option *ngFor="let consultor of vendedores" [value]="consultor.nome">{{consultor.nome}}</option>
                        </select>
                    </div>
                    <div class="col col-2" *ngIf="tipoUsuario == 'Administrador'">
                        <label>Status</label>
                        <select [(ngModel)]="checkTurmaSelecionado" class="form-control" (change)="openSenhaStatus(checkTurmaSelecionado,turmaSelecionadaDetails._id)">
                            <option [ngValue]=true>VERIFICADO</option>
                            <option [ngValue]=false>NÃO VERIFICADO</option>
                        </select>
                    </div>
                </div>
                    <hr>
                    <div class="row" [style.pointer-events]="(tipoUsuario == 'Administrador' || usuario == turmaSelecionadaDetails?.consultor || turmaSelecionadaDetails?.consultor == '' || turmaSelecionadaDetails?.consultor == 'Sem Consultor' ) ? null : 'none'">
                    <div class="col col-12" style="text-align: center;">
                        <h4>Turma Ata</h4>
                    </div>
                    <div class="col col-5">
                        <label>Nome Comissão</label>
                        <input type="text" class="form-control" [(ngModel)]="nomeComissao" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Nome da Comissão','nomeComissao',nomeComissao)">
                    </div>
                    <div class="col col-5">
                        <label>Razão Social</label>
                        <input type="text" class="form-control" [(ngModel)]="razaoSocialComissao" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Razão Social da Comissão','razaoSocialComissao',razaoSocialComissao)">
                    </div>
                    <div class="col col-2">
                        <label>CNPJ</label>
                        <input type="text" class="form-control" [(ngModel)]="cnpjComissao" (change)="postAlteracao('CNPJ da Comissão','cnpjComissao',cnpjComissao)">
                    </div>
                </div>
                <div class="row" [style.pointer-events]="(tipoUsuario == 'Administrador' || usuario == turmaSelecionadaDetails?.consultor || turmaSelecionadaDetails?.consultor == '' || turmaSelecionadaDetails?.consultor == 'Sem Consultor' ) ? null : 'none'">
                    <div class="col col-2">
                        <label>CEP</label>
                        <input type="text" class="form-control" [(ngModel)]="cepComissao" (change)="postAlteracao('CEP da Comissão','cepComissao',cepComissao)">
                    </div>
                    <div class="col col-4">
                        <label>Endereço</label>
                        <input type="text" class="form-control" [(ngModel)]="enderecoComissao" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Endereço da Comissão','enderecoComissao',enderecoComissao)">
                    </div>
                    <div class="col col-2">
                        <label>Bairro</label>
                        <input type="text" class="form-control" [(ngModel)]="bairroComissao" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Bairro da Comissão','bairroComissao',bairroComissao)">
                    </div>
                    <div class="col col-3">
                        <label>Cidade</label>
                        <input type="text" class="form-control" [(ngModel)]="cidadeComissao" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Cidade da Comissão','cidadeComissao',cidadeComissao)">
                    </div>
                    <div class="col col-1">
                        <label>UF</label>
                        <select [(ngModel)]="ufComissao" class="form-control" (change)="postAlteracao('UF da Comissão','ufComissao',ufComissao)">
                            <option value="AC">AC</option>
                            <option value="AL">AL</option>
                            <option value="AP">AP</option>
                            <option value="AM">AM</option>
                            <option value="BA">BA</option>
                            <option value="CE">CE</option>
                            <option value="DF">DF</option>
                            <option value="ES">ES</option>
                            <option value="GO">GO</option>
                            <option value="MA">MA</option>
                            <option value="MT">MT</option>
                            <option value="MS">MS</option>
                            <option value="MG">MG</option>
                            <option value="PA">PA</option>
                            <option value="PB">PB</option>
                            <option value="PE">PE</option>
                            <option value="PI">PI</option>
                            <option value="PR">PR</option>
                            <option value="RJ">RJ</option>
                            <option value="RN">RN</option>
                            <option value="RS">RS</option>
                            <option value="RO">RO</option>
                            <option value="RR">RR</option>
                            <option value="SC">SC</option>
                            <option value="SP">SP</option>
                            <option value="SE">SE</option>
                            <option value="TO">TO</option>
                        </select>
                    </div>
                </div>
            </div>
        <hr>
        <mat-accordion>
            <mat-expansion-panel style="background-color: rgb(246, 246, 246)" (opened)="panelOpenState" (closed)="!panelOpenState">
                <mat-expansion-panel-header>
                    <mat-panel-description> 
                    {{panelOpenState ? 'Esconder Dados da Comissão' : 'Mostrar Dados da Comissão'}}
                    </mat-panel-description>
                </mat-expansion-panel-header>
        <div class="row" [style.pointer-events]="(tipoUsuario == 'Administrador' || usuario == turmaSelecionadaDetails?.consultor || turmaSelecionadaDetails?.consultor == '' || turmaSelecionadaDetails?.consultor == 'Sem Consultor' ) && temContrato != 'Sim' ? null : 'none'">
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-4">
                <label>Presidente</label>
                <input type="text" class="form-control" [(ngModel)]="presidente" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Presidente','presidente',presidente)">
            </div>
            <div class="col col-3">
                <label>E-mail</label>
                <input type="text" class="form-control" [(ngModel)]="presidenteEmail" (change)="postAlteracao('Email do Presidente','presidenteEmail',presidenteEmail)">
            </div>
            <div class="col col-2">
                <label>CPF</label>
                <input type="text" class="form-control" mask="000.000.000-00" [(ngModel)]="presidenteCpf" (change)="postAlteracao('CPF do Presidente','presidenteCpf',presidenteCpf)">
            </div>
            <div class="col col-2">
                <label>RG</label>
                <input type="text" class="form-control" [(ngModel)]="presidenteRg" (change)="postAlteracao('RG do Presidente','presidenteRg',presidenteRg)">
            </div>
            <div class="col col-1">
                <label>Órgão</label>
                <input type="text" class="form-control" [(ngModel)]="presidenteRgOrgao" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Órgão RG do Presidente','presidenteRgOrgao',presidenteRgOrgao)">
            </div>
        </div>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-5">
                <label>Endereço</label>
                <input type="text" class="form-control" [(ngModel)]="presidenteEndereco" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Endereço do Presidente','presidenteEndereco',presidenteEndereco)">
            </div>
            <div class="col col-2">
                <label>Estado Civil</label>
                <select class="form-control" [(ngModel)]="presidenteEstadoCivil" (change)="postAlteracao('Estado Civil do Presidente','presidenteEstadoCivil',presidenteEstadoCivil)">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                        <option value="CASADO">CASADO</option>
                        <option value="DIVORCIADO">DIVORCIADO</option>
                        <option value="VIÚVO">VIÚVO</option>
                        <option value="SEPARADO">SEPARADO</option>
                        <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Telefone</label>
                <input type="text" class="form-control" mask="(00) 00000-0000" [(ngModel)]="presidenteTelefone" (change)="postAlteracao('Telefone do Presidente','presidenteTelefone',presidenteTelefone)">
            </div>
            <div class="col col-1">
                <br>
                <button class="btn btn-success" (click)="callWhats(presidenteTelefone)">
                    <fa-icon [icon]="['fab', 'whatsapp']" size="1x" ></fa-icon>
                </button>
            </div>
            <div class="col col-2">
                <label>Nacionalidade</label>
                <input type="text" class="form-control" [(ngModel)]="presidenteNacionalidade" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Nacionalidade do Presidente','presidenteNacionalidade',presidenteNacionalidade)">
            </div>
        </div>
        <hr>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-4">
                <label>Vice-Presidente</label>
                <input type="text" class="form-control" [(ngModel)]="vicePresidente" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Vice Presidente','vicePresidente',vicePresidente)">
            </div>
            <div class="col col-3">
                <label>E-mail</label>
                <input type="text" class="form-control" [(ngModel)]="vicePresidenteEmail" (change)="postAlteracao('Email do Vice-Presidente','vicePresidenteEmail',vicePresidenteEmail)">
            </div>
            <div class="col col-2">
                <label>CPF</label>
                <input type="text" class="form-control" mask="000.000.000-00" [(ngModel)]="vicePresidenteCpf" (change)="postAlteracao('CPF do Vice-Presidente','vicePresidenteCpf',vicePresidenteCpf)">
            </div>
            <div class="col col-2">
                <label>RG</label>
                <input type="text" class="form-control" [(ngModel)]="vicePresidenteRg" (change)="postAlteracao('RG do Vice-Presidente','vicePresidenteRg',vicePresidenteRg)">
            </div>
            <div class="col col-1">
                <label>Órgão</label>
                <input type="text" class="form-control" [(ngModel)]="vicePresidenteRgOrgao" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Órgão RG do Vice-Presidente','vicePresidenteRgOrgao',vicePresidenteRgOrgao)">
            </div>
        </div>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-6">
                <label>Endereço</label>
                <input type="text" class="form-control" [(ngModel)]="vicePresidenteEndereco" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Endereço do Vice-Presidente','vicePresidenteEndereco',vicePresidenteEndereco)">
            </div>
            <div class="col col-2">
                <label>Estado Civil</label>
                <select class="form-control" [(ngModel)]="vicePresidenteEstadoCivil" (change)="postAlteracao('Estado Civil do Vice-Presidente','vicePresidenteEstadoCivil',vicePresidenteEstadoCivil)">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                        <option value="CASADO">CASADO</option>
                        <option value="DIVORCIADO">DIVORCIADO</option>
                        <option value="VIÚVO">VIÚVO</option>
                        <option value="SEPARADO">SEPARADO</option>
                        <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Telefone</label>
                <input type="text" class="form-control" mask="(00) 00000-0000" [(ngModel)]="vicePresidenteTelefone" (change)="postAlteracao('Telefone do Vice-Presidente','vicePresidenteTelefone',vicePresidenteTelefone)">
            </div>
            <div class="col col-2">
                <label>Nacionalidade</label>
                <input type="text" class="form-control" [(ngModel)]="vicePresidenteNacionalidade" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Nacionalidade do Vice-Presidente','vicePresidenteNacionalidade',vicePresidenteNacionalidade)">
            </div>
        </div>
        <hr>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-4">
                <label>1º Presidente</label>
                <input type="text" class="form-control" [(ngModel)]="primeiroSecretario" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('1º Secretário','primeiroSecretario',primeiroSecretario)">
            </div>
            <div class="col col-3">
                <label>E-mail</label>
                <input type="text" class="form-control" [(ngModel)]="primeiroSecretarioEmail" (change)="postAlteracao('Email do 1º Secretário','primeiroSecretarioEmail',primeiroSecretarioEmail)">
            </div>
            <div class="col col-2">
                <label>CPF</label>
                <input type="text" class="form-control" mask="000.000.000-00" [(ngModel)]="primeiroSecretarioCpf" (change)="postAlteracao('CPF do 1º Secretário','primeiroSecretarioCpf',primeiroSecretarioCpf)">
            </div>
            <div class="col col-2">
                <label>RG</label>
                <input type="text" class="form-control" [(ngModel)]="primeiroSecretarioRg" (change)="postAlteracao('RG do 1º Secretário','primeiroSecretarioRg',primeiroSecretarioRg)">
            </div>
            <div class="col col-1">
                <label>Órgão</label>
                <input type="text" class="form-control" [(ngModel)]="primeiroSecretarioRgOrgao" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Órgão RG do 1º Secretário','primeiroSecretarioRgOrgao',primeiroSecretarioRgOrgao)">
            </div>
        </div>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-6">
                <label>Endereço</label>
                <input type="text" class="form-control" [(ngModel)]="primeiroSecretarioEndereco" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Endereço do 1º Secretário','primeiroSecretarioEndereco',primeiroSecretarioEndereco)">
            </div>
            <div class="col col-2">
                <label>Estado Civil</label>
                <select class="form-control" [(ngModel)]="primeiroSecretarioEstadoCivil" (change)="postAlteracao('Estado Civil do 1º Secretário','primeiroSecretarioEstadoCivil',primeiroSecretarioEstadoCivil)">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                        <option value="CASADO">CASADO</option>
                        <option value="DIVORCIADO">DIVORCIADO</option>
                        <option value="VIÚVO">VIÚVO</option>
                        <option value="SEPARADO">SEPARADO</option>
                        <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Telefone</label>
                <input type="text" class="form-control" mask="(00) 00000-0000" [(ngModel)]="primeiroSecretarioTelefone" (change)="postAlteracao('Telefone do 1º Secretário','primeiroSecretarioTelefone',primeiroSecretarioTelefone)">
            </div>
            <div class="col col-2">
                <label>Nacionalidade</label>
                <input type="text" class="form-control" [(ngModel)]="primeiroSecretarioNacionalidade" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Nacionalidade do 1º Secretário','primeiroSecretarioNacionalidade',primeiroSecretarioNacionalidade)">
            </div>
        </div>
        <hr>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-4">
                <label>2º Secretário</label>
                <input type="text" class="form-control" [(ngModel)]="segundoSecretario" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('2º Secretário','segundoSecretario',segundoSecretario)">
            </div>
            <div class="col col-3">
                <label>E-mail</label>
                <input type="text" class="form-control" [(ngModel)]="segundoSecretarioEmail" (change)="postAlteracao('Email do 2º Secretário','segundoSecretarioEmail',segundoSecretarioEmail)">
            </div>
            <div class="col col-2">
                <label>CPF</label>
                <input type="text" class="form-control" mask="000.000.000-00" [(ngModel)]="segundoSecretarioCpf" (change)="postAlteracao('CPF do 2º Secretário','segundoSecretarioCpf',segundoSecretarioCpf)">
            </div>
            <div class="col col-2">
                <label>RG</label>
                <input type="text" class="form-control" [(ngModel)]="segundoSecretarioRg" (change)="postAlteracao('RG do 2º Secretário','segundoSecretarioRg',segundoSecretarioRg)">
            </div>
            <div class="col col-1">
                <label>Órgão</label>
                <input type="text" class="form-control" [(ngModel)]="segundoSecretarioRgOrgao" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Órgão RG do 2º Secretário','segundoSecretarioRgOrgao',segundoSecretarioRgOrgao)">
            </div>
        </div>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-6">
                <label>Endereço</label>
                <input type="text" class="form-control" [(ngModel)]="segundoSecretarioEndereco" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Endereço do 2º Secretário','segundoSecretarioEndereco',segundoSecretarioEndereco)">
            </div>
            <div class="col col-2">
                <label>Estado Civil</label>
                <select class="form-control" [(ngModel)]="segundoSecretarioEstadoCivil" (change)="postAlteracao('Estado Civil do 2º Secretário','segundoSecretarioEstadoCivil',segundoSecretarioEstadoCivil)">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                        <option value="CASADO">CASADO</option>
                        <option value="DIVORCIADO">DIVORCIADO</option>
                        <option value="VIÚVO">VIÚVO</option>
                        <option value="SEPARADO">SEPARADO</option>
                        <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Telefone</label>
                <input type="text" class="form-control" mask="(00) 00000-0000" [(ngModel)]="segundoSecretarioTelefone" (change)="postAlteracao('Telefone do 2º Secretário','segundoSecretarioTelefone',segundoSecretarioTelefone)">
            </div>
            <div class="col col-2">
                <label>Nacionalidade</label>
                <input type="text" class="form-control" [(ngModel)]="segundoSecretarioNacionalidade" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Nacionalidade do 2º Secretário','segundoSecretarioNacionalidade',segundoSecretarioNacionalidade)">
            </div>
        </div>
        <hr>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-4">
                <label>3º Secretário</label>
                <input type="text" class="form-control" [(ngModel)]="terceiroSecretario" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('3º Secretário','terceiroSecretario',terceiroSecretario)">
            </div>
            <div class="col col-3">
                <label>E-mail</label>
                <input type="text" class="form-control" [(ngModel)]="terceiroSecretarioEmail" (change)="postAlteracao('Email do 3º Secretário','terceiroSecretarioEmail',terceiroSecretarioEmail)">
            </div>
            <div class="col col-2">
                <label>CPF</label>
                <input type="text" class="form-control" mask="000.000.000-00" [(ngModel)]="terceiroSecretarioCpf" (change)="postAlteracao('CPF do 3º Secretário','terceiroSecretarioCpf',terceiroSecretarioCpf)">
            </div>
            <div class="col col-2">
                <label>RG</label>
                <input type="text" class="form-control" [(ngModel)]="terceiroSecretarioRg" (change)="postAlteracao('RG do 3º Secretário','terceiroSecretarioRg',terceiroSecretarioRg)">
            </div>
            <div class="col col-1">
                <label>Órgão</label>
                <input type="text" class="form-control" [(ngModel)]="terceiroSecretarioRgOrgao" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Órgão RG do 3º Secretário','terceiroSecretarioRgOrgao',terceiroSecretarioRgOrgao)">
            </div>
        </div>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-6">
                <label>Endereço</label>
                <input type="text" class="form-control" [(ngModel)]="terceiroSecretarioEndereco" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Endereço do 3º Secretário','terceiroSecretarioEndereco',terceiroSecretarioEndereco)">
            </div>
            <div class="col col-2">
                <label>Estado Civil</label>
                <select class="form-control" [(ngModel)]="terceiroSecretarioEstadoCivil" (change)="postAlteracao('Estado Civil do 3º Secretário','terceiroSecretarioEstadoCivil',terceiroSecretarioEstadoCivil)">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                        <option value="CASADO">CASADO</option>
                        <option value="DIVORCIADO">DIVORCIADO</option>
                        <option value="VIÚVO">VIÚVO</option>
                        <option value="SEPARADO">SEPARADO</option>
                        <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Telefone</label>
                <input type="text" class="form-control" mask="(00) 00000-0000" [(ngModel)]="terceiroSecretarioTelefone" (change)="postAlteracao('Telefone do 3º Secretário','terceiroSecretarioTelefone',terceiroSecretarioTelefone)">
            </div>
            <div class="col col-2">
                <label>Nacionalidade</label>
                <input type="text" class="form-control" [(ngModel)]="terceiroSecretarioNacionalidade" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Nacionalidade do 3º Secretário','terceiroSecretarioNacionalidade',terceiroSecretarioNacionalidade)">
            </div>
        </div>
        <hr>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-4">
                <label>Tesoureiro</label>
                <input type="text" class="form-control" [(ngModel)]="tesoureiro" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Tesoureiro','tesoureiro',tesoureiro)">
            </div>
            <div class="col col-3">
                <label>E-mail</label>
                <input type="text" class="form-control" [(ngModel)]="tesoureiroEmail" (change)="postAlteracao('Email do Tesoureiro','tesoureiroEmail',tesoureiroEmail)">
            </div>
            <div class="col col-2">
                <label>CPF</label>
                <input type="text" class="form-control" mask="000.000.000-00" [(ngModel)]="tesoureiroCpf" (change)="postAlteracao('CPF do Tesoureiro','tesoureiroCpf',tesoureiroCpf)">
            </div>
            <div class="col col-2">
                <label>RG</label>
                <input type="text" class="form-control" [(ngModel)]="tesoureiroRg" (change)="postAlteracao('RG do Tesoureiro','tesoureiroRg',tesoureiroRg)">
            </div>
            <div class="col col-1">
                <label>Órgão</label>
                <input type="text" class="form-control" [(ngModel)]="tesoureiroRgOrgao" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Órgão RG do Tesoureiro','tesoureiroRgOrgao',tesoureiroRgOrgao)">
            </div>
        </div>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-6">
                <label>Endereço</label>
                <input type="text" class="form-control" [(ngModel)]="tesoureiroEndereco" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Endereço do Tesoureiro','tesoureiroEndereco',tesoureiroEndereco)">
            </div>
            <div class="col col-2">
                <label>Estado Civil</label>
                <select class="form-control" [(ngModel)]="tesoureiroEstadoCivil" (change)="postAlteracao('Estado Civil do Tesoureiro','tesoureiroEstadoCivil',tesoureiroEstadoCivil)">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                        <option value="CASADO">CASADO</option>
                        <option value="DIVORCIADO">DIVORCIADO</option>
                        <option value="VIÚVO">VIÚVO</option>
                        <option value="SEPARADO">SEPARADO</option>
                        <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Telefone</label>
                <input type="text" class="form-control" mask="(00) 00000-0000" [(ngModel)]="tesoureiroTelefone" (change)="postAlteracao('Telefone do Tesoureiro','tesoureiroTelefone',tesoureiroTelefone)">
            </div>
            <div class="col col-2">
                <label>Nacionalidade</label>
                <input type="text" class="form-control" [(ngModel)]="tesoureiroNacionalidade" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Nacionalidade do Tesoureiro','tesoureiroNacionalidade',tesoureiroNacionalidade)">
            </div>
        </div>
        <hr>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-4">
                <label>1º Conselheiro Fiscal</label>
                <input type="text" class="form-control" [(ngModel)]="primeiroConsFiscal" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('1º Conselheiro Fiscal','primeiroConsFiscal',primeiroConsFiscal)">
            </div>
            <div class="col col-3">
                <label>E-mail</label>
                <input type="text" class="form-control" [(ngModel)]="primeiroConsFiscalEmail" (change)="postAlteracao('Email do 1º Conselheiro Fiscal','primeiroConsFiscalEmail',primeiroConsFiscalEmail)">
            </div>
            <div class="col col-2">
                <label>CPF</label>
                <input type="text" class="form-control" mask="000.000.000-00" [(ngModel)]="primeiroConsFiscalCpf" (change)="postAlteracao('CPF do 1º Conselheiro Fiscal','primeiroConsFiscalCpf',primeiroConsFiscalCpf)">
            </div>
            <div class="col col-2">
                <label>RG</label>
                <input type="text" class="form-control" [(ngModel)]="primeiroConsFiscalRg" (change)="postAlteracao('RG do 1º Conselheiro Fiscal','primeiroConsFiscalRg',primeiroConsFiscalRg)">
            </div>
            <div class="col col-1">
                <label>Órgão</label>
                <input type="text" class="form-control" [(ngModel)]="primeiroConsFiscalRgOrgao" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Órgão RG do 1º Conselheiro Fiscal','primeiroConsFiscalRgOrgao',primeiroConsFiscalRgOrgao)">
            </div>
        </div>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-6">
                <label>Endereço</label>
                <input type="text" class="form-control" [(ngModel)]="primeiroConsFiscalEndereco" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Endereço do 1º Conselheiro Fiscal','primeiroConsFiscalEndereco',primeiroConsFiscalEndereco)">
            </div>
            <div class="col col-2">
                <label>Estado Civil</label>
                <select class="form-control" [(ngModel)]="primeiroConsFiscalEstadoCivil" (change)="postAlteracao('Estado Civil do 1º Conselheiro Fiscal','primeiroConsFiscalEstadoCivil',primeiroConsFiscalEstadoCivil)">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                        <option value="CASADO">CASADO</option>
                        <option value="DIVORCIADO">DIVORCIADO</option>
                        <option value="VIÚVO">VIÚVO</option>
                        <option value="SEPARADO">SEPARADO</option>
                        <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Telefone</label>
                <input type="text" class="form-control" mask="(00) 00000-0000" [(ngModel)]="primeiroConsFiscalTelefone" (change)="postAlteracao('Telefone do 1º Conselheiro Fiscal','primeiroConsFiscalTelefone',primeiroConsFiscalTelefone)">
            </div>
            <div class="col col-2">
                <label>Nacionalidade</label>
                <input type="text" class="form-control" [(ngModel)]="primeiroConsFiscalNacionalidade" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Nacionalidade do 1º Conselheiro Fiscal','primeiroConsFiscalNacionalidade',primeiroConsFiscalNacionalidade)">
            </div>
        </div>
        <hr>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-4">
                <label>2º Conselheiro Fiscal</label>
                <input type="text" class="form-control" [(ngModel)]="segundoConsFiscal" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('2º Conselheiro Fiscal','segundoConsFiscal',segundoConsFiscal)">
            </div>
            <div class="col col-3">
                <label>E-mail</label>
                <input type="text" class="form-control" [(ngModel)]="segundoConsFiscalEmail" (change)="postAlteracao('Email do 2º Conselheiro Fiscal','segundoConsFiscalEmail',segundoConsFiscalEmail)">
            </div>
            <div class="col col-2">
                <label>CPF</label>
                <input type="text" class="form-control" mask="000.000.000-00" [(ngModel)]="segundoConsFiscalCpf" (change)="postAlteracao('CPF do 2º Conselheiro Fiscal','segundoConsFiscalCpf',segundoConsFiscalCpf)">
            </div>
            <div class="col col-2">
                <label>RG</label>
                <input type="text" class="form-control" [(ngModel)]="segundoConsFiscalRg" (change)="postAlteracao('RG do 2º Conselheiro Fiscal','segundoConsFiscalRg',segundoConsFiscalRg)">
            </div>
            <div class="col col-1">
                <label>Órgão</label>
                <input type="text" class="form-control" [(ngModel)]="segundoConsFiscalRgOrgao" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Órgão RG do 2º Conselheiro Fiscal','segundoConsFiscalRgOrgao',segundoConsFiscalRgOrgao)">
            </div>
        </div>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-6">
                <label>Endereço</label>
                <input type="text" class="form-control" [(ngModel)]="segundoConsFiscalEndereco" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Endereço do 2º Conselheiro Fiscal','segundoConsFiscalEndereco',segundoConsFiscalEndereco)">
            </div>
            <div class="col col-2">
                <label>Estado Civil</label>
                <select class="form-control" [(ngModel)]="segundoConsFiscalEstadoCivil" (change)="postAlteracao('Estado Civil do 2º Conselheiro Fiscal','segundoConsFiscalEstadoCivil',segundoConsFiscalEstadoCivil)">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                        <option value="CASADO">CASADO</option>
                        <option value="DIVORCIADO">DIVORCIADO</option>
                        <option value="VIÚVO">VIÚVO</option>
                        <option value="SEPARADO">SEPARADO</option>
                        <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Telefone</label>
                <input type="text" class="form-control" mask="(00) 00000-0000" [(ngModel)]="segundoConsFiscalTelefone" (change)="postAlteracao('Telefone do 2º Conselheiro Fiscal','segundoConsFiscalTelefone',segundoConsFiscalTelefone)">
            </div>
            <div class="col col-2">
                <label>Nacionalidade</label>
                <input type="text" class="form-control" [(ngModel)]="segundoConsFiscalNacionalidade" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Nacionalidade do 2º Conselheiro Fiscal','segundoConsFiscalNacionalidade',segundoConsFiscalNacionalidade)">
            </div>
        </div>
        <hr>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-4">
                <label>3º Conselheiro Fiscal</label>
                <input type="text" class="form-control" [(ngModel)]="terceiroConsFiscal" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('3º Conselheiro Fiscal','terceiroConsFiscal',terceiroConsFiscal)">
            </div>
            <div class="col col-3">
                <label>E-mail</label>
                <input type="text" class="form-control" [(ngModel)]="terceiroConsFiscalEmail" (change)="postAlteracao('Email do 3º Conselheiro Fiscal','terceiroConsFiscalEmail',terceiroConsFiscalEmail)">
            </div>
            <div class="col col-2">
                <label>CPF</label>
                <input type="text" class="form-control" mask="000.000.000-00" [(ngModel)]="terceiroConsFiscalCpf" (change)="postAlteracao('CPF do 3º Conselheiro Fiscal','terceiroConsFiscalCpf',terceiroConsFiscalCpf)">
            </div>
            <div class="col col-2">
                <label>RG</label>
                <input type="text" class="form-control" [(ngModel)]="terceiroConsFiscalRg" (change)="postAlteracao('RG do 3º Conselheiro Fiscal','terceiroConsFiscalRg',terceiroConsFiscalRg)">
            </div>
            <div class="col col-1">
                <label>Órgão</label>
                <input type="text" class="form-control" [(ngModel)]="terceiroConsFiscalRgOrgao" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Órgão RG do 3º Conselheiro Fiscal','terceiroConsFiscalRgOrgao',terceiroConsFiscalRgOrgao)">
            </div>
        </div>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-6">
                <label>Endereço</label>
                <input type="text" class="form-control" [(ngModel)]="terceiroConsFiscalEndereco" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Endereço do 3º Conselheiro Fiscal','terceiroConsFiscalEndereco',terceiroConsFiscalEndereco)">
            </div>
            <div class="col col-2">
                <label>Estado Civil</label>
                <select class="form-control" [(ngModel)]="terceiroConsFiscalEstadoCivil" (change)="postAlteracao('Estado Civil do 3º Conselheiro Fiscal','terceiroConsFiscalEstadoCivil',terceiroConsFiscalEstadoCivil)">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                        <option value="CASADO">CASADO</option>
                        <option value="DIVORCIADO">DIVORCIADO</option>
                        <option value="VIÚVO">VIÚVO</option>
                        <option value="SEPARADO">SEPARADO</option>
                        <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Telefone</label>
                <input type="text" class="form-control" mask="(00) 00000-0000" [(ngModel)]="terceiroConsFiscalTelefone" (change)="postAlteracao('Telefone do 3º Conselheiro Fiscal','terceiroConsFiscalTelefone',terceiroConsFiscalTelefone)">
            </div>
            <div class="col col-2">
                <label>Nacionalidade</label>
                <input type="text" class="form-control" [(ngModel)]="terceiroConsFiscalNacionalidade" oninput="this.value = this.value.toUpperCase()" (change)="postAlteracao('Nacionalidade do 3º Conselheiro Fiscal','terceiroConsFiscalNacionalidade',terceiroConsFiscalNacionalidade)">
            </div>
        </div>
    </div>
    </mat-expansion-panel>
</mat-accordion>
<hr>
<div style="text-align: right; margin:10px">
    <button [disabled]="temContrato == 'Sim'" class="btn btn-danger btn-block" (click)="openSenhaDeleteTurma(turmaSelecionadaDetails._id)" *ngIf="tipoUsuario != 'Vendedor'">EXCLUIR TURMA</button>
    &nbsp;&nbsp;
    <button class="btn btn-info btn-block" [disabled]="alunos < 1 || !salaOkey || temContrato == 'Sim' || !cidadeTurmaSelecionado || !instituicaoTurmaSelecionado || !cursoTurmaSelecionado || !dataInicioTurmaSelecionado || !dataFimTurmaSelecionado || !turmaTurmaSelecionado || !salaTurmaSelecionado || !situacaoTurmaSelecionado || !consultorTurmaSelecionado || !periodoEAD && (periodoTurmaSelecionado == '' || periodoTurmaSelecionado == null) || situacaoTurmaSelecionado == 'FECHADO' && !empresaTurmaSelecionado" (click)="editTurma(turmaSelecionadaDetails._id, cidadeTurmaSelecionado, instituicaoTurmaSelecionado, cursoTurmaSelecionado, graduacaoTurmaSelecionado, periodoTurmaSelecionado, dataInicioTurmaSelecionado, dataFimTurmaSelecionado, anoTurmaSelecionado, duracaoTurmaSelecionado, turmaTurmaSelecionado, salaTurmaSelecionado, situacaoTurmaSelecionado, consultorTurmaSelecionado, empresaTurmaSelecionado)">EDITAR DADOS</button>
</div>
<div class="row" style="background-color: #535353;padding:10px">
    <div class="col col-10" style="color:white; font-size: 1.5em; font-weight: 500;">Relatórios</div>
    <div class="col col-2">
        <button class="btn btn-outline-light" (click)="novoRelatorio()">
            <fa-icon [icon]="['fas', 'plus']" size="1x" ></fa-icon>
            <span class="text-icon">&nbsp;Novo Relatório</span>
            </button>
    </div>
</div>
<div *ngIf="newRelatorio" style="margin-bottom:10px;">
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col">
                    <label class="labelRelatorio">Assunto</label>
                    <select class="form-control" [(ngModel)]="assuntoRelatorio">
                        <option value selected></option>
                        <option *ngFor="let assunto of assuntos">{{assunto}}</option>
                    </select> 
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label class="labelRelatorio">O que foi resolvido</label>
                    <textarea class="form-control" [(ngModel)]="descricaoRelatorio"></textarea>  
                </div>
            </div>
        </div>
    <div class="col" [ngStyle]="{'pointer-events' : situacaoTurmaSelecionado == 'FECHADO' ? 'none' : 'all'}" [hidden]="situacaoTurmaSelecionado == 'FECHADO'">
    <div class="row">
        <div class="col">
            <label class="labelRelatorio">Data</label>
            <input type="date" class="form-control" [(ngModel)]="dataAgenda">   
        </div>
    </div>
    <div class="row">
        <div class="col">
            <label class="labelRelatorio">Forma de Contato</label>
            <select class="form-control" [(ngModel)]="assuntoAgenda">
                <option value selected></option>
                <option *ngFor="let assunto of assuntos">{{assunto}}</option>
            </select>  
        </div>
    </div>
    <div class="row">
        <div class="col">
            <label class="labelRelatorio">O que será tratado</label>
            <textarea class="form-control" [(ngModel)]="descricaoAgenda"></textarea>  
        </div>
    </div>
    </div>              
</div>
<div class="row" style="margin:10px 0px; text-align: right;">
    <div class="col">
        <button class="btn btn-danger btn-block" (click)="cancelarRelatorio()">Cancelar Relatório</button>
        &nbsp;&nbsp;
        <button class="btn btn-success btn-block" [hidden]="turmaSelecionadaDetails.situacao != 'FECHADO'" (click)="enviarRelatorioFechado(assuntoRelatorio, descricaoRelatorio)" [disabled]="!assuntoRelatorio || !descricaoRelatorio">Enviar Relatório</button>
        <button class="btn btn-success btn-block" [hidden]="turmaSelecionadaDetails.situacao == 'FECHADO'" (click)="enviarRelatorio(assuntoRelatorio, descricaoRelatorio, assuntoAgenda, descricaoAgenda, dataAgenda)" [disabled]="!assuntoRelatorio || !descricaoRelatorio || !assuntoAgenda || !descricaoAgenda || !dataAgenda">Enviar Relatório</button>
    </div>
</div>
</div>
<mat-expansion-panel *ngFor="let rel of relatorios">
<mat-expansion-panel-header>
    <mat-panel-title>
        {{ rel?.data | date: 'dd/MM/yyyy' }} - {{ rel?.hora }} - {{ rel?.usuario }} 
    </mat-panel-title>
    <mat-panel-description>
        <h4>{{ rel?.assunto }}</h4>
    </mat-panel-description>
</mat-expansion-panel-header>
    <p>{{ rel?.descricao }}</p>
</mat-expansion-panel>
<mat-tab-group color="primary" animationDuration="1000ms" mat-align-tabs="center" style="margin-top:50px">
    <mat-tab label="Documentação">
    <ng-template matTabContent>
        <div class="row" style="width:95%">
            <div class="col col-8">
                <div class="custom-file" style="width:95%">
                    <input type="file" class="custom-file-input" id="customFile" lang="ptBR"  (change)="onChange($event)" multiple>
                    <label class="custom-file-label" for="customFile" id="customFileLabel">Escolher Documento Para Enviar...</label>
                </div>
            </div>
            <div class="col" style="text-align:right">
                <button class="btn btn-success btn-block" [disabled]="!selectedFiles" (click)="uploadFile()">Enviar</button>
                &nbsp;&nbsp;
                <button class="btn btn-danger btn-block" id="deletarDocs" [disabled]="documentos.length == 0" (click)="delButton()">Excluir</button>
            </div>
        </div> 
        <div style="height: 10px;"></div>
        <div class="row" style="width:95%; margin-left: 10px">
            <div class="col col-3" style="padding:2px; text-align: center;" *ngFor="let doc of documentos; let i = index">
                <div class="delButton" name="delButton" hidden="false">
                    <button class="btn btn-outline-danger btn-sm" (click)="delImage(doc._id, doc.url)"><strong>X</strong></button>
                </div>
                <img [src]="doc.url" class="imagemGaleria" (click)="openModalDoc(doc.url, i)">
            </div>
        </div>
    </ng-template>
</mat-tab>
<mat-tab label="Orçamentos">
    <ng-template matTabContent>
        <table class="table table-striped" style="text-align:center">
            <thead>
                <tr>
                <th scope="col">#</th>
                <th scope="col">Nº Proposta</th>
                <th scope="col">Data</th>
                <th scope="col">Turmas</th>
                <th scope="col">Data Fim</th>
                <th scope="col">Ver</th>
                <th scope="col">Contrato</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let orca of orcamentos; let i = index" class="turmasSelect" >
                <td>{{ i + 1 }}</td>
                <td>{{orca?.nProposta}}</td>
                <td>{{orca?.data | date:'dd/MM/yyyy'}}</td>
                <td>{{orca?.arrayTurmas.length}}</td>
                <td>{{orca?.dtFim | date:'dd/MM/yyyy'}}</td>
                <td>
                    <button class="btn btn-info" (click)="detailOrcamento(orca?._id)">
                        <fa-icon [icon]="['fas', 'eye']" size="1x"></fa-icon>
                    </button>
                </td>
                <td>
                    <button *ngIf="temContrato != 'Sim' && orca?.situacaoContrato != 'Rejeitado'" class="btn btn-warning" (click)="enviarContrato(orca?._id)">
                        <fa-icon [icon]="['fas', 'plus-square']" size="1x"></fa-icon>
                    </button>
                    <button *ngIf="temContrato != 'Sim' && orca?.situacaoContrato == 'Rejeitado'" class="btn btn-danger" (click)="enviarContrato(orca?._id)">
                        <fa-icon [icon]="['fas', 'plus-square']" size="1x"></fa-icon>
                    </button>
                    <button *ngIf="temContrato == 'Sim' && orca?.contrato == 'Sim'" class="btn btn-success" [disabled]="temContrato == 'Sim'">
                        <fa-icon [icon]="['fas', 'copy']" size="1x"></fa-icon>
                    </button>
                    <button *ngIf="temContrato == 'Sim' && orca?.contrato != 'Sim' && orca?.contrato == 'Pendente'" class="btn btn-warning" [disabled]="temContrato == 'Sim'" [disabled]="temContrato == 'Sim'">
                        <fa-icon [icon]="['fas', 'copy']" size="1x"></fa-icon>
                    </button>
                    <button *ngIf="temContrato == 'Sim' && orca?.contrato != 'Sim' && orca?.contrato == 'Rejeitado'" class="btn btn-danger" [disabled]="temContrato == 'Sim'" [disabled]="temContrato == 'Sim'">
                        <fa-icon [icon]="['fas', 'copy']" size="1x"></fa-icon>
                    </button>
                </td>
                </tr>
            </tbody>
        </table>
    </ng-template>
</mat-tab>
<mat-tab label="Contratos">
    <ng-template matTabContent>
        <table class="table table-striped" style="width: 90%;text-align: center">
        <thead>
            <th>Data</th>
            <th>Vendedor</th>
            <th>Situação</th>
            <th>Ver</th>
            <th>Aprovar</th>
            <th>Rejeitar</th>
            <th>Excluir</th>
        </thead>
        <tbody *ngFor="let contrato of contratos">
            <tr>
                <td>{{contrato?.data | date:'dd/MM/yyyy'}}</td>
                <td>{{contrato?.vendedor}}</td>
                <td>{{contrato?.situacao}}</td>
                <td>
                    <button class="btn btn-info btn-sm" (click)="detailContrato(contrato?._id)">
                        <fa-icon [icon]="['fas', 'eye']" size="1x"></fa-icon>
                    </button>
                </td>
                <td> 
                    <button class="btn btn-success btn-sm" (click)="aprovarContrato(contrato?._id)" [disabled]="contrato?.situacao == 'Aprovado' || contrato?.situacao == 'Rejeitado'">
                        <fa-icon [icon]="['fas', 'thumbs-up']" size="1x"></fa-icon>
                    </button>
                </td>
                <td> 
                    <button class="btn btn-warning btn-sm" (click)="rejeitarContrato(contrato?._id)" [disabled]="contrato?.situacao == 'Aprovado' || contrato?.situacao == 'Rejeitado'">
                        <fa-icon [icon]="['fas', 'thumbs-down']" size="1x"></fa-icon>
                    </button>
                </td>
                <td> 
                    <button class="btn btn-danger btn-sm" (click)="delContrato(contrato?._id)" [disabled]="contrato?.situacao == 'Aprovado'">
                        <fa-icon [icon]="['fas', 'times']" size="1x"></fa-icon>
                    </button>
                </td>
            </tr>
        </tbody>
        </table>
    </ng-template>
    </mat-tab>
    </mat-tab-group>
    <div style="height: 100px;"></div>
    </div>
</div>
</div>
<div id="modalDoc" class="modalDoc">
    <span (click)="closeModalDoc()" class="close">&times;</span>
    <div class="prev" (click)="prevImg()">&lt;</div>
    <img [src]="imagemSelecionada" class="imagemLightbox">
    <div class="next" (click)="nextImg()">&gt;</div>
</div>
<div id="myModalTurmas" class="modal">
    <div class="topVoltar fixo">
        <fa-icon [icon]="['fas', 'arrow-circle-left']" size="2x" (click)="closeModal()"></fa-icon>
    </div>
    <mat-tab-group backgroundColor="primary" animationDuration="1000ms" color="accent" mat-align-tabs="center">
        <mat-tab label="Cadastros">
            <ng-template matTabContent>
                <div class="row" style="width:99%; margin:0 auto">
                    <div class="col col-4 cadastroOption">
                        <h4 style="text-align: center">CADASTRAR CIDADE</h4>
                        <div class="row">
                            <div class="col col-11">
                                <label>Nova Cidade</label>
                                <input type="text" class="form-control" [(ngModel)]="novaCidadeCadastro" oninput="this.value = this.value.toUpperCase()">
                            </div>
                            <div class="col col-1">
                                <br>
                                <button class="btn btn-info" (click)="cadastrarNovaCidade(novaCidadeCadastro)" [disabled]="!novaCidadeCadastro">
                                    <fa-icon [icon]="['fas', 'plus']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col col-7 cadastroOption">
                        <h4 style="text-align: center">CADASTRAR INSTITUIÇÃO</h4>
                        <div class="row">
                            <div class="col col-5">
                                <label>Cidade</label>
                                <select [(ngModel)]="cidadeCadastroNovaInstituicao" class="form-control">
                                    <option value=""></option>
                                    <option *ngFor="let cidade of listaCidadesNovaInstituicao" [value]="cidade?.nome">{{cidade?.nome}}</option>
                                </select>
                            </div>
                            <div class="col col-6">
                                <label>Nova Instituição</label>
                                <input type="text" class="form-control" [(ngModel)]="novaInstituicaoCadastro" oninput="this.value = this.value.toUpperCase()">
                            </div>
                            <div class="col col-1">
                                <br>
                                <button class="btn btn-info" (click)="cadastrarNovaInstituicao(cidadeCadastroNovaInstituicao, novaInstituicaoCadastro)" [disabled]="!cidadeCadastroNovaInstituicao || !novaInstituicaoCadastro">
                                    <fa-icon [icon]="['fas', 'plus']" size="1x"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row" style=" width:98%; margin:0 auto;">
                    <div class="col cadastroOption">
                    <h4 style="text-align: center">CADASTRAR EMPRESA CONCORRENTE</h4>
                    <div class="row">  
                        <div class="col col-11">
                            <label>Nome Empresa</label>
                            <input type="text" class="form-control" [(ngModel)]="novaEmpresa" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-1">
                            <br>
                            <button class="btn btn-info" (click)="cadastrarNovaEmpresa(novaEmpresa)" [disabled]="!novaEmpresa">
                                <fa-icon [icon]="['fas', 'plus']" size="1x"></fa-icon>
                            </button>
                        </div>
                    </div>
                    <div class="scrollTable" style="height: auto">
                        <div style="text-align: center;font-weight: bold;padding-top:0.5em">EMPRESAS CADASTRADAS</div>
                        <br>
                        <table class="table" style="text-align: center;">
                           <thead class="thead-warning">
                              <tr>
                                 <th>#</th>
                                 <th>Nome</th>
                                 <th>Excluir</th>
                              </tr>
                           </thead>
                           <tbody *ngFor="let empresa of todasEmpresas; let i = index">
                              <tr>
                                 <td>{{ i+1 }}</td>
                                 <td>{{ empresa?.nome}}</td>
                                 <td>
                                     <button class="btn btn-danger" (click)="excluirEmpresa(empresa._id,empresa.nome)">
                                         <fa-icon [icon]="['fas', 'trash']" size="1x"></fa-icon>
                                    </button>
                                </td>      
                              </tr>
                           </tbody>
                        </table>
                     </div>
                </div>         
         </div>
         <div style="height:4em"></div>
        </ng-template>
        </mat-tab>
            <mat-tab label="Cadastrar Turma" *ngIf="tipoUsuario != 'Vendedor'">
                <ng-template matTabContent>
                    <div class="filtroContainer">
                    <div class="cadastroOption">
                        <form [formGroup]="novaTurma" (ngSubmit)="cadastrarNovaTurma()">
                            <h4>CADASTRAR TURMA</h4> 
                            <div class="row">
                                <div class="col col-2">
                                    <label>Cidade</label>
                                    <select formControlName="cidade" (change)="selecionarCidadeCadastrarNovaTurma(novaTurma.get('cidade')?.value)" class="form-control" [ngStyle]="{'border-color': !novaTurma.controls['cidade'].value ? '#dd443e' : '#3cb42c'}">
                                        <option [selected] value="">SELECIONAR</option>
                                        <option *ngFor="let cidade of listaCidadesCadastroNovaTurma" [value]="cidade?.nome">{{cidade?.nome}}</option>
                                    </select>
                                </div>
                                <div class="col col-2">
                                    <label>Instituição</label>
                                    <select formControlName="instituicao" (change)="selecionarInstituicaoNovaTurma()" class="form-control" [ngStyle]="{'border-color': !novaTurma.controls['instituicao'].value ? '#dd443e' : '#3cb42c'}">
                                        <option [selected] value="">SELECIONAR</option>
                                        <option *ngFor="let facul of listaInstituicoesNovaTurma" [value]="facul?.nome">
                                        {{facul?.nome}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col col-4">
                                    <label>Curso</label>
                                    <select formControlName="curso" (change)="selecionarCursoNovaTurma(novaTurma.get('curso')?.value)" class="form-control" [ngStyle]="{'border-color': !novaTurma.controls['curso'].value ? '#dd443e' : '#3cb42c'}">
                                        <option [selected] value="">SELECIONAR</option>
                                        <option *ngFor="let curso of cursosCadastrados" [value]="curso?.nome">
                                            {{curso?.nome}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col col-2">
                                    <label>Graduação</label>
                                    <select formControlName="graduacaoCursoSelecionado" class="form-control">
                                        <option value=""></option>
                                        <option value="PRESENCIAL">PRESENCIAL</option>
                                        <option value="EAD">EAD</option>
                                        <option value="ENSINO TÉCNICO">ENSINO TÉCNICO</option>
                                        <option value="ENSINO MÉDIO">ENSINO MÉDIO</option>
                                        <option value="ENSINO FUNDAMENTAL">ENSINO FUNDAMENTAL</option>
                                    </select>
                                </div>
                                <div class="col col-2" *ngIf="novaTurma.get('graduacaoCursoSelecionado')?.value != 'EAD'">
                                    <label>Turno</label>
                                    <select formControlName="periodo" class="form-control" [ngStyle]="{'border-color': (novaTurma.get('periodo')?.value == '' || novaTurma.get('periodo')?.value == null)  ? '#dd443e' : '#3cb99c'}">
                                        <option value="MATUTINO">MATUTINO</option>
                                        <option value="VESPERTINO">VESPERTINO</option>
                                        <option value="NOTURNO">NOTURNO</option>
                                        <option value="INTEGRAL">INTEGRAL</option>
                                    </select>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col col-3">
                                    <label>Data Inicio</label>
                                    <input type="date" class="form-control" formControlName="dataInicio" (change)="diferencaMeses(novaTurma.get('dataInicio')?.value, novaTurma.get('dataFim')?.value)" [ngStyle]="{'border-color': !novaTurma.controls['dataInicio'].value ? '#dd443e' : '#3cb42c'}">
                                </div>
                                <div class="col col-3">
                                    <label>Data Fim</label>
                                    <input type="date" class="form-control" formControlName="dataFim" (change)="diferencaMeses(novaTurma.get('dataInicio')?.value, novaTurma.get('dataFim')?.value)" [ngStyle]="{'border-color': !novaTurma.controls['dataFim'].value ? '#dd443e' : '#3cb42c'}">
                                </div>
                                <div class="col col-1">
                                    <label>Ano</label>
                                    <input type="text" class="form-control" formControlName="ano" readonly>
                                </div>
                                <div class="col col-1">
                                    <label>Duração</label>
                                    <input type="text" class="form-control" formControlName="duracao" readonly>
                                </div>
                                <div class="col col-1">
                                    <label>Turma</label>
                                        <input type="text" class="form-control" formControlName="turma" [ngStyle]="{'border-color': !novaTurma.controls['turma'].value ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                                </div>
                                <div class="col col-1">
                                    <label>Sala</label>
                                    <input type="text" class="form-control" formControlName="sala" (keyup)="turmaUnicaNova(novaTurma.controls['cidade'].value,novaTurma.controls['instituicao'].value,novaTurma.controls['curso'].value,novaTurma.controls['sala'].value,novaTurma.controls['periodo'].value)" [ngStyle]="{'border-color': !novaTurma.controls['sala'].value || !salaOkey ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                                </div>
                                <div class="col col-2">
                                    <label>Consultor</label>
                                    <input type="text" class="form-control" formControlName="consultor" readonly>
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="col col-1">
                                    <label>Alunos</label>
                                    <input type="number" class="form-control" formControlName="alunos" [ngStyle]="{'border-color': !novaTurma.controls['alunos'].value ? '#dd443e' : '#3cb42c'}">
                                </div>
                                <div class="col col-1">
                                    <label>Periodo</label>
                                    <input type="text" class="form-control" formControlName="periodoTurma" readonly>
                                </div>
                                <div class="col col-4">
                                    <label>Situação</label>
                                    <select formControlName="situacao" class="form-control" [ngStyle]="{'border-color': !novaTurma.controls['situacao'].value ? '#dd443e' : '#3cb42c'}">
                                        <option value="ABERTO">ABERTO</option>
                                        <option value="FECHADO">FECHADO</option>
                                        <option value="NEGOCIAÇÃO">NEGOCIAÇÃO</option>
                                        <option value="PRÉ-CADASTRO">PRÉ-CADASTRO</option>
                                        <option value="ATENDIMENTO URGENTE">ATENDIMENTO URGENTE</option>
                                        <option value="FORMADO">FORMADO</option>
                                    </select>
                                </div>
                                <div class="col col-6" *ngIf="novaTurma.get('situacao')?.value == 'FECHADO'">
                                    <label>Empresa</label>
                                    <select class="form-control" formControlName="empresa" [ngStyle]="{'border-color': novaTurma.get('situacao')?.value == 'FECHADO' && (novaTurma.get('empresa')?.value == '' || novaTurma.get('empresa')?.value == null) ? '#dd443e' : '#3cb42c'}">
                                        <option value="" disabled selected>SELECIONAR</option>
                                        <option *ngFor="let emp of todasEmpresas" [value]="emp?.nome">{{emp?.nome}}</option>
                                    </select>
                                </div>    
                            </div>
                            <br>
                            <div class="row">
                                <div class="col col-5">
                                    <label>Nome Comissão</label>
                                    <input type="text" class="form-control" formControlName="nomeComissao" oninput="this.value = this.value.toUpperCase()">
                                </div>
                                <div class="col col-5">
                                    <label>Razão Social</label>
                                    <input type="text" class="form-control" formControlName="razaoSocialComissao" oninput="this.value = this.value.toUpperCase()">
                                </div>
                                <div class="col col-2">
                                    <label>CNPJ</label>
                                    <input type="text" class="form-control" formControlName="cnpjComissao">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col col-2">
                                    <label>CEP</label>
                                    <input type="text" class="form-control" formControlName="cepComissao">
                                </div>
                                <div class="col col-4">
                                    <label>Endereço</label>
                                    <input type="text" class="form-control" formControlName="enderecoComissao" oninput="this.value = this.value.toUpperCase()">
                                </div>
                                <div class="col col-2">
                                    <label>Bairro</label>
                                    <input type="text" class="form-control" formControlName="bairroComissao" oninput="this.value = this.value.toUpperCase()">
                                </div>
                                <div class="col col-3">
                                    <label>Cidade</label>
                                    <input type="text" class="form-control" formControlName="cidadeComissao" oninput="this.value = this.value.toUpperCase()">
                                </div>
                                <div class="col col-1">
                                    <label>UF</label>
                                    <select formControlName="ufComissao" class="form-control">
                                        <option value="AC">AC</option>
                                        <option value="AL">AL</option>
                                        <option value="AP">AP</option>
                                        <option value="AM">AM</option>
                                        <option value="BA">BA</option>
                                        <option value="CE">CE</option>
                                        <option value="DF">DF</option>
                                        <option value="ES">ES</option>
                                        <option value="GO">GO</option>
                                        <option value="MA">MA</option>
                                        <option value="MT">MT</option>
                                        <option value="MS">MS</option>
                                        <option value="MG">MG</option>
                                        <option value="PA">PA</option>
                                        <option value="PB">PB</option>
                                        <option value="PE">PE</option>
                                        <option value="PI">PI</option>
                                        <option value="PR">PR</option>
                                        <option value="RJ">RJ</option>
                                        <option value="RN">RN</option>
                                        <option value="RS">RS</option>
                                        <option value="RO">RO</option>
                                        <option value="RR">RR</option>
                                        <option value="SC">SC</option>
                                        <option value="SP">SP</option>
                                        <option value="SE">SE</option>
                                        <option value="TO">TO</option>
                                    </select>
                                </div>
                    </div>
                    <hr>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-4">
                            <label>Presidente</label>
                            <input type="text" class="form-control" formControlName="presidente" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-3">
                            <label>Email</label>
                            <input type="text" class="form-control" formControlName="presidenteEmail">
                        </div>
                        <div class="col col-2">
                            <label>CPF</label>
                            <input type="text" class="form-control" mask="000.000.000-00" formControlName="presidenteCpf">
                        </div>
                        <div class="col col-2">
                            <label>RG</label>
                            <input type="text" class="form-control" formControlName="presidenteRg">
                        </div>
                        <div class="col col-1">
                            <label>Órgão</label>
                            <input type="text" class="form-control" formControlName="presidenteRgOrgao" oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-6">
                            <label>Endereço</label>
                            <input type="text" class="form-control" formControlName="presidenteEndereco" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-2">
                            <label>Estado Civil</label>
                            <select class="form-control" formControlName="presidenteEstadoCivil">
                                <option value="SOLTEIRO">SOLTEIRO</option>
                                    <option value="CASADO">CASADO</option>
                                    <option value="DIVORCIADO">DIVORCIADO</option>
                                    <option value="VIÚVO">VIÚVO</option>
                                    <option value="SEPARADO">SEPARADO</option>
                                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Telefone</label>
                            <input type="text" class="form-control" mask="(00) 00000-0000" formControlName="presidenteTelefone">
                        </div>
                        <div class="col col-2">
                            <label>Nacionalidade</label>
                            <input type="text" class="form-control" formControlName="presidenteNacionalidade" oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-4">
                            <label>Vice-Presidente</label>
                            <input type="text" class="form-control" formControlName="vicePresidente" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-3">
                            <label>Email</label>
                            <input type="text" class="form-control" formControlName="vicePresidenteEmail">
                        </div>
                        <div class="col col-2">
                            <label>CPF</label>
                            <input type="text" class="form-control" mask="000.000.000-00" formControlName="vicePresidenteCpf">
                        </div>
                        <div class="col col-2">
                            <label>RG</label>
                            <input type="text" class="form-control" formControlName="vicePresidenteRg">
                        </div>
                        <div class="col col-1">
                            <label>Órgão</label>
                            <input type="text" class="form-control" formControlName="vicePresidenteRgOrgao" oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-6">
                            <label>Endereço</label>
                            <input type="text" class="form-control" formControlName="vicePresidenteEndereco" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-2">
                            <label>Estado Civil</label>
                            <select class="form-control" formControlName="vicePresidenteEstadoCivil">
                                <option value="SOLTEIRO">SOLTEIRO</option>
                                    <option value="CASADO">CASADO</option>
                                    <option value="DIVORCIADO">DIVORCIADO</option>
                                    <option value="VIÚVO">VIÚVO</option>
                                    <option value="SEPARADO">SEPARADO</option>
                                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Telefone</label>
                            <input type="text" class="form-control" mask="(00) 00000-0000" formControlName="vicePresidenteTelefone">
                        </div>
                        <div class="col col-2">
                            <label>Nacionalidade</label>
                            <input type="text" class="form-control" formControlName="vicePresidenteNacionalidade" oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-4">
                            <label>1º Secratário</label>
                            <input type="text" class="form-control" formControlName="primeiroSecretario" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-3">
                            <label>Email</label>
                            <input type="text" class="form-control" formControlName="primeiroSecretarioEmail">
                        </div>
                        <div class="col col-2">
                            <label>CPF</label>
                            <input type="text" class="form-control" mask="000.000.000-00" formControlName="primeiroSecretarioCpf">
                        </div>
                        <div class="col col-2">
                            <label>RG</label>
                            <input type="text" class="form-control" formControlName="primeiroSecretarioRg">
                        </div>
                        <div class="col col-1">
                            <label>Órgão</label>
                            <input type="text" class="form-control" formControlName="primeiroSecretarioRgOrgao" oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-6">
                            <label>Endereço</label>
                            <input type="text" class="form-control" formControlName="primeiroSecretarioEndereco" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-2">
                            <label>Estado Civil</label>
                            <select class="form-control" formControlName="primeiroSecretarioEstadoCivil">
                                <option value="SOLTEIRO">SOLTEIRO</option>
                                    <option value="CASADO">CASADO</option>
                                    <option value="DIVORCIADO">DIVORCIADO</option>
                                    <option value="VIÚVO">VIÚVO</option>
                                    <option value="SEPARADO">SEPARADO</option>
                                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Telefone</label>
                            <input type="text" class="form-control" mask="(00) 00000-0000" formControlName="primeiroSecretarioTelefone">
                        </div>
                        <div class="col col-2">
                            <label>Nacionalidade</label>
                            <input type="text" class="form-control" formControlName="primeiroSecretarioNacionalidade" oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-4">
                            <label>2º Secretário</label>
                            <input type="text" class="form-control" formControlName="segundoSecretario" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-3">
                            <label>Email</label>
                            <input type="text" class="form-control" formControlName="segundoSecretarioEmail">
                        </div>
                        <div class="col col-2">
                            <label>CPF</label>
                            <input type="text" class="form-control" mask="000.000.000-00" formControlName="segundoSecretarioCpf">
                        </div>
                        <div class="col col-2">
                            <label>RG</label>
                            <input type="text" class="form-control" formControlName="segundoSecretarioRg">
                        </div>
                        <div class="col col-1">
                            <label>Órgão</label>
                            <input type="text" class="form-control" formControlName="segundoSecretarioRgOrgao" oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-6">
                            <label>Endereço</label>
                            <input type="text" class="form-control" formControlName="segundoSecretarioEndereco" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-2">
                            <label>Estado Civil</label>
                            <select class="form-control" formControlName="segundoSecretarioEstadoCivil">
                                <option value="SOLTEIRO">SOLTEIRO</option>
                                    <option value="CASADO">CASADO</option>
                                    <option value="DIVORCIADO">DIVORCIADO</option>
                                    <option value="VIÚVO">VIÚVO</option>
                                    <option value="SEPARADO">SEPARADO</option>
                                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Telefone</label>
                            <input type="text" class="form-control" mask="(00) 00000-0000" formControlName="segundoSecretarioTelefone">
                        </div>
                        <div class="col col-2">
                            <label>Nacionalidade</label>
                            <input type="text" class="form-control" formControlName="segundoSecretarioNacionalidade" oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-4">
                            <label>3º Secretário</label>
                            <input type="text" class="form-control" formControlName="terceiroSecretario" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-3">
                            <label>Email</label>
                            <input type="text" class="form-control" formControlName="terceiroSecretarioEmail">
                        </div>
                        <div class="col col-2">
                            <label>CPF</label>
                            <input type="text" class="form-control" mask="000.000.000-00" formControlName="terceiroSecretarioCpf">
                        </div>
                        <div class="col col-2">
                            <label>RG</label>
                            <input type="text" class="form-control" formControlName="terceiroSecretarioRg">
                        </div>
                        <div class="col col-1">
                            <label>Órgão</label>
                            <input type="text" class="form-control" formControlName="terceiroSecretarioRgOrgao" oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-6">
                            <label>Endereço</label>
                            <input type="text" class="form-control" formControlName="terceiroSecretarioEndereco" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-2">
                            <label>Estado Civil</label>
                            <select class="form-control" formControlName="terceiroSecretarioEstadoCivil">
                                <option value="SOLTEIRO">SOLTEIRO</option>
                                    <option value="CASADO">CASADO</option>
                                    <option value="DIVORCIADO">DIVORCIADO</option>
                                    <option value="VIÚVO">VIÚVO</option>
                                    <option value="SEPARADO">SEPARADO</option>
                                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Telefone</label>
                            <input type="text" class="form-control" mask="(00) 00000-0000" formControlName="terceiroSecretarioTelefone">
                        </div>
                        <div class="col col-2">
                            <label>Nacionalidade</label>
                            <input type="text" class="form-control" formControlName="terceiroSecretarioNacionalidade" oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-4">
                            <label>Tesoureiro</label>
                            <input type="text" class="form-control" formControlName="tesoureiro" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-3">
                            <label>Email</label>
                            <input type="text" class="form-control" formControlName="tesoureiroEmail">
                        </div>
                        <div class="col col-2">
                            <label>CPF</label>
                            <input type="text" class="form-control" mask="000.000.000-00" formControlName="tesoureiroCpf">
                        </div>
                        <div class="col col-2">
                            <label>RG</label>
                            <input type="text" class="form-control" formControlName="tesoureiroRg">
                        </div>
                        <div class="col col-1">
                            <label>Órgão</label>
                            <input type="text" class="form-control" formControlName="tesoureiroRgOrgao" oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-6">
                            <label>Endereço</label>
                            <input type="text" class="form-control" formControlName="tesoureiroEndereco" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-2">
                            <label>Estado Civil</label>
                            <select class="form-control" formControlName="tesoureiroEstadoCivil">
                                <option value="SOLTEIRO">SOLTEIRO</option>
                                    <option value="CASADO">CASADO</option>
                                    <option value="DIVORCIADO">DIVORCIADO</option>
                                    <option value="VIÚVO">VIÚVO</option>
                                    <option value="SEPARADO">SEPARADO</option>
                                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Telefone</label>
                            <input type="text" class="form-control" mask="(00) 00000-0000" formControlName="tesoureiroTelefone">
                        </div>
                        <div class="col col-2">
                            <label>Nacionalidade</label>
                            <input type="text" class="form-control" formControlName="tesoureiroNacionalidade" oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-4">
                            <label>1º Conselheiro Fiscal</label>
                            <input type="text" class="form-control" formControlName="primeiroConsFiscal" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-3">
                            <label>Email</label>
                            <input type="text" class="form-control" formControlName="primeiroConsFiscalEmail">
                        </div>
                        <div class="col col-2">
                            <label>CPF</label>
                            <input type="text" class="form-control" mask="000.000.000-00" formControlName="primeiroConsFiscalCpf">
                        </div>
                        <div class="col col-2">
                            <label>RG</label>
                            <input type="text" class="form-control" formControlName="primeiroConsFiscalRg">
                        </div>
                        <div class="col col-1">
                            <label>Órgão</label>
                            <input type="text" class="form-control" formControlName="primeiroConsFiscalRgOrgao" oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-6">
                            <label>Endereço</label>
                            <input type="text" class="form-control" formControlName="primeiroConsFiscalEndereco" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-2">
                            <label>Estado Civil</label>
                            <select class="form-control" formControlName="primeiroConsFiscalEstadoCivil">
                                <option value="SOLTEIRO">SOLTEIRO</option>
                                    <option value="CASADO">CASADO</option>
                                    <option value="DIVORCIADO">DIVORCIADO</option>
                                    <option value="VIÚVO">VIÚVO</option>
                                    <option value="SEPARADO">SEPARADO</option>
                                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Telefone</label>
                            <input type="text" class="form-control" mask="(00) 00000-0000" formControlName="primeiroConsFiscalTelefone">
                        </div>
                        <div class="col col-2">
                            <label>Nacionalidade</label>
                            <input type="text" class="form-control" formControlName="primeiroConsFiscalNacionalidade" oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-4">
                            <label>2º Conselheiro Fiscal</label>
                            <input type="text" class="form-control" formControlName="segundoConsFiscal" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-3">
                            <label>Email</label>
                            <input type="text" class="form-control" formControlName="segundoConsFiscalEmail">
                        </div>
                        <div class="col col-2">
                            <label>CPF</label>
                            <input type="text" class="form-control" mask="000.000.000-00" formControlName="segundoConsFiscalCpf">
                        </div>
                        <div class="col col-2">
                            <label>RG</label>
                            <input type="text" class="form-control" formControlName="segundoConsFiscalRg">
                        </div>
                        <div class="col col-1">
                            <label>Órgão</label>
                            <input type="text" class="form-control" formControlName="segundoConsFiscalRgOrgao" oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-6">
                            <label>Endereço</label>
                            <input type="text" class="form-control" formControlName="segundoConsFiscalEndereco" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-2">
                            <label>Estado Civil</label>
                            <select class="form-control" formControlName="segundoConsFiscalEstadoCivil">
                                <option value="SOLTEIRO">SOLTEIRO</option>
                                    <option value="CASADO">CASADO</option>
                                    <option value="DIVORCIADO">DIVORCIADO</option>
                                    <option value="VIÚVO">VIÚVO</option>
                                    <option value="SEPARADO">SEPARADO</option>
                                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Telefone</label>
                            <input type="text" class="form-control" mask="(00) 00000-0000" formControlName="segundoConsFiscalTelefone">
                        </div>
                        <div class="col col-2">
                            <label>Nacionalidade</label>
                            <input type="text" class="form-control" formControlName="segundoConsFiscalNacionalidade" oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-4">
                            <label>3º Conselheiro Fiscal</label>
                            <input type="text" class="form-control" formControlName="terceiroConsFiscal" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-3">
                            <label>Email</label>
                            <input type="text" class="form-control" formControlName="terceiroConsFiscalEmail">
                        </div>
                        <div class="col col-2">
                            <label>CPF</label>
                            <input type="text" class="form-control" mask="000.000.000-00" formControlName="terceiroConsFiscalCpf">
                        </div>
                        <div class="col col-2">
                            <label>RG</label>
                            <input type="text" class="form-control" formControlName="terceiroConsFiscalRg">
                        </div>
                        <div class="col col-1">
                            <label>Órgão</label>
                            <input type="text" class="form-control" formControlName="terceiroConsFiscalRgOrgao" oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <div class="row" style="width: 95%; margin-left: 10px">
                        <div class="col col-6">
                            <label>Endereço</label>
                            <input type="text" class="form-control" placeholder="Endereço" formControlName="terceiroConsFiscalEndereco" oninput="this.value = this.value.toUpperCase()">
                        </div>
                        <div class="col col-2">
                            <label>Estado Civil</label>
                            <select class="form-control" formControlName="terceiroConsFiscalEstadoCivil">
                                <option value="SOLTEIRO">SOLTEIRO</option>
                                    <option value="CASADO">CASADO</option>
                                    <option value="DIVORCIADO">DIVORCIADO</option>
                                    <option value="VIÚVO">VIÚVO</option>
                                    <option value="SEPARADO">SEPARADO</option>
                                    <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                            </select>
                        </div>
                        <div class="col col-2">
                            <label>Telefone</label>
                            <input type="text" class="form-control" mask="(00) 00000-0000" formControlName="terceiroConsFiscalTelefone">
                        </div>
                        <div class="col col-2">
                            <label>Nacionalidade</label>
                            <input type="text" class="form-control" formControlName="terceiroConsFiscalNacionalidade" oninput="this.value = this.value.toUpperCase()">
                        </div>
                    </div>
                    <div style="text-align: center; margin-top: 40px;margin-bottom:40px">
                        <button type="button" class="btn btn-danger" (click)="closeModal()">CANCELAR</button>
                            &nbsp;&nbsp;
                            <button type="submit" class="btn btn-info" [disabled]="novaTurma.get('alunos')?.value < 1 || !salaOkey || novaTurma.invalid || novaTurma.get('graduacaoCursoSelecionado')?.value != 'EAD' && (novaTurma.get('periodo')?.value == '' || novaTurma.get('periodo')?.value == null) || novaTurma.get('situacao')?.value == 'FECHADO' && (novaTurma.get('empresa')?.value == '' || novaTurma.get('empresa')?.value == null)">CADASTRAR TURMA</button>
                        </div> 
                        </form>
                    </div>
                </div>
        </ng-template>
        </mat-tab>
        <mat-tab label="Excluir Itens" *ngIf="tipoUsuario != 'Vendedor'">
        <ng-template matTabContent>
            <div class="row" style="margin:5px; padding-left: 1em">
            <div class="col col-5 cadastroOption">
                <h4 style="text-align: center">EXCLUIR CIDADE</h4>
                <div class="row">
                    <div class="col col-10">
                        <label>Cidade</label>
                        <select [(ngModel)]="excluirCidadeCadastro" class="form-control">
                            <option value=""></option>
                            <option *ngFor="let cidade of listaCidadesExcluir" [value]="[cidade._id,cidade.nome]">{{cidade?.nome}}</option>
                        </select>
                    </div>
                    <div class="col col-2">
                        <br>
                        <button class="btn btn-danger" (click)="excluirCidade(excluirCidadeCadastro)" [disabled]="!excluirCidadeCadastro">
                            <fa-icon [icon]="['fas', 'trash']" size="1x"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col col-6 cadastroOption">
                <h4 style="text-align: center">EXCLUIR INSTITUIÇÃO</h4>
                <div class="row" >            
                    <div class="col col-5">
                            <label>Cidade</label>
                            <select [(ngModel)]="cidadeExcluirInstituicao" (change)="instituicaoExcluirCadastro(cidadeExcluirInstituicao)" class="form-control">
                                <option value=""></option>
                                <option *ngFor="let cidade of listaCidadesExcluirInstituicao " [value]="cidade?.nome">{{cidade?.nome}}</option>
                            </select>
                    </div>
                    <div class="col col-5">
                            <label>Instituição</label>
                            <select [(ngModel)]="instituicaoExcluirInstituicao" class="form-control">
                                <option value=""></option>
                                <option *ngFor="let facul of listaInstituicoesExcluirInstituicao" [value]="[facul._id,facul.nome]">{{facul?.nome}}</option>
                            </select>
                    </div>
                    <div class="col col-2">
                        <br>
                        <button class="btn btn-danger" (click)="excluirInstituicao(instituicaoExcluirInstituicao)" [disabled]="!cidadeExcluirInstituicao || !instituicaoExcluirInstituicao">
                            <fa-icon [icon]="['fas', 'trash']" size="1x"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    </mat-tab>
    </mat-tab-group>
</div>
<div id="addTurmaModal" class="modal">
    <div class="topVoltar fixo" style="display:flex">
        <fa-icon [icon]="['fas', 'arrow-circle-left']" size="2x" (click)="closeAddTurma()"></fa-icon>
        <div style="text-align:right;width:95%">
            <button class="btn btn-outline-light btn-block" (click)="cadastrarNovaTurma()" [disabled]="novaTurma.invalid">CADASTRAR TURMA</button>
        </div>
    </div>
    <div class="filtroContainer" style="margin-top: 4em;">
        <div class="cadastroOption">
            <form [formGroup]="novaTurma">
                <div class="row">
                    <div class="col col-2">
                        <label>Cidade</label>
                        <select formControlName="cidade" (change)="selecionarCidadeCadastrarNovaTurma(novaTurma.get('cidade')?.value)" class="form-control" [ngStyle]="{'border-color': !novaTurma.controls['cidade'].value ? '#dd443e' : '#3cb42c'}">
                            <option [selected] value="">SELECIONAR</option>
                            <option *ngFor="let cidade of listaCidadesCadastroNovaTurma" [value]="cidade?.nome">{{cidade?.nome}}</option>
                        </select>
                    </div>
                    <div class="col col-2">
                        <label>Instituição</label>
                        <select formControlName="instituicao" (change)="selecionarInstituicaoNovaTurma()" class="form-control" [ngStyle]="{'border-color': !novaTurma.controls['instituicao'].value ? '#dd443e' : '#3cb42c'}">
                            <option [selected] value="">SELECIONAR</option>
                            <option *ngFor="let facul of listaInstituicoesNovaTurma" [value]="facul?.nome">
                            {{facul?.nome}}
                            </option>
                        </select>
                    </div>
                    <div class="col col-4">
                        <label>Curso</label>
                        <select formControlName="curso" (change)="selecionarCursoNovaTurma(novaTurma.get('curso')?.value)" class="form-control" [ngStyle]="{'border-color': !novaTurma.controls['curso'].value ? '#dd443e' : '#3cb42c'}">
                            <option [selected] value="">SELECIONAR</option>
                            <option *ngFor="let curso of cursosCadastrados" [value]="curso?.nome">
                            {{curso?.nome}}
                            </option>
                        </select>
                    </div>
                    <div class="col col-2">
                        <label>Graduação</label>
                        <select formControlName="graduacaoCursoSelecionado" class="form-control">
                            <option value=""></option>
                            <option value="PRESENCIAL">PRESENCIAL</option>
                            <option value="EAD">EAD</option>
                            <option value="ENSINO TÉCNICO">ENSINO TÉCNICO</option>
                            <option value="ENSINO MÉDIO">ENSINO MÉDIO</option>
                            <option value="ENSINO FUNDAMENTAL">ENSINO FUNDAMENTAL</option>
                        </select>
                    </div>
                    <div class="col col-2" *ngIf="novaTurma.get('graduacaoCursoSelecionado')?.value != 'EAD'">
                        <label>Turno</label>
                        <select formControlName="periodo" class="form-control" [ngStyle]="{'border-color': (novaTurma.get('periodo')?.value == '' || novaTurma.get('periodo')?.value == null)  ? '#dd443e' : '#3cb99c'}">
                            <option value="MATUTINO">MATUTINO</option>
                            <option value="VESPERTINO">VESPERTINO</option>
                            <option value="NOTURNO">NOTURNO</option>
                            <option value="INTEGRAL">INTEGRAL</option>
                        </select>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-3">
                        <label>Data Inicio</label>
                        <input type="date" class="form-control" formControlName="dataInicio" (change)="diferencaMeses(novaTurma.get('dataInicio')?.value, novaTurma.get('dataFim')?.value)" [ngStyle]="{'border-color': !novaTurma.controls['dataInicio'].value ? '#dd443e' : '#3cb42c'}">
                    </div>
                    <div class="col col-3">
                        <label>Data Fim</label>
                        <input type="date" class="form-control" formControlName="dataFim" (change)="diferencaMeses(novaTurma.get('dataInicio')?.value, novaTurma.get('dataFim')?.value)" [ngStyle]="{'border-color': !novaTurma.controls['dataFim'].value ? '#dd443e' : '#3cb42c'}">
                    </div>
                    <div class="col col-1">
                        <label>Ano</label>
                        <input type="text" class="form-control" formControlName="ano" readonly>
                    </div>
                    <div class="col col-1">
                        <label>Duração</label>
                        <input type="text" class="form-control" formControlName="duracao" readonly>
                    </div>
                    <div class="col col-1">
                        <label>Turma</label>
                            <input type="text" class="form-control" formControlName="turma" [ngStyle]="{'border-color': !novaTurma.controls['turma'].value ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-1">
                        <label>Sala</label>
                        <input type="text" class="form-control" formControlName="sala" (keyup)="turmaUnicaNova(novaTurma.controls['cidade'].value,novaTurma.controls['instituicao'].value,novaTurma.controls['curso'].value,novaTurma.controls['sala'].value,novaTurma.controls['periodo'].value)" [ngStyle]="{'border-color': !novaTurma.controls['sala'].value || !salaOkey ? '#dd443e' : '#3cb42c'}" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-2">
                        <label>Consultor</label>
                        <input type="text" class="form-control" formControlName="consultor" readonly>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col col-1">
                        <label>Alunos</label>
                        <input type="number" class="form-control" formControlName="alunos" [ngStyle]="{'border-color': !novaTurma.controls['alunos'].value ? '#dd443e' : '#3cb42c'}">
                    </div>
                    <div class="col col-1">
                        <label>Periodo</label>
                        <input type="text" class="form-control" formControlName="periodoTurma" readonly>
                    </div>
                    <div class="col col-4">
                        <label>Situação</label>
                        <select formControlName="situacao" class="form-control" [ngStyle]="{'border-color': !novaTurma.controls['situacao'].value ? '#dd443e' : '#3cb42c'}">
                            <option value="ABERTO">ABERTO</option>
                            <option value="FECHADO">FECHADO</option>
                            <option value="NEGOCIAÇÃO">NEGOCIAÇÃO</option>
                            <option value="PRÉ-CADASTRO">PRÉ-CADASTRO</option>
                            <option value="ATENDIMENTO URGENTE">ATENDIMENTO URGENTE</option>
                            <option value="FORMADO">FORMADO</option>
                        </select>
                    </div>
                    <div class="col col-6" *ngIf="novaTurma.get('situacao')?.value == 'FECHADO'">
                        <label>Empresa</label>
                        <select class="form-control" formControlName="empresa" [ngStyle]="{'border-color': novaTurma.get('situacao')?.value == 'FECHADO' && (novaTurma.get('empresa')?.value == '' || novaTurma.get('empresa')?.value == null) ? '#dd443e' : '#3cb42c'}">
                            <option value="" disabled selected>SELECIONAR</option>
                            <option *ngFor="let emp of todasEmpresas" [value]="emp.nome">{{emp.nome}}</option>
                        </select>
                    </div>     
                </div>
                <br>
                <div class="row">
                    <div class="col col-5">
                        <label>Nome Comissão</label>
                        <input type="text" class="form-control" formControlName="nomeComissao" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-5">
                        <label>Razão Social</label>
                        <input type="text" class="form-control" formControlName="razaoSocialComissao" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-2">
                        <label>CNPJ</label>
                        <input type="text" class="form-control" formControlName="cnpjComissao">
                    </div>
                </div>
                <div class="row">
                    <div class="col col-2">
                        <label>CEP</label>
                        <input type="text" class="form-control" formControlName="cepComissao">
                    </div>
                    <div class="col col-4">
                        <label>Endereço</label>
                        <input type="text" class="form-control" formControlName="enderecoComissao" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-2">
                        <label>Bairro</label>
                        <input type="text" class="form-control" formControlName="bairroComissao" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-3">
                        <label>Cidade</label>
                        <input type="text" class="form-control" formControlName="cidadeComissao" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="col col-1">
                        <label>UF</label>
                        <select formControlName="ufComissao" class="form-control">
                            <option value="AC">AC</option>
                            <option value="AL">AL</option>
                            <option value="AP">AP</option>
                            <option value="AM">AM</option>
                            <option value="BA">BA</option>
                            <option value="CE">CE</option>
                            <option value="DF">DF</option>
                            <option value="ES">ES</option>
                            <option value="GO">GO</option>
                            <option value="MA">MA</option>
                            <option value="MT">MT</option>
                            <option value="MS">MS</option>
                            <option value="MG">MG</option>
                            <option value="PA">PA</option>
                            <option value="PB">PB</option>
                            <option value="PE">PE</option>
                            <option value="PI">PI</option>
                            <option value="PR">PR</option>
                            <option value="RJ">RJ</option>
                            <option value="RN">RN</option>
                            <option value="RS">RS</option>
                            <option value="RO">RO</option>
                            <option value="RR">RR</option>
                            <option value="SC">SC</option>
                            <option value="SP">SP</option>
                            <option value="SE">SE</option>
                            <option value="TO">TO</option>
                        </select>
                    </div>
        </div>
        <hr>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-4">
                <label>Presidente</label>
                <input type="text" class="form-control" formControlName="presidente" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-3">
                <label>E-mail</label>
                <input type="text" class="form-control" formControlName="presidenteEmail">
            </div>
            <div class="col col-2">
                <label>CPF</label>
                <input type="text" class="form-control" mask="000.000.000-00" formControlName="presidenteCpf">
            </div>
            <div class="col col-2">
                <label>RG</label>
                <input type="text" class="form-control" formControlName="presidenteRg">
            </div>
            <div class="col col-1">
                <label>Órgão</label>
                <input type="text" class="form-control" formControlName="presidenteRgOrgao" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-6">
                <label>Endereço</label>
                <input type="text" class="form-control" formControlName="presidenteEndereco" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-2">
                <label>Estado Civil</label>
                <select class="form-control" formControlName="presidenteEstadoCivil">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                        <option value="CASADO">CASADO</option>
                        <option value="DIVORCIADO">DIVORCIADO</option>
                        <option value="VIÚVO">VIÚVO</option>
                        <option value="SEPARADO">SEPARADO</option>
                        <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Telefone</label>
                <input type="text" class="form-control" mask="(00) 00000-0000" formControlName="presidenteTelefone">
            </div>
            <div class="col col-2">
                <label>Nacionalidade</label>
                <input type="text" class="form-control" formControlName="presidenteNacionalidade" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <hr>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-4">
                <label>Vice-Presidente</label>
                <input type="text" class="form-control" formControlName="vicePresidente" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-3">
                <label>E-mail</label>
                <input type="text" class="form-control" formControlName="vicePresidenteEmail">
            </div>
            <div class="col col-2">
                <label>CPF</label>
                <input type="text" class="form-control" mask="000.000.000-00" formControlName="vicePresidenteCpf">
            </div>
            <div class="col col-2">
                <label>RG</label>
                <input type="text" class="form-control" formControlName="vicePresidenteRg">
            </div>
            <div class="col col-1">
                <label>Órgão</label>
                <input type="text" class="form-control" formControlName="vicePresidenteRgOrgao" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-6">
                <label>Endereço</label>
                <input type="text" class="form-control" formControlName="vicePresidenteEndereco" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-2">
                <label>Estado Civil</label>
                <select class="form-control" formControlName="vicePresidenteEstadoCivil">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                        <option value="CASADO">CASADO</option>
                        <option value="DIVORCIADO">DIVORCIADO</option>
                        <option value="VIÚVO">VIÚVO</option>
                        <option value="SEPARADO">SEPARADO</option>
                        <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Telefone</label>
                <input type="text" class="form-control" mask="(00) 00000-0000" formControlName="vicePresidenteTelefone">
            </div>
            <div class="col col-2">
                <label>Nacionalidade</label>
                <input type="text" class="form-control" formControlName="vicePresidenteNacionalidade" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <hr>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-4">
                <label>1º Secratário</label>
                <input type="text" class="form-control" formControlName="primeiroSecretario" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-3">
                <label>E-mail</label>
                <input type="text" class="form-control" formControlName="primeiroSecretarioEmail">
            </div>
            <div class="col col-2">
                <label>CPF</label>
                <input type="text" class="form-control" mask="000.000.000-00" formControlName="primeiroSecretarioCpf">
            </div>
            <div class="col col-2">
                <label>RG</label>
                <input type="text" class="form-control" formControlName="primeiroSecretarioRg">
            </div>
            <div class="col col-1">
                <label>Órgão</label>
                <input type="text" class="form-control" formControlName="primeiroSecretarioRgOrgao" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-6">
                <label>Endereço</label>
                <input type="text" class="form-control" formControlName="primeiroSecretarioEndereco" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-2">
                <label>Estado Civil</label>
                <select class="form-control" formControlName="primeiroSecretarioEstadoCivil">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                        <option value="CASADO">CASADO</option>
                        <option value="DIVORCIADO">DIVORCIADO</option>
                        <option value="VIÚVO">VIÚVO</option>
                        <option value="SEPARADO">SEPARADO</option>
                        <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Telefone</label>
                <input type="text" class="form-control" mask="(00) 00000-0000" formControlName="primeiroSecretarioTelefone">
            </div>
            <div class="col col-2">
                <label>Nacionalidade</label>
                <input type="text" class="form-control" formControlName="primeiroSecretarioNacionalidade" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <hr>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-4">
                <label>2º Secretário</label>
                <input type="text" class="form-control" formControlName="segundoSecretario" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-3">
                <label>E-mail</label>
                <input type="text" class="form-control" formControlName="segundoSecretarioEmail">
            </div>
            <div class="col col-2">
                <label>CPF</label>
                <input type="text" class="form-control" mask="000.000.000-00" formControlName="segundoSecretarioCpf">
            </div>
            <div class="col col-2">
                <label>RG</label>
                <input type="text" class="form-control" formControlName="segundoSecretarioRg">
            </div>
            <div class="col col-1">
                <label>Órgão</label>
                <input type="text" class="form-control" formControlName="segundoSecretarioRgOrgao" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-6">
                <label>Endereço</label>
                <input type="text" class="form-control" formControlName="segundoSecretarioEndereco" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-2">
                <label>Estado Civil</label>
                <select class="form-control" formControlName="segundoSecretarioEstadoCivil">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                        <option value="CASADO">CASADO</option>
                        <option value="DIVORCIADO">DIVORCIADO</option>
                        <option value="VIÚVO">VIÚVO</option>
                        <option value="SEPARADO">SEPARADO</option>
                        <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Telefone</label>
                <input type="text" class="form-control" mask="(00) 00000-0000" formControlName="segundoSecretarioTelefone">
            </div>
            <div class="col col-2">
                <label>Nacionalidade</label>
                <input type="text" class="form-control" formControlName="segundoSecretarioNacionalidade" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <hr>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-4">
                <label>3º Secretário</label>
                <input type="text" class="form-control" formControlName="terceiroSecretario" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-3">
                <label>E-mail</label>
                <input type="text" class="form-control" formControlName="terceiroSecretarioEmail">
            </div>
            <div class="col col-2">
                <label>CPF</label>
                <input type="text" class="form-control" mask="000.000.000-00" formControlName="terceiroSecretarioCpf">
            </div>
            <div class="col col-2">
                <label>RG</label>
                <input type="text" class="form-control" formControlName="terceiroSecretarioRg">
            </div>
            <div class="col col-1">
                <label>Órgão</label>
                <input type="text" class="form-control" formControlName="terceiroSecretarioRgOrgao" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-6">
                <label>Endereço</label>
                <input type="text" class="form-control" formControlName="terceiroSecretarioEndereco" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-2">
                <label>Estado Civil</label>
                <select class="form-control" formControlName="terceiroSecretarioEstadoCivil">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                        <option value="CASADO">CASADO</option>
                        <option value="DIVORCIADO">DIVORCIADO</option>
                        <option value="VIÚVO">VIÚVO</option>
                        <option value="SEPARADO">SEPARADO</option>
                        <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Telefone</label>
                <input type="text" class="form-control" mask="(00) 00000-0000" formControlName="terceiroSecretarioTelefone">
            </div>
            <div class="col col-2">
                <label>Nacionalidade</label>
                <input type="text" class="form-control" formControlName="terceiroSecretarioNacionalidade" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <hr>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-4">
                <label>Tesoureiro</label>
                <input type="text" class="form-control" formControlName="tesoureiro" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-3">
                <label>E-mail</label>
                <input type="text" class="form-control" formControlName="tesoureiroEmail">
            </div>
            <div class="col col-2">
                <label>CPF</label>
                <input type="text" class="form-control" mask="000.000.000-00" formControlName="tesoureiroCpf">
            </div>
            <div class="col col-2">
                <label>RG</label>
                <input type="text" class="form-control" formControlName="tesoureiroRg">
            </div>
            <div class="col col-1">
                <label>Órgão</label>
                <input type="text" class="form-control" formControlName="tesoureiroRgOrgao" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-6">
                <label>Endereço</label>
                <input type="text" class="form-control" formControlName="tesoureiroEndereco" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-2">
                <label>Estado Civil</label>
                <select class="form-control" formControlName="tesoureiroEstadoCivil">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                        <option value="CASADO">CASADO</option>
                        <option value="DIVORCIADO">DIVORCIADO</option>
                        <option value="VIÚVO">VIÚVO</option>
                        <option value="SEPARADO">SEPARADO</option>
                        <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Telefone</label>
                <input type="text" class="form-control" mask="(00) 00000-0000" formControlName="tesoureiroTelefone">
            </div>
            <div class="col col-2">
                <label>Nacionalidade</label>
                <input type="text" class="form-control" formControlName="tesoureiroNacionalidade" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <hr>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-4">
                <label>1º Conselheiro Fiscal</label>
                <input type="text" class="form-control" formControlName="primeiroConsFiscal" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-3">
                <label>E-mail</label>
                <input type="text" class="form-control" formControlName="primeiroConsFiscalEmail">
            </div>
            <div class="col col-2">
                <label>CPF</label>
                <input type="text" class="form-control" mask="000.000.000-00" formControlName="primeiroConsFiscalCpf">
            </div>
            <div class="col col-2">
                <label>RG</label>
                <input type="text" class="form-control" formControlName="primeiroConsFiscalRg">
            </div>
            <div class="col col-1">
                <label>Órgão</label>
                <input type="text" class="form-control" formControlName="primeiroConsFiscalRgOrgao" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-6">
                <label>Endereço</label>
                <input type="text" class="form-control" formControlName="primeiroConsFiscalEndereco" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-2">
                <label>Estado Civil</label>
                <select class="form-control" formControlName="primeiroConsFiscalEstadoCivil">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                        <option value="CASADO">CASADO</option>
                        <option value="DIVORCIADO">DIVORCIADO</option>
                        <option value="VIÚVO">VIÚVO</option>
                        <option value="SEPARADO">SEPARADO</option>
                        <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Telefone</label>
                <input type="text" class="form-control" mask="(00) 00000-0000" formControlName="primeiroConsFiscalTelefone">
            </div>
            <div class="col col-2">
                <label>Nacionalidade</label>
                <input type="text" class="form-control" formControlName="primeiroConsFiscalNacionalidade" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <hr>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-4">
                <label>2º Conselheiro Fiscal</label>
                <input type="text" class="form-control" formControlName="segundoConsFiscal" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-3">
                <label>E-mail</label>
                <input type="text" class="form-control" formControlName="segundoConsFiscalEmail">
            </div>
            <div class="col col-2">
                <label>CPF</label>
                <input type="text" class="form-control" mask="000.000.000-00" formControlName="segundoConsFiscalCpf">
            </div>
            <div class="col col-2">
                <label>RG</label>
                <input type="text" class="form-control" formControlName="segundoConsFiscalRg">
            </div>
            <div class="col col-1">
                <label>Órgão</label>
                <input type="text" class="form-control" formControlName="segundoConsFiscalRgOrgao" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-6">
                <label>Endereço</label>
                <input type="text" class="form-control" formControlName="segundoConsFiscalEndereco" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-2">
                <label>Estado Civil</label>
                <select class="form-control" formControlName="segundoConsFiscalEstadoCivil">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                        <option value="CASADO">CASADO</option>
                        <option value="DIVORCIADO">DIVORCIADO</option>
                        <option value="VIÚVO">VIÚVO</option>
                        <option value="SEPARADO">SEPARADO</option>
                        <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Telefone</label>
                <input type="text" class="form-control" mask="(00) 00000-0000" formControlName="segundoConsFiscalTelefone">
            </div>
            <div class="col col-2">
                <label>Nacionalidade</label>
                <input type="text" class="form-control" formControlName="segundoConsFiscalNacionalidade" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <hr>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-4">
                <label>3º Conselheiro Fiscal</label>
                <input type="text" class="form-control" formControlName="terceiroConsFiscal" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-3">
                <label>E-mail</label>
                <input type="text" class="form-control" formControlName="terceiroConsFiscalEmail">
            </div>
            <div class="col col-2">
                <label>CPF</label>
                <input type="text" class="form-control" mask="000.000.000-00" formControlName="terceiroConsFiscalCpf">
            </div>
            <div class="col col-2">
                <label>RG</label>
                <input type="text" class="form-control" formControlName="terceiroConsFiscalRg">
            </div>
            <div class="col col-1">
                <label>Órgão</label>
                <input type="text" class="form-control" formControlName="terceiroConsFiscalRgOrgao" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
        <div class="row" style="width: 95%; margin-left: 10px">
            <div class="col col-6">
                <label>Endereço</label>
                <input type="text" class="form-control" formControlName="terceiroConsFiscalEndereco" oninput="this.value = this.value.toUpperCase()">
            </div>
            <div class="col col-2">
                <label>Estado Civil</label>
                <select class="form-control"formControlName="terceiroConsFiscalEstadoCivil">
                    <option value="SOLTEIRO">SOLTEIRO</option>
                        <option value="CASADO">CASADO</option>
                        <option value="DIVORCIADO">DIVORCIADO</option>
                        <option value="VIÚVO">VIÚVO</option>
                        <option value="SEPARADO">SEPARADO</option>
                        <option value="UNIÃO ESTÁVEL">UNIÃO ESTÁVEL</option>
                </select>
            </div>
            <div class="col col-2">
                <label>Telefone</label>
                <input type="text" class="form-control" mask="(00) 00000-0000" formControlName="terceiroConsFiscalTelefone">
            </div>
            <div class="col col-2">
                <label>Nacionalidade</label>
                <input type="text" class="form-control" formControlName="terceiroConsFiscalNacionalidade" oninput="this.value = this.value.toUpperCase()">
            </div>
        </div>
            </form>
        </div>
    </div>
</div>
<div class="topoLogo">
    <div>
        <img src="../assets/img/logo.png" style="height:90px">
    </div>
    <div class="btnInner">
        <button class="btn btn-light" (click)="todasTurmas()" style="font-size: 0.75em">
            <fa-icon [icon]="['fas', 'border-all']" size="1x"></fa-icon>&nbsp;&nbsp;TODAS TURMAS
        </button>
        <button class="btn btn-light" (click)="openModal()" style="font-size: 0.75em">
                <fa-icon [icon]="['fas', 'cogs']" size="1x"></fa-icon>&nbsp;&nbsp;GERENCIAR
        </button>
        <button class="btn btn-light" (click)="addTurma()" style="font-size: 0.75em">
            <fa-icon [icon]="['fas', 'plus']" size="1x"></fa-icon>&nbsp;&nbsp;ADD TURMA
        </button>
    </div>
</div>  
<div style="padding-left: 5em">
    <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenStateFilter = true" (closed)="panelOpenStateFilter = false">
            <mat-expansion-panel-header>
                <mat-panel-description> 
                {{panelOpenStateFilter ? 'Esconder Filtros' : 'Mostrar Filtros'}}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col col-3">
                    <label>Data Inicio</label>
                    <input type="date" class="form-control" [(ngModel)]="dtInicio">
                </div>
                <div class="col col-3">
                    <label>Data Fim</label>
                    <input type="date" class="form-control" [(ngModel)]="dtFim">
                </div>
                <div class="col col-3">
                    <label>Graduação</label>
                    <select [(ngModel)]="graduacaoFiltro" class="form-control">
                        <option value="">TODOS</option>
                        <option value="PRESENCIAL">PRESENCIAL</option>
                        <option value="EAD">EAD</option>
                        <option value="ENSINO TÉCNICO">ENSINO TÉCNICO</option>
                        <option value="ENSINO MÉDIO">ENSINO MÉDIO</option>
                        <option value="ENSINO FUNDAMENTAL">ENSINO FUNDAMENTAL</option>
                    </select>
                </div>
                <div class="col col-3">
                    <label>Consultor</label>
                    <select [(ngModel)]="consultor" class="form-control">
                        <option value="">TODOS</option>
                        <option value="Sem Consultor">Sem Consultor</option>
                        <option *ngFor="let consultor of vendedores" [value]="consultor.nome">{{consultor.nome}}</option>
                    </select>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-3">
                    <label>Cidade</label>
                    <select [(ngModel)]="cidadeFiltro" (change)="selecionarCidadeFiltro(cidadeFiltro)" class="form-control">
                        <option [selected] value="">SELECIONAR</option>
                        <option [value]="city.nome" *ngFor="let city of listaCidades">{{city.nome}}</option>
                    </select>
                </div>
                <div class="col col-3">
                    <label>Instituição</label>
                    <select [(ngModel)]="instituicao" class="form-control">
                        <option [selected] value="">SELECIONAR</option>
                        <option *ngFor="let facul of listaInstituicoesFiltro" [value]="facul?.nome">
                        {{facul?.nome}}
                        </option>
                    </select>
                </div>
                <div class="col col-6">
                    <label>Curso</label>
                    <select [(ngModel)]="curso" class="form-control">
                        <option [selected] value="">SELECIONAR</option>
                        <option *ngFor="let curso of cursosCadastrados" [value]="curso?.nome">
                            {{curso?.nome}}
                        </option>
                    </select>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col col-4">
                    <label>Situação</label>
                    <select [(ngModel)]="situacaoFiltro" class="form-control">
                        <option value="">TODOS</option>
                        <option value="ABERTO">ABERTO</option>
                        <option value="FECHADO">FECHADO</option>
                        <option value="NEGOCIAÇÃO">NEGOCIAÇÃO</option>
                        <option value="PRÉ-CADASTRO">PRÉ-CADASTRO</option>
                        <option value="ATENDIMENTO URGENTE">ATENDIMENTO URGENTE</option>
                        <option value="FORMADO">FORMADO</option>
                    </select>
                </div>
                <div class="col col-3">
                    <br>
                    <button class="btn btn-success btn-block" (click)="buscarTurmas()">
                        <fa-icon [icon]="['fas', 'search']" size="1x"></fa-icon>&nbsp;&nbsp;BUSCAR
                    </button>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<br>
<div class="containerTurmas">
    <div class="turmaBox" *ngFor="let turma of turmas;let i = index" [ngStyle]="{'background': turma?.situacao == 'FORMADO' ? 'rgb(181 181 181)' : turma?.situacao == 'PRÉ-CADASTRO' ? 'rgb(137 176 217)' : turma?.situacao == 'ABERTO' ? 'rgb(125 237 149)' : (turma?.situacao == 'FECHADO' && turma?.empresa == 'TRENDS FORMATURAS') ? 'rgb(225, 120, 188)' : (turma?.situacao == 'FECHADO' && turma?.empresa != 'TRENDS FORMATURAS') ? 'rgb(246, 74, 74)' : turma?.situacao == 'ATENDIMENTO URGENTE' ? 'rgb(255 253 154)' : 'rgb(255 185 134)'}">
      <div (click)="openModalDetails(turma?._id)">
        <div class="row">
                <div class="col">
                    <h5>{{turma?.cidade}} - {{turma?.instituicao}}</h5>
                </div>
                <div class="check">
                    <fa-icon [icon]="['fas', 'check-double']" size="1x" [hidden]="!turma?.check"></fa-icon>
                    <fa-icon [icon]="['far', 'square']" size="1x" [hidden]="turma?.check"></fa-icon>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p style="font-size: 0.9em;"><strong>{{turma?.curso}}</strong></p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h5>ANO</h5>
                    <p>{{turma?.ano}}</p>
                </div>
                <div class="col">
                    <h5>PERIODO</h5>
                    <p>{{turma?.periodoTurma}}</p>
                </div>
                <div class="col">
                    <h5>TURMA</h5>
                    <p>{{turma?.turma}}</p>
                </div>
                <div class="col">
                    <h5>SALA</h5>
                    <p>{{turma?.sala}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h5>TURNO</h5>
                    <p>{{turma?.periodo}}</p>
                </div>
                <div class="col">
                    <h5>ANO FIM</h5>
                    <p>{{turma?.dataFim | date:'yyyy'}}</p>
                </div>
                <div class="col">
                    <h5>CONSULTOR</h5>
                    <p>{{turma?.consultor.split(' ')[0]}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h5>SITUAÇÃO</h5>
                    <p [hidden]="turma?.situacao == 'ATENDIMENTO URGENTE'">{{turma?.situacao}}</p>
                    <p [hidden]="turma?.situacao != 'ATENDIMENTO URGENTE'">URGENTE</p>
                </div>
                <div class="col">
                    <h5>COMISSÃO</h5>
                    <p>{{turma?.nomeComissao}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="legenda">
    <div class="row">
        <div class="col col-2 coluna" style="background-color:rgb(137 176 217)">
            PRÉ-CADASTRO
        </div>
        <div class="col col-1 coluna" style="background-color:rgb(137 176 217)">
            {{totalPre}}
        </div>
        <div class="col col-2 coluna" style="background-color:rgb(255, 185, 134)">
            NEGOCIAÇÃO
        </div>
        <div class="col col-1 coluna" style="background-color:rgb(255, 185, 134)">
            {{totalNegociacao}}
        </div>
        <div class="col col-2 coluna" style="background-color:rgb(125 237 149)">
            ABERTO
        </div>
        <div class="col col-1 coluna" style="background-color:rgb(125 237 149)">
            {{totalAberto}}
        </div>
        <div class="col col-2 coluna" style="background-color:rgb(255 253 154)">
            URGENTE
        </div>
        <div class="col col-1 coluna" style="background-color:rgb(255 253 154)">
            {{totalUrgente}}
        </div>
    </div>
    <div class="row">
        <div class="col col-2 coluna" style="background-color:rgb(224 74 74)">
            FECHADO
        </div>
        <div class="col col-1 coluna" style="background-color:rgb(224 74 74)">
            {{totalFechado}}
        </div>
        <div class="col col-2 coluna" style="background-color: rgb(225, 120, 188)">
            FECHADO TRENDS
        </div>
        <div class="col col-1 coluna" style="background-color:rgb(225, 120, 188)">
            {{totalFechadoTrends}}
        </div>
        <div class="col col-2 coluna" style="background-color:rgb(181, 181, 181)">
            FORMADO
        </div>
        <div class="col col-1 coluna" style="background-color:rgb(181, 181, 181)">
            {{totalFormado}}
        </div>
        <div class="col col-2 coluna" style="background-color:rgb(125, 213, 216)">
            TOTAL
        </div>
        <div class="col col-1 coluna" style="background-color:rgb(125, 213, 216)">
            {{totalPre + totalAberto + totalFechado + totalFechadoTrends + totalUrgente + totalNegociacao}}
        </div>
    </div>
</div>