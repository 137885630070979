import { Component, OnDestroy, OnInit} from '@angular/core';
import { DataBaseService } from 'src/app/provider';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-formados',
  templateUrl: './formados.component.html',
  styleUrls: ['./formados.component.css']
})

export class FormadosComponent implements OnInit,OnDestroy {
  selectedFiles:any = "";
  tipoUsuario:any = "";
  usuario:any = "";
  orcamentos:any = [];
  turmas:any = [];
  documentos:any = [];
  listaCidades:any = [];
  cidadeCadastrarTurma:any;
  turmaSelecionadaDetails:any = "";
  listaCidadesDetails:any = [];
  listaInstituicoesDetails:any = [];
  listaCursosDetails:any = [];
  turmaCursoSelecionado:any;
  cursoEscolhidoDetails:any;
  backup:any = [];
  arrayLogs:any = [];
  listaCidadesNovaInstituicao:any = [];
  listaCidadesCadastrarCurso:any = [];
  listaCidadesCadastroNovaTurma:any = [];
  listaCidadesExcluir:any = [];
  listaCidadesExcluirInstituicao:any = [];
  listaCidadesExcluirCurso:any = [];
  listaInstituicoes:any = [];
  listaInstituicoesCadastrarCurso:any = [];
  listaInstituicoesNovaTurma:any = [];
  listaInstituicoesExcluirInstituicao:any = [];
  listaInstituicoesExcluirCurso:any = [];
  listaCursosNovaTurma:any = [];
  listaCursosExcluirCurso:any = [];
  cidadeTurmaSelecionado:any = "";
  instituicaoTurmaSelecionado:any = "";
  cursoTurmaSelecionado:any = "";
  graduacaoTurmaSelecionado:any = "";
  periodoTurmaSelecionado:any = "";
  periodoEAD:boolean = false;
  dataInicioTurmaSelecionado:any = "";
  dataFimTurmaSelecionado:any = "";
  anoTurmaSelecionado:any = "";
  duracaoTurmaSelecionado:any = "";
  relatorios:any = [];
  newRelatorio:boolean = false;
  novaTurma: any; 
  turmaId:any;
  imagemSelecionada:any;
  indexImage:number = 0; 
  contratos:any = [];
  presidente:any = "";
  presidenteCpf:any = "";
  presidenteRg:any = "";
  presidenteEndereco:any = "";
  presidenteRgOrgao:any = "";
  presidenteEstadoCivil:any = "";
  presidenteNacionalidade:any = "";
  presidenteTelefone:any = "";
  presidenteEmail:any = "";
  vicePresidente:any = "";
  vicePresidenteCpf:any = "";
  vicePresidenteRg:any = "";
  vicePresidenteEndereco:any = "";
  vicePresidenteRgOrgao:any = "";
  vicePresidenteEstadoCivil:any = "";
  vicePresidenteNacionalidade:any = "";
  vicePresidenteTelefone:any = "";
  vicePresidenteEmail:any = "";
  primeiroSecretario:any = "";
  primeiroSecretarioCpf:any = "";
  primeiroSecretarioRg:any = "";
  primeiroSecretarioEndereco:any = "";
  primeiroSecretarioRgOrgao:any = "";
  primeiroSecretarioEstadoCivil:any = "";
  primeiroSecretarioNacionalidade:any = "";
  primeiroSecretarioTelefone:any = "";
  primeiroSecretarioEmail:any = "";
  segundoSecretario:any = "";
  segundoSecretarioCpf:any = "";
  segundoSecretarioRg:any = "";
  segundoSecretarioEndereco:any = "";
  segundoSecretarioRgOrgao:any = "";
  segundoSecretarioEstadoCivil:any = "";
  segundoSecretarioNacionalidade:any = "";
  segundoSecretarioTelefone:any = "";
  segundoSecretarioEmail:any = "";
  terceiroSecretario:any = "";
  terceiroSecretarioCpf:any = "";
  terceiroSecretarioRg:any = "";
  terceiroSecretarioEndereco:any = "";
  terceiroSecretarioRgOrgao:any = "";
  terceiroSecretarioEstadoCivil:any = "";
  terceiroSecretarioNacionalidade:any = "";
  terceiroSecretarioTelefone:any = "";
  terceiroSecretarioEmail:any = "";
  tesoureiro:any = "";
  tesoureiroCpf:any = "";
  tesoureiroRg:any = "";
  tesoureiroEndereco:any = "";
  tesoureiroRgOrgao:any = "";
  tesoureiroEstadoCivil:any = "";
  tesoureiroNacionalidade:any = "";
  tesoureiroTelefone:any = "";
  tesoureiroEmail:any = "";
  primeiroConsFiscal:any = "";
  primeiroConsFiscalCpf:any = "";
  primeiroConsFiscalRg:any = "";
  primeiroConsFiscalEndereco:any = "";
  primeiroConsFiscalRgOrgao:any = "";
  primeiroConsFiscalEstadoCivil:any = "";
  primeiroConsFiscalNacionalidade:any = "";
  primeiroConsFiscalTelefone:any = "";
  primeiroConsFiscalEmail:any = "";
  segundoConsFiscal:any = "";
  segundoConsFiscalCpf:any = "";
  segundoConsFiscalRg:any = "";
  segundoConsFiscalEndereco:any = "";
  segundoConsFiscalRgOrgao:any = "";
  segundoConsFiscalEstadoCivil:any = "";
  segundoConsFiscalNacionalidade:any = "";
  segundoConsFiscalTelefone:any = "";
  segundoConsFiscalEmail:any = "";
  terceiroConsFiscal:any = "";
  terceiroConsFiscalCpf:any = "";
  terceiroConsFiscalRg:any = "";
  terceiroConsFiscalEndereco:any = "";
  terceiroConsFiscalRgOrgao:any = "";
  terceiroConsFiscalEstadoCivil:any = "";
  terceiroConsFiscalNacionalidade:any = "";
  terceiroConsFiscalTelefone:any = "";
  terceiroConsFiscalEmail:any = "";
  nomeComissao:any = "";
  razaoSocialComissao:any = "";
  cnpjComissao:any = "";
  cepComissao:any = "";
  enderecoComissao:any = "";
  bairroComissao:any = "";
  cidadeComissao:any = "";
  ufComissao:any = "";
  temContrato:any = "";
  idOrcamento:any = "";
  ultimaCidadeExcluir:any = "";
  ultimaInstituicaoExcluir:any = "";
  assuntos:any = ["Apresentação Turma","Atendimento Presencial","Email", "Orçamento","Proposta Web","Reunião Comissão","Reunião Online","Telefonema","WhatsApp"];
  idTurma:any = "";
  senhaAdm:any = "";
  vendedores:any = [];
  salasTurma:any = "";
  salaAntiga:any = "";
  salaOkey:boolean = true;
  periodoTurma:number = 0;
  alunos:number = 0;
  alunosAntigo:any = 0;
  alunosHistorico:any = [];
  novaEmpresa:any = "";
  todasEmpresas:any = [];
  assuntoRelatorio:any = "";
  descricaoRelatorio:any = "";
  assuntoAgenda:any = "";
  descricaoAgenda:any = "";
  dataAgenda:any = "";
  totalPre:number = 0;
  totalFechado:number = 0;
  totalAberto:number = 0;
  totalUrgente:number = 0;
  totalFechadoVibe:number = 0;
  totalNegociacao:number = 0;
  dtInicio:any = "";
  dtFim:any = "";
  curso:any = "";
  instituicao:any = "";
  graduacaoFiltro:any = "";
  cidadeFiltro:any = "";
  situacaoFiltro:any = "";
  consultor:any = "";
  dataHoje:any = "";
  cidadeExcluirInstituicao:any = "";
  instituicaoExcluirCurso:any = "";
  cidadeExcluirCurso:any = "";
  statusTurma:boolean = false;
  novaCidadeCadastro:any = "";
  cidadeCadastroNovaInstituicao:any = "";
  novaInstituicaoCadastro:any = "";
  cidadeCadastrarCurso:any = "";
  instituicaoNovoCurso:any = "";
  graduacaoCadastroNovoCurso:any = "";
  panelOpenState:boolean = false;
  panelOpenStateFilter:boolean = false;
  novoCurso:any = "";
  instituicaoExcluirInstituicao:any = "";
  cursoExcluirCurso:any = "";
  excluirCidadeCadastro:any = "";
  cursosCadastrados:any = [];
  anos:number = 0;
  arrayTurmas:any = [];

  constructor(private location:Location, private route: ActivatedRoute, public auth:AuthService, public _router:Router, private formBuilder: FormBuilder, private db: DataBaseService) {
    this.tipoUsuario = auth.tipoUsuario;
    this.usuario = localStorage.getItem('usuarioTrends');
    if(this.tipoUsuario == ""){
      this.tipoUsuario = localStorage.getItem('tipoUsuarioTrends')
    }
    if(this.usuario == ""){
      this.usuario = localStorage.getItem('usuarioTrends')
    }
   }

   ngOnDestroy(){
     this.closeModalDetails();
   }

  ngOnInit(){
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    this.turmaId = this.route.snapshot.paramMap.get('key');
    if(this.turmaId != null){
      this.openModalDetails(this.turmaId)
    } 
    this.todasTurmas();
    this.getEmpresas();
    this.db.getUsuarios().subscribe((data:any) => this.vendedores = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}}));
     this.db.getCidades().subscribe((data:any) => this.listaCidades = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}}));
     this.novaTurma = this.formBuilder.group({
      cidade : [null, Validators.required],
      instituicao: [null, Validators.required],
      curso: [null, Validators.required],
      graduacaoCursoSelecionado: [null],
      periodo: [null],
      dataInicio: [null, Validators.required],
      dataFim: [null, Validators.required],
      ano: [null],
      duracao: [null],
      turma: [null, Validators.required],
      sala: [null, Validators.required],
      alunos: [null, Validators.required],
      situacao: [null, Validators.required],
      consultor: [this.usuario],
      empresa: [null],
      nomeComissao: [null],
      razaoSocialComissao: [null],
      cnpjComissao: [null],
      cepComissao : [null],
      enderecoComissao: [null],
      bairroComissao: [null],
      cidadeComissao: [null],
      ufComissao: [null],
      presidente : [null],
      presidenteCpf : [null],
      presidenteRg : [null],
      presidenteEndereco : [null],
      presidenteRgOrgao : [null],
      presidenteEstadoCivil : [null],
      presidenteNacionalidade : [null],
      presidenteTelefone : [null],
      presidenteEmail : [null],
      vicePresidente : [null],
      vicePresidenteCpf : [null],
      vicePresidenteRg : [null],
      vicePresidenteEndereco : [null],
      vicePresidenteRgOrgao : [null],
      vicePresidenteEstadoCivil : [null],
      vicePresidenteNacionalidade : [null],
      vicePresidenteTelefone : [null],
      vicePresidenteEmail : [null],
      primeiroSecretario : [null],
      primeiroSecretarioCpf : [null],
      primeiroSecretarioRg : [null],
      primeiroSecretarioEndereco : [null],
      primeiroSecretarioRgOrgao : [null],
      primeiroSecretarioEstadoCivil : [null],
      primeiroSecretarioNacionalidade : [null],
      primeiroSecretarioTelefone : [null],
      primeiroSecretarioEmail : [null],
      segundoSecretario : [null],
      segundoSecretarioCpf : [null],
      segundoSecretarioRg : [null],
      segundoSecretarioEndereco : [null],
      segundoSecretarioRgOrgao : [null],
      segundoSecretarioEstadoCivil : [null],
      segundoSecretarioNacionalidade : [null],
      segundoSecretarioTelefone : [null],
      segundoSecretarioEmail : [null],
      terceiroSecretario : [null],
      terceiroSecretarioCpf : [null],
      terceiroSecretarioRg : [null],
      terceiroSecretarioEndereco : [null],
      terceiroSecretarioRgOrgao : [null],
      terceiroSecretarioEstadoCivil : [null],
      terceiroSecretarioNacionalidade : [null],
      terceiroSecretarioTelefone : [null],
      terceiroSecretarioEmail : [null],
      tesoureiro : [null],
      tesoureiroCpf : [null],
      tesoureiroRg : [null],
      tesoureiroEndereco : [null],
      tesoureiroRgOrgao : [null],
      tesoureiroEstadoCivil : [null],
      tesoureiroNacionalidade : [null],
      tesoureiroTelefone : [null],
      tesoureiroEmail : [null],
      primeiroConsFiscal : [null],
      primeiroConsFiscalCpf : [null],
      primeiroConsFiscalRg : [null],
      primeiroConsFiscalEndereco : [null],
      primeiroConsFiscalRgOrgao : [null],
      primeiroConsFiscalEstadoCivil : [null],
      primeiroConsFiscalNacionalidade : [null],
      primeiroConsFiscalTelefone : [null],
      primeiroConsFiscalEmail : [null],
      segundoConsFiscal : [null],
      segundoConsFiscalCpf : [null],
      segundoConsFiscalRg : [null],
      segundoConsFiscalEndereco : [null],
      segundoConsFiscalRgOrgao : [null],
      segundoConsFiscalEstadoCivil : [null],
      segundoConsFiscalNacionalidade : [null],
      segundoConsFiscalTelefone : [null],
      segundoConsFiscalEmail : [null],
      terceiroConsFiscal : [null],
      terceiroConsFiscalCpf : [null],
      terceiroConsFiscalRg : [null],
      terceiroConsFiscalEndereco : [null],
      terceiroConsFiscalRgOrgao : [null],
      terceiroConsFiscalEstadoCivil : [null],
      terceiroConsFiscalNacionalidade : [null],
      terceiroConsFiscalTelefone : [null],
      terceiroConsFiscalEmail : [null],
      periodoTurma : [null]
      });
      this.db.getCursosConfig().subscribe((conf:any) => {
        this.cursosCadastrados = conf[0].cursos;
      })
  }

  getEmpresas(){
    this.db.getEmpresas().subscribe((data:any) => {
      this.todasEmpresas = data;
    })
  }

  todasTurmas(){
    this.db.getTodasTurmas("UNIVERSIDADE",true).subscribe((data:any) => {
      this.turmas = data.sort((a:any,b:any) => {
        if(a.curso < b.curso){return -1}
        if(a.curso > b.curso){return 1}
        if(a.ano < b.ano){return -1}
        if(a.ano > b.ano){return 1}
        if(a.turma < b.turma){return -1}
        if(a.turma > b.turma){return 1}
        return 1;
      });
    });
  }
  
  buscarTurmas(){
    this.db.getTurmasGeralFormados(this.dtInicio,this.dtFim,this.curso,this.instituicao,this.graduacaoFiltro,this.cidadeFiltro,this.consultor).subscribe((data:any) => {
      this.turmas = data.sort((a:any,b:any) => {
        if(a.curso < b.curso){return -1}
        if(a.curso > b.curso){return 1}
        if(a.ano < b.ano){return -1}
        if(a.ano > b.ano){return 1}
        if(a.turma < b.turma){return -1}
        if(a.turma > b.turma){return 1}
        return 1;
      });
    })
  }

openModalDetails(id:any){
     this.turmaId = id;
     let modal:any = document.getElementById('myModalDetailsFormados');
     modal.style.display = "block";
     this.db.getCidades().subscribe((data:any) => {
       this.listaCidadesDetails = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
      this.db.getTurmaById(id).subscribe((data:any) => {
          this.turmaSelecionadaDetails = data;
          this.backup = data;
          this.cidadeTurmaSelecionado = this.turmaSelecionadaDetails.cidade;
          this.instituicaoTurmaSelecionado = this.turmaSelecionadaDetails.instituicao;
          this.cursoTurmaSelecionado = this.turmaSelecionadaDetails.curso;
          this.periodoTurmaSelecionado = this.turmaSelecionadaDetails.periodo;
          this.graduacaoTurmaSelecionado = this.turmaSelecionadaDetails.graduacao;
          this.dataInicioTurmaSelecionado = this.turmaSelecionadaDetails.dataInicio;
          this.dataFimTurmaSelecionado = this.turmaSelecionadaDetails.dataFim;
          this.anoTurmaSelecionado = Number(this.turmaSelecionadaDetails.ano);
          this.duracaoTurmaSelecionado = Number(this.turmaSelecionadaDetails.duracao);
          this.periodoTurma = Number(this.turmaSelecionadaDetails.periodoTurma);
          this.alunos = Number(this.turmaSelecionadaDetails.alunos);
          this.alunosAntigo = Number(this.turmaSelecionadaDetails.alunos);
          this.salaAntiga = this.turmaSelecionadaDetails.sala;
          this.presidente = this.turmaSelecionadaDetails.presidente;
          this.presidenteCpf = this.turmaSelecionadaDetails.presidenteCpf;
          this.presidenteRg = this.turmaSelecionadaDetails.presidenteRg;
          this.presidenteEndereco = this.turmaSelecionadaDetails.presidenteEndereco;
          this.presidenteRgOrgao = this.turmaSelecionadaDetails.presidenteRgOrgao;
          this.presidenteEstadoCivil = this.turmaSelecionadaDetails.presidenteEstadoCivil;
          this.presidenteNacionalidade = this.turmaSelecionadaDetails.presidenteNacionalidade;
          this.presidenteTelefone = this.turmaSelecionadaDetails.presidenteTelefone;
          this.vicePresidente = this.turmaSelecionadaDetails.vicePresidente;
          this.vicePresidenteCpf = this.turmaSelecionadaDetails.vicePresidenteCpf;
          this.vicePresidenteRg = this.turmaSelecionadaDetails.vicePresidenteRg;
          this.vicePresidenteEndereco = this.turmaSelecionadaDetails.vicePresidenteEndereco;
          this.vicePresidenteRgOrgao = this.turmaSelecionadaDetails.vicePresidenteRgOrgao;
          this.vicePresidenteEstadoCivil = this.turmaSelecionadaDetails.vicePresidenteEstadoCivil;
          this.vicePresidenteNacionalidade = this.turmaSelecionadaDetails.vicePresidenteNacionalidade;
          this.vicePresidenteTelefone = this.turmaSelecionadaDetails.vicePresidenteTelefone;
          this.primeiroSecretario = this.turmaSelecionadaDetails.primeiroSecretario;
          this.primeiroSecretarioCpf = this.turmaSelecionadaDetails.primeiroSecretarioCpf;
          this.primeiroSecretarioRg = this.turmaSelecionadaDetails.primeiroSecretarioRg;
          this.primeiroSecretarioEndereco = this.turmaSelecionadaDetails.primeiroSecretarioEndereco;
          this.primeiroSecretarioRgOrgao = this.turmaSelecionadaDetails.primeiroSecretarioRgOrgao;
          this.primeiroSecretarioEstadoCivil = this.turmaSelecionadaDetails.primeiroSecretarioEstadoCivil;
          this.primeiroSecretarioNacionalidade = this.turmaSelecionadaDetails.primeiroSecretarioNacionalidade;
          this.primeiroSecretarioTelefone = this.turmaSelecionadaDetails.primeiroSecretarioTelefone;
          this.segundoSecretario = this.turmaSelecionadaDetails.segundoSecretario;
          this.segundoSecretarioCpf = this.turmaSelecionadaDetails.segundoSecretarioCpf;
          this.segundoSecretarioRg = this.turmaSelecionadaDetails.segundoSecretarioRg;
          this.segundoSecretarioEndereco = this.turmaSelecionadaDetails.segundoSecretarioEndereco;
          this.segundoSecretarioRgOrgao = this.turmaSelecionadaDetails.segundoSecretarioRgOrgao;
          this.segundoSecretarioEstadoCivil = this.turmaSelecionadaDetails.segundoSecretarioEstadoCivil;
          this.segundoSecretarioNacionalidade = this.turmaSelecionadaDetails.segundoSecretarioNacionalidade;
          this.segundoSecretarioTelefone = this.turmaSelecionadaDetails.segundoSecretarioTelefone;
          this.terceiroSecretario = this.turmaSelecionadaDetails.terceiroSecretario;
          this.terceiroSecretarioCpf = this.turmaSelecionadaDetails.terceiroSecretarioCpf;
          this.terceiroSecretarioRg = this.turmaSelecionadaDetails.terceiroSecretarioRg;
          this.terceiroSecretarioEndereco = this.turmaSelecionadaDetails.terceiroSecretarioEndereco;
          this.terceiroSecretarioRgOrgao = this.turmaSelecionadaDetails.terceiroSecretarioRgOrgao;
          this.terceiroSecretarioEstadoCivil = this.turmaSelecionadaDetails.terceiroSecretarioEstadoCivil;
          this.terceiroSecretarioNacionalidade = this.turmaSelecionadaDetails.terceiroSecretarioNacionalidade;
          this.terceiroSecretarioTelefone = this.turmaSelecionadaDetails.terceiroSecretarioTelefone;
          this.tesoureiro = this.turmaSelecionadaDetails.tesoureiro;
          this.tesoureiroCpf = this.turmaSelecionadaDetails.tesoureiroCpf;
          this.tesoureiroRg = this.turmaSelecionadaDetails.tesoureiroRg;
          this.tesoureiroEndereco = this.turmaSelecionadaDetails.tesoureiroEndereco;
          this.tesoureiroRgOrgao = this.turmaSelecionadaDetails.tesoureiroRgOrgao;
          this.tesoureiroEstadoCivil = this.turmaSelecionadaDetails.tesoureiroEstadoCivil;
          this.tesoureiroNacionalidade = this.turmaSelecionadaDetails.tesoureiroNacionalidade;
          this.tesoureiroTelefone = this.turmaSelecionadaDetails.tesoureiroTelefone;
          this.primeiroConsFiscal = this.turmaSelecionadaDetails.primeiroConsFiscal;
          this.primeiroConsFiscalCpf = this.turmaSelecionadaDetails.primeiroConsFiscalCpf;
          this.primeiroConsFiscalRg = this.turmaSelecionadaDetails.primeiroConsFiscalRg;
          this.primeiroConsFiscalEndereco = this.turmaSelecionadaDetails.primeiroConsFiscalEndereco;
          this.primeiroConsFiscalRgOrgao = this.turmaSelecionadaDetails.primeiroConsFiscalRgOrgao;
          this.primeiroConsFiscalEstadoCivil = this.turmaSelecionadaDetails.primeiroConsFiscalEstadoCivil;
          this.primeiroConsFiscalNacionalidade = this.turmaSelecionadaDetails.primeiroConsFiscalNacionalidade;
          this.primeiroConsFiscalTelefone = this.turmaSelecionadaDetails.primeiroConsFiscalTelefone;
          this.segundoConsFiscal = this.turmaSelecionadaDetails.segundoConsFiscal;
          this.segundoConsFiscalCpf = this.turmaSelecionadaDetails.segundoConsFiscalCpf;
          this.segundoConsFiscalRg = this.turmaSelecionadaDetails.segundoConsFiscalRg;
          this.segundoConsFiscalEndereco = this.turmaSelecionadaDetails.segundoConsFiscalEndereco;
          this.segundoConsFiscalRgOrgao = this.turmaSelecionadaDetails.segundoConsFiscalRgOrgao;
          this.segundoConsFiscalEstadoCivil = this.turmaSelecionadaDetails.segundoConsFiscalEstadoCivil;
          this.segundoConsFiscalNacionalidade = this.turmaSelecionadaDetails.segundoConsFiscalNacionalidade;
          this.segundoConsFiscalTelefone = this.turmaSelecionadaDetails.segundoConsFiscalTelefone;
          this.terceiroConsFiscal = this.turmaSelecionadaDetails.terceiroConsFiscal;
          this.terceiroConsFiscalCpf = this.turmaSelecionadaDetails.terceiroConsFiscalCpf;
          this.terceiroConsFiscalRg = this.turmaSelecionadaDetails.terceiroConsFiscalRg;
          this.terceiroConsFiscalEndereco = this.turmaSelecionadaDetails.terceiroConsFiscalEndereco;
          this.terceiroConsFiscalRgOrgao = this.turmaSelecionadaDetails.terceiroConsFiscalRgOrgao;
          this.terceiroConsFiscalEstadoCivil = this.turmaSelecionadaDetails.terceiroConsFiscalEstadoCivil;
          this.terceiroConsFiscalNacionalidade = this.turmaSelecionadaDetails.terceiroConsFiscalNacionalidade;
          this.terceiroConsFiscalTelefone = this.turmaSelecionadaDetails.terceiroConsFiscalTelefone;
          this.nomeComissao = this.turmaSelecionadaDetails.nomeComissao;
          this.razaoSocialComissao = this.turmaSelecionadaDetails.razaoSocialComissao;
          this.cnpjComissao = this.turmaSelecionadaDetails.cnpjComissao;
          this.cepComissao = this.turmaSelecionadaDetails.cepComissao;
          this.enderecoComissao = this.turmaSelecionadaDetails.enderecoComissao;
          this.bairroComissao = this.turmaSelecionadaDetails.bairroComissao;
          this.cidadeComissao = this.turmaSelecionadaDetails.cidadeComissao;
          this.ufComissao = this.turmaSelecionadaDetails.ufComissao;
          this.temContrato = this.turmaSelecionadaDetails.contrato;
          this.presidenteEmail = this.turmaSelecionadaDetails.presidenteEmail;
          this.vicePresidenteEmail = this.turmaSelecionadaDetails.vicePresidenteEmail;
          this.primeiroSecretarioEmail = this.turmaSelecionadaDetails.primeiroSecretarioEmail;
          this.segundoSecretarioEmail = this.turmaSelecionadaDetails.segundoSecretarioEmail;
          this.terceiroSecretarioEmail = this.turmaSelecionadaDetails.terceiroSecretarioEmail;
          this.tesoureiroEmail = this.turmaSelecionadaDetails.tesoureiroEmail;
          this.primeiroConsFiscalEmail = this.turmaSelecionadaDetails.primeiroConsFiscalEmail;
          this.segundoConsFiscalEmail = this.turmaSelecionadaDetails.segundoConsFiscalEmail;
          this.terceiroConsFiscalEmail = this.turmaSelecionadaDetails.terceiroConsFiscalEmail;
          if(this.turmaSelecionadaDetails.salas){
            this.salasTurma = [];
            let ctrl = 1;
            this.turmaSelecionadaDetails.salas.reverse().forEach((sl:any) => {
              this.salasTurma.push(sl.data.split('-').reverse().join('/')+" - "+sl.sala);
              if(this.turmaSelecionadaDetails.salas.length == ctrl){
                this.salasTurma = this.salasTurma.join(' \n ')
              }else{
                ctrl++;
              }
            })
          }else{
            this.salasTurma = "";
          }
          //
          if(this.turmaSelecionadaDetails.alunosHistorico){
            this.alunosHistorico = [];
            let ctrl = 1;
            this.turmaSelecionadaDetails.alunosHistorico.reverse().forEach((sl:any) => {
              this.alunosHistorico.push(sl.data.split('-').reverse().join('/')+" - "+sl.alunos);
              if(this.turmaSelecionadaDetails.alunosHistorico.length == ctrl){
                this.alunosHistorico = this.alunosHistorico.join(' \n ')
              }else{
                ctrl++;
              }
            })
          }else{
            this.alunosHistorico = "";
          }
          this.db.getInstituicoesByCidade(this.cidadeTurmaSelecionado).subscribe((data:any) => {
            this.listaInstituicoesDetails = data.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});
              this.db.getCursosByInstituicaoCidade(this.instituicaoTurmaSelecionado,this.cidadeTurmaSelecionado).subscribe((dataC:any) => {
              this.listaCursosDetails = dataC.sort((a:any,b:any) => {if(a.nome < b.nome){return -1}else{return 1}});;
                if(this.graduacaoTurmaSelecionado == "EAD"){
                  this.periodoTurmaSelecionado = "";
                  this.periodoEAD = true;
                }else{
                  this.periodoEAD = false;
                }   
                this.db.getOrcamentoByTurmaId(id).subscribe((data:any) => {
                  this.orcamentos = data;
                  this.db.getDocumentosByTurmaId(id).subscribe((data:any) => {
                    this.documentos = data;
                    this.db.getRelatoriosByTurmaId(id).subscribe((data:any) => {
                      this.relatorios = data;
                      this.db.getContratosByTurmaId(id).subscribe((data:any) => this.contratos = data)
              })
            })
          })
        });
      });           
    });
  });
}

closeModalDetails(){
  let modal:any = document.getElementById('myModalDetailsFormados');
  modal.style.display = "none";
  this.backup = [];
  this.arrayLogs = [];
  this.turmaSelecionadaDetails = [];
  this.cidadeTurmaSelecionado = "";
  this.instituicaoTurmaSelecionado = "";
  this.cursoTurmaSelecionado = "";
  this.periodoTurmaSelecionado = "";
  this.graduacaoTurmaSelecionado = "";
  this.periodoEAD = false;
  this.relatorios = [];
  this.documentos = [];
  this.presidenteCpf = "";
  this.presidenteRg = "";
  this.presidenteEndereco = "";
  this.presidenteRgOrgao = "";
  this.presidenteEstadoCivil = "";
  this.presidenteNacionalidade = "";
  this.vicePresidente = "";
  this.vicePresidenteCpf = "";
  this.vicePresidenteRg = "";
  this.vicePresidenteEndereco = "";
  this.vicePresidenteRgOrgao = "";
  this.vicePresidenteEstadoCivil = "";
  this.vicePresidenteNacionalidade = "";
  this.primeiroSecretario = "";
  this.primeiroSecretarioCpf = "";
  this.primeiroSecretarioRg = "";
  this.primeiroSecretarioEndereco = "";
  this.primeiroSecretarioRgOrgao = "";
  this.primeiroSecretarioEstadoCivil = "";
  this.primeiroSecretarioNacionalidade = "";
  this.segundoSecretario = "";
  this.segundoSecretarioCpf = "";
  this.segundoSecretarioRg = "";
  this.segundoSecretarioEndereco = "";
  this.segundoSecretarioRgOrgao = "";
  this.segundoSecretarioEstadoCivil = "";
  this.segundoSecretarioNacionalidade = "";
  this.terceiroSecretario = "";
  this.terceiroSecretarioCpf = "";
  this.terceiroSecretarioRg = "";
  this.terceiroSecretarioEndereco = "";
  this.terceiroSecretarioRgOrgao = "";
  this.terceiroSecretarioEstadoCivil = "";
  this.terceiroSecretarioNacionalidade = "";
  this.tesoureiro = "";
  this.tesoureiroCpf = "";
  this.tesoureiroRg = "";
  this.tesoureiroEndereco = "";
  this.tesoureiroRgOrgao = "";
  this.tesoureiroEstadoCivil = "";
  this.tesoureiroNacionalidade = "";
  this.primeiroConsFiscal = "";
  this.primeiroConsFiscalCpf = "";
  this.primeiroConsFiscalRg = "";
  this.primeiroConsFiscalEndereco = "";
  this.primeiroConsFiscalRgOrgao = "";
  this.primeiroConsFiscalEstadoCivil = "";
  this.primeiroConsFiscalNacionalidade = "";
  this.segundoConsFiscal = "";
  this.segundoConsFiscalCpf = "";
  this.segundoConsFiscalRg = "";
  this.segundoConsFiscalEndereco = "";
  this.segundoConsFiscalRgOrgao = "";
  this.segundoConsFiscalEstadoCivil = "";
  this.segundoConsFiscalNacionalidade = "";
  this.terceiroConsFiscal = "";
  this.terceiroConsFiscalCpf = "";
  this.terceiroConsFiscalRg = "";
  this.terceiroConsFiscalEndereco = "";
  this.terceiroConsFiscalRgOrgao = "";
  this.terceiroConsFiscalEstadoCivil = "";
  this.terceiroConsFiscalNacionalidade = "";
  this.nomeComissao = "";
  this.razaoSocialComissao = "";
  this.cnpjComissao = "";
  this.cepComissao = "";
  this.enderecoComissao = "";
  this.bairroComissao = "";
  this.cidadeComissao = "";
  this.ufComissao = "";
  this.temContrato = "";
  this.salaAntiga = "";
  this.alunosAntigo = 0;
  this.presidenteTelefone = "";
  this.vicePresidenteTelefone = "";
  this.primeiroSecretarioTelefone = "";
  this.segundoSecretarioTelefone = "";
  this.terceiroSecretarioTelefone = "";
  this.tesoureiroTelefone = "";
  this.primeiroConsFiscalTelefone = "";
  this.segundoConsFiscalTelefone = "";
  this.terceiroConsFiscalTelefone = "";
  this.presidenteEmail = "";
  this.vicePresidenteEmail = "";
  this.primeiroSecretarioEmail = "";
  this.segundoSecretarioEmail = "";
  this.terceiroSecretarioEmail = "";
  this.tesoureiroEmail = "";
  this.primeiroConsFiscalEmail = "";
  this.segundoConsFiscalEmail = "";
  this.terceiroConsFiscalEmail = "";
  this.salaOkey = true;
  this.periodoTurma = 0;
  this.alunos = 0;
  this.todasTurmas();
}
 
  detailOrcamento(key:any){
    this._router.navigate(['/details-orcamento', key])
  }

  detailContrato(key:any){
    this._router.navigate(['/details-contrato', key])
  }

  goBack(){
    this.location.back();
   }

openModalDoc(url:any, i:any){
  this.indexImage = i;
  let modal:any = document.getElementById('modalDocFormados')
  modal.style.display = "block";
  this.imagemSelecionada = url;
}

nextImg(){
  let i = this.indexImage
  if((this.documentos.length - 1) == i){
    this.imagemSelecionada = this.documentos[0].url;
    this.indexImage = 0;
  }else{
    i++
    this.imagemSelecionada = this.documentos[i].url;
    this.indexImage++
  }
}

prevImg(){
  let i = this.indexImage
  if(i == 0){
    this.imagemSelecionada = this.documentos[this.documentos.length - 1].url;
    this.indexImage = this.documentos.length - 1;
  }else{
    i--
    this.imagemSelecionada = this.documentos[i].url;
    this.indexImage--
  }
}

closeModalDoc(){
  let modal:any = document.getElementById('modalDocFormados')
  modal.style.display = "none";
}

}