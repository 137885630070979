import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth.service';
import { DataBaseService } from 'src/app/provider';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  modalOrcamento:boolean = false;
  tipoUsuario:any = "";
  usuario:any = "";

  constructor(public auth: AuthService, public db:DataBaseService) { }

  ngOnInit() {
    this.usuario = localStorage.getItem('usuarioTrends');
    this.tipoUsuario = this.auth.tipoUsuario;
    if(this.tipoUsuario == ""){
      this.tipoUsuario = localStorage.getItem('tipoUsuarioTrends');
    }
   /*   this.db.getInstituicoesEscola().subscribe((data:any) => {
      console.log(data)
      for(let i = 0; i < data.length;i++){
        this.criarTurmas(data[i])
      }
    }) */
  }

  //ESSE CODIGO É PARA INCLUIR TURMAS PRÉ-CADASTRO DE TODAS AS INTITUIÇÕES
 /* 
  criarTurmas(escola:any){
    let cursos = [{"nome":"MATERNAL I"},{"nome":"MATERNAL II"},{"nome":"MATERNAL III"},{"nome":"PRÉ I"},{"nome":"PRÉ II"},{"nome":"PRÉ III"},{"nome":"INFANTIL 4"},{"nome":"INFANTIL 5"}];
    let dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0];
    let jsonDate = new Date().toJSON();
    let hora = new Date(jsonDate).toLocaleTimeString();
    for(let i = 0; i < cursos.length; i++){
      let codigo = (escola.nome.slice(17,23)+cursos[i].nome.replace(/[^\w\s]/gi, '')+Math.random().toString().slice(2,11)).normalize('NFD').replace(/[\u0300-\u036f]/g,"");
      let obj = {
        dataCadastro:dataHoje,
        horaCadastro:hora,
        codigoTurma:codigo.replace(/\s]/gi,''),
        cidade : escola.cidade,
        instituicao: escola.nome,
        curso: cursos[i].nome,
        graduacao: "PRESENCIAL",    
        periodo: "INTEGRAL",
        dataInicio: "2024-02-01",
        dataFim: "2024-12-01",
        ano: 1,
        alunos:0,
        duracao: 1,
        turma: "A",
        sala: "1",
        situacao: "PRÉ-CADASTRO",
        empresa: "TRENDS FORMATURAS",
        consultor: "Anderson Novachinski",
        periodoTurma: 1,
        nomeComissao: null,
        razaoSocialComissao: null,
        cnpjComissao: null,
        cepComissao : null,
        enderecoComissao: null,
        bairroComissao: null,
        cidadeComissao: null,
        ufComissao: null,
        presidente : null,
        presidenteCpf : null,
        presidenteRg : null,
        presidenteEndereco : null,
        presidenteRgOrgao : null,
        presidenteEstadoCivil : null,
        presidenteNacionalidade : null,
        vicePresidente : null,
        vicePresidenteCpf : null,
        vicePresidenteRg : null,
        vicePresidenteEndereco : null,
        vicePresidenteRgOrgao : null,
        vicePresidenteEstadoCivil : null,
        vicePresidenteNacionalidade : null,
        primeiroSecretario : null,
        primeiroSecretarioCpf : null,
        primeiroSecretarioRg : null,
        primeiroSecretarioEndereco : null,
        primeiroSecretarioRgOrgao : null,
        primeiroSecretarioEstadoCivil : null,
        primeiroSecretarioNacionalidade : null,
        segundoSecretario : null,
        segundoSecretarioCpf : null,
        segundoSecretarioRg : null,
        segundoSecretarioEndereco : null,
        segundoSecretarioRgOrgao : null,
        segundoSecretarioEstadoCivil : null,
        segundoSecretarioNacionalidade : null,
        terceiroSecretario : null,
        terceiroSecretarioCpf : null,
        terceiroSecretarioRg : null,
        terceiroSecretarioEndereco : null,
        terceiroSecretarioRgOrgao : null,
        terceiroSecretarioEstadoCivil : null,
        terceiroSecretarioNacionalidade : null,
        tesoureiro : null,
        tesoureiroCpf : null,
        tesoureiroRg : null,
        tesoureiroEndereco : null,
        tesoureiroRgOrgao : null,
        tesoureiroEstadoCivil : null,
        tesoureiroNacionalidade : null,
        primeiroConsFiscal : null,
        primeiroConsFiscalCpf : null,
        primeiroConsFiscalRg : null,
        primeiroConsFiscalEndereco : null,
        primeiroConsFiscalRgOrgao : null,
        primeiroConsFiscalEstadoCivil : null,
        primeiroConsFiscalNacionalidade : null,
        segundoConsFiscal : null,
        segundoConsFiscalCpf : null,
        segundoConsFiscalRg : null,
        segundoConsFiscalEndereco : null,
        segundoConsFiscalRgOrgao : null,
        segundoConsFiscalEstadoCivil : null,
        segundoConsFiscalNacionalidade : null,
        terceiroConsFiscal : null,
        terceiroConsFiscalCpf : null,
        terceiroConsFiscalRg : null,
        terceiroConsFiscalEndereco : null,
        terceiroConsFiscalRgOrgao :null,
        terceiroConsFiscalEstadoCivil : null,
        terceiroConsFiscalNacionalidade : null,
        presidenteTelefone : null,
        vicePresidenteTelefone : null,
        primeiroSecretarioTelefone : null,
        segundoSecretarioTelefone : null,
        terceiroSecretarioTelefone : null,
        tesoureiroTelefone : null,
        primeiroConsFiscalTelefone : null,
        segundoConsFiscalTelefone : null,
        terceiroConsFiscalTelefone : null,
        presidenteEmail : null,
        vicePresidenteEmail : null,
        primeiroSecretarioEmail : null,
        segundoSecretarioEmail : null,
        terceiroSecretarioEmail : null,
        tesoureiroEmail : null,
        primeiroConsFiscalEmail : null,
        segundoConsFiscalEmail : null,
        terceiroConsFiscalEmail : null,
        salas:[],
        alunosHistorico:[],
        tipo:"ESCOLA"
      }
      this.db.postTurma(obj).subscribe(res => {
        console.log(res);
      }, err => console.log(err))
    }
  } */
 
  openModal(){
    let modal:any = document.getElementById('myModalHome')
    modal.style.display = "block";
  }
  
  closeModal(){
    let modal:any = document.getElementById('myModalHome')
    modal.style.display = "none";
  }

  openModalTurmas(){
    let modalTurmas:any = document.getElementById('myModalTurmasHome')
    modalTurmas.style.display = "block";
  }
  
  closeModalTurmas(){
    let modalTurmas:any = document.getElementById('myModalTurmasHome')
    modalTurmas.style.display = "none";
  }

}
