import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth.service';
import { DataBaseService } from './provider';
import { jqxSchedulerComponent } from 'jqwidgets-ng/jqxscheduler';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
  fechado:boolean = false;
  loggedIn: any;
  tipoUsuario:any = "";
  badgeAgenda:number = 0;
  agenda:boolean = false;
  dataHoje:any = "";
  dataSchedulerFull:any = new Date(Date.now() - 10800000).toISOString().split('T')[0].split('-');
  dataScheduler:any = parseFloat(this.dataSchedulerFull[0])+','+parseFloat(this.dataSchedulerFull[1])+','+parseFloat(this.dataSchedulerFull[2]);
  agendaHoje:any = [];
  source:any = null;
  resources:any = null;
  dataAdapter:any;
  loginScreen:boolean = true;
  email:any = "";
  mensagem:any = "";
  imprimirValores:boolean = false;
  
  constructor(public auth: AuthService, public db: DataBaseService, public _router: Router) {
    this.dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0]
    this.tipoUsuario = this.auth.tipoUsuario;
    if(this.tipoUsuario == ""){
      this.tipoUsuario = localStorage.getItem('tipoUsuarioTrends')
    }
  } 
  
ngOnInit(){
  this.email = localStorage.getItem('emailTrends');
  this.navEvents();
  this.atualizarBadge();
  if(localStorage.getItem('tokenTrends')){
        this.db.getUsuarioByEmail(localStorage.getItem('emailTrends')).subscribe((data:any) => {
        if(data.length > 0){
          this.imprimirValores = data[0].imprimirValores;
          if(data[0].situacao != "ATIVO"){
            this.openAlert("USUÁRIO BLOQUEADO! POR FAVOR ENTRAR EM CONTATO COM A TRENDS FORMATURAS!")
            this.auth.logOut();
          }
        }else{
          this.openAlert("USUÁRIO BLOQUEADO! POR FAVOR ENTRAR EM CONTATO COM A TRENDS FORMATURAS!")
          this.auth.logOut();
        }
    })
      setInterval(() => {
        if(localStorage.getItem('tokenTrends')){
        this.db.getUsuarioByEmail(localStorage.getItem('emailTrends')).subscribe((data:any) => {
          if(data.length > 0){
            this.imprimirValores = data[0].imprimirValores;
            if(data[0].situacao != "ATIVO"){
              this.openAlert("USUÁRIO BLOQUEADO! POR FAVOR ENTRAR EM CONTATO COM A TRENDS FORMATURAS!")
              this.auth.logOut();
            }
          }else{
            this.openAlert("USUÁRIO BLOQUEADO! POR FAVOR ENTRAR EM CONTATO COM A TRENDS FORMATURAS!")
            this.auth.logOut();
          }
      })}},900000); 
  }else{
    this.auth.logOut();
  }
}

getAgenda(){
    this.db.getAgendaByVendedor(localStorage.getItem('usuarioTrends')).subscribe((data:any) => {
      let todaAgenda:any = [];
      if(data.length > 0){
        let ctrl = 1;
        data.forEach((item:any) => {
          item.id = item._id;
          todaAgenda.push(item);
          if(data.length == ctrl){
              this.source = {
                dataType: 'json',
                dataFields: [
                    { name: 'id', type: 'string' },
                    { name: 'descricao', type: 'string' },
                    { name: 'assunto', type: 'string' },
                    { name: 'turma', type: 'string' },
                    { name: 'background', type: 'string'},
                    { name: 'start', type: 'date', format: 'yyyy-MM-dd' },
                    { name: 'end', type: 'date', format: 'yyyy-MM-dd' }
                ],
                id: 'id',
                localData: todaAgenda
            };
              this.dataAdapter = new jqx.dataAdapter(this.source);
          }else{
            ctrl++
          }
        })
      }else{
        this.source =  {
          dataType: 'json',
          dataFields: [
              { name: 'id', type: 'string' },
              { name: 'descricao', type: 'string' },
              { name: 'assunto', type: 'string' },
              { name: 'turma', type: 'string' },
              { name: 'background', type: 'string'},
              { name: 'start', type: 'date', format: 'yyyy-MM-dd' },
              { name: 'end', type: 'date', format: 'yyyy-MM-dd' }
          ],
          id: 'id',
          localData: []
      };
      this.dataAdapter = new jqx.dataAdapter(this.source);
    }
    });
}

  date:any = new jqx.date(this.dataScheduler);
  appointmentDataFields: any =  {
      from: 'start',
      to: 'end',
      id: 'id',
      description: 'descricao',
      background: 'background',
      location: 'turma',
      subject: 'assunto'
  };
  views:any =  [
      'dayView',
      'weekView',
      { type: 'monthView', monthRowAutoHeight: true },
      'agendaView'
  ];
  
editDialogCreate = (dialog:any, fields:any, editAppointment:any) => {
  if (!fields){
    return
  }else {
  fields.statusContainer.hide();
  fields.timeZoneContainer.hide();
  fields.repeatContainer.hide();
  fields.allDayContainer.hide();
  fields.colorContainer.hide();
  fields.toContainer.hide();
  if(localStorage.getItem('tipoUsuarioTrends') != "Administrador"){
  fields.buttons.hide();
    }
  }
}
   
editDialogOpen = (dialog:any, fields:any, editAppointment:any) => {
  if (!fields){
    return
  }else {
  fields.statusContainer.hide();
  fields.timeZoneContainer.hide();
  fields.repeatContainer.hide();
  fields.allDayContainer.hide();
  fields.toContainer.hide();
  }
}
 
  localization = {
    '/': '/',
    ':': ':',
    firstDay: 1,
    days: {
        names: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        namesAbbr: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        namesShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']
    },
    months: {
        names: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro', ''],
        namesAbbr: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez', '']
    },
    AM: ['AM', 'am', 'AM'],
    PM: ['PM', 'pm', 'PM'],
    eras: [
        { 'name': 'A.D.', 'start': null, 'offset': 0 }
    ],
    twoDigitYearMax: 2029,
    patterns: {
        d: 'dd/MM/yyyy',
        D: 'dddd, MMMM dd, yyyy',
        t: 'h:mm tt',
        T: 'h:mm:ss tt',
        f: 'dddd, MMMM dd, yyyy h:mm tt',
        F: 'dddd, MMMM dd, yyyy h:mm:ss tt',
        M: 'MMMM dd',
        Y: 'yyyy MMMM',
        S: 'yyyy\u0027-\u0027MM\u0027-\u0027dd\u0027T\u0027HH\u0027:\u0027mm\u0027:\u0027ss',
        ISO: 'yyyy-MM-dd hh:mm:ss',
        ISO2: 'yyyy-MM-dd HH:mm:ss',
        d1: 'dd.MM.yyyy',
        d2: 'dd-MM-yyyy',
        d3: 'dd-MMMM-yyyy',
        d4: 'dd-MM-yy',
        d5: 'H:mm',
        d6: 'HH:mm',
        d7: 'HH:mm tt',
        d8: 'dd/MMMM/yyyy',
        d9: 'MMMM-dd',
        d10: 'MM-dd',
        d11: 'MM-dd-yyyy'
    },
    backString: 'Voltar',
    forwardString: 'Avançar',
    toolBarPreviousButtonString: 'Anterior',
    toolBarNextButtonString: 'Próximo',
    emptyDataString: 'Vazio',
    loadString: 'Carregando...',
    clearString: 'Limpar',
    todayString: 'Hoje',
    dayViewString: 'Dia',
    weekViewString: 'Semana',
    monthViewString: 'Mês',
    timelineDayViewString: 'Diário',
    timelineWeekViewString: 'Semanal',
    timelineMonthViewString: 'Anual',
    loadingErrorMessage: 'Erro ao Carregar, por favor, fechar e abrir novamente a Agenda!',
    editRecurringAppointmentDialogTitleString: 'Editar compromisso recorrente',
    editRecurringAppointmentDialogContentString: 'Deseja editar apenas esta ocorrência ou a série ?',
    editRecurringAppointmentDialogOccurrenceString: 'Editar ocorrências',
    editRecurringAppointmentDialogSeriesString: 'Editar a série',
    editDialogTitleString: 'Editar compromisso',
    editDialogCreateTitleString: 'Criar novo compromisso',
    contextMenuEditAppointmentString: 'Editar compromisso',
    contextMenuCreateAppointmentString: 'Criar novo compromisso',
    editDialogSubjectString: 'Assunto',
    editDialogLocationString: 'Turma',
    editDialogFromString: 'Data',
    editDialogToString: 'Para',
    editDialogAllDayString: 'Todo o Dia',
    editDialogExceptionsString: 'Exceções',
    editDialogResetExceptionsString: 'Redefinir para salvar',
    editDialogDescriptionString: 'Descrição',
    editDialogResourceIdString: 'Calendário',
    editDialogStatusString: 'Status',
    editDialogColorString: 'Cor',
    editDialogColorPlaceHolderString: 'Escolha a Cor',
    editDialogTimeZoneString: 'Fuso Horário',
    editDialogSelectTimeZoneString: 'Selecione o Fuso Horário',
    editDialogSaveString: 'Salvar',
    editDialogDeleteString: 'Deletar',
    editDialogCancelString: 'Cancelar',
    editDialogRepeatString: 'Repetir',
    editDialogRepeatEveryString: 'Repetir Tudo',
    editDialogRepeatEveryWeekString: 'Semana(s)',
    editDialogRepeatEveryYearString: 'Ano(s)',
    editDialogRepeatEveryDayString: 'Dia(s)',
    editDialogRepeatNeverString: 'Nunca',
    editDialogRepeatDailyString: 'Diário',
    editDialogRepeatWeeklyString: 'Semanal',
    editDialogRepeatMonthlyString: 'Mensal',
    editDialogRepeatYearlyString: 'Anual',
    editDialogRepeatEveryMonthString: 'Mes(es)',
    editDialogRepeatEveryMonthDayString: 'Dia',
    editDialogRepeatFirstString: 'Primeiro',
    editDialogRepeatSecondString: 'Segundo',
    editDialogRepeatThirdString: 'Terceiro',
    editDialogRepeatFourthString: 'Quarto',
    editDialogRepeatLastString: 'Ultimo',
    editDialogRepeatEndString: 'Fim',
    editDialogRepeatAfterString: 'Por',
    editDialogRepeatOnString: 'No',
    editDialogRepeatOfString: 'De',
    editDialogRepeatOccurrencesString: 'entrada(s)',
    editDialogRepeatSaveString: 'Salvar Ocorrência',
    editDialogRepeatSaveSeriesString: 'Salvar Séries',
    editDialogRepeatDeleteString: 'Excluir Ocorrências',
    editDialogRepeatDeleteSeriesString: 'Excluir Séries',
    editDialogStatuses: {
        free: 'Livre',
        tentative: 'Tentativa',
        busy: 'Ocupado',
        outOfOffice: 'Fora do Escritório'
    }
  };

  appointmentAdd(e:any){
    let dados = e.args.appointment;
    let startOriginal = dados.originalData.start.toISOString().split('T')[0]+'T12:00:00.000Z';
    let obj = {
      assunto : dados.subject, 
      background : dados.background, 
      turma : dados.location, 
      descricao : dados.description, 
      start : startOriginal,
      end : startOriginal,
      resolvido: false,
      vendedor : localStorage.getItem('usuarioTrends')
      }
    this.db.postAgenda(obj).subscribe(res => {
      console.log(res);
      this.getAgenda();
      this.atualizarBadge()
    }, err => console.log(err))
  }

  appointmentChange(e:any){
    let dados = e.args.appointment;
    let id = dados.originalData.id
    let startOriginal = dados.originalData.start.toISOString().split('T')[0]+'T12:00:00.000Z';
    let obj = {
      assunto : dados.subject, 
      background : dados.background, 
      turma : dados.location, 
      descricao : dados.description, 
      start : startOriginal,
      end : startOriginal,
      vendedor : localStorage.getItem('usuarioTrends')
      }
    this.db.patchAgenda(id,obj).subscribe(res => {
      console.log(res);
      this.getAgenda();
      this.atualizarBadge()
    }, err => console.log(err))
  }

  appointmentDelete(e:any){
    let id = e.args.appointment.originalData.id;
    this.db.deleteAgenda(id).subscribe(res => {
      console.log(res);
      this.getAgenda();
      this.atualizarBadge()
    },
    err => {
      console.log(err);
    });
  }

openAgenda(){
  let modal:any = document.getElementById('agendaModal')
  if(modal.style.display == "block"){
    modal.style.display = "none";
    this.agenda = false;
  }else{
    modal.style.display = "block";
    this.getAgenda();
    this.atualizarBadge();
    this.agenda = true;
  }
}

fecharAgenda(){
  let modal:any = document.getElementById('agendaModal')
  if(modal.style.display == "block"){
    modal.style.display = "none";
    this.agenda = false;
  }
}

menu(){
  let menu:any = document.getElementById('sidebarMenu');
   if(!this.fechado){
        menu.style.transform = "translateX(-250px)";
        this.fechado = true;
   }else{
      menu.style.transform = "translateX(0)";
      this.fechado = false;
  }
}

atualizarBadge(){
    this.db.getAgendaByVendedorData(localStorage.getItem('usuarioTrends'),'2020-01-01T12:00:00.000Z',this.dataHoje+'T12:00:00.000Z').subscribe((data:any) => {
      this.agendaHoje = data.sort((a:any,b:any) => {
        if(a.start.split('T')[0] < b.start.split('T')[0]){return 1}
        if(a.start.split('T')[0] > b.start.split('T')[0]){return -1}
        return 1;
      });
      this.badgeAgenda = data.length;
    });
}

openAgendaBox(){
  let modal:any = document.getElementById('agendaBox')
  if(modal.style.display == "none"){
    modal.style.display = "block";
  }else{
    modal.style.display = "none";
  }
}

fecharCompromisso(id:any){
  if(window.confirm('Deseja Realmente Colocar Esse Compromisso Como RESOLVIDO?')){
      let obj = {
        resolvido: true,
        background: '%231baa02'
      }
      this.db.patchAgenda(id,obj).subscribe(res => {
      console.log(res);
      this.atualizarBadge();
      this.openAlert("Compromisso Alterado para RESOLVIDO!")
    }, err => console.log(err))
  }
}

goToTurma(id:any){
  if(id){
    this._router.navigate(['/turmas']);
    setTimeout(() => {
      this._router.navigate(['/turmas/'+id]);
    },100)
  }
}

navEvents(){
  this._router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: any) => {
    let url = e.url
    if(url == '/login'){
      this.loginScreen = true;
    }else{
      this.loginScreen = false;
    } 
  });
}

openModalTurmas(){
  let modalTurmas:any = document.getElementById('modalTurma')
  modalTurmas.style.display = "block";
}

closeModalTurmas(){
  let modalTurmas:any = document.getElementById('modalTurma')
  modalTurmas.style.display = "none";
}

openModalOrcamentos(){
  let modalTurmas:any = document.getElementById('modalOrcamento')
  modalTurmas.style.display = "block";
}

closeModalOrcamentos(){
  let modalTurmas:any = document.getElementById('modalOrcamento')
  modalTurmas.style.display = "none";
}

openModalParametros(){
  let modalTurmas:any = document.getElementById('modalParametros')
  modalTurmas.style.display = "block";
}

closeModalParametros(){
  let modalTurmas:any = document.getElementById('modalParametros')
  modalTurmas.style.display = "none";
}

openAlert(msg:any){
  this.mensagem = msg;
  let modSair:any = document.getElementById('aviso');
  modSair.style.display = "block";
}

closeAlert(){
  this.mensagem = "";
  let modSair:any = document.getElementById('aviso');
  modSair.style.display = "none";
}

closeMenu(){
  let menu:any = document.getElementById('sidebarMenu');
  menu.style.left = '-200px';
}

showMenu(){
  let menu:any = document.getElementById('sidebarMenu');
  menu.style.left = '0px';
}

}