import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
providedIn: 'root'
})

export class AuthGuard  {
constructor(
public authService: AuthService,
public router: Router
){ }


canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn()) {
        return true;
    }
    this.router.navigate(['login']);
    return false;
    }

}