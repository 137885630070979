<div class="row topoLogo">
    <div class="col col-12">
        <a target="_self" routerLink="/home">
            <img src="../assets/img/logo-branca.png">
        </a>
    </div>
</div>
<div class="back" (click)="goBack()"><fa-icon [icon]="['fas', 'arrow-alt-circle-left']" size="2x"></fa-icon></div>
<!-- INICIO FILTROS -->
<div style="padding-left: 5.5em">
<mat-accordion>
<mat-expansion-panel (opened)="panelOpenState = true"
(closed)="panelOpenState = false">
<mat-expansion-panel-header>
<mat-panel-description> 
{{panelOpenState ? 'Esconder Filtros' : 'Mostrar Filtros'}}
</mat-panel-description>
</mat-expansion-panel-header>
<div class="row"> 
    <div class="col col-2">
        <label>Data Inicio</label>
        <input type="date" class="form-control" [(ngModel)]="dataInicio">
    </div>
    <div class="col col-2">
        <label>Data Fim</label>
        <input type="date" class="form-control" [(ngModel)]="dataFim">
    </div>
    <div class="col col-4">
        <label>Vendedor</label>
        <select class="form-control" [(ngModel)]="vendedor">
            <option value="">TODOS</option>
            <option [value]="v.nome" *ngFor="let v of vendedores">{{v.nome}}</option>
        </select>
    </div>
    <div class="col col-4">
        <label>Assunto</label>
        <select class="form-control" [(ngModel)]="assunto">
            <option value="">TODOS</option>
            <option *ngFor="let assunto of assuntos">{{assunto}}</option>
        </select> 
    </div>
</div>
<div class="row">
    <div class="col col-6">
        <label>Descrição</label>
        <input type="text" class="form-control" [(ngModel)]="descricao">
    </div>
    <div class="col col-5">
        <label>Turma</label>
        <input type="text" class="form-control" [(ngModel)]="turma">
    </div>
    <div class="col col-1">
        <br>
        <button class="btn btn-info" (click)="filterAgenda(dataInicio,dataFim,vendedor,assunto,descricao,turma)">
            <fa-icon [icon]="['fas', 'search']" size="1x"></fa-icon>
        </button>
    </div>
</div>
</mat-expansion-panel>
</mat-accordion>
</div>
<br>
<!-- FIM FILTROS -->
<div  style="padding-left: 5.5em">
<table class="table table-striped">
    <tr>
        <th>Data Inicio</th>
        <th>Data Fim</th>
        <th>Vendedor</th>
        <th>Assunto</th>
        <th>Descrição</th>
        <th>Turma</th>
        <th></th>
        <th></th>
        <th></th>
    </tr>
    <tr *ngFor="let a of agenda">
        <td>{{ a.start | date:'dd/MM/yyyy'}}</td>
        <td>{{ a.end | date:'dd/MM/yyyy'}}</td>
        <td>{{ a.vendedor }}</td>
        <td>{{ a.assunto }}</td>
        <td>{{ a.descricao }}</td>
        <td>{{ a.turma }}</td>
        <td>
            <button class="btn btn-sm btn-warning" (click)="goToTurma(a?.turmaId)" [disabled]="!a?.turmaId">
                <fa-icon [icon]="['fas', 'graduation-cap']"></fa-icon>
            </button>
        </td>
        <td>
            <button class="btn btn-sm btn-success" (click)="fecharCompromisso(a)">
                <fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon>
            </button>
        </td>
        <td>
            <button class="btn btn-sm btn-danger" (click)="excluirCompromisso(a?._id)">
                <fa-icon [icon]="['fas', 'trash']"></fa-icon>
            </button>
        </td>
    </tr>
</table>
<div style="height: 100px;"></div>
</div>