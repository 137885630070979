import { Component, OnInit } from '@angular/core';
import { DataBaseService } from 'src/app/provider';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {
  usuarios:any = [];
  novoUsuarioForm!: FormGroup;
  editUsuarioForm!: FormGroup;
  idUser:any = "";
  dataHoje = new Date(Date.now() - 10800000).toISOString().split('T')[0]; 
 
  constructor(public app:AppComponent, private location:Location, private formBuilder: FormBuilder, private db:DataBaseService) { }

  ngOnInit() {
    this.db.getUsuarios().subscribe((data:any) => this.usuarios = data);
    this.novoUsuarioForm = this.formBuilder.group({
      nome : [null, Validators.required],
      email: [null, Validators.required],
      tipo: [null, Validators.required],
      imprimirValores : [null],
      senha: [null, Validators.required]
    });
    this.editUsuarioForm = this.formBuilder.group({
      nome : [null, Validators.required],
      email: [null, Validators.required],
      tipo: [null, Validators.required],
      senha: [null, Validators.required],
      imprimirValores: [null],
      situacao: [null, Validators.required]
    });
  }

  openModal(){
    let modal:any = document.getElementById('modalAdd')
    modal.style.display = "block";
  }
  
  closeModal(){
    this.novoUsuarioForm.reset();
    let modal:any = document.getElementById('modalAdd')
    modal.style.display = "none";
  }

  novoUsuario(nome:any, email:any, tipo:any, senha:any, imprimirValores:any){
    if(window.confirm("Deseja Cadastrar Esse Novo Usuário?")){
      let obj = {
        nome:nome,
        email:email,
        tipo:tipo,
        senha:senha,
        imprimirValores:imprimirValores,
        situacao:"ATIVO"
      }
      this.db.postUsuario(obj).subscribe(res => {
        console.log(res);
        let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
        let ob = {
          data:this.dataHoje,
          hora:hora,
          usuario: localStorage.getItem('usuarioTrends'),
          menu: "USUÁRIOS",
          descricao: "Novo usuário cadastrado: "+nome
        }
        this.db.postLogs(ob).subscribe(res => {
          console.log(res);
           this.app.openAlert("Usuário Cadastrado com Sucesso!")
          this.closeModal();
          this.ngOnInit();
          }, err => console.log(err));
        }, err => {
         console.log(err);
         this.app.openAlert("Não Foi Possivel Cadastrar Usuário, Tente Novamente")});
      }
  }

  openModalEdit(id:any, nome:any, email:any, tipo:any, senha:any,situacao:any,imprimirValores:any){
    this.editUsuarioForm.get('nome')?.setValue(nome);
    this.editUsuarioForm.get('email')?.setValue(email);
    this.editUsuarioForm.get('tipo')?.setValue(tipo);
    this.editUsuarioForm.get('senha')?.setValue(senha);
    this.editUsuarioForm.get('situacao')?.setValue(situacao);
    this.editUsuarioForm.get('imprimirValores')?.setValue(imprimirValores)
    this.idUser = id;
    let modal:any = document.getElementById('modalEdit')
    modal.style.display = "block";
  }
  
  closeModalEdit(){
    this.editUsuarioForm.reset();
    let modal:any = document.getElementById('modalEdit')
    modal.style.display = "none";
  }

  editarUsuario(nome:any,email:any,tipo:any,senha:any,situacao:any,imprimirValores:any){
    if(window.confirm("Deseja Editar Esse Novo Usuário?")){
      let obj = {
        nome:nome,
        email:email,
        tipo:tipo,
        senha:senha,
        imprimirValores:imprimirValores,
        situacao:situacao
      }
      this.db.patchUsuario(this.idUser,obj).subscribe(res => {
        console.log(res);
        let jsonDate = new Date().toJSON();
        let hora = new Date(jsonDate).toLocaleTimeString();
        let ob = {
          data:this.dataHoje,
          hora:hora,
          usuario: localStorage.getItem('usuarioTrends'),
          menu: "USUÁRIOS",
          descricao: "Usuário editado: "+nome
        }
        this.db.postLogs(ob).subscribe(res => {
          console.log(res);
           this.app.openAlert("Usuário Editado com Sucesso!")
          this.ngOnInit();
          this.closeModalEdit();
        }, err => console.log(err));
      }, err => {
      console.log(err);
       this.app.openAlert("Não Foi Possivel Editar Usuário, Tente Novamente")});
    }
  }


deleteUser(u:any){
  if(window.confirm('Deseja Realmente Excluir Esse Usuário? Esta ação não pode ser desfeita!')){
    this.db.deleteUsuario(u._id).subscribe(res=>{
    console.log(res);
    let jsonDate = new Date().toJSON();
      let hora = new Date(jsonDate).toLocaleTimeString();
      let ob = {
        data:this.dataHoje,
        hora:hora,
        usuario: localStorage.getItem('usuarioTrends'),
        menu: "USUÁRIOS",
        descricao: "Usuário Excluido: "+u.nome
      }
      this.db.postLogs(ob).subscribe(res => {
        console.log(res);
         this.app.openAlert("Usuário Excluido Com Sucesso!")
        this.ngOnInit();
      }, err => console.log(err));
          },err=>{
      console.log(err);
       this.app.openAlert("Não Foi Possivel Excluir esse Usuário, Tente Novamente!")
      })
  }
}

goBack(){
  this.location.back();
 }
}
